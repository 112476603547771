import React from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
  infoIcon: {
    color: theme.colors.system.grey4,
    fontSize: 22,
  },
  popoverRoot: {
    backgroundColor: 'white',
    color: theme.colors.text.main,
    padding: '0.5rem',
    boxShadow: theme.shadows[1],
    width: 250,
  },
  cont: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    ...theme.textStyles.bodyExtraSmall,
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    color: theme.colors.primary.main,
    fontSize: '0.8rem',
  },
  desc: {
    ...theme.textStyles.bodyExtraSmall,
    textAlign: 'center',
    fontSize: '0.8rem',
    marginBottom: theme.spacing(2),
    '& *': {
      ...theme.textStyles.bodyExtraSmall,
    },
  },
}));

const InfoPopover = ({ title, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.cont}>
      <Typography variant="body1" className={classes.title}>
        {title}
      </Typography>

      <Typography variant="body1" component="p" className={classes.desc}>
        {children}
      </Typography>
    </div>
  );
};

InfoPopover.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

InfoPopover.defaultProps = {
  children: null,
};

const AccessInfoTooltip = ({ title, children }) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={(
        <>
          <InfoPopover
            title={title}
          >
            {children}
          </InfoPopover>
        </>
      )}
      placement="left-start"
      classes={{
        tooltip: classes.popoverRoot,
      }}
      interactive
    >
      <IconButton>
        <InfoIcon className={classes.infoIcon} />
      </IconButton>
    </Tooltip>
  );
};

AccessInfoTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

AccessInfoTooltip.defaultProps = {
  children: null,
};

export default AccessInfoTooltip;
