import React, { useState } from 'react';

import { Link, Typography } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import CameraIcon from '@material-ui/icons/Camera';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useAuth, useApi } from '../../../hooks';
import UnlockScrunchUniversityDialog from '../../scrunch-university/UnlockScrunchUniversityDialog';
import { ScrunchUniversityUnlockCTA } from '../scrunch-university/ScrunchUniversityPage';
import MenuPageBase, { Menu, MenuItem } from '../MenuPageBase';

const useStyles = makeStyles((theme) => ({
  resource: {
    width: '100%',
    height: '5rem',
    border: `2px solid ${theme.colors.system.grey2}`,
    borderRadius: '8px',
    display: 'flex',
    marginBottom: '1rem',
    '&:hover': {
      backgroundColor: theme.colors.system.grey1,
    },
  },
  resourceImg: {
    height: '60px',
    width: '60px',
    borderRadius: '4px',
    margin: 'auto 0.5rem',
  },
  resTitle: {
    margin: 'auto 1rem',
    width: '100%',
  },
  resLink: {
    margin: 'auto 2rem',
  },

}));

const Resources = ({ data, isInfluencer }) => {
  const classes = useStyles();

  return (
    <>
      {data && Object.values(data).map((it) => (
        <div className={classes.resource} key={it.filename}>
          <img
            src={it.image}
            alt=" "
            className={classes.resourceImg}
          />
          <div className={classes.resTitle}>
            <Typography>{it.title}</Typography>
          </div>
          <div className={classes.resLink}>
            {/* <Link
              href={`${Config.API_BASE_URL}/scrunch-university/resource/${it.filename}`}
              target="_blank"
            >
              Download
            </Link> */}
            <Link
              href={`https://scrunch-resources-dev.s3.us-east-2.amazonaws.com/${isInfluencer ? 'influencer' : 'brand_and_agency'}/${it.filename}`}
              target="_blank"
            >
              Download
            </Link>
          </div>
        </div>
      ))}
    </>
  );
};

Resources.propTypes = {
  data: PropTypes.shape().isRequired,
  isInfluencer: PropTypes.bool.isRequired,
};

const ScrunchResourcesPage = () => {
  const auth = useAuth();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const renderInfMenu = [
    {
      title: 'Guides',
      icon: LibraryBooksIcon,
    },
    {
      title: 'Templates',
      icon: CalendarTodayIcon,
    },
    {
      title: 'LightroomPresets',
      icon: CameraIcon,
    },
  ];
  const renderBrandMenu = [
    {
      title: 'Documents',
      icon: LibraryBooksIcon,
    },
    {
      title: 'Lightroom Presets',
      icon: CameraIcon,
    },
  ];
  const [activeIndex, setActiveIndex] = useState(0);
  const [{ data, isFetching }] = useApi({
    method: 'get',
    url: '/scrunch-resources/',
  });
  const isInfluencerMenu = !!(data && data.children.length > 2);
  const renderMenu = isInfluencerMenu ? renderInfMenu : renderBrandMenu;
  const [resShow, setResShow] = useState(0);

  const handleClick = (e, index) => {
    setActiveIndex(index);
    setResShow(index);
  };

  const handleLockedMenuItemClick = () => {
    setIsDialogOpen(true);
  };

  if (!auth.hasPermission('scrunch_university.view')) {
    // Show the plan upgrade CTA
    return (
      <MenuPageBase
        menu={(
          <Menu>
            {data && data.children.map((it, index) => (
              <MenuItem
                Icon={LockIcon}
                key={it.title}
                label={renderMenu[index].title}
                onClick={handleLockedMenuItemClick}
                selected={activeIndex === index}
              />
            ))}
          </Menu>
        )}
        showLoadingBar={isFetching}
        title={data ? data.title : ''}
        lock={{
          title: 'Scrunch Resources',
          description: 'Upgrade now and get access to hours of educational content',
        }}
      >
        {data && (
          <ScrunchUniversityUnlockCTA title="Welcome!" youtubeVideoId={isInfluencerMenu ? 'n2vEdEBFBH8' : 'nQ53kYbOd6I'} />
        )}

        <UnlockScrunchUniversityDialog
          isDialogOpen={isDialogOpen}
          closeDialog={() => setIsDialogOpen(false)}
          content={data}
        />
      </MenuPageBase>
    );
  }

  return (
    <MenuPageBase
      menu={(
        <Menu>
          {data && data.children.map((it, index) => (
            <MenuItem
              key={`${it.title}${String(index)}`}
              Icon={renderMenu[index].icon}
              label={renderMenu[index].title}
              onClick={(e) => {
                handleClick(e, index);
              }}
              selected={activeIndex === index}
            />
          ))}
        </Menu>
        )}
      showLoadingBar={isFetching}
      title={data ? data.title : ''}
      subtitle={data ? data.description : ''}
    >
      {data && (
      <Resources
        data={data.children[resShow]}
        isInfluencer={isInfluencerMenu}
      />
      )}
    </MenuPageBase>
  );
};

export default ScrunchResourcesPage;
