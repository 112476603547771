import React from 'react';

import PropTypes from 'prop-types';

import {
  Breadcrumbs, ButtonBase, Typography, Link,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import PageBase from './PageBase';
import AccessLockTooltip from '../common/AccessLockTooltip';
import { useAuth } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '300px 1fr',
    gridTemplateRows: 'auto 1fr',
    gridTemplateAreas: `
      "header header"
      "menu children"
    `,
    height: '100%',
  },
  headerContainer: {
    gridArea: 'header',
    padding: '50px',
    borderBottom: `1px solid ${theme.colors.system.grey3}`,
  },
  menuContainer: {
    gridArea: 'menu',
    borderRight: `1px solid ${theme.colors.system.grey3}`,
  },
  childrenContainer: {
    gridArea: 'children',
    padding: '50px',
  },
  menu: {
    backgroundColor: theme.colors.system.light,
    '&>*': {
      borderBottom: `1px solid ${theme.colors.system.grey3}`,
    },
  },
  menuItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    padding: '20px 15px 20px 15px',
    color: ({ disabled }) => (disabled ? theme.colors.text.light : theme.colors.text.dark),
    backgroundColor: ({ selected }) => (selected ? theme.colors.system.grey1 : 'inherit'),
    borderRight: ({ selected }) => (selected ? `2px solid ${theme.palette.primary.main}` : null),
    '&:hover': {
      backgroundColor: theme.colors.system.grey2,
    },
  },
  menuItemIcon: {
    width: 14,
    height: 14,
    marginRight: 15,
  },
  menuItemLabel: {
    textAlign: 'left',
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  pageTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  pageTitleLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  pageSubtitle: {
    color: theme.colors.system.grey7,
    marginTop: 10,
  },
  pageDescription: {
    marginTop: '0.75rem',
  },
  backButton: {
    marginBottom: 20,
    ...theme.textStyles.bodySmall,
  },
  numbered: {
    font: 'Cooper Lt BT',
    fontSize: '1rem',
    fontWeight: '600',
    marginRight: '1rem',
  },
}));

const MenuPageBreadcrumbs = withStyles((theme) => ({
  root: {
    marginBottom: 15,
    ...theme.textStyles.bodySmall,
  },
}))(Breadcrumbs);

const MenuItem = (props) => {
  const {
    disabled,
    Icon,
    label,
    onClick,
    selected,
    endIcon,
    Numbered,
  } = props;
  const classes = useStyles({ disabled, onClick, selected });
  return (
    <ButtonBase className={classes.menuItem} disabled={disabled} onClick={onClick}>
      {Numbered && (
        <div className={classes.numbered}>{Numbered}</div>
      )}
      {Icon && (
        <Icon className={classes.menuItemIcon} />
      )}
      <Typography className={classes.menuItemLabel} variant="body1">{label}</Typography>
      <div>
        {endIcon}
      </div>
    </ButtonBase>
  );
};

MenuItem.propTypes = {
  disabled: PropTypes.bool,
  Icon: PropTypes.elementType,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  endIcon: PropTypes.node,
  Numbered: PropTypes.number,
};

MenuItem.defaultProps = {
  disabled: false,
  Icon: null,
  onClick: null,
  selected: null,
  endIcon: null,
  Numbered: null,
};

const Menu = ({ children, className }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.menu} ${className}`}>
      {children}
    </div>
  );
};

Menu.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Menu.defaultProps = {
  children: null,
  className: null,
};

const MenuPageBase = (props) => {
  const {
    breadcrumbs,
    children,
    menu,
    showLoadingBar,
    title,
    subtitle,
    lock,
    backLink,
    alertMessage,
    backButton,
  } = props;
  const classes = useStyles();
  const auth = useAuth();

  return (
    <PageBase showLoadingBar={showLoadingBar} title={title}>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          {backButton && <MenuPageBreadcrumbs>{backButton}</MenuPageBreadcrumbs>}
          {breadcrumbs && <MenuPageBreadcrumbs>{breadcrumbs}</MenuPageBreadcrumbs>}
          {auth.account.type === 'influencer' && backLink && backLink.link && backLink.label && (
            <Link href={backLink.link} color="primary" variant="body2">
              &lt;&nbsp;
              {backLink.label}
            </Link>
          )}
          <div
            className={classes.pageTitle}
            style={auth.account.type === 'influencer' && backLink && backLink.link && backLink.label ? { marginTop: 10 } : {}}
          >
            <div className={classes.pageTitleLeft}>
              <Typography variant="h5">{title}</Typography>
              {lock && (
              <AccessLockTooltip
                title={lock.title}
              >
                {lock.description}
              </AccessLockTooltip>
              )}
            </div>
            {alertMessage && alertMessage}
          </div>

          {/* {description && (
            <div className={classes.pageDescription}>
              <Typography>{description}</Typography>
            </div>
           )} */}

          {subtitle && (
            <Typography variant="body2" className={classes.pageSubtitle}>{subtitle}</Typography>
          )}
        </div>
        <div className={classes.menuContainer}>
          {menu}
        </div>
        <div className={classes.childrenContainer}>
          {children}
        </div>
      </div>
    </PageBase>
  );
};

MenuPageBase.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.node),
  backButton: PropTypes.node,
  children: PropTypes.node,
  menu: PropTypes.node.isRequired,
  showLoadingBar: PropTypes.bool,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  lock: PropTypes.shape(),
  backLink: PropTypes.shape(),
  alertMessage: PropTypes.node,
  description: PropTypes.string,
};

MenuPageBase.defaultProps = {
  breadcrumbs: null,
  children: null,
  showLoadingBar: false,
  lock: null,
  alertMessage: null,
  subtitle: null,
  backLink: null,
  description: null,
  backButton: null,
};

export default MenuPageBase;

export { Menu, MenuItem };
