import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button, makeStyles, Typography, Paper,
} from '@material-ui/core';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import Config from '../../config';
import { useApi, useAuth, useNotification } from '../../hooks';
import SlidingPaneBase from './SlidingPaneBase';
import SafeCheckoutHeader from './SafeCheckoutHeader';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(Config.STRIPE_PUBLIC_KEY);

const useStyles = makeStyles((theme) => ({
  extraSmallText: {
    ...theme.textStyles.bodyExtraSmall,
    color: theme.colors.system.grey7,
  },
  label: {
    ...theme.textStyles.body,
    marginBottom: '5px',
    marginTop: 0,
  },
  grid: {
    display: 'grid',
    gridTemplateRows: '1fr 1fr auto',
    gridTemplateColumns: '2fr 1fr',
    gap: '20px 20px',
    marginBottom: '20px',
  },
  name: {
    gridArea: '1/1/2/3',
  },
  number: {
    gridArea: '2/1/3/2',
  },
  expire: {
    gridArea: '2/2/3/3',
  },
  cvc: {
    gridArea: '3/1/4/2',
  },
  zip: {
    gridArea: '3/2/4/3',
  },
  paper: {
    padding: theme.spacing(1),
  },
  submitBtn: {
    width: '200px',
  },
  input: {
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  stripeInput: {
    padding: '2px 1px 1px 1px',
  },
}));

const UpdateCreditCard = ({
  onClose, onSuccess, hasCardOnRecord,
}) => {
  const [isStripeFetching, setIsStripeFetching] = useState(false);
  const { createNotification } = useNotification();
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  // const [name, setName] = useState(hasCardOnRecord ?
  // customer.invoice_settings.default_payment_method.billing_details.name : '');
  // const [postalCode, setPostalCode] = useState(hasCardOnRecord ?
  // customer.invoice_settings.default_payment_method.billing_details.address.postal_code : '');
  const auth = useAuth();
  const [
    {
      status: postPaymentMethodStatus,
      isFetching: isPostPaymentMethodFetching,
    },
    postPaymentMethodRequest,
  ] = useApi();
  const [{ data: setupIntent, isFetching: isGetSetupIntentFetching }] = useApi({
    method: 'get',
    url: `/account/${auth.account.id}/billing/stripe-setup-intent/`,
  });

  useEffect(() => {
    if (postPaymentMethodStatus === 200) {
      if (onSuccess) {
        onSuccess();
        createNotification('success', 'Credit card has been updated.');
      }
      onClose(false);
    }
  }, [createNotification, onClose, onSuccess, postPaymentMethodStatus]);

  const handleSubmit = async () => {
    setIsStripeFetching(true);

    const result = await stripe.confirmCardSetup(
      setupIntent.client_secret,
      {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          // billing_details: {
          //   name,
          //   address: {
          //     postal_code: postalCode,
          //   },
          // },
        },
      },
    );

    if (result.error) {
      createNotification('error', 'Credit card is invalid.');
    } else {
      postPaymentMethodRequest({
        method: 'post',
        url: `/account/${auth.account.id}/billing/stripe-payment-method/`,
        data: {
          payment_method: result.setupIntent.payment_method,
        },
      });
    }
    setIsStripeFetching(false);
  };

  return (
    <>
      <SafeCheckoutHeader
        title={`${hasCardOnRecord ? 'Update' : 'Add'} Credit Card`}
        subTitle="Save your card information for easy billings"
      />
      <div className={classes.grid}>
        <div className={classes.name}>
          <Typography variant="h5" className={classes.label}>
            Name On Card
          </Typography>
          <Paper
            variant="outlined"
            elevation={0}
            className={classes.paper}
          >
            <input
              className={classes.input}
              // onChange={(e) => setName(e.target.value)}
              // value={name || ''}
            />
          </Paper>
        </div>
        <div className={classes.number}>
          <Typography variant="h5" className={classes.label}>
            Card Number
          </Typography>
          <Paper
            variant="outlined"
            elevation={0}
            className={classes.paper}
          >
            <CardNumberElement className={classes.stripeInput} />
          </Paper>
        </div>
        <div className={classes.expire}>
          <Typography variant="h5" className={classes.label}>
            Expiration
          </Typography>
          <Paper
            variant="outlined"
            elevation={0}
            className={classes.paper}
          >
            <CardExpiryElement className={classes.stripeInput} />
          </Paper>
        </div>
        <div className={classes.cvc}>
          <Typography className={classes.label}>
            {'CVC '}
          </Typography>
          <Paper
            variant="outlined"
            elevation={0}
            className={classes.paper}
          >
            <CardCvcElement className={classes.stripeInput} />
          </Paper>
          <Typography component="span" className={classes.extraSmallText}>
            3 digits on back
          </Typography>
        </div>
        <div className={classes.zip}>
          <Typography variant="h5" className={classes.label}>
            {'Postal Code '}
            <span className={classes.extraSmallText}>(Optional)</span>
          </Typography>
          <Paper
            variant="outlined"
            elevation={0}
            className={classes.paper}
          >
            <input
              className={classes.input}
              // onChange={(e) => setPostalCode(e.target.value)}
              // value={postalCode || ''}
            />
          </Paper>
        </div>
      </div>
      <Button
        color="primary"
        disabled={isStripeFetching || isPostPaymentMethodFetching || isGetSetupIntentFetching}
        onClick={handleSubmit}
        variant="contained"
        className={classes.submitBtn}
      >
        {`${hasCardOnRecord ? 'Update' : 'Add'} Now`}
      </Button>
    </>
  );
};

UpdateCreditCard.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  hasCardOnRecord: PropTypes.bool.isRequired,
  // customer: PropTypes.shape().isRequired,
};

UpdateCreditCard.defaultProps = {
  onSuccess: null,
};

const UpdateCreditCardButton = (props) => {
  const {
    ButtonProps,
    ButtonText,
    // customer,
    onSuccess,
    hasCardOnRecord,
  } = props;
  const [paneIsOpen, setPaneIsOpen] = useState(false);

  return (
    <div>
      <Button
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...ButtonProps}
        onClick={() => setPaneIsOpen(true)}
      >
        {ButtonText}
      </Button>
      <Elements stripe={stripePromise}>
        <SlidingPaneBase
          paneIsOpen={paneIsOpen}
          onRequestClose={setPaneIsOpen}
        >
          <UpdateCreditCard
            // customer={customer}
            onClose={setPaneIsOpen}
            onSuccess={onSuccess}
            hasCardOnRecord={hasCardOnRecord}
          />
        </SlidingPaneBase>
      </Elements>
    </div>
  );
};

UpdateCreditCardButton.propTypes = {
  ButtonProps: PropTypes.shape(),
  ButtonText: PropTypes.string.isRequired,
  customer: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func,
  hasCardOnRecord: PropTypes.bool.isRequired,
};

UpdateCreditCardButton.defaultProps = {
  ButtonProps: null,
  onSuccess: null,
};

export default UpdateCreditCardButton;
