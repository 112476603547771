import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Typography,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import PageBase from '../PageBase';

import {
  MainPageForm,
  AboutPageForm,
  ContactPageForm,
} from '../../media-kit/form/PersonalDetails';
import SocialStatisticsForm from '../../media-kit/form/SocialStatistics';
import {
  InstagramAnalyticsForm,
  InsatgramAudienceForm,
  InstagramAudienceLocationForm,
  InstagramCollaborationsForm,
} from '../../media-kit/form/InstagramAnalytics';
import { BrandsForm, TestimonialForm } from '../../media-kit/form/Collaborations';
import ServicesForm from '../../media-kit/form/RateCard';
import DisablePage from '../../common/DisablePage';

import Page1Preview from '../../media-kit/previews/Page1Preview';
import Page2Preview from '../../media-kit/previews/Page2Preview';
import Page3Preview from '../../media-kit/previews/Page3Preview';
import Page4Preview from '../../media-kit/previews/Page4Preview';
import Page5Preview from '../../media-kit/previews/Page5Preview';
import Page6Preview from '../../media-kit/previews/Page6Preview';
import Page7Preview from '../../media-kit/previews/Page7Preview';
import Page8Preview from '../../media-kit/previews/Page8Preview';

import { useStyles } from '../../media-kit/form/styles';
import MediaKitPdf from '../../media-kit/MediaKitPdf';
import { useAuth, useNotification, useRoute } from '../../../hooks';

import {
  defaultFormData,
} from '../../media-kit/data';
import API from '../../../utils/api';

import MainImage from '../../../assets/images/media-kit/page-1-dummy.jpg';
import AboutImage from '../../../assets/images/media-kit/page-2-dummy.jpg';
import ContactImage from '../../../assets/images/media-kit/page-3-dummy.jpg';
import SocialStatsImage from '../../../assets/images/media-kit/page-4-dummy.jpg';
import InstagramAnalyticsImage from '../../../assets/images/media-kit/page-5-dummy.jpg';
import Blog1Image from '../../../assets/images/media-kit/blog-1-dummy.jpg';
import Blog2Image from '../../../assets/images/media-kit/blog-2-dummy.jpg';
import Blog3Image from '../../../assets/images/media-kit/blog-3-dummy.jpg';
// import Placeholder from '../../../assets/images/media-kit/placeholder.png';

const breadcrumOrderInit = {
  1: {
    name: 'Personal Details',
    unlocked: true,
  },
  2: {
    name: 'Social Statistics',
    unlocked: false,
  },
  3: {
    name: 'Instagram Analytics',
    unlocked: false,
  },
  4: {
    name: 'Brands & Testimonial',
    unlocked: false,
  },
  5: {
    name: 'Rate Card',
    unlocked: false,
  },
};

const PdfPreview = ({ formData }) => (
  <PDFViewer style={{ width: '100%', height: '100%' }}>
    <MediaKitPdf formData={formData} />
  </PDFViewer>
);

PdfPreview.propTypes = {
  formData: PropTypes.shape().isRequired,
};

const BreadcrumItem = ({
  onClickBreadcrumItem,
  selected,
  breadcrumIndex,
  label,
  disabled,
}) => {
  const classes = useStyles({ selected: breadcrumIndex <= selected });

  return (
    <>
      {breadcrumIndex !== 1 && (
        <div className={classes.breadcrumLine} />
      )}
      <Button
        className={classes.breadcrumRoot}
        onClick={() => (onClickBreadcrumItem ? onClickBreadcrumItem(breadcrumIndex) : null)}
        disabled={disabled}
      >
        <div className={classes.breadcrumIndex}>
          <Typography variant="body2" component="span">{breadcrumIndex}</Typography>
        </div>
        <Typography variant="body2" component="span">
          {label}
        </Typography>
      </Button>
    </>
  );
};

BreadcrumItem.propTypes = {
  onClickBreadcrumItem: PropTypes.func,
  selected: PropTypes.number.isRequired,
  breadcrumIndex: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

BreadcrumItem.defaultProps = {
  onClickBreadcrumItem: null,
  disabled: false,
};

const CreateMediaKit = ({ mkId, mode }) => {
  const classes = useStyles();
  const auth = useAuth();
  const [, setRoute] = useRoute();
  const [isFetching, setIsFetching] = useState(false);
  const [formData, setFormData] = useState({
    personalDetails: {
      mainImg: MainImage,
      firstName: '',
      lastName: '',
      professionalTitle: '',
      aboutImg: AboutImage,
      about: '',
      contactImg: ContactImage,
      instagramHandle: '',
      websiteUrl: '',
      email: '',
      mobile: '',
      shippingAddress: '',
      availableFor: '',
    },
    socialStatistics: {
      socialStatsImg: SocialStatsImage,
      socialData: [
        {
          // started testing these
          id: 1, socialWebsite: 'Instagram', followerCount: '', socialHandle: '',
        },
        {
          id: 2, socialWebsite: 'Twitter', followerCount: '', socialHandle: '',
        },
        {
          id: 3, socialWebsite: 'Facebook', followerCount: '', socialHandle: '',
        },
        {
          id: 4, socialWebsite: 'Linkedin', followerCount: '', socialHandle: '',
        },
        {
          id: 5, socialWebsite: 'Website', followerCount: '', socialHandle: '',
        },
        {
          id: 6, socialWebsite: 'Treemail', followerCount: '', socialHandle: '',
        },
      ],
    },
    instagramAnalytics: {
      analyticsImg: InstagramAnalyticsImage,
      handle: '',
      followerCount: '',
      avgPostEngagement: '',
      audienceGender: {
        gender: 'Female',
        audienceGenderPercentage: '',
      },
      audienceAge: '',
      audienceLocations: [
        { id: 0, location: '', locationPercentage: '' },
        { id: 1, location: '', locationPercentage: '' },
        { id: 2, location: '', locationPercentage: '' },
      ],
      collaborations: [
        {
          id: 0, collabImg: Blog1Image, brandName: '', collabDesc: '',
        },
        {
          id: 1, collabImg: Blog2Image, brandName: '', collabDesc: '',
        },
        {
          id: 2, collabImg: Blog3Image, brandName: '', collabDesc: '',
        },
      ],
    },
    brandsAndTestimonials: {
      brands: [
        { id: 0, brand: '' },
        { id: 1, brand: '' },
        { id: 2, brand: '' },
        { id: 3, brand: '' },
        { id: 4, brand: '' },
        { id: 5, brand: '' },
        { id: 6, brand: '' },
        { id: 7, brand: '' },
      ],
      testimonial: {
        recommendation: '',
        recommenderName: '',
        brandName: '',
        recommenderTitle: '',
      },
    },
    services: [
      {
        id: 0, title: '', rate: '', serviceDesc: '',
      },
      {
        id: 1, title: '', rate: '', serviceDesc: '',
      },
      {
        id: 2, title: '', rate: '', serviceDesc: '',
      },
      {
        id: 3, title: '', rate: '', serviceDesc: '',
      },
      {
        id: 4, title: '', rate: '', serviceDesc: '',
      },
      {
        id: 5, title: '', rate: '', serviceDesc: '',
      },
    ],
  });
  const [finalData, setFinalData] = useState({
    personalDetails: {
      mainImg: MainImage,
      firstName: 'Rhea',
      lastName: 'Cooke',
      professionalTitle: 'Food Writer, Blogger & Mom',
      aboutImg: AboutImage,
      about: 'Hi, I’m Rhea, a British food writer of Polish descent. Following a career break from architecture, I began writing a blog at rheacooke.com and completed a Diploma in Food Journalism. I am now a mum of one and for the past 10 years, I have been writing about food for online and print publications. I have recently returned to law, but I hope that some of the recipes here will continue to be of relevance to you.\nMy cookbook Wild Flowers and Rhea, Top Polish Recipes, was published by Ullasis Books (UK) and by Galaxy Books (USA) in 2017.\nWriting books gave me the wonderful opportunity of drawing upon both my Polish heritage and my trips to Poland. I hope you’ll love coming on this journey with me too.',
      contactImg: ContactImage,
      instagramHandle: 'rheacooke',
      websiteUrl: 'www.rheacooke.com',
      email: 'rhea@rheacooke.com',
      mobile: '+1 999 191 911',
      shippingAddress: 'London UK P/O Box - South Street, 901',
      availableFor: 'Food Stylist\nRecepie Consultations\nSpeaking Events\nAmbassadorship\nSocial Media Content & Collaborations',
    },
    socialStatistics: {
      socialStatsImg: SocialStatsImage,
      socialData: [
        {
          id: 0, socialWebsite: 'Instagram', followerCount: '275K', socialHandle: 'rheacooke',
        },
        {
          id: 1, socialWebsite: 'Twitter', followerCount: '15K', socialHandle: 'rheacooke',
        },
        {
          id: 2, socialWebsite: 'Facebook', followerCount: '28K', socialHandle: 'rheacooke',
        },
        {
          id: 3, socialWebsite: 'Linkedin', followerCount: '1.2m', socialHandle: 'rheacooke',
        },
        {
          id: 4, socialWebsite: 'Website', followerCount: '6m', socialHandle: 'rheacooke',
        },
        {
          id: 5, socialWebsite: 'Treemail', followerCount: '20k', socialHandle: 'rheacooke',
        },
      ],
    },
    instagramAnalytics: {
      analyticsImg: InstagramAnalyticsImage,
      handle: 'rheacooke',
      followerCount: '275K',
      avgPostEngagement: '13',
      audienceGender: {
        gender: 'Female',
        audienceGenderPercentage: '78',
      },
      audienceAge: '25-35',
      audienceLocations: [
        { id: 0, location: 'USA', locationPercentage: '39.5' },
        { id: 1, location: 'UK', locationPercentage: '25.6' },
        { id: 2, location: 'Canada', locationPercentage: '16.2' },
      ],
      collaborations: [
        {
          id: 0, collabImg: Blog1Image, brandName: 'Premier Foods', collabDesc: 'Collaboration project to promote Premier’ new line of hand-rosted coffee  and sweet confectionaries',
        },
        {
          id: 1, collabImg: Blog2Image, brandName: 'American Heritage', collabDesc: 'Instagram campaign to create a new recepie using American Heritage Chocolates',
        },
        {
          id: 2, collabImg: Blog3Image, brandName: 'Branston', collabDesc: 'An Instagram campaign to promote Branston’s new collection of stoneware cookware',
        },
      ],
    },
    brandsAndTestimonials: {
      brands: [
        { id: 0, brand: 'Cadbury' },
        { id: 1, brand: 'Diageo' },
        { id: 2, brand: 'Premier Foods' },
        { id: 3, brand: 'Branston' },
        { id: 4, brand: 'Cathedral City Cheddar' },
        { id: 5, brand: 'Marmite' },
        { id: 6, brand: 'Weetabix' },
        { id: 7, brand: 'Penguin Biscuits' },
      ],
      testimonial: {
        recommendation: 'What your food business needs is more Rhea, and less B.S. She’s a pioneer for making the best recepies out of any food material given to her.',
        recommenderName: 'Charles James',
        brandName: 'Threads Brisket',
        recommenderTitle: 'CEO',
      },
    },
    services: [
      {
        id: 0, title: 'Instagram Sponsored Posts', rate: '$200-$500', serviceDesc: '- High quality, product images for your brand\n- Multiple posts over a period of time\n- Post promotion to email subscribers\n',
      },
      {
        id: 1, title: 'Brand Takeover', rate: '$500-$1000', serviceDesc: '- Managing the social space of the brand\n- Posting high quality content\n- Actively interacting with the brand users\n- Going live on Instagram from time to time',
      },
      {
        id: 2, title: 'Youtube Sponsored Video', rate: '$1.2k-$5k', serviceDesc: '- Week long promotion in between daily videos\n- Multiple posts over a period of time\n- 1 Review video, with brand’s product',
      },
      {
        id: 3, title: 'Guest for Events', rate: '$800k-$1.5k', serviceDesc: '- Appearance for Up to 3 hours\n- Photo opportunity to the visitors\n- Event related Blogs and posts',
      },
      {
        id: 4, title: 'Website Advertising', rate: '$400-$600', serviceDesc: '- One week/ One month banner advertisement\n- Blog article review for brand product',
      },
      {
        id: 5, title: 'Other Services', rate: '$100 - $5k', serviceDesc: '- Custom requirements could be discussed ove email',
      },
    ],
  });
  const [breadcrumOrder, setBreadcrumOrder] = useState(breadcrumOrderInit);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [highlighted, setHighlighted] = useState('');
  const [createId, setCreateId] = useState(-1);

  const { createNotification } = useNotification();

  const onClickBreadcrumItem = (index) => {
    if (breadcrumOrder[index].unlocked) {
      setSelectedIndex(parseInt(index, 10));
    }
  };

  const covertToFinalData = (data) => {
    const dataTemp = {};

    const personalDetailsTemp = {};
    personalDetailsTemp.mainImg = data.personal_details.main_page_image;
    personalDetailsTemp.firstName = data.personal_details.first_name;
    personalDetailsTemp.lastName = data.personal_details.last_name;
    personalDetailsTemp.professionalTitle = data.personal_details.professional_title;
    personalDetailsTemp.aboutImg = data.personal_details.about_page_image;
    personalDetailsTemp.about = data.personal_details.about_me;
    personalDetailsTemp.contactImg = data.personal_details.contact_page_image;
    personalDetailsTemp.instagramHandle = data.personal_details.instagram_handle;
    personalDetailsTemp.websiteUrl = data.personal_details.website_url;
    personalDetailsTemp.email = data.personal_details.email;
    personalDetailsTemp.mobile = data.personal_details.mobile_number;
    personalDetailsTemp.shippingAddress = data.personal_details.shipping_address;
    personalDetailsTemp.availableFor = data.personal_details.available_for;
    dataTemp.personalDetails = personalDetailsTemp;

    const socialStatisticsTemp = {};
    const socialDataTemp = [];
    data.social_statistics.stats.forEach((item) => {
      const itemTemp = {};
      itemTemp.id = item.id;
      itemTemp.followerCount = item.number_of_followers;
      itemTemp.socialWebsite = item.social_website;
      itemTemp.socialHandle = item.social_website_handle;
      socialDataTemp.push(itemTemp);
    });
    socialStatisticsTemp.socialStatsImg = data.social_statistics.social_stats_image;
    socialStatisticsTemp.socialData = socialDataTemp;
    dataTemp.socialStatistics = socialStatisticsTemp;

    const instagramAnalyticsTemp = {};
    instagramAnalyticsTemp.analyticsImg = data.instagram_analytics_image;
    instagramAnalyticsTemp.handle = data.instagram_analytics.instagram_handle_analytics;
    instagramAnalyticsTemp.followerCount = data.instagram_analytics.number_of_followers;
    instagramAnalyticsTemp.avgPostEngagement = data.instagram_analytics.avg_post_engagement;
    const audienceGenderTemp = {};
    audienceGenderTemp.gender = data.instagram_analytics.prominant_gender;
    audienceGenderTemp.audienceGenderPercentage = data.instagram_analytics.percentage_breakup;
    instagramAnalyticsTemp.audienceGender = audienceGenderTemp;
    instagramAnalyticsTemp.audienceAge = data.instagram_analytics.prominant_age_group;
    const audienceLocationsTemp = [];
    const loc1 = {};
    loc1.id = 0;
    loc1.location = data.instagram_analytics.location_one;
    loc1.locationPercentage = data.instagram_analytics.location_one_perc;
    const loc2 = {};
    loc2.id = 1;
    loc2.location = data.instagram_analytics.location_two;
    loc2.locationPercentage = data.instagram_analytics.location_two_perc;
    const loc3 = {};
    loc3.id = 2;
    loc3.location = data.instagram_analytics.location_three;
    loc3.locationPercentage = data.instagram_analytics.location_three_perc;
    audienceLocationsTemp.push(loc1);
    audienceLocationsTemp.push(loc2);
    audienceLocationsTemp.push(loc3);
    instagramAnalyticsTemp.audienceLocations = audienceLocationsTemp;
    const collaborationsTemp = [];
    data.instagram_analytics.instagram_collaborations.forEach((item) => {
      const itemTemp = {};
      itemTemp.id = item.id;
      itemTemp.collabImg = item.collaboration_image;
      itemTemp.brandName = item.brand_name;
      itemTemp.collabDesc = item.about;
      collaborationsTemp.push(itemTemp);
    });
    instagramAnalyticsTemp.collaborations = collaborationsTemp;
    dataTemp.instagramAnalytics = instagramAnalyticsTemp;

    const brandsAndTestimonialsTemp = {};
    const brandsTemp = [];
    data.brands_and_testimonials.brand_names.forEach((item) => {
      const itemTemp = {};
      itemTemp.id = item.id;
      itemTemp.brand = item.brand;
      brandsTemp.push(itemTemp);
    });
    brandsAndTestimonialsTemp.brands = brandsTemp;
    const testimonialTemp = {};
    testimonialTemp.recommendation = data.brands_and_testimonials.testmonial;
    testimonialTemp.recommenderName = data.brands_and_testimonials.recommenders_name;
    testimonialTemp.brandName = data.brands_and_testimonials.recommenders_company;
    testimonialTemp.recommenderTitle = data.brands_and_testimonials.recommenders_title;
    brandsAndTestimonialsTemp.testimonial = testimonialTemp;
    dataTemp.brandsAndTestimonials = brandsAndTestimonialsTemp;

    const servicesTemp = [];
    data.rate_cards.forEach((item) => {
      const itemTemp = {};
      itemTemp.id = item.id;
      itemTemp.title = item.service_name;
      itemTemp.rate = item.service_charge;
      itemTemp.serviceDesc = item.service_description;
      servicesTemp.push(itemTemp);
    });
    dataTemp.services = servicesTemp;

    return dataTemp;
  };

  const convertToFormData = (fd) => {
    const dataTemp = {};

    const personalDetailsTemp = {};
    personalDetailsTemp.mainImg = fd.personalDetails.mainImg;
    personalDetailsTemp.firstName = fd.personalDetails.firstName === defaultFormData.personalDetails.firstName ? '' : fd.personalDetails.firstName;
    personalDetailsTemp.lastName = fd.personalDetails.lastName === defaultFormData.personalDetails.lastName ? '' : fd.personalDetails.lastName;
    personalDetailsTemp.professionalTitle = fd.personalDetails.professionalTitle === defaultFormData.personalDetails.professionalTitle ? '' : fd.personalDetails.professionalTitle;
    personalDetailsTemp.aboutImg = fd.personalDetails.aboutImg;
    personalDetailsTemp.about = fd.personalDetails.about === defaultFormData.personalDetails.about ? '' : fd.personalDetails.about;
    personalDetailsTemp.contactImg = fd.personalDetails.contactImg;
    personalDetailsTemp.instagramHandle = fd.personalDetails.instagramHandle === defaultFormData.personalDetails.instagramHandle ? '' : fd.personalDetails.instagramHandle;
    personalDetailsTemp.websiteUrl = fd.personalDetails.websiteUrl === defaultFormData.personalDetails.websiteUrl ? '' : fd.personalDetails.websiteUrl;
    personalDetailsTemp.email = fd.personalDetails.email === defaultFormData.personalDetails.email ? '' : fd.personalDetails.email;
    personalDetailsTemp.mobile = fd.personalDetails.mobile === defaultFormData.personalDetails.mobile ? '' : fd.personalDetails.mobile;
    personalDetailsTemp.shippingAddress = fd.personalDetails.shippingAddress === defaultFormData.personalDetails.shippingAddress ? '' : fd.personalDetails.shippingAddress;
    personalDetailsTemp.availableFor = fd.personalDetails.availableFor === defaultFormData.personalDetails.availableFor ? '' : fd.personalDetails.availableFor;
    dataTemp.personalDetails = personalDetailsTemp;

    const socialStatisticsTemp = {};
    const socialDataTemp = [];
    fd.socialStatistics.socialData.forEach((item, index) => {
      const defaultTemp = defaultFormData.socialStatistics.socialData[index];
      const itemTemp = {};
      itemTemp.id = item.id;
      itemTemp.followerCount = item.followerCount === defaultTemp.followerCount ? '' : item.followerCount;
      itemTemp.socialWebsite = item.socialWebsite;
      itemTemp.socialHandle = item.socialHandle === defaultTemp.socialHandle ? '' : item.socialHandle;
      socialDataTemp.push(itemTemp);
    });
    socialStatisticsTemp.socialStatsImg = fd.socialStatistics.socialStatsImg;
    socialStatisticsTemp.socialData = socialDataTemp;
    dataTemp.socialStatistics = socialStatisticsTemp;

    dataTemp.socialStatsIds = fd.socialStatistics.socialData.map((item) => item.id);

    const instagramAnalyticsTemp = {};
    instagramAnalyticsTemp.analyticsImg = fd.instagramAnalytics.analyticsImg;
    instagramAnalyticsTemp.handle = fd.instagramAnalytics.handle === defaultFormData.instagramAnalytics.handle ? '' : fd.instagramAnalytics.handle;
    instagramAnalyticsTemp.followerCount = fd.instagramAnalytics.followerCount === defaultFormData.instagramAnalytics.followerCount ? '' : fd.instagramAnalytics.followerCount;
    instagramAnalyticsTemp.avgPostEngagement = fd.instagramAnalytics.avgPostEngagement === defaultFormData.instagramAnalytics.avgPostEngagement ? '' : fd.instagramAnalytics.avgPostEngagement;
    const audienceGenderTemp = {};
    audienceGenderTemp.gender = fd.instagramAnalytics.audienceGender.gender === defaultFormData.instagramAnalytics.audienceGender.gender ? '' : fd.instagramAnalytics.audienceGender.gender;
    audienceGenderTemp.audienceGenderPercentage = fd.instagramAnalytics.audienceGender.audienceGenderPercentage === defaultFormData.instagramAnalytics.audienceGender.audienceGenderPercentage ? '' : fd.instagramAnalytics.audienceGender.audienceGenderPercentage;
    instagramAnalyticsTemp.audienceGender = audienceGenderTemp;
    instagramAnalyticsTemp.audienceAge = fd.instagramAnalytics.audienceAge === defaultFormData.instagramAnalytics.audienceAge ? '' : fd.instagramAnalytics.audienceAge;
    const audienceLocationsTemp = [];
    fd.instagramAnalytics.audienceLocations.forEach((item, index) => {
      const defaultTemp = defaultFormData.instagramAnalytics.audienceLocations[index];
      const itemTemp = {};
      itemTemp.id = index;
      itemTemp.location = item.location === defaultTemp.location ? '' : item.location;
      itemTemp.locationPercentage = item.locationPercentage === defaultTemp.locationPercentage ? '' : item.locationPercentage;
      audienceLocationsTemp.push(itemTemp);
    });
    instagramAnalyticsTemp.audienceLocations = audienceLocationsTemp;
    const collaborationsTemp = [];
    fd.instagramAnalytics.collaborations.forEach((item, index) => {
      const defaultTemp = defaultFormData.instagramAnalytics.collaborations[index];
      const itemTemp = {};
      itemTemp.id = item.id;
      itemTemp.collabImg = item.collabImg;
      itemTemp.brandName = item.brandName === defaultTemp.brandName ? '' : item.brandName;
      itemTemp.collabDesc = item.collabDesc === defaultTemp.collabDesc ? '' : item.collabDesc;
      collaborationsTemp.push(itemTemp);
    });
    instagramAnalyticsTemp.collaborations = collaborationsTemp;
    dataTemp.instagramAnalytics = instagramAnalyticsTemp;

    dataTemp.collaborationIds = fd.instagramAnalytics.collaborations.map((item) => item.id);

    const brandsAndTestimonialsTemp = {};
    const brandsTemp = [];
    fd.brandsAndTestimonials.brands.forEach((item, index) => {
      const defaultTemp = defaultFormData.brandsAndTestimonials.brands[index];
      const itemTemp = {};
      itemTemp.id = item.id;
      itemTemp.brand = item.brand === defaultTemp.brand ? '' : item.brand;
      brandsTemp.push(itemTemp);
    });
    brandsAndTestimonialsTemp.brands = brandsTemp;
    const testimonialTemp = {};
    const fdTest = fd.brandsAndTestimonials.testimonial;
    testimonialTemp.recommendation = fdTest.recommendation === defaultFormData.brandsAndTestimonials.testimonial.recommendation ? '' : fdTest.recommendation;
    testimonialTemp.recommenderName = fdTest.recommenderName === defaultFormData.brandsAndTestimonials.testimonial.recommenderName ? '' : fdTest.recommenderName;
    testimonialTemp.brandName = fdTest.brandName === defaultFormData.brandsAndTestimonials.testimonial.brandName ? '' : fdTest.brandName;
    testimonialTemp.recommenderTitle = fdTest.recommenderTitle === defaultFormData.brandsAndTestimonials.testimonial.recommenderTitle ? '' : fdTest.recommenderTitle;
    brandsAndTestimonialsTemp.testimonial = testimonialTemp;
    dataTemp.brandsAndTestimonials = brandsAndTestimonialsTemp;

    dataTemp.brandIds = fd.brandsAndTestimonials.brands.map((item) => item.id);

    const servicesTemp = [];
    fd.services.forEach((item, index) => {
      const defaultTemp = defaultFormData.services[index];
      const itemTemp = {};
      itemTemp.id = item.id;
      itemTemp.title = item.title === defaultTemp.title ? '' : item.title;
      itemTemp.rate = item.rate === defaultTemp.rate ? '' : item.rate;
      itemTemp.serviceDesc = item.serviceDesc === defaultTemp.serviceDesc ? '' : item.serviceDesc;
      servicesTemp.push(itemTemp);
    });
    dataTemp.services = servicesTemp;

    dataTemp.serviceIds = fd.services.map((item) => item.id);

    return dataTemp;
  };

  const fetchData = useCallback(async () => {
    if (mode === 'edit' && mkId) {
      try {
        setIsFetching(true);
        const response = await API({
          url: `/account/${auth.account.id}/media-kit/${mkId}/`,
          method: 'get',
        });

        if (response.data && response.data.data) {
          const finalDataTemp = covertToFinalData(response.data.data);
          const formDataTemp = convertToFormData(finalDataTemp);
          setFinalData(finalDataTemp);
          setFormData(formDataTemp);
          setIsFetching(false);
        }
      } catch (_) {
        setIsFetching(false);
        // do nothing
      }
    }
  }, [auth, mkId, mode]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const sendMediaKitData = async () => {
    const data = {
      which_template: 'default',
      main_page_image: finalData.personalDetails.mainImg,
      first_name: finalData.personalDetails.firstName,
      last_name: finalData.personalDetails.lastName,
      professional_title: finalData.personalDetails.professionalTitle,
      about_page_image: finalData.personalDetails.aboutImg,
      about_me: finalData.personalDetails.about,
      contact_page_image: finalData.personalDetails.contactImg,
      instagram_handle: finalData.personalDetails.instagramHandle,
      website_url: finalData.personalDetails.websiteUrl,
      email: finalData.personalDetails.email,
      mobile_number: finalData.personalDetails.mobile,
      shipping_address: finalData.personalDetails.shippingAddress,
      available_for: finalData.personalDetails.availableFor,

      social_stats_image: finalData.socialStatistics.socialStatsImg,
      media_kit_stats: finalData.socialStatistics.socialData.map((item) => ({
        id: item.id,
        social_website: item.socialWebsite,
        number_of_followers: item.followerCount,
        handle: item.socialHandle,
      })),

      instagram_analytics_image: finalData.instagramAnalytics.analyticsImg,
      instagram_handle_analytics: finalData.instagramAnalytics.handle,
      number_of_followers: finalData.instagramAnalytics.followerCount,
      avg_post_engagement: finalData.instagramAnalytics.avgPostEngagement,
      prominant_gender: finalData.instagramAnalytics.audienceGender.gender,
      percentage_breakup: finalData.instagramAnalytics.audienceGender.audienceGenderPercentage,
      prominant_age_group: finalData.instagramAnalytics.audienceAge,
      location_one: finalData.instagramAnalytics.audienceLocations[0].location,
      location_one_percentage: parseFloat(finalData.instagramAnalytics.audienceLocations[0].locationPercentage),
      location_two: finalData.instagramAnalytics.audienceLocations[1].location,
      location_two_percentage: parseFloat(finalData.instagramAnalytics.audienceLocations[1].locationPercentage),
      location_three: finalData.instagramAnalytics.audienceLocations[2].location,
      location_three_percentage: parseFloat(finalData.instagramAnalytics.audienceLocations[2].locationPercentage),

      instagram_collaborations: finalData.instagramAnalytics.collaborations.map((item) => ({
        id: item.id,
        collaboration_image: item.collabImg,
        brand_name: item.brandName,
        about: item.collabDesc,
      })),

      testmonial: finalData.brandsAndTestimonials.testimonial.recommendation,
      brand_names: JSON.stringify(finalData.brandsAndTestimonials.brands.map((item) => ({
        id: item.id,
        brand: item.brand,
      }))),
      recommenders_name: finalData.brandsAndTestimonials.testimonial.recommenderName,
      recommenders_company: finalData.brandsAndTestimonials.testimonial.brandName,
      recommenders_title: finalData.brandsAndTestimonials.testimonial.recommenderTitle,

      rate_cards: finalData.services.map((item) => ({
        id: item.id,
        service_name: item.title,
        service_charge: item.rate,
        currency: 'not needed yet',
        service_description: item.serviceDesc,
      })),
    };

    let url = '';
    let method = 'post';
    if (mode === 'edit') {
      url = `/account/${auth.account.id}/media-kit/${mkId}/`;
      method = 'patch';
    } else if (mode === 'create') {
      if (createId === -1) {
        url = `/account/${auth.account.id}/media-kit/`;
      } else {
        url = `/account/${auth.account.id}/media-kit/${createId}/`;
        method = 'patch';
      }
    }

    setIsFetching(true);

    const response = await API({
      url,
      method,
      data,
    });

    if (response.status === 200) {
      createNotification('success', 'Saved');
      setCreateId(response.data.data.id);

      fetchData();
      // added this to test
      if (createId === -1) {
        setRoute({ pathname: `/media-kit/design/edit/${response.data.data.id}/` });
      }
    } else if (response.status === 400) {
      createNotification('error', 'Permission not allowed');
    } else {
      createNotification('error', 'Not Saved');
    }
    setIsFetching(false);
  };

  const onNextPageClick = async () => {
    await sendMediaKitData();
    if (selectedIndex !== 5) {
      const breadcrumOrderTemp = { ...breadcrumOrder };
      breadcrumOrderTemp[selectedIndex + 1].unlocked = true;
      setBreadcrumOrder(breadcrumOrderTemp);
    }
    setSelectedIndex(selectedIndex + 1);
  };

  const exitCreator = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Some of the content you changed so far might not get saved!')) {
      setRoute({ pathname: '/media-kit' });
    }
  };

  return (
    <>
      {!auth.hasPermission('media_kit.create') && <DisablePage />}
      <PageBase showLoadingBar={isFetching}>
        {selectedIndex === 6 ? (
          <div className={classes.root}>
            <div className={classes.header}>
              <Button
                size="small"
                color="primary"
                onClick={() => setSelectedIndex(1)}
              >
                &lt; Return Back To Media Kit Creator
              </Button>
              <div className={classes.nav}>
                <Typography variant="h5" component="h5">Media Kit Preview</Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    className={classes.invertedBtn}
                    color="primary"
                    size="small"
                    onClick={() => setSelectedIndex(1)}
                  >
                    Continue Editting
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ padding: '7px 15px' }}
                    size="small"
                    startIcon={<GetAppIcon />}
                  >
                    <PDFDownloadLink
                      document={<MediaKitPdf formData={finalData} />}
                      fileName="Media Kit.pdf"
                      style={{ color: '#fff', textDecoration: 'none' }}
                    >
                      {({ loading }) => (loading ? 'Loading document...' : 'Download PDF')}
                    </PDFDownloadLink>
                  </Button>

                </div>
              </div>
            </div>
            <PdfPreview formData={finalData} />
          </div>
        ) : (
          <div className={classes.root}>
            <div className={classes.header}>
              <Button
                size="small"
                color="primary"
                // component={Link}
                // to="/media-kit"
                onClick={exitCreator}
              >
                &lt; Exit Creator
              </Button>
              <div className={classes.nav}>
                <Typography variant="h5" component="h5">Media Kit Creator</Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {Object.keys(breadcrumOrder).map((key) => (
                    <BreadcrumItem
                      onClickBreadcrumItem={onClickBreadcrumItem}
                      breadcrumIndex={parseInt(key, 10)}
                      label={breadcrumOrder[key].name}
                      selected={selectedIndex}
                      disabled={!breadcrumOrder[key].unlocked}
                      key={key}
                    />
                  ))}
                </div>
              </div>
            </div>

            <div className={classes.container}>
              <div className={classes.formWrapper} id="formWrapper">
                {selectedIndex === 1 && (
                  <>
                    <MainPageForm
                      formData={formData}
                      setFormData={setFormData}
                      setHighlighted={setHighlighted}
                      finalData={finalData}
                      setFinalData={setFinalData}
                    />
                    <AboutPageForm
                      formData={formData}
                      setFormData={setFormData}
                      setHighlighted={setHighlighted}
                      finalData={finalData}
                      setFinalData={setFinalData}
                    />
                    <ContactPageForm
                      formData={formData}
                      setFormData={setFormData}
                      setHighlighted={setHighlighted}
                      finalData={finalData}
                      setFinalData={setFinalData}
                    />
                  </>
                )}
                {selectedIndex === 2 && (
                  <SocialStatisticsForm
                    formData={formData}
                    setFormData={setFormData}
                    setHighlighted={setHighlighted}
                    finalData={finalData}
                    setFinalData={setFinalData}
                    mkId={mkId}
                    setIsFetching={setIsFetching}
                  />
                )}
                {selectedIndex === 3 && (
                  <>
                    <InstagramAnalyticsForm
                      formData={formData}
                      setFormData={setFormData}
                      setHighlighted={setHighlighted}
                      finalData={finalData}
                      setFinalData={setFinalData}
                    />
                    <InsatgramAudienceForm
                      formData={formData}
                      setFormData={setFormData}
                      setHighlighted={setHighlighted}
                      finalData={finalData}
                      setFinalData={setFinalData}
                    />
                    <InstagramAudienceLocationForm
                      formData={formData}
                      setFormData={setFormData}
                      setHighlighted={setHighlighted}
                      finalData={finalData}
                      setFinalData={setFinalData}
                    />
                    <InstagramCollaborationsForm
                      formData={formData}
                      setFormData={setFormData}
                      setHighlighted={setHighlighted}
                      finalData={finalData}
                      setFinalData={setFinalData}
                      createId={createId}
                      mkId={mkId}
                    />
                  </>
                )}
                {selectedIndex === 4 && (
                  <>
                    <BrandsForm
                      formData={formData}
                      setFormData={setFormData}
                      setHighlighted={setHighlighted}
                      finalData={finalData}
                      setFinalData={setFinalData}
                    />
                    <TestimonialForm
                      formData={formData}
                      setFormData={setFormData}
                      setHighlighted={setHighlighted}
                      finalData={finalData}
                      setFinalData={setFinalData}
                    />
                  </>
                )}
                {selectedIndex === 5 && (
                  <ServicesForm
                    formData={formData}
                    setFormData={setFormData}
                    setHighlighted={setHighlighted}
                    finalData={finalData}
                    setFinalData={setFinalData}
                    mkId={mkId}
                    createId={createId}

                  />
                )}
                <div className={classes.footer}>
                  <Button
                    className={classes.backButton}
                    size="small"
                    disabled={selectedIndex === 1}
                    onClick={() => setSelectedIndex(selectedIndex - 1)}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ padding: '11px 50px' }}
                    onClick={() => onNextPageClick()}
                  >
                    {selectedIndex >= 5 ? 'Finish & Preview' : `Save & Proceed: ${breadcrumOrder[selectedIndex + 1].name}`}
                  </Button>
                </div>
              </div>
              <div className={classes.formWrapper} style={{ padding: 20 }}>
                <Typography variant="body1" component="p" className={classes.prevTitle}>Media Kit Preview</Typography>
                {selectedIndex === 1 && (
                  <>
                    <Page1Preview
                      formData={formData}
                      highlighted={highlighted}
                      finalData={finalData}
                    />
                    <Page2Preview
                      formData={formData}
                      highlighted={highlighted}
                      finalData={finalData}
                    />
                    <Page3Preview
                      formData={formData}
                      highlighted={highlighted}
                      finalData={finalData}
                    />
                  </>
                )}
                {selectedIndex === 2 && (
                  <Page4Preview
                    formData={formData}
                    highlighted={highlighted}
                    finalData={finalData}
                  />
                )}
                {selectedIndex === 3 && (
                  <>
                    <Page5Preview
                      formData={formData}
                      highlighted={highlighted}
                      finalData={finalData}
                    />
                    <Page6Preview
                      formData={formData}
                      highlighted={highlighted}
                      finalData={finalData}
                    />
                  </>
                )}
                {selectedIndex === 4 && (
                  <Page7Preview
                    formData={formData}
                    highlighted={highlighted}
                    finalData={finalData}
                  />
                )}
                {selectedIndex === 5 && (
                  <Page8Preview
                    formData={formData}
                    highlighted={highlighted}
                    finalData={finalData}
                  />
                )}
              </div>
            </div>
          </div>
        )}

      </PageBase>
    </>
  );
};

CreateMediaKit.propTypes = {
  mkId: PropTypes.number,
  mode: PropTypes.string,
};

CreateMediaKit.defaultProps = {
  mkId: null,
  mode: null,
};

export default CreateMediaKit;
