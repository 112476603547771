import { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';
import {
  useLocation,
  useNavigate,
} from '@reach/router';
import qs from 'qs';

const getQueryParams = (location) => {
  if (location.search) {
    return qs.parse(location.search.substring(1));
  }
  return {};
};

const toQueryString = (params, includeNullValues = true) => {
  const newParams = _.fromPairs(
    _.toPairs(params).filter(
      ([, value]) => includeNullValues || value !== null,
    ),
  );
  return _.isEmpty(newParams) ? '' : `?${qs.stringify(newParams)}`;
};

const compareParams = (a, b) => {
  if (_.isString(a) || _.isString(b)) {
    return a === b;
  }
  return _.isEqual(a, b);
};

const parseRouteFromLocation = (location) => ({
  pathname: location.pathname,
  params: getQueryParams(location),
});

const parseLocationFromRoute = (route) => `${route.pathname}${toQueryString(route.params || {}, false)}`;

const useRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [route, _setRoute] = useState(parseRouteFromLocation(location));

  const setRoute = useCallback(({ pathname, params }) => {
    const newRoute = {
      pathname: pathname || route.pathname,
      params: params || {},
    };
    if (!_.isEqual(newRoute, parseRouteFromLocation(location), compareParams)) {
      // Only push history if route is different to current
      navigate(parseLocationFromRoute(newRoute));
    }
  }, [location, navigate, route.pathname]);

  const updateRoute = useCallback(() => {
    const newRoute = parseRouteFromLocation(location);
    if (!_.isEqual(route, newRoute, compareParams)) {
      _setRoute(newRoute);
    }
  }, [location, route]);

  // Update the route when the location (browser URL path) changes
  useEffect(updateRoute, [location]);

  return [route, setRoute];
};

export default useRoute;
