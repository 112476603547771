/* eslint-disable no-use-before-define */
import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';

/**
 * Stores a single page of lists, with methods to get, clear and refresh the lists.
 */
const ListsContext = createContext({
  fetching: false,
  lists: [],
  setLists: () => {},
  clearLists: () => {},
  refreshLists: () => {},
});

const ListsStore = (props) => {
  const { children } = props;

  const setLists = (lists) => {
    setState({
      ...state,
      lists,
    });
  };

  const clearLists = () => {
    setState({
      ...state,
      lists: null,
    });
  };

  const refreshLists = async (id) => {
    setState({
      ...state,
      fetching: true,
    });
    const response = await API({
      method: 'get',
      url: `/account/${id}/list/`,
    });
    if (response.status === 200) {
      setState({
        ...state,
        lists: response.data.data.lists,
        fetching: false,
      });
    } else {
      setState({
        ...state,
        fetching: false,
      });
    }
  };

  const [state, setState] = useState({
    fetching: false,
    lists: [],
    setLists,
    clearLists,
    refreshLists,
  });

  return (
    <ListsContext.Provider value={state}>
      {children}
    </ListsContext.Provider>
  );
};

ListsStore.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ListsContext;
export { ListsStore };
