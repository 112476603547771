import React, { useEffect, useState } from 'react';

import {
  Avatar,
  ButtonBase,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CaretDownIcon from '../common/icons/CaretDown';
import { useApi, useAuth, useRoute } from '../../hooks';

const useStyles = makeStyles(() => ({
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&:hover *': {
      opacity: 0.8,
    },
  },
  buttonCaretDown: {
    width: 12,
    height: 12,
    marginRight: 8,
  },
  buttonAvatar: {
    width: 40,
    height: 40,
  },
}));

const UserProfileButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [, setRoute] = useRoute();
  const classes = useStyles();
  const auth = useAuth();
  const [{ status: logOutStatus }, postLogOut] = useApi();

  useEffect(() => {
    if (logOutStatus === 200) {
      setRoute({ pathname: '/auth/login' });
    }
  }, [logOutStatus, setRoute]);

  const handleMenuClose = (event) => {
    event.preventDefault();
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event, handler) => {
    handleMenuClose(event);
    handler();
  };

  const handleLogOut = () => {
    // setRoute({ pathname: '/auth/login' });
    postLogOut({
      method: 'post',
      url: '/auth/logout/',
    });
  };

  return (
    <div>
      <ButtonBase
        className={classes.button}
        onClick={(e) => setAnchorEl(e.target)}
      >
        <CaretDownIcon className={classes.buttonCaretDown} />
        <Avatar
          alt={auth.user ? auth.user.first_name : null}
          className={classes.buttonAvatar}
          src="#"
        />
      </ButtonBase>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={(event) => handleMenuItemClick(event, () => setRoute({ pathname: '/settings/user' }))}>
          Settings
        </MenuItem>
        <MenuItem onClick={(event) => handleMenuItemClick(event, handleLogOut)}>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserProfileButton;
