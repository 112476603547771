import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from '@reach/router';
import LockOpenIcon from '@material-ui/icons/LockOpen';

import ListSelectOrCreateField from '../common/ListSelectOrCreateField';
import ImportIcon from '../common/icons/Import';
import {
  useApi,
  useAuth,
  useNotification,
  useRoute,
} from '../../hooks';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 15,
    height: 15,
  },
  compactIcon: {
    color: theme.colors.button.primary.main,
  },
  dialogUrls: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  importButtonLabel: {
    justifyContent: 'start',
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
}));

const ImportProfilesDialog = ({ onClose }) => {
  const [urls, setUrls] = useState('');
  const [listValue, setListValue] = useState(null);
  const [listValueError, setListValueError] = useState(null);
  const classes = useStyles();
  const auth = useAuth();
  const { createNotification } = useNotification();
  const [, setRoute] = useRoute();
  const [{ isFetching: isImportFetching, isSuccess: isImportSuccess }, setImportRequest] = useApi();
  const [{ data: list, isFetching: isCreateListFetching }, setCreateListRequest] = useApi();

  useEffect(() => {
    if (isImportSuccess) {
      createNotification('success', 'Successfully imported profiles.');
      onClose();
      setRoute({ pathname: `/list/${list ? list.id : listValue.selectedList.id}` });
    }
  }, [createNotification, list, listValue, isImportSuccess, onClose, setRoute]);

  useEffect(() => {
    if (list) {
      setImportRequest({
        method: 'post',
        url: `/account/${auth.account.id}/list/${list.id}/import/`,
        data: {
          urls: urls.split(/\n/).map((url) => url.trim()),
        },
      });
    }
  }, [auth, list, setImportRequest, urls]);

  const handleSubmit = () => {
    setListValueError(null);
    if (listValue && listValue.mode === 'create' && listValue.newListName) {
      // Create a new list for these imports
      const accountId = auth.account.id;
      setCreateListRequest({
        method: 'post',
        url: `/account/${auth.account.id}/list/`,
        data: {
          account_id: accountId,
          name: listValue.newListName,
        },
      });
    } else if (listValue && listValue.mode === 'select' && listValue.selectedList) {
      // Import into existing list
      setImportRequest({
        method: 'post',
        url: `/account/${auth.account.id}/list/${listValue.selectedList.id}/import/`,
        data: {
          urls: urls.split(/\n/).map((url) => url.trim()),
        },
      });
    } else {
      setListValueError('Must select an existing list or create a new list with a name.');
    }
  };

  return (
    <Dialog
      onClose={onClose}
      open
    >
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <DialogTitle>
        Import profiles
      </DialogTitle>
      <DialogContent>
        <div className={classes.dialogDescription}>
          <Typography variant="body2">
            Paste the URLs of the profiles you want to import from the channels we currently support.
            Import multiple profiles at once with a list or one at a time:
          </Typography>
        </div>
        <div className={classes.dialogUrls}>
          <TextField
            fullWidth
            label="URLs"
            multiline
            onChange={(e) => setUrls(e.target.value)}
            placeholder={'https://www.instagram.com/scrunchforbrands\nhttps://twitter.com/scrunch'}
            required
            rows={10}
            type="textarea"
            value={urls}
          />
        </div>
        <ListSelectOrCreateField
          error={Boolean(listValueError)}
          helperText={listValueError}
          onChange={setListValue}
        />
      </DialogContent>
      <DialogActions>
        {auth.hasPermission('import.restrict') ? (
          <Button
            color="primary"
            component={Link}
            startIcon={<LockOpenIcon />}
            to="/settings/plans"
            variant="contained"
          >
            Upgrade Plan
          </Button>
        ) : (
          <Button
            color="primary"
            disabled={isImportFetching || isCreateListFetching}
            onClick={handleSubmit}
            variant="contained"
          >
            Import
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

ImportProfilesDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const ImportProfilesButton = ({ compact, label, textButton }) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const classes = useStyles();

  let propsLabel = label;
  if (!label) {
    propsLabel = 'Import Profiles';
  }

  return (
    <>
      {compact && (
        <Tooltip arrow placement="right" title="Import profiles">
          <IconButton className={classes.button} onClick={() => setDialogIsOpen(true)}>
            <ImportIcon className={`${classes.icon} ${classes.compactIcon}`} />
          </IconButton>
        </Tooltip>
      )}
      {!compact && !textButton && (
        <Button
          classes={{
            label: classes.importButtonLabel,
          }}
          color="primary"
          fullWidth
          onClick={() => setDialogIsOpen(true)}
          startIcon={<ImportIcon className={classes.icon} />}
          variant="contained"
        >
          {propsLabel}
        </Button>
      )}
      {!compact && textButton && (
        <Button
          color="primary"
          onClick={() => setDialogIsOpen(true)}
          style={{ padding: 0, minWidth: 0 }}
        >
          <b>{propsLabel}</b>
        </Button>
      )}
      {dialogIsOpen && (
        <ImportProfilesDialog
          onClose={() => setDialogIsOpen(false)}
        />
      )}
    </>
  );
};

ImportProfilesButton.propTypes = {
  compact: PropTypes.bool.isRequired,
  label: PropTypes.string,
  textButton: PropTypes.bool,
};

ImportProfilesButton.defaultProps = {
  label: '',
  textButton: false,
};

export default ImportProfilesButton;
