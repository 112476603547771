import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { colors } from '../definitions';
import { previewStyles } from '../form/styles';

const useStyles = makeStyles(() => ({
  highlighted: {
    border: `2px solid ${colors.PRIMARY}`,
  },
  root: {
    backgroundColor: colors.BG_LIGHT,
    display: 'flex',
    height: 250,
    marginTop: 20,
  },
  col1: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.BG_DARK,
    padding: 10,
  },
  main1: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  brandNames: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: ({ column }) => (column ? 'column' : 'row'),
  },
  brand: {
    paddingTop: 5,
    minWidth: '50%',
  },
  col2: {
    flex: 1.5,
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
  },
  main2: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

const Page7Preview = ({ finalData, highlighted }) => {
  let column = false;
  finalData.brandsAndTestimonials.brands.forEach(({ brand }) => {
    if (brand.length > 15) {
      column = true;
    }
  });
  const classes = useStyles({ column });
  const textStyles = previewStyles();

  return (
    <div className={classes.root}>
      <div className={classes.col1}>
        <div className={textStyles.h5}>Brands &amp; Testimonials</div>
        <div className={classes.main1}>
          <div className={textStyles.h4} style={{ textDecoration: 'underline' }}>
            Brand Collaborations With
          </div>
          <div className={`${classes.brandNames} ${highlighted === 'brands' ? classes.highlighted : ''}`}>
            {finalData.brandsAndTestimonials.brands.map((brand) => (
              <div
                className={`${textStyles.bodyLarge} ${classes.brand}`}
                key={brand.id}
              >
                {brand.brand}
              </div>
            ))}
          </div>
        </div>
        <div className={textStyles.body}>{finalData.personalDetails.websiteUrl}</div>
      </div>
      <div className={classes.col2}>
        <div className={textStyles.body} style={{ padding: 5, textAlign: 'right' }}>
          {finalData.personalDetails.firstName}
          &nbsp;
          {finalData.personalDetails.lastName}
        </div>
        <div className={classes.main2}>
          <div
            className={`${textStyles.h3} ${highlighted === 'testimonial.recommendation' ? classes.highlighted : ''}`}
            style={{ marginBottom: 5 }}
          >
            &quot;
            {finalData.brandsAndTestimonials.testimonial.recommendation}
            &quot;
          </div>
          <div className={textStyles.h5}>
            -
            <span className={highlighted === 'testimonial.recommenderName' ? classes.highlighted : ''}>
              {finalData.brandsAndTestimonials.testimonial.recommenderName}
              ,&nbsp;
            </span>
            <span className={highlighted === 'testimonial.recommenderTitle' ? classes.highlighted : ''}>
              {finalData.brandsAndTestimonials.testimonial.recommenderTitle}
            </span>
            &nbsp;at&nbsp;
            <span className={highlighted === 'testimonial.brandName' ? classes.highlighted : ''}>
              {finalData.brandsAndTestimonials.testimonial.brandName}
            </span>
          </div>
        </div>
        <div className={textStyles.body} style={{ padding: 5, textAlign: 'right' }}>
          {finalData.personalDetails.instagramHandle ? `@${finalData.personalDetails.instagramHandle}` : ''}
        </div>
      </div>
    </div>
  );
};

Page7Preview.propTypes = {
  finalData: PropTypes.shape().isRequired,
  highlighted: PropTypes.string,
};

Page7Preview.defaultProps = {
  highlighted: '',
};

export default Page7Preview;
