import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Typography,
  TextField,
  DialogActions,
  DialogContent,
  Select,
  MenuItem,
  Radio,
  ButtonBase,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import AccessInfoTooltip from '../common/AccessInfoTooltip';
import DeleteAccountButton from '../account/DeleteAccountButton';
import ScrDialog from '../common/ScrDialog';
import { capitalize, isValidEmail } from '../../utils/string';
import {
  useApi,
  useAuth,
  useNotification,
  useContentManager,
} from '../../hooks';

const useStyles = makeStyles((theme) => ({
  // edit
  editTitle: {
    marginBottom: '0',
  },
  editContext: {
    padding: '0 5rem',
    textAlign: 'center',
    marginBottom: '3rem',
  },
  editContent: {
    padding: '0 5rem',
  },
  nameDiv: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  firstName: {
    width: '48%',
  },
  lastName: {
    width: '48%',
  },
  editClose: {
    position: 'absolute',
    right: '2rem',
    top: '2rem',
  },
  infoIcon: {
    float: 'right',
    color: theme.colors.system.grey5,
    translate: 'transformY(-10px)',
  },
  editSelect: {
    width: '100%',
    marginBottom: '2rem',
  },
  noMembers: {
    color: theme.colors.system.grey6,
  },
  descBox: {
    backgroundColor: theme.colors.system.grey1,
    borderRadius: '4px',
    padding: '0.5rem 0',
    marginTop: '-1.25rem',
  },
  list: {
    margin: '1rem 0',
  },
  accessList: {
    margin: '1rem',
  },
  contactSupport: {
    color: theme.colors.primary.main,
    fontSize: '0.85rem',
  },
  radioCont: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  select: {
    width: '100%',
    margin: '10px 0 0 0',
  },
  selectAndButton: {
    display: ' flex',
    justifyContent: 'space-between',
    margin: '10px 0 0 0',
  },
  brandAccessCont: {
    marginTop: '20px',
  },
  profileSelect: {
    width: '75%',
  },
  profileItems: {
    borderBottom: `1px solid ${theme.colors.system.grey3}`,
    padding: '15px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  profileDelete: {
    color: '#CB1A08',
    fontSize: '18px',
  },
}));

const accountRoles = [
  {
    title: 'Administrator & Owner',
    accesses: [
      'Manages Billing',
      'Manages Agency Settings',
      'Manages All the Agency Lists',
      'Manages the Complete Content Planning System',
      'Manages All Users',
      'Inherits All Capabilities of the Manager Role',
    ],
  },
  {
    title: 'Administrator',
    accesses: [
      'Manages Billing',
      'Manages Agency Settings',
      'Manages All the Agency Lists',
      'Manages the Complete Content Planning System',
      'Manages All Users',
      'Inherits All Capabilities of the Manager Role',
    ],
  },
  {
    title: 'Manager',
    accesses: [
      'View access to agency settings',
      // "Manages clients",
      // "Manages brands in content planning system",
      'Access to agency lists',
    ],
  },
  {
    title: 'Client',
    accesses: ["Viewing Brand's Profile", "Reviewing Brand's Posts"],
  },
];

const ContentApprovalAddNew = (props) => {
  const {
    addNewToBrand, handleChangeRadio, members, selectedMember,
  } = props;
  const classes = useStyles();
  const content = useContentManager();
  const membersFiltered = members.filter(
    (mem) => mem.agency_role === 'client'
      && !JSON.parse(mem.brand_profiles_access).includes(content.brand.username),
  );

  const names = membersFiltered.length > 0
    && membersFiltered.map((mem) => `${mem.first_name} ${mem.last_name}`);

  const [selectedName, setSelectedName] = useState((names && names.length > 0) ? names[0] : '');

  useEffect(() => {
    selectedMember(selectedName);
  });

  return (
    <>
      <div className={classes.radioCont}>
        <Radio
          checked={!addNewToBrand}
          onChange={() => handleChangeRadio(false)}
          color="primary"
        />
        <Typography>Existing Member</Typography>
        <Radio
          checked={addNewToBrand}
          onChange={() => handleChangeRadio(true)}
          color="primary"
        />
        <Typography>New Member</Typography>
      </div>
      {!addNewToBrand && (
        <>
          <Typography
            className={classes.selectLabel}
            variant="body1"
            component="span"
          >
            Select a user
          </Typography>
          <Select
            className={classes.select}
            value={selectedName}
            disabled={names.length < 1}
            onChange={(e) => {
              setSelectedName(e.target.value);
              selectedMember(e.target.value);
            }}
          >
            {names.length > 0 && names.map((name) => (
              <MenuItem key={name} value={name} className={classes.options}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
    </>
  );
};

ContentApprovalAddNew.propTypes = {
  addNewToBrand: PropTypes.bool,
  handleChangeRadio: PropTypes.func,
  selectedMember: PropTypes.shape(),
  members: PropTypes.shape(),
};

ContentApprovalAddNew.defaultProps = {
  addNewToBrand: false,
  handleChangeRadio: null,
  selectedMember: null,
  members: null,
};

const AddEditAgencyMemberModal = ({
  isEdit,
  handleClose,
  selected,
  isMember,
  refreshData,
  refreshMembers,
  isToBrand,
  members,
}) => {
  const classes = useStyles();
  const auth = useAuth();
  const content = useContentManager();
  const [invitationKey] = useState(selected ? selected.invitation_key : null);
  const [email, setEmail] = useState(selected ? selected.email : null);
  const [firstName, setFirstName] = useState(
    selected ? selected.first_name : null,
  );
  const [lastName, setLastName] = useState(
    selected ? selected.last_name : null,
  );
  const roleFunc = () => {
    if (selected && selected.agency_role) {
      return accountRoles.find(
        (i) => i.title === capitalize(selected.agency_role),
      );
    }
    if (isToBrand) {
      return accountRoles[3];
    }
    return accountRoles[2];
  };
  const [accountRole, setAccountRole] = useState(roleFunc());
  const [emailError, setEmailError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [addNewToBrand, setAddNewToBrand] = useState(false);
  const [brandNames, setBrandNames] = useState();
  const [brandName, setBrandName] = useState('');
  const [selectedMember, setSelectedMember] = useState();
  const [initialFetch, setInitialFetch] = useState(false);
  const [profileAccess, setProfileAccess] = useState(
    selected && selected.brand_profiles_access
      ? JSON.parse(selected.brand_profiles_access)
      : [],
  );
  const { createNotification } = useNotification();
  const [{ response: data, isFetching: dataFetching }, setRequest] = useApi();
  const handleErrors = () => {
    setEmailError(!email ? 'Email is required.' : '');
    if (email) setEmailError(!isValidEmail(email) ? 'Not a valid email.' : '');
    setFirstNameError(!firstName ? 'First name is required.' : '');
    setLastNameError(!lastName ? 'Last name is required.' : '');
  };

  const titleFunc = () => {
    if (isEdit) {
      return 'Edit Member';
    }
    if (isToBrand) {
      return `Add Member To ${content.brand.username}`;
    }
    return 'Add New Member';
  };

  const buttonTitleFunc = () => {
    if (isEdit) {
      return 'Save Member';
    }
    if (isToBrand) {
      return 'Invite Member Now';
    }
    return 'Add Member Now';
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleErrors();

    if (isToBrand || (email && isValidEmail(email) && firstName && lastName)) {
      // Add existing member to Brand
      if (isToBrand && !addNewToBrand) {
        const fName = selectedMember.split(' ')[0];
        const lName = selectedMember.split(' ')[1];
        const member = members.find(
          (mem) => mem.first_name === fName && mem.last_name === lName,
        );
        const {
          first_name,
          last_name,
          agency_role,
          brand_profiles_access,
        } = member;

        const newProfileAccess = JSON.parse(brand_profiles_access);
        newProfileAccess.push(content.brand.username);
        // console.log(newProfileAccess);

        setRequest({
          method: 'put',
          url: `/account/${auth.account.id}/users/`,
          data: {
            email: member.email,
            first_name,
            last_name,
            agency_role,
            brand_profiles_access: JSON.stringify(newProfileAccess),
          },
        });
      } else {
        setRequest({
          method: 'post',
          url: `/account/${auth.account.id}/billing/invite-employee/`,
          data: {
            email,
            first_name: firstName,
            last_name: lastName,
            agency_role: accountRole.title.toLowerCase(),
            brand_profiles_access:
              profileAccess.length > 0 ? JSON.stringify(profileAccess) : null,
          },
        });
      }
    }
  };

  const handleEdit = (event) => {
    event.preventDefault();
    handleErrors();

    if (email && isValidEmail(email) && firstName && lastName) {
      if (!isMember) {
        setRequest({
          method: 'put',
          url: `/account/${auth.account.id}/employee-invitations/`,
          data: {
            email,
            first_name: firstName,
            last_name: lastName,
            agency_role: accountRole.title.toLowerCase(),
            brand_profiles_access:
              profileAccess.length > 0 ? JSON.stringify(profileAccess) : null,
            invitation_key: invitationKey,
          },
        });
      } else {
        setRequest({
          method: 'put',
          url: `/account/${auth.account.id}/users/`,
          data: {
            email,
            first_name: firstName,
            last_name: lastName,
            agency_role: accountRole.title.toLowerCase(),
            brand_profiles_access:
              profileAccess.length > 0 ? JSON.stringify(profileAccess) : null,
            invitation_key: invitationKey,
          },
        });
      }
    }
  };

  const addProfileAccess = () => {
    setProfileAccess([...profileAccess, brandName]);
    setBrandName('');
  };

  const removeProfile = (prof) => {
    const newArr = profileAccess;
    newArr.splice(newArr.indexOf(prof), 1);
    setProfileAccess([...newArr]);
  };

  useEffect(() => {
    if (data) {
      if (data.status > 400) {
        createNotification('error', 'Failed');
      }
      if (data.status === 400) {
        createNotification('error', 'User Already Exists');
      }
      if (data.status === 200) {
        handleClose();
        if (!isToBrand)refreshData();
        if (isToBrand)refreshMembers();
        createNotification('success', 'Success');
      }
    }
  }, [refreshData, data, handleClose, createNotification, isToBrand, refreshMembers]);

  useEffect(() => {
    if (!initialFetch && accountRole.title.toLowerCase() === 'client') {
      content.getBrandsAndPosts(auth.account.id);
      setInitialFetch(true);
    }
  }, [accountRole, auth.account.id, content, initialFetch]);

  useEffect(() => {
    if (content.brands) {
      setBrandNames(content.brands.map((brand) => brand.username));
    }
  }, [content.brands]);

  return (
    <>
      <ScrDialog
        titleStyle={{ marginBottom: '20px' }}
        isOpen
        onClose={handleClose}
        title={titleFunc()}
        subTitle="Add or delete team members from your agency account. You can also edit
          or remove member permissions anytime you want."
        isFetching={dataFetching}
        minWidth={800}
      >
        <DialogContent className={classes.editContent}>
          <form onSubmit={isEdit ? handleEdit : handleSubmit}>
            {/* Radios and Selection box for COntent Approval Add Member */}
            {isToBrand && (
              <>
                <ContentApprovalAddNew
                  addNewToBrand={addNewToBrand}
                  handleChangeRadio={(i) => setAddNewToBrand(i)}
                  members={members}
                  selectedMember={(member) => setSelectedMember(member)}
                />
              </>
            )}

            {(!isToBrand || addNewToBrand) && (
              <>
                {/* Email */}
                <Typography variant="body1" component="span">
                  Email Address
                </Typography>
                <TextField
                  margin="dense"
                  type="email"
                  helperText={emailError}
                  error={Boolean(emailError)}
                  required
                  fullWidth
                  style={{ marginBottom: '1.5rem' }}
                  value={selected ? null : email}
                  defaultValue={email}
                  onChange={(e) => setEmail(e.target.value)}
                  InputProps={{
                    readOnly: isMember,
                  }}
                />
                {/* First Name */}
                <div className={classes.nameDiv}>
                  <div className={classes.firstName}>
                    <Typography variant="body1" component="span">
                      First Name
                    </Typography>
                    <TextField
                      margin="dense"
                      type="text"
                      fullWidth
                      required
                      error={Boolean(firstNameError)}
                      helperText={firstNameError}
                      value={selected ? null : firstName}
                      defaultValue={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      style={{ marginBottom: '1.5rem' }}
                    />
                  </div>
                  {/* Last Name */}
                  <div className={classes.lastName}>
                    <Typography variant="body1" component="span">
                      Last Name
                    </Typography>
                    <TextField
                      margin="dense"
                      id="name"
                      type="text"
                      fullWidth
                      required
                      value={selected ? null : lastName}
                      defaultValue={lastName}
                      error={Boolean(lastNameError)}
                      helperText={lastNameError}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
                <div>
                  {/* Role */}
                  <Typography variant="body1" component="span">
                    Role
                  </Typography>
                  <span className={classes.infoIcon}>
                    {accountRole.title === 'Administrator & Owner' && (
                      <AccessInfoTooltip title="Want to change the owner's role?">
                        If you want to change the owner&apos;s role, you need to
                        {' '}
                        <Typography
                          variant="body1"
                          component="span"
                          className={classes.contactSupport}
                        >
                          contact support
                        </Typography>
                        and request for the ownership of the agency to be
                        transferred to another user.
                      </AccessInfoTooltip>
                    )}
                  </span>
                  <Select
                    className={classes.editSelect}
                    value={accountRole.title}
                    disabled={isToBrand}
                    onChange={(e) => {
                      setAccountRole(
                        accountRoles.find((i) => i.title === e.target.value),
                      );
                    }}
                  >
                    {accountRoles.map((item, index) => {
                      if (index !== 0) {
                        return (
                          <MenuItem
                            key={item.title}
                            value={item.title}
                            className={classes.options}
                          >
                            {item.title}
                          </MenuItem>
                        );
                      }
                      return null;
                    })}
                  </Select>
                  <div className={classes.descBox}>
                    <ul>
                      {accountRole.accesses.map((item) => (
                        <li key={item} className={classes.list}>
                          <Typography
                            variant="body1"
                            component="span"
                            className={classes.accessList}
                          >
                            {item}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                {/* Brand Profile Access */}
                {accountRole.title.toLowerCase() === 'client' && (
                  <div className={classes.brandAccessCont}>
                    <Typography variant="body1" component="span">
                      Brand Profile Access
                    </Typography>
                    <div className={classes.selectAndButton}>
                      <Select
                        className={classes.profileSelect}
                        value={brandName}
                        displayEmpty
                        // disabled={content.fetching}
                        onChange={(e) => setBrandName(e.target.value)}
                      >
                        <MenuItem value="" disabled>
                          Select a brand profile to give access to
                        </MenuItem>
                        {brandNames
                          && brandNames.map((brand, id) => (
                            <MenuItem
                              key={`${brand}${String(id)}`}
                              value={brand}
                              disabled={profileAccess.includes(brand)}
                            >
                              {brand}
                            </MenuItem>
                          ))}
                      </Select>
                      <Button
                        color="primary"
                        variant="contained"
                        style={{ width: '125px' }}
                        onClick={addProfileAccess}
                        disabled={Boolean(!brandName)}
                      >
                        Add Now
                      </Button>
                    </div>

                    {profileAccess
                      && profileAccess.map((prof) => (
                        <div className={classes.profileItems}>
                          <Typography>{prof}</Typography>
                          <ButtonBase onClick={() => removeProfile(prof)}>
                            <DeleteIcon className={classes.profileDelete} />
                          </ButtonBase>
                        </div>
                      ))}
                  </div>
                )}
              </>
            )}
          </form>
        </DialogContent>

        <DialogActions className={classes.editActions}>
          <Button
            onClick={isEdit ? handleEdit : handleSubmit}
            color="primary"
            variant="contained"
            style={{
              height: '2.5rem',
              width: '13rem',
              marginRight: isEdit ? '1rem' : '',
            }}
          >
            {buttonTitleFunc()}
          </Button>
          {isEdit && (
            <DeleteAccountButton
              email={selected.email}
              deleteStyle={{
                height: '2.5rem',
                width: '13rem',
                marginLeft: '1rem',
              }}
              deleteVariant="outlined"
              deleteColor="primary"
              buttonName="Delete Member"
              isMember={isMember}
              isInvite={!isMember}
              handleClose={handleClose}
              // getInvites={getInvites}
              // getMembers={getMembers}
              refreshData={refreshData}
            />
          )}
        </DialogActions>
        {/* </Dialog> */}
      </ScrDialog>
    </>
  );
};

AddEditAgencyMemberModal.propTypes = {
  isEdit: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  selected: PropTypes.shape(),
  isMember: PropTypes.bool,
  isToBrand: PropTypes.bool,
  members: PropTypes.shape(),
  refreshMembers: PropTypes.func,
};

AddEditAgencyMemberModal.defaultProps = {
  isMember: false,
  selected: null,
  isEdit: false,
  isToBrand: false,
  members: null,
  refreshMembers: null,
};

export default AddEditAgencyMemberModal;
