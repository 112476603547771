import React, { useState, useCallback } from 'react';
import SettingsPageBase from './SettingsPageBase';
import NoMatch from '../../router/NoMatch';
import MembersAndInvites from '../../common/MembersAndInvites';
import { useAuth } from '../../../hooks';

const MyTeamSettingsPage = () => {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const handleIsLoading = useCallback((state) => {
    setIsLoading(state);
  }, []);

  if (
    auth.hasPermission('administrator.restricted')
    || !auth.hasPermission('account.add_employee')
  ) {
    return <NoMatch default />;
  }

  return (
    <>
      <SettingsPageBase
        showLoadingBar={isLoading}
        title="Members and Permissions"
      >
        <MembersAndInvites isPageInSettings handleIsLoading={handleIsLoading} />
      </SettingsPageBase>
    </>
  );
};

export default MyTeamSettingsPage;
