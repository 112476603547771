import React, { useState } from 'react';

import {
  Button,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@reach/router';

import SearchPageBase from '../SearchPageBase';
import AddProfilesToListButton from '../../list/AddProfilesToListButton';
import NoSearchResults from './NoSearchResults';
import PopularItems from './PopularItems';
import VipBackground from '../../../assets/images/discover/vip.jpg';
import DisablePage from '../../common/DisablePage';
import { useAuth, useRoute } from '../../../hooks';

const useStyles = makeStyles((theme) => ({
  discoverWrapper: {
    width: '100%',
    display: 'flex',
    minHeight: '500px',
    padding: '0 20px',
    '@media (min-width: 1920px)': {
      padding: '0 40px',
    },
  },
  vipWrapper: {
    flex: '1',
    padding: '60px 0 30px',
    '@media(min-width: 1920px)': {
      padding: '90px 0 40px',
    },
    position: 'relative',
  },
  vipBg: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  vipContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '50px',
  },
  vipHeader: {
    color: '#FFFFFF',
  },
  vipText: {
    color: theme.colors.text.extraLight,
    textAlign: 'center',
    fontSize: '12px',
    padding: '10px 0 20px',
  },
  discoverCont: {
    flex: '2',
    '@media (min-width:1920px)': {
      flex: '2.7',
    },
    padding: '25px 20px',
    paddingLeft: 0,
    '@media (min-width: 1920px)': {
      padding: '25px 35px 25px 0',
    },
    '& > p': {
      ...theme.textStyles.bodyLarge,
    },
    borderRadius: '4px',
  },
  personalizeButton: {
    position: 'absolute',
    right: 0,
    top: '2rem',
    '@media (max-width: 1919px)': {
      top: '0',
    },
  },
}));

const EmptySearchContent = () => {
  const classes = useStyles();
  const auth = useAuth();
  return (
    <div className={classes.discoverWrapper}>

      {/* DIV FOR POPULAR TOPICS AND LOCATION */}
      <div className={classes.discoverCont}>
        <PopularItems showTitle />
      </div>
      {/* DIV FOR VIP SECTION */}
      <div className={classes.vipWrapper}>
        {auth.hasPermission('preferences.all') && (
          <Button
            className={classes.personalizeButton}
            color="primary"
            component={Link}
            variant="contained"
            size="small"
            to="/settings/preferences"
          >
            <Typography variant="body1">Personalize My View</Typography>
          </Button>
        )}
        <div className={classes.vipBg} style={{ backgroundImage: `url(${VipBackground})` }}>
          <div className={classes.vipContent}>
            <Typography variant="h5" className={classes.vipHeader}>Scrunch VIP&apos;s</Typography>
            <Typography variant="body1" className={classes.vipText}>A list of VIP influecers curated by the Scrunch team</Typography>
            <Button
              className={classes.button}
              color="primary"
              component={Link}
              variant="contained"
              size="small"
              to="/discover/vip"
            >
              <Typography variant="body1">View Now</Typography>
            </Button>
          </div>
        </div>
      </div>

    </div>
  );
};

const DiscoverPage = () => {
  const [profiles, setProfiles] = useState(null);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const auth = useAuth();
  const [, setRoute] = useRoute();
  const employee = auth.user && auth.user.roles[0].name === 'employee';

  if (auth.account && auth.account.type === 'influencer') {
    setRoute({ pathname: '/dashboard' });
  }

  return (
    <>
      {!auth.hasPermission('list.create') && employee && <DisablePage />}
      <SearchPageBase
        actions={[
          <AddProfilesToListButton
            key="addtolist"
            profiles={selectedProfiles}
          />,
        ]}
        emptyContent={profiles ? <NoSearchResults /> : <EmptySearchContent />}
        onProfilesChange={setProfiles}
        onSelectedProfilesChange={setSelectedProfiles}
        searchUrlBase={`/account/${auth.account.id}/profile/search/`}
        title="Discover"
      />
    </>
  );
};

export default DiscoverPage;
