import React from 'react';

import PropTypes from 'prop-types';

import MuiAlert from '@material-ui/lab/Alert';

const Alert = ({ className, elevation, ...remainingProps }) => (
  <MuiAlert
    className={className}
    elevation={elevation || 0}
    variant="filled"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...remainingProps}
  />
);

Alert.propTypes = {
  className: PropTypes.string,
  elevation: PropTypes.number,
};

Alert.defaultProps = {
  className: null,
  elevation: null,
};

export default Alert;
