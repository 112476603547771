import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Box,
  Input,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from '@reach/router';

import DowngradeStatus from '../../account/DowngradeStatus';
// import NoMatch from '../../router/NoMatch';
import SettingsPageBase from './SettingsPageBase';
import CreditCardField from '../../account/CreditCardField';
import UpdateBillingEmailButton from '../../account/UpdateBillingEmailButton';
import { useApi, useAuth } from '../../../hooks';
import { capitalize } from '../../../utils/string';

const useStyles = makeStyles((theme) => ({
  accountSettingsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    '&>*': {
      width: '100%',
    },
    '&>:not(:last-child)': {
      marginBottom: 20,
    },
  },
  inlineEditableField: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'end',
    '&>:not(:last-child)': {
      marginRight: 10,
    },
  },
  sectionTitle: {
    marginBottom: 25,
    ...theme.textStyles.bodyLarge,
  },
  paymentHistoryTable: {
    border: `1px solid ${theme.colors.system.grey3}`,
    borderRadius: '2px',
    marginTop: theme.spacing(1),
    '& tr': {
      border: `1px solid ${theme.colors.system.grey3}`,
    },
    '& th,td': {
      padding: '17px 20px !important',
      border: 'none',
      ...theme.textStyles.bodySmall,
    },
    '& td': {
      backgroundColor: theme.colors.system.light,
    },
    '& th': {
      color: '#2b2e34 !important',
      fontWeight: 'bold',
    },
    '@media (min-width: 1920px)': {
      width: 'calc(80% + 40px)',
    },
  },
  tableHeader: {
    backgroundColor: theme.colors.system.grey1,
  },
  box: {
    width: '50%',
    '@media(min-width:1920px)': {
      width: '40%',
    },
    border: `1px solid ${theme.colors.system.grey4}`,
    backgroundColor: theme.colors.system.grey1,
    padding: 15,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  label: {
    lineHeight: '18px',
    marginBottom: 8,
    ...theme.fonts.medium,
  },
  inputField: {
    border: 'none',
    width: '100%',
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 20,
  },
  rowItem: {
    width: '40%',
    '@media(max-width: 1919px)': {
      width: '80%',
    },
    '@media(max-width: 767px)': {
      width: '100%',
    },
    marginBottom: 20,
    '@media(min-width:1920px)': {
      '&:first-child': {
        marginRight: 40,
      },
    },
  },
  paymentRowBox: {
    width: '100%',
    padding: 4,
    paddingRight: 15,
  },
  link: {
    ...theme.fonts.medium,
    fontWeight: 500,
  },
  currentPlanValid: {
    marginBottom: 50,
    display: 'flex',
  },
}));

const CurrentPlan = ({
  customer, currentPlan, billingData,
}) => {
  const classes = useStyles();
  const auth = useAuth();
  const freePlans = [
    'starter_no_charge',
    'student_no_charge',
    'agency_no_charge',
    'starter_influencer_no_charge',
    'influencer_no_charge',
    'staff_no_charge',
    'basic_invoice',
    'basic_no_charge',
    'power_no_charge',
    'power_invoice',
    'power_product_trial',
    'power_additional_user',
    'power_spark'];
  const [isPlanActive] = useState(!freePlans.includes(billingData.current_plan.id));
  const [billedWhen] = useState(billingData.current_plan.id.split('_')[2]);
  const [billingStart] = useState(customer.subscriptions.data[0].current_period_start);
  const [billingEnd] = useState(customer.subscriptions.data[0].current_period_end);
  let currentPlanName = customer.subscriptions.data ? customer.subscriptions.data[0].plan.nickname : null;
  if (currentPlan.id.split('_').includes('trial')) currentPlanName = 'Power - FREE 7 day trial';

  return (
    <>
      {billingData && billingData.customer && billingData.next_plan && (
        <DowngradeStatus
          customer={billingData.customer}
          nextPlan={billingData.next_plan}
        />
      )}
      <Typography variant="body1" component="p" className={classes.sectionTitle}>Plan Details</Typography>
      <div className={classes.row}>
        <div className={classes.rowItem}>
          <Typography variant="body2" className={classes.label}>Current plan</Typography>
          <Box className={`${classes.box} ${classes.paymentRowBox}`} style={{ padding: '1rem' }}>
            <Typography variant="body2">{currentPlanName}</Typography>
            {!auth.hasPermission('manager.restricted')
              && (
              <Link component={RouterLink} to="/settings/plans">
                <Typography variant="body2" className={classes.link}>Change Plan</Typography>
              </Link>
              )}
          </Box>
        </div>
        <div className={classes.rowItem}>
          <Typography variant="body2" className={classes.label}>Plan Validity</Typography>
          <Box className={`${classes.box} ${classes.paymentRowBox}`}>
            <Input
              className={classes.inputField}
              readOnly
              value={isPlanActive
                ? `${moment.unix(billingStart).format('Do MMM YYYY')} - ${moment.unix(billingEnd).format('Do MMM YYYY')} (Billed ${billedWhen}ly)`
                : 'Free Plan'}
            />
          </Box>
        </div>
      </div>

    </>
  );
};

CurrentPlan.propTypes = {
  customer: PropTypes.shape().isRequired,
  currentPlan: PropTypes.shape().isRequired,
  billingData: PropTypes.shape().isRequired,
};

const PaymentDetails = ({ customer, onChange }) => {
  const classes = useStyles();
  const auth = useAuth();

  return (
    <div>
      <Typography variant="body1" component="p" className={classes.sectionTitle}>Payment Details</Typography>
      <div className={classes.row}>
        <div className={classes.rowItem}>
          <Typography variant="body2" className={classes.label}>Billing email</Typography>
          <Box className={`${classes.box} ${classes.paymentRowBox}`} style={{ padding: '0rem' }}>
            <Input
              className={classes.inputField}
              readOnly
              value={customer.metadata.billing_email || customer.email}
              endAdornment={!auth.hasPermission('manager.restricted')
                && (
                <InputAdornment position="end" color="primary">
                  <UpdateBillingEmailButton customer={customer} onSuccess={onChange} />
                </InputAdornment>
                )}
            />
          </Box>
        </div>

        {!auth.hasPermission('manager.restricted')
          && (
          <div className={classes.rowItem}>
            <CreditCardField customer={customer} onSuccess={onChange} />
          </div>
          )}
      </div>
    </div>
  );
};

PaymentDetails.propTypes = {
  customer: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
};

const PaymentHistory = ({ invoices }) => {
  const classes = useStyles();
  const auth = useAuth();

  return (
    <div>
      <Typography variant="body1" component="p" className={classes.sectionTitle}>Payment History</Typography>
      <Table className={classes.paymentHistoryTable}>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Status</TableCell>
            {/* Download link */}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices.map((invoice) => (
            <TableRow key={invoice.id}>
              <TableCell width="30%">{moment.unix(invoice.created).format('Do MMM YYYY')}</TableCell>
              <TableCell width="30%">
                $
                {invoice.amount_due / 100}
              </TableCell>
              <TableCell width="30%">{capitalize(invoice.status)}</TableCell>
              {!auth.hasPermission('manager.restricted')
                && (
                <TableCell align="right" width="15%">
                  <Link href={invoice.invoice_pdf} target="_blank" className={classes.link}>Download</Link>
                </TableCell>
                )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

PaymentHistory.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const AccountSettingsPage = () => {
  const classes = useStyles();
  const auth = useAuth();
  const [{ data: billingData, isFetching }, getBillingRequest] = useApi();

  const refreshBilling = useCallback(() => {
    getBillingRequest({
      method: 'get',
      url: `/account/${auth.account.id}/billing/`,
    });
  }, [auth.account.id, getBillingRequest]);

  useEffect(() => {
    refreshBilling();
  }, [refreshBilling]);

  // if (auth.hasPermission('administrator.restricted')) {
  //   return (
  //     <NoMatch default />
  //   );
  // }

  return (
    <SettingsPageBase
      showLoadingBar={isFetching}
      title="Account settings"
    >
      <div className={classes.accountSettingsContainer}>
        {billingData && billingData.customer && (
          <CurrentPlan
            customer={billingData.customer}
            currentPlan={billingData.current_plan}
            billingData={billingData}
            auth={auth}
          />
        )}
        {billingData && billingData.customer && (
          <PaymentDetails customer={billingData.customer} onChange={refreshBilling} auth={auth} />
        )}
        {billingData && billingData.invoices && (
          <PaymentHistory invoices={billingData.invoices} auth={auth} />
        )}
      </div>
    </SettingsPageBase>
  );
};

export default AccountSettingsPage;
