import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import MUIRichTextEditor from 'mui-rte';
import moment from 'moment';
import EditNoteButton from './EditNoteButton';
import { useAuth, useNotes } from '../../hooks';

const EditableNoteContent = ({ profile }) => {
  const auth = useAuth();
  const notes = useNotes();
  const [noteText] = useState((profile.data.note && profile.data.note.text) ? profile.data.note.text : '');
  const [modifiedNote, setModifiedNote] = useState(noteText && notes.checkForText(noteText) ? profile.data.note.modified : '');
  const [lastSavedNote, setLastSavedNote] = useState(
    modifiedNote
      ? `Last saved ${moment.utc(modifiedNote).fromNow()}`
      : 'Add a New Note',
  );
  const [newNote, setNewNote] = useState(noteText && notes.checkForText(noteText) ? noteText : '');

  // 1st: setNewNote once a change occurs
  const handleSuccess = (data) => {
    setNewNote(data.text);
    setModifiedNote(new Date().format());
  };

  // 2nd: setNewNote and modifiedNote if state is available
  useEffect(() => {
    if (notes.newNote !== undefined && (profile.data.id === notes.profileId)) {
      setNewNote(notes.newNote);
      setModifiedNote(notes.modifiedNote);
    }
  }, [notes, profile.data.id]);

  // change lastSavedNote for slide pane
  useEffect(() => {
    if (newNote && notes.checkForText(newNote)) {
      setLastSavedNote(`Last saved ${moment.utc(modifiedNote).fromNow()}`);
    } else {
      setLastSavedNote('Add a New Note');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiedNote]);

  return (
    <div style={{
      maxHeight: '10rem',
      overflow: 'hidden',
      minWidth: '6rem',
      maxWidth: '15rem',
    }}
    >
      {auth.hasPermission('profile.edit_note') && (
        <EditNoteButton
          iconButton={false}
          networkId={profile.networkId()}
          onSuccess={handleSuccess}
          profile={profile}
          newNoteFromRow={newNote}
          lastSavedNoteFromRow={lastSavedNote}
        />
      )}

      <MUIRichTextEditor
        defaultValue={newNote}
        label="..."
        readOnly
        toolbar={false}
      />

    </div>
  );
};

EditableNoteContent.propTypes = {
  profile: PropTypes.shape().isRequired,
};

export default EditableNoteContent;
