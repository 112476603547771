import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import {
  Card,
  CardContent,
  Typography,
  Button,
  Switch,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SettingsPageBase from './SettingsPageBase';
import ChangePlanButton from '../../account/ChangePlanButton';
import DowngradeStatus from '../../account/DowngradeStatus';
import DowngradeCancelStatus from '../../account/DowngradeCancelStatus';
import { useApi, useAuth, useRoute } from '../../../hooks';

const planFeatures = {
  // Brand/Agency
  starter_no_charge: {
    name: 'Starter',
    features: [
      '- Search influencers by social network, follower range, engagement, location and more',
      '- Find influencers in any niche',
    ],
  },
  basic_199_month: {
    name: 'Basic',
    features: [
      'Everything in the Starter plan, plus:',
      '- Unlimited search results',
      '- Unlimited influencer lists',
      '- Create lists of your favorite influencers',
      '- Access influencer contact details',
    ],
  },
  power_299_month: {
    name: 'Power',
    features: [
      'Everything in the Starter and Basic plans, plus:',
      '- Search influencers by audience location, gender, age and topics',
    ],
  },
  basic_1791_year: {
    name: 'Basic',
    features: [
      'Everything in the Starter plan, plus:',
      '- Unlimited search results',
      '- Unlimited influencer lists',
      '- Create lists of your favorite influencers',
      '- Access influencer contact details',
    ],
  },
  power_2691_year: {
    name: 'Power',
    features: [
      'Everything in the Starter and Basic plans, plus:',
      '- Search influencers by audience location, gender, age and topics',
    ],
  },

  // Influencer
  starter_influencer_no_charge: {
    name: 'Influencer (Starter)',
    features: [
      'Your profile listed on Scrunch FREE forever, so more brands can discover you',
      'A weekly email dedicated to leveling up your Influencer game',
    ],
  },
  influencer_10_month: {
    name: 'Influencer (Pro)',
    features: [
      'The Scrunch Media Kit Generator',
      'Full audience insights for your Influencer profile',
      'Scrunch Influencer University',
      '- Unlimited access to training videos created specifically for Influencers to grow and monetise your following.',
      '- Unlimited access to eBooks and resources.',
      '- Unlimited access to a library of Lightroom Presets to level up your content game.',
      'Scrunch Resources',
      '- Business templates and resources to take the pain our of running your business of Influence.',
      'A global Influencer community',
    ],
  },
  influencer_100_year: {
    name: 'Influencer (Pro - yearly)',
    features: [
      'All of the epic inclusions on the Scrunch Influencer Pro plan PLUS 2 months free!',
      'A free website and social media audit designed to give you bespoke, actionable insights into growing your business of Influence. (Value $500)',
    ],
  },

  // Agency
  agency_499_month: {
    name: 'Agency',
    features: [
      'Everything in the Power plan, plus:',
      '- Multi user accounts, up to 10',
      '- Ongoing team platform and influencer marketing training',
      '- Monthly influencer recommendations for key clients or campaigns (up to 5)',
      '- Complimentary tickets to virtual events',
      '- 25% off any content packs',
    ],
  },
  agency_4491_year: {
    name: 'Agency',
    features: [
      'Everything in the Power plan, plus:',
      '- Multi user accounts, up to 10',
      '- Ongoing team platform and influencer marketing training',
      '- Monthly influencer recommendations for key clients or campaigns (up to 5)',
      '- Complimentary tickets to virtual events',
      '- 25% off any content packs',
    ],
  },
  // Data Saver
  datasaver_19_month: {
    name: 'Data Saver',
    features: [
      '- Put your account on pause and save all of your influencer lists',
      '- Store Premium Data',
      '- Keep your profile notes',
    ],
  },
  // ALL Access Plan
  allaccess_39_month: {
    name: 'All Access Plan',
    features: [
      '- Unlimited search results',
      '- Unlimited Influencer lists',
      '- Influencer engagement rate',
      '- Influencer contact details',
      '- Search Influencers by audience location, gender, age and topics',
      '- Complete Influencer audience insights including audience location, gender, age and topics',
      '- Export bulk lists'
    ],
  },
  allaccess_365_year: {
    name: 'All Access Plan',
    features: [
      '- Unlimited search results',
      '- Unlimited Influencer lists',
      '- Influencer engagement rate',
      '- Influencer contact details',
      '- Search Influencers by audience location, gender, age and topics',
      '- Complete Influencer audience insights including audience location, gender, age and topics',
      '- Export bulk lists'

    ],
  },
};

const useStyles = makeStyles((theme) => ({
  plans: {
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'start',
    '&>*': {
      width: 285,
      minHeight: 400,
      '@media (min-width: 1920px)': {
        width: 350,
        minHeight: 450,
      },
      '@media (min-width: 2560px)': {
        width: 400,
        minHeight: 500,
      },
    },
    '&>:not(:last-child)': {
      marginRight: 5,
    },
    '&>:first-child': {
      marginLeft: '0px',
    },
    '&>:last-child': {
      marginRight: '0px',
    },
  },
  planCard: {
    margin: '0px 20px',
    '@media (min-width:1920px)': {
      margin: '0px 40px',
    },
    '@media (min-width:2560px)': {
      margin: '0px 50px',
    },
    boxShadow: 'none',
    backgroundColor: theme.colors.system.grey1,
    border: `1px solid ${theme.colors.system.grey3}`,
    borderRadius: 4,
  },
  planContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
  },
  planName: {
    ...theme.textStyles.bodyLarge,
    marginBottom: 30,
  },
  planDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  planFeatures: {
    flex: 1,
    marginBottom: 40,
  },
  featureContainer: {
    backgroundColor: '#FFFFFF',
    border: `1px solid ${theme.colors.system.grey3}`,
    borderRadius: 4,
    padding: 15,
    '& ul': {
      paddingLeft: 20,
      margin: 0,
    },
    '& ul > li, ul > p': {
      marginBottom: 15,
      '@media (max-width: 1919px)': {
        ...theme.textStyles.bodyExtraSmall,
      },
    },
    '& ul > li': {
      '&:last-child': {
        marginBottom: 0,
      },
    },
    '& ul > p': {
      marginLeft: '-20px',
    },
  },
  planPricing: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginBottom: 8,
    color: theme.colors.primary.main,
  },
  planPricingCurrency: {
    marginLeft: theme.spacing(1),
  },
  planButton: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  disabledBtn: {
    '&:disabled': {
      backgroundColor: theme.colors.system.grey3,
      color: `${theme.colors.system.grey7} !important`,
      ...theme.fonts.medium,
      fontWeight: 500,
      padding: '11px 0',
    },
  },
  title: {
    ...theme.textStyles.bodyLarge,
  },
  annualOptionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  annualOptionText: {
    ...theme.textStyles.bodyExtraSmall,
    '& span': {
      color: theme.colors.primary.main,
    },
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  disabledText: {
    color: theme.colors.system.grey7,
  },
  billingFrequencyText: {
    ...theme.textStyles.bodyExtraSmall,
    textAlign: 'center',
    marginBottom: 10,
    color: theme.colors.system.grey7,
  },
  margin: {
    marginBottom: theme.spacing(2),
  },
}));

const AnnualSelectBar = ({ annual, setAnnual }) => {
  const classes = useStyles();

  return (
    <div className={classes.annualOptionContainer}>
      <Typography variant="body2" className={classes.annualOptionText}>
        <span>Pay yearly</span>
        {' and get '}
        <span>3 months free</span>
      </Typography>
      <div className={classes.switchContainer}>
        <Typography variant="body2" className={`${classes.annualOptionText} ${annual ? classes.disabledText : ''}`}>
          Pay Monthly
        </Typography>
        <Switch
          color="primary"
          checked={annual}
          onChange={(e) => setAnnual(e.target.checked)}
        />
        <Typography variant="body2" className={`${classes.annualOptionText} ${annual ? '' : classes.disabledText}`}>
          Pay Yearly
        </Typography>
      </div>
    </div>
  );
};

AnnualSelectBar.propTypes = {
  annual: PropTypes.bool.isRequired,
  setAnnual: PropTypes.func.isRequired,
};

const Plan = (props) => {
  const {
    currentPlan,
    onSuccess,
    plan,
    altIntervalPlan,
    dataSaverPlan,
  } = props;
  const classes = useStyles();
  const { product } = plan;
  const isCurrentPlan = currentPlan.id === plan.id;
  const name = plan.id in planFeatures ? planFeatures[plan.id].name : product.name;
  const features = plan.id in planFeatures ? planFeatures[plan.id].features : [];
  return (
    <Card className={classes.planCard}>
      <CardContent className={classes.planContent}>
        <Typography variant="h5" className={classes.planName}>{name}</Typography>

        <div className={classes.planFeatures}>
          <div className={classes.featureContainer}>
            <ul>
              {features && features.map((it) => (
                it.indexOf('- ') !== 0
                  ? <Typography key={it} component="p" variant="body2">{it}</Typography>
                  : <Typography key={it} component="li" variant="body2">{it.replace('- ', '')}</Typography>
              ))}
            </ul>
          </div>
        </div>

        <div className={classes.planPricing}>
          <Typography variant="h5">
            $
            {plan.amount / 100}
          </Typography>
          <Typography className={classes.planPricingCurrency} variant="body2">
            {plan.currency.toUpperCase()}
            /
            {plan.interval}
          </Typography>
        </div>
        <Typography varient="body2" className={classes.billingFrequencyText}>
          {`Billed ${plan.interval === 'year' ? 'Yearly' : 'Monthly'}`}
        </Typography>
        <div className={classes.planButton}>
          {isCurrentPlan && (
            <Button color="primary" disabled fullWidth variant="contained" size="small" className={classes.disabledBtn}>
              Currently subscribed
            </Button>
          )}
          {!isCurrentPlan && (
            <ChangePlanButton
              isUpgrade={currentPlan.amount <= plan.amount}
              nextPlan={plan}
              onSuccess={onSuccess}
              altIntervalPlan={altIntervalPlan}
              dataSaverPlan={dataSaverPlan}
              currentPlan={currentPlan}
            />
          )}
        </div>
      </CardContent>
    </Card>
  );
};

Plan.propTypes = {
  currentPlan: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func.isRequired,
  plan: PropTypes.shape().isRequired,
  altIntervalPlan: PropTypes.shape(),
  dataSaverPlan: PropTypes.shape(),
};

Plan.defaultProps = {
  altIntervalPlan: null,
  dataSaverPlan: null,
};

const PlansSettingsPage = () => {
  const classes = useStyles();
  const auth = useAuth();
  const [{ data: billingData, isFetching }, getBillingRequest] = useApi();
  const [annual, setAnnual] = useState(false);
  const [displayedPlans, setDisplayedPlans] = useState();
  const [isInfluencerAcc, setIsInfluencerAcc] = useState(false);
  const [dataSaverPlan, setDataSaverPlan] = useState('');
  const [isDowngrading, setIsDowngrading] = useState(false);
  const [route] = useRoute();
  const [dataSaverReconstructed, setDataSaverReconstructed] = useState();

  useEffect(() => {
    if (billingData) {
      setDataSaverPlan(billingData.available_plans.find((el) => el.id === 'datasaver_19_month'));
    }
  }, [billingData]);

  const refreshBilling = useCallback(() => {
    getBillingRequest({
      method: 'get',
      url: `/account/${auth.account.id}/billing/`,
    });
    setIsDowngrading(false);
  }, [auth.account.id, getBillingRequest]);

  useEffect(() => {
    refreshBilling();
  }, [refreshBilling]);

  useEffect(() => {
    if (billingData) {
      const isInfluencer = billingData.available_plans[0].id.split('_').includes('influencer');
      const isTrialUser = billingData.current_plan.id.split('_').includes('trial');
      setIsInfluencerAcc(isInfluencer);
      const selectedPlans = billingData.available_plans.map((plan) => {
        const planLevel = plan.id.split('_')[0];

        if (planLevel.includes('starter') && isTrialUser) return { plan: null };
        // if (planLevel.includes('datasaver')) return { plan: null };

        const altInterval = plan.interval === 'month' ? 'year' : 'month';
        const altIntervalPlan = { ...billingData.available_plans.find((item) => item.interval === altInterval && item.id.split('_')[0] === planLevel), isInfluencer };
        // eslint-disable-next-line
        const altIsCurrentPlan = altIntervalPlan !== undefined ? (altIntervalPlan.id === billingData.current_plan.id) : false;
        if (!isInfluencer) {
          return {
            plan: plan.id.split('_')[0] === 'starter' || plan.id.split('_')[2] === (annual ? 'year' : 'month') ? { ...plan, isInfluencer, altIsCurrentPlan } : null,
            altIntervalPlan,
          };
        }
        return {
          plan: { ...plan, isInfluencer, altIsCurrentPlan },
          altIntervalPlan,
        };
      });
      // eslint-disable-next-line
      setDisplayedPlans(selectedPlans.filter((plan) => Boolean(plan.plan)));
    }
  }, [annual, billingData]);

  useEffect(() => {
    if (displayedPlans) {
      setDataSaverReconstructed(displayedPlans.find((plan) => plan.plan.id.includes('data')));
    }
  }, [displayedPlans]);

  const downgradeLoading = () => {
    setIsDowngrading(!isDowngrading);
  };

  return (
    <SettingsPageBase
      showLoadingBar={isFetching || isDowngrading}
      title="Plans"

    >
      {billingData
      && billingData.customer
      && billingData.next_plan
      && (billingData.next_plan.id !== 'starter_no_charge' || billingData.current_plan.id === 'power_product_trial')
      && (
        <div>
          <DowngradeStatus
            customer={billingData.customer}
            nextPlan={billingData.next_plan}
          />
        </div>
      )}
      {billingData
      && billingData.customer
      && billingData.next_plan
      && (billingData.next_plan.id === 'starter_no_charge' && billingData.current_plan.id !== 'power_product_trial') && (
        <div>
          <DowngradeCancelStatus
            customer={billingData.customer}
            refreshBilling={refreshBilling}
            downgradeLoading={downgradeLoading}
          />
        </div>
      )}
      <Typography varient="h5" className={`${classes.title}  ${isInfluencerAcc ? classes.margin : ''}`}>
        Change Plan
      </Typography>
      <div style={{ display: 'inline-block' }}>
        {billingData && !isInfluencerAcc
        && <AnnualSelectBar annual={annual} setAnnual={setAnnual} />}
        <div className={classes.plans}>
          {billingData
          && !route.pathname.includes('data-saver')
          && displayedPlans
          && displayedPlans.map((plan) => (
            <Plan
              key={plan.plan.id}
              currentPlan={billingData.current_plan}
              onSuccess={refreshBilling}
              plan={plan.plan}
              altIntervalPlan={plan.altIntervalPlan}
              dataSaverPlan={dataSaverPlan}
            />
          ))}
          {route.pathname.includes('data-saver')
          && billingData
          && displayedPlans
          && dataSaverReconstructed
          && (
          <Plan
            currentPlan={billingData.current_plan}
            onSuccess={refreshBilling}
            plan={dataSaverReconstructed.plan}
            altIntervalPlan={dataSaverReconstructed.altIntervalPlan}
            dataSaverPlan={dataSaverPlan}
          />
          )}
        </div>
      </div>
    </SettingsPageBase>
  );
};

export default PlansSettingsPage;
