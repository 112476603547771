import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import {
  Button,
  ButtonBase,
  IconButton,
  Tooltip,
  Typography,
  Avatar,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SlidingPane from 'react-sliding-pane';
import moment from 'moment';
import MUIRichTextEditor from 'mui-rte';
import { convertToRaw } from 'draft-js';
import { useAuth, useNotification, useNotes } from '../../hooks';
import API from '../../utils/api';
import EditNoteIconWhite from '../common/icons/EditNoteWhite';
import EditNoteIconRed from '../common/icons/EditNoteRed';
import 'react-sliding-pane/dist/react-sliding-pane.css';

const useStyles = makeStyles((theme) => ({
  popup: {
    padding: theme.spacing(1),
    '&>:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  heading: {
    fontWeight: 'bold',
    display: 'flex',
  },
  editButtonText: {
    color: theme.colors.text.light,
    fontStyle: 'italic',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  slidingPane: {
    maxWidth: '40rem',
    height: '100%',
    // position: 'absolute',
    right: '0',
    bottom: '0',
    zIndex: '5',
  },

  slideHr: {
    color: '#E2E2E240',
  },
  noteTitle: {
    fontFamily: 'Beatrice-Regular',
    fontWeight: 400,
    letterSpacing: '0.5px',
    display: 'flex',
    justifyContent: 'space-between',
    background: theme.colors.system.grey3,
    padding: '1.5rem',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',

  },
  paneTitle: {
    fontFamily: 'Beatrice-Regular',
    fontWeight: 400,
    letterSpacing: '0.5px',
  },
  paneTitleUnder: {
    fontFamily: 'Beatrice-Regular',
    fontWeight: 100,
    fontSize: '0.75rem',
    color: '#909090',
  },
  noteContent: {
    border: `3px solid ${theme.colors.system.grey3}`,
    borderEndEndRadius: '5px',
    borderEndStartRadius: '5px',
    padding: '1rem',
    minHeight: '10rem',
  },
  noteBtnSave: {
    margin: 'auto',
  },
  notesOnUnder: {
    fontSize: '0.75rem',
  },
  noteBtnAdd: {
    padding: '0.2rem 0.5rem',
    margin: 'auto 0',
  },
  noteBtnAddPane: {
    margin: '0.4rem 1rem 0.4rem 0',
    float: 'right',
    zIndex: '3',
  },
  noteBtnEdit: {
    padding: '0.2rem 0.5rem',
    margin: 'auto 0',
    background: theme.colors.system.grey3,

    '&:hover': {
      backgroundColor: theme.colors.system.grey1,
    },

    '& > *': {
      color: '#E75041',
    },
  },
  noteRowTextField: {
    minHeight: '20rem',
  },
  noteContentInput: {
    height: '100%',
    width: '100%',
    border: '0',
    fontFamily: 'Beatrice-Regular',
    fontSize: '0.875rem',
  },

}));

const EditNoteContent = (props) => {
  const {
    networkId,
    onSuccess,
    profile,
    onProfilePage,
    newNoteFromRow,
    lastSavedNoteFromRow,
  } = props;
  const classes = useStyles();
  const auth = useAuth();
  const notes = useNotes();
  const { createNotification } = useNotification();
  const [isFetching, setIsFetching] = useState(false);
  const [noteText] = useState((profile.data.note && profile.data.note.text) ? profile.data.note.text : '');
  const [value, setValue] = useState(noteText);
  const [editNote, setEditNote] = useState(false);
  const [modifiedNote, setModifiedNote] = useState(noteText && notes.checkForText(noteText) ? profile.data.note.modified : '');
  const [lastSavedNote, setLastSavedNote] = useState(
    modifiedNote
      ? `Last saved ${moment.utc(modifiedNote).fromNow()}`
      : 'Add a New Note',
  );

  const [newNote, setNewNote] = useState(noteText && notes.checkForText(noteText) ? noteText : '');
  const profileId = profile.data.id;
  const [saveToState, setSaveToState] = useState(false);

  // 1st: Used once to Save to state only when first changed data
  useEffect(() => {
    if (saveToState) {
      notes.setUpNotes({
        profileId,
        newNote,
        lastSavedNote,
        modifiedNote,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveToState, onProfilePage, newNote, modifiedNote]);

  // Every update, update notes for profilePage
  useEffect(() => {
    if (saveToState) {
      setNewNote(notes.newNote);
      setModifiedNote(notes.modifiedNote);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes.newNote]);

  // Every change, initiate and update state
  useEffect(() => {
    if (saveToState) {
      notes.setUpNotes({
        profileId, newNote, lastSavedNote, modifiedNote,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveToState, onProfilePage, profileId, newNote, lastSavedNote, modifiedNote]);

  // change lastSavedNote for profile page
  useEffect(() => {
    if (newNote && notes.checkForText(newNote)) {
      setLastSavedNote(`Last saved ${moment.utc(modifiedNote).fromNow()}`);
    } else {
      setLastSavedNote('Add a New Note');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newNote]);

  const handleValueChange = (event) => {
    const content = JSON.stringify(convertToRaw(event.getCurrentContent()));
    setValue(content);
  };

  const handleNoteProfile = () => {
    setEditNote(true);
  };

  const updateStates = () => {
    setNewNote(value);
    setModifiedNote(new Date().format());
  };

  const handleSave = async (event) => {
    event.preventDefault();
    setIsFetching(true);
    setEditNote(onProfilePage ? false : editNote);
    const response = await API({
      method: 'post',
      url: `/account/${auth.account.id}/profile/by-network-id/${networkId}/note/`,
      data: {
        text: value,
      },
    });
    setIsFetching(false);
    if (response.status === 200) {
      updateStates();
      createNotification('success', 'Note saved.');
      onSuccess(response.data.data);
      setSaveToState(true);
    } else {
      createNotification('error', 'Could not save note.');
      onSuccess(null);
    }
  };

  if (onProfilePage) {
    return (
      <>
        <div className={classes.noteTitle}>
          <div>
            <Typography className={classes.notesOnName} component="h2" gutterBottom>
              {`Notes on ${profile.fullName()}`}
            </Typography>
            <Typography className={classes.notesOnUnder} color="textSecondary" gutterBottom>
              {newNote ? lastSavedNote : 'Add a private note to this profile'}
            </Typography>
          </div>

          { newNote
            ? (
              <Button
                className={classes.noteBtnEdit}
                disabled={isFetching}
                onClick={(Boolean(newNote) && editNote) ? handleSave : handleNoteProfile}
                size="small"
                variant="contained"
                startIcon={<EditNoteIconRed style={{ fontSize: '1.5rem' }} />}
              >

                {editNote ? 'Save Note' : 'Edit Note'}
              </Button>
            )
            : (
              <Button
                className={classes.noteBtnAdd}
                color="primary"
                disabled={isFetching}
                onClick={editNote ? handleSave : handleNoteProfile}
                size="small"
                variant="contained"
                startIcon={<EditNoteIconWhite style={{ fontSize: '1.5rem', color: 'white' }} />}
              >
                { editNote ? 'Save Note' : 'Add A Note'}
              </Button>
            )}
        </div>

        {(editNote || Boolean(newNote)) && (
        <div className={classes.noteContent}>
          <MUIRichTextEditor
            disabled={isFetching}
            defaultValue={newNote}
            label="Enter your note here..."
            onSave={handleSave}
            onChange={handleValueChange}
            controls={['title', 'bold', 'italic', 'underline', 'bulletList', 'link']}
            readOnly={!editNote}
            toolbar={editNote}
          />
        </div>
        )}

      </>
    );
  }
  return (
    // Slide Pane Notes from ProfileTableRows
    <>
      <div className={classes.popup}>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex' }}>
            <Avatar
              alt={profile.data.full_name[0]}
              className={classes.profileAvatar}
              src={profile.data.profile_picture[0]}
            />
            <div style={{ marginLeft: '1rem' }}>
              <Typography
                // className={classes.heading}
                variant="body1"
                style={{ width: '100%' }}
              >
                <span className={classes.paneTitle}>{`Notes on ${profile.data.full_name[0]}`}</span>
                <br />

                <span className={classes.paneTitleUnder}>{ lastSavedNoteFromRow }</span>
              </Typography>
            </div>
          </div>
        </div>

        <hr className={classes.slideHr} />

        <Button
          color="primary"
          disabled={isFetching}
          onClick={handleSave}
          variant="contained"
          className={classes.noteBtnAddPane}
        >
          Save Note
        </Button>

        <MUIRichTextEditor
          disabled={isFetching}
          defaultValue={newNoteFromRow}
          label="Enter your note here..."
          onSave={handleSave}
          onChange={handleValueChange}
          controls={['title', 'bold', 'italic', 'underline', 'bulletList', 'link']}
          style={{ minHeight: '20rem' }}
        />
      </div>

    </>

  );
};

EditNoteContent.propTypes = {
  networkId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  profile: PropTypes.shape().isRequired,
  onProfilePage: PropTypes.bool.isRequired,
  newNoteFromRow: PropTypes.string.isRequired,
  lastSavedNoteFromRow: PropTypes.string.isRequired,
};

const EditNoteButton = (props) => {
  const {
    iconButton,
    networkId,
    onSuccess,
    profile,
    newNoteFromRow,
    lastSavedNoteFromRow,
  } = props;
  const classes = useStyles();
  const [paneState, setPaneState] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });
  const notes = useNotes();

  const handleNotes = (event) => {
    event.preventDefault();
    setPaneState({ isPaneOpen: true });
  };

  const handleSuccess = (data) => {
    onSuccess(data);
  };

  const addOrEditButton = () => {
    let result;
    const stateNote = !!(notes.newNote && notes.checkForText(notes.newNote));

    if (notes.newNotes === undefined) {
      if (newNoteFromRow) {
        result = newNoteFromRow && notes.checkForText(newNoteFromRow);
      }
    } else if (stateNote) {
      result = true;
    } else {
      result = false;
    }

    return result;
  };

  useEffect(() => {
    addOrEditButton();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes.newNote]);

  return (
    <div>

      {iconButton && (
        <Tooltip title="Edit note">
          <span>
            <IconButton onClick={handleNotes} size="small">
              <EditNoteIconRed style={{ fontSize: 28 }} />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {!iconButton && (
        <ButtonBase
          onClick={handleNotes}
        >
          <Typography className={classes.editButtonText} variant="body2">
            {(addOrEditButton())
              ? 'Edit note'
              : 'Add note'}
          </Typography>
        </ButtonBase>
      )}

      {profile && (
      <SlidingPane
        className={classes.slidingPane}
          // overlayClassName="some-custom-overlay-class"
        isOpen={paneState.isPaneOpen}

          // subtitle="Optional subtitle."
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setPaneState({ isPaneOpen: false });
        }}
      >
        <Link href={`/profile/${btoa(profile.networkId())}`} target="_blank" className={classes.paneLink}>
          <div className={classes.paneHeader}>
            {`< View ${profile.data.full_name[0]}'s Profile`}
            <hr className={classes.slideHr} />
          </div>
        </Link>
        <EditNoteContent
          networkId={networkId}
          onSuccess={handleSuccess}
          profile={profile}
          onProfilePage={false}
          newNoteFromRow={newNoteFromRow}
          lastSavedNoteFromRow={lastSavedNoteFromRow}

        />
      </SlidingPane>
      )}

    </div>
  );
};

EditNoteButton.propTypes = {
  iconButton: PropTypes.bool,
  networkId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  profile: PropTypes.shape().isRequired,
  newNoteFromRow: PropTypes.string.isRequired,
  lastSavedNoteFromRow: PropTypes.string.isRequired,
};

EditNoteButton.defaultProps = {
  iconButton: true,
};

export default EditNoteButton;

export { EditNoteContent };
