import React from 'react';

import { SvgIcon } from '@material-ui/core';

const Search = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <path
      style={{ strokeWidth: 2.0629437 }}
      id="path2"
      d="m 17.229754,15.521408 6.486163,6.498356 c 0.195774,0.231462 0.296652,0.528319 0.28283,0.83116 -0.01362,0.30284 -0.141312,0.589383 -0.357096,0.802278 -0.21599,0.212896 -0.504183,0.33626 -0.80723,0.34575 -0.303046,0.0095 -0.59846,-0.09572 -0.82724,-0.294589 L 15.532982,17.230206 C 13.598807,18.732731 11.164658,19.441558 8.7261961,19.212365 6.2877347,18.983172 4.0283161,17.833205 2.4079563,15.996587 0.78762343,14.159989 -0.07179487,11.774855 0.00470113,9.3268416 0.08119713,6.8788286 1.0878559,4.5520136 2.8196993,2.8201725 4.5515612,1.0883127 6.8783761,0.0816539 9.3263889,0.0051579 c 2.4480131,-0.076494 4.8331471,0.7829222 6.6697451,2.4032717 1.836598,1.620339 2.986586,3.879758 3.215779,6.318219 0.229172,2.4384614 -0.479635,4.8726114 -1.982159,6.8067864 z m -7.6053935,1.323729 c 1.9149485,0 3.7514635,-0.76071 5.1055385,-2.114785 1.354075,-1.354075 2.114765,-3.19059 2.114765,-5.1055184 0,-1.914948 -0.76069,-3.751463 -2.114765,-5.105538 -1.354075,-1.3540751 -3.19059,-2.114765 -5.1055385,-2.114765 -1.9149275,0 -3.7514425,0.7606899 -5.1055176,2.114765 -1.354075,1.354075 -2.1147855,3.19059 -2.1147855,5.105538 0,1.9149284 0.7607105,3.7514434 2.1147855,5.1055184 1.3540751,1.354075 3.1905901,2.114785 5.1055176,2.114785 z"
    />
  </SvgIcon>
);

export default Search;
