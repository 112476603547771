import React from 'react';
import PropTypes from 'prop-types';

import {
  View,
  Text,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';

import { textStyles } from '../definitions';
import { PdfPage, Header } from '../components';

const styles = StyleSheet.create({
  col: {
    flex: 1,
    maxWidth: '50%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flex: 1,
  },
  imgWrapper: {
    width: '100%',
    height: '100%',
    padding: 0,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
});

const Page1 = ({ formData }) => {
  let nameStyle = textStyles.h1;
  if (formData.personalDetails.firstName.length > 8 || formData.personalDetails.lastName.length > 8) {
    nameStyle = textStyles.h2;
  }

  return (
    <PdfPage>
      <View style={styles.col}>
        <View style={styles.imgWrapper}>
          <Image src={formData.personalDetails.mainImg} style={styles.image} />
        </View>
      </View>
      <View style={{ ...styles.col, padding: 50 }}>
        <View style={styles.header}>
          <Text style={textStyles.body}>MEDIA KIT 2021</Text>
          <Text style={textStyles.body}>
            {formData.personalDetails.instagramHandle ? `@${formData.personalDetails.instagramHandle}` : ''}
          </Text>
        </View>
        <View style={styles.main}>
          <Text style={{ ...nameStyle, marginBottom: 20 }}>
            {formData.personalDetails.firstName}
            {'\n'}
            {formData.personalDetails.lastName}
          </Text>
          <Text style={textStyles.h5}>{formData.personalDetails.professionalTitle}</Text>
        </View>
        <Header align="left">{formData.personalDetails.websiteUrl}</Header>
      </View>
    </PdfPage>
  );
};

Page1.propTypes = {
  formData: PropTypes.shape().isRequired,
};

export default Page1;
