import React, { useState, useEffect } from 'react';
// import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  Button,
  DialogContent,
  DialogActions,
  Radio,
  Typography,
  MenuItem,
  Select,
  Checkbox,
  Avatar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AtTextField from './AtTextField';
import ScrDialog from '../common/ScrDialog';
import {
  useLists,
  useAuth,
  useContentManager,
  useNotification,
} from '../../hooks';
import API from '../../utils/api';
import Profile from '../../utils/profile';
import NetworkIcon from '../common/icons/NetworkIcon';

const useStyles = makeStyles(() => ({
  btn: {
    width: '275px',
    height: '45px',
  },
  radios: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  profCont: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    // width: "100%",
    overflowY: 'auto',
  },
  profs: {
    display: 'flex',
    margin: '15px 0',
  },
}));

const AddNewInfluencerDialog = ({ onClose }) => {
  const classes = useStyles();
  const auth = useAuth();
  const lists = useLists();
  const content = useContentManager();
  const { createNotification } = useNotification();
  const [importFromLists, setImportFromLists] = useState(false);
  const [listArr, setListArr] = useState();
  const [selectedList, setSelectedList] = useState('');
  const [profiles, setProfiles] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [newInfs, setNewInfs] = useState([]);
  const [allChecked, setAllChecked] = useState(false);

  // get lists
  useEffect(() => {
    if (lists.lists.length < 1) {
      lists.refreshLists(auth.account.id);
    }
  }, [auth.account.id]);

  // set list array
  useEffect(() => {
    if (lists.lists.length > 0) {
      setListArr(lists.lists.map((list) => list.name));
    }
  }, [lists.lists]);

  const handleChange = async (value) => {
    setIsFetching(true);
    const selected = lists.lists.find((list) => list.name === value);
    try {
      const response = await API({
        method: 'post',
        url: `/account/${auth.account.id}/list/${selected.id}/search/`,
        data: {
          from: 0,
          size: 999,
          include_stats: true,
        },
      });
      // TODO
      if (response.status === 200) {
        const resProfiles = response.data.data.profiles;
        setProfiles(resProfiles.map((it) => Profile(it)));
      }
      setIsFetching(false);
      setSelectedList(value);
    } catch (e) {
      createNotification('Error', `List Search Failed: ${e}`);
    }
  };

  const addInfsToCampaign = async () => {
    setIsFetching(true);
    try {
      const response = await API({
        method: 'post',
        url: `/account/${auth.account.id}/content-manager/brand/${content.brand.id}/campaign/${content.campaign.id}/add_influencers/`,
        data: {
          network_ids: newInfs,
        },
      });
      if (response.status === 200) {
        createNotification('success', 'Infuencers Added');
        content.getCampaignAndPosts(auth.account.id, content.brand.id, content.campaign.id);
        onClose();
      }
    } catch (e) {
      createNotification('error', 'Couldnt Add Influencers');
      setIsFetching(false);
      onClose();
    }
    onClose();
    setIsFetching(false);
  };

  const selectAllInfs = () => {
    if (!allChecked) {
      const newArr = profiles.map((p) => p.networkId());
      setNewInfs(newArr);
      setAllChecked(true);
    } else {
      setAllChecked(false);
      setNewInfs([]);
    }
  };

  const checkChanged = (p) => {
    if (newInfs.includes(p.networkId())) {
      // setAllChecked(false);
      setNewInfs(newInfs.filter((i) => i !== p.networkId()));
    } else {
      setNewInfs([...newInfs, p.networkId()]);
    }
  };

  useEffect(() => {
    // console.log(newInfs);
  }, [newInfs]);

  return (
    <>
      <ScrDialog
        minWidth={1000}
        isOpen
        onClose={onClose}
        isFetching={isFetching}
        title="Add A New Influencer"
        subTitle="Add an influencer to a campaign by entering their instagram @username of by importing them from lists"
        titleStyle={{ marginBottom: '10px' }}
      >
        <DialogContent style={{ alignSelf: 'center', width: '90%' }}>
          <div className={classes.radios}>
            <Radio
              checked={!importFromLists}
              onChange={() => setImportFromLists(false)}
              color="primary"
            />
            <Typography style={{ marginRight: '20px' }}>
              Import Using Instagram Username
            </Typography>
            <Radio
              checked={importFromLists}
              onChange={() => setImportFromLists(true)}
              color="primary"
            />
            <Typography>Import From Lists</Typography>
          </div>
          {!importFromLists && (
            <>
              <Typography variant="body1" component="span">
                Instagram Username
              </Typography>

              <AtTextField />
            </>
          )}

          {importFromLists && (
            <>
              <Typography variant="body1" component="span">
                Select a list
              </Typography>

              <Select
                margin="dense"
                type="select"
                fullWidth
                required
                displayEmpty
                value={selectedList}
                defaultValue=""
                style={{ height: '45px', marginTop: '5px' }}
                onChange={(e) => handleChange(e.target.value)}
              >
                <MenuItem disabled value="">
                  {' '}
                  Select a List
                  {' '}
                </MenuItem>
                {lists.fetching && (
                  <MenuItem disabled value="loading">
                    {' '}
                    Loading...
                    {' '}
                  </MenuItem>
                )}
                {!lists.fetching
                  && listArr
                  && listArr.map((list) => (
                    <MenuItem key={list} value={list}>
                      {list}
                    </MenuItem>
                  ))}
              </Select>
              {profiles && (
                <>
                  <Checkbox onChange={selectAllInfs} checked={allChecked} />
                  <Typography style={{ display: 'inline', margin: '15px 0' }}>
                    {' '}
                    Select profiles to import
                  </Typography>
                  <div className={classes.profCont}>
                    {profiles.map((p) => (
                      <div key={p.networkId()}>
                        <div className={classes.profs}>
                          <Checkbox
                            checked={
                              newInfs.includes(p.networkId())
                              || (content.campaign
                                && content.campaign.network_ids.includes(
                                  p.networkId(),
                                ))
                            }
                            disabled={
                              content.campaign
                              && content.campaign.network_ids.includes(p.networkId())
                            }
                            onChange={() => checkChanged(p)}
                          />
                          <Avatar
                            src={p.profilePicture()}
                            alt={p.fullName()}
                            style={{
                              height: '50px',
                              width: '50px',
                              marginRight: '15px',
                            }}
                          />
                          <div>
                            <Typography style={{ marginBottom: '10px' }}>
                              {p.fullName()}
                            </Typography>
                            <NetworkIcon
                              network="instagram"
                              style={{ height: '15px' }}
                            />
                            <Typography style={{ display: 'inline' }}>
                              {p.socialReach()}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.btn}
            disabled={newInfs.length < 1}
            color="primary"
            variant="contained"
            onClick={addInfsToCampaign}
          >
            Add Now
          </Button>
        </DialogActions>
      </ScrDialog>
    </>
  );
};

AddNewInfluencerDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AddNewInfluencerDialog;
