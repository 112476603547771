import React from 'react';

import { SvgIcon } from '@material-ui/core';

const CaretDown = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <path
      style={{
        strokeWidth: 3.42363143,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
      }}
      id="path68"
      d="M 1.7291058,6.8732333 12,17.144127 22.270894,6.8732333"
    />
  </SvgIcon>
);

export default CaretDown;
