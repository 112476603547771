const checkUnique = (infObjects) => {
  const newArr = [];
  let unique = true;
  infObjects.forEach((inf) => {
    if (newArr.length === 0) {
      newArr.push(inf);
    } else {
      newArr.forEach((u) => {
        if (inf.id === u.id) {
          unique = false;
        }
      });
      if (unique) {
        newArr.push(inf);
      }
    }
  });
  return newArr;
};

const typesOfPosts = (postObjects) => {
  const newArr = [];
  let unique = true;
  postObjects.forEach((post) => {
    if (newArr.length === 0) {
      newArr.push(post);
    } else {
      newArr.forEach((u) => {
        if (post.content_type === u.content_type) {
          unique = false;
        }
      });
      if (unique) {
        newArr.push(post);
      }
    }
  });
  return newArr;
};

const typesOfPostsTally = (postObjects) => {
  const newObj = {};
  postObjects.forEach((post) => {
    if (!newObj[post.content_type]) {
      newObj[post.content_type] = 1;
    } else {
      newObj[post.content_type] += 1;
    }
  });
  return newObj;
};

export { checkUnique, typesOfPosts, typesOfPostsTally };
