import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
  },
  header: {
    padding: 25,
  },
  nav: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10,
  },
  invertedBtn: {
    border: `1px solid ${theme.colors.primary.main}`,
    borderRadius: 4,
    padding: '7px 15px',
    marginRight: 15,
  },
  breadcrumWraper: {
    display: 'flex',
    alignItems: 'center',
  },
  breadcrumRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
  breadcrumIndex: {
    width: 25,
    height: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    border: (props) => (!props.selected ? `1px solid ${theme.colors.system.grey3}` : `1px solid ${theme.colors.primary.main}`),
    backgroundColor: (props) => (props.selected ? theme.colors.primary.main : '#fff'),
    color: (props) => (props.selected ? '#fff' : theme.colors.primary.main),
    marginRight: 10,
    '@media (max-width: 1280px)': {
      minWidth: 25,
      minHeight: 25,
      margin: 0,
    },
  },
  breadcrumLine: {
    width: 15,
    height: 1,
    backgroundColor: (props) => (props.selected ? theme.colors.primary.main : theme.colors.system.grey7),
    marginRight: 10,
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1.8fr 1fr',
    gridGap: 20,
    flex: 1,
    padding: '0 25px',
    overflowY: 'hidden',
  },
  formWrapper: {
    backgroundColor: theme.colors.system.grey1,
    border: `1px solid ${theme.colors.system.grey3}`,
    borderRadius: 4,
    maxHeight: '100%',
    overflowY: 'auto',
  },
  sectionRoot: {
    padding: '25px 20px',
    borderBottom: (props) => (props.bb ? `1px solid ${theme.colors.system.grey3}` : 'none'),
  },
  sectionTitle: {
    ...theme.textStyles.bodyLarge,
    marginBottom: 25,
  },
  formLabel: {
    fontWeight: 600,
    marginBottom: 15,
  },
  uploadWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 25,
  },
  imgAvatar: {
    width: 80,
    height: 80,
    marginRight: 25,
  },
  delImgBtn: {
    color: theme.colors.error.main,
    borderColor: theme.colors.error.main,
    padding: 8,
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    backgroundColor: '#fff',
    minHeight: 42.5,
  },
  adornment: {
    backgroundColor: theme.colors.system.grey3,
    padding: '5px 10px',
    height: 42.5,
    maxHeight: 42.5,
  },
  delIcon: {
    color: theme.colors.danger.main,
    marginTop: 20,
  },
  sectionListTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  delIconTitle: {
    color: theme.colors.danger.main,
    marginBottom: 25,
  },
  uploadBtn: {
    backgroundColor: theme.colors.system.grey3,
    padding: 10,
  },
  prevTitle: {
    ...theme.textStyles.bodyLarge,
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '25px 20px',
  },
  backButton: {
    backgroundColor: theme.colors.system.grey5,
    padding: 11,
  },
}));

const previewStyles = makeStyles((theme) => ({
  h1: {
    ...theme.fonts.tertiary,
    fontSize: 35,
    lineHeight: '35px',
    '@media (max-width: 1280px)': {
      fontSize: 28,
      lineHeight: '28px',
    },
  },
  h2: {
    ...theme.fonts.tertiary,
    fontSize: 20,
    lineHeight: '25px',
    '@media (max-width: 1280px)': {
      fontSize: 15,
      lineHeight: '24px',
      letterSpacing: -1,
    },
  },
  h3: {
    ...theme.fonts.tertiary,
    fontSize: 15,
    lineHeight: '18px',
    '@media (max-width: 1280px)': {
      fontSize: 13,
      lineHeight: '18px',
      letterSpacing: -1,
    },
  },
  h4: {
    ...theme.fonts.tertiary,
    fontSize: 10,
    lineHeight: '12px',
    '@media (max-width: 1280px)': {
      fontSize: 8,
      lineHeight: '14px',
      letterSpacing: -1,
    },
  },
  h5: {
    ...theme.fonts.tertiary,
    fontSize: 8,
    lineHeight: '9px',
    '@media (max-width: 1280px)': {
      fontSize: 6,
      lineHeight: '10px',
    },
  },
  body: {
    ...theme.fonts.primary,
    fontSize: 5,
    lineHeight: '6px',
    '@media (max-width: 1280px)': {
      fontSize: 4,
      lineHeight: '6px',
    },
  },
  bodyLarge: {
    ...theme.fonts.primary,
    fontSize: 7,
    lineHeight: '9px',
    '@media (max-width: 1280px)': {
      fontSize: 6,
      lineHeight: '10px',
    },
  },
}));

export { useStyles, previewStyles };
