import React from 'react';

import { SvgIcon } from '@material-ui/core';

const Import = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <path
      style={{ strokeWidth: 2 }}
      id="path2"
      d="M 9.6,0 V 10.8 H 4.8 L 12,18 19.2,10.8 H 14.4 V 0 Z M 0,21.6 V 24 h 24 v -2.4 z"
    />
  </SvgIcon>
);

export default Import;
