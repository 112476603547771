import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';

import { MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FilterBase from './FilterBase';
import AudienceLocationSelect from '../../common/AudienceLocationSelect';
import Select from '../../common/Select';
import { useApi } from '../../../hooks';
import { formatPercentage } from '../../../utils/number';
import { decodeRange, encodeRange } from '../../../utils/string';

const probabilities = [0, 0.1, 0.25, 0.5, 0.75];

const useStyles = makeStyles((theme) => ({
  inputs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  input: {
    width: 150,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  text: {
    width: 100,
    color: theme.palette.grey[600],
    textAlign: 'center',
  },
  locationSelect: {
    width: 300,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const AudienceLocationFilter = (props) => {
  const {
    className,
    filterData,
    filterKeys,
    label,
    onApply,
  } = props;
  const [value, setValue] = useState(null);
  const [probability, setProbability] = useState(probabilities[0]);
  const [{ data, status }, postSearchLocationRequest] = useApi();
  const classes = useStyles();

  const location = useMemo(
    () => (!_.isEmpty(filterData[filterKeys[0]]) && status === 200 ? data : null),
    [data, filterData, filterKeys, status],
  );
  const activeText = useMemo(
    () => (location ? `${location.label} >= ${formatPercentage(probability)}` : null),
    [location, probability],
  );

  useEffect(() => {
    const { [filterKeys[0]]: locationData } = filterData;
    if (!_.isEmpty(locationData)) {
      const locationId = _.keys(locationData)[0];
      const [min] = decodeRange(locationData[locationId]);
      setProbability(min);
      postSearchLocationRequest({
        method: 'get',
        url: `/location/by-id/${locationId}/`,
      });
    }
  }, [filterData, filterKeys, postSearchLocationRequest]);

  useEffect(() => {
    if (status === 200) {
      setValue(data);
    }
  }, [data, status]);

  const handleOnApply = () => {
    if (value && value.geoname_id) {
      onApply({
        ...filterData,
        [filterKeys[0]]: {
          [value.geoname_id]: encodeRange(probability, null),
        },
      });
    } else {
      onApply({ ...filterData, [filterKeys[0]]: null });
    }
  };

  const handleOnClear = () => {
    setProbability(probabilities[0]);
    setValue(null);
    onApply({ ...filterData, [filterKeys[0]]: null });
  };

  return (
    <FilterBase
      className={className}
      label={label}
      activeText={activeText}
      isActive={Boolean(activeText)}
      onApply={handleOnApply}
      onClear={handleOnClear}
    >
      <div className={classes.inputs}>
        <AudienceLocationSelect
          className={classes.locationSelect}
          multiple={false}
          onChange={setValue}
          value={value}
        />
        <Typography className={classes.text} variant="body2">
          greater than or equal to
        </Typography>
        <Select
          className={classes.input}
          type="number"
          label="Percentage"
          value={probability}
          onChange={(e) => setProbability(e.target.value !== '' ? e.target.value : null)}
        >
          {probabilities.map((it) => (
            <MenuItem key={it} value={it}>
              {formatPercentage(it)}
            </MenuItem>
          ))}
        </Select>
      </div>
    </FilterBase>
  );
};

AudienceLocationFilter.propTypes = {
  className: PropTypes.string,
  filterData: PropTypes.shape().isRequired,
  filterKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  onApply: PropTypes.func.isRequired,
};

AudienceLocationFilter.defaultProps = {
  className: '',
};

export default AudienceLocationFilter;
