// Main dashboard add new brand dialog
//  Add New Post pane
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import API from '../../utils/api';
import { useAuth, useNotification } from '../../hooks';
import AtTextField from './AtTextField';

import ScrDialog from '../common/ScrDialog';

const useStyles = makeStyles((theme) => ({
  deleteDialogBtn: {
    backgroundColor: theme.colors.primary.main,
    color: '#FFFFFF',
    minWidth: 240,
    minHeight: 40,
    ...theme.textStyles.bodySmall,
  },
  dialogCont: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const NewBrandProfileDialog = (props) => {
  const { newBrandIsOpen, closeDialog /* refresh */ } = props;
  const classes = useStyles();
  const auth = useAuth();
  const { createNotification } = useNotification();
  const [username, setUsername] = useState('');

  const handleCloseDialog = () => {
    setUsername('');
    if (username) {
      closeDialog(true);
    } else {
      closeDialog();
    }
  };

  const handleCreate = async () => {
    // console.log(username);
    try {
      const response = await API({
        method: 'post',
        url: `account/${auth.account.id}/content-manager/`,
        data: {
          username,
        },
      });
      if (response.status === 200) {
        // console.log(response);
        createNotification('success', 'Brand Added');
        handleCloseDialog();
      }
      // console.log(response);
    } catch (e) {
      // console.log(e);
      createNotification('error', 'Unable to Add Brand');
    }
  };

  const handleCreateTest = async () => {
    try {
      const response = await API({
        method: 'post',
        url: `account/${auth.account.id}/content-manager/instagram/username_search/`,
        data: {
          usernames: ['therock'],
        },
      });
      if (response.status === 200) {
        // console.log(response);
      }
      // console.log(response);
    } catch (e) {
      // console.log(e);
    }
  };

  return (
    <ScrDialog
      isOpen={newBrandIsOpen}
      onClose={handleCloseDialog}
      title="New Brand Profile"
      subTitle="Create a brand's profile by entering their Instagram @username"
      minWidth={800}
      titleStyle={{ marginBottom: '10px' }}
    >
      <DialogContent className={classes.dialogCont}>
        <AtTextField
          width="500px"
          handleChange={(e) => setUsername(e.target.value)}
          value={username}
        />
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreate}
          disabled={username.length < 3}
          className={classes.deleteDialogBtn}
        >
          Create Now
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateTest}
          className={classes.deleteDialogBtn}
        >
          Ignore Button, left here for username search test
        </Button>
      </DialogActions>
    </ScrDialog>
  );
};

NewBrandProfileDialog.propTypes = {
  newBrandIsOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default NewBrandProfileDialog;
