import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { defaultFormData } from '../data';

import { useStyles } from './styles';

/* ----------------------------------- COLLABORATIONS - BRANDS --------------------------------*/
const BrandsForm = ({
  formData,
  setFormData,
  setHighlighted,
  finalData,
  setFinalData,
}) => {
  const classes = useStyles({ bb: true });

  useEffect(() => {
    document.getElementById('formWrapper').scrollTo(0, 0);
  }, []);

  const onBrandNameChange = (e, index) => {
    const brandsTemp = [...formData.brandsAndTestimonials.brands];
    brandsTemp[index].brand = e.target.value;
    const formDataTemp = { ...formData };
    formDataTemp.brandsAndTestimonials.brands = brandsTemp;

    const finalBrandsTemp = [...finalData.brandsAndTestimonials.brands];
    finalBrandsTemp[index].brand = e.target.value;
    const finalDataTemp = { ...finalData };
    finalDataTemp.brandsAndTestimonials.brands = finalBrandsTemp;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const addBrandName = () => {
    const usedIds = formData.brandsAndTestimonials.brands.map((item) => item.id);
    const availableIds = formData.brandIds
      .filter((x) => !usedIds.includes(x))
      .concat(usedIds.filter((x) => !formData.brandIds.includes(x)));

    const brandsTemp = [
      ...formData.brandsAndTestimonials.brands,
      { id: availableIds[0], brand: '' },
    ];
    const formDataTemp = { ...formData };
    formDataTemp.brandsAndTestimonials.brands = brandsTemp;

    const finalBrandsTemp = [
      ...finalData.brandsAndTestimonials.brands,
      { id: availableIds[0], brand: '' },
    ];
    const finalDataTemp = { ...finalData };
    finalDataTemp.brandsAndTestimonials.brands = finalBrandsTemp;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const deleteBrandName = (index) => {
    const brandsTemp = formData.brandsAndTestimonials.brands.filter((_, i) => i !== index);
    const formDataTemp = { ...formData };
    formDataTemp.brandsAndTestimonials.brands = brandsTemp;

    const finalBrandsTemp = finalData.brandsAndTestimonials.brands.filter((_, i) => i !== index);
    const finalDataTemp = { ...finalData };
    finalDataTemp.brandsAndTestimonials.brands = finalBrandsTemp;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  return (
    <div className={classes.sectionRoot}>
      <Typography
        variant="body1"
        component="div"
        color="primary"
        className={classes.sectionTitle}
      >
        Brands To Showcase
      </Typography>

      {formData.brandsAndTestimonials.brands.map((brand, index) => (
        <div className={classes.flexRow} style={{ marginBottom: 25 }} key={brand.id}>
          <TextField
            label="Brand Name"
            value={brand.brand}
            placeholder={defaultFormData.brandsAndTestimonials.brands[index].brand}
            InputProps={{ className: classes.input }}
            fullWidth
            onChange={(e) => onBrandNameChange(e, index)}
            onFocus={() => setHighlighted('brands')}
          />
          {formData.brandsAndTestimonials.brands.length !== 1 && (
            <IconButton
              aria-label="delete"
              className={classes.delIcon}
              onClick={() => deleteBrandName(index)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      ))}

      {formData.brandsAndTestimonials.brands.length < 10 && (
        <Button
          color="primary"
          size="small"
          onClick={addBrandName}
        >
          + Add Another Brand
        </Button>
      )}
    </div>
  );
};

BrandsForm.propTypes = {
  formData: PropTypes.shape().isRequired,
  setFormData: PropTypes.func.isRequired,
  setHighlighted: PropTypes.func.isRequired,
  finalData: PropTypes.shape().isRequired,
  setFinalData: PropTypes.func.isRequired,
};

/* ----------------------------------- COLLABORATIONS - TESTIMONIALS --------------------------------*/
const TestimonialForm = ({
  formData,
  setFormData,
  setHighlighted,
  finalData,
  setFinalData,
}) => {
  const classes = useStyles();

  useEffect(() => {
    document.getElementById('formWrapper').scrollTo(0, 0);
  }, []);

  const onRecommendationChange = (e) => {
    const formDataTemp = { ...formData };
    formDataTemp.brandsAndTestimonials.testimonial.recommendation = e.target.value;

    const finalDataTemp = { ...finalData };
    finalDataTemp.brandsAndTestimonials.testimonial.recommendation = e.target.value;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const onRecommenderNameChage = (e) => {
    const formDataTemp = { ...formData };
    formDataTemp.brandsAndTestimonials.testimonial.recommenderName = e.target.value;

    const finalDataTemp = { ...finalData };
    finalDataTemp.brandsAndTestimonials.testimonial.recommenderName = e.target.value;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const onBrandNameChange = (e) => {
    const formDataTemp = { ...formData };
    formDataTemp.brandsAndTestimonials.testimonial.brandName = e.target.value;

    const finalDataTemp = { ...finalData };
    finalDataTemp.brandsAndTestimonials.testimonial.brandName = e.target.value;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const onRecommenderTitleChange = (e) => {
    const formDataTemp = { ...formData };
    formDataTemp.brandsAndTestimonials.testimonial.recommenderTitle = e.target.value;

    const finalDataTemp = { ...finalData };
    finalDataTemp.brandsAndTestimonials.testimonial.recommenderTitle = e.target.value;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  return (
    <div className={classes.sectionRoot}>
      <Typography
        variant="body1"
        component="div"
        color="primary"
        className={classes.sectionTitle}
      >
        Testmonial
      </Typography>

      <TextField
        label="Recommendation"
        value={formData.brandsAndTestimonials.testimonial.recommendation}
        placeholder={defaultFormData.brandsAndTestimonials.testimonial.recommendation}
        style={{ marginBottom: 25 }}
        InputProps={{ className: classes.input }}
        multiline
        rows={10}
        fullWidth
        onChange={onRecommendationChange}
        onFocus={() => setHighlighted('testimonial.recommendation')}
      />

      <div className={classes.flexRow} style={{ marginBottom: 25 }}>
        <TextField
          label="Recommender’s Name"
          value={formData.brandsAndTestimonials.testimonial.recommenderName}
          placeholder={defaultFormData.brandsAndTestimonials.testimonial.recommenderName}
          style={{ width: '48%', marginRight: '4%' }}
          InputProps={{ className: classes.input }}
          onChange={onRecommenderNameChage}
          onFocus={() => setHighlighted('testimonial.recommenderName')}
        />
        <TextField
          label="Recommender’s Company/ Brand Name "
          value={formData.brandsAndTestimonials.testimonial.brandName}
          placeholder={defaultFormData.brandsAndTestimonials.testimonial.brandName}
          style={{ width: '48%', marginRight: '4%' }}
          InputProps={{ className: classes.input }}
          onChange={onBrandNameChange}
          onFocus={() => setHighlighted('testimonial.brandName')}
        />
      </div>

      <TextField
        label="Recommender’s Professional Title"
        value={formData.brandsAndTestimonials.testimonial.recommenderTitle}
        placeholder={defaultFormData.brandsAndTestimonials.testimonial.recommenderTitle}
        InputProps={{ className: classes.input }}
        fullWidth
        onChange={onRecommenderTitleChange}
        onFocus={() => setHighlighted('testimonial.recommenderTitle')}
      />
    </div>
  );
};

TestimonialForm.propTypes = {
  formData: PropTypes.shape().isRequired,
  setFormData: PropTypes.func.isRequired,
  setHighlighted: PropTypes.func.isRequired,
  finalData: PropTypes.shape().isRequired,
  setFinalData: PropTypes.func.isRequired,
};

export {
  BrandsForm,
  TestimonialForm,
};
