import _ from 'lodash';

const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

const ellipsize = (s, length) => {
  if (_.isString(s) && s.length > length) {
    return `${s.slice(0, length).trim()}...`;
  }
  return s;
};

const decodeRange = (rangeString) => {
  const sp = rangeString ? rangeString.split(',') : [];
  return [
    sp.length && sp[0] ? parseFloat(sp[0]) : null,
    sp.length > 1 && sp[1] ? parseFloat(sp[1]) : null,
  ];
};

const encodeRange = (min, max) => `${_.isNumber(min) ? min : ''},${_.isNumber(max) ? max : ''}`;

const capitalise = (string) => {
  const result = string[0].toUpperCase() + string.slice(1);
  return result;
};

const capitalizeName = (firstName, lastName) => {
  let result;
  if (firstName && lastName) {
    result = ` ${firstName[0].toUpperCase()}${firstName.slice(
      1,
    )} ${lastName[0].toUpperCase()}${lastName.slice(1)}`;
  }
  return result;
};

export {
  capitalize,
  decodeRange,
  ellipsize,
  encodeRange,
  isValidEmail,
  capitalise,
  capitalizeName,
};
