import React from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  ButtonBase,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { Link } from '@reach/router';

// import FileCopyIcon from '@material-ui/icons/FileCopy';
import DescriptionIcon from '@material-ui/icons/Description';
import LockIcon from '@material-ui/icons/Lock';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DashboardIcon from '@material-ui/icons/Dashboard';
// import PostAddIcon from '@material-ui/icons/PostAdd';
import DiscoverIcon from '../common/icons/Discover';
import ListIcon from '../common/icons/List';
import MenuIcon from '../common/icons/Menu';
import UniversityIcon from '../common/icons/University';
import SparkIcon from '../common/icons/Spark';
import ImportProfilesButton from '../list/ImportProfilesButton';
import ScrunchLogoFull from '../../assets/images/scrunch-logo-full.svg';
import { useAuth, useRoute } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  sidebar: {
    display: 'grid',
    gridTemplateRows: (props) => `${props.barHeight}px 1fr auto`,
    width: (props) => props.width,
    backgroundColor: theme.colors.system.grey3,
    '&>div': {
      padding: (props) => (props.compact ? 0 : ' 10px 10px 10px 10px'),
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    '&>:not(:last-child)': {
      marginBottom: 10,
    },
    overflowX: 'hidden',
  },
  footer: {
    textAlign: 'center',
  },
  headerLogo: {
    paddingLeft: theme.spacing(1),
    height: 20,
  },
  headerButton: {
    alignItems: 'center',
  },
  menuItemButton: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    padding: '10px 15px 10px 15px',
    borderRadius: 2,
    backgroundColor: (props) => (props.selected ? theme.colors.system.grey1 : null),
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.colors.system.grey2,
    },
  },
  menuItemIcon: {
    width: 15,
    height: 15,
  },
  menuItemLabel: {
    fontWeight: 'bold',
    marginLeft: 15,
  },
  menuItemSubItems: {
    margin: '10px 10px 0px 10px',
    padding: '15px 0px 15px 0px',
    borderRadius: 2,
    backgroundColor: theme.colors.system.grey2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',

    '&>:not(:last-child)': {
      marginBottom: 5,
    },
  },
  menuSubItemButton: {
    width: '100%',
    justifyContent: 'start',
    padding: '7px 20px 7px 20px',
    color: (props) => (props.selected ? theme.palette.primary.main : null),
    backgroundColor: (props) => (props.selected ? theme.colors.system.grey1 : null),

    '&:hover': {
      backgroundColor: theme.colors.system.grey3,
    },
  },
  menuSubItemLabel: {
    fontWeight: 'bold',
  },
  lockIcon: {
    color: theme.colors.system.grey7,
    fontSize: 14,
    mrginLeft: 15,
  },
  menuLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
  },
}));

const SidebarMenuItem = (props) => {
  const {
    children,
    compact,
    Icon,
    label,
    selected,
    to,
    lock,
  } = props;
  const classes = useStyles({ selected });

  const Component = (
    <div className={classes.menuItem}>
      <ButtonBase
        className={classes.menuItemButton}
        component={Link}
        to={to}
      >
        {Icon && (
          <Icon className={classes.menuItemIcon} />
        )}
        {!compact && (
          <div className={classes.menuLabel}>
            <Typography className={classes.menuItemLabel} noWrap variant="body2">{label}</Typography>
            {lock && (
              <LockIcon className={classes.lockIcon} />
            )}
          </div>
        )}
      </ButtonBase>
      {!compact && !_.isEmpty(children) && (
        <div className={classes.menuItemSubItems}>
          {children}
        </div>
      )}
    </div>
  );

  if (compact) {
    return (
      <Tooltip arrow placement="right" title={label}>
        {Component}
      </Tooltip>
    );
  }

  return Component;
};
//
SidebarMenuItem.propTypes = {
  children: PropTypes.node,
  compact: PropTypes.bool.isRequired,
  Icon: PropTypes.elementType,
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  to: PropTypes.string.isRequired,
  lock: PropTypes.bool,
};

SidebarMenuItem.defaultProps = {
  children: null,
  Icon: null,
  lock: false,
};

const SidebarMenuSubItem = ({ label, selected, to }) => {
  const classes = useStyles({ selected });
  return (
    <ButtonBase
      className={classes.menuSubItemButton}
      component={Link}
      to={to}
    >
      <Typography className={classes.menuSubItemLabel} noWrap variant="body2">{label}</Typography>
    </ButtonBase>
  );
};

SidebarMenuSubItem.propTypes = {
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  to: PropTypes.string.isRequired,
};

const Sidebar = (props) => {
  const {
    barHeight,
    className,
    compact,
    onSidebarButtonClick,
    width,
  } = props;
  const classes = useStyles({ barHeight, compact, width });
  const auth = useAuth();
  const [route] = useRoute();

  // Commented out list stuff if from old sidebarvisual feature
  // const [{ data: listsData }, setRequest] = useApi();

  // useEffect(() => {
  //   setRequest({ method: 'get', url: `/account/${auth.account.id}/list/`, params: { from: 0, size: 5 } });
  // }, [auth, setRequest]);

  // useEffect(() => {
  //   if (listsData && !_.isEqual(lists.lists, listsData.lists)) {
  //     lists.setLists(listsData.lists);
  //   }

  return (
    <div className={`${className} ${classes.sidebar}`}>
      <div className={classes.header}>
        {!compact && (
          <img
            className={classes.headerLogo}
            alt="Scrunch"
            src={ScrunchLogoFull}
          />
        )}
        <div className={classes.headerButton}>
          <IconButton onClick={onSidebarButtonClick}>
            {compact ? <MenuIcon /> : <MenuOpenIcon />}
          </IconButton>
        </div>
      </div>
      <div className={classes.content}>
        {/* Hide Dashboard until built */}
        {auth.account.type === 'influencer' && (
          <SidebarMenuItem
            compact={compact}
            Icon={DashboardIcon}
            label="Dashboard"
            selected={route.pathname === '/'}
            to="/"
          />
        )}
        {auth.account.type !== 'influencer'
          && auth.hasPermission('profile.search') && (
            <SidebarMenuItem
              compact={compact}
              Icon={DiscoverIcon}
              label="Discover"
              selected={route.pathname === '/discover'}
              to="/discover"
            />
        )}
        {auth.account.type !== 'influencer' && (
          <SidebarMenuItem
            compact={compact}
            Icon={ListIcon}
            label="Lists"
            selected={route.pathname === '/list'}
            to="/list"
          />
        )}
        {/* //   {lists.lists && lists.lists.slice(0, 5).map((list) => (
          //     <SidebarMenuSubItem
          //       key={list.id}
          //       label={list.name}
          //       selected={route.pathname === `/list/${list.id}`}
          //       to={`/list/${list.id}`}
          //     />
          //   ))}
          // </SidebarMenuItem> */}
        {auth.account.type !== 'influencer' && (
          <SidebarMenuItem
            compact={compact}
            Icon={DescriptionIcon}
            label="Campaign Analytics"
            selected={route.pathname === '/campaign-report'}
            to="/campaign-report"
            lock={!auth.hasPermission('report.create')}
          />
        )}
        <SidebarMenuItem
          compact={compact}
          Icon={UniversityIcon}
          label="Scrunch University"
          selected={route.pathname === '/scrunch-university'}
          to="/scrunch-university"
          lock={!auth.hasPermission('scrunch_university.view')}
        />
        <SidebarMenuItem
          compact={compact}
          Icon={CloudDownloadIcon}
          label="Scrunch Resources"
          selected={route.pathname === '/scrunch-resources'}
          to="/scrunch-resources"
          lock={!auth.hasPermission('scrunch_resources.view')}
        />

        {/* <SidebarMenuItem
          compact={compact}
          Icon={PostAddIcon}
          label="Content Approval"
          selected={route.pathname === '/content-approval'}
          to="/content-approval"
          lock={!auth.hasPermission('content_manager.all')}
        /> */}

        {auth.hasPermission('spark_ffp.view') && (
          <SidebarMenuItem
            compact={compact}
            Icon={SparkIcon}
            label="Spark FFP"
            selected={route.pathname === '/spark-ffp'}
            to="/spark-ffp"
            lock={!auth.hasPermission('scrunch_university.view')}
          />
        )}

        {/* {auth.account.type === 'influencer' && (
          <SidebarMenuItem
            compact={compact}
            Icon={FileCopyIcon}
            label="Media Kit Creator"
            selected={route.pathname === '/media-kit'}
            to="/media-kit"
            lock={!auth.hasPermission('media_kit.create')}
          />
        )} */}
      </div>
      <div className={classes.footer}>
        {auth.hasPermission('profile_import.create') && (
          <ImportProfilesButton compact={compact} />
        )}
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  barHeight: PropTypes.number.isRequired,
  className: PropTypes.string,
  compact: PropTypes.bool.isRequired,
  onSidebarButtonClick: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
};

Sidebar.defaultProps = {
  className: null,
};

export default Sidebar;
