import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  TextField,
  Typography,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import API from '../../utils/api';
import ScrDialog from '../common/ScrDialog';
import { useAuth, useNotification, useContentManager } from '../../hooks';

const useStyles = makeStyles(() => ({
  field: {
    marginBottom: '20px',
    marginTop: '10px',
  },
  btn: {
    height: '50px',
    width: '275px',
  },
}));

const AnalyticsTrackingDialog = ({ onClose }) => {
  const classes = useStyles();
  const auth = useAuth();
  const content = useContentManager();
  const { createNotification } = useNotification();
  const [postUrl, setPostUrl] = useState('');
  const [campUrl, setCampUrl] = useState('');
  const [coupCode, setCoupCode] = useState('');
  const [isFetching, setIsFetching] = useState(false);

  const handleClick = async () => {
    setIsFetching(true);

    try {
      const response = await API({
        method: 'patch',
        url: `/account/${auth.account.id}/content-manager/post/${content.post.id}`,
        data: {
          status: 5,
          publish_post_url: postUrl,
          google_tracking_url: campUrl,
          shopify_coupon_code: coupCode,
          is_published: true,
        },
      });
      if (response.status === 200) {
        createNotification('success', 'Status Updated');
        content.setPost(auth.account.id, content.post.id);
      } else {
        createNotification('error', 'Status Not Saved');
      }
    } catch (e) {
      createNotification('error', `Status not Updated: ${e}`);
      setIsFetching(false);
    }
    setIsFetching(false);
  };

  return (
    <ScrDialog
      title="Analytics &amp; Tracking"
      isOpen
      onClose={onClose}
      titleStyle={{ marginBottom: 0 }}
      // children,
      isFetching={isFetching}
      minWidth={700}
      maxWidth={700}
    >
      <DialogContent style={{ padding: '0 50px' }}>
        <Typography variant="body1" component="span">
          Published Post Url
        </Typography>
        <TextField
          fullWidth
          margin="dense"
          placeholder="Enter published post's URL"
          className={classes.field}
          onChange={(e) => setPostUrl(e.target.value)}
        />
        <Typography variant="body1" component="span">
          Google Analytics custom campaign URL
        </Typography>
        <TextField
          margin="dense"
          fullWidth
          className={classes.field}
          placeholder="Enter Google Analytics custom campaign URL"
          onChange={(e) => setCampUrl(e.target.value)}
        />
        <Typography variant="body1" component="span">
          Shopify Coupon Code
        </Typography>
        <TextField
          margin="dense"
          fullWidth
          placeholder="Influencer's custom Shopify coupon code"
          className={classes.field}
          onChange={(e) => setCoupCode(e.target.value)}
        />
      </DialogContent>
      <DialogActions style={{ marginTop: '10px' }}>
        <Button
          disabled={Boolean(!postUrl) || Boolean(!campUrl) || Boolean(!coupCode)}
          className={classes.btn}
          color="primary"
          variant="contained"
          onClick={handleClick}
        >
          Send Now
        </Button>
      </DialogActions>
    </ScrDialog>
  );
};

AnalyticsTrackingDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AnalyticsTrackingDialog;
