import React from 'react';
import { Link as RouterLink } from '@reach/router';

import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';

import ListGroupImg from '../../assets/images/lists/lists_group.png';
import ImportProfilesButton from './ImportProfilesButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    padding: '50px 70px',
  },
  cont: {
    backgroundColor: theme.colors.system.grey1,
    border: `1px solid ${theme.colors.system.grey3}`,
    borderRadius: 4,
    display: 'grid',
    gridTemplateColumns: '1fr 1.3fr',
    padding: 50,
  },
  col: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  welcomeRoot: {
    width: '100%',
    height: '100%',
    borderRight: `1px solid ${theme.colors.system.grey3}`,
    padding: '0px 60px 0px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  welcomeTitle: {
    textAlign: 'center',
    marginBottom: 25,
    '@media (max-width: 1280px)': {
      '& span': {
        ...theme.textStyles.bodyExtraLarge,
        fontWeight: 600,
      },
    },
  },
  description: {
    color: theme.colors.system.grey7,
    ...theme.textStyles.body,
    textAlign: 'center',
  },
  btnWrapper: {
    margin: '25px auto 11px',
  },
  listGrpImg: {
    marginTop: 20,
    width: '80%',
    objectFit: 'contain',
    objectPosition: 'center',
    '@media (max-width: 1280px)': {
      width: '88%',
    },
  },
}));

const EmptyList = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.cont}>
        <div className={classes.col}>
          <div className={classes.welcomeRoot}>
            <div className={classes.welcomeTitle}>
              <Typography variant="h5" component="span">
                Welcome To&nbsp;
              </Typography>
              <Typography variant="h5" component="span" color="primary">
                Scrunch Lists
              </Typography>
            </div>

            <Typography className={classes.description}>
              A place for you to list out all your awesome influencer campaigns!
            </Typography>

            <div className={classes.btnWrapper}>
              <Button
                variant="contained"
                color="primary"
                style={{ padding: '12px 20px', textAlign: 'center' }}
                component={RouterLink}
                to="/discover"
              >
                Start Discovering Influencers
              </Button>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              <Typography variant="body1" component="span">
                <b>or import profiles&nbsp;</b>
              </Typography>
              <ImportProfilesButton compact={false} textButton label="here" />
            </div>
          </div>
        </div>

        <div className={classes.col} style={{ padding: '0px 10px 0px 60px' }}>
          <img
            src={ListGroupImg}
            alt="Sample Images of Lists"
            className={classes.listGrpImg}
          />
        </div>

      </div>
    </div>
  );
};

export default EmptyList;
