import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.colors.system.grey1,
    border: `1px solid ${theme.colors.system.grey3}`,
    borderRadius: 4,
    padding: '25px 20px',
    marginBottom: 35,
  },
  title: {
    ...theme.textStyles.bodyMedium,
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1.3fr',
    marginTop: 25,
  },
  video: {
    width: '100%',
    height: 350,
    borderRadius: 8,
  },
  desc: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 25,
  },
  aboutTipTitle: {
    borderBottom: `1px solid ${theme.colors.system.grey3}`,
    paddingBottom: 10,
    marginBottom: 20,
  },
  aboutText: {
    color: theme.colors.system.grey7,
    marginTop: 10,
  },
}));

const TipOfTheWeek = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="body1" className={classes.title}>
        Tip of the Week
      </Typography>
      <div className={classes.container}>
        <iframe
          title="Welcome"
          src="https://www.youtube.com/embed/hdrvobzxUGw"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          frameBorder="0"
          className={classes.video}
        />
        <div className={classes.desc}>
          <div className={classes.aboutTipTitle}>
            <Typography variant="body1" component="p" color="primary">About this Tip</Typography>
          </div>
          <div>
            <Typography variant="body1" component="p" style={{ fontWeight: 'bold' }}>
              1. How to grow your followers.
            </Typography>
            <Typography variant="body1" component="p" className={classes.aboutText}>
              A few quick tips to grow your following authentically on Instagram.
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TipOfTheWeek;
