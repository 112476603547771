import axios from 'axios';
import qs from 'qs';
import Semaphore from 'semaphore-async-await';

import Config from '../config';

const refreshTokenLock = new Semaphore(1);
let lastRefreshTokenRequest = null;
const refreshTokenTimeout = 10000; // ms

const API = axios.create({
  baseURL: Config.API_BASE_URL,
  // headers: {
  //   Accept: 'application/json; v=0.1',
  //   'Content-Type': 'application/json',
  // },
  timeout: 60000,
  withCredentials: true,

  // Serialize query params that are arrays without brackets
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),

  // By default, only 2XX responses are considered successful
  validateStatus: (status) => status >= 200 && status < 300,
});

const handleRetryOnAuthFailure = async (error) => {
  if (!error.response || error.response.status !== 401) {
    // If response is not an auth failure, do nothing.
    return Promise.reject(error);
  }

  // Attempt to gracefully handle 401 Unauthorized response by requesting another
  // access token, and then retrying the initial request.
  await refreshTokenLock.acquire();
  try {
    // To prevent unneccessarily requesting refresh tokens, enfore a 10 second wait
    // between requests. If multiple API calls are waiting on the semaphore to get
    // a refresh token, only the first will fetch the token and the remainder will
    // use the new refresh token.
    if (!lastRefreshTokenRequest || (Date.now() - lastRefreshTokenRequest) > refreshTokenTimeout) {
      lastRefreshTokenRequest = Date.now();
      const refreshTokenResponse = await axios({
        method: 'post',
        url: `${Config.API_BASE_URL}/auth/refresh/`,
        validateStatus: () => true,
        withCredentials: true,
      });
      if (refreshTokenResponse.status !== 200) {
        // Could not refresh the session
        return Promise.reject(error);
      }
    }
  } finally {
    refreshTokenLock.release();
  }

  // Try the original request again.
  return API(error.response.config);
};

API.interceptors.response.use(
  (response) => response,
  // Attempt to authorize and retry request on auth failure
  handleRetryOnAuthFailure,
);

export default API;
