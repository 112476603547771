import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  statusBox: {
    borderRadius: '4px',
    padding: '5px 10px',
    height: '25px',
    width: 'fit-content',
  },
  statusText: {
    fontSize: '12px',
    // fontWeight: 300,
    margin: 0,
    padding: 0,
  },
}));

const StatusBox = ({ status, textColor, bgColor }) => {
  const classes = useStyles();
  return (
    <div className={classes.statusBox} style={{ backgroundColor: bgColor }}>
      <p className={classes.statusText} style={{ color: textColor }}>
        {status}
      </p>
    </div>
  );
};

StatusBox.propTypes = {
  status: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
};

export default StatusBox;
