import React from 'react';
import PropTypes from 'prop-types';

import { Avatar, ButtonBase } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import StatusBox from './StatusBox';
import { useContentManager } from '../../hooks';
import { formatDateWithTime } from '../../utils/datetime';

const useStyles = makeStyles((theme) => ({
  postCont: {
    backgroundColor: 'white',
    margin: '1rem 0',
    padding: '0.75rem',
    borderRadius: '4px',
    border: `2px solid ${theme.colors.system.grey2}`,
    height: '125px',
    display: 'flex',
    position: 'relative',
  },
  img: {
    borderRadius: '4px',
    marginRight: '0.75rem',
    height: '100px',
    width: '100px',
    objectFit: 'cover',
  },
  postContent: {
    width: '100%',
  },
  brandNameStatus: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  brandImg: {
    height: '25px',
    width: '25px',
    borderRadius: '50%',
    marginRight: '0.5rem',
    fontSize: '12px',
  },
  text: {
    fontSize: '12px',
    height: '50px',
    overflow: 'hidden',
    margin: '0.25rem 0',
    textAlign: 'left',
  },
  date: {
    fontSize: '12px',
    margin: '0',
    color: 'grey',
    textAlign: 'left',
  },
  contentType: {
    height: '25px',
    width: '25px',
    position: 'absolute',
    bottom: '5px',
    right: '20px',
    borderRadius: '50%',
    color: 'white',
    backgroundColor: theme.colors.primary.main,
    '&>*': {
      height: '15px !important',
    },
  },
  postButton: {
    width: '100%',
    '&>*': {
      width: '100%',
    },
  },
}));

const PostItem = (props) => {
  const {
    img,
    contentType,
    brandImg,
    brandName,
    postStatus,
    text,
    date,
    handleClick,
  } = props;
  const classes = useStyles();
  const content = useContentManager();
  return (
    <ButtonBase className={classes.postButton} onClick={handleClick}>
      <div className={classes.postCont}>
        <div style={{ position: 'relative' }}>
          <img className={classes.img} src={img} alt="Post " />
          <Avatar className={classes.contentType} alt="content type">
            {content.contentTypes[contentType].icon}
          </Avatar>
        </div>
        <div className={classes.postContent}>
          <div className={classes.brandNameStatus}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar className={classes.brandImg} src="#" alt={brandImg} />
              <span style={{ fontSize: 'small' }}>{brandName}</span>
            </div>
            <StatusBox
              status={content.status[postStatus].label}
              bgColor={content.status[postStatus].bgColor}
              textColor={content.status[postStatus].fontColor}
            />
          </div>
          <p className={classes.text}>{text}</p>
          <p className={classes.date}>{formatDateWithTime(date)}</p>
        </div>
      </div>
    </ButtonBase>
  );
};

PostItem.propTypes = {
  img: PropTypes.string,
  contentType: PropTypes.number.isRequired,
  brandImg: PropTypes.string.isRequired,
  brandName: PropTypes.string.isRequired,
  postStatus: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};

PostItem.defaultProps = {
  img: null,
};

export default PostItem;
