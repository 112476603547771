import React, { useState } from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  Avatar,
  Chip,
  Typography,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import AddToListButton from './AddToListButton';
import MailToButton from './MailToButton';
import NetworkIcon from '../common/icons/NetworkIcon';
import NetworkChip from '../common/NetworkChip';
import { useAuth } from '../../hooks';
import { formatNumber, formatPercentage } from '../../utils/number';
import { getAvatarAlt, getGenderLabel } from '../../utils/profile';
import { EditNoteContent } from './EditNoteButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
  },
  top: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 40,
    // borderBottom: `1px solid ${theme.colors.system.grey4}`,
  },
  bottom: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  info: {
    width: '100%',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '&>:not(last-child)': {
      marginBottom: theme.spacing(1),
    },
    paddingLeft: 30,
  },
  actions: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  profilePicture: {
    width: 120,
    height: 120,
    marginRight: 20,
  },
  attributes: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    flexWrap: 'wrap',
    '&>*': {
      marginRight: 5,
      marginBottom: 2,
    },
    fontSize: 12,
  },
  infoLabel: {
    height: 24,
  },
  statName: {
    textAlign: 'right',
  },
  topics: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    flexWrap: 'wrap',
    '&>:not(last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  handle: {
    color: '#2B2E34',
  },
  socialSummary: {
    padding: '20px 0',
    display: 'flex',
    flexWrap: 'wrap',
    borderBottom: `1px solid ${theme.colors.system.grey4}`,
  },
  socialSummaryCont: {
    width: '50%',
    padding: '20px 0',
    '& h5': {
      paddingBottom: 20,
    },
  },
  socialSummarySidebar: {
    width: '100%',
    padding: '40px 0',
    '& h5': {
      paddingBottom: 20,
    },
  },
  socialRow: {
    display: 'flex',
  },
  socialIcon: {
    paddingTop: 5,
  },
  socialReachDetails: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 15,
  },
  socialHandle: {
    color: theme.colors.system.grey7,
  },
  addNoteBtn: {
    borderRadius: 5,
    marginLeft: 20,
  },
  videoChips: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '&>*': {
      marginRight: '0.5rem',
    },
  },
}));

const Stat = (props) => {
  const { name, content } = props;
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h3">{content}</Typography>
      <Typography variant="caption" className={classes.statName}>{name}</Typography>
    </div>
  );
};

Stat.propTypes = {
  content: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const SocialReach = (props) => {
  const {
    content,
    profile,
    sidebar,
  } = props;
  const classes = useStyles();

  return (
    <div
      className={sidebar ? classes.socialSummarySidebar : classes.socialSummaryCont}
      style={sidebar ? { borderBottom: '1px solid #E2E2E2' } : null}
    >
      <Typography variant="h5">
        {content}
        &nbsp;social reach
      </Typography>
      {profile.data.network_profiles.map((it) => (
        <div key={it.network} style={{ marginBottom: 15 }}>
          <div className={classes.socialRow}>
            <div className={classes.socialIcon}>
              <NetworkIcon network={it.network} />
            </div>
            <div className={classes.socialReachDetails}>
              <Typography variant="body1">
                {formatNumber(it.stats.social_reach)}
                &nbsp;
                {it.network}
                &nbsp;followers
              </Typography>
              <Link
                href={it.url}
                target="_blank"
              >
                <Typography className={classes.socialHandle} variant="body1">
                  @
                  {it.handle || it.full_name}
                </Typography>
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

SocialReach.propTypes = {
  content: PropTypes.string.isRequired,
  profile: PropTypes.shape().isRequired,
  sidebar: PropTypes.bool,
};

SocialReach.defaultProps = {
  sidebar: false,
};

const Engagement = (props) => {
  const {
    engagement,
    engagementRate,
    sidebar,
  } = props;
  const classes = useStyles();

  return (
    <div
      className={sidebar ? classes.socialSummarySidebar : classes.socialSummaryCont}
      style={!sidebar ? { borderLeft: '1px solid #E2E2E2', paddingLeft: 20 } : null}
    >
      <Typography variant="h5">
        {engagement}
        &nbsp;average engagement
      </Typography>

      <Chip
        label={`${engagementRate} Avg. Engagement Rate`}
        size="small"
        color="secondary"
        style={{ padding: 15, fontSize: 12 }}
      />
    </div>
  );
};

Engagement.propTypes = {
  engagement: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  engagementRate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  sidebar: PropTypes.bool,
};

Engagement.defaultProps = {
  sidebar: false,
};

const ProfileHeader = (props) => {
  const { profile, sidebar } = props;
  const [noteText, setNoteText] = useState(profile.data.note && profile.data.note.text ? profile.data.note.text : '');
  const auth = useAuth();
  const classes = useStyles();

  const fullName = profile.fullName();
  const profilePicture = profile.profilePicture();
  const gender = profile.gender();
  const age = profile.age();
  const locationCountry = profile.locationCountry();
  const engagement = profile.engagement();
  const engagementRate = profile.engagementRate();
  const topics = profile.topics();
  const handle = profile.handle();

  const handleEditNoteSuccess = (data) => {
    if (data && _.isString(data.text)) {
      setNoteText(data.text);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <Avatar
          className={classes.profilePicture}
          alt={fullName}
          src={profilePicture}
        >
          {getAvatarAlt(fullName)}
        </Avatar>
        <div className={classes.info}>
          <Typography variant="h5">{fullName}</Typography>
          <div className={classes.topics}>
            {gender && (
              <Chip size="small" label={getGenderLabel(gender)} />
            )}
            {locationCountry && (
              <Chip size="small" label={locationCountry} />
            )}
            {age && (
              <Chip size="small" label={age} />
            )}
          </div>
          <Typography className={classes.handle} variant="body1" component="p">
            {`@${handle}`}
          </Typography>

          <div className={classes.topics}>
            {topics.map((it) => (
              <Chip key={it} size="small" label={it} />
            ))}
          </div>
        </div>
        <div className={classes.actions}>
          <div className={classes.actionButtons}>
            <MailToButton profile={profile} />

            <div style={{ width: 7 }} />
            <AddToListButton profile={profile} />
            {auth.hasPermission('profile.edit_note') && (
            <>
              <div style={{ width: 5 }} />

            </>
            )}
          </div>
        </div>
      </div>
      <EditNoteContent
        networkId={profile.networkId()}
        profile={profile}
        onProfilePage
        onSuccess={handleEditNoteSuccess}
        noteText={noteText}
      />
      <div className={classes.socialSummary}>
        <SocialReach content={formatNumber(profile.data.stats.social_reach)} profile={profile} sidebar={sidebar} />
        <Engagement
          engagement={formatNumber(engagement)}
          engagementRate={formatPercentage(engagementRate)}
          sidebar={sidebar}
        />
      </div>
      <div className={classes.socialSummary} style={{ padding: '40px 0' }}>
        <Typography variant="h5" component="h5" style={{ width: '100%', marginBottom: 20 }}>
          Video Views
        </Typography>
        <div className={classes.videoChips}>
          {profile.data.network_profiles.filter((it) => it.stats.video_views).map((it) => (
            <NetworkChip
              key={it.network}
              network={it.network}
              label={it.stats.video_views ? formatNumber(it.stats.video_views) : '0'}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

ProfileHeader.propTypes = {
  profile: PropTypes.shape().isRequired,
  sidebar: PropTypes.bool,
};

ProfileHeader.defaultProps = {
  sidebar: false,
};

export default ProfileHeader;
