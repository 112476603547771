import React, { useMemo } from 'react';

import {
  Button,
  Link,
  Typography,
} from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { makeStyles } from '@material-ui/core/styles';

import AuthPageBase from '../AuthPageBase';
import { useApi, useAuth } from '../../../../hooks';

const useStyles = makeStyles(() => ({
  body: {
    textAlign: 'center',
  },
}));

const EmailVerificationRequestPage = () => {
  const classes = useStyles();
  const auth = useAuth();
  const [{ response, isFetching }, setRequest] = useApi();

  const resubmitted = useMemo(() => response && response.status === 200, [response]);

  const handleButtonClick = () => {
    setRequest({
      method: 'post',
      url: '/auth/email-verification/request/',
    });
  };

  return (
    <AuthPageBase
      headerTitle="Verify your email"
      title="Verify email"
    >
      <div className={classes.body}>
        <MailOutlineIcon style={{ fontSize: 80 }} />
        <Typography variant="body2">
          {`Please check your email (${auth.user.email}) for your verification link.`}
        </Typography>
        {!resubmitted && (
          <Button
            disabled={isFetching}
            variant="contained"
            color="primary"
            style={{ marginTop: '5px' }}
            onClick={handleButtonClick}
            fullWidth
          >
            Resend Verification Email
          </Button>
        )}
        {resubmitted && (
          <Typography
            variant="body2"
            color="secondary"
            style={{ marginTop: '5px' }}
          >
            The verification email has been resent.
          </Typography>
        )}
        <Typography variant="body2">
          Not you? Login as another user
          {' '}
          <Link href="/auth/login">here</Link>
          {' '}
          .
        </Typography>
      </div>
    </AuthPageBase>
  );
};

export default EmailVerificationRequestPage;
