import React, { /* useCallback, */ useEffect } from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';

import RouteContainer from './RouteContainer';
import { useApi, useAuth } from '../../hooks';

/**
 * A verified route requires that a user be both authenticated AND verified
 * (i.e. email is verified) before it will render it's children.
 *
 * If the user is not authenticated, they are automatically redirected to the
 * login page.
 * If the user is authenticated but not verified, they are redirected to the
 * email confirmation page.
 * @param {*} props
 */
const VerifiedRouteContainer = ({ children }) => {
  const [{ response }] = useApi({ method: 'get', url: '/auth/me/' });
  const auth = useAuth();

  useEffect(() => {
    if (response && response.status === 200) {
      if (!_.isEqual(auth.user, response.data.data)) {
        auth.setUser(response.data.data);
      }
    }
  }, [auth, response]);

  // Commented out to fix login redirect,
  //  not sure if this is needed

  // const redirect = useCallback(
  //   (pathname) => {
  //     setRoute({
  //       pathname,
  //       params: {
  //         next: route.pathname,
  //       },
  //     });
  //   },
  //   [route, setRoute],
  // );

  // if not verified redirect back to /auth/email-verification

  // useEffect(() => {
  //   if (auth.isAuthenticated) {
  //     if (!auth.isVerified) {
  //       redirect('/auth/email-verification');
  //     }
  //   }
  // }, [auth, redirect]);

  if (!auth.isAuthenticated && !auth.isVerified) {
    // Render a blank page when not authenticated, the authentication check
    // will handle redirects
    return <div />;
  }

  return (
    <RouteContainer>
      {children}
    </RouteContainer>
  );
};

VerifiedRouteContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default VerifiedRouteContainer;
