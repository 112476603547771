import React from 'react';
import PropTypes from 'prop-types';
import SlidingPane from 'react-sliding-pane';

import { IconButton, Divider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    margin: '20px',
    height: 'calc(100% - 40px)',
    width: 'calc(100% - 40px)',
  },
  closeButton: {
    padding: 0,
    margin: '5px 0 10px',
  },
  divider: {
    margin: '15px 0 30px',
  },
}));

const SlidingPaneBase = ({ paneIsOpen, onRequestClose, children }) => {
  const classes = useStyles();

  return (
    <SlidingPane
      isOpen={paneIsOpen}
      onRequestClose={() => onRequestClose(false)}
      width="600px"
    >
      <div className={classes.container}>
        <IconButton onClick={() => onRequestClose(false)} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
        <Divider className={classes.divider} />
        {children}
      </div>
    </SlidingPane>
  );
};

SlidingPaneBase.propTypes = {
  paneIsOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  children: PropTypes.node,
};

SlidingPaneBase.defaultProps = {
  children: null,
};

export default SlidingPaneBase;
