import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import SettingsPageBase from './SettingsPageBase';
import NoMatch from '../../router/NoMatch';
import EditEmailButton from '../../account/EditEmailButton';
import { useApi, useAuth } from '../../../hooks';
import DeleteAccountButton from '../../account/DeleteAccountButton';

const useStyles = makeStyles((theme) => ({
  heading: {
    ...theme.textStyles.bodyLarge,
  },
  searchInput: {
    margin: '25px 0',
    '@media (min-width: 1600px)': {
      width: 'calc(60% + 40px)',
    },
  },
  table: {
    border: `1px solid ${theme.colors.system.grey3}`,
    borderRadius: '2px',
    marginTop: theme.spacing(1),
    '& tr': {
      border: `1px solid ${theme.colors.system.grey3}`,
    },
    '& th,td': {
      padding: '17px 20px !important',
      border: 'none',
      ...theme.textStyles.bodySmall,
    },
    '& td': {
      backgroundColor: theme.colors.system.light,
    },
    '& th': {
      color: '#2b2e34 !important',
      fontWeight: 'bold',
    },
    '@media (min-width: 1600px)': {
      width: 'calc(60% + 40px)',
    },
  },
  tableHeader: {
    backgroundColor: theme.colors.system.grey1,
  },
}));

const SearchResults = ({ searchResults, setIsFetching, refreshResults }) => {
  const classes = useStyles();

  return (
    <Table className={classes.table}>
      <TableHead className={classes.tableHeader}>
        <TableRow>
          <TableCell>Email Address</TableCell>
          <TableCell />
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {searchResults && searchResults.map((email) => (
          <TableRow key={email}>
            <TableCell style={{ width: '60%' }}>{email}</TableCell>
            <TableCell>
              <EditEmailButton
                email={email}
                setIsFetching={setIsFetching}
                refreshResults={refreshResults}
              />
            </TableCell>
            <TableCell>
              <DeleteAccountButton
                email={email}
                setIsFetching={setIsFetching}
                refreshResults={refreshResults}
                buttonName="Delete User"
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

SearchResults.propTypes = {
  searchResults: PropTypes.arrayOf(PropTypes.string).isRequired,
  setIsFetching: PropTypes.func,
  refreshResults: PropTypes.func,
};

SearchResults.defaultProps = {
  setIsFetching: null,
  refreshResults: null,
};

const EditDeleteUsersPage = () => {
  const classes = useStyles();
  const auth = useAuth();

  // state variables
  const [emailValue, setEmailValue] = useState('');
  const [isFetching, setIsFetching] = useState(false);

  // APIs
  const [{ response: searchData }, postSearchEmailRequest] = useApi();

  // functions
  const refreshResults = () => {
    postSearchEmailRequest({
      method: 'post',
      url: `/account/${auth.account.id}/admin/search-email/`,
      data: {
        email: emailValue,
      },
    });
  };

  useEffect(() => {
    if (emailValue && emailValue.length >= 7) {
      refreshResults();
    } else {
      postSearchEmailRequest(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailValue]);

  if (!auth.hasPermission('admin.change_email')) {
    return (
      <NoMatch default />
    );
  }

  return (
    <SettingsPageBase
      showLoadingBar={isFetching}
      title="Edit/Delete Users"
    >
      <div>
        <Typography variant="h5" component="p" className={classes.heading}>Edit/Delete Users</Typography>
        <Typography variant="body2" componet="span">Start searching users from the Scrunch database</Typography>

        <TextField
          variant="outlined"
          value={emailValue}
          placeholder="Search current audience by email address"
          InputProps={{
            startAdornment: <SearchIcon style={{ fontSize: 18, marginRight: 10 }} />,
            endAdornment: emailValue ? (
              <InputAdornment position="end">
                <IconButton onClick={() => setEmailValue('')}>
                  <CloseIcon style={{ fontSize: 18 }} />
                </IconButton>
              </InputAdornment>
            ) : null,
            style: {
              fontSize: 12,
            },
          }}
          fullWidth
          size="medium"
          onChange={(e) => setEmailValue(e.target.value)}
          className={classes.searchInput}
        />

        {searchData && searchData.data && searchData.data.data && emailValue && emailValue.length >= 7 && (
          <SearchResults
            searchResults={searchData.data.data}
            setIsFetching={setIsFetching}
            refreshResults={refreshResults}
          />
        )}
      </div>
    </SettingsPageBase>
  );
};

export default EditDeleteUsersPage;
