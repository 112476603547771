import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { colors } from '../definitions';
import { previewStyles } from '../form/styles';

const useStyles = makeStyles((theme) => ({
  highlighted: {
    border: `2px solid ${theme.colors.primary.main}`,
  },
  root: {
    backgroundColor: colors.BG_LIGHT,
    display: 'flex',
    height: 260,
    marginTop: 20,
    '@media (max-width: 1280px)': {
      height: 220,
    },
  },
  col: {
    flex: 1,
    maxWidth: '50%',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  col2: {
    display: 'flex',
    flexDirection: 'column',
    padding: 15,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  padding: {
    padding: 5,
    '@media (max-width: 1280px)': {
      padding: 2,
    },
  },
}));

const Page1Preview = ({
  highlighted,
  finalData,
}) => {
  const classes = useStyles();
  const textStyles = previewStyles();

  const data = finalData.personalDetails;

  let nameStyle = textStyles.h1;
  if (data.firstName.length > 8 || data.lastName.length > 8) {
    nameStyle = textStyles.h2;
  }

  return (
    <div className={classes.root} id="page-1-preview">
      <div className={`${classes.col} ${highlighted === 'personalDetails.mainImg' ? classes.highlighted : ''} ${classes.padding}`}>
        <img
          src={data.mainImg}
          className={classes.image}
          alt="Background for Page 1"
        />
      </div>
      <div className={`${classes.col} ${classes.col2}`}>
        <div className={classes.header}>
          <div className={`${textStyles.bodyLarge} ${classes.padding}`}>MEDIA KIT 2021</div>
          <div
            className={`${textStyles.body} ${highlighted === 'personalDetails.instagramHandle' ? classes.highlighted : ''} ${classes.padding}`}
          >
            {data.instagramHandle ? `@${data.instagramHandle}` : ''}
          </div>
        </div>
        <div className={classes.main}>
          <div
            className={`${nameStyle} ${highlighted === 'personalDetails.firstName' ? classes.highlighted : ''} ${classes.padding}`}
          >
            {data.firstName}
          </div>
          <div
            className={`${nameStyle} ${highlighted === 'personalDetails.lastName' ? classes.highlighted : ''} ${classes.padding}`}
          >
            {data.lastName}
          </div>
          <div
            className={`${textStyles.h5} ${highlighted === 'personalDetails.professionalTitle' ? classes.highlighted : ''} ${classes.padding}`}
          >
            {data.professionalTitle}
          </div>
        </div>
        <div className={classes.header}>
          <div
            className={`${textStyles.body} ${highlighted === 'personalDetails.websiteUrl' ? classes.highlighted : ''}`}
          >
            {data.websiteUrl}
          </div>
        </div>
      </div>
    </div>
  );
};

Page1Preview.propTypes = {
  highlighted: PropTypes.string,
  finalData: PropTypes.shape().isRequired,
};

Page1Preview.defaultProps = {
  highlighted: '',
};

export default Page1Preview;
