import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  Avatar,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import DeleteIcon from '@material-ui/icons/Delete';
import Placeholder from '../../../assets/images/media-kit/placeholder.png';
import API from '../../../utils/api';
import { useAuth } from '../../../hooks';
import { useStyles } from './styles';

import { defaultFormData } from '../data';

/* ----------------------------------- INSTAGRAM ANALYTICS --------------------------------*/
const InstagramAnalyticsForm = ({
  formData,
  setFormData,
  setHighlighted,
  finalData,
  setFinalData,
}) => {
  const classes = useStyles({ bb: true });
  const imgRef = useRef(null);

  useEffect(() => {
    document.getElementById('formWrapper').scrollTo(0, 0);
  }, []);

  const onImageUpload = (e) => {
    try {
      const imgUrl = URL.createObjectURL(e.target.files[0]);
      const formDataTemp = { ...formData };
      formDataTemp.instagramAnalytics.analyticsImg = imgUrl;

      const finalDataTemp = { ...finalData };
      finalDataTemp.instagramAnalytics.analyticsImg = imgUrl;

      setFormData(formDataTemp);
      setFinalData(finalDataTemp);
    } catch (err) {
      // Do Nothing
    }
  };

  const onImageDelete = () => {
    document.getElementById('page-5-preview').scrollIntoView({
      behavior: 'smooth',
    });
    const formDataTemp = { ...formData };
    formDataTemp.instagramAnalytics.analyticsImg = Placeholder;

    const finalDataTemp = { ...finalData };
    finalDataTemp.instagramAnalytics.analyticsImg = Placeholder;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const instagramHandles = formData.socialStatistics.socialData
    .filter((data) => data.socialWebsite === 'Instagram')
    .map((data) => data.socialHandle);

  const onHandleChange = (e) => {
    const { value } = e.target;

    const filterData = formData.socialStatistics.socialData
      .filter((data) => data.socialWebsite === 'Instagram' && data.socialHandle === value);
    const handleData = filterData[0];

    const formDataTemp = { ...formData };
    formDataTemp.instagramAnalytics.handle = value;
    formDataTemp.instagramAnalytics.followerCount = handleData.followerCount;

    const finalDataTemp = { ...finalData };
    finalDataTemp.instagramAnalytics.handle = value;
    finalDataTemp.instagramAnalytics.followerCount = handleData.followerCount;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const onFollowerCountChange = (e) => {
    const formDataTemp = { ...formData };
    formDataTemp.instagramAnalytics.followerCount = e.target.value;

    const finalDataTemp = { ...finalData };
    finalDataTemp.instagramAnalytics.followerCount = e.target.value;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const onPostEngagementChange = (e) => {
    const formDataTemp = { ...formData };
    formDataTemp.instagramAnalytics.avgPostEngagement = e.target.value;

    const finalDataTemp = { ...finalData };
    finalDataTemp.instagramAnalytics.avgPostEngagement = e.target.value;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  return (
    <div className={classes.sectionRoot}>
      <Typography
        variant="body1"
        component="div"
        color="primary"
        className={classes.sectionTitle}
      >
        Instagram Analytics
      </Typography>

      <Typography variant="body2" component="p" className={classes.formLabel}>Upload Instagram Analytics Image</Typography>
      <div className={classes.uploadWrapper}>
        <Avatar src={formData.instagramAnalytics.analyticsImg} className={classes.imgAvatar}><ImageIcon /></Avatar>
        <input type="file" ref={imgRef} onChange={onImageUpload} style={{ display: 'none' }} />
        {formData.instagramAnalytics.analyticsImg === Placeholder ? (
          <Button
            className={classes.uploadBtn}
            size="small"
            onClick={() => {
              document.getElementById('page-5-preview').scrollIntoView({
                behavior: 'smooth',
              });
              setHighlighted('instagramAnalytics.analyticsImg');
              imgRef.current.click();
            }}
          >
            Upload Image
          </Button>
        ) : (
          <Button
            className={classes.delImgBtn}
            size="small"
            startIcon={<DeleteIcon />}
            variant="outlined"
            onClick={onImageDelete}
          >
            Delete Image
          </Button>
        )}
      </div>

      <div className={classes.flexRow}>
        <TextField
          label="Handle"
          value={formData.instagramAnalytics.handle}
          placeholder={defaultFormData.instagramAnalytics.handle}
          style={{ width: '31%', marginRight: '2%' }}
          InputProps={{
            className: classes.input,
            startAdornment: <InputAdornment position="start" classes={{ root: classes.adornment }}>@</InputAdornment>,
          }}
          select
          onChange={onHandleChange}
          onFocus={() => {
            document.getElementById('page-5-preview').scrollIntoView({
              behavior: 'smooth',
            });
            setHighlighted('instagramAnalytics.handle');
          }}
        >
          {instagramHandles.map((handle) => (
            <MenuItem key={handle} value={handle}>{handle}</MenuItem>
          ))}
        </TextField>
        <TextField
          label="Number Of Followers"
          value={formData.instagramAnalytics.followerCount}
          placeholder={defaultFormData.instagramAnalytics.followerCount}
          style={{ width: '31%', marginRight: '2%' }}
          InputProps={{ className: classes.input }}
          onChange={onFollowerCountChange}
          onFocus={() => {
            document.getElementById('page-5-preview').scrollIntoView({
              behavior: 'smooth',
            });
            setHighlighted('instagramAnalytics.followerCount');
          }}
        />
        <TextField
          label="Average Post Engagement"
          value={formData.instagramAnalytics.avgPostEngagement}
          placeholder={defaultFormData.instagramAnalytics.avgPostEngagement}
          style={{ width: '34%' }}
          InputProps={{ className: classes.input }}
          onChange={onPostEngagementChange}
          onFocus={() => {
            document.getElementById('page-5-preview').scrollIntoView({
              behavior: 'smooth',
            });
            setHighlighted('instagramAnalytics.avgPostEngagement');
          }}
        />
      </div>
    </div>
  );
};

InstagramAnalyticsForm.propTypes = {
  formData: PropTypes.shape().isRequired,
  setFormData: PropTypes.func.isRequired,
  setHighlighted: PropTypes.func.isRequired,
  finalData: PropTypes.shape().isRequired,
  setFinalData: PropTypes.func.isRequired,
};

/* ----------------------------------- INSTAGRAM AUDIENCE --------------------------------*/
const InsatgramAudienceForm = ({
  formData,
  setFormData,
  setHighlighted,
  finalData,
  setFinalData,
}) => {
  const classes = useStyles({ bb: true });

  useEffect(() => {
    document.getElementById('formWrapper').scrollTo(0, 0);
  }, []);

  const onAudienceGenderChange = (e) => {
    const formDataTemp = { ...formData };
    formDataTemp.instagramAnalytics.audienceGender.gender = e.target.value;

    const finalDataTemp = { ...finalData };
    finalDataTemp.instagramAnalytics.audienceGender.gender = e.target.value;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const onAudienceGenderPercentageChange = (e) => {
    const formDataTemp = { ...formData };
    formDataTemp.instagramAnalytics.audienceGender.audienceGenderPercentage = e.target.value;

    const finalDataTemp = { ...finalData };
    finalDataTemp.instagramAnalytics.audienceGender.audienceGenderPercentage = e.target.value;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const onAgeGroupChange = (e) => {
    const formDataTemp = { ...formData };
    formDataTemp.instagramAnalytics.audienceAge = e.target.value;

    const finalDataTemp = { ...finalData };
    finalDataTemp.instagramAnalytics.audienceAge = e.target.value;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  return (
    <div className={classes.sectionRoot}>
      <Typography
        variant="body1"
        component="div"
        color="primary"
        className={classes.sectionTitle}
      >
        Instagram Audience
      </Typography>

      <div className={classes.flexRow}>
        <TextField
          label="Most Prominent Audience Gender Percentage"
          value={formData.instagramAnalytics.audienceGender.audienceGenderPercentage}
          placeholder={defaultFormData.instagramAnalytics.audienceGender.audienceGenderPercentage}
          style={{ width: '48%', marginRight: '4%' }}
          onChange={onAudienceGenderPercentageChange}
          onFocus={() => {
            document.getElementById('page-5-preview').scrollIntoView({
              behavior: 'smooth',
            });
            setHighlighted('instagramAnalytics.audienceGender');
          }}
          InputProps={{
            className: classes.input,
            startAdornment: (
              <InputAdornment position="start" classes={{ root: classes.adornment }}>
                <TextField
                  value={formData.instagramAnalytics.audienceGender.gender}
                  InputProps={{ style: { border: 'none' } }}
                  select
                  onChange={onAudienceGenderChange}
                  onFocus={() => {
                    document.getElementById('page-5-preview').scrollIntoView({
                      behavior: 'smooth',
                    });
                    setHighlighted('instagramAnalytics.audienceGender');
                  }}
                >
                  <MenuItem key="female" value="Female">Female</MenuItem>
                  <MenuItem key="male" value="Male">Male</MenuItem>
                </TextField>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end" classes={{ root: classes.adornment }}>%</InputAdornment>
            ),
          }}
        />
        <TextField
          label="Most Prominent Age Group"
          value={formData.instagramAnalytics.audienceAge}
          placeholder={defaultFormData.instagramAnalytics.audienceAge}
          style={{ width: '48%' }}
          InputProps={{
            className: classes.input,
            endAdornment: <InputAdornment position="end" classes={{ root: classes.adornment }}>years old</InputAdornment>,
          }}
          onChange={onAgeGroupChange}
          onFocus={() => {
            document.getElementById('page-5-preview').scrollIntoView({
              behavior: 'smooth',
            });
            setHighlighted('instagramAnalytics.audienceAge');
          }}
        />
      </div>
    </div>
  );
};

InsatgramAudienceForm.propTypes = {
  formData: PropTypes.shape().isRequired,
  setFormData: PropTypes.func.isRequired,
  setHighlighted: PropTypes.func.isRequired,
  finalData: PropTypes.shape().isRequired,
  setFinalData: PropTypes.func.isRequired,
};

/* ----------------------------------- INSTAGRAM LOCATION --------------------------------*/
const InstagramAudienceLocationForm = ({
  formData,
  setFormData,
  setHighlighted,
  finalData,
  setFinalData,
}) => {
  const classes = useStyles({ bb: true });

  useEffect(() => {
    document.getElementById('formWrapper').scrollTo(0, 0);
  }, []);

  const onLocationChange = (e, index) => {
    const locationDataTemp = [...formData.instagramAnalytics.audienceLocations];
    locationDataTemp[index].location = e.target.value;
    const formDataTemp = { ...formData };
    formDataTemp.instagramAnalytics.audienceLocations = locationDataTemp;

    const finalLocationDataTemp = [...finalData.instagramAnalytics.audienceLocations];
    finalLocationDataTemp[index].location = e.target.value;
    const finalDataTemp = { ...finalData };
    finalDataTemp.instagramAnalytics.audienceLocations = finalLocationDataTemp;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const onLocationPercentageChange = (e, index) => {
    const locationDataTemp = [...formData.instagramAnalytics.audienceLocations];
    locationDataTemp[index].locationPercentage = e.target.value;
    const formDataTemp = { ...formData };
    formDataTemp.instagramAnalytics.audienceLocations = locationDataTemp;

    const finalLocationDataTemp = [...finalData.instagramAnalytics.audienceLocations];
    finalLocationDataTemp[index].locationPercentage = e.target.value;
    const finalDataTemp = { ...finalData };
    finalDataTemp.instagramAnalytics.audienceLocations = finalLocationDataTemp;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const addAudienceLocation = () => {
    const locationDataTemp = [
      ...formData.instagramAnalytics.audienceLocations,
      {
        id: formData.instagramAnalytics.audienceLocations.length, socialWebsite: '', followerCount: '', socialHandle: '',
      },
    ];
    const formDataTemp = { ...formData };
    formDataTemp.instagramAnalytics.audienceLocations = locationDataTemp;

    const finalLocationDataTemp = [
      ...finalData.instagramAnalytics.audienceLocations,
      {
        id: finalData.instagramAnalytics.audienceLocations.length, socialWebsite: '', followerCount: '', socialHandle: '',
      },
    ];
    const finalDataTemp = { ...finalData };
    finalDataTemp.instagramAnalytics.audienceLocations = finalLocationDataTemp;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const deleteAudienceLocation = (index) => {
    const locationDataTemp = formData.instagramAnalytics.audienceLocations.filter((_, i) => i !== index);
    Object.keys(locationDataTemp).forEach((key) => {
      locationDataTemp[key].id = key;
    });
    const finaLocationDataTemp = finalData.instagramAnalytics.audienceLocations.filter((_, i) => i !== index);
    Object.keys(finaLocationDataTemp).forEach((key) => {
      finaLocationDataTemp[key].id = key;
    });

    const formDataTemp = { ...formData };
    formDataTemp.instagramAnalytics.audienceLocations = locationDataTemp;

    const finalDataTemp = { ...finalData };
    finalDataTemp.instagramAnalytics.audienceLocations = finaLocationDataTemp;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  return (
    <div className={classes.sectionRoot}>
      <Typography
        variant="body1"
        component="div"
        color="primary"
        className={classes.sectionTitle}
      >
        Instagram Location
      </Typography>

      {formData.instagramAnalytics.audienceLocations.map((location, index) => (
        <div className={classes.flexRow} style={{ marginBottom: index !== 2 ? 25 : 0 }} key={location.id}>
          <TextField
            label={`Localtion ${index + 1}`}
            value={location.location}
            placeholder={defaultFormData.instagramAnalytics.audienceLocations[index].location}
            style={{ width: '48%', marginRight: '4%' }}
            InputProps={{ className: classes.input }}
            onChange={(e) => onLocationChange(e, index)}
            onFocus={() => {
              document.getElementById('page-5-preview').scrollIntoView({
                behavior: 'smooth',
              });
              setHighlighted(`instagramAnalytics.audienceLocation${index}`);
            }}
          />
          <TextField
            label={`Location ${index + 1} Percentage`}
            value={location.locationPercentage}
            placeholder={defaultFormData.instagramAnalytics.audienceLocations[index].locationPercentage}
            style={{ width: '48%' }}
            InputProps={{
              className: classes.input,
              endAdornment: <InputAdornment position="end" classes={{ root: classes.adornment }}>%</InputAdornment>,
            }}
            onChange={(e) => onLocationPercentageChange(e, index)}
            onFocus={() => {
              document.getElementById('page-5-preview').scrollIntoView({
                behavior: 'smooth',
              });
              setHighlighted(`instagramAnalytics.audienceLocation${index}`);
            }}
          />
          {formData.instagramAnalytics.audienceLocations.length !== 1 && (
          <IconButton aria-label="delete" className={classes.delIcon} onClick={() => deleteAudienceLocation(index)}>
            <DeleteIcon />
          </IconButton>
          )}
        </div>
      ))}

      {formData.instagramAnalytics.audienceLocations.length < 3 && (
        <Button
          color="primary"
          size="small"
          onClick={addAudienceLocation}
        >
          + Add Another Social Website
        </Button>
      )}
    </div>
  );
};

InstagramAudienceLocationForm.propTypes = {
  formData: PropTypes.shape().isRequired,
  setFormData: PropTypes.func.isRequired,
  setHighlighted: PropTypes.func.isRequired,
  finalData: PropTypes.shape().isRequired,
  setFinalData: PropTypes.func.isRequired,
};

/* ----------------------------------- INSTAGRAM COLLABORATIONS --------------------------------*/
const InstagramCollaborationsForm = ({
  formData,
  setFormData,
  setHighlighted,
  finalData,
  setFinalData,
  mkId,
}) => {
  const classes = useStyles({ bb: true });
  const imgRef = useRef(null);
  const auth = useAuth();

  useEffect(() => {
    document.getElementById('formWrapper').scrollTo(0, 0);
  }, []);

  const onImageUpload = (e, index) => {
    try {
      const imgUrl = URL.createObjectURL(e.target.files[0]);

      const collabDataTemp = [...formData.instagramAnalytics.collaborations];
      collabDataTemp[index].collabImg = imgUrl;
      const formDataTemp = { ...formData };
      formDataTemp.instagramAnalytics.collaborations = collabDataTemp;

      const finalCollabDataTemp = [...finalData.instagramAnalytics.collaborations];
      finalCollabDataTemp[index].collabImg = imgUrl;
      const finalDataTemp = { ...finalData };
      finalDataTemp.instagramAnalytics.collaborations = finalCollabDataTemp;

      setFormData(formDataTemp);
      setFinalData(formDataTemp);
    } catch (err) {
      // Do Nothing
    }
  };

  const onImageDelete = (index) => {
    document.getElementById('page-6-preview').scrollIntoView({
      behavior: 'smooth',
    });

    const collabDataTemp = [...formData.instagramAnalytics.collaborations];
    collabDataTemp[index].collabImg = Placeholder;
    const formDataTemp = { ...formData };
    formDataTemp.instagramAnalytics.collaborations = collabDataTemp;

    const finalCollabDataTemp = [...finalData.instagramAnalytics.collaborations];
    finalCollabDataTemp[index].collabImg = Placeholder;
    const finalDataTemp = { ...finalData };
    finalDataTemp.instagramAnalytics.collaborations = finalCollabDataTemp;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const onBrandNameChange = (e, index) => {
    const collabDataTemp = [...formData.instagramAnalytics.collaborations];
    collabDataTemp[index].brandName = e.target.value;
    const formDataTemp = { ...formData };
    formDataTemp.instagramAnalytics.collaborations = collabDataTemp;

    const finalCollabDataTemp = [...finalData.instagramAnalytics.collaborations];
    finalCollabDataTemp[index].brandName = e.target.value;
    const finalDataTemp = { ...finalData };
    finalDataTemp.instagramAnalytics.collaborations = finalCollabDataTemp;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const onCollabDescChange = (e, index) => {
    const collabDataTemp = [...formData.instagramAnalytics.collaborations];
    collabDataTemp[index].collabDesc = e.target.value;
    const formDataTemp = { ...formData };
    formDataTemp.instagramAnalytics.collaborations = collabDataTemp;

    const finalCollabDataTemp = [...finalData.instagramAnalytics.collaborations];
    finalCollabDataTemp[index].collabDesc = e.target.value;
    const finalDataTemp = { ...finalData };
    finalDataTemp.instagramAnalytics.collaborations = finalCollabDataTemp;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const addCollaboration = () => {
    const usedIds = formData.instagramAnalytics.collaborations.map((item) => item.id);
    const availableIds = formData.collaborationIds
      .filter((x) => !usedIds.includes(x))
      .concat(usedIds.filter((x) => !formData.collaborationIds.includes(x)));

    const collabDataTemp = [
      ...formData.instagramAnalytics.collaborations,
      {
        id: availableIds[0], collabImg: Placeholder, brandName: '', collabDesc: '',
      },
    ];
    const formDataTemp = { ...formData };
    formDataTemp.instagramAnalytics.collaborations = collabDataTemp;

    const finalCollabDataTemp = [
      ...finalData.instagramAnalytics.collaborations,
      {
        id: availableIds[0], collabImg: Placeholder, brandName: '', collabDesc: '',
      },
    ];
    const finalDataTemp = { ...finalData };
    finalDataTemp.instagramAnalytics.collaborations = finalCollabDataTemp;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const deleteCollaboration = async (index) => {
    try {
      // setIsFetching(true);
      const response = await API({
        url: `/account/${auth.account.id}/media-kit/${mkId}/instagram_collaboration/${finalData.instagramAnalytics.collaborations[index].id}/`,
        method: 'delete',
      });

      if (response.status === 200) {
        const collabDataTemp = formData.instagramAnalytics.collaborations.filter((_, i) => i !== index);
        const formDataTemp = { ...formData };
        formDataTemp.instagramAnalytics.collaborations = collabDataTemp;

        const finalCollabDataTemp = finalData.instagramAnalytics.collaborations.filter((_, i) => i !== index);
        const finalDataTemp = { ...finalData };
        finalDataTemp.instagramAnalytics.collaborations = finalCollabDataTemp;

        setFormData(formDataTemp);
        setFinalData(finalDataTemp);
      }
    } catch (_) {
      // setIsFetching(false);
    }
  };

  return (
    <>
      {formData.instagramAnalytics.collaborations.map((collab, index) => (
        <div
          className={classes.sectionRoot}
          style={index === formData.instagramAnalytics.collaborations.length - 1 ? { borderBottom: 'none' } : {}}
          key={collab.id}
        >
          <div className={classes.sectionListTitle}>
            <Typography
              variant="body1"
              component="div"
              color="primary"
              className={classes.sectionTitle}
            >
              Instagram Collaboration
              {' '}
              {index + 1}
            </Typography>
            <IconButton aria-label="delete" className={classes.delIconTitle} onClick={() => deleteCollaboration(index)}>
              <DeleteIcon />
            </IconButton>
          </div>

          <Typography variant="body2" component="p" className={classes.formLabel}>Upload Collaboration Image</Typography>
          <div className={classes.uploadWrapper}>
            <Avatar src={collab.collabImg} className={classes.imgAvatar}><ImageIcon /></Avatar>
            <input type="file" ref={imgRef} onChange={(e) => onImageUpload(e, index)} style={{ display: 'none' }} />
            {collab.collabImg === Placeholder ? (
              <Button
                className={classes.uploadBtn}
                size="small"
                onClick={() => {
                  document.getElementById('page-6-preview').scrollIntoView({
                    behavior: 'smooth',
                  });
                  imgRef.current.click();
                }}
              >
                Upload Image
              </Button>
            ) : (
              <Button
                className={classes.delImgBtn}
                size="small"
                startIcon={<DeleteIcon />}
                variant="outlined"
                onClick={() => onImageDelete(index)}
              >
                Delete Image
              </Button>
            )}
          </div>

          <TextField
            label="Brand Name"
            value={collab.brandName}
            placeholder={defaultFormData.instagramAnalytics.collaborations[index].brandName}
            style={{ marginBottom: 25 }}
            InputProps={{ className: classes.input }}
            fullWidth
            onChange={(e) => onBrandNameChange(e, index)}
            onFocus={() => {
              document.getElementById('page-6-preview').scrollIntoView({
                behavior: 'smooth',
              });
              setHighlighted(`instagramAnalytics.collaboration${index}`);
            }}
          />

          <TextField
            label="About The Collab"
            value={collab.collabDesc}
            placeholder={defaultFormData.instagramAnalytics.collaborations[index].collabDesc}
            InputProps={{ className: classes.input }}
            multiline
            rows={10}
            fullWidth
            onChange={(e) => onCollabDescChange(e, index)}
            onFocus={() => {
              document.getElementById('page-6-preview').scrollIntoView({
                behavior: 'smooth',
              });
              setHighlighted(`instagramAnalytics.collaboration${index}`);
            }}
          />
        </div>
      ))}
      {formData.instagramAnalytics.collaborations.length < 3 && (
        <div className={classes.sectionRoot} style={{ borderBottom: 'none' }}>
          <Button color="primary" size="small" onClick={addCollaboration}>+ Add Another Brand Collaboration</Button>
        </div>
      )}
    </>
  );
};

InstagramCollaborationsForm.propTypes = {
  formData: PropTypes.shape().isRequired,
  setFormData: PropTypes.func.isRequired,
  setHighlighted: PropTypes.func.isRequired,
  finalData: PropTypes.shape().isRequired,
  setFinalData: PropTypes.func.isRequired,
  mkId: PropTypes.number.isRequired,
};

export {
  InstagramAnalyticsForm,
  InsatgramAudienceForm,
  InstagramAudienceLocationForm,
  InstagramCollaborationsForm,
};
