import { SvgIcon } from '@material-ui/core';
import React from 'react';
// import { SvgIcon } from '@material-ui/core';

const Spark = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <svg width="30" height="25" viewBox="0 0 450 500">
      <g id="Avenir" transform="translate(-16.404 525.41)">
        <path
          id="Path_196"
          data-name="Path 196"
          d="M151.3-525a165.841,165.841,0,0,0-42.7,8.2c-41.3,13.6-70.5,41.4-80.7,76.7-4.5,15.6-4.4,39,.2,56.1C38.6-345.2,68-320.8,147-285.3c44.5,20,62.4,32.1,69.9,47.3,4.3,8.6,5.4,13.9,4.9,23.7-.7,11.4-3.3,18.2-10,25.8-13,14.5-31.2,20.6-55,18.4-20.6-1.9-37.1-9.6-52.7-24.3-8.2-7.9-15.4-17.6-26.6-36C60-259.2,47.4-268.2,31.7-263.1c-4.8,1.6-11.1,8.9-13.4,15.4-2.7,7.9-2.5,38.7.5,51.2,5,21.5,12.3,37.7,20.4,45.7,3.3,3.2,8.7,6.6,17,10.7C96.9-120,166.6-112.5,218-122.6c45.6-9,80.9-32.5,97.3-64.9,13.2-26,15.2-60.4,5.2-88.5-7.2-20.1-22-37.1-45.8-52.8-14.8-9.8-43.2-24.3-66.7-34.2-41.3-17.5-70.2-37.5-78.6-54.5-2.6-5.4-2.9-7-2.9-16,0-8.7.3-10.7,2.7-15.7,5.1-11,14.3-18.6,28.3-23.4,7.2-2.5,9.4-2.8,22.5-2.8,16.4-.1,22.6,1.1,34.9,6.9,12,5.7,23,15.5,39.4,35.1,20.5,24.6,35.7,32.8,49.2,26.5,9.4-4.3,13.6-11.8,14.3-25.3,1.1-22.1-6.8-46.5-21.6-67.2-17.1-23.7-34.5-30-52.7-19.1l-5.5,3.4-15.8-3.5C196.1-524.2,172.2-526.4,151.3-525Z"
          fill="#2B2E34"
        />
      </g>
    </svg>
  </SvgIcon>
);

export default Spark;
