import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  Typography,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import { Link } from '@reach/router';

import SearchPageBase from '../SearchPageBase';
import ImportProfilesStatusWidget from '../../list/ImportProfilesStatusWidget';
import RemoveFromListButton from '../../list/RemoveFromListButton';
import ListExportButton from '../../list/ListExportButton';
import ListSettingsButton from '../../list/ListSettingsButton';
import { useApi, useAuth, useNotification } from '../../../hooks';
import NoMatch from '../../router/NoMatch';

const useStyles = makeStyles(() => ({
  listHeader: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridTemplateAreas: `
      "left right"
    `,
    padding: '50px 40px 50px 40px',
  },
  listHeaderLeft: {
    gridArea: 'left',
  },
  listHeaderRight: {
    gridArea: 'right',
  },
  listSubHeading: {
    marginTop: 10,
  },
}));

const ListPage = ({ listId }) => {
  const [profiles, setProfiles] = useState(null);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const classes = useStyles();
  const { createNotification } = useNotification();
  const auth = useAuth();
  const [{ data: list, isFetching: isGetListFetching, status: listStatus }, setGetListRequest] = useApi();
  const [isDeleteListFetching, setIsDeleteListFetching] = useState(false);

  const refreshList = useCallback(() => {
    setGetListRequest({
      method: 'get',
      url: `/account/${auth.account.id}/list/${listId}/`,
    });
  }, [auth, listId, setGetListRequest]);

  useEffect(() => {
    refreshList();
  }, [refreshList]);

  const handleRemoveFromListButtonSuccess = (removedProfiles) => {
    refreshList();
    createNotification(
      'success',
      `${removedProfiles.length} profiles removed from list.`,
    );
  };

  if (!auth.hasPermission('list.create') || auth.account.type === 'influencer') {
    return (
      <NoMatch />
    );
  }

  if (listStatus === 404) {
    return (
      <div>
        <Typography variant="body1">
          Oops! We couldn&apos;t find that list for you.
        </Typography>
        <br />
        <Typography variant="body1">
          Go to the
          {' '}
          <Link to="/list">lists</Link>
          {' '}
          page to see your lists, or search for profiles on the
          {' '}
          <Link to="/discover">discover</Link>
          {' '}
          page.
        </Typography>
      </div>
    );
  }

  if (!list) {
    return <div />;
  }

  const listActions = [
    <RemoveFromListButton
      key="remove"
      list={list}
      onSuccess={handleRemoveFromListButtonSuccess}
      profiles={selectedProfiles}
    />,
    <ListSettingsButton
      key="settings"
      list={list}
      onSuccess={refreshList}
      setIsDeleteListFetching={setIsDeleteListFetching}
    />,
  ];
  if (auth.hasPermission('profile.export') && !_.isEmpty(profiles)) {
    listActions.push(
      <ListExportButton
        key="saveascsv"
        list={list}
        profiles={profiles}
      />,
    );
  }

  return (
    <SearchPageBase
      actions={listActions}
      header={list && (
        <div className={classes.listHeader}>
          <div className={classes.listHeaderLeft}>
            <div style={{ display: 'flex', justifyContent: 'space-between ' }}>
              <Typography variant="h4" style={{ marginBottom: 10 }}>{list.name}</Typography>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ width: 160 }}
                startIcon={<RefreshRoundedIcon />}
                onClick={() => refreshList()}
              >
                Refresh
              </Button>
            </div>
            <ImportProfilesStatusWidget list={list} profiles={profiles} />
          </div>
        </div>
      )}
      onProfilesChange={setProfiles}
      onSelectedProfilesChange={setSelectedProfiles}
      searchUrlBase={`/account/${auth.account.id}/list/${list.id}/search/`}
      showEmptyOnLoad={false}
      showLoadingBar={isGetListFetching || isDeleteListFetching}
      title={_.isEmpty(list) ? 'List' : `${list.name} - List`}
      list={list}
    />
  );
};

ListPage.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ListPage.defaultProps = {
  listId: null,
};

export default ListPage;
