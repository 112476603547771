import React from 'react';

import PropTypes from 'prop-types';

import RouteContainer from './RouteContainer';
import GlobalStore from '../../context/GlobalStore';

/**
 * Wraps child routes in the GlobalStore
 */
const GlobalStoreRouteContainer = ({ children }) => (
  <GlobalStore>
    <RouteContainer>
      {children}
    </RouteContainer>
  </GlobalStore>
);

GlobalStoreRouteContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GlobalStoreRouteContainer;
