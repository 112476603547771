import React from 'react';

import PropTypes from 'prop-types';

import { AuthStore } from './AuthContext';
import { ListsStore } from './ListsContext';
import { NotificationStore } from './NotificationContext';
import { NotesStore } from './NotesContext';
import { ContentManagerStore } from './ContentManagerContext';

const GlobalStore = ({ children }) => (
  <AuthStore>
    <ListsStore>
      <NotificationStore>
        <NotesStore>
          <ContentManagerStore>
            {children}
          </ContentManagerStore>
        </NotesStore>
      </NotificationStore>
    </ListsStore>
  </AuthStore>
);

GlobalStore.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GlobalStore;
