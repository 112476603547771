export default {
  TITLE: process.env.REACT_APP_TITLE,
  FAVICON: process.env.REACT_APP_FAVICON,

  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,

  STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,

  SENTRY_ENVIRONMENT: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
};
