import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import { Link as RouterLink } from '@reach/router';

import {
  Link,
  Typography,
} from '@material-ui/core';

import SparkAndSuContent from './SparkAndSuContent';
import MenuPageBase, { Menu, MenuItem } from '../pages/MenuPageBase';
import SparkIcon from '../common/icons/Spark';
import UniversityIcon from '../common/icons/University';
import { useApi, useRoute } from '../../hooks';
import BackButton from '../common/BackButton';

const SparkAndSuModulePage = ({
  moduleId, lessonId, path, title, isSpark,
}) => {
  const [route, setRoute] = useRoute();
  const [{ data: module, isFetching }] = useApi({
    method: 'get',
    url: `/${path}/module/${moduleId}/`,
  });

  const lesson = useMemo(() => {
    if (lessonId && module) {
      return module.children[parseInt(lessonId, 10) - 1];
    }
    return null;
  }, [lessonId, module]);

  const breadcrumbs = useMemo(() => {
    const crumbs = [
      <Link key="home" component={RouterLink} to={`/${path}`}>
        {title}
      </Link>,
    ];
    if (module && !lesson) {
      crumbs.push(
        <Typography key="module" variant="body1">
          {module.title}
        </Typography>,
      );
    } else if (module && lesson) {
      crumbs.push(
        <Link key="module" component={RouterLink} to={`/${path}/module/${moduleId}`}>
          {module.title}
        </Link>,
      );
      crumbs.push(
        <Typography key="lesson" variant="body1">
          {lesson.title}
        </Typography>,
      );
    }
    return crumbs;
  }, [lesson, module, moduleId, path, title]);

  const backButtonUrl = useMemo(() => {
    let url;
    if (isSpark) {
      url = `/${path}`;
    } else if (module && lesson) {
      url = `/${path}/module/${moduleId}`;
    } else {
      url = `/${path}`;
    }
    return url;
  }, [isSpark, module, lesson, path, moduleId]);

  return (
    <MenuPageBase
      backButton={<BackButton url={backButtonUrl} />}
      breadcrumbs={isSpark ? null : breadcrumbs}
      menu={(
        <Menu>
          {module && module.children && module.children.map((it, index) => (
            <MenuItem
              Icon={path === 'spark-ffp' ? SparkIcon : UniversityIcon}
              key={it.title}
              label={it.title}
              onClick={() => setRoute({ pathname: `/${path}/module/${moduleId}/lesson/${index + 1}` })}
              selected={route.pathname === `/${path}/module/${moduleId}/lesson/${index + 1}`}
            />
          ))}
        </Menu>
      )}
      showLoadingBar={isFetching}
      title={(lesson && lesson.title) || (module && module.title) || ''}
    >
      {!lessonId && module && <SparkAndSuContent content={module} />}
      {lessonId && lesson && <SparkAndSuContent content={lesson} />}
    </MenuPageBase>
  );
};

SparkAndSuModulePage.propTypes = {
  moduleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lessonId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isSpark: PropTypes.bool,
};

SparkAndSuModulePage.defaultProps = {
  moduleId: null,
  lessonId: null,
  isSpark: null,
};

export default SparkAndSuModulePage;
