import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  Avatar,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import DeleteIcon from '@material-ui/icons/Delete';
import { defaultFormData } from '../data';
import Placeholder from '../../../assets/images/media-kit/placeholder.png';

import { useStyles } from './styles';

/* ----------------------------------- MAIN PAGE --------------------------------*/
const MainPageForm = ({
  formData,
  setFormData,
  setHighlighted,
  finalData,
  setFinalData,
}) => {
  const classes = useStyles({ bb: true });
  const imgRef = useRef(null);

  const data = formData.personalDetails;

  useEffect(() => {
    document.getElementById('formWrapper').scrollTo(0, 0);
  }, []);

  const onImageUpload = (e) => {
    try {
      const imgUrl = URL.createObjectURL(e.target.files[0]);

      const formDataTemp = { ...formData };
      formDataTemp.personalDetails.mainImg = imgUrl;

      const finalDataTemp = { ...finalData };
      finalDataTemp.personalDetails.mainImg = imgUrl;

      setFormData(formDataTemp);
      setFinalData(finalDataTemp);
    } catch (err) {
      // Do Nothing
    }
  };

  const onImageDelete = () => {
    document.getElementById('page-1-preview').scrollIntoView({
      behavior: 'smooth',
    });

    const formDataTemp = { ...formData };
    formDataTemp.personalDetails.mainImg = Placeholder;

    const finalDataTemp = { ...finalData };
    finalDataTemp.personalDetails.mainImg = Placeholder;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const onChangeFirstName = (e) => {
    const formDataTemp = { ...formData };
    formDataTemp.personalDetails.firstName = e.target.value;

    const finalDataTemp = { ...finalData };
    finalDataTemp.personalDetails.firstName = e.target.value;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const onChangeLastName = (e) => {
    const formDataTemp = { ...formData };
    formDataTemp.personalDetails.lastName = e.target.value;

    const finalDataTemp = { ...finalData };
    finalDataTemp.personalDetails.lastName = e.target.value;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const onChangeTitle = (e) => {
    const formDataTemp = { ...formData };
    formDataTemp.personalDetails.professionalTitle = e.target.value;

    const finalDataTemp = { ...finalData };
    finalDataTemp.personalDetails.professionalTitle = e.target.value;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  return (
    <div className={classes.sectionRoot}>
      <Typography
        variant="body1"
        component="div"
        color="primary"
        className={classes.sectionTitle}
      >
        Main Page Details
      </Typography>
      <Typography variant="body2" component="p" className={classes.formLabel}>Upload Main Page Image</Typography>
      <div className={classes.uploadWrapper}>
        <Avatar
          src={data.mainImg}
          className={classes.imgAvatar}
        >
          <ImageIcon />
        </Avatar>
        <input type="file" ref={imgRef} onChange={onImageUpload} style={{ display: 'none' }} />
        {data.mainImg === Placeholder ? (
          <Button
            className={classes.uploadBtn}
            size="small"
            onClick={() => {
              document.getElementById('page-1-preview').scrollIntoView({
                behavior: 'smooth',
              });
              setHighlighted('personalDetails.mainImg');
              imgRef.current.click();
            }}
          >
            Upload Image
          </Button>
        ) : (
          <Button
            className={classes.delImgBtn}
            size="small"
            startIcon={<DeleteIcon />}
            variant="outlined"
            onClick={onImageDelete}
          >
            Delete Image
          </Button>
        )}
      </div>
      <div className={classes.flexRow} style={{ marginBottom: 25 }}>
        <TextField
          label="First Name"
          value={data.firstName}
          placeholder={defaultFormData.personalDetails.firstName}
          InputProps={{ className: classes.input }}
          style={{ width: '48%', marginRight: '4%' }}
          onChange={onChangeFirstName}
          onFocus={() => {
            setHighlighted('personalDetails.firstName');
          }}
        />
        <TextField
          label="Last Name"
          value={data.lastName}
          placeholder={defaultFormData.personalDetails.lastName}
          InputProps={{ className: classes.input }}
          style={{ width: '48%' }}
          onChange={onChangeLastName}
          onFocus={() => {
            document.getElementById('page-1-preview').scrollIntoView({
              behavior: 'smooth',
            });
            setHighlighted('personalDetails.lastName');
          }}
        />
      </div>
      <TextField
        label="Professional Title"
        value={data.professionalTitle}
        placeholder={defaultFormData.personalDetails.professionalTitle}
        InputProps={{ className: classes.input }}
        fullWidth
        onChange={onChangeTitle}
        onFocus={() => {
          document.getElementById('page-1-preview').scrollIntoView({
            behavior: 'smooth',
          });
          setHighlighted('personalDetails.professionalTitle');
        }}
      />
    </div>
  );
};

MainPageForm.propTypes = {
  formData: PropTypes.shape().isRequired,
  setFormData: PropTypes.func.isRequired,
  setHighlighted: PropTypes.func.isRequired,
  finalData: PropTypes.shape().isRequired,
  setFinalData: PropTypes.func.isRequired,
};

/* ----------------------------------- ABOUT PAGE --------------------------------*/
const AboutPageForm = ({
  formData,
  setFormData,
  setHighlighted,
  finalData,
  setFinalData,
}) => {
  const classes = useStyles({ bb: true });
  const imgRef = useRef(null);

  useEffect(() => {
    document.getElementById('formWrapper').scrollTo(0, 0);
  }, []);

  const onImageUpload = (e) => {
    try {
      const imgUrl = URL.createObjectURL(e.target.files[0]);

      const formDataTemp = { ...formData };
      formDataTemp.personalDetails.aboutImg = imgUrl;

      const finalDataTemp = { ...finalData };
      finalDataTemp.personalDetails.aboutImg = imgUrl;

      setFormData(formDataTemp);
      setFinalData(finalDataTemp);
    } catch (err) {
      // Do Nothing
    }
  };

  const onImageDelete = () => {
    document.getElementById('page-2-preview').scrollIntoView({
      behavior: 'smooth',
    });

    const formDataTemp = { ...formData };
    formDataTemp.personalDetails.aboutImg = Placeholder;

    const finalDataTemp = { ...finalData };
    finalDataTemp.personalDetails.aboutImg = Placeholder;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const onChangeAboutUs = (e) => {
    const formDataTemp = { ...formData };
    formDataTemp.personalDetails.about = e.target.value;

    const finalDataTemp = { ...finalData };
    finalDataTemp.personalDetails.about = e.target.value;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  return (
    <div className={classes.sectionRoot}>
      <Typography
        variant="body1"
        component="div"
        color="primary"
        className={classes.sectionTitle}
      >
        About Page Details
      </Typography>
      <Typography variant="body2" component="p" className={classes.formLabel}>Upload About Page Image</Typography>
      <div className={classes.uploadWrapper}>
        <Avatar src={formData.personalDetails.aboutImg} className={classes.imgAvatar}><ImageIcon /></Avatar>
        <input type="file" ref={imgRef} onChange={onImageUpload} style={{ display: 'none' }} />
        {formData.personalDetails.aboutImg === Placeholder ? (
          <Button
            className={classes.uploadBtn}
            size="small"
            onClick={() => {
              document.getElementById('page-2-preview').scrollIntoView({
                behavior: 'smooth',
              });
              setHighlighted('personalDetails.aboutImg');
              imgRef.current.click();
            }}
          >
            Upload Image
          </Button>
        ) : (
          <Button
            className={classes.delImgBtn}
            size="small"
            startIcon={<DeleteIcon />}
            variant="outlined"
            onClick={onImageDelete}
          >
            Delete Image
          </Button>
        )}
      </div>
      <TextField
        label="About Me"
        value={formData.personalDetails.about}
        placeholder={defaultFormData.personalDetails.about}
        InputProps={{ className: classes.input }}
        multiline
        rows={10}
        fullWidth
        onChange={onChangeAboutUs}
        onFocus={() => {
          document.getElementById('page-2-preview').scrollIntoView({
            behavior: 'smooth',
          });
          setHighlighted('personalDetails.about');
        }}
      />
    </div>
  );
};

AboutPageForm.propTypes = {
  formData: PropTypes.shape().isRequired,
  setFormData: PropTypes.func.isRequired,
  setHighlighted: PropTypes.func.isRequired,
  finalData: PropTypes.shape().isRequired,
  setFinalData: PropTypes.func.isRequired,
};

/* ----------------------------------- CONTACT PAGE --------------------------------*/
const ContactPageForm = ({
  formData,
  setFormData,
  setHighlighted,
  finalData,
  setFinalData,
}) => {
  const classes = useStyles();
  const imgRef = useRef(null);

  useEffect(() => {
    document.getElementById('formWrapper').scrollTo(0, 0);
  }, []);

  const onImageUpload = (e) => {
    try {
      const imgUrl = URL.createObjectURL(e.target.files[0]);
      const formDataTemp = { ...formData };
      formDataTemp.personalDetails.contactImg = imgUrl;

      const finalDataTemp = { ...finalData };
      finalDataTemp.personalDetails.contactImg = imgUrl;

      setFormData(formDataTemp);
      setFinalData(finalDataTemp);
    } catch (err) {
      // Do Nothing
    }
  };

  const onImageDelete = () => {
    document.getElementById('page-3-preview').scrollIntoView({
      behavior: 'smooth',
    });

    const formDataTemp = { ...formData };
    formDataTemp.personalDetails.contactImg = Placeholder;

    const finalDataTemp = { ...finalData };
    finalDataTemp.personalDetails.contactImg = Placeholder;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const onChangeInstagramHandle = (e) => {
    const formDataTemp = { ...formData };
    formDataTemp.personalDetails.instagramHandle = e.target.value;

    const finalDataTemp = { ...finalData };
    finalDataTemp.personalDetails.instagramHandle = e.target.value;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const onChangeWebsiteUrl = (e) => {
    const formDataTemp = { ...formData };
    formDataTemp.personalDetails.websiteUrl = e.target.value;

    const finalDataTemp = { ...finalData };
    finalDataTemp.personalDetails.websiteUrl = e.target.value;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const onChangeEmailAddress = (e) => {
    const formDataTemp = { ...formData };
    formDataTemp.personalDetails.email = e.target.value;

    const finalDataTemp = { ...finalData };
    finalDataTemp.personalDetails.email = e.target.value;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const onChangeMobile = (e) => {
    const formDataTemp = { ...formData };
    formDataTemp.personalDetails.mobile = e.target.value;

    const finalDataTemp = { ...finalData };
    finalDataTemp.personalDetails.mobile = e.target.value;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const onChangeShippingAddress = (e) => {
    const formDataTemp = { ...formData };
    formDataTemp.personalDetails.shippingAddress = e.target.value;

    const finalDataTemp = { ...finalData };
    finalDataTemp.personalDetails.shippingAddress = e.target.value;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const onChangeAvailableFor = (e) => {
    const formDataTemp = { ...formData };
    formDataTemp.personalDetails.availableFor = e.target.value;

    const finalDataTemp = { ...finalData };
    finalDataTemp.personalDetails.availableFor = e.target.value;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  return (
    <div className={classes.sectionRoot}>
      <Typography
        variant="body1"
        component="div"
        color="primary"
        className={classes.sectionTitle}
      >
        Contact Page Details
      </Typography>
      <Typography variant="body2" component="p" className={classes.formLabel}>Upload Contact Page Image</Typography>
      <div className={classes.uploadWrapper}>
        <Avatar src={formData.personalDetails.contactImg} className={classes.imgAvatar}><ImageIcon /></Avatar>
        <input type="file" ref={imgRef} onChange={onImageUpload} style={{ display: 'none' }} />
        {formData.personalDetails.contactImg === Placeholder ? (
          <Button
            className={classes.uploadBtn}
            size="small"
            onClick={() => {
              document.getElementById('page-3-preview').scrollIntoView({
                behavior: 'smooth',
              });
              setHighlighted('personalDetails.contactImg');
              imgRef.current.click();
            }}
          >
            Upload Image
          </Button>
        ) : (
          <Button
            className={classes.delImgBtn}
            size="small"
            startIcon={<DeleteIcon />}
            variant="outlined"
            onClick={onImageDelete}
          >
            Delete Image
          </Button>
        )}
      </div>
      <div className={classes.flexRow} style={{ marginBottom: 25 }}>
        <TextField
          label="Instagram Handle"
          value={formData.personalDetails.instagramHandle}
          placeholder={defaultFormData.personalDetails.instagramHandle}
          style={{ width: '48%', marginRight: '4%' }}
          InputProps={{
            className: classes.input,
            startAdornment: <InputAdornment position="start" classes={{ root: classes.adornment }}>@</InputAdornment>,
          }}
          onChange={onChangeInstagramHandle}
          onFocus={() => {
            document.getElementById('page-3-preview').scrollIntoView({
              behavior: 'smooth',
            });
            setHighlighted('personalDetails.instagramHandle');
          }}
        />
        <TextField
          label="Website Url"
          value={formData.personalDetails.websiteUrl}
          placeholder={defaultFormData.personalDetails.websiteUrl}
          style={{ width: '48%' }}
          InputProps={{
            className: classes.input,
          }}
          onChange={onChangeWebsiteUrl}
          onFocus={() => {
            document.getElementById('page-3-preview').scrollIntoView({
              behavior: 'smooth',
            });
            setHighlighted('personalDetails.websiteUrl');
          }}
        />
      </div>
      <div className={classes.flexRow} style={{ marginBottom: 25 }}>
        <TextField
          label="Email Address"
          value={formData.personalDetails.email}
          placeholder={defaultFormData.personalDetails.email}
          style={{ width: '48%', marginRight: '4%' }}
          InputProps={{
            className: classes.input,
          }}
          onChange={onChangeEmailAddress}
          onFocus={() => {
            document.getElementById('page-3-preview').scrollIntoView({
              behavior: 'smooth',
            });
            setHighlighted('personalDetails.email');
          }}
        />
        <TextField
          label="Mobile Number"
          value={formData.personalDetails.mobile}
          placeholder={defaultFormData.personalDetails.mobile}
          style={{ width: '48%' }}
          InputProps={{
            className: classes.input,
          }}
          onChange={onChangeMobile}
          onFocus={() => {
            document.getElementById('page-3-preview').scrollIntoView({
              behavior: 'smooth',
            });
            setHighlighted('personalDetails.mobile');
          }}
        />
      </div>
      <TextField
        label="Shipping Address"
        value={formData.personalDetails.shippingAddress}
        placeholder={defaultFormData.personalDetails.shippingAddress}
        style={{ marginBottom: 25 }}
        InputProps={{
          className: classes.input,
          startAdornment: <InputAdornment position="start" classes={{ root: classes.adornment }}>Based In</InputAdornment>,
        }}
        fullWidth
        onChange={onChangeShippingAddress}
        onFocus={() => {
          document.getElementById('page-3-preview').scrollIntoView({
            behavior: 'smooth',
          });
          setHighlighted('personalDetails.shippingAddress');
        }}
      />
      <TextField
        label="Available for"
        value={formData.personalDetails.availableFor}
        placeholder={defaultFormData.personalDetails.availableFor}
        fullWidth
        style={{ minHeight: 43.5 }}
        InputProps={{
          className: classes.input,
        }}
        multiline
        rows={10}
        onChange={onChangeAvailableFor}
        onFocus={() => {
          document.getElementById('page-3-preview').scrollIntoView({
            behavior: 'smooth',
          });
          setHighlighted('personalDetails.availableFor');
        }}
      />
    </div>
  );
};

ContactPageForm.propTypes = {
  formData: PropTypes.shape().isRequired,
  setFormData: PropTypes.func.isRequired,
  setHighlighted: PropTypes.func.isRequired,
  finalData: PropTypes.shape().isRequired,
  setFinalData: PropTypes.func.isRequired,
};

export {
  MainPageForm,
  AboutPageForm,
  ContactPageForm,
};
