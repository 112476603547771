import React, { useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga';

import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Button,
  LinearProgress,
  Paper,
  TextField,
  Typography,
  Divider,
  Select,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CreditCardField from './CreditCardField';
import API from '../../utils/api';
import { useAuth, useNotification } from '../../hooks';
import SlidingPaneBase from './SlidingPaneBase';
import SafeCheckoutHeader from './SafeCheckoutHeader';
import SaveDataBeforeDowngradeDialog from './SaveDataBeforeDowngradeDialog';

const useStyles = makeStyles((theme) => ({
  content: {
    '&>:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
  },
  planDetailsHeader: {
    backgroundColor: '#FAE7DA',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  label: {
    fontWeight: 600,
  },
  select: {
    border: 'none',
    '& div': {
      padding: 0,
      ...theme.textStyles.bodyExtraSmall,
      color: theme.colors.system.grey7,
    },
    '& svg': {
      color: theme.colors.system.grey7,
    },
  },
  extraSmallText: {
    ...theme.textStyles.bodyExtraSmall,
  },
  extraSmallTextGrey: {
    ...theme.textStyles.bodyExtraSmall,
    color: theme.colors.system.grey7,
  },
  divider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  lineItemValues: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  submitBtn: {
    width: '200px',
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  alert: {
    color: theme.colors.primary.main,
    ...theme.textStyles.bodyExtraSmall,
    marginTop: theme.spacing(1),
  },
}));

const ChangePlanContent = (props) => {
  const {
    isUpgrade,
    nextPlan,
    altPlan,
    onClose,
    onSuccess,
  } = props;
  const [billingData, setBillingData] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(nextPlan);
  const [paymentMethod, setPaymentMethod] = useState();
  const auth = useAuth();
  const { createNotification } = useNotification();
  const classes = useStyles();
  const customer = billingData ? billingData.customer : null;
  const paidPlans = ['basic_1791_year', 'basic_199_month', 'influencer_100_year', 'influencer_10_month', 'power_2691_year', 'power_299_month', 'agency_499_month', 'agency_4491_year'];

  useEffect(() => {
    setPaymentMethod(customer ? customer.invoice_settings.default_payment_method : null);
  }, [customer]);

  const refreshCustomer = useCallback(async () => {
    setIsFetching(true);
    const response = await API({
      method: 'get',
      url: `/account/${auth.account.id}/billing/`,
    });
    if (response.status === 200) {
      setBillingData(response.data.data);
    }
    setIsFetching(false);
  }, [auth]);

  useEffect(() => {
    refreshCustomer();
  }, [refreshCustomer]);

  const handleSubmit = async () => {
    setIsFetching(true);
    const response = await API({
      method: 'post',
      url: `/account/${auth.account.id}/billing/change-plan/`,
      data: {
        next_plan_id: selectedPlan.id,
        coupon_code: couponCode || null,
      },
      validateStatus: (status) => status <= 400,
    });

    if (response.status === 200) {
      createNotification('success', 'Plan changed successfully!');

      if (paidPlans.includes(selectedPlan.id)) {
        ReactGA.event({
          category: 'Subscription',
          action: 'Updated',
          label: selectedPlan.id,
          value: selectedPlan.amount.toFixed(2) / 100,
        });
      }

      if (onSuccess) {
        onSuccess();
      }
      onClose(false);
    } else if (response.status === 400) {
      if (response.data.message === 'payment_method_required') {
        createNotification('error', 'Could not change plan, please enter a valid credit card.');
      } else if (response.data.message === 'card_declined') {
        createNotification('error', 'Could not change plan, credit card has been declined.');
      } else if (response.data.message === 'invalid_coupon') {
        createNotification('error', 'Could not change plan, the coupon is not a valid coupon code.');
      }
    }
    setIsFetching(false);
  };

  const handleCreditCardSuccess = () => {
    refreshCustomer();
  };

  const handlePlanSelect = (e) => {
    if (e.target.value !== selectedPlan.interval) {
      setSelectedPlan(selectedPlan.interval === nextPlan.interval ? altPlan : nextPlan);
    }
  };

  return (
    <>
      <div className={classes.content}>
        {isFetching && (
          <LinearProgress variant="indeterminate" />
        )}
        <Paper variant="outlined">
          <Paper elevation={0} square className={`${classes.planDetailsHeader} ${classes.flex}`}>
            <div>
              <Typography variant="body2" className={classes.label}>
                {`${nextPlan.product.name} Plan`}
              </Typography>
              {'id' in altPlan ? (
                <Select
                  value={selectedPlan.interval}
                  onChange={handlePlanSelect}
                  className={classes.select}
                >
                  <MenuItem value="year">Billed Yearly</MenuItem>
                  <MenuItem value="month">Billed Monthly</MenuItem>
                </Select>
              ) : (
                <Typography variant="body2" className={classes.extraSmallTextGrey}>
                  {`Billed ${nextPlan.interval === 'year' ? 'Yearly' : 'Monthly'}`}
                </Typography>
              )}
            </div>
            <Typography variant="h5">
              {`$${selectedPlan.amount / 100} `}
              <span className={classes.extraSmallTextGrey}>
                {`${selectedPlan.currency.toUpperCase()}/${selectedPlan.interval}`}
              </span>
            </Typography>
          </Paper>
          {!isUpgrade && customer && (
            <Typography variant="body2" className={`${classes.flex} ${classes.extraSmallTextGrey}`}>
              {`Your plan change will be effective from the start of your next billing cycle: ${moment.unix(customer.subscriptions.data[0].current_period_end).format('Do MMMM, YYYY')}`}
            </Typography>
          )}
          {selectedPlan.interval === 'year'
          && (
          <>
            <div className={classes.flex}>
              <div>
                <Typography className={`${classes.extraSmallText} ${classes.marginBottom}`}>
                  {`${selectedPlan.currency.toUpperCase()} $${selectedPlan.amount / (selectedPlan.isInfluencer ? 1000 : 900)}.00 x 12 months`}
                </Typography>
                <Typography className={classes.extraSmallText}>
                  {`${selectedPlan.currency.toUpperCase()} $${selectedPlan.amount / (selectedPlan.isInfluencer ? 1000 : 900)}.00 x ${selectedPlan.isInfluencer ? 2 : 3} free months`}
                </Typography>
              </div>
              <div className={classes.lineItemValues}>
                <Typography className={`${classes.extraSmallText} ${classes.marginBottom}`}>
                  {`${selectedPlan.currency.toUpperCase()} $${Math.round(selectedPlan.amount / (selectedPlan.isInfluencer ? 83.33 : 75))}.00`}
                </Typography>
                <Typography className={classes.extraSmallText}>
                  {`- ${selectedPlan.currency.toUpperCase()} $${selectedPlan.amount / (selectedPlan.isInfluencer ? 500 : 300)}.00`}
                </Typography>
              </div>
            </div>
            <Divider className={classes.divider} />
            <div className={classes.flex}>
              <Typography className={classes.extraSmallText}>Total</Typography>
              <Typography className={classes.extraSmallText}>{`${selectedPlan.currency.toUpperCase()} $${selectedPlan.amount / 100}.00`}</Typography>
            </div>
          </>
          )}
        </Paper>
        <TextField
          placeholder="Promo code? (if any)"
          onChange={(e) => setCouponCode(e.target.value)}
          value={couponCode}
          fullWidth
        />
        <div>
          <div>
            {customer && (
            <CreditCardField customer={customer} onSuccess={handleCreditCardSuccess} />
            )}
            {!paymentMethod && (
            <Typography variant="body2" className={classes.alert}>*Please enter a payment method.</Typography>
            )}
          </div>
        </div>
        <Button
          color="primary"
          disabled={isFetching || (nextPlan.altIsCurrentPlan && selectedPlan === altPlan) || !paymentMethod}
          onClick={handleSubmit}
          variant="contained"
          className={classes.submitBtn}
        >
          {nextPlan.altIsCurrentPlan && selectedPlan === altPlan ? 'Currently Subscribed' : `${isUpgrade ? 'Upgrade' : 'Downgrade'} Now`}
        </Button>
      </div>
    </>
  );
};

ChangePlanContent.propTypes = {
  isUpgrade: PropTypes.bool.isRequired,
  nextPlan: PropTypes.shape().isRequired,
  altPlan: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};

ChangePlanContent.defaultProps = {
  onSuccess: null,
  altPlan: null,
};

const ChangePlanButton = (props) => {
  const {
    isUpgrade,
    nextPlan,
    onSuccess,
    altIntervalPlan,
    dataSaverPlan,
    currentPlan,
  } = props;
  const [paneIsOpen, setPaneIsOpen] = useState(false);
  const auth = useAuth();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDataSaver, setIsDataSaver] = useState(false);

  const handleUpDowngrade = () => {
    if (nextPlan.product.name === 'Starter'
    && currentPlan.id !== 'datasaver_19_month') {
      setIsDialogOpen(true);
    } else {
      setPaneIsOpen(true);
    }
  };

  const downgradeWithoutSaving = () => {
    setIsDataSaver(false);
    setIsDialogOpen(false);
    setPaneIsOpen(true);
  };

  const downgradeToDataSaver = () => {
    setIsDialogOpen(false);
    setPaneIsOpen(true);
    setIsDataSaver(true);
  };

  return (
    <div>
      {!auth.hasPermission('manager.restricted')
        && (
        <Button
          color="primary"
          fullWidth
          onClick={handleUpDowngrade}
          variant={isUpgrade ? 'contained' : 'outlined'}
          size="small"
          style={{ padding: '11px 0' }}
        >
          {isUpgrade ? 'Upgrade' : 'Downgrade'}
        </Button>
        )}
      <SaveDataBeforeDowngradeDialog
        isDialogOpen={isDialogOpen}
        closeDialog={() => setIsDialogOpen(false)}
        downgradeToDataSaver={downgradeToDataSaver}
        downgradeWithoutSaving={downgradeWithoutSaving}
      />
      <SlidingPaneBase
        paneIsOpen={paneIsOpen}
        onRequestClose={setPaneIsOpen}
      >
        {isDataSaver ? (
          <SafeCheckoutHeader
            title="Downgrade Plan"
            subTitle="Downgrading plan to Data Saver"
          />
        ) : (
          <SafeCheckoutHeader
            title={`${isUpgrade ? 'Upgrade' : 'Downgrade'} Plan`}
            subTitle={`${isUpgrade ? 'Upgrading' : 'Downgrading'} plan to ${nextPlan.product.name === 'Influencer' ? 'Influencer (Pro)' : nextPlan.product.name}`}
          />
        )}
        <ChangePlanContent
          isUpgrade={isUpgrade}
          nextPlan={isDataSaver ? dataSaverPlan : nextPlan}
          altPlan={altIntervalPlan}
          onClose={setPaneIsOpen}
          onSuccess={onSuccess}
        />
      </SlidingPaneBase>
    </div>
  );
};

ChangePlanButton.propTypes = {
  isUpgrade: PropTypes.bool.isRequired,
  nextPlan: PropTypes.shape().isRequired,
  altIntervalPlan: PropTypes.shape(),
  onSuccess: PropTypes.func,
  dataSaverPlan: PropTypes.shape(),
  currentPlan: PropTypes.shape().isRequired,
};

ChangePlanButton.defaultProps = {
  onSuccess: null,
  altIntervalPlan: null,
  dataSaverPlan: null,
};

export default ChangePlanButton;
