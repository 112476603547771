import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Typography, Link } from '@material-ui/core';
import { Link as RouterLink } from '@reach/router';

import CalendarPosts from '../../content-approval/CalendarPosts';
import CampaignInfluencers from '../../content-approval/CampaignInfluencers';
import CampaignAnalytics from '../../content-approval/CampaignAnalytics';
import PlainPageBase from '../PlainPageBase';
import { useAuth, useContentManager } from '../../../hooks';
import BackButton from '../../common/BackButton';
import Tabs from '../../common/Tabs';
import { formatDate } from '../../../utils/datetime';
import NoMatch from '../../router/NoMatch';

const CampaignDashboardPage = ({ brandId, campaignId }) => {
  const auth = useAuth();
  const content = useContentManager();
  const campaign = content.campaign || { posts: [] };
  const [whichTab, setWhichTab] = useState('calendar');
  const [initialFetch, setInitialFetch] = useState(false);

  useEffect(() => {
    if (!initialFetch) {
      if (!content.brand) {
        content.getBrandCampaignsPosts(auth.account.id, brandId);
      }
      if (
        content.isNewCampaignPost
        || content.campaign.id !== Number(campaignId)
      ) {
        content.getCampaignAndPosts(auth.account.id, brandId, campaignId);
      }
      setInitialFetch(true);
    }
  }, [content.brand, initialFetch, auth, brandId, campaignId, content]);

  const breadcrumbs = useMemo(
    () => [
      <Link key="home" component={RouterLink} to="/content-approval/">
        Dashboard
      </Link>,
      <Link
        key="brand"
        component={RouterLink}
        to={`/content-approval/${brandId}/`}
      >
        {content.brand && content.brand.username ? content.brand.username : ''}
      </Link>,
      <Typography key="brand" variant="body1">
        {content.fetching ? 'Loading...' : campaign.name}
      </Typography>,
    ],
    [brandId, content, campaign],
  );

  if (initialFetch && !content.fetching && !content.campaign) {
    return <NoMatch />;
  }

  return (
    <PlainPageBase
      title={content.fetching ? 'Loading...' : campaign.name || ''}
      breadcrumbs={breadcrumbs}
      // showLoadingBar={content.fetching}
      backButton={<BackButton url={`/content-approval/${brandId}/`} />}
      subtitle={`${formatDate(campaign.startdate)} - ${formatDate(
        campaign.enddate,
      )}`}
    >
      <Tabs
        noDataNeeded
        tabOneActive={whichTab === 'calendar'}
        tabTwoActive={whichTab === 'influencers'}
        tabOneTitle="Calendar"
        tabTwoTitle="Influencers"
        tabThreeTitle="Analytics"
        changeToTabOne={() => setWhichTab('calendar')}
        changeToTabTwo={() => setWhichTab('influencers')}
        changeToTabThree={() => setWhichTab('analytics')}
        fontSize="1rem"
      />
      {whichTab === 'calendar' && <CalendarPosts />}
      {whichTab === 'influencers' && <CampaignInfluencers />}
      {whichTab === 'analytics' && <CampaignAnalytics />}
    </PlainPageBase>
  );
};

CampaignDashboardPage.propTypes = {
  brandId: PropTypes.string,
  campaignId: PropTypes.string,
};

CampaignDashboardPage.defaultProps = {
  brandId: '-',
  campaignId: '-',
};

export default CampaignDashboardPage;
