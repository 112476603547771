import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
} from '@material-ui/core';

const Select = (props) => {
  const {
    className,
    children,
    label,
    onChange,
    type,
    value,
    ...selectProps
  } = props;

  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    if (label !== '') {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, [label]);

  return (
    <FormControl className={className}>
      {label === '' ? '' : <InputLabel ref={inputLabel}>{label}</InputLabel>}
      <MuiSelect
        labelWidth={labelWidth}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...selectProps}
        type={type}
        value={value}
        onChange={onChange}
      >
        {children}
      </MuiSelect>
    </FormControl>
  );
};

const valuePropType = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
  PropTypes.shape(),
]);

Select.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  label: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(valuePropType),
    valuePropType,
  ]),
};

Select.defaultProps = {
  className: '',
  children: null,
  label: null,
  onChange: null,
  type: null,
  value: null,
};

export default Select;
