import React, { useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import Profile from '../../utils/profile';
// import { checkUnique } from '../../utils/contentManager';
import ProfileTable from '../search/ProfileTable';
import ImportIcon from '../common/icons/Import';
import AddNewInfluencerDialog from './AddNewInfluencerDialog';
import { useContentManager, useAuth } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  btnCont: {
    position: 'relative',
    width: '80vw',
  },
  addBtn: {
    position: 'absolute',
    right: 0,
  },
  emptyCont: {
    borderBottom: `1px solid ${theme.colors.system.grey5}`,
    padding: '0 0 30px 10px',
  },
  emptyWords: {
    color: theme.colors.system.grey6,
  },
}));

const CampaignInfluencers = () => {
  const classes = useStyles();
  const auth = useAuth();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const content = useContentManager();
  const influencers = content.campaign && content.campaign.influencers;
  // const uniqInfs = influencers && checkUnique(influencers);
  const isClient = auth.user.agency_role === 'client';

  const handleOnClose = () => {
    // refresh list
    setIsDialogOpen(false);
  };

  return (
    <>
      {!isClient && isDialogOpen && (
        <AddNewInfluencerDialog onClose={handleOnClose} />
      )}
      <div className={classes.btnCont}>
        {influencers && influencers.length > 0 && (
          <Button style={{ marginBottom: '20px' }}>
            <ImportIcon style={{ fontSize: '15px' }} />
          </Button>
        )}
        <Button
          onClick={() => setIsDialogOpen(true)}
          className={classes.addBtn}
          color="primary"
          style={{ marginBottom: '20px' }}
        >
          <AddIcon />
          {' '}
          Add A New Influencer
        </Button>
      </div>
      {influencers && influencers.length > 0 ? (
        <ProfileTable profiles={influencers.map((e) => Profile(e)) || []} />
      ) : (
        <div className={classes.emptyCont}>
          <Typography className={classes.emptyWords}>
            Your Campaign currently has no Influencers.
          </Typography>
          <Typography className={classes.emptyWords}>
            Start by adding new Influencers now.
          </Typography>
        </div>
      )}
    </>
  );
};

export default CampaignInfluencers;
