import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { colors } from '../definitions';
import { previewStyles } from '../form/styles';

const useStyles = makeStyles(() => ({
  highlighted: {
    border: `2px solid ${colors.PRIMARY}`,
  },
  root: {
    backgroundColor: ({ size }) => (size === 1 ? colors.BG_DARK : colors.BG_LIGHT),
    display: 'flex',
    flexDirection: 'column',
    height: 250,
    padding: 15,
    marginTop: 20,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  main: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: '20px 0',
  },
  singleRoot: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  doubleRoot: {
    width: '50%',
    height: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: ({ index }) => (index === 0 || index === 3 ? colors.BG_DARK : colors.BG_LIGHT),
    padding: 15,
  },
  blockRoot: {
    width: '33%',
    height: '50%',
    padding: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: ({ index }) => (index % 2 === 0 ? colors.BG_DARK : colors.BG_LIGHT),
  },
}));

const SingleBlock = ({ item, highlight }) => {
  const classes = useStyles();
  const textStyles = previewStyles();

  return (
    <div className={`${classes.singleRoot} ${highlight ? classes.highlighted : ''}`}>
      <div className={textStyles.h1} style={{ marginBottom: 5, textAlign: 'center' }}>
        {item.rate}
      </div>
      <div className={textStyles.h5} style={{ marginBottom: 5, textAlign: 'center' }}>
        {item.title}
      </div>
      <div className={textStyles.body} style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>
        {item.serviceDesc}
      </div>
    </div>
  );
};

SingleBlock.propTypes = {
  item: PropTypes.shape().isRequired,
  highlight: PropTypes.bool,
};

SingleBlock.defaultProps = {
  highlight: false,
};

const DoubleBlock = ({ item, highlight, index }) => {
  const classes = useStyles({ index });
  const textStyles = previewStyles();

  return (
    <div className={`${classes.doubleRoot} ${highlight ? classes.highlighted : ''}`}>
      <div className={textStyles.h2} style={{ marginBottom: 5, textAlign: 'center' }}>
        {item.rate}
      </div>
      <div className={textStyles.h5} style={{ marginBottom: 5, textAlign: 'center' }}>
        {item.title}
      </div>
      <div className={textStyles.body} style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>
        {item.serviceDesc}
      </div>
    </div>
  );
};

DoubleBlock.propTypes = {
  item: PropTypes.shape().isRequired,
  highlight: PropTypes.bool,
  index: PropTypes.number.isRequired,
};

DoubleBlock.defaultProps = {
  highlight: false,
};

const Block = ({ item, highlight, index }) => {
  const classes = useStyles({ index });
  const textStyles = previewStyles();

  return (
    <div className={`${classes.blockRoot} ${highlight ? classes.highlighted : ''}`}>
      <div className={textStyles.h3} style={{ marginBottom: 5, textAlign: 'center' }}>
        {item.rate}
      </div>
      <div className={textStyles.h5} style={{ marginBottom: 5, textAlign: 'center' }}>
        {item.title}
      </div>
      <div className={textStyles.body} style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>
        {item.serviceDesc}
      </div>
    </div>
  );
};

Block.propTypes = {
  item: PropTypes.shape().isRequired,
  highlight: PropTypes.bool,
  index: PropTypes.number.isRequired,
};

Block.defaultProps = {
  highlight: false,
};

const Page8Preview = ({ finalData, highlighted }) => {
  const classes = useStyles({ size: finalData.services.length });
  const textStyles = previewStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={textStyles.h5}>Rate Card</div>
        <div className={textStyles.body}>
          {finalData.personalDetails.firstName}
          &nbsp;
          {finalData.personalDetails.lastName}
        </div>
      </div>
      <div className={classes.main}>
        {finalData.services.length === 1 && (
          <SingleBlock item={finalData.services[0]} highlight={highlighted === 'services0'} />
        )}

        {(finalData.services.length === 2 || finalData.services.length === 4) && (
          finalData.services.map((item, index) => (
            <DoubleBlock item={item} index={index} highlight={highlighted === `services${index}`} key={item.id} />
          ))
        )}

        {(finalData.services.length === 3 || finalData.services.length === 5 || finalData.services.length === 6) && (
          finalData.services.map((item, index) => (
            <Block item={item} index={index} highlight={highlighted === `services${index}`} key={item.id} />
          ))
        )}
      </div>
      <div className={classes.header}>
        <div className={textStyles.body}>{finalData.personalDetails.websiteUrl}</div>
        <div className={textStyles.body}>
          {finalData.personalDetails.instagramHandle ? `@${finalData.personalDetails.instagramHandle}` : ''}
        </div>
      </div>
    </div>
  );
};

Page8Preview.propTypes = {
  finalData: PropTypes.shape().isRequired,
  highlighted: PropTypes.string,
};

Page8Preview.defaultProps = {
  highlighted: '',
};

export default Page8Preview;
