import React, { useState } from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SearchPageBase from '../SearchPageBase';
import { useAuth } from '../../../hooks';
import AddProfilesToListButton from '../../list/AddProfilesToListButton';

const useStyles = makeStyles(() => ({
  listHeader: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridTemplateAreas: `
      "left right"
    `,
    padding: '50px 40px 50px 40px',
  },
  listHeaderLeft: {
    gridArea: 'left',
  },
}));

const VipPage = () => {
  const classes = useStyles();
  const auth = useAuth();
  const [selectedProfiles, setSelectedProfiles] = useState([]);

  return (
    <SearchPageBase
      header={(
        <div className={classes.listHeader}>
          <div className={classes.listHeaderLeft}>
            <Typography variant="h4">Scrunch VIPs</Typography>
          </div>
        </div>
      )}
      actions={[
        <AddProfilesToListButton key="addtolist" profiles={selectedProfiles} />,
      ]}
      onSelectedProfilesChange={setSelectedProfiles}
      searchUrlBase={`/account/${auth.account.id}/vip/search/`}
      showEmptyOnLoad={false}
      title="Scrunch VIPs"
    />
  );
};

export default VipPage;
