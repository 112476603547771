/* eslint-disable no-use-before-define */
import React, { createContext, useState } from 'react';

import PropTypes from 'prop-types';

const NotesContext = createContext({
  // profileId: null,
  // notes: null,
  // lastSavedNote: "Add a Note",
  // modifiedNote: null,
  setUpNotes: () => {},
  checkForText: () => {},
});

const NotesStore = (props) => {
  const { children } = props;

  const setUpNotes = ({
    profileId, newNote, lastSavedNote, modifiedNote,
  }) => {
    setState((state) => ({
      ...state,
      profileId,
      newNote,
      lastSavedNote,
      modifiedNote,
    }));
  };

  const checkForText = (json) => {
    let result = false;
    JSON.parse(json).blocks.map((text) => {
      if (text.text) {
        result = true;
      }
      return result;
    });
    return result;
  };

  const [state, setState] = useState({
    setUpNotes,
    checkForText,
  });

  return (
    <NotesContext.Provider value={state}>
      {children}
    </NotesContext.Provider>
  );
};

NotesStore.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotesContext;
export { NotesStore };
