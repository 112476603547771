import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import Tabs from './Tabs';
import MemberTable from './MemberTable';
import AddNewMemberButton from './AddNewMemberButton';
import API from '../../utils/api';
import { useAuth, useContentManager } from '../../hooks';

const useStyles = makeStyles(() => ({
  addMember: {
    width: '90%',
    height: '4rem',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '2rem',
    padding: '1rem 1rem 1rem 0',
    // backgroundColor: theme.colors.system.grey1,
  },
}));

const MembersAndInvites = ({ isPageInSettings, handleIsLoading }) => {
  const [invites, setInvites] = useState([]);
  const [members, setMembers] = useState([]);
  const [isMemberFetching, setMemberIsFetching] = useState(false);
  const [isInviteFetching, setInviteIsFetching] = useState(false);
  const [whichTab, setWhichTab] = useState('members');
  const [maximumMembers, setMaximumMembers] = useState(false);
  const isLoading = isMemberFetching || isInviteFetching;
  const auth = useAuth();
  const classes = useStyles();
  const content = useContentManager();

  const getMembers = useCallback(async () => {
    const response = await API({
      method: 'get',
      url: `/account/${auth.account.id}/users/`,
    });
    if (response.status === 200) {
      setMembers(response.data.data.user);
      setMemberIsFetching(false);
      // TODO WHAT HAPPENS IF NO USER, CHECK AFTER DELETE API
      if (response.data.data.user.length > 0) {
        setWhichTab('members');
      }
    }
  }, [auth.account.id]);

  const getInvites = useCallback(async () => {
    const response = await API({
      method: 'get',
      url: `/account/${auth.account.id}/employee-invitations/`,
    });
    if (response.status === 200) {
      setInvites(response.data.data.invitation);
      setInviteIsFetching(false);
    }
  }, [auth.account.id]);

  useEffect(() => {
    setMemberIsFetching(true);
    setInviteIsFetching(true);
    getMembers();
    getInvites();
  }, [getInvites, getMembers]);

  useEffect(() => {
    setMaximumMembers(invites.length + members.length < 10);
  }, [invites, members]);

  useEffect(() => {
    if (isPageInSettings) {
      handleIsLoading(isLoading);
    }
  }, [isLoading, isPageInSettings, handleIsLoading]);

  return (
    <>
      <div className={classes.addMember}>
        <div>
          <Typography variant="body1" style={{ fontSize: '1.5rem' }}>
            {isPageInSettings ? 'Members & Permissions' : 'Members'}
          </Typography>
          {isPageInSettings && (
            <>
              <Typography variant="body2" component="span">
                <strong>Owner: </strong>
              </Typography>
              <Typography variant="body2" component="span">
                {auth.account.owner}
              </Typography>
            </>
          )}
        </div>
        {maximumMembers
          && !isLoading
          && !auth.hasPermission('manager.restricted') && (
            <AddNewMemberButton
              isInSettings={isPageInSettings}
              refreshInvites={getInvites}
              refreshMembers={getMembers}
              members={members}
            />
        )}
      </div>

      {!isLoading && invites.length < 1 && members.length < 1 && (
        <div className={classes.noMembers}>
          <Typography variant="body2">
            Your agency currently has no members.
          </Typography>
          <Typography variant="body2">
            Start by adding new members now.
          </Typography>
        </div>
      )}

      <Tabs
        tabOneData={members}
        tabTwoData={invites}
        tabOneActive={whichTab === 'members'}
        tabTwoActive={whichTab === 'invites'}
        tabOneTitle="Active Members"
        tabTwoTitle="Invitations"
        changeToTabOne={() => setWhichTab('members')}
        changeToTabTwo={() => setWhichTab('invites')}
        fontSize="1rem"
      />

      {whichTab === 'invites' && invites && invites.length > 0 && (
        <MemberTable
          inviteNameCol
          roleCol
          items={
            isPageInSettings
              ? invites
              : invites.filter(
                (inv) => inv.brand_profiles_access
                    && JSON.parse(inv.brand_profiles_access).includes(
                      content.brand.username,
                    ),
              )
          }
          removeMemberAccess={!isPageInSettings}
          refreshData={getInvites}
          buttonName={isPageInSettings ? 'Edit Invite' : 'Edit Member'}
        />
      )}

      {whichTab === 'members' && (members.length > 0 || invites.length > 0) && (
        <MemberTable
          items={
            isPageInSettings
              ? members
              : members.filter((mem) => (
                mem.agency_role !== 'client'
                    || (mem.brand_profiles_access
                      && JSON.parse(mem.brand_profiles_access).includes(
                        content.brand.username,
                      ))
              ))
          }
          memberNameCol
          roleCol
          buttonName={isPageInSettings ? 'Edit Member' : 'Remove Member Access'}
          removeMemberAccess={!isPageInSettings}
          refreshData={getMembers}
          isMembers
        />
      )}
    </>
  );
};

MembersAndInvites.propTypes = {
  isPageInSettings: PropTypes.bool,
  handleIsLoading: PropTypes.func,
};

MembersAndInvites.defaultProps = {
  isPageInSettings: false,
  handleIsLoading: null,
};

export default MembersAndInvites;
