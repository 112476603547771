import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useContentManager } from '../../hooks';

import '@fullcalendar/daygrid/main.css';
import '../../styles/calender.css';
import StatusBox from './StatusBox';
import { formatDate } from '../../utils/datetime';

const useStyles = makeStyles((theme) => ({
  mainCont: {
    borderRadius: '4px',
    position: 'relative',
  },
  '.fc-dayGridMonth-button .fc-button .fc-button-primary .fc-button-active': {
    backgroundColor: '#F5F0EA !important',
  },
  /* List View */
  listTable: {
    position: 'absolute',
    top: '80px',
    left: '20px',
    width: '105%',
    margin: 'auto',
    borderCollapse: 'collapse',
    borderRadius: '4px',
    '&>tr': {
      padding: '15px 30px',
      borderTop: '0',
      height: '75px',
      border: 'none',
      '&>*': {
        width: '200px',
      },
      '&>td>*': {
        width: '75%',
        margin: 'auto',
      },
      '&>th>*': {
        width: '75%',
        margin: 'auto',
      },
    },
  },
  tableHeader: {
    backgroundColor: theme.colors.system.grey2,
    border: `1px solid ${theme.colors.system.grey3} !important`,
    '&>th>*': {
      textAlign: 'left',
    },
  },
  tableBody: {
    border: `1px solid ${theme.colors.system.grey4} !important`,
    '&>td>*': {
      fontWeight: 'normal',
    },
  },
  tablePost: {
    maxWidth: '175px',
    maxHeight: '85px',
    overflow: 'hidden',
  },
  tableStatus: {
    width: '200px',
  },
  tableActiveDate: {
    color: theme.colors.primary.main,
  },
  tableDate: {

  },
}));

const Calendar = ({ events }) => {
  const classes = useStyles();
  const content = useContentManager();
  const [listOpen, setListOpen] = useState(false);
  const listButton = document.querySelector('.fc-listYear-button');
  const dayButton = document.querySelector('.fc-dayGridDay-button');
  const weekButton = document.querySelector('.fc-dayGridWeek-button');
  const monthButton = document.querySelector('.fc-dayGridMonth-button');

  useEffect(() => {
    if (listButton && dayButton && weekButton && monthButton) {
      listButton.onclick = () => setListOpen(true);
      dayButton.onclick = () => setListOpen(false);
      weekButton.onclick = () => setListOpen(false);
      monthButton.onclick = () => setListOpen(false);
    }
  }, [listButton, dayButton, weekButton, monthButton]);

  const checkDate = (date) => {
    if (formatDate(date).includes('Today')) {
      return [true, formatDate(date).split(' (')[0]];
    }
    return [false, formatDate(date)];
  };

  return (
    <div className={classes.mainCont}>
      <FullCalendar
        plugins={[dayGridPlugin, listPlugin]}
        initialView="dayGridMonth"
        // initialView="listYear"
        headerToolbar={{
          left: 'prev,next',
          center: 'title',
          right: 'dayGridMonth dayGridWeek dayGridDay listYear',
        }}
        events={!content.fetching && events}
      />
      {listOpen && (
        <table className={classes.listTable}>
          <tr className={classes.tableHeader}>
            <th>
              <Typography>Date</Typography>
            </th>
            <th>
              <Typography>Post</Typography>
            </th>
            <th>
              <Typography>Content Type</Typography>
            </th>
            <th>
              <Typography>Influencer Name</Typography>
            </th>
            <th>
              <Typography>Status</Typography>
            </th>
          </tr>
          {content.posts.length > 0
            && content.posts.map((post) => (
              <tr className={classes.tableBody}>
                <td
                  className={
                    checkDate(post.post_datetime)[0]
                      ? classes.tableActiveDate
                      : classes.tableDate
                  }
                >
                  <Typography>{checkDate(post.post_datetime)[1]}</Typography>
                </td>
                <td>
                  <Typography className={classes.tablePost}>
                    {post.text}
                  </Typography>
                </td>
                <td>
                  <Typography>
                    {content.contentTypes[post.content_type].type}
                  </Typography>
                </td>
                <td>
                  <Typography>
                    @
                    {post.inf_name[0] || 'name'}
                  </Typography>
                </td>
                <td className={classes.tableStatus}>
                  <div>
                    <StatusBox
                      status={content.status[post.status].label}
                      textColor={content.status[post.status].fontColor}
                      bgColor={content.status[post.status].bgColor}
                    />
                  </div>
                </td>
              </tr>
            ))}
        </table>
      )}
    </div>
  );
};

Calendar.propTypes = {
  events: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default Calendar;
