import React from 'react';

import PropTypes from 'prop-types';

import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import NetworkIcon from './icons/NetworkIcon';

const networkStyles = (theme) => ({
  root: {
    color: theme.colors.system.light,
    background: '#354065',
    fontWeight: 'normal',
  },
  icon: {
    color: theme.colors.system.light,
    width: 15,
    height: 15,
  },
});

const FilterLabelChip = ({ network, label }) => {
  const classes = makeStyles((theme) => networkStyles(theme))();

  return (
    <Chip
      classes={classes}
      icon={<NetworkIcon network={network} />}
      label={label}
    />
  );
};

FilterLabelChip.propTypes = {
  network: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default FilterLabelChip;
