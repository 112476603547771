import { StyleSheet, Font } from '@react-pdf/renderer';
import CooperLightBT from '../../assets/fonts/cooperLtBt/CooperLightBT.ttf';
import BeatriceRegular from '../../assets/fonts/beatrice/Beatrice-Regular.ttf';
import BeatriceBold from '../../assets/fonts/beatrice/Beatrice-Bold.ttf';

Font.register({
  family: 'CooperLightBT',
  src: CooperLightBT,
});

Font.register({
  family: 'Beatrice',
  src: BeatriceRegular,
});

Font.register({
  family: 'Beatrice-Bold',
  src: BeatriceBold,
});

Font.registerHyphenationCallback((word) => (
  [word]
));

const textClasses = {
  h1: {
    fontFamily: 'CooperLightBT',
    fontSize: 96,
    lineHeight: '115px',
  },
  h2: {
    fontFamily: 'CooperLightBT',
    fontSize: 48,
    lineHeight: '57px',
  },
  h3: {
    fontFamily: 'CooperLightBT',
    fontSize: 36,
    lineHeight: '43px',
  },
  h4: {
    fontFamily: 'CooperLightBT',
    fontSize: 24,
    lineHeight: '28px',
  },
  h5: {
    fontFamily: 'CooperLightBT',
    fontSize: 18,
    lineHeight: '21px',
  },
  body: {
    fontFamily: 'Beatrice',
    fontSize: 12,
    lineHeight: '18px',
  },
  bodyBold: {
    fontFamily: 'Beatrice-Bold',
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: '18px',
  },
  bodyLarge: {
    fontFamily: 'Beatrice',
    fontSize: 14,
    lineHeight: '20px',
  },
};

const textStyles = StyleSheet.create(textClasses);

const colors = {
  BG_LIGHT: '#F5F0EA',
  BG_DARK: '#FAE7DA',
  PRIMARY: '#E75041',
  SECONDARY: '#354065',
};

export {
  textClasses,
  textStyles,
  colors,
};
