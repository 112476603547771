import React from 'react';

import PropTypes from 'prop-types';

import { Typography, Chip } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import NetworkIcon from '../common/icons/NetworkIcon';

import ProfileAudience from './ProfileAudience';
import ProfileContentCard from './ProfileContentCard';
import ProfileHeader from './ProfileHeader';
import { useAuth } from '../../hooks';
import { getCostPerPost } from '../../utils/profile';
import AccessLockTooltip from '../common/AccessLockTooltip';

const useStyles = makeStyles((theme) => ({
  section: {
    padding: '40px 0',
    position: 'relative',
  },
  profileContent: {
    display: 'grid',
    gridGap: 40,
  },
  socialSummary: {
    padding: '20px 0',
    display: 'flex',
    flexWrap: 'wrap',
    borderBottom: `1px solid ${theme.colors.system.grey4}`,
    borderTop: `1px solid ${theme.colors.system.grey4}`,
  },
  summaryCont: {
    width: '50%',
    padding: '20px 0',
    '& h5': {
      paddingBottom: 20,
    },
  },
  summaryContSidebar: {
    width: '100%',
    padding: '40px 0',
    '& h5': {
      paddingBottom: 20,
    },
  },
  topics: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    flexWrap: 'wrap',
    '&>:not(last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  runAnalysisSection: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '7.5rem',
  },
  audienceAnalysis: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 0,
  },
}));

const SectionTitle = withStyles((theme) => ({
  root: {
    ...theme.textStyles.h5,
    marginBottom: 15,
    width: '100%',
    borderTop: `5x solid ${theme.colors.system.grey4}`,
  },
}))(Typography);

const Topics = (props) => {
  const { topics, sidebar } = props;

  const classes = useStyles();

  return (
    <div
      className={sidebar ? classes.summaryContSidebar : classes.summaryCont}
      style={
        !sidebar ? { borderLeft: '1px solid #E2E2E2', paddingLeft: 20 } : null
      }
    >
      <Typography variant="h5">Topics</Typography>

      {topics && (
        <div className={classes.topics}>
          {topics.map((it) => (
            <Chip key={it} size="small" label={it} />
          ))}
        </div>
      )}
    </div>
  );
};

Topics.propTypes = {
  topics: PropTypes.arrayOf(PropTypes.string).isRequired,
  sidebar: PropTypes.bool,
};

Topics.defaultProps = {
  sidebar: false,
};

const Cost = (props) => {
  const { profile, sidebar } = props;
  const classes = useStyles();

  return (
    <div
      className={sidebar ? classes.summaryContSidebar : classes.summaryCont}
      style={sidebar ? { borderBottom: '1px solid #E2E2E2' } : null}
    >
      <Typography variant="h5">Estimated cost per post</Typography>
      <div className={classes.topics}>
        {profile.data.network_profiles.map((it) => {
          const costPerPost = getCostPerPost(it.network, it.stats.social_reach);
          return (
            <Chip
              key={it.network}
              style={{
                marginBottom: 10,
                backgroundColor: '#35406520',
                color: '#354065',
                padding: 15,
              }}
              icon={(
                <NetworkIcon
                  style={{ fontSize: 11, color: '#354065' }}
                  network={it.network}
                />
              )}
              label={`${costPerPost} on ${it.network}`}
              size="small"
            />
          );
        })}
      </div>
    </div>
  );
};

Cost.propTypes = {
  profile: PropTypes.shape().isRequired,
  sidebar: PropTypes.bool,
};

Cost.defaultProps = {
  sidebar: false,
};

const ProfileLayout = ({ profile, sidebar }) => {
  const classes = useStyles();
  const auth = useAuth();
  const refreshed = new Date(profile.data.refreshed);
  const datetime = new Date();
  const daysSinceRefreshed = datetime.getDate() - refreshed.getDate();

  const gridTemplateColumns = sidebar ? '1fr 1fr' : '1fr 1fr 1fr 1fr';

  if (!profile) {
    return null;
  }

  return (
    <div>
      <div className={classes.section} style={{ paddingBottom: 0 }}>
        <ProfileHeader profile={profile} sidebar={sidebar} />
      </div>
      <div className={classes.section}>
        <div
          className={
            daysSinceRefreshed >= 14
              ? classes.runAnalysisSection
              : classes.audienceAnalysis
          }
        >
          <SectionTitle>Audience analysis</SectionTitle>
          {!auth.hasPermission('profile.view_audience') && (
            <div style={{ marginBottom: 15 }}>
              <AccessLockTooltip title="Audience analysis">
                Upgrade to our&nbsp;
                <Typography variant="body2" component="span" color="primary">
                  Power Plan or above
                </Typography>
                &nbsp;to unlock audience analysis
              </AccessLockTooltip>
            </div>
          )}
        </div>
        <ProfileAudience
          profile={profile}
          sidebar={sidebar}
          sinceRefreshed={daysSinceRefreshed}
        />
      </div>
      <div className={classes.socialSummary}>
        <Cost profile={profile} sidebar={sidebar} />
        <Topics topics={profile.topics()} sidebar={sidebar} />
      </div>
      <div className={classes.section}>
        <SectionTitle>Influencer content</SectionTitle>
        <div className={classes.profileContent} style={{ gridTemplateColumns }}>
          {profile
            .content()
            .slice(0, 4)
            .map((content) => (
              <ProfileContentCard
                key={content.id}
                content={content}
                sidebar={sidebar}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

ProfileLayout.propTypes = {
  profile: PropTypes.shape().isRequired,
  sidebar: PropTypes.bool,
};

ProfileLayout.defaultProps = {
  sidebar: false,
};

export default ProfileLayout;
