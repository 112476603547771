import React from 'react';

import { SvgIcon } from '@material-ui/core';

const Avatar = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <path
      style={{ strokeWidth: 2 }}
      id="path863"
      d="M 12,0 C 9.05448,0 6.66666,2.38782 6.66666,5.33334 c 0,2.9455 2.38782,5.33332 5.33334,5.33332 2.94552,0 5.33332,-2.38782 5.33332,-5.33332 C 17.33332,2.38782 14.94552,0 12,0 Z M 8.20832,15.1328 C 8.09906,15.12754 7.98726,15.13518 7.8776,15.15884 4.08426,15.97618 0,17.81466 0,20.6666 v 2 C 0,23.4026 0.597334,24 1.333334,24 H 22.6666 C 23.4026,24 24,23.4026 24,22.6666 v -2 c 0,-2.85194 -4.0856,-4.69042 -7.8776,-5.50776 -0.44,-0.09466 -0.89362,0.04986 -1.21094,0.3672 l -1.96876,1.96874 c -0.52132,0.52134 -1.3654,0.52134 -1.8854,0 L 9.08854,15.52604 c -0.238,-0.238 -0.5524,-0.37742 -0.8802,-0.39324 z"
    />
  </SvgIcon>
);

export default Avatar;
