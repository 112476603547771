import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
// import PropTypes from "prop-types";

import { Link as RouterLink } from '@reach/router';

import { Typography, Link } from '@material-ui/core';
import PlainPageBase from '../PlainPageBase';
import Calendar from '../../content-approval/Calendar';
import CampaignsList from '../../content-approval/CampaignsList';
import Tabs from '../../common/Tabs';
import MembersAndInvites from '../../common/MembersAndInvites';
import NewCampaignDialog from '../../content-approval/NewCampaignDialog';
import { useAuth, useContentManager } from '../../../hooks';
import BackButton from '../../common/BackButton';
import NoMatch from '../../router/NoMatch';

const useStyles = makeStyles(() => ({
  campcalendar: {
    display: 'grid',
    gridTemplateColumns: '500px 900px',
    gridGap: '20px',
  },
  title: {
    margin: '4rem 0',
    fontSize: '20px',
  },
}));

const BrandDashboardPage = (props) => {
  const { brandId } = props;
  const classes = useStyles();
  const auth = useAuth();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [whichTab, setWhichTab] = useState('campaigns');
  const [initialFetch, setInitialFetch] = useState(false);
  const content = useContentManager();
  const brand = content.brand || { campaigns: [] };
  const posts = content.posts || [];
  const isClient = auth.user.agency_role === 'client';
  const breadcrumbs = useMemo(
    () => [
      <Link key="home" component={RouterLink} to="/content-approval/">
        Dashboard
      </Link>,
      <Typography key="brand" variant="body1">
        {content.fetching ? 'Loading' : brand && brand.username}
      </Typography>,
    ],
    [brand, content.fetching],
  );

  const calendarEvents = posts
    .filter((post) => post.post_datetime && post.text)
    .map((post) => ({
      title: `${post.text.slice(0, 12)}...`,
      start: post.post_datetime,
      end: post.post_datetime,
      borderColor: post.color,
    }));

  useEffect(() => {
    if (!initialFetch) {
      setInitialFetch(true);
      if (content.isNewCampaign
        || content.isNewBrandPost
        || content.brand.id !== Number(brandId)) {
        content.getBrandCampaignsPosts(auth.account.id, brandId);
      }
    }
  }, [content, auth, initialFetch, brandId]);

  const handleClose = () => {
    setIsDialogOpen(false);
    content.getBrandCampaignsPosts(auth.account.id, brandId);
  };

  if (initialFetch && !content.fetching && !content.brand) {
    return (
      <NoMatch />
    );
  }

  return (
    <>
      <PlainPageBase
        title={content.fetching ? 'Loading' : brand && brand.username}
        titleImg={brand && brand.username}
        breadcrumbs={breadcrumbs}
        backButton={<BackButton url="/content-approval/" />}
        // showLoadingBar={content.fetching}
      >
        <Tabs
          noDataNeeded
          tabOneActive={isClient || whichTab === 'campaigns'}
          tabTwoActive={whichTab === 'members'}
          tabOneTitle="Campaigns"
          tabTwoTitle={isClient ? null : 'Members'}
          changeToTabOne={() => setWhichTab('campaigns')}
          changeToTabTwo={isClient ? null : () => setWhichTab('members')}
          fontSize="1rem"
        />

        {whichTab === 'campaigns' ? (
          <div className={classes.campcalendar}>
            <>
              <NewCampaignDialog
                isOpen={isDialogOpen}
                onClose={handleClose}
                brand={brand}
              />
              <CampaignsList
                // campaigns={brand && brand.campaigns}
                setIsDialogOpen={() => setIsDialogOpen(true)}
                brand={brand}
              />
              <Calendar events={calendarEvents} />
            </>
          </div>
        ) : (
          <>
            {content.brand ? (
              <MembersAndInvites />
            ) : (
              <Typography>
                Loading...
              </Typography>
            )}
          </>
        )}
      </PlainPageBase>
    </>
  );
};

BrandDashboardPage.propTypes = {
  brandId: PropTypes.string,
};

BrandDashboardPage.defaultProps = {
  brandId: '-',
};

export default BrandDashboardPage;
