import React from 'react';

import { Avatar, Typography, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import CampaignPost from './CampaignPost';
import ScrunchLogo from '../../assets/images/scrunch-logo-full.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '30px 0',
  },
  profileHeader: {
    width: '100%',
    display: 'flex',
  },
  influencerImg: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  profilePicture: {
    width: 110,
    height: 110,
    borderRadius: '50%',
  },
  scrunchLogo: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    '& img': {
      width: 70,
      marginTop: theme.spacing(1),
    },
  },
  influencerDetails: {
    flex: 1,
    padding: '0 20px',
    display: 'flex',
    flexDirection: 'column',
  },
  influencerName: {
    ...theme.textStyles.bodyLarge,
    marginBottom: theme.spacing(1),
  },
  infoChips: {
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(1),
    '&>:not(last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  postHeader: {
    display: 'inline-flex',
    padding: '20px 0',
  },
  campaignPosts: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  contentDivider: {
    border: 'none',
    borderBottom: `1px solid ${theme.colors.system.grey5}`,
  },
}));

const InfluencerDetails = ({ influencerData, first }) => {
  const classes = useStyles();

  const numberOfPosts = influencerData && influencerData.report_media ? influencerData.report_media.length : 0;

  if (!(influencerData)) {
    return null;
  }

  return (
    <>
      <div className={classes.root}>
        <div className={`${classes.profileHeader} ${!first ? 'pageBreak' : ''}`}>
          <div className={classes.influencerImg}>
            <Avatar
              className={classes.profilePicture}
              alt={influencerData.full_name.toString().substring(0, 1)}
              src={influencerData.profile_pic_url}
            />
          </div>

          <div className={classes.influencerDetails}>
            <Typography variant="h5" className={classes.influencerName}>{influencerData.full_name}</Typography>
            {(influencerData.gender || influencerData.location || influencerData.age) && (
              <div className={classes.infoChips}>
                {influencerData.gender && <Chip size="small" label={influencerData.gender} />}
                {influencerData.location && <Chip size="small" label={influencerData.location} />}
                {influencerData.age && <Chip size="small" label={influencerData.age} />}
              </div>
            )}
            <Typography style={{ color: '#2B2E34', marginBottom: 8 }} variant="body2" component="p">
              @
              {influencerData.username}
            </Typography>
          </div>

          <div className={classes.scrunchLogo}>
            <img src={ScrunchLogo} alt="Scrunch." />
          </div>
        </div>

        <div className={classes.postHeader}>
          <Typography variant="h5" className={classes.influencerName} color="primary">
            {numberOfPosts}
            &nbsp;
          </Typography>
          <Typography variant="h5" className={classes.influencerName}>
            {numberOfPosts && numberOfPosts === 1 ? 'Post' : 'Posts'}
            &nbsp;
            In Campaign
          </Typography>
        </div>

        {numberOfPosts > 0 && (
          <div className={classes.campaignPosts}>
            {influencerData.report_media.map((media, index) => (
              <CampaignPost key={media.code} influencerData={influencerData} index={index} />
            ))}
          </div>
        )}
      </div>
      <hr className={classes.contentDivider} />
    </>
  );
};

InfluencerDetails.propTypes = {
  influencerData: PropTypes.shape().isRequired,
  first: PropTypes.bool,
};

InfluencerDetails.defaultProps = {
  first: false,
};

export default InfluencerDetails;
