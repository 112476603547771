import React from 'react';

import PropTypes from 'prop-types';

import {
  Box,
  Input,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import UpdateCreditCardWidget from './UpdateCreditCardWidget';

const useStyles = makeStyles((theme) => ({
  box: {
    border: `1px solid ${theme.colors.system.grey4}`,
    backgroundColor: theme.colors.system.grey1,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 15,
  },
  label: {
    lineHeight: '18px',
    marginBottom: 8,
    ...theme.fonts.medium,
  },
  input: {
    border: 'none',
    width: '100%',
  },
}));

const CreditCardField = (props) => {
  const { customer, onSuccess } = props;
  const classes = useStyles();

  let last4 = null;
  if (customer && customer.invoice_settings && customer.invoice_settings.default_payment_method
    && customer.invoice_settings.default_payment_method.card) {
    last4 = customer.invoice_settings.default_payment_method.card.last4;
  } else if (customer && customer.default_source && customer.default_source.last4) {
    last4 = customer.default_source && customer.default_source.last4;
  }

  return (
    <div>
      <Typography variant="body2" className={classes.label}>Payment method</Typography>
      <Box className={classes.box}>
        <Input
          className={classes.input}
          readOnly
          value={last4 ? `**** **** **** ${last4}` : ''}
          endAdornment={(
            <InputAdornment position="end" color="primary">
              <UpdateCreditCardWidget
                ButtonProps={{
                  color: 'primary',
                  variant: 'text',
                  size: 'small',
                }}
                ButtonText={last4 ? 'Update credit card' : 'Add credit card'}
                customer={customer}
                onSuccess={onSuccess}
                hasCardOnRecord={Boolean(last4)}
              />
            </InputAdornment>
          )}
        />
      </Box>
    </div>
  );
};

CreditCardField.propTypes = {
  customer: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default CreditCardField;
