import React from 'react';

import { SvgIcon } from '@material-ui/core';

const TikTok = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <path
      style={{ strokeWidth: 0.5714286 }}
      id="path4566"
      d="M 21.142857,0 H 2.857143 C 1.281714,0 0,1.281714 0,2.857143 V 21.142857 C 0,22.718286 1.281714,24 2.857143,24 H 21.142857 C 22.718286,24 24,22.718286 24,21.142857 V 2.857143 C 24,1.281714 22.718286,0 21.142857,0 Z m -2.282286,10.470286 c -0.129714,0.012 -0.261142,0.02 -0.394285,0.02 -1.498857,0 -2.816,-0.770857 -3.582286,-1.936 0,3.056571 0,6.534285 0,6.592571 0,2.690857 -2.181714,4.872572 -4.872571,4.872572 -2.690858,0 -4.872572,-2.181715 -4.872572,-4.872572 0,-2.690857 2.181714,-4.872571 4.872572,-4.872571 0.101714,0 0.201142,0.0091 0.301142,0.01543 v 2.401143 c -0.1,-0.012 -0.198285,-0.03029 -0.301142,-0.03029 -1.373715,0 -2.486858,1.113143 -2.486858,2.486858 0,1.373714 1.113143,2.486857 2.486858,2.486857 1.373714,0 2.586857,-1.082286 2.586857,-2.456 0,-0.05429 0.024,-11.196572 0.024,-11.196572 h 2.294857 c 0.216,2.052 1.872571,3.671429 3.943428,3.82 z"
    />
  </SvgIcon>
);

export default TikTok;
