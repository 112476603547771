import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { colors } from '../definitions';
import { previewStyles } from '../form/styles';

const useStyles = makeStyles((theme) => ({
  highlighted: {
    border: `2px solid ${colors.PRIMARY}`,
  },
  root: {
    backgroundColor: colors.BG_LIGHT,
    display: 'flex',
    height: 250,
    marginTop: 20,
    '@media (max-width: 1280px)': {
      height: 220,
    },
  },
  col1: {
    flex: 1,
    backgroundColor: colors.BG_DARK,
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
  },
  col2: {
    flex: 1.6,
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
  },
  main: {
    flex: 1,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 15,
    '& > div': {
      height: 150,
    },
    '@media (max-width: 1280px)': {
      '& > div': {
        height: 120,
      },
    },
  },
  imageWrapper: {
    width: 150,
    height: 150,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 0,
    '@media (max-width: 1280px)': {
      width: 120,
      height: 120,
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  flexRow: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  blockRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '50%',
  },
  extraLarge: {
    ...theme.fonts.tertiary,
    fontSize: 40,
  },
  large: {
    ...theme.fonts.tertiary,
    fontSize: 34,
  },
  medium: {
    ...theme.fonts.tertiary,
    fontSize: 25,
  },
  regular: {
    ...theme.fonts.tertiary,
    fontSize: 20,
  },
  networkName: {
    textTransform: 'capitalize',
    marginBottom: 3,
    marginTop: 3,
  },
  padding: {
    padding: 5,
    '@media (max-width: 1280px)': {
      padding: 2,
    },
  },
}));

const SocialData = ({
  size,
  followers,
  network,
  handle,
  highlighted,
  index,
}) => {
  const classes = useStyles();
  const textStyles = previewStyles();

  let networkText = '';
  if (network) {
    if (network.toLowerCase() === 'website') {
      networkText = 'Views / Month';
    } else {
      networkText = 'Followers';
    }
  }

  return (
    <div
      className={`${classes.blockRoot} ${highlighted === `socialStatistics.socialData${index}` ? classes.highlighted : ''}`}
    >
      <div className={classes[size]}>{followers}</div>
      <div className={`${classes.networkName} ${textStyles.h5}`}>
        {network}
        &nbsp;
        {networkText}
      </div>
      <div className={textStyles.body}>{handle ? `@${handle}` : ''}</div>
    </div>
  );
};

SocialData.propTypes = {
  size: PropTypes.string.isRequired,
  followers: PropTypes.string,
  network: PropTypes.string,
  handle: PropTypes.string,
  highlighted: PropTypes.string,
  index: PropTypes.number.isRequired,
};

SocialData.defaultProps = {
  followers: '',
  network: '',
  handle: '',
  highlighted: '',
};

const Page4Preview = ({ highlighted, finalData }) => {
  const classes = useStyles();
  const textStyles = previewStyles();

  const networkData = finalData.socialStatistics.socialData;

  // Determine size of the text
  let size = 'regular';
  if (networkData === 1) size = 'extraLarge';
  else if (networkData === 2) size = 'large';
  else if (networkData === 3 || networkData === 4) size = 'medium';
  else size = 'regular';

  // Determine flex properties
  let flexStyle = 'flexCol';
  if (networkData.length >= 4) flexStyle = 'flexRow';

  // Determine justify value
  let justify = 'center';
  if (networkData.length === 1) justify = 'center';
  else if (networkData.length === 2) justify = 'space-evenly';
  else if (networkData.length === 3) justify = 'space-between';
  else if (networkData.length === 4) justify = 'space-evenly';
  else if (networkData.length === 5) justify = 'space-evenly';
  else if (networkData.length === 6) justify = 'space-evenly';

  // Determine align value
  let align = 'center';
  if (networkData.length === 4) align = 'space-evenly';
  else if (networkData.length === 5) align = 'space-evenly';

  const pData = finalData.personalDetails;
  const instaHandle = pData.instagramHandle;

  return (
    <div className={classes.root}>
      <div className={classes.col1}>
        <div className={textStyles.h5}>Social Statistics</div>
        <div className={classes.main}>
          <div className={`${classes.imageWrapper} ${highlighted === 'socialStatistics.socialStatsImg' ? classes.highlighted : ''}`}>
            <img
              src={
                finalData.socialStatistics.socialStatsImg
              }
              className={classes.image}
              alt="Background for Page 4"
            />
          </div>
        </div>
        <div className={textStyles.body}>
          {pData.websiteUrl}
        </div>
      </div>
      <div className={classes.col2}>
        <div className={`${textStyles.body} ${classes.padding}`} style={{ textAlign: 'right' }}>
          {pData.firstName}
          &nbsp;
          {pData.lastName}
        </div>
        <div className={classes.main}>
          <div className={classes[flexStyle]} style={{ alignItems: align, justifyContent: justify }}>
            {networkData.map((data, index) => (
              <SocialData
                size={size}
                followers={data.followerCount}
                network={data.socialWebsite}
                handle={data.socialHandle}
                highlighted={highlighted}
                index={index}
                key={data.id}
              />
            ))}
          </div>
        </div>
        <div className={`${textStyles.body} ${classes.padding}`} style={{ textAlign: 'right' }}>
          {instaHandle ? `@${instaHandle}` : ''}
        </div>
      </div>
    </div>
  );
};

Page4Preview.propTypes = {
  finalData: PropTypes.shape().isRequired,
  highlighted: PropTypes.string,
};

Page4Preview.defaultProps = {
  highlighted: '',
};

export default Page4Preview;
