import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  TextField,
  DialogActions,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';

import { useNotification, useAuth } from '../../hooks';
import API from '../../utils/api';

const useStyles = makeStyles(() => ({
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
}));

const EditEmailButton = ({ email, setIsFetching, refreshResults }) => {
  const classes = useStyles();
  const auth = useAuth();
  const { createNotification } = useNotification();

  // state
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [modifiedEmail, setModifiedEmail] = useState(email);

  // functions
  const closeDialog = () => {
    setIsDialogOpen(false);
    setModifiedEmail('');
  };

  const saveEmail = async () => {
    if (modifiedEmail && modifiedEmail !== email) {
      try {
        if (setIsFetching) {
          setIsFetching(true);
        }

        const response = await API({
          method: 'post',
          url: `/account/${auth.account.id}/admin/change-email/`,
          data: {
            current_email: email,
            new_email: modifiedEmail,
          },
        });

        if (setIsFetching) {
          setIsFetching(false);
        }
        setIsDialogOpen(false);

        if (response.status !== 200) {
          createNotification('error', 'Could not edit email. Please try again later.');
        } else {
          createNotification('success', 'Email updated successfully!');
          if (refreshResults) {
            refreshResults();
          }
        }
      } catch (_) {
        setIsFetching(false);
        setIsDialogOpen(false);
        createNotification('error', 'An unexpected error occured while updating email');
      }
    }
  };

  return (
    <>
      <Button
        color="secondary"
        startIcon={<EditIcon />}
        size="small"
        onClick={() => setIsDialogOpen(true)}
      >
        Edit User
      </Button>

      <Dialog
        open={isDialogOpen}
        onBackdropClick={closeDialog}
        PaperProps={{ style: { width: '50%' } }}
      >
        <IconButton aria-label="close" className={classes.closeButton} onClick={closeDialog}>
          <CloseIcon />
        </IconButton>

        <DialogTitle>
          <Typography variant="h5" component="h5">Edit User</Typography>
        </DialogTitle>

        <DialogContent>
          <TextField
            label="Email Address"
            value={modifiedEmail}
            onChange={(e) => setModifiedEmail(e.target.value)}
            fullWidth
            required
            inputProps={{
              required: true,
            }}
          />
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => saveEmail()}
            disabled={!modifiedEmail}
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

EditEmailButton.propTypes = {
  email: PropTypes.string.isRequired,
  setIsFetching: PropTypes.func,
  refreshResults: PropTypes.func,
};

EditEmailButton.defaultProps = {
  setIsFetching: null,
  refreshResults: null,
};

export default EditEmailButton;
