import MainImage from '../../assets/images/media-kit/page-1-dummy.jpg';
import AboutImage from '../../assets/images/media-kit/page-2-dummy.jpg';
import ContactImage from '../../assets/images/media-kit/page-3-dummy.jpg';
import SocialStatsImage from '../../assets/images/media-kit/page-4-dummy.jpg';
import InstagramAnalyticsImage from '../../assets/images/media-kit/page-5-dummy.jpg';
import Blog1Image from '../../assets/images/media-kit/blog-1-dummy.jpg';
import Blog2Image from '../../assets/images/media-kit/blog-2-dummy.jpg';
import Blog3Image from '../../assets/images/media-kit/blog-3-dummy.jpg';

const defaultFormData = Object.freeze({
  personalDetails: {
    mainImg: MainImage,
    firstName: 'Rhea',
    lastName: 'Cooke',
    professionalTitle: 'Food Writer, Blogger & Mom',
    aboutImg: AboutImage,
    about: 'Hi, I’m Rhea, a British food writer of Polish descent. Following a career break from architecture, I began writing a blog at rheacooke.com and completed a Diploma in Food Journalism. I am now a mum of one and for the past 10 years, I have been writing about food for online and print publications. I have recently returned to law, but I hope that some of the recipes here will continue to be of relevance to you.\nMy cookbook Wild Flowers and Rhea, Top Polish Recipes, was published by Ullasis Books (UK) and by Galaxy Books (USA) in 2017.\nWriting books gave me the wonderful opportunity of drawing upon both my Polish heritage and my trips to Poland. I hope you’ll love coming on this journey with me too.',
    contactImg: ContactImage,
    instagramHandle: 'rheacooke',
    websiteUrl: 'www.rheacooke.com',
    email: 'rhea@rheacooke.com',
    mobile: '+1 999 191 911',
    shippingAddress: 'London UK P/O Box - South Street, 901',
    availableFor: 'Food Stylist\nRecepie Consultations\nSpeaking Events\nAmbassadorship\nSocial Media Content & Collaborations',
  },
  socialStatistics: {
    socialStatsImg: SocialStatsImage,
    socialData: [
      {
        id: 0, socialWebsite: 'Instagram', followerCount: '275K', socialHandle: 'rheacooke',
      },
      {
        id: 1, socialWebsite: 'Twitter', followerCount: '15K', socialHandle: 'rheacooke',
      },
      {
        id: 2, socialWebsite: 'Facebook', followerCount: '28K', socialHandle: 'rheacooke',
      },
      {
        id: 3, socialWebsite: 'Linkedin', followerCount: '1.2m', socialHandle: 'rheacooke',
      },
      {
        id: 4, socialWebsite: 'Website', followerCount: '6m', socialHandle: 'rheacooke',
      },
      {
        id: 5, socialWebsite: 'Treemail', followerCount: '20k', socialHandle: 'rheacooke',
      },
    ],
  },
  instagramAnalytics: {
    analyticsImg: InstagramAnalyticsImage,
    handle: 'rheacooke',
    followerCount: '275K',
    avgPostEngagement: '13',
    audienceGender: {
      gender: 'Female',
      audienceGenderPercentage: '78',
    },
    audienceAge: '25-35',
    audienceLocations: [
      { id: 0, location: 'USA', locationPercentage: '39.5' },
      { id: 1, location: 'UK', locationPercentage: '25.6' },
      { id: 2, location: 'Canada', locationPercentage: '16.2' },
    ],
    collaborations: [
      {
        id: 0, collabImg: Blog1Image, brandName: 'Premier Foods', collabDesc: 'Collaboration project to promote Premier’ new line of hand-rosted coffee  and sweet confectionaries',
      },
      {
        id: 1, collabImg: Blog2Image, brandName: 'American Heritage', collabDesc: 'Instagram campaign to create a new recepie using American Heritage Chocolates',
      },
      {
        id: 2, collabImg: Blog3Image, brandName: 'Branston', collabDesc: 'An Instagram campaign to promote Branston’s new collection of stoneware cookware',
      },
    ],
  },
  brandsAndTestimonials: {
    brands: [
      { id: 0, brand: 'Cadbury' },
      { id: 1, brand: 'Diageo' },
      { id: 2, brand: 'Premier Foods' },
      { id: 3, brand: 'Branston' },
      { id: 4, brand: 'Cathedral City Cheddar' },
      { id: 5, brand: 'Marmite' },
      { id: 6, brand: 'Weetabix' },
      { id: 7, brand: 'Penguin Biscuits' },
    ],
    testimonial: {
      recommendation: 'What your food business needs is more Rhea, and less B.S. She’s a pioneer for making the best recepies out of any food material given to her.',
      recommenderName: 'Charles James',
      brandName: 'Threads Brisket',
      recommenderTitle: 'CEO',
    },
  },
  services: [
    {
      id: 0, title: 'Instagram Sponsored Posts', rate: '$200-$500', serviceDesc: '- High quality, product images for your brand\n- Multiple posts over a period of time\n- Post promotion to email subscribers\n',
    },
    {
      id: 1, title: 'Brand Takeover', rate: '$500-$1000', serviceDesc: '- Managing the social space of the brand\n- Posting high quality content\n- Actively interacting with the brand users\n- Going live on Instagram from time to time',
    },
    {
      id: 2, title: 'Youtube Sponsored Video', rate: '$1.2k-$5k', serviceDesc: '- Week long promotion in between daily videos\n- Multiple posts over a period of time\n- 1 Review video, with brand’s product',
    },
    {
      id: 3, title: 'Guest for Events', rate: '$800k-$1.5k', serviceDesc: '- Appearance for Up to 3 hours\n- Photo opportunity to the visitors\n- Event related Blogs and posts',
    },
    {
      id: 4, title: 'Website Advertising', rate: '$400-$600', serviceDesc: '- One week/ One month banner advertisement\n- Blog article review for brand product',
    },
    {
      id: 5, title: 'Other Services', rate: '$100 - $5k', serviceDesc: '- Custom requirements could be discussed ove email',
    },
  ],
});

const initFinalData = Object.seal({
  personalDetails: {
    mainImg: MainImage,
    firstName: 'Rhea',
    lastName: 'Cooke',
    professionalTitle: 'Food Writer, Blogger & Mom',
    aboutImg: AboutImage,
    about: 'Hi, I’m Rhea, a British food writer of Polish descent. Following a career break from architecture, I began writing a blog at rheacooke.com and completed a Diploma in Food Journalism. I am now a mum of one and for the past 10 years, I have been writing about food for online and print publications. I have recently returned to law, but I hope that some of the recipes here will continue to be of relevance to you.\nMy cookbook Wild Flowers and Rhea, Top Polish Recipes, was published by Ullasis Books (UK) and by Galaxy Books (USA) in 2017.\nWriting books gave me the wonderful opportunity of drawing upon both my Polish heritage and my trips to Poland. I hope you’ll love coming on this journey with me too.',
    contactImg: ContactImage,
    instagramHandle: 'rheacooke',
    websiteUrl: 'www.rheacooke.com',
    email: 'rhea@rheacooke.com',
    mobile: '+1 999 191 911',
    shippingAddress: 'London UK P/O Box - South Street, 901',
    availableFor: 'Food Stylist\nRecepie Consultations\nSpeaking Events\nAmbassadorship\nSocial Media Content & Collaborations',
  },
  socialStatistics: {
    socialStatsImg: SocialStatsImage,
    socialData: [
      {
        id: 0, socialWebsite: 'Instagram', followerCount: '275K', socialHandle: 'rheacooke',
      },
      {
        id: 1, socialWebsite: 'Twitter', followerCount: '15K', socialHandle: 'rheacooke',
      },
      {
        id: 2, socialWebsite: 'Facebook', followerCount: '28K', socialHandle: 'rheacooke',
      },
      {
        id: 3, socialWebsite: 'Linkedin', followerCount: '1.2m', socialHandle: 'rheacooke',
      },
      {
        id: 4, socialWebsite: 'Website', followerCount: '6m', socialHandle: 'rheacooke',
      },
      {
        id: 5, socialWebsite: 'Treemail', followerCount: '20k', socialHandle: 'rheacooke',
      },
    ],
  },
  instagramAnalytics: {
    analyticsImg: InstagramAnalyticsImage,
    handle: 'rheacooke',
    followerCount: '275K',
    avgPostEngagement: '13',
    audienceGender: {
      gender: 'Female',
      audienceGenderPercentage: '78',
    },
    audienceAge: '25-35',
    audienceLocations: [
      { id: 0, location: 'USA', locationPercentage: '39.5' },
      { id: 1, location: 'UK', locationPercentage: '25.6' },
      { id: 2, location: 'Canada', locationPercentage: '16.2' },
    ],
    collaborations: [
      {
        id: 0, collabImg: Blog1Image, brandName: 'Premier Foods', collabDesc: 'Collaboration project to promote Premier’ new line of hand-rosted coffee  and sweet confectionaries',
      },
      {
        id: 1, collabImg: Blog2Image, brandName: 'American Heritage', collabDesc: 'Instagram campaign to create a new recepie using American Heritage Chocolates',
      },
      {
        id: 2, collabImg: Blog3Image, brandName: 'Branston', collabDesc: 'An Instagram campaign to promote Branston’s new collection of stoneware cookware',
      },
    ],
  },
  brandsAndTestimonials: {
    brands: [
      { id: 0, brand: 'Cadbury' },
      { id: 1, brand: 'Diageo' },
      { id: 2, brand: 'Premier Foods' },
      { id: 3, brand: 'Branston' },
      { id: 4, brand: 'Cathedral City Cheddar' },
      { id: 5, brand: 'Marmite' },
      { id: 6, brand: 'Weetabix' },
      { id: 7, brand: 'Penguin Biscuits' },
    ],
    testimonial: {
      recommendation: 'What your food business needs is more Rhea, and less B.S. She’s a pioneer for making the best recepies out of any food material given to her.',
      recommenderName: 'Charles James',
      brandName: 'Threads Brisket',
      recommenderTitle: 'CEO',
    },
  },
  services: [
    {
      id: 0, title: 'Instagram Sponsored Posts', rate: '$200-$500', serviceDesc: '- High quality, product images for your brand\n- Multiple posts over a period of time\n- Post promotion to email subscribers\n',
    },
    {
      id: 1, title: 'Brand Takeover', rate: '$500-$1000', serviceDesc: '- Managing the social space of the brand\n- Posting high quality content\n- Actively interacting with the brand users\n- Going live on Instagram from time to time',
    },
    {
      id: 2, title: 'Youtube Sponsored Video', rate: '$1.2k-$5k', serviceDesc: '- Week long promotion in between daily videos\n- Multiple posts over a period of time\n- 1 Review video, with brand’s product',
    },
    {
      id: 3, title: 'Guest for Events', rate: '$800k-$1.5k', serviceDesc: '- Appearance for Up to 3 hours\n- Photo opportunity to the visitors\n- Event related Blogs and posts',
    },
    {
      id: 4, title: 'Website Advertising', rate: '$400-$600', serviceDesc: '- One week/ One month banner advertisement\n- Blog article review for brand product',
    },
    {
      id: 5, title: 'Other Services', rate: '$100 - $5k', serviceDesc: '- Custom requirements could be discussed ove email',
    },
  ],
});

const resetFinalData = Object.seal({
  personalDetails: {
    mainImg: MainImage,
    firstName: 'Rhea',
    lastName: 'Cooke',
    professionalTitle: 'Food Writer, Blogger & Mom',
    aboutImg: AboutImage,
    about: 'Hi, I’m Rhea, a British food writer of Polish descent. Following a career break from architecture, I began writing a blog at rheacooke.com and completed a Diploma in Food Journalism. I am now a mum of one and for the past 10 years, I have been writing about food for online and print publications. I have recently returned to law, but I hope that some of the recipes here will continue to be of relevance to you.\nMy cookbook Wild Flowers and Rhea, Top Polish Recipes, was published by Ullasis Books (UK) and by Galaxy Books (USA) in 2017.\nWriting books gave me the wonderful opportunity of drawing upon both my Polish heritage and my trips to Poland. I hope you’ll love coming on this journey with me too.',
    contactImg: ContactImage,
    instagramHandle: 'rheacooke',
    websiteUrl: 'www.rheacooke.com',
    email: 'rhea@rheacooke.com',
    mobile: '+1 999 191 911',
    shippingAddress: 'London UK P/O Box - South Street, 901',
    availableFor: 'Food Stylist\nRecepie Consultations\nSpeaking Events\nAmbassadorship\nSocial Media Content & Collaborations\nProduct Reviews',
  },
  socialStatistics: {
    socialStatsImg: SocialStatsImage,
    socialData: [
      {
        id: 0, socialWebsite: 'Instagram', followerCount: '275K', socialHandle: 'rheacooke',
      },
      {
        id: 1, socialWebsite: 'Twitter', followerCount: '15K', socialHandle: 'rheacooke',
      },
      {
        id: 2, socialWebsite: 'Facebook', followerCount: '28K', socialHandle: 'rheacooke',
      },
      {
        id: 3, socialWebsite: 'Linkedin', followerCount: '1.2m', socialHandle: 'rheacooke',
      },
      {
        id: 4, socialWebsite: 'Website', followerCount: '6m', socialHandle: 'rheacooke',
      },
      {
        id: 5, socialWebsite: 'Treemail', followerCount: '20k', socialHandle: 'rheacooke',
      },
    ],
  },
  instagramAnalytics: {
    analyticsImg: InstagramAnalyticsImage,
    handle: 'rheacooke',
    followerCount: '275K',
    avgPostEngagement: '13',
    audienceGender: {
      gender: 'Female',
      audienceGenderPercentage: '78',
    },
    audienceAge: '25-35',
    audienceLocations: [
      { id: 0, location: 'USA', locationPercentage: '39.5' },
      { id: 1, location: 'UK', locationPercentage: '25.6' },
      { id: 2, location: 'Canada', locationPercentage: '16.2' },
    ],
    collaborations: [
      {
        id: 0, collabImg: Blog1Image, brandName: 'Premier Foods', collabDesc: 'Collaboration project to promote Premier’ new line of hand-rosted coffee  and sweet confectionaries',
      },
      {
        id: 1, collabImg: Blog2Image, brandName: 'American Heritage', collabDesc: 'Instagram campaign to create a new recepie using American Heritage Chocolates',
      },
      {
        id: 2, collabImg: Blog3Image, brandName: 'Branston', collabDesc: 'An Instagram campaign to promote Branston’s new collection of stoneware cookware',
      },
    ],
  },
  brandsAndTestimonials: {
    brands: [
      { id: 0, brand: 'Cadbury' },
      { id: 1, brand: 'Diageo' },
      { id: 2, brand: 'Premier Foods' },
      { id: 3, brand: 'Branston' },
      { id: 4, brand: 'Cathedral City Cheddar' },
      { id: 5, brand: 'Marmite' },
      { id: 6, brand: 'Weetabix' },
      { id: 7, brand: 'Penguin Biscuits' },
    ],
    testimonial: {
      recommendation: 'What your food business needs is more Rhea, and less B.S. She’s a pioneer for making the best recepies out of any food material given to her.',
      recommenderName: 'Charles James',
      brandName: 'Threads Brisket',
      recommenderTitle: 'CEO',
    },
  },
  services: [
    {
      id: 0, title: 'Instagram Sponsored Posts', rate: '$200-$500', serviceDesc: '- High quality, product images for your brand\n- Multiple posts over a period of time\n- Post promotion to email subscribers\n',
    },
    {
      id: 1, title: 'Brand Takeover', rate: '$500-$1000', serviceDesc: '- Managing the social space of the brand\n- Posting high quality content\n- Actively interacting with the brand users\n- Going live on Instagram from time to time',
    },
    {
      id: 2, title: 'Youtube Sponsored Video', rate: '$1.2k-$5k', serviceDesc: '- Week long promotion in between daily videos\n- Multiple posts over a period of time\n- 1 Review video, with brand’s product',
    },
    {
      id: 3, title: 'Guest for Events', rate: '$800k-$1.5k', serviceDesc: '- Appearance for Up to 3 hours\n- Photo opportunity to the visitors\n- Event related Blogs and posts',
    },
    {
      id: 4, title: 'Website Advertising', rate: '$400-$600', serviceDesc: '- One week/ One month banner advertisement\n- Blog article review for brand product',
    },
    {
      id: 5, title: 'Other Services', rate: '$100 - $5k', serviceDesc: '- Custom requirements could be discussed ove email',
    },
  ],
});

const initFormData = Object.seal({
  personalDetails: {
    mainImg: '',
    firstName: '',
    lastName: '',
    professionalTitle: '',
    aboutImg: '',
    about: '',
    contactImg: '',
    instagramHandle: '',
    websiteUrl: '',
    email: '',
    mobile: '',
    shippingAddress: '',
    availableFor: '',
  },
  socialStatistics: {
    socialStatsImg: '',
    socialData: [
      {
        id: 0, socialWebsite: 'Instagram', followerCount: '', socialHandle: '',
      },
      {
        id: 1, socialWebsite: 'Twitter', followerCount: '', socialHandle: '',
      },
      {
        id: 2, socialWebsite: 'Facebook', followerCount: '', socialHandle: '',
      },
      {
        id: 3, socialWebsite: 'Linkedin', followerCount: '', socialHandle: '',
      },
      {
        id: 4, socialWebsite: 'Website', followerCount: '', socialHandle: '',
      },
      {
        id: 5, socialWebsite: 'Treemail', followerCount: '', socialHandle: '',
      },
    ],
  },
  instagramAnalytics: {
    analyticsImg: '',
    handle: '',
    followerCount: '',
    avgPostEngagement: '',
    audienceGender: {
      gender: 'Female',
      audienceGenderPercentage: '',
    },
    audienceAge: '',
    audienceLocations: [
      { id: 0, location: '', locationPercentage: '' },
      { id: 1, location: '', locationPercentage: '' },
      { id: 2, location: '', locationPercentage: '' },
    ],
    collaborations: [
      {
        id: 0, collabImg: '', brandName: '', collabDesc: '',
      },
      {
        id: 1, collabImg: '', brandName: '', collabDesc: '',
      },
      {
        id: 2, collabImg: '', brandName: '', collabDesc: '',
      },
    ],
  },
  brandsAndTestimonials: {
    brands: [
      { id: 0, brand: '' },
      { id: 1, brand: '' },
      { id: 2, brand: '' },
      { id: 3, brand: '' },
      { id: 4, brand: '' },
      { id: 5, brand: '' },
      { id: 6, brand: '' },
      { id: 7, brand: '' },
    ],
    testimonial: {
      recommendation: '',
      recommenderName: '',
      brandName: '',
      recommenderTitle: '',
    },
  },
  services: [
    {
      id: 0, title: '', rate: '', serviceDesc: '',
    },
    {
      id: 1, title: '', rate: '', serviceDesc: '',
    },
    {
      id: 2, title: '', rate: '', serviceDesc: '',
    },
    {
      id: 3, title: '', rate: '', serviceDesc: '',
    },
    {
      id: 4, title: '', rate: '', serviceDesc: '',
    },
    {
      id: 5, title: '', rate: '', serviceDesc: '',
    },
  ],
});

const resetFormData = Object.seal({
  personalDetails: {
    mainImg: '',
    firstName: '',
    lastName: '',
    professionalTitle: '',
    aboutImg: '',
    about: '',
    contactImg: '',
    instagramHandle: '',
    websiteUrl: '',
    email: '',
    mobile: '',
    shippingAddress: '',
    availableFor: '',
  },
  socialStatistics: {
    socialStatsImg: '',
    socialData: [
      {
        id: 0, socialWebsite: 'Instagram', followerCount: '', socialHandle: '',
      },
      {
        id: 1, socialWebsite: 'Twitter', followerCount: '', socialHandle: '',
      },
      {
        id: 2, socialWebsite: 'Facebook', followerCount: '', socialHandle: '',
      },
      {
        id: 3, socialWebsite: 'Linkedin', followerCount: '', socialHandle: '',
      },
      {
        id: 4, socialWebsite: 'Website', followerCount: '', socialHandle: '',
      },
      {
        id: 5, socialWebsite: 'Treemail', followerCount: '', socialHandle: '',
      },
    ],
  },
  instagramAnalytics: {
    analyticsImg: '',
    handle: '',
    followerCount: '',
    avgPostEngagement: '',
    audienceGender: {
      gender: 'Female',
      audienceGenderPercentage: '',
    },
    audienceAge: '',
    audienceLocations: [
      { id: 0, location: '', locationPercentage: '' },
      { id: 1, location: '', locationPercentage: '' },
      { id: 2, location: '', locationPercentage: '' },
    ],
    collaborations: [
      {
        id: 0, collabImg: '', brandName: '', collabDesc: '',
      },
      {
        id: 1, collabImg: '', brandName: '', collabDesc: '',
      },
      {
        id: 2, collabImg: '', brandName: '', collabDesc: '',
      },
    ],
  },
  brandsAndTestimonials: {
    brands: [
      { id: 0, brand: '' },
      { id: 1, brand: '' },
      { id: 2, brand: '' },
      { id: 3, brand: '' },
      { id: 4, brand: '' },
      { id: 5, brand: '' },
      { id: 6, brand: '' },
      { id: 7, brand: '' },
    ],
    testimonial: {
      recommendation: '',
      recommenderName: '',
      brandName: '',
      recommenderTitle: '',
    },
  },
  services: [
    {
      id: 0, title: '', rate: '', serviceDesc: '',
    },
    {
      id: 1, title: '', rate: '', serviceDesc: '',
    },
    {
      id: 2, title: '', rate: '', serviceDesc: '',
    },
    {
      id: 3, title: '', rate: '', serviceDesc: '',
    },
    {
      id: 4, title: '', rate: '', serviceDesc: '',
    },
    {
      id: 5, title: '', rate: '', serviceDesc: '',
    },
  ],
});

const initTouched = {
  personalDetails: {
    mainImg: false,
    firstName: false,
    lastName: false,
    professionalTitle: false,
    aboutImg: false,
    about: false,
    contactImg: false,
    instagramHandle: false,
    websiteUrl: false,
    email: false,
    mobile: false,
    shippingAddress: false,
    availableFor: false,
  },
  socialStatistics: {
    socialStatsImg: false,
    socialData: [
      {
        id: 0, socialWebsite: false, followerCount: false, socialHandle: false,
      },
      {
        id: 1, socialWebsite: false, followerCount: false, socialHandle: false,
      },
      {
        id: 2, socialWebsite: false, followerCount: false, socialHandle: false,
      },
      {
        id: 3, socialWebsite: false, followerCount: false, socialHandle: false,
      },
      {
        id: 4, socialWebsite: false, followerCount: false, socialHandle: false,
      },
      {
        id: 5, socialWebsite: false, followerCount: false, socialHandle: false,
      },
    ],
  },
  instagramAnalytics: {
    analyticsImg: false,
    handle: false,
    followerCount: false,
    avgPostEngagement: false,
    audienceGender: {
      gender: 'Female',
      audienceGenderPercentage: false,
    },
    audienceAge: false,
    audienceLocations: [
      { id: 0, location: false, locationPercentage: false },
      { id: 1, location: false, locationPercentage: false },
      { id: 2, location: false, locationPercentage: false },
    ],
    collaborations: [
      {
        id: 0, collabImg: false, brandName: false, collabDesc: false,
      },
      {
        id: 1, collabImg: false, brandName: false, collabDesc: false,
      },
      {
        id: 2, collabImg: false, brandName: false, collabDesc: false,
      },
    ],
  },
  brandsAndTestimonials: {
    brands: [
      { id: 0, brand: false },
      { id: 1, brand: false },
      { id: 2, brand: false },
      { id: 3, brand: false },
      { id: 4, brand: false },
      { id: 5, brand: false },
      { id: 6, brand: false },
      { id: 7, brand: false },
    ],
    testimonial: {
      recommendation: false,
      recommenderName: false,
      brandName: false,
      recommenderTitle: false,
    },
  },
  services: [
    {
      id: 0, title: false, rate: false, serviceDesc: false,
    },
    {
      id: 1, title: false, rate: false, serviceDesc: false,
    },
    {
      id: 2, title: false, rate: false, serviceDesc: false,
    },
    {
      id: 3, title: false, rate: false, serviceDesc: false,
    },
    {
      id: 4, title: false, rate: false, serviceDesc: false,
    },
    {
      id: 5, title: false, rate: false, serviceDesc: false,
    },
  ],
};

export {
  initFinalData,
  defaultFormData,
  initFormData,
  initTouched,
  resetFinalData,
  resetFormData,
};
