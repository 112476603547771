import React, { useState } from 'react';

import Linkify from 'react-linkify';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  Link,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useAuth, useRoute } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    maxWidth: 680,
  },
  video: {
    width: '100%',
    '&>iframe': {
      width: '100%',
      height: 420,
    },
  },
  about: {
    padding: '30px 15px',
  },
  title: {
    ...theme.textStyles.bodyMedium,
    marginBottom: '10px',
  },
  description: {
    color: theme.colors.system.grey8,
    marginBottom: 8,
  },
  resources: {
    '&>div': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '20px',
    },
    '&>:not(:last-child)': {
      borderBottom: `1px solid ${theme.colors.system.grey3}`,
    },
  },
}));

const ContentTabs = withStyles((theme) => ({
  indicator: {
    backgroundColor: theme.colors.button.primary.main,
  },
}))(Tabs);

const ContentTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
  },
  selected: {
    color: theme.colors.button.primary.main,
    '&:hover': {
      color: theme.colors.button.primary.hover,
    },
  },
}))(Tab);

const SparkAndSuContent = ({ content }) => {
  const [tabValue, setTabValue] = useState('about');
  const classes = useStyles();
  const auth = useAuth();
  const [route] = useRoute();
  const isInfluencer = auth.account && (auth.account.type === 'influencer');

  const awsDirectory = () => {
    if (route.pathname.split('/')[1] === 'spark-ffp') {
      return 'spark-ffp';
    } if (isInfluencer) {
      return 'influencer';
    }
    return 'brand_and_agency';
  };

  // useEffect(() => {
  //   if (route.pathname.split('/')[3] == 2 ){
  //     setRoute({ pathname: `/spark-ffp/module/2/lesson/1`})
  //   }
  // },[route])

  return (
    <div className={classes.content}>
      {content.youtube_video_id && (
        <div className={classes.video}>
          <iframe
            title={content.title}
            src={`https://www.youtube.com/embed/${content.youtube_video_id}`}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameBorder="0"
          />
        </div>
      )}
      <div className={classes.content}>
        <ContentTabs onChange={(e, newValue) => setTabValue(newValue)} value={tabValue}>
          <ContentTab label="About" value="about" />
          {!_.isEmpty(content.resources) && <ContentTab label="Resources" value="resources" />}
        </ContentTabs>
        <div className={classes.tabContent}>
          {tabValue === 'about' && (
            <div className={classes.about}>
              <Typography className={classes.title}>
                {content.title}
              </Typography>
              {content.description && content.description.split('\n').map((text) => (
                <Typography key={text} className={classes.description} variant="body2">
                  <Linkify component={Link}>{text}</Linkify>
                </Typography>
              ))}
            </div>
          )}
          {!_.isEmpty(content.resources) && tabValue === 'resources' && (
            <div className={classes.resources}>
              {content.resources.map((resource) => (
                <div>
                  <Typography variant="body1">{resource.title}</Typography>
                  <Link
                    href={resource.link ? resource.link
                      : `https://scrunch-resources-dev.s3.us-east-2.amazonaws.com/${awsDirectory()}/${resource.filename}`}
                    target="_blank"
                  >
                    {resource.link ? 'Link' : 'Download'}
                  </Link>
                </div>
              ))}

            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SparkAndSuContent.propTypes = {
  content: PropTypes.shape().isRequired,
};

export default SparkAndSuContent;
