import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { IconButton, Tooltip, MenuItem } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';

import { makeStyles } from '@material-ui/core/styles';
import ListSettingsDialog from './ListSettingsDialog';
import DeleteListDialog from './DeleteListDialog';
import {
  useRoute, useAuth, useNotification, useLists,
} from '../../hooks';
import API from '../../utils/api';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    ...theme.textStyles.bodySmall,
  },
}));

const ListSettingsButton = ({
  list, onSuccess, size, setIsDeleteListFetching, listsPage,
}) => {
  const auth = useAuth();
  const [, setRoute] = useRoute();
  const { createNotification } = useNotification();
  const classes = useStyles();
  const lists = useLists();

  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [isDeleteListDialogOpen, setIsDeleteListDialogOpen] = useState(false);

  const handleDeleteClick = () => {
    setDialogIsOpen(false);
    setIsDeleteListDialogOpen(true);
  };

  const handleDeleteList = async () => {
    if (setIsDeleteListFetching) {
      setIsDeleteListFetching(true);
    }

    try {
      const response = await API({
        method: 'delete',
        url: `/account/${auth.account.id}/list/${list.id}/`,
      });

      if (response && response.status >= 200 && response.status < 300) {
        createNotification(
          'success',
          'List Deleted Successfully',
        );
        setIsDeleteListDialogOpen(false);
        setRoute({
          pathname: '/list',
        });
        lists.refreshLists(auth.account.id);
      }
    } catch (err) {
      setIsDeleteListDialogOpen(false);
      createNotification(
        'error',
        'An unexpected error occured. Could not delete list',
      );
      if (setIsDeleteListFetching) {
        setIsDeleteListFetching(false);
      }
    }
  };

  return (
    <>
      <Tooltip title="List settings">
        <span>
          {listsPage && auth.hasPermission('list.delete') ? (
            <MenuItem
              className={classes.menuItem}
              onClick={() => setDialogIsOpen(true)}
            >
              Settings
            </MenuItem>
          ) : (
            <IconButton onClick={() => setDialogIsOpen(true)} size={size}>
              <SettingsIcon />
            </IconButton>
          )}
        </span>
      </Tooltip>
      {dialogIsOpen && (
        <ListSettingsDialog
          onClose={() => setDialogIsOpen(false)}
          onSuccess={onSuccess}
          onDelete={handleDeleteClick}
          list={list}
        />
      )}
      <DeleteListDialog
        showDeleteDialog={isDeleteListDialogOpen}
        setShowDeleteDialog={setIsDeleteListDialogOpen}
        listName={list.name}
        deleteList={handleDeleteList}
      />
    </>
  );
};

ListSettingsButton.propTypes = {
  list: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func,
  size: PropTypes.string,
  setIsDeleteListFetching: PropTypes.func,
  listsPage: PropTypes.bool,
};

ListSettingsButton.defaultProps = {
  onSuccess: null,
  size: 'medium',
  setIsDeleteListFetching: null,
  listsPage: false,
};

export default ListSettingsButton;
