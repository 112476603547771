import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  Avatar,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import DeleteIcon from '@material-ui/icons/Delete';
import { defaultFormData } from '../data';
import Placeholder from '../../../assets/images/media-kit/placeholder.png';
import API from '../../../utils/api';
import { useAuth } from '../../../hooks';

import { useStyles } from './styles';

/* ----------------------------------- SOCIAL STATISTICS --------------------------------*/
const SocialStatisticsForm = ({
  formData,
  setFormData,
  setHighlighted,
  finalData,
  setFinalData,
  mkId,
  setIsFetching,
}) => {
  // console.log(formData);

  const classes = useStyles();
  const imgRef = useRef(null);
  const socialWebsites = ['Instagram', 'Twitter', 'Facebook', 'Linkedin', 'Website', 'Treemail'];
  const auth = useAuth();

  useEffect(() => {
    document.getElementById('formWrapper').scrollTo(0, 0);
  }, []);

  const onImageUpload = (e) => {
    try {
      const imgUrl = URL.createObjectURL(e.target.files[0]);
      const formDataTemp = { ...formData };
      formDataTemp.socialStatistics.socialStatsImg = imgUrl;

      const finalDataTemp = { ...finalData };
      finalDataTemp.socialStatistics.socialStatsImg = imgUrl;

      setFormData(formDataTemp);
      setFinalData(finalDataTemp);
    } catch (err) {
      // Do Nothing
    }
  };

  const onImageDelete = () => {
    const formDataTemp = { ...formData };
    formDataTemp.socialStatistics.socialStatsImg = Placeholder;

    const finalDataTemp = { ...finalData };
    finalDataTemp.socialStatistics.socialStatsImg = Placeholder;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const onSocialWebsiteChange = (e, index) => {
    const socialDataTemp = [...formData.socialStatistics.socialData];
    socialDataTemp[index].socialWebsite = e.target.value;
    const formDataTemp = { ...formData };
    formDataTemp.socialStatistics.socialData = socialDataTemp;

    const finalSocialDataTemp = [...finalData.socialStatistics.socialData];
    finalSocialDataTemp[index].socialWebsite = e.target.value;
    const finalDataTemp = { ...finalData };
    finalDataTemp.socialStatistics.socialData = finalSocialDataTemp;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const onFollowerCountChange = (e, index) => {
    const socialDataTemp = [...formData.socialStatistics.socialData];
    socialDataTemp[index].followerCount = e.target.value;
    const formDataTemp = { ...formData };
    formDataTemp.socialStatistics.socialData = socialDataTemp;

    const finalSocialDataTemp = [...finalData.socialStatistics.socialData];
    finalSocialDataTemp[index].followerCount = e.target.value;
    const finalDataTemp = { ...finalData };
    finalDataTemp.socialStatistics.socialData = finalSocialDataTemp;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const onHandleChange = (e, index) => {
    const socialDataTemp = [...formData.socialStatistics.socialData];
    socialDataTemp[index].socialHandle = e.target.value;
    const formDataTemp = { ...formData };
    formDataTemp.socialStatistics.socialData = socialDataTemp;

    const finalSocialDataTemp = [...finalData.socialStatistics.socialData];
    finalSocialDataTemp[index].socialHandle = e.target.value;
    const finalDataTemp = { ...finalData };
    finalDataTemp.socialStatistics.socialData = finalSocialDataTemp;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const addSocialStatistic = () => {
    const usedIds = formData.socialStatistics.socialData.map((item) => item.id);
    const availableIds = formData.socialStatsIds
      .filter((x) => !usedIds.includes(x))
      .concat(usedIds.filter((x) => !formData.socialStatsIds.includes(x)));

    const socialDataTemp = [
      ...formData.socialStatistics.socialData,
      {
        id: availableIds[0],
        socialWebsite: '',
        followerCount: '',
        socialHandle: '',
      },
    ];
    const formDataTemp = { ...formData };
    formDataTemp.socialStatistics.socialData = socialDataTemp;

    const finalSocialDataTemp = [
      ...finalData.socialStatistics.socialData,
      {
        id: availableIds[0],
        followerCount: '',
        socialHandle: '',
      },
    ];
    const finalDataTemp = { ...finalData };
    finalDataTemp.socialStatistics.socialData = finalSocialDataTemp;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const deleteSocialStatistic = async (index) => {
    try {
      setIsFetching(true);
      const response = await API({
        url: `/account/${auth.account.id}/media-kit/${mkId}/social_statistics/${finalData.socialStatistics.socialData[index].id}/`,
        method: 'delete',
      });

      if (response.status === 200) {
        const socialDataTemp = formData.socialStatistics.socialData.filter((_, i) => i !== index);
        const finalSocialDataTemp = finalData.socialStatistics.socialData.filter((_, i) => i !== index);

        const formDataTemp = { ...formData };
        formDataTemp.socialStatistics.socialData = socialDataTemp;
        const finalDataTemp = { ...finalData };
        finalDataTemp.socialStatistics.socialData = finalSocialDataTemp;

        setFormData(formDataTemp);
        setFinalData(finalDataTemp);
        setIsFetching(false);
      }
    } catch (_) {
      setIsFetching(false);
    }
  };

  return (
    <div className={classes.sectionRoot}>
      <Typography
        variant="body1"
        component="div"
        color="primary"
        className={classes.sectionTitle}
      >
        Number Of Followers
      </Typography>

      <Typography variant="body2" component="p" className={classes.formLabel}>Upload Social Statistics Image</Typography>
      <div className={classes.uploadWrapper}>
        <Avatar src={formData.socialStatistics.socialStatsImg} className={classes.imgAvatar}><ImageIcon /></Avatar>
        <input type="file" ref={imgRef} onChange={onImageUpload} style={{ display: 'none' }} />
        {formData.socialStatistics.socialStatsImg === Placeholder ? (
          <Button
            className={classes.uploadBtn}
            size="small"
            onClick={() => { setHighlighted('socialStatistics.socialStatsImg'); imgRef.current.click(); }}
          >
            Upload Image
          </Button>
        ) : (
          <Button
            className={classes.delImgBtn}
            size="small"
            startIcon={<DeleteIcon />}
            variant="outlined"
            onClick={onImageDelete}
          >
            Delete Image
          </Button>
        )}
      </div>

      {formData.socialStatistics.socialData && formData.socialStatistics.socialData.map((data, index) => (
        <div className={classes.flexRow} style={{ marginBottom: 25 }} key={data.id}>
          <TextField
            label="Social Website"
            value={data.socialWebsite}
            placeholder={defaultFormData.socialStatistics.socialData[index].socialWebsite}
            style={{ width: '30%', marginRight: '2%' }}
            InputProps={{ className: classes.input }}
            select
            onChange={(e) => onSocialWebsiteChange(e, index)}
            onFocus={() => setHighlighted(`socialStatistics.socialData${index}`)}
          >
            {socialWebsites.map((website) => (
              <MenuItem key={website} value={website}>{website}</MenuItem>
            ))}
          </TextField>
          <TextField
            label="Number Of Followers"
            value={data.followerCount}
            placeholder={defaultFormData.socialStatistics.socialData[index].followerCount}
            style={{ width: '30%', marginRight: '2%' }}
            InputProps={{ className: classes.input }}
            onChange={(e) => onFollowerCountChange(e, index)}
            onFocus={() => setHighlighted(`socialStatistics.socialData${index}`)}
          />
          <TextField
            label="Handle"
            value={data.socialHandle}
            placeholder={defaultFormData.socialStatistics.socialData[index].socialHandle}
            style={{ width: '30%', marginRight: '2%' }}
            InputProps={{
              className: classes.input,
              startAdornment: <InputAdornment position="start" classes={{ root: classes.adornment }}>@</InputAdornment>,
            }}
            onChange={(e) => onHandleChange(e, index)}
            onFocus={() => setHighlighted(`socialStatistics.socialData${index}`)}
          />
          {formData.socialStatistics.socialData.length !== 1 && (
            <IconButton
              aria-label="delete"
              className={classes.delIcon}
              onClick={() => deleteSocialStatistic(index)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      ))}

      {formData.socialStatistics.socialData.length < 6 && (
        <Button
          color="primary"
          size="small"
          onClick={addSocialStatistic}
        >
          + Add Another Social Website
        </Button>
      )}
    </div>
  );
};

SocialStatisticsForm.propTypes = {
  formData: PropTypes.shape().isRequired,
  setFormData: PropTypes.func.isRequired,
  setHighlighted: PropTypes.func.isRequired,
  finalData: PropTypes.shape().isRequired,
  setFinalData: PropTypes.func.isRequired,
  mkId: PropTypes.number.isRequired,
  setIsFetching: PropTypes.func.isRequired,
};

export default SocialStatisticsForm;
