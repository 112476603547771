import React from 'react';
import { Link as RouterLink } from '@reach/router';

import {
  Avatar,
  Button,
  Link,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Ellipse1 from '../../assets/images/campaign-report/ellipse1.png';
import Ellipse2 from '../../assets/images/campaign-report/ellipse2.png';
import Ellipse3 from '../../assets/images/campaign-report/ellipse3.png';
import SampleReport from '../../assets/files/sample-report.pdf';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 40,
    backgroundColor: theme.colors.background.primary,
    // minHeight: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  influencerImages: {
    display: 'flex',
  },
  profileAvatar: {
    width: 70,
    height: 70,
    '&:not(:first-child)': {
      marginLeft: '-20px',
    },
  },
  subHeading: {
    color: theme.colors.system.grey7,
    textAlign: 'center',
    width: '80%',
    ...theme.textStyles.bodyMedium,
  },
  downloadLink: {
    textAlign: 'center',
    ...theme.textStyles.bodyMedium,
  },
  upgradeBtn: {
    margin: '30px 0',
    width: 220,
    minHeight: 40,
  },
  dividerText: {
    color: theme.colors.system.grey6,
    padding: '5px 0 15px',
    display: 'flex',
    '&::before': {
      content: '""',
      flex: '1 1',
      borderBottom: `1px solid ${theme.colors.system.grey7}`,
      margin: 'auto',
      marginRight: 10,
    },
    '&::after': {
      content: '""',
      flex: '1 1',
      borderBottom: `1px solid ${theme.colors.system.grey7}`,
      margin: 'auto',
      marginLeft: 15,
    },
  },
  features: {
    '& li': {
      padding: '15px 0',
      fontWeight: 400,
      fontSize: 13.5,
      lineHeight: '20px',
    },
  },
}));

const NoReportAccess = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.influencerImages}>
        <Avatar
          className={classes.profileAvatar}
          src={Ellipse2}
          alt="A"
          style={{ zIndex: 3 }}
        />
        <Avatar
          className={classes.profileAvatar}
          src={Ellipse1}
          alt="A"
          style={{ zIndex: 2 }}
        />
        <Avatar
          className={classes.profileAvatar}
          src={Ellipse3}
          alt="A"
          style={{ zIndex: 1 }}
        />
      </div>

      <Typography variant="h5" component="h5" style={{ display: 'flex', margin: '25px 0' }}>
        Want access to&nbsp;
        <Typography variant="h5" component="h5" color="primary">campaign reports?</Typography>
      </Typography>

      <Typography className={classes.subHeading}>
        Upgrade now to create unlimited campaign reports to save time and show off your campaign success.
      </Typography>

      <Button
        variant="contained"
        color="primary"
        component={RouterLink}
        to="/settings/plans"
        className={classes.upgradeBtn}
      >
        Upgrade Now
      </Button>

      <div style={{ width: '100%' }}>
        <Typography component="span" variant="body1" className={classes.dividerText}>
          What are campaign reports?
        </Typography>
      </div>

      <ul className={classes.features}>
        <Typography variant="body2" component="li">
          A quick and easy report of your Influencer posts.
        </Typography>
        <Typography variant="body2" component="li">
          Simply paste in the Instagram live links and we&apos;ll do the rest.
        </Typography>
        <Typography variant="body2" component="li">
          Export your post campaign report as a PDF or excel file to share and analyse with your team.
        </Typography>
      </ul>

      <Typography className={classes.subHeading}>
        Here&apos;s to more awesome Influencer Marketing!!
      </Typography>

      <Typography
        className={classes.subHeading}
        style={{ display: 'flex', width: 'auto', marginTop: 15 }}
      >
        Download a sample campaign report&nbsp;
        <Link href={SampleReport} target="_blank">
          <Typography color="primary" className={classes.downloadLink}>here</Typography>
        </Link>
      </Typography>
    </div>
  );
};

export default NoReportAccess;
