import React from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '47.5%',
    backgroundColor: '#FFFFFF',
    border: `1px solid ${theme.colors.system.grey3}`,
    borderRadius: 4,
    marginBottom: 20,
    minHeight: 290,
  },
  campaignImage: {
    width: '100%',
    paddingTop: '125%',
    position: 'relative',
    backgroundColor: '#000000',
    borderRadius: '4px 4px 0 0',
  },
  imageWrapper: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  },
  metrics: {
    padding: '5px 10px',
    display: 'flex',
    flexDirection: 'column',
  },
  metric: {
    display: 'inline-flex',
    padding: '5px 0',
    '& span': {
      ...theme.textStyles.bodyExtraSmall,
    },
  },
}));

const Metric = ({ label, value }) => {
  const classes = useStyles();
  return (
    <div className={classes.metric}>
      <Typography variant="body2" component="span" color="primary">
        {value}
        &nbsp;
      </Typography>
      <Typography variant="body2" component="span">
        {label}
        &nbsp;
      </Typography>
    </div>
  );
};

Metric.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const CampaignPost = ({ influencerData, index }) => {
  const classes = useStyles();

  if (!(influencerData && influencerData.report_media && influencerData.report_media[index])) {
    return null;
  }

  const { follower_count: followerCount, report_media: reportMedia, stats } = influencerData;
  const post = reportMedia[index];

  return (
    <div className={`${classes.root} ${index !== 0 && index % 2 === 0 ? 'pageBreak' : ''}`}>
      <div className={classes.campaignImage}>
        <div className={classes.imageWrapper} style={{ backgroundImage: `url(${post.image_versions[0].url})` }} />
      </div>
      <div className={classes.metrics}>
        <Metric label="Followers" value={followerCount ? followerCount.toString() : '0'} />
        <Metric label="Engagements" value={post.stats.engagement ? post.stats.engagement.toString() : '0'} />
        <Metric label="Post Engagement Rate" value={`${(post.stats.engagement_rate * 100).toFixed(2)}%`} />
        <Metric label="Influencer Engagement Rate" value={`${(stats.average_engagement_rate * 100).toFixed(2)}%`} />
      </div>
    </div>
  );
};

CampaignPost.propTypes = {
  influencerData: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
};

export default CampaignPost;
