import React from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  IconButton,
  Typography,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  root: {
    width: '100%',
    padding: '0 3rem',
    minHeight: 275,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heading: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  text: {
    ...theme.textStyles.bodyMedium,
    padding: 10,
    fontSize: '0.6rem',
    textAlign: 'center',
    margin: '35px auto',
    minWidth: 80,
    width: '100%',
    backgroundColor: theme.colors.warning.mid,
    color: theme.colors.warning.main,
    lineHeight: 1.5,
  },
  btnCont: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  btn: {
    padding: '0.5rem 1.5rem',
    fontSize: '0.75rem',
  },
}));

const SaveDataBeforeDowngradeDialog = ({
  isDialogOpen,
  closeDialog,
  downgradeWithoutSaving,
  downgradeToDataSaver,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isDialogOpen}
      onBackdropClick={closeDialog}
      PaperProps={{ style: { padding: 0, width: 600 } }}
    >
      <IconButton aria-label="close" className={classes.closeButton} onClick={closeDialog}>
        <CloseIcon />
      </IconButton>

      <div className={classes.root}>
        <div className={classes.heading}>
          <Typography variant="h5" component="h5">
            Save Data Before Downgrading
          </Typography>
        </div>

        <Typography variant="body1" className={classes.text}>
          If the plan is downgraded all the premium lists, reports, and records will be
          permanently deleted. You can choose to save your data for $19 a month before downgrading
        </Typography>

        <div className={classes.btnCont}>
          <Button
            color="primary"
            variant="contained"
            className={classes.btn}
            onClick={downgradeToDataSaver}
          >
            Pay $19 a month to Save Data
          </Button>
          <Button
            color="primary"
            variant="outlined"
            className={classes.btn}
            onClick={downgradeWithoutSaving}
          >
            Downgrade without Saving Data
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

SaveDataBeforeDowngradeDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  downgradeWithoutSaving: PropTypes.func.isRequired,
  downgradeToDataSaver: PropTypes.func.isRequired,
};

export default SaveDataBeforeDowngradeDialog;
