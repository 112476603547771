import React from 'react';

import PropTypes from 'prop-types';

import { Chip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '20px 0',
    borderRadius: 5,
    /* backgroundColor: theme.colors.system.grey1, */
  },
  title: {
    marginBottom: 15,
    '&>p': {
      fontWeight: 'bold',
    },
    ...theme.textStyles.bodySmall,
  },
  tagList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '&>:not(last-child)': {
      marginRight: 10,
      marginBottom: 15,
    },
  },
}));

const ProfileAudienceTags = (props) => {
  const { tags, title } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="body1">{title}</Typography>
      </div>
      <div className={classes.tagList}>
        {tags.map((tag) => (
          <Chip key={tag} label={tag} style={{ fontSize: 12, borderRadius: 5 }} />
        ))}
      </div>
    </div>
  );
};

ProfileAudienceTags.propTypes = {
  title: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ProfileAudienceTags;
