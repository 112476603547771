import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import TikTokIcon from '../../common/icons/TikTok';

import InstagramLogo from '../../../assets/images/network-logo/instagram.svg';

const profilesToClaim = [
  {
    title: 'Instagram',
    icon: InstagramIcon,
    logo: InstagramLogo,
  },
  {
    title: 'Facebook',
    icon: FacebookIcon,
    logo: InstagramLogo,
  },
  {
    title: 'Twitter',
    icon: TwitterIcon,
    logo: InstagramLogo,
  },
  {
    title: 'TikTok',
    icon: TikTokIcon,
    logo: InstagramLogo,
  },
];

export default profilesToClaim;
