import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  textField: {
    display: 'flex',
    alignItems: 'center',
  },
  at: {
    height: '45px',
    width: '45px',
    backgroundColor: theme.colors.system.grey4,
    borderRadius: '4px',
    textAlign: 'center',
    padding: '12.5px',
  },
}));

const AtTextField = ({ handleChange, width }) => {
  const classes = useStyles();

  return (
    <div className={classes.textField} style={{ width }}>
      <TextField
        margin="dense"
        id="username"
        type="text"
        fullWidth
        // required
        placeholder="username"
        style={{ borderRadius: 0 }}
        // error={Boolean(lastNameError)}
        // helperText={lastNameError}
        onChange={handleChange}
        InputProps={{
          startAdornment: <div className={classes.at}>@</div>,
        }}
      />
    </div>
  );
};

AtTextField.propTypes = {
  handleChange: PropTypes.func,
  width: PropTypes.string,
};

AtTextField.defaultProps = {
  handleChange: null,
  width: null,
};

export default AtTextField;
