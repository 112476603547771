import React, {
  useEffect,
  // useMemo,
  useState,
} from 'react';

import PropTypes from 'prop-types';

import { InputAdornment, TextField, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@reach/router';

import ClearIcon from '@material-ui/icons/Clear';
import UserProfileButton from './UserProfileButton';
import SearchIcon from '../common/icons/Search';
import { useAuth, useRoute } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    height: (props) => props.height,
    display: 'grid',
    gridTemplateColumns: 'auto min-content min-content',
    alignItems: 'center',
    backgroundColor: theme.colors.system.grey2,
    padding: '0px 20px 0px 20px',
  },
  searchTextField: {
    backgroundColor: theme.colors.system.light,
    maxWidth: '100%',
    marginRight: theme.spacing(3),
    '& input': {
      fontSize: '12px',
    },
  },
  userProfile: {
    justifySelf: 'end',
  },
  searchIcon: {
    width: 12,
    height: 12,
  },
  keywordBox: {
    alignItems: 'center',
  },
  keywordChip: {
    marginRight: '0.5rem',
  },
  freeTrialCount: {
    padding: theme.spacing(1),
    border: `1px solid ${theme.colors.primary.main}`,
    borderRadius: '4px',
    backgroundColor: theme.colors.warning.light,
    whiteSpace: 'nowrap',
    marginRight: theme.spacing(3),
    ...theme.textStyles.bodySmall,
    color: theme.colors.primary.main,
    width: 'min-content',
    justifySelf: 'end',
  },
  upgradeNowLink: {
    textDecoration: 'none',
    marginLeft: theme.spacing(3),
    ...theme.textStyles.bodySmall,
    color: theme.colors.primary.main,
  },
}));

const Header = ({ className, height }) => {
  const classes = useStyles({ height });
  const [route, setRoute] = useRoute();
  const auth = useAuth();
  const [searchKeywords, setSearchKeywords] = useState([]);
  const [tempKeyword, setTempKeyword] = useState('');
  const [changeKeywords, setChangeKeywords] = useState(!!route.params.keywords);
  const accountCreatedTime = auth.user.roles[0].account.billing.customer.created;
  const currentTime = Math.floor(new Date().getTime() / 1000);
  const trialDaysRemaining = 7 - Math.floor(((currentTime - accountCreatedTime) / (24 * 60 * 60)));
  const [showTrialPeriod, setShowTrialPeriod] = useState(false);
  const employee = auth.user && auth.user.roles[0].name === 'employee';

  useEffect(() => {
    if (auth) {
      const currentPlan = auth.account.billing.current_plan.id.split('_');
      const notSubscribed = currentPlan.includes('starter') || currentPlan.includes('trial');
      const isInfluencer = auth.account.type === 'influencer';
      if (notSubscribed && !isInfluencer) {
        setShowTrialPeriod(true);
      }
    }
  }, [auth]);

  const KeywordBox = () => {
    const handleOnDelete = (topic) => {
      if (searchKeywords.length === 1) {
        setChangeKeywords(false);
        setRoute({
          pathname: route.pathname,
          params: {},
        });
      }
      setSearchKeywords(searchKeywords.filter((item) => item !== topic));
    };
    return (
      <>
        {searchKeywords && searchKeywords.map((topic) => (
          <span className={classes.keywordChip}>
            <Chip
              key={topic}
              label={topic}
              onDelete={() => { handleOnDelete(topic); }}
              color="primary"
              deleteIcon={<ClearIcon style={{ height: 15 }} />}
              className={classes.chip}
            />
          </span>
        ))}
      </>
    );
  };

  useEffect(() => {
    if (changeKeywords) {
      if (searchKeywords) {
        setRoute({
          pathname: route.pathname === '/list' ? route.pathname : '/discover',
          params: {
            ...route.params,
            keywords: searchKeywords.join(' ') || '',
            lastPage: route.pathname,
          },
        });
      }
      setTempKeyword('');
      setChangeKeywords(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeKeywords, searchKeywords, route.params]);

  useEffect(() => {
    if (route.pathname !== '/discover') {
      setSearchKeywords('');
    }
  }, [route.pathname]);

  const handleSearchTextFieldKeyDown = (event) => {
    if (!searchKeywords || searchKeywords.length < 5) {
      if (event.key === 'Enter') {
        event.preventDefault();
        setChangeKeywords(true);
        setSearchKeywords([...searchKeywords, tempKeyword]);
      // submitSearch()
      }
    }
  };

  const placeHolder = () => {
    if (route.pathname === '/list' && (Boolean(!searchKeywords) || (searchKeywords && searchKeywords.length < 5))) {
      return 'Filter list by keyword, topics, location and more...';
    } if (searchKeywords && searchKeywords.length >= 5) {
      return 'Filter limit reached';
    }
    return 'Search by keyword, topics, locations and more...';
  };

  return (
    <div className={`${className} ${classes.root}`}>
      {auth.hasPermission('profile.search') ? (
        <TextField
          className={classes.searchTextField}
          placeholder={placeHolder()}
          variant="outlined"
          onKeyDown={handleSearchTextFieldKeyDown}
          onChange={(event) => setTempKeyword(event.target.value)}
          margin="dense"
          style={{ marginTop: '4px' }}
          value={tempKeyword}
          disabled={!auth.hasPermission('list.create') && employee}
          InputProps={{
            readOnly: !!(searchKeywords && searchKeywords.length >= 5),
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
                <InputAdornment position="end">
                  <KeywordBox />
                </InputAdornment>
              </>
            ),
            'aria-label': 'search',
          }}
        />
      ) : <div />}
      {showTrialPeriod
        && (
        <div variant="outlined" className={classes.freeTrialCount}>
          <b>{`${trialDaysRemaining > 0 ? trialDaysRemaining : 0} days `}</b>
          remaining on your free trial
          <Link to="settings/plans" className={classes.upgradeNowLink}>
            <b>Upgrade Now</b>
          </Link>
        </div>
        )}
      <div>
        <UserProfileButton className={classes.userProfile} />
      </div>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number.isRequired,
};

Header.defaultProps = {
  className: null,
};

export default Header;
