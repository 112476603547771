import { makeStyles } from '@material-ui/core/styles';

const viewPostStyles = makeStyles((theme) => ({
  leftSide: {
    overflowY: 'hidden',
    backgroundColor: theme.colors.background.primary,
    width: '45%',
  },
  leftCont: {
    width: '45%',
    height: '50%',
    margin: 'auto auto',
    position: 'relative',
    '&>*': {
      margin: 'auto auto',
    },
  },
  iphone: {
    height: '500px',
    // marginBottom: '40px',
    // marginTop: '100px',
    position: 'absolute',
    top: '100px',
  },
  phoneContent: {
    position: 'absolute',
    top: '172px',
    width: '217px',
    left: '15px',
    height: '207px',
    zIndex: '99',
  },
  iphoneAvatar: {
    height: '25px',
    width: '25px',
    marginTop: '0',
  },
  handleLocation: {
    marginLeft: '10px',
  },
  handle: {
    fontSize: '10px',
    lineHeight: '1.5',
    fontWeight: 'bold',
  },
  location: {
    fontSize: '10px',
    lineHeight: '1',
  },
  leftAvatarUsername: {
    display: 'flex',
    margin: '0 10px 8px 10px',
  },
  postImage: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  postText: {
    marginTop: '25px',
    padding: '12px',
    heigh: '100px',
    overflow: 'hidden',
  },
  downContBtn: {
    height: '45px',
    position: 'absolute',
    top: '650px',
    left: '40px',
  },
  rightSide: {
    width: '55%',
    padding: '20px',
    overflowY: 'scroll',
    '& p': {
      ...theme.textStyles.h6,
      fontWeight: 'bold',
      fontSize: '12px',
      marginBottom: theme.spacing(1),
    },
  },
  titlePicStatus: {
    display: 'flex',
    padding: '10px 10px 20px 10px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    borderBottom: `2px solid ${theme.colors.system.grey2}`,
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 0,
  },
  brandPic: {
    height: '50px',
    width: '50px',
    marginRight: '20px',
  },
  headIcons: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: 40,
    right: 0,
    '&>*': {
      marginLeft: '10px',
      fontSize: '18px',
    },
  },
  sendForBtn: {
    fontSize: '10px',
    height: '25px',
  },
  menuItem: {
    fontSize: '12px',
  },
  tabs: {
    marginTop: '50px',
  },
  influencer: {
    marginTop: '15px',
  },
  infField: {
    marginTop: '10px',
    height: '45px',
    display: 'flex',
    alignItems: 'center',
  },
  infAvatar: {
    height: '25px',
    width: '26px',
    fontSize: '12px',
    margin: '0 10px',
  },
  checklist: {},
  checklistItem: {
    '&>*': {
      padding: '5px',
    },
  },
  checklistBtn: {
    display: 'block',
    fontSize: '10px',
    paddingLeft: 0,
  },
  analytics: {
    marginTop: '25px',
  },
  analyticsField: {
    marginTop: '10px',
  },
  labels: {
    marginTop: '25px',
  },
  labelField: {
    marginTop: '10px',
    height: '45px',
    display: 'flex',
    alignItems: 'center',
  },
  dates: {
    display: 'flex',
    marginTop: '25px',
    '&>*': {
      width: '50%',
    },
  },
  dateField: {
    marginTop: '10px',
    width: '90%',
  },
  notes: {
    marginTop: '25px',
    marginBottom: '50px',
  },
  noteField: {
    marginTop: '10px',
  },
  // Messages
  msgCont: {
    border: `2px solid ${theme.colors.system.grey2}`,
  },
  msgHeader: {
    display: 'flex',
    borderBottom: `2px solid ${theme.colors.system.grey2}`,
  },
  msgTab: {
    border: `1px solid ${theme.colors.system.grey2}`,
    padding: '10px 15px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '12px',
    backgroundColor: 'white',
  },
  activeTab: {
    color: theme.colors.primary.main,
    border: `1px solid ${theme.colors.system.grey2}`,
    padding: '10px 15px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '12px',
    backgroundColor: 'white',
  },
  msgMsgs: {
    maxHeight: '450px',
    minHeight: '450px',
    overflowY: 'auto',
    padding: '25px',
    display: 'flex',
    flexDirection: 'column-reverse',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    // '&::-webkit-scrollbar-track': {
    //   boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
    // },
    // '&::-webkit-scrollbar-thumb': {
    //   backgroundColor: 'darkgrey',
    //   outline: '1px solid slategrey',
    // },
  },
  msgActions: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.system.grey1,
    alignItems: 'center',
  },
  msgInput: {
    padding: '20px',
  },
  msgSend: {
    height: '50px',
    width: '50px',
    color: 'white',
    backgroundColor: theme.colors.primary.main,
    marginRight: '20px',
  },
  leftMsg: {
    display: 'flex',
    alignItems: 'bottom',
    marginBottom: '25px',
  },
  leftMsgBox: {
    backgroundColor: theme.colors.system.grey3,
    borderRadius: '4px',
    padding: '10px',
  },
  leftAvatar: {
    marginRight: '15px',
    marginTop: '40px',
    color: 'black',
    backgroundColor: '#E4F3FF',
  },
  rightMsg: {
    display: 'flex',
    marginBottom: '25px',
    justifyContent: 'right',
  },
  rightAvatar: {
    marginLeft: '15px',
    marginTop: '40px',
    color: 'black',
    backgroundColor: '#FAE7DA',
  },
  rightMsgBox: {
    backgroundColor: theme.colors.secondary.main,
    color: 'white',
    borderRadius: '4px',
    padding: '15px',
    paddingRight: '40px',
  },
}));

export default viewPostStyles;
