import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { LinearProgress } from '@material-ui/core';
import { FixedSizeList } from 'react-window';

import { useApi, useAuth } from '../../hooks';

const VirtualizedList = (props) => {
  const {
    height,
    itemData,
    itemHeight,
    ListItemComponent,
    overscanCount,
    overscanFetchCount,
  } = props;
  const [lists, setLists] = useState([]);
  const [total, setTotal] = useState(0);
  const auth = useAuth();
  const [
    {
      data,
      request,
      status,
      isFetching,
    },
    setGetListsRequest,
  ] = useApi({
    method: 'get',
    url: `/account/${auth.account.id}/list/`,
    params: {
      from: 0,
      size: Math.floor(height / itemHeight) + overscanFetchCount,
    },
  });

  useEffect(() => {
    if (status === 200 && data) {
      const { from, size } = request.params;
      const listsSize = Math.min(data.total, from + size);
      setLists((l) => Array(listsSize).fill(null).map((it, idx) => {
        // Merge the new list array into the existing list state
        if (idx >= from && (idx - from) < data.lists.length) {
          return data.lists[idx - from];
        }
        return idx < l.length ? l[idx] : null;
      }));
      setTotal(data.total);
    }
  }, [data, request, status, total]);

  const handleItemsRendered = ({ overscanStartIndex, overscanStopIndex }) => {
    if (isFetching) {
      return;
    }
    const fetchRequired = (
      overscanStopIndex > lists.length
      || lists.slice(overscanStartIndex, overscanStopIndex + 1).some((it) => it === null)
    );
    if (fetchRequired) {
      setGetListsRequest({
        method: 'get',
        url: `/account/${auth.account.id}/list/`,
        params: {
          from: Math.max(overscanStartIndex - overscanFetchCount, 0),
          size: overscanStopIndex - overscanStartIndex + 2 * overscanFetchCount,
        },
      });
    }
  };

  if (!total) {
    return <div />;
  }

  return (
    <div style={{ width: '100%', height, overflow: 'hidden auto' }}>
      {isFetching && (
        <LinearProgress
          style={{
            position: 'absolute',
            width: '100%',
          }}
        />
      )}
      <FixedSizeList
        height={height}
        itemCount={total}
        itemData={{ lists, ...itemData }}
        itemSize={itemHeight}
        onItemsRendered={handleItemsRendered}
        overscanCount={overscanCount}
      >
        {ListItemComponent}
      </FixedSizeList>
    </div>
  );
};

VirtualizedList.propTypes = {
  height: PropTypes.number.isRequired,
  itemData: PropTypes.shape(),
  itemHeight: PropTypes.number.isRequired,
  ListItemComponent: PropTypes.elementType.isRequired,
  overscanCount: PropTypes.number,
  overscanFetchCount: PropTypes.number,
};

VirtualizedList.defaultProps = {
  itemData: {},
  overscanCount: 5,
  overscanFetchCount: 20,
};

export default VirtualizedList;
