import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import Dashboard from '@material-ui/icons/Dashboard';
import People from '@material-ui/icons/People';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import BarChartIcon from '@material-ui/icons/BarChart';
import EmailIcon from '@material-ui/icons/Email';

const useStyles = makeStyles((theme) => ({
  tabs: {
    width: '90%',
    height: '2rem',
    borderBottom: `solid 2px ${theme.colors.system.grey2}`,
    marginBottom: '2rem',
  },
  tabTitle: {
    padding: '1rem 1rem 0 1rem',
    cursor: 'pointer',
  },
  activeTab: {
    border: `solid 2px ${theme.colors.system.grey2}`,
    borderTopRightRadius: '2px',
    borderTopLeftRadius: '2px',
    borderBottom: 'solid 1rem white',
    color: theme.colors.primary.main,
    padding: '1rem 1rem 0 1rem',
    cursor: 'pointer',
  },
  icon: {
    fontSize: '15px',
    marginRight: '5px',
  },
}));

// On settings page the two tabs act different depending on whether
// there is member or invite data.

// On content approval the two tabs show regarless.
// (noDataNeeded)

const Tabs = (props) => {
  const {
    tabOneData,
    tabTwoData,
    tabOneActive,
    tabTwoActive,
    tabOneTitle,
    tabTwoTitle,
    tabThreeTitle,
    changeToTabOne,
    changeToTabTwo,
    changeToTabThree,
    noDataNeeded,
    fontSize,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.tabs}>
      {/* Tab1 */}
      {(noDataNeeded || tabOneData.length > 0 || tabTwoData.length > 0) && (
        <Typography
          variant="body1"
          component="span"
          style={{ fontSize }}
          onClick={changeToTabOne}
          className={tabOneActive ? classes.activeTab : classes.tabTitle}
        >
          {tabOneTitle === 'Calendar' && (
            <CalendarTodayIcon className={classes.icon} />
          )}
          {tabOneTitle === 'Campaigns' && (
            <Dashboard className={classes.icon} />
          )}
          {tabOneTitle === 'Members' && <People className={classes.icon} />}
          {tabOneTitle}
        </Typography>
      )}

      {/* Tab2 Optional */}
      {(noDataNeeded || tabTwoData.length > 0) && (
        <Typography
          variant="body1"
          component="span"
          style={{ fontSize }}
          onClick={changeToTabTwo}
          className={
            tabOneActive || !tabTwoActive ? classes.tabTitle : classes.activeTab
          }
        >
          {tabTwoTitle === 'Invites' && <EmailIcon className={classes.icon} />}
          {tabTwoTitle === 'Influencers' && <People className={classes.icon} />}
          {tabTwoTitle === 'Members' && <People className={classes.icon} />}
          {tabTwoTitle}
        </Typography>
      )}

      {/* Tab3 Optional */}
      {noDataNeeded && tabThreeTitle && (
        <Typography
          variant="body1"
          component="span"
          style={{ fontSize }}
          onClick={changeToTabThree}
          className={
            tabOneActive || tabTwoActive ? classes.tabTitle : classes.activeTab
          }
        >
          {tabThreeTitle === 'Analytics' && (
            <BarChartIcon className={classes.icon} />
          )}
          {tabThreeTitle}
        </Typography>
      )}
    </div>
  );
};

Tabs.propTypes = {
  // tabOne and TwoData wont be needed, need to refactor
  tabOneData: PropTypes.arrayOf(PropTypes.any),
  tabTwoData: PropTypes.arrayOf(PropTypes.any),
  tabOneActive: PropTypes.bool,
  tabTwoActive: PropTypes.bool,
  tabOneTitle: PropTypes.string.isRequired,
  tabTwoTitle: PropTypes.string,
  tabThreeTitle: PropTypes.string,
  changeToTabOne: PropTypes.func.isRequired,
  changeToTabTwo: PropTypes.func.isRequired,
  noDataNeeded: PropTypes.bool,
  changeToTabThree: PropTypes.func,
  fontSize: PropTypes.string,
};

Tabs.defaultProps = {
  tabOneData: [],
  tabTwoData: [],
  tabOneActive: true,
  tabTwoActive: false,
  noDataNeeded: false,
  tabTwoTitle: null,
  tabThreeTitle: null,
  changeToTabThree: null,
  fontSize: '1rem',
};

export default Tabs;
