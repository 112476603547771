import {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';

import useNotification from './notification';
import useRoute from './route';
import API from '../utils/api';

const INITIAL_STATE = {
  isFetching: false,
  isError: false,
  isSuccess: false,
  request: null,
  response: null,
  status: null,
  data: null,
  error: null,
};

const apiReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'FETCHING':
      return {
        ...state,
        isFetching: true,
        isError: false,
        isSuccess: false,
        request: action.request,
        response: null,
        status: null,
        data: null,
        error: null,
      };
    case 'SUCCESS':
      return {
        ...state,
        isFetching: false,
        isError: false,
        isSuccess: true,
        request: action.request,
        response: action.response,
        status: action.response.status,
        data: action.response.data ? action.response.data.data : null,
        error: null,
      };
    case 'ERROR':
      return {
        ...state,
        isFetching: false,
        isError: true,
        isSuccess: false,
        request: action.request,
        response: action.error.response || null,
        status: action.error.response ? action.error.response.status : null,
        data: action.error.response && action.error.response.data ? action.error.response.data.data : null,
        error: action.error,
      };
    default:
      throw new Error(`Unhandled type: ${action.type}`);
  }
};

const useApi = (initialRequest) => {
  const notification = useNotification();
  const [route, setRoute] = useRoute();
  const savedMakeRequest = useRef();
  const [request, setRequest] = useState(initialRequest || null);
  const [state, dispatch] = useReducer(apiReducer, INITIAL_STATE);

  const makeRequest = useCallback(async () => {
    dispatch({ type: 'FETCHING', request });
    try {
      const response = await API(request);
      dispatch({ type: 'SUCCESS', request, response });
    } catch (error) {
      dispatch({ type: 'ERROR', request, error });
      if (!error.response) {
        // No response, must have been a network error
        notification.createNotification(
          'error',
          'Sorry, there is a problem with your connection.',
        );
      }
      if (error.response && error.response.status === 401) {
        // Could not refresh the session, redirect user to login page
        notification.createNotification(
          'error',
          'Sorry, your session has expired. Please log in again.',
        );
        setRoute({
          pathname: '/auth/login',
          params: {
            next: route.pathname,
          },
        });
      }
    }
  }, [notification, request, route, setRoute]);

  useEffect(() => {
    savedMakeRequest.current = makeRequest;
  }, [makeRequest]);

  useEffect(() => {
    if (request) {
      savedMakeRequest.current();
    }
  }, [request]);

  return [state, setRequest];
};

export default useApi;

export { API };
