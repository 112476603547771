import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import RouteContainer from './RouteContainer';
import { useApi, useAuth } from '../../hooks';

/**
 * An authenticated route requires that users be authenticated before the route will render its children. Note that
 * this route does not check whether a user is verified or not.
 *
 * If the user is not authenticated, they are redirected to the login page.
 */
const AuthenticatedRouteContainer = ({ children }) => {
  const [{ response }] = useApi({ method: 'get', url: '/auth/me/' });
  const auth = useAuth();

  useEffect(() => {
    if (!auth.user && response && response.status === 200) {
      auth.setUser(response.data.data);
    }
  }, [auth, response]);

  if (!auth.isAuthenticated) {
    // Render a blank page when not authenticated, the authentication check
    // will handle redirects
    return <div />;
  }

  return (
    <RouteContainer>
      {children}
    </RouteContainer>
  );
};

AuthenticatedRouteContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthenticatedRouteContainer;
