import React from 'react';

import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Typography,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import API from '../../utils/api';
import { useAuth, useNotification } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: 'max-content',
    padding: theme.spacing(2),
    borderRadius: 2,
    backgroundColor: theme.colors.warning.mid,
    position: 'relative',
    borderLeft: `4px solid ${theme.colors.primary.main}`,
    marginBottom: 40,
  },
  warning: {
    color: theme.colors.warning.main,
    position: 'absolute',
    left: '0.5rem',
    top: '0.3rem',
  },
  text: {
    color: theme.colors.primary.main,
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  text2: {
    color: theme.colors.primary.main,
  },
  btn: {
    marginLeft: '10rem',
    fontSize: '0.66rem',
    height: '2rem',
    margin: 'auto 0',
  },
}));

const DowngradeCancelStatus = (props) => {
  const {
    customer,
    refreshBilling,
    downgradeLoading,
  } = props;
  const classes = useStyles();
  const auth = useAuth();
  const { createNotification } = useNotification();

  const handleCancelDowngrade = async () => {
    downgradeLoading();
    const response = await API({
      method: 'post',
      url: `/account/${auth.account.id}/billing/cancel-downgrade/`,
    });
    if (response.status === 200) {
      refreshBilling();
    } else {
      createNotification('error', 'Could not Downgrade, Try again');
    }
  };

  return (
    <div className={classes.container}>
      <div>
        <Typography variant="body2" className={classes.text}>
          <strong>
            {`Your plan is scheduled to be downgraded to the Starter plan on ${moment.unix(customer.subscriptions.data[0].current_period_end).format('Do MMMM, YYYY')}`}
          </strong>
        </Typography>
        <Typography variant="body2" className={classes.text2}>
          Post downgrade of your plan, all influencer data and lists will be deleted
        </Typography>
      </div>
      <Button
        color="primary"
        variant="contained"
        className={classes.btn}
        onClick={handleCancelDowngrade}
      >
        Cancel Downgrade
      </Button>
    </div>
  );
};

DowngradeCancelStatus.propTypes = {
  customer: PropTypes.shape().isRequired,
  refreshBilling: PropTypes.func.isRequired,
  downgradeLoading: PropTypes.func.isRequired,
};

export default DowngradeCancelStatus;
