import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { colors } from '../definitions';
import { previewStyles } from '../form/styles';

const useStyles = makeStyles(() => ({
  highlighted: {
    border: `2px solid ${colors.BG_DARK}`,
  },
  root: {
    backgroundColor: colors.BG_LIGHT,
    display: 'flex',
    height: 260,
    marginTop: 20,
    '@media (max-width: 1280px)': {
      height: 220,
    },
  },
  col: {
    flex: 1,
    maxWidth: '50%',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  col2: {
    display: 'flex',
    flexDirection: 'column',
    padding: 15,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
  },
  container: {
    width: '125%',
    height: '96%',
    position: 'absolute',
    top: '2%',
    right: 0,
    backgroundColor: colors.SECONDARY,
    color: colors.BG_LIGHT,
  },
  wrapper1: {
    width: '100%',
    height: '50%',
    padding: 10,
  },
  wrapper2: {
    width: '100%',
    height: '50%',
    padding: 10,
    backgroundColor: colors.PRIMARY,
  },
  padding: {
    padding: 5,
    '@media (max-width: 1280px)': {
      padding: 2,
    },
  },
}));

const Page3Preview = ({ highlighted, finalData }) => {
  const classes = useStyles();
  const textStyles = previewStyles();

  const data = finalData.personalDetails;
  const instaHandle = data.instagramHandle;

  return (
    <div className={classes.root} id="page-3-preview">
      <div className={`${classes.col} ${highlighted === 'personalDetails.contactImg' ? classes.highlighted : ''}`}>
        <img
          src={data.contactImg}
          className={classes.image}
          alt="Background for Page 3"
        />
      </div>
      <div className={`${classes.col} ${classes.col2}`}>
        <div className={classes.header}>
          <div className={`${textStyles.body} ${classes.padding}`}>
            {data.firstName}
            &nbsp;
            {data.lastName}
          </div>
        </div>
        <div className={classes.main}>
          <div className={classes.container}>
            <div className={classes.wrapper1}>
              <div className={`${textStyles.h2} ${classes.padding}`}>Available For</div>
              <div
                className={`${textStyles.body} ${highlighted === 'personalDetails.availableFor' ? classes.highlighted : ''} ${classes.padding}`}
                style={{ whiteSpace: 'pre-line' }}
              >
                {data.availableFor}
              </div>
            </div>
            <div className={classes.wrapper2}>
              <div className={`${textStyles.h2} ${classes.padding}`}>Contact</div>
              <div
                className={`${textStyles.body} ${highlighted === 'personalDetails.email' ? classes.highlighted : ''} ${classes.padding}`}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <span style={{ fontWeight: 'bold' }}>Email:&nbsp;</span>
                {data.email}
              </div>
              <div
                className={`${textStyles.body} ${highlighted === 'personalDetails.mobile' ? classes.highlighted : ''} ${classes.padding}`}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <span style={{ fontWeight: 'bold' }}>Phone:&nbsp;</span>
                {data.mobile}
              </div>
              <div
                className={`${textStyles.body} ${highlighted === 'personalDetails.instagramHandle' ? classes.highlighted : ''} ${classes.padding}`}
                style={{ paddingTop: 0, paddingBottom: 0, marginBottom: 6 }}
              >
                <span style={{ fontWeight: 'bold' }}>IG Handle:&nbsp;</span>
                {instaHandle ? `@${instaHandle}` : ''}
              </div>
              <div
                className={`${textStyles.body} ${highlighted === 'personalDetails.shippingAddress' ? classes.highlighted : ''} ${classes.padding}`}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                Based in&nbsp;
                {data.shippingAddress}
              </div>
            </div>
          </div>
        </div>
        <div className={`${classes.header} ${classes.padding}`}>
          <div
            className={`${textStyles.body} ${classes.padding}`}
            style={{
              border: highlighted === 'personalDetails.websiteUrl' ? `2px solid ${colors.PRIMARY}` : '',
            }}
          >
            {instaHandle ? `@${instaHandle}` : ''}
          </div>
        </div>
      </div>
    </div>
  );
};

Page3Preview.propTypes = {
  highlighted: PropTypes.string,
  finalData: PropTypes.shape().isRequired,
};

Page3Preview.defaultProps = {
  highlighted: '',
};

export default Page3Preview;
