import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import {
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import AuthPageBase from '../AuthPageBase';
import { useApi, useRoute } from '../../../../hooks';

const useStyles = makeStyles(() => ({
  body: {
    textAlign: 'center',
  },
}));
const EmailVerificationConfirmPage = (props) => {
  const { token } = props;
  const [, setRoute] = useRoute();
  const [error, setError] = useState('');
  const classes = useStyles();
  const [{ isSuccess, isError }] = useApi({
    method: 'post',
    url: '/auth/email-verification/confirm/',
    data: {
      token,
    },
  });

  useEffect(() => {
    if (isSuccess) {
      setRoute({ pathname: '/auth/login/' });
    } else if (isError) {
      setError('Invalid verification token, please check the verification link.');
    }
  }, [isError, isSuccess, setRoute]);

  return (
    <AuthPageBase
      headerTitle="Verifying your email..."
      title="Verifying email"
    >
      <div className={classes.body}>
        {error && <Typography>{error}</Typography>}
        {!error && <CircularProgress />}
      </div>
    </AuthPageBase>
  );
};

EmailVerificationConfirmPage.propTypes = {
  token: PropTypes.string,
};

EmailVerificationConfirmPage.defaultProps = {
  token: null,
};

export default EmailVerificationConfirmPage;
