import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  TextField,
  Typography,
  Checkbox,
  Chip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClearIcon from '@material-ui/icons/Clear';

import { useApi } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  searchOptionSubtitle: {
    color: theme.colors.system.grey6,
    padding: '30px 0',
    display: 'flex',
    '&::after': {
      content: '""',
      flex: '1 1',
      borderBottom: `1px solid ${theme.colors.system.grey6}`,
      margin: 'auto',
      marginLeft: 15,
    },
  },
  defaultOptions: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  checkOption: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
  },
  locationChip: {
    padding: 5,
    height: 'auto',
    maxWidth: '100%',
    '&:not(:last-child)': {
      marginRight: 10,
    },
    marginTop: 10,
  },
}));

const parseLocationType = (location) => {
  switch (location.feature_class) {
    case 'L':
      return 'Global region';
    case 'A':
      if (location.feature_code === 'PCLI') {
        return 'Country';
      }
      if (location.feature_code === 'ADM1') {
        return 'State';
      }
      return 'Region';
    case 'P':
      return 'City/Town/Suburb';
    default:
      return null;
  }
};

const LocationOption = (props) => {
  const { location } = props;
  const locationType = parseLocationType(location);
  return (
    <div>
      <Typography variant="body1">{location.label}</Typography>
      {locationType && (
        <Typography color="textSecondary" variant="body2">{parseLocationType(location)}</Typography>
      )}
    </div>
  );
};

LocationOption.propTypes = {
  location: PropTypes.shape().isRequired,
};

const DefaultOption = ({
  item,
  checked,
  handleCheckboxOnChange,
  label,
}) => {
  const classes = useStyles();

  return (
    <div key={label} className={classes.checkOption}>
      <Checkbox
        checked={checked}
        onChange={(e) => handleCheckboxOnChange(item, e.target.checked)}
      />
      <Typography variant="body2">{label}</Typography>
    </div>
  );
};

DefaultOption.propTypes = {
  item: PropTypes.shape().isRequired,
  checked: PropTypes.bool,
  handleCheckboxOnChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

DefaultOption.defaultProps = {
  checked: false,
};

const LocationSelect = (props) => {
  const classes = useStyles();

  const {
    className,
    multiple,
    onChange,
    value,
    activeLocations,
  } = props;
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  const [selectedLocations, setSelectedLocations] = useState(activeLocations);

  const [{ data: defaultLocations }] = useApi({
    method: 'post',
    url: '/location/search/',
    data: {
      feature_code: ['CONT'],
      from: 0,
      size: 10,
    },
  });
  const [{ data, isFetching, status }, postSearchLocationRequest] = useApi();

  const defaultOptions = useMemo(() => (defaultLocations ? defaultLocations.locations : []), [defaultLocations]);

  useEffect(() => {
    if (inputValue) {
      postSearchLocationRequest({
        method: 'post',
        url: '/location/search/',
        data: {
          prefix: inputValue,
          from: 0,
          size: 10,
        },
      });
    } else {
      setOptions(defaultOptions || []);
    }
  }, [defaultOptions, inputValue, postSearchLocationRequest]);

  useEffect(() => {
    if (status === 200 && data) {
      setOptions(data.locations);
    }
  }, [data, status]);

  const handleChange = (event, newValue) => {
    if (newValue && selectedLocations.indexOf(newValue.label) === -1) {
      onChange([...selectedLocations, newValue]);
      setSelectedLocations([...selectedLocations, newValue]);
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleCheckboxChange = (location, checked) => {
    if (checked) {
      setSelectedLocations([...selectedLocations, location]);
      onChange([...selectedLocations, location]);
    } else {
      setSelectedLocations(selectedLocations.filter((item) => item.label !== location.label));
      onChange(selectedLocations.filter((item) => item.label !== location.label));
    }
  };

  const handleDeleteLabel = (location) => {
    setSelectedLocations(selectedLocations.filter((item) => item.label !== location.label));
    onChange(selectedLocations.filter((item) => item.label !== location.label));
  };

  return (
    <div className={className}>
      <Autocomplete
        key={selectedLocations}
        getOptionLabel={(option) => (_.isObject(option) ? option.label : option)}
        getOptionSelected={(option, v) => option.geoname_id === v.geoname_id}
        loading={isFetching}
        multiple={multiple}
        noOptionsText="No matching locations"
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={options}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            fullWidth
            placeholder="Search a country or city..."
          />
        )}
        renderOption={(option) => (
          <LocationOption location={option} />
        )}
        value={value ? value.label : ''}
      />

      {selectedLocations && (
        <div>
          {selectedLocations.map((item) => (
            <Chip
              label={item.label}
              onDelete={() => handleDeleteLabel(item)}
              color="primary"
              deleteIcon={<ClearIcon style={{ height: 15 }} />}
              className={classes.locationChip}
            />
          ))}
        </div>
      )}

      {defaultOptions && (
        <>
          <Typography
            className={classes.searchOptionSubtitle}
            component="span"
            variant="body2"
          >
            Or select from
          </Typography>

          <div className={classes.defaultOptions}>
            {defaultOptions.map((option) => (
              <DefaultOption
                key={option.geoname_id}
                label={option.label}
                item={option}
                handleCheckboxOnChange={handleCheckboxChange}
                checked={value && value.map((item) => item.label).indexOf(option.label) !== -1}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

LocationSelect.propTypes = {
  className: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.shape()),
  ]),
  activeLocations: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.shape()),
  ]),
};

LocationSelect.defaultProps = {
  className: '',
  multiple: false,
  value: null,
  activeLocations: [],
};

export default LocationSelect;
