// Brand Dashboard Campaign List
import React from 'react';
import PropTypes from 'prop-types';

import ClickList from '../common/ClickList';
import EmptyStateList from './EmptyStateList';
import { useContentManager } from '../../hooks';

const CampaignsList = ({ brand, setIsDialogOpen }) => {
  const content = useContentManager();

  return (
    <>
      {(brand && brand.campaigns.length > 0) || content.fetching ? (
        <ClickList
          title="Campaigns"
          setIsOpen={setIsDialogOpen}
          addNew="Campaign"
          items={brand.campaigns || []}
          dotPoint
          brand={brand || false}
        />
      ) : (
        <EmptyStateList
          title="Add your first campaign"
          body='Campaigns are a way to differentiate between initiatives of a brand. To add a campaign, click "Add A Campaign"'
          buttonLabel="+ Add a Campaign"
          handleClick={setIsDialogOpen}
          video="slxPFAJN9UM"
        />
      )}
    </>
  );
};

CampaignsList.propTypes = {
  setIsDialogOpen: PropTypes.func,
  brand: PropTypes.shape().isRequired,
};

CampaignsList.defaultProps = {
  setIsDialogOpen: null,
};

export default CampaignsList;
