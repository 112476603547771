import React from 'react';
import PropTypes from 'prop-types';

import {
  View,
  Text,
  StyleSheet,
} from '@react-pdf/renderer';

import { textStyles, colors } from '../definitions';
import { PdfPage, Header } from '../components';

const styles = StyleSheet.create({
  col1: {
    flex: 1,
    backgroundColor: colors.BG_DARK,
    padding: 50,
  },
  col2: {
    flex: 1.5,
    padding: 50,
  },
  main: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  flexGrid: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  flexCol1: {
    minWidth: '45%',
    paddingTop: 12.5,
    paddingBottom: 12.5,
  },
  flexCol2: {
    minWidth: '55%',
    paddingTop: 12.5,
    paddingBottom: 12.5,
  },
});

const Page7 = ({ formData }) => {
  const { testimonial } = formData.brandsAndTestimonials;
  const rows = [];
  let flexDirection = 'row';

  let temp = [];
  formData.brandsAndTestimonials.brands.forEach((brand, index) => {
    if (index === 0) {
      temp.push(brand);
    } else if (index % 2 !== 0) {
      temp.push(brand);
    } else {
      rows.push(temp);
      temp = [];
      temp.push(brand);
    }

    if (brand.brand && brand.brand.length > 15) {
      flexDirection = 'column';
    }
  });

  if (temp.length > 0) {
    rows.push(temp);
  }

  return (
    <PdfPage>
      <View style={styles.col1}>
        <Header align="left" variant="h5">Brands &amp; Testimonials</Header>
        <View style={styles.main}>
          <Text style={{ ...textStyles.h5, textDecoration: 'underline', marginBottom: 12.5 }}>Brand Collaborations With</Text>
          {rows.map((row) => (
            <View style={{ ...styles.flexGrid, flexDirection }} key={rows.indexOf(row)}>
              {row.map((brand, index) => (index % 2 === 0 ? (
                <Text style={{ ...textStyles.bodyLarge, ...styles.flexCol1 }} key={brand.id}>
                  {brand.brand}
                </Text>
              ) : (
                <Text style={{ ...textStyles.bodyLarge, ...styles.flexCol2 }} key={brand.id}>{brand.brand}</Text>
              )))}
            </View>
          ))}
        </View>
        <Header align="left">www.scrunch.com</Header>
      </View>
      <View style={styles.col2}>
        <Header alignItems="right">Danielle Lewis</Header>
        <View style={styles.main}>
          <Text style={{ ...textStyles.h3, marginBottom: 25 }}>
            “
            {testimonial.recommendation}
            ”
          </Text>
          <Text style={textStyles.h5}>
            -
            {' '}
            {testimonial.recommenderName}
            ,
            {' '}
            {testimonial.recommenderTitle}
            {' '}
            at
            {' '}
            {testimonial.brandName}
          </Text>
        </View>
        <Header alignItems="right">
          {formData.personalDetails.instagramHandle ? `@${formData.personalDetails.instagramHandle}` : ''}
        </Header>
      </View>
    </PdfPage>
  );
};

Page7.propTypes = {
  formData: PropTypes.shape().isRequired,
};

export default Page7;
