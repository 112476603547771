import React from 'react';

import { SvgIcon } from '@material-ui/core';

const University = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <path
      style={{ strokeWidth: 2.00038338 }}
      id="path4785"
      d="m 11.575339,4.0005934 c -0.10196,-0.0022 -0.203539,0.013 -0.300318,0.04513 L 0.6090007,7.6010594 C 0.245413,7.7217024 0,8.0616874 0,8.4447614 c 0,0.383073 0.245411,0.723078 0.6090007,0.843701 L 11.275021,12.843804 c 0.182535,0.06087 0.379913,0.06087 0.562448,0 l 9.49602,-3.1647466 v 5.2340636 c -0.529502,0.307779 -0.88897,0.874347 -0.88897,1.531153 0,0.982168 1.77774,3.555341 1.77774,3.555341 0,0 1.777741,-2.573173 1.777741,-3.555341 0,-0.656806 -0.359469,-1.223374 -0.88897,-1.531153 V 8.4899094 c 4e-4,-0.015 4e-4,-0.0301 0,-0.04515 0,-0.02432 -0.0012,-0.04865 -0.0034,-0.07291 0,-0.0052 0,-0.0104 0,-0.0156 -0.0014,-0.0146 -0.0032,-0.02897 -0.0052,-0.04339 -6e-4,-0.004 -0.0012,-0.008 -0.0018,-0.0122 -0.004,-0.02509 -0.0092,-0.04999 -0.0156,-0.07463 -0.08262,-0.344806 -0.36247,-0.607477 -0.711737,-0.668368 L 11.837489,4.0457304 c -0.08464,-0.02811 -0.172993,-0.04332 -0.26215,-0.04514 z M 5.3344024,12.736163 v 2.819281 c 0,0 1.4274736,1.77766 6.2218526,1.77766 4.794359,0 6.221832,-1.77766 6.221832,-1.77766 v -2.819281 l -5.37813,1.793304 c -0.273773,0.09066 -0.559287,0.137146 -0.843702,0.137146 -0.284435,0 -0.569949,-0.04649 -0.843702,-0.137146 z"
    />
  </SvgIcon>
);

export default University;
