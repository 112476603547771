import React from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  Avatar,
} from '@material-ui/core';

import viewPostStyles from '../../styles/viewPost';

const LeftMessage = ({ author, text, date }) => {
  const classes = viewPostStyles();

  return (
    <div className={classes.leftMsg}>
      <Avatar className={classes.leftAvatar} src="#" alt={author} />
      <div className={classes.leftMsgBox}>
        <Typography style={{ fontWeight: 'normal', fontSize: '13px' }}>
          {author}
        </Typography>
        <Typography style={{ fontWeight: 'normal', lineHeight: '1.5' }}>
          {text}
        </Typography>
        <Typography style={{ fontWeight: 'normal', marginBottom: 0 }}>
          {date}
        </Typography>
      </div>
    </div>
  );
};

const RightMessage = ({ author, text, date }) => {
  const classes = viewPostStyles();

  return (
    <div className={classes.rightMsg}>
      <div className={classes.rightMsgBox}>
        <Typography
          style={{ fontWeight: 'normal', fontSize: '14px' }}
        >
          {author}
        </Typography>
        <Typography
          style={{ fontWeight: 'normal', margin: '17px 0', letterSpacing: '1px' }}
        >
          {text}
        </Typography>
        <Typography
          style={{ fontWeight: 'normal', marginBottom: 0, color: 'lightgrey' }}
        >
          {date}
        </Typography>
      </div>
      <Avatar className={classes.rightAvatar} src="#" alt={author} />
    </div>

  );
};

LeftMessage.propTypes = {
  author: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

RightMessage.propTypes = {
  author: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export { LeftMessage, RightMessage };
