import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  NativeSelect,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  useAuth, useNotification, useRoute,
} from '../../hooks';
import API from '../../utils/api';

const useStyles = makeStyles((theme) => ({
  selectBox: {
    margin: theme.spacing(1),
    width: '10rem',
  },
}));

const Status = (props) => {
  const { profile, list } = props;
  const classes = useStyles();
  const auth = useAuth();
  const [route] = useRoute();
  const { createNotification } = useNotification();
  const statusArr = ['None',
    'Research',
    'Have Emailed',
    'Declined',
    'Negotiations',
    'Sent Product',
    'Product Received',
    'Content Approval',
    'Approved to Post',
    'Posted',
    'Insights Collected',
    'Complete'];
  const [whichStatus, setWhichStatus] = useState(list
    ? statusArr[list.items.find((x) => x.network_id === profile.networkId()).status_id]
    : statusArr[0]);
  const [isFetching, setIsFetching] = useState(false);

  const postToDB = useCallback(async () => {
    const response = await API({
      method: 'post',
      url: `/account/${auth.account.id}/profile/by-network-id/${profile.networkId()}/status/`,
      data: {
        status_id: statusArr.indexOf(whichStatus),
        list_id: route.pathname.split('/')[2],
      },
    });
    if (response.status === 200) {
      setIsFetching(false);
    } else {
      createNotification('error', 'Could not save Status.');
      setIsFetching(false);
    }
  }, [auth.account.id, createNotification, profile, statusArr, whichStatus, route.pathname]);

  useEffect(() => {
    if (isFetching) {
      postToDB();
    }
  }, [isFetching, postToDB]);

  const handleChange = async (event) => {
    event.preventDefault();
    setWhichStatus(event.target.value);
    setIsFetching(true);
  };

  return (
    <>
      <NativeSelect
        defaultValue={whichStatus}
        onChange={handleChange}
        className={classes.selectBox}
      >
        {statusArr.map((item) => (
          <option value={item}>{item}</option>
        ))}
      </NativeSelect>
    </>
  );
};

Status.propTypes = {
  profile: PropTypes.shape().isRequired,
  list: PropTypes.node,
};

Status.defaultProps = {
  list: null,
};

export default Status;
