import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';

import {
  Dialog,
  IconButton,
  Typography,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import LockOpenIcon from '@material-ui/icons/LockOpen';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  root: {
    width: '100%',
    padding: 40,
    backgroundColor: theme.colors.background.primary,
    minHeight: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heading: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginBottom: 40,
  },
  video: {
    width: '100%',
    '&>iframe': {
      width: '100%',
      height: 300,
      borderRadius: 8,
    },
  },
  text: {
    ...theme.textStyles.bodyMedium,
    color: theme.colors.system.grey7,
    textAlign: 'center',
    margin: '40px auto',
  },
  unlockBtn: {
    ...theme.textStyles.bodySmall,
    padding: '11px 50px',
  },
}));

const UnlockScrunchUniversityDialog = ({
  isDialogOpen,
  closeDialog,
  content,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={isDialogOpen}
      onBackdropClick={closeDialog}
      PaperProps={{ style: { padding: 0, width: '37%' } }}
    >
      <IconButton aria-label="close" className={classes.closeButton} onClick={closeDialog}>
        <CloseIcon />
      </IconButton>

      <div className={classes.root}>
        <div className={classes.heading}>
          <Typography variant="h5" component="h5">
            Welcome to&nbsp;
          </Typography>
          <Typography variant="h5" component="h5" color="primary">
            Scrunch University
          </Typography>
        </div>

        {content && content.youtube_video_id && (
          <div className={classes.video}>
            <iframe
              title={content.title}
              src={`https://www.youtube.com/embed/${content.youtube_video_id}`}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameBorder="0"
            />
          </div>
        )}

        <Typography variant="body1" className={classes.text}>
          Get access to powerful audience insights, hours of&nbsp;
          educational content, templates, cheatsheets and a&nbsp;
          library of Lightroom presets!
        </Typography>

        <Button
          color="primary"
          component={Link}
          startIcon={<LockOpenIcon style={{ fontSize: 14 }} />}
          to="/settings/plans"
          variant="contained"
          className={classes.unlockBtn}
        >
          Unlock Scrunch University
        </Button>
      </div>
    </Dialog>
  );
};

UnlockScrunchUniversityDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  content: PropTypes.shape(),
};

UnlockScrunchUniversityDialog.defaultProps = {
  content: null,
};

export default UnlockScrunchUniversityDialog;
