import React from 'react';

import { SvgIcon } from '@material-ui/core';

const Discover = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <path
      style={{ strokeWidth: 2.4000001 }}
      id="path2"
      d="m 7.999992,1.0000009 c -1.300776,0 -2.39844,0.839844 -2.812488,2.0000064 h -1.125 L 0.3437496,15.000007 C 0.1210939,15.625015 0,16.300783 0,16.999999 c 0,3.300792 2.699208,6 6,6 2.261712,0 4.257816,-1.265616 5.281248,-3.124992 0.222672,0.08594 0.464856,0.124992 0.718752,0.124992 0.25392,0 0.496104,-0.03905 0.718752,-0.124992 1.023432,1.859376 3.019536,3.124992 5.281248,3.124992 3.300792,0 6,-2.699208 6,-6 0,-1.093752 -0.292968,-2.113272 -0.812496,-3 L 19.937496,3.0000073 h -1.125 C 18.398448,1.8398449 17.300784,1.0000009 16.000008,1.0000009 c -1.300776,0 -2.39844,0.839844 -2.812512,2.0000064 H 10.812504 C 10.398432,1.8398449 9.300792,1.0000009 7.999992,1.0000009 Z M 6,12.999991 c 2.222664,0 4.000008,1.777368 4.000008,4.000008 0,2.222664 -1.777344,4.000008 -4.000008,4.000008 -2.222664,0 -4.0000008,-1.777344 -4.0000008,-4.000008 0,-2.22264 1.7773368,-4.000008 4.0000008,-4.000008 z m 12,0 c 2.222664,0 4.000008,1.777368 4.000008,4.000008 0,2.222664 -1.777344,4.000008 -4.000008,4.000008 -2.222664,0 -4.000008,-1.777344 -4.000008,-4.000008 0,-2.22264 1.777344,-4.000008 4.000008,-4.000008 z"
    />
  </SvgIcon>
);

export default Discover;
