/* eslint-disable no-use-before-define */
import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import MovieOutlinedIcon from '@material-ui/icons/MovieOutlined';
import LiveTvIcon from '@material-ui/icons/LiveTv';
// import { useNotification } from '../hooks';

import API from '../utils/api';

const status = [
  // 0
  {
    label: 'Draft Post',
    fontColor: '#E75041',
    bgColor: '#E7504120',
  },
  // 1
  {
    label: 'Internal Rework',
    fontColor: '#CB1A08',
    bgColor: '#CB1A0820',
  },
  // 2
  {
    label: 'Client Rework',
    fontColor: '#CB1A08',
    bgColor: '#CB1A0820',
  },
  // 3
  {
    label: 'Pending Client Approval',
    fontColor: '#F2994A',
    bgColor: '#F2994A20',
  },
  // 4
  {
    label: 'Approved By Client',
    fontColor: '#219653',
    bgColor: '#21965320',
  },
  // 5
  {
    label: 'Published',
    fontColor: '#219653',
    bgColor: '#21965320',
  },
  // 6
  {
    label: 'Pending Internal Approval',
    fontColor: '#F2994A',
    bgColor: '#F2994A20',
  },
];

const contentTypes = [
  {
    type: 'Feed Post',
    icon: <PhotoLibraryIcon />,
    pieColor: '#354065',
  },
  {
    type: 'Story',
    icon: <AddCircleOutlineIcon />,
    pieColor: '#A9B7E5',
  },
  {
    type: 'IGTV',
    icon: <LiveTvIcon />,
    pieColor: '#EFEEEE',
  },
  {
    type: 'Reel',
    icon: <MovieOutlinedIcon />,
    pieColor: '#E2E2E2',
  },
  {
    type: 'TickTok Video',
    icon: <PlayArrowIcon />,
    pieColor: 'pink',
  },
  {
    type: 'Facebook Post',
    icon: <PhotoLibraryIcon />,
    pieColor: 'lightblue',
  },
];

const ContentManagerContext = createContext({
  isNewBrand: true,
  isNewCampaign: true,
  isNewAllPost: true,
  isNewBrandPost: true,
  isNewCampaignPost: true,
  brand: null,
  brands: [],
  campaign: null,
  campaigns: [],
  posts: [],
  post: null,
  analtyics: null,
  fetching: false,
  status,
  contentTypes,
  setPost: () => {},
  getBrandsAndPosts: () => {},
  getBrandCampaignsPosts: () => {},
  getCampaignAndPosts: () => {},
  getCampaignAnalytics: () => {},
});

const ContentManagerStore = (props) => {
  const { children } = props;

  const getBrandsAndPosts = async (id) => {
    setState((state) => ({
      ...state,
      fetching: true,
    }));

    try {
      const response = await API({
        method: 'get',
        url: `/account/${id}/content-manager/`,
      });
      if (response.status === 200) {
        setState((state) => ({
          ...state,
          isNewBrand: false,
          isNewAllPost: false,
          brands: response.data.data.brands,
          // campaigns: response.data.data['campaigns'],
          posts: response.data.data.posts,
          fetching: false,
        }));
      }
    } catch (e) {
      // console.log('REQUEST TO /account/:id/content-manager/ FAILED');
      // alert(e);
      setState((state) => ({
        ...state,
        fetching: false,
      }));
    }
  };

  const getBrandCampaignsPosts = async (id, brandId) => {
    setState((state) => ({
      ...state,
      fetching: true,
    }));
    try {
      const response = await API({
        method: 'get',
        url: `/account/${id}/content-manager/brand/${brandId}/`,
      });
      if (response.status === 200) {
        setState((state) => ({
          ...state,
          isNewCampaign: false,
          isNewBrandPost: false,
          isNewCampaignPost: true,
          brand: response.data.data,
          campaigns: response.data.data.campaigns,
          posts: response.data.data.posts,
          fetching: false,
        }));
      }
    } catch (e) {
      /* console.log(
        'REQUEST TO /account/:id/content-manager/brand/:brandId/ FAILED',
      ); */
      setState((state) => ({
        ...state,
        fetching: false,
      }));
    }
  };

  const getCampaignAndPosts = async (id, brandId, campaignId) => {
    setState((state) => ({
      ...state,
      fetching: true,
    }));

    try {
      const response = await API({
        method: 'get',
        url: `/account/${id}/content-manager/brand/${brandId}/campaign/${campaignId}/`,
      });

      if (response.status === 200) {
        setState((state) => ({
          ...state,
          isNewCampaignPost: false,
          campaign: response.data.data,
          posts: response.data.data.posts,
          fetching: false,
          isNewBrandPost: true,
        }));
      }
    } catch (e) {
      /* console.log(
        'REQUEST TO /account/:id/content-manager/brand/:brandId/campaign/:campaignId/ FAILED',
      ); */
      setState((state) => ({
        ...state,
        fetching: false,
      }));
    }
  };

  const setPost = async (id, postId) => {
    setState((state) => ({
      ...state,
      // fetching: true,
    }));

    try {
      const response = await API({
        method: 'get',
        url: `/account/${id}/content-manager/post/${postId}/`,
      });
      if (response.status === 200) {
        setState((state) => ({
          ...state,
          post: response.data.data,
          // isNewCampaignPost: true,
          fetching: false,
        }));
      }
    } catch (e) {
      /* console.log(
        'REQUEST TO /account/:id/content-manager/brand/:brandId/campaign/:campaignId/post/$:postId/ FAILED',
      ); */
      // alert(e);
      setState((state) => ({
        ...state,
        fetching: false,
      }));
    }
  };

  const getCampaignAnalytics = async (id, brandId, campaignId) => {
    setState((state) => ({
      ...state,
      fetching: true,
    }));

    try {
      const response = await API({
        method: 'post',
        url: `/account/${id}/content-manager/brand/${brandId}/campaign_analytics/${campaignId}/`,
      });
      if (response.status === 200) {
        setState((state) => ({
          ...state,
          fetching: false,
          analytics: response.data.data,
        }));
      }
    } catch (e) {
      /* console.log(
        'REQUEST TO /account/:id/content-manager/brand/:brandId/campaign_analytics/:campaignId/ FAILED',
      ); */
      // alert(e);
      setState((state) => ({
        ...state,
        fetching: false,
      }));
    }
  };

  const [state, setState] = useState({
    isNewBrand: true,
    isNewCampaign: true,
    isNewAllPost: true,
    isNewBrandPost: true,
    isNewCampaignPost: true,
    brand: null,
    brands: [],
    campaign: null,
    campaigns: [],
    posts: [],
    post: null,
    analtyics: null,
    fetching: false,
    setPost,
    getBrandsAndPosts,
    getBrandCampaignsPosts,
    getCampaignAndPosts,
    getCampaignAnalytics,
    status,
    contentTypes,
  });

  return (
    <ContentManagerContext.Provider value={state}>
      {children}
    </ContentManagerContext.Provider>
  );
};

ContentManagerStore.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContentManagerContext;
export { ContentManagerStore };
