import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  runAnalysis: {
    margin: '2rem 0',
    padding: '15px 20px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme.colors.warning.mid,
    borderLeft: `6px solid ${theme.colors.primary.main}`,
    borderRadius: '2px',
  },
  analysisBtn: {
    borderRadius: 5,
    marginLeft: 20,
    height: '30px',
  },
  runText: {
    fontSize: '12px',
  },
  onProfilePage: {
    position: 'absolute',
    top: 0,
  },
}));

const RequestNotice = ({
  title, body, btnLabel, handleFunction, disabled, isProfilePage,
}) => {
  const classes = useStyles();

  return (
    <div className={isProfilePage
      ? `${classes.runAnalysis} ${classes.onProfilePage}`
      : classes.runAnalysis}
    >
      <div>
        <div
          className={classes.runText}
          style={{ fontFamily: 'Beatrice-Medium', color: '#933710' }}
        >
          {title}
        </div>
        <div
          className={classes.runText}
          style={{ fontFamily: 'Beatrice-Regular', color: '#933710' }}
        >
          {body}
        </div>
      </div>
      <Button
        className={classes.analysisBtn}
        color="primary"
        disabled={disabled}
        onClick={handleFunction}
        size="small"
        variant="contained"
      >
        {btnLabel}
      </Button>
    </div>
  );
};

RequestNotice.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  btnLabel: PropTypes.string.isRequired,
  handleFunction: PropTypes.func,
  disabled: PropTypes.bool,
  isProfilePage: PropTypes.bool,
};

RequestNotice.defaultProps = {
  disabled: false,
  isProfilePage: false,
  handleFunction: null,
};

export default RequestNotice;
