import React, { useState } from 'react';

import PropTypes from 'prop-types';

import {
  Typography,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { useAuth } from '../../hooks';

import AddProfilesToListDialog from './AddProfilesToListDialog';

const AddProfilesToListButton = ({ profiles, size }) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const auth = useAuth();
  const listPermission = auth.hasPermission('list.create');

  return (
    <>
      <Tooltip
        title={
          profiles.length
            ? `Add ${profiles.length} profile(s) to list`
            : 'Select profiles to add to a list'
        }
      >
        <span>
          <IconButton
            disabled={!listPermission || profiles.length === 0}
            onClick={() => setDialogIsOpen(true)}
            size={size}
          >
            <PlaylistAddIcon />
            <Typography> &nbsp; Add to list</Typography>
          </IconButton>
        </span>
      </Tooltip>
      {dialogIsOpen && (
        <AddProfilesToListDialog
          onClose={() => setDialogIsOpen(false)}
          profiles={profiles}
        />
      )}
    </>
  );
};

AddProfilesToListButton.propTypes = {
  profiles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  size: PropTypes.string,
};

AddProfilesToListButton.defaultProps = {
  size: 'medium',
};

export default AddProfilesToListButton;
