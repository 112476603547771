import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import WarningIcon from '@material-ui/icons/Warning';
import MenuPageBase, { Menu, MenuItem } from '../MenuPageBase';

import VerifiedProfileContent from './VerifiedProfileContent';
import {
  useAuth,
  useApi,
  useRoute,
  useNotification,
} from '../../../hooks';
import profiles from './profiles';
import API from '../../../utils/api';

const useStyles = makeStyles((theme) => ({
  successCheck: {
    color: theme.colors.success.main,
    fontSize: 15,
    marginRight: 8,
  },
  failedCheck: {
    color: theme.colors.warning.main,
    fontSize: 15,
    marginRight: 8,
  },
}));

const ClaimProfileModule = ({ networkName, networkId, claimType }) => {
  const classes = useStyles();
  const [route, setRoute] = useRoute();
  const auth = useAuth();
  const { createNotification } = useNotification();

  const mainNetworkData = profiles.find((network) => network.title.toUpperCase() === networkName.toUpperCase());

  if (!mainNetworkData) {
    setRoute({
      pathname: '/404',
    });
  }

  const [verifiedProfiles, setVerifiedProfiles] = useState(null);
  const [{ data: verifiedProfilesData }, postverifiedProfiles] = useApi({
    url: `/account/${auth.account.id}/profile/influencer/list/`,
    method: 'get',
  });

  useEffect(() => {
    if (verifiedProfilesData) {
      setVerifiedProfiles(verifiedProfilesData.reverse());
    }
  }, [verifiedProfilesData]);

  let profileData = {};
  if (verifiedProfiles) {
    profileData = verifiedProfiles.find((network) => network.network_id.toUpperCase() === networkId.toUpperCase());
  }

  const getNetworkIcon = (network) => {
    const selectedNetwork = profiles.find((n) => n.title.toUpperCase() === network.toUpperCase());
    return selectedNetwork.icon;
  };

  const getNetworkLabel = (networkIdentifier) => {
    const netId = networkIdentifier.split(':');
    return netId.length === 3 ? netId[2] : networkIdentifier;
  };

  // Verify Profile
  const [isAccountVerificationFetching, setIsAccountVerificationFetching] = useState(false);

  const verifyProfile = async () => {
    setIsAccountVerificationFetching(true);
    try {
      const response = await API({
        method: 'post',
        url: `/account/${auth.account.id}/profile/influencer/claim-verify/`,
        data: {
          network_handle: getNetworkLabel(profileData.network_id),
          network_type: mainNetworkData.title.toLowerCase(),
        },
      });
      postverifiedProfiles({
        url: `/account/${auth.account.id}/profile/influencer/list/`,
        method: 'get',
      });
      if (response.status >= 200 && response.status < 300 && claimType === 'claim') {
        setRoute({
          pathname: `/claim-profile/${profileData.network_type.toLowerCase()}/profile/verify/${profileData.network_id}`,
        });
      }
    } catch (err) {
      postverifiedProfiles({
        url: `/account/${auth.account.id}/profile/influencer/list/`,
        method: 'get',
      });
      createNotification('error', 'Unable to verify your profile. Please try again after some time.');
      setRoute({
        pathname: `/claim-profile/${profileData.network_type.toLowerCase()}/profile/verify/${profileData.network_id}`,
      });
    }
    setIsAccountVerificationFetching(false);
  };

  // Disconnect Profile
  const [isAccountDisconnectFetching, setIsAccountDisconnectFetching] = useState(false);

  const disconnectAccount = async (networkHandle, networkType) => {
    setIsAccountDisconnectFetching(true);
    try {
      const response = await API({
        method: 'post',
        url: `/account/${auth.account.id}/profile/influencer/disconnect-profile/`,
        data: {
          network_handle: networkHandle,
          network_type: networkType,
        },
      });
      if (response.status >= 200 && response.status < 300) {
        setRoute({ pathname: `/claim-profile/${networkType}` });
      } else {
        createNotification('error', 'Unable to discontinue your profile. Please try again after some time.');
      }
    } catch (err) {
      createNotification('error', 'Unable to discontinue your profile. Please try again after some time.');
    }
    setIsAccountDisconnectFetching(false);
  };

  // Restart Setup
  const restartSetup = async (networkHandle, networkType) => {
    setIsAccountDisconnectFetching(true);
    try {
      const response = await API({
        method: 'post',
        url: `/account/${auth.account.id}/profile/influencer/disconnect-profile/`,
        data: {
          network_handle: networkHandle,
          network_type: networkType,
        },
      });
      if (response.status >= 200 && response.status < 300) {
        setRoute({ pathname: `/claim-profile/${networkType}` });
      } else {
        createNotification('error', 'An Unexpected error occured. Please try again later.');
      }
    } catch (err) {
      createNotification('error', 'An Unexpected error occured. Please try again later.');
    }
    setIsAccountDisconnectFetching(false);
  };

  // Edit Profile
  const [isEditProfileFetching, setIsEditProfileFetching] = useState(false);

  const editProfile = async () => {
    setIsEditProfileFetching(true);
    try {
      const response = await API({
        method: 'get',
        // url: `/account/${auth.account.id}/profile/by-network-id/scrunchforinfluencers/`,
        url: `/account/${auth.account.id}/profile/by-network-id/${profileData.network_id}/`,
      });
      if (response.status === 200) {
        // console.log(response);
      } else {
        createNotification('error', 'Unable to locate your profile!');
      }
    } catch (err) {
      createNotification('error', 'Unable to locate your profile!');
    }
    setIsEditProfileFetching(false);
  };

  return (
    <MenuPageBase
      title="Claim Your Profiles"
      subtitle="Claim your profiles, start editting your details and get yourself noticed by campaign managers"
      showLoadingBar={isAccountVerificationFetching || isAccountDisconnectFetching || isEditProfileFetching}
      backLink={{
        link: '/',
        label: 'Go Back To Dashboard',
      }}
      menu={(
        <Menu>
          <MenuItem
            // Icon={it.icon}
            key={networkName}
            label={`Claim ${mainNetworkData.title} Profile`}
            onClick={() => setRoute({ pathname: `/claim-profile/${networkName}` })}
            selected={route.pathname === `/claim-profile/${networkName}`}
          />
          {verifiedProfiles && verifiedProfiles.map((it) => (
            <MenuItem
              Icon={getNetworkIcon(it.network_type)}
              key={it.network_id}
              label={getNetworkLabel(it.network_id)}
              onClick={() => setRoute({ pathname: `/claim-profile/${networkName}/profile/verify/${it.network_id}` })}
              selected={
                route.pathname === `/claim-profile/${networkName}/profile/claim/${it.network_id}`
                || route.pathname === `/claim-profile/${networkName}/profile/verify/${it.network_id}`
              }
              endIcon={it.is_approved !== 0 ? (
                <CheckCircleRoundedIcon className={classes.successCheck} />
              ) : (
                <WarningIcon className={classes.failedCheck} />
              )}
            />
          ))}
        </Menu>
      )}
    >
      {profileData && profileData.network_type && (
        <VerifiedProfileContent
          profileData={profileData}
          verifyProfile={verifyProfile}
          disconnectAccount={disconnectAccount}
          restartSetup={restartSetup}
          editProfile={editProfile}
          isFetching={isAccountVerificationFetching}
          claimType={claimType}
        />
      )}
    </MenuPageBase>
  );
};

ClaimProfileModule.propTypes = {
  networkName: PropTypes.string,
  networkId: PropTypes.string,
  claimType: PropTypes.string,
};

ClaimProfileModule.defaultProps = {
  networkName: 'instagram',
  networkId: '',
  claimType: 'claim',
};

export default ClaimProfileModule;
