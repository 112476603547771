import React, { useEffect, useState, useRef } from 'react';

import PropTypes from 'prop-types';

import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  ButtonBase,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import ProfileTablePagination from './ProfileTablePagination';
import ProfileTableRow from './ProfileTableRow';
import { useRoute } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: 'inherit',
    // transform: 'rotateX(-180deg)',
  },
  tableHeader: {
    backgroundColor: theme.colors.system.light,
    '& th:not(:first-child) > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  tableCellPagination: {
    borderBottom: 'none',
  },
  pageCont: {
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  tableWrapper: {
    overflow: 'auto',
    width: '100%',
    height: '100%',
    flex: 1,
    position: 'relative',
    // transform: 'rotateX(-180deg)',

  },
  scrollWrapper: {
    display: 'flex',
    flex: 1,
    width: '100%',
    height: '100%',
    overflowX: 'auto',
    position: 'relative',
    // transform: 'rotateX(-180deg)',
    '&:hover': {
      '& button ': {
        opacity: 1,
      },
    },
  },

  rightBtn: {
    backgroundImage: 'linear-gradient(to left, #00000030, #FFFFFF10)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 80,
    height: '100%',
    opacity: 0,
    transition: 'all 0.1s ease',
    position: 'absolute',
    right: 0,
  },
  leftBtn: {
    backgroundImage: 'linear-gradient(to right, #00000020, #FFFFFF10)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 80,
    height: '100%',
    opacity: 0,
    transition: 'all 0.1s ease',
    position: 'absolute',
    left: 0,
    zIndex: 1,
  },
  verticalCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& button': {
      padding: 0,
      '&:first-child': {
        marginBottom: '-0.6em',
      },
    },
  },
}));

const SelectAllCheckbox = ({ selectedProfiles, onChange }) => {
  const allSelected = selectedProfiles.every((it) => it);
  const someSelected = selectedProfiles.some((it) => it);

  return (
    <Tooltip title={allSelected ? 'Deselect all' : 'Select all'}>
      <Checkbox
        disabled={!selectedProfiles || selectedProfiles.length === 0}
        checked={allSelected}
        indeterminate={someSelected && !allSelected}
        onChange={onChange}
      />
    </Tooltip>
  );
};

SelectAllCheckbox.propTypes = {
  selectedProfiles: PropTypes.arrayOf(PropTypes.bool).isRequired,
  onChange: PropTypes.func.isRequired,
};

const ProfileTable = (props) => {
  const {
    profiles,
    ProfileTablePaginationProps,
    onProfileClick,
    onSelectedProfilesChange,
    showActions,
    showCheckbox,
    list,
    handleProfileSort,
  } = props;

  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const classes = useStyles();
  const [route] = useRoute();

  useEffect(() => {
    setSelectedProfiles(profiles.map(() => false));
  }, [profiles]);

  useEffect(() => {
    if (onSelectedProfilesChange) {
      onSelectedProfilesChange(profiles.filter((it, i) => selectedProfiles[i]));
    }
  }, [profiles, selectedProfiles, onSelectedProfilesChange]);

  const handleSelectAllOnChange = (event) => {
    setSelectedProfiles(selectedProfiles.map(() => event.target.checked));
  };

  const handleProfileSelectedChange = (event, profile, index) => {
    setSelectedProfiles(
      selectedProfiles.map((it, i) => (i === index ? event.target.checked : it)),
    );
  };

  const tableRef = useRef();

  return (
    <div className={classes.pageCont}>
      {ProfileTablePaginationProps && (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <ProfileTablePagination {...ProfileTablePaginationProps} />
      )}
      <div
        className={classes.scrollWrapper}
      >
        <ButtonBase
          className={classes.leftBtn}
          onClick={() => {
            tableRef.current.scrollTo({
              left: tableRef.current.scrollLeft - 700,
              behavior: 'smooth',
            });
          }}
        >
          <ChevronLeftIcon style={{ fontSize: 40 }} />
        </ButtonBase>
        <div
          className={classes.tableWrapper}
          ref={tableRef}
        >
          <Table className={classes.table}>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                {showCheckbox && (
                  <TableCell>
                    <SelectAllCheckbox
                      selectedProfiles={selectedProfiles}
                      onChange={handleSelectAllOnChange}
                    />
                  </TableCell>
                )}
                {showActions && (
                  <TableCell>Action</TableCell>
                )}
                <TableCell>
                  Profile
                </TableCell>
                <TableCell>Bio</TableCell>
                {/* {auth.hasPermission('profile.view_note') && (
                  <> */}
                <TableCell>Notes</TableCell>
                {route.pathname.split('/')[1] === 'list' && (
                <TableCell>Status</TableCell>
                )}
                {/* </>
                )} */}
                <TableCell>Social reach</TableCell>
                <TableCell>
                  <div>
                    Avg. engagement
                    <div className={classes.verticalCol}>
                      <IconButton onClick={() => handleProfileSort('engagement', 'asc')}><ArrowDropUp /></IconButton>
                      <IconButton onClick={() => handleProfileSort('engagement', 'desc')}><ArrowDropDown /></IconButton>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div>
                    Engagement
                    <div className={classes.verticalCol}>
                      <IconButton onClick={() => handleProfileSort('engagement_rate', 'asc')}><ArrowDropUp /></IconButton>
                      <IconButton onClick={() => handleProfileSort('engagement_rate', 'desc')}><ArrowDropDown /></IconButton>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div>
                    Video Views
                    <div className={classes.verticalCol}>
                      <IconButton onClick={() => handleProfileSort('video_views', 'asc')}><ArrowDropUp /></IconButton>
                      <IconButton onClick={() => handleProfileSort('video_views', 'desc')}><ArrowDropDown /></IconButton>
                    </div>
                  </div>
                </TableCell>
                <TableCell>Estimated cost per post</TableCell>
                <TableCell>Content</TableCell>
                <TableCell>Topics</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Audience locations</TableCell>
                <TableCell>Audience gender</TableCell>
                <TableCell>
                  <div>
                    Audience age
                    <div className={classes.verticalCol}>
                      <IconButton onClick={() => handleProfileSort('audience_age', 'asc')}><ArrowDropUp /></IconButton>
                      <IconButton onClick={() => handleProfileSort('audience_age', 'desc')}><ArrowDropDown /></IconButton>
                    </div>
                  </div>
                </TableCell>
                <TableCell>Audience hashtags</TableCell>
                <TableCell>Audience topics</TableCell>
                <TableCell>Audience mentions</TableCell>
                <TableCell>
                  <div>
                    Gender
                    <div className={classes.verticalCol}>
                      <IconButton onClick={() => handleProfileSort('gender', 'asc')}><ArrowDropUp /></IconButton>
                      <IconButton onClick={() => handleProfileSort('gender', 'desc')}><ArrowDropDown /></IconButton>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div>
                    Age Range
                    <div className={classes.verticalCol}>
                      <IconButton onClick={() => handleProfileSort('influencer_age', 'asc')}><ArrowDropUp /></IconButton>
                      <IconButton onClick={() => handleProfileSort('influencer_age', 'desc')}><ArrowDropDown /></IconButton>
                    </div>
                  </div>
                </TableCell>
                <TableCell>Ethnicity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {profiles.map((profile, index) => (
                <ProfileTableRow
                  checked={selectedProfiles[index] || false}
                  index={index}
                  key={profile.id()}
                  onCheckedChange={handleProfileSelectedChange}
                  onClick={onProfileClick}
                  profile={profile}
                  showActions={showActions}
                  showCheckbox={showCheckbox}
                  list={list}
                />
              ))}
            </TableBody>
          </Table>
        </div>
        <ButtonBase
          className={classes.rightBtn}
          onClick={() => {
            tableRef.current.scrollTo({
              left: tableRef.current.scrollLeft + 700,
              behavior: 'smooth',
            });
          }}
        >
          <ChevronRightIcon style={{ fontSize: 40 }} />
        </ButtonBase>

      </div>
    </div>
  );
};

ProfileTable.propTypes = {
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  ProfileTablePaginationProps: PropTypes.shape(),
  showActions: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  onProfileClick: PropTypes.func,
  onSelectedProfilesChange: PropTypes.func,
  list: PropTypes.node,
  handleProfileSort: PropTypes.func,
};

ProfileTable.defaultProps = {
  ProfileTablePaginationProps: null,
  showActions: true,
  showCheckbox: true,
  onProfileClick: null,
  onSelectedProfilesChange: null,
  list: null,
  handleProfileSort: null,
};

export default ProfileTable;
