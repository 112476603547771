import React from 'react';
import PropTypes from 'prop-types';

import SparkAndSuModules from '../../scrunch-university/SparkAndSuModules';

const ScrunchUniversityModulePage = ({ moduleId, lessonId }) => (
  <SparkAndSuModules
    path="scrunch-university"
    title="Scrunch University"
    moduleId={moduleId}
    lessonId={lessonId}
  />
);

ScrunchUniversityModulePage.propTypes = {
  moduleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lessonId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ScrunchUniversityModulePage.defaultProps = {
  moduleId: null,
  lessonId: null,
};

export default ScrunchUniversityModulePage;
