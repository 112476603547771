/* eslint-disable no-use-before-define */
import React, { createContext, useState } from 'react';

import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';

const hasPermission = (user, permission) => {
  if (!user) {
    return false;
  }
  const rolePermissions = user.roles.map((role) => role.permissions);
  return rolePermissions.some((permissions) => permissions.includes(permission));
};

/**
 * Authentication context stores information about the currently logged in
 * user.
 * See the AuthenticationRoute for how this gets set.
 */

const AuthContext = createContext({
  user: null,
  account: null,
  isAuthenticated: false,
  isVerified: false,
  hasPermission: () => false,
  setUser: () => {},
  clearUser: () => {},
  changeAccount: () => {},
});

const AuthStore = (props) => {
  const { children } = props;

  const setUser = (user) => {
    Sentry.setUser(user);
    setState({
      ...state,
      user,
      account: user && user.roles ? user.roles[0].account : null,
      isAuthenticated: Boolean(user && user.email),
      isVerified: Boolean(user && user.email_verification_verified_at),
      hasPermission: (permission) => hasPermission(user, permission),
    });
  };

  const clearUser = () => {
    setState({
      ...state,
      user: null,
      account: null,
      isAuthenticated: false,
      isVerified: false,
      hasPermission: () => false,
    });
  };

  const changeAccount = (accountId) => {
    if (!state || !state.user) {
      return;
    }
    const role = state.user.roles.find((it) => it.account.id === accountId);

    setState({
      ...state,
      account: role ? role.account : null,
    });
  };

  const [state, setState] = useState({
    user: null,
    account: null,
    isAuthenticated: false,
    isVerified: false,
    hasPermission: () => false,
    setUser,
    clearUser,
    changeAccount,
  });

  return (
    <AuthContext.Provider value={state}>
      {children}
    </AuthContext.Provider>
  );
};

AuthStore.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
export { AuthStore };
