import React from 'react';
import PropTypes from 'prop-types';

import {
  View,
  Text,
  Image,
  StyleSheet,
} from '@react-pdf/renderer';

import { textStyles, colors } from '../definitions';
import { PdfPage, Header, InfoCard } from '../components';

const styles = StyleSheet.create({
  col: {
    flex: 1,
    maxWidth: '50%',
  },
  main: {
    flex: 1,
    position: 'relative',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    flex: 1,
    padding: 50,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  imgWrapper: {
    width: '100%',
    height: '100%',
    padding: 0,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
});

const Page3 = ({ formData }) => {
  /* const paragraphs = [
    'Stylist',
    'Fashion Design Consultant',
    'Speaking Events',
    'Ambassadorship',
    'Social Media Content & Collaborations',
    'Product Reviews',
  ]; */
  const paragraphs = formData.personalDetails.availableFor.split(/\r?\n/);

  return (
    <PdfPage>
      <View style={styles.col}>
        <View style={styles.imgWrapper}>
          <Image src={formData.personalDetails.contactImg} style={styles.image} />
        </View>
      </View>
      <View style={{ ...styles.col, padding: 50 }}>
        <Header align="right">
          {formData.personalDetails.firstName}
          {' '}
          {formData.personalDetails.lastName}
        </Header>
        <View style={styles.main}>
          <InfoCard style={{ padding: 0 }}>
            <View style={styles.card}>
              <Text style={{ ...textStyles.h2, marginBottom: 20 }}>Available For</Text>
              {paragraphs && paragraphs.map((para) => (
                <Text style={textStyles.body} key={para.substring(0, 10)}>{para}</Text>
              ))}
            </View>
            <View style={{ ...styles.card, backgroundColor: colors.PRIMARY }}>
              <Text style={{ ...textStyles.h2, marginBottom: 20 }}>Contact</Text>
              <View style={styles.row}>
                <Text style={textStyles.bodyBold}>Email: </Text>
                <Text style={textStyles.body}>{formData.personalDetails.email}</Text>
              </View>
              <View style={styles.row}>
                <Text style={textStyles.bodyBold}>Phone: </Text>
                <Text style={textStyles.body}>{formData.personalDetails.mobile}</Text>
              </View>
              <View style={styles.row}>
                <Text style={textStyles.bodyBold}>IG Handle: </Text>
                <Text style={textStyles.body}>{formData.personalDetails.instagramHandle}</Text>
              </View>
              <View style={{ marginTop: 20 }}>
                <Text style={textStyles.body}>
                  Based in
                  {formData.personalDetails.shippingAddress}
                </Text>
              </View>
            </View>
          </InfoCard>
        </View>
        <Header align="right">
          {formData.personalDetails.instagramHandle ? `@${formData.personalDetails.instagramHandle}` : ''}
        </Header>
      </View>
    </PdfPage>
  );
};

Page3.propTypes = {
  formData: PropTypes.shape().isRequired,
};

export default Page3;
