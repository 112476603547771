import React, { useEffect, useState } from 'react';

import {
  Button,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from '@reach/router';

import AuthPageBase from './AuthPageBase';
import { Alert, PasswordTextField } from '../../common';
import { useApi, useRoute } from '../../../hooks';
import { isValidEmail } from '../../../utils/string';

const useStyles = makeStyles((theme) => ({
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  error: {
    margin: theme.spacing(1),
  },
  field: {
    width: '100%',
    margin: theme.spacing(1),
  },
  resetPassword: {
    fontSize: '0.8em',
    marginLeft: 'auto',
  },
  button: {
    width: '100%',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
  },
  footer: {
    paddingTop: theme.spacing(3),
    padding: theme.spacing(1),
    textAlign: 'center',
  },
}));

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loginError, setLoginError] = useState('');
  const [route, setRoute] = useRoute();
  const classes = useStyles();
  const [{ response, isFetching }, setRequest] = useApi();

  async function handleSubmit(e) {
    e.preventDefault();

    setEmailError(!email ? 'Email is required.' : '');
    setEmailError(email && !isValidEmail(email) ? 'Not a valid email.' : '');
    setPasswordError(!password ? 'Password is required.' : '');
    setLoginError('');

    if (email && isValidEmail(email) && password) {
      setRequest({
        method: 'post',
        url: '/auth/login/',
        data: {
          email,
          password,
        },
      });
    }
  }

  useEffect(() => {
    if (!response) {
      return;
    }

    if (response.status === 200) {
      setRoute({ pathname: route.params.next || '/' });
    } else if (response.status === 400) {
      setLoginError(response.data.message);
    }
  }, [response, route, setRoute]);

  return (
    <AuthPageBase
      headerTitle="World's #1 Influencer Marketing Platform"
      headerSubtitle="Welcome back babe, please log in to your account"
      showLoadingBar={isFetching}
      title="Login"
    >
      {loginError && (
        <div className={classes.row}>
          <Alert className={classes.error} severity="error">
            {loginError}
          </Alert>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className={classes.row}>
          <TextField
            className={classes.field}
            type="email"
            label="Email"
            required
            error={Boolean(emailError || loginError)}
            helperText={emailError}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className={classes.row}>
          <PasswordTextField
            className={classes.field}
            label="Password"
            required
            error={Boolean(passwordError || loginError)}
            helperText={passwordError}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className={classes.row}>
          <div className={classes.resetPassword}>
            <Link component={RouterLink} to="/auth/password-reset">
              Forgot password?
            </Link>
          </div>
        </div>
        <Button
          className={classes.button}
          type="submit"
          variant="contained"
          color="primary"
          disabled={isFetching}
          onClick={handleSubmit}
        >
          Login
        </Button>
      </form>
      <div className={classes.footer}>
        <Typography variant="body2">
          {"Don't have an account yet? "}
        </Typography>
        <Link component={RouterLink} to="/auth/signup">
          Sign up here.
        </Link>
      </div>
    </AuthPageBase>
  );
};

export default LoginPage;
