import React from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  LinearProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '28px',
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  subTitle: {
    width: '80%',
    color: theme.colors.system.grey7,
    margin: '15px auto 0',
    fontSize: '14px',
    lineHeight: '24px',
  },
  deleteDialogBtn: {
    backgroundColor: theme.colors.primary.main,
    color: '#FFFFFF',
    minWidth: 240,
    minHeight: 40,
    ...theme.textStyles.bodySmall,
  },
  dialogCont: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

// Dialog Modal with close button, title, subtitle(not needed) and children

const ScrDialog = (props) => {
  const {
    isOpen,
    onClose,
    title,
    subTitle,
    titleStyle,
    children,
    isFetching,
    minWidth,
    maxWidth,
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onBackdropClick={onClose}
      PaperProps={{
        style: {
          minWidth,
          maxWidth,
        },
      }}
    >
      {isFetching && (
      <LinearProgress
        style={{
          width: '100rem',
          transform: 'translate(-3rem, -2rem)',
        }}
      />
      )}
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>

      <DialogTitle style={titleStyle}>
        <Typography className={classes.title} variant="h5" component="h5">
          {title}
        </Typography>
        <Typography
          variant="body2"
          component="p"
          className={classes.subTitle}
        >
          {subTitle}
        </Typography>
      </DialogTitle>

      {children}

    </Dialog>
  );
};

ScrDialog.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  titleStyle: PropTypes.shape(),
  isFetching: PropTypes.bool,
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  isOpen: PropTypes.bool,
};

ScrDialog.defaultProps = {
  titleStyle: null,
  subTitle: null,
  isFetching: false,
  minWidth: 800,
  maxWidth: 800,
  isOpen: false,
  onClose: null,
};

export default ScrDialog;
