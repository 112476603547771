import React, { useState } from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ViewPost from './ViewPost';
import { useContentManager, useAuth } from '../../hooks';
import { formatDate } from '../../utils/datetime';

import PostItem from './PostItem';

const useStyles = makeStyles((theme) => ({
  mainCont: {
    backgroundColor: theme.colors.system.grey1,
    borderRadius: '4px',
    padding: '1rem',
    border: `1px solid ${theme.colors.system.grey2}`,
  },
  mainHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  noPosts: {
    color: theme.colors.system.grey6,
    marginTop: '30px',
  },
}));

const TodaysPosts = () => {
  const classes = useStyles();
  const content = useContentManager();
  const auth = useAuth();
  const [isViewPost, setIsViewPost] = useState(false);

  const isClient = auth.user.agency_role === 'client';
  const profilesAccess = JSON.parse(auth.user.brand_profiles_access);

  const posts = () => {
    if (content.posts.length > 0) {
      if (isClient) {
        return content.posts.filter((post) => profilesAccess.includes(post.brand));
      }
      return content.posts.filter((post) => formatDate(post.post_datetime).includes('Today'));
    }
    return false;
  };

  const handleClose = (callback) => {
    setIsViewPost(false);
    if (callback) {
      callback();
    }
    // content.getBrandsAndPosts(auth.account.id);
  };

  const handleClickOnView = (post) => {
    content.setPost(auth.account.id, post.id);
    setIsViewPost(true);
  };

  return (
    <>
      <ViewPost isOpen={isViewPost} closeDialog={handleClose} />
      <div className={classes.mainCont}>
        <div className={classes.mainHeader}>
          <Typography variant="h6">Today&apos;s Posts</Typography>
        </div>
        {posts().length < 1 && (
          <Typography className={classes.noPosts}>
            No post&apos;s are scheduled for today.
          </Typography>
        )}
        {posts()
          && posts().map((post) => (
            <PostItem
              img={post.img_url && post.img_url[0]}
              contentType={post.content_type}
              brandImg={post.brand}
              brandName={post.brand}
              postStatus={post.status}
              text={post.text}
              date={post.post_datetime}
              key={post.text}
              handleClick={() => handleClickOnView(post)}
            />
          ))}
      </div>
    </>
  );
};

export default TodaysPosts;
