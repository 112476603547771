import numeral from 'numeral';

const formatNumber = (number) => {
  if (number === undefined || number === null) {
    return null;
  }

  if (number > -1000 && number < 1000) {
    return number;
  }

  return numeral(number).format('0.[0]a');
};

const formatPercentage = (number) => {
  if (number === undefined || number === null) {
    return null;
  }

  return numeral(number).format('0.[0]%');
};

export { formatNumber, formatPercentage };
