import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  routeChildrenContainer: {
    width: '100%',
    height: '100%',
    '&>*': {
      width: '100%',
      height: '100%',
    },
  },
}));

/**
 * Generic route container to organise child routes.
 */
const RouteContainer = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.routeChildrenContainer}>
      {children}
    </div>
  );
};

RouteContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RouteContainer;
