import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  Avatar,
  Checkbox,
  Chip,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';

import AccessLockTooltip from '../common/AccessLockTooltip';
import FilterLabelChip from '../common/FilterLabelChip';
import NetworkChip from '../common/NetworkChip';
import NetworkIcon from '../common/icons/NetworkIcon';
import AddToListButton from '../profile/AddToListButton';
import EditableNoteContent from '../profile/EditableNoteContent';
import MailToButton from '../profile/MailToButton';
import Status from '../profile/Status';
import { useAuth, useRoute } from '../../hooks';
import { formatNumber, formatPercentage } from '../../utils/number';
import { getCostPerPost, getGenderLabel } from '../../utils/profile';
import { ellipsize } from '../../utils/string';

const useStyles = makeStyles((theme) => ({
  clickableCell: {
    cursor: (props) => (props.onClick ? 'pointer !important' : 'default'),
    '&>*': {
      cursor: (props) => (props.onClick ? 'pointer !important' : 'default'),
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
  },
  profile: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    gridTemplateColumns: 'auto 1fr',
    gridTemplateAreas: `
      "profileAvatar profileName"
      "profileAvatar profileHandles"
    `,
    gridGap: 4,
    alignItems: 'center',
  },
  profileAvatar: {
    gridArea: 'profileAvatar',
    width: 45,
    height: 45,
  },
  profileName: {
    gridArea: 'profileName',
  },
  profileHandles: {
    gridArea: 'profileHandles',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    '&>:not(last-child)': {
      marginBottom: 3,
    },
  },
  attributes: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    flexWrap: 'wrap',
    '&>*': {
      marginBottom: 5,
    },
    '&>:not(:last-child)': {
      marginRight: 5,
    },
  },
  stats: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    '&>:not(:last-child)': {
      marginBottom: 8,
    },
  },
  content: {
    display: 'grid',
    gridGap: 5,
    gridTemplateColumns: '50px 50px',
    gridTemplateRows: '50px 50px',
  },
  contentItem: {
    width: 50,
    height: 50,
    borderRadius: 5,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  roundedChip: {
    borderRadius: 6,
  },
  lightChip: {
    backgroundColor: 'transparent',
  },
  highlightedChip: {
    backgroundColor: '#354065',
    color: theme.colors.system.light,
  },
  notes: {
    fontStyle: 'italic',
    color: theme.colors.system.grey7,
  },
  noteCell: {
    maxHeight: '10rem',
    width: '10rem',
  },

}));

const HandleChip = withStyles((theme) => ({
  label: {
    color: theme.colors.system.grey8,
  },
  labelSmall: {
    fontSize: 12,
  },
}))(Chip);

const ProfileTableRow = (props) => {
  const {
    checked,
    index,
    onClick,
    onCheckedChange,
    profile,
    showActions,
    showCheckbox,
    list,
  } = props;
  const classes = useStyles(props);
  const auth = useAuth();
  const [route] = useRoute();

  const content = profile.content();

  const age = profile.age();
  const gender = profile.gender();
  const location = profile.locationCountry();
  const topics = profile.topics();
  const audience = profile.audience();
  const audienceAge = profile.audienceAge();
  const audienceGender = profile.audienceGender();
  const audienceHashtags = profile.audienceHashtags();
  const audienceMentions = profile.audienceMentions();
  const audiencePermission = auth.hasPermission('profile.view_audience');
  const notePermission = auth.hasPermission('profile.edit_note');

  const handleClick = useCallback(
    onClick ? (event) => onClick(event, profile, index) : null,
    [index, onClick, profile],
  );

  const handleCheckedChange = (event) => {
    if (onCheckedChange) {
      onCheckedChange(event, profile, index);
    }
  };

  return (
    <TableRow>
      {showCheckbox && (
        <TableCell>
          <Checkbox
            checked={checked}
            onChange={handleCheckedChange}
          />
        </TableCell>
      )}
      {showActions && (
        <TableCell className={classes.actionsCell}>
          <div className={classes.actions}>
            <AddToListButton profile={profile} />
            <MailToButton profile={profile} />
          </div>
        </TableCell>
      )}
      <TableCell className={classes.clickableCell} onClick={handleClick}>
        <div className={classes.profile}>
          <Avatar
            alt={profile.fullName()}
            className={classes.profileAvatar}
            src={profile.profilePicture()}
          />
          <Typography className={classes.profileName} variant="body1">{profile.fullName() || profile.handle()}</Typography>
          <div className={classes.profileHandles}>
            {profile.data.network_profiles.map((it) => (
              <HandleChip
                key={it.network}
                icon={<NetworkIcon network={it.network} />}
                label={it.handle || it.full_name}
                size="small"
              />
            ))}
          </div>
        </div>
      </TableCell>
      <TableCell className={classes.clickableCell} onClick={handleClick} style={{ minWidth: 280 }}>
        <div className={classes.attributes}>
          {gender && <Chip label={getGenderLabel(gender)} size="small" />}
          {location && <Chip label={location} size="small" />}
          {age && <Chip label={age} size="small" />}
        </div>
        <Typography variant="body2">{ellipsize(profile.biography(), 200)}</Typography>
      </TableCell>

      <TableCell>
        {!notePermission && (
          <div className={classes.attributes} style={{ alignItems: 'center', justifyContent: 'center' }}>
            <AccessLockTooltip
              title="Notes"
            >
              Upgrade to our&nbsp;
              <Typography variant="body2" component="span" color="primary">
                Basic Plan or above
              </Typography>
              &nbsp;to unlock this feature
            </AccessLockTooltip>
          </div>
        )}
        <EditableNoteContent profile={profile} />
      </TableCell>
      {route.pathname.split('/')[1] === 'list' && (
        <TableCell>
          <Status profile={profile} profileIndex={index} list={list} />
        </TableCell>
      )}

      <TableCell className={classes.clickableCell} onClick={handleClick}>
        <div className={classes.stats}>
          {profile.data.network_profiles.filter((it) => it.stats.social_reach).map((it) => (
            <NetworkChip
              key={it.network}
              network={it.network}
              label={formatNumber(it.stats.social_reach)}
            />
          ))}
        </div>
      </TableCell>
      <TableCell className={classes.clickableCell} onClick={handleClick}>
        <div className={classes.stats}>
          {profile.data.network_profiles.filter((it) => it.stats.engagement).map((it) => (
            <NetworkChip
              key={it.network}
              network={it.network}
              label={formatNumber(it.stats.engagement)}
            />
          ))}
        </div>
      </TableCell>
      <TableCell className={classes.clickableCell} onClick={handleClick}>
        <div className={classes.stats}>
          {profile.data.network_profiles.filter((it) => it.stats.engagement_rate).map((it) => (
            <NetworkChip
              key={it.network}
              network={it.network}
              label={formatPercentage(it.stats.engagement_rate)}
            />
          ))}
        </div>
      </TableCell>
      <TableCell className={classes.clickableCell} onClick={handleClick}>
        <div className={classes.stats}>
          {profile.data.network_profiles.filter((it) => it.stats.video_views).map((it) => (
            <NetworkChip
              key={it.network}
              network={it.network}
              label={it.stats.video_views ? formatNumber(it.stats.video_views) : '0'}
            />
          ))}
        </div>
      </TableCell>
      <TableCell className={classes.clickableCell} onClick={handleClick}>
        <div className={classes.stats}>
          {profile.data.network_profiles.filter((it) => it.stats.social_reach).map((it) => {
            const costPerPost = getCostPerPost(it.network, it.stats.social_reach);
            if (!costPerPost) {
              return null;
            }
            return (
              <FilterLabelChip
                key={it.network}
                network={it.network}
                label={costPerPost}
              />
            );
          })}
        </div>
      </TableCell>
      <TableCell className={classes.clickableCell} onClick={handleClick}>
        <div className={classes.content}>
          {content.filter((it) => it.image_url).slice(0, 4).map((it) => (
            <div key={it.image_url} className={classes.contentItem} style={{ backgroundImage: `url("${it.image_url}")` }} />
          ))}
        </div>
      </TableCell>
      <TableCell className={classes.clickableCell} onClick={handleClick} style={{ minWidth: 250 }}>
        <div className={classes.attributes}>
          {topics && topics.map((topic, itemIndex) => (
            <Chip key={topic} label={topic} size="small" className={`${classes.roundedChip} ${itemIndex < 2 ? classes.highlightedChip : ''}`} />
          ))}
        </div>
      </TableCell>
      <TableCell className={classes.clickableCell} onClick={handleClick}>
        <div className={classes.attributes}>
          {location && (
            <Chip label={location} size="small" icon={<LocationOnOutlinedIcon />} className={classes.lightChip} />
          )}
        </div>
      </TableCell>

      <TableCell className={classes.clickableCell} onClick={handleClick}>
        {audiencePermission && (
          <div className={classes.attributes} style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
            {audience
              && audience.location
              && audience.location.country
              && audience.location.country.majority
              && audience.location.country.majority.slice(0, 3).map((loc) => (
                <Chip
                  key={loc.label}
                  label={loc.label}
                  size="small"
                  icon={<PeopleOutlineOutlinedIcon />}
                  className={classes.lightChip}
                />
              ))}
          </div>
        )}
        {!audiencePermission && (
          <div className={classes.attributes} style={{ alignItems: 'center', justifyContent: 'center' }}>
            <AccessLockTooltip
              title="Audience locations"
            >
              Upgrade to our&nbsp;
              <Typography variant="body2" component="span" color="primary">
                Power Plan or above
              </Typography>
              &nbsp;to unlock this feature
            </AccessLockTooltip>
          </div>
        )}
      </TableCell>

      <TableCell className={classes.clickableCell} onClick={handleClick}>
        {audiencePermission && (
          <div className={classes.attributes}>
            {audienceGender && (
              <Chip
                label={`Mostly ${getGenderLabel(audienceGender).toLowerCase()}`}
                size="small"
                icon={<AccessibilityNewIcon />}
                className={classes.lightChip}
                style={{ textTransform: 'capitalize' }}
              />
            )}
          </div>
        )}
        {!audiencePermission && (
          <div className={classes.attributes} style={{ alignItems: 'center', justifyContent: 'center' }}>
            <AccessLockTooltip
              title="Audience locations"
            >
              Upgrade to our&nbsp;
              <Typography variant="body2" component="span" color="primary">
                Power Plan or above
              </Typography>
              &nbsp;to unlock this feature
            </AccessLockTooltip>
          </div>
        )}
      </TableCell>

      <TableCell className={classes.clickableCell} onClick={handleClick}>
        {audiencePermission && (
          <div className={classes.attributes}>
            {audienceAge && <Chip label={`Mostly ${audienceAge}`} size="small" className={classes.lightChip} />}
          </div>
        )}
        {!audiencePermission && (
          <div className={classes.attributes} style={{ alignItems: 'center', justifyContent: 'center' }}>
            <AccessLockTooltip
              title="Audience locations"
            >
              Upgrade to our&nbsp;
              <Typography variant="body2" component="span" color="primary">
                Power Plan or above
              </Typography>
              &nbsp;to unlock this feature
            </AccessLockTooltip>
          </div>
        )}
      </TableCell>

      <TableCell className={classes.clickableCell} onClick={handleClick} style={{ minWidth: 300 }}>
        {audiencePermission && (
          <div className={classes.attributes}>
            {audienceHashtags && audienceHashtags.slice(0, 7).map((hashtag, itemIndex) => (
              <Chip
                key={hashtag}
                label={hashtag}
                size="small"
                className={`${classes.roundedChip} ${itemIndex < 2 ? classes.highlightedChip : ''}`}
              />
            ))}
          </div>
        )}
        {!audiencePermission && (
          <div className={classes.attributes} style={{ alignItems: 'center', justifyContent: 'center' }}>
            <AccessLockTooltip
              title="Audience locations"
            >
              Upgrade to our&nbsp;
              <Typography variant="body2" component="span" color="primary">
                Power Plan or above
              </Typography>
              &nbsp;to unlock this feature
            </AccessLockTooltip>
          </div>
        )}
      </TableCell>

      <TableCell className={classes.clickableCell} onClick={handleClick} style={{ minWidth: 300 }}>
        {audiencePermission && (
          <div className={classes.attributes}>
            {audience.topic.majority && audience.topic.majority.slice(0, 7).map((topic, itemIndex) => (
              <Chip
                key={topic}
                label={topic}
                size="small"
                className={`${classes.roundedChip} ${itemIndex < 2 ? classes.highlightedChip : ''}`}
              />
            ))}
          </div>
        )}
        {!audiencePermission && (
          <div className={classes.attributes} style={{ alignItems: 'center', justifyContent: 'center' }}>
            <AccessLockTooltip
              title="Audience locations"
            >
              Upgrade to our&nbsp;
              <Typography variant="body2" component="span" color="primary">
                Power Plan or above
              </Typography>
              &nbsp;to unlock this feature
            </AccessLockTooltip>
          </div>
        )}
      </TableCell>

      <TableCell className={classes.clickableCell} onClick={handleClick} style={{ minWidth: 300 }}>
        {audiencePermission && (
          <div className={classes.attributes}>
            {audienceMentions && audienceMentions.slice(0, 7).map((mention, itemIndex) => (
              <Chip
                key={mention}
                label={mention}
                size="small"
                className={`${classes.roundedChip} ${itemIndex < 2 ? classes.highlightedChip : ''}`}
              />
            ))}
          </div>
        )}
        {!audiencePermission && (
          <div className={classes.attributes} style={{ alignItems: 'center', justifyContent: 'center' }}>
            <AccessLockTooltip
              title="Audience locations"
            >
              Upgrade to our&nbsp;
              <Typography variant="body2" component="span" color="primary">
                Power Plan or above
              </Typography>
              &nbsp;to unlock this feature
            </AccessLockTooltip>
          </div>
        )}
      </TableCell>
      <TableCell className={classes.clickableCell} onClick={handleClick}>
        <div className={classes.attributes}>
          {gender && <Chip label={getGenderLabel(gender).toLowerCase()} size="small" className={classes.roundedChip} />}
        </div>
      </TableCell>
      <TableCell className={classes.clickableCell} onClick={handleClick}>
        <div className={classes.attributes}>
          {age && (
            <Chip label={age} size="small" className={classes.roundedChip} />
          )}
        </div>
      </TableCell>
      <TableCell className={classes.clickableCell} onClick={handleClick}>
        <div className={classes.attributes}>
          {age && (
            <Chip label={profile.ethnicity()} size="small" className={classes.roundedChip} />
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

ProfileTableRow.propTypes = {
  checked: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  onCheckedChange: PropTypes.func,
  onClick: PropTypes.func,
  profile: PropTypes.shape().isRequired,
  showActions: PropTypes.bool.isRequired,
  showCheckbox: PropTypes.bool.isRequired,
  list: PropTypes.node,
};

ProfileTableRow.defaultProps = {
  onCheckedChange: null,
  onClick: null,
  list: null,
};

export default ProfileTableRow;
