import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from '@reach/router';

import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ProfileAudienceActivity from './audience/ProfileAudienceActivity';
import ProfileAudienceLocation from './audience/ProfileAudienceLocation';
import ProfileAudienceTags from './audience/ProfileAudienceTags';
import { useApi, useAuth } from '../../hooks';
import { getGenderLabel } from '../../utils/profile';
import AudienceAnalysisImage from '../../assets/images/audience/audience-analysis.png';
import RequestNotice from '../common/RequestNotice';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',

    '&>:not(last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  text: {
    '& p': {
      marginBottom: 15,
      ...theme.textStyles.bodySmall,
    },
  },
  sectionTitle: {
    marginBottom: 15,
  },
  audienceImage: {
    position: 'absolute',
    top: 30,
    right: 0,
    height: 230,
  },
  audienceImageSidebar: {
    position: 'absolute',
    top: 60,
    right: 0,
    height: 180,
    '@media (max-width: 1919px)': {
      height: 120,
      top: 80,
    },
  },
  btn: {
    ...theme.textStyles.bodyExtraSmall,
    width: 'fit-content',
    marginTop: 20,
  },
  runAnalysis: {
    margin: '2rem 0',
    padding: '1.25rem 1.5rem',
    position: 'absolute',
    top: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    background: theme.colors.warning.mid,
    borderLeft: `6px solid ${theme.colors.primary.main}`,
    borderRadius: '2px',
  },
}));

const ProfileAudienceSummary = ({ profile }) => {
  const audienceAge = profile.audienceAge();
  const audienceGender = profile.audienceGender();
  const audienceLocationCountry = profile.audienceLocationCountry();

  // Get audience terms that have a valid value
  const audienceSummaryTerms = [];
  if (audienceGender) {
    audienceSummaryTerms.push(
      <span>
        mostly
        {' '}
        <strong>{getGenderLabel(audienceGender).toLowerCase()}</strong>
      </span>,
    );
  }
  if (audienceAge) {
    audienceSummaryTerms.push(
      <span>
        aged between
        {' '}
        <strong>{audienceAge}</strong>
      </span>,
    );
  }
  if (audienceLocationCountry) {
    audienceSummaryTerms.push(
      <span>
        located in
        {' '}
        <strong>{audienceLocationCountry}</strong>
      </span>,
    );
  }

  if (_.isEmpty(audienceSummaryTerms)) {
    return null;
  }

  return (
    <Typography variant="body2">
      Audience is
      {' '}
      {audienceSummaryTerms.map((it, idx) => {
        // Calculate the separator to use between the terms
        let separator = ', ';
        if (idx === audienceSummaryTerms.length - 2) {
          separator = ', and ';
        } else if (idx === audienceSummaryTerms.length - 1) {
          separator = null;
        }

        return [it, separator];
      })}
      .
    </Typography>
  );
};

ProfileAudienceSummary.propTypes = {
  profile: PropTypes.shape().isRequired,
};

const AudienceStatus = ({ profile, sidebar, sinceRefreshed }) => {
  const [audienceData, setAudienceData] = useState(null);
  const classes = useStyles();
  const auth = useAuth();
  const [{ data: getData, isFetching: getIsFetching }, getAudienceRequest] = useApi();
  const [{ data: postData, isFetching: postIsFetching }, postAudienceRequest] = useApi();

  const audience = profile.audience();
  const hasAudience = Boolean(audience && audience.sample_size);
  // Only instagram is supported for audience currently
  const canRequestAudience = profile.data.network_id.some((it) => it.includes('instagram'));
  const isFetching = getIsFetching || postIsFetching;

  useEffect(() => {
    setAudienceData(null);
    const instagramNetworkId = profile.data.network_id.find((it) => it.includes('instagram'));
    if (instagramNetworkId) {
      getAudienceRequest({
        method: 'get',
        url: `/account/${auth.account.id}/profile/by-network-id/${instagramNetworkId}/audience/`,
        validateStatus: (status) => status < 500,
      });
    }
  }, [auth, getAudienceRequest, profile]);

  useEffect(() => setAudienceData(getData), [getData]);
  useEffect(() => setAudienceData(postData), [postData]);

  const handleRequestAudience = () => {
    const instagramNetworkId = profile.data.network_id.find((it) => it.includes('instagram'));
    if (instagramNetworkId) {
      postAudienceRequest({
        method: 'post',
        url: `/account/${auth.account.id}/profile/by-network-id/${instagramNetworkId}/audience/`,
      });
    }
  };

  if (!auth.hasPermission('profile.view_audience')) {
    return (
      <div style={{ paddingBottom: sidebar ? 60 : 100 }}>
        <Typography
          variant="body2"
          component="p"
          style={{
            width: sidebar ? '55%' : '60%',
            fontSize: sidebar ? 11 : 12,
          }}
        >
          Upgrade to our&nbsp;
          <Typography
            variant="body2"
            component="span"
            color="primary"
            style={{ fontSize: sidebar ? 11 : 12 }}
          >
            Power Plan or above
          </Typography>
          &nbsp;and get to see analysis data such as gender split,&nbsp;
          audinece location, audience interest and more.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="small"
          component={Link}
          to="/settings/plans"
          className={classes.btn}
        >
          Upgrade Now
        </Button>
        <img
          src={AudienceAnalysisImage}
          className={
            !sidebar ? classes.audienceImage : classes.audienceImageSidebar
          }
          style={sidebar ? { top: 95 } : {}}
          alt="Audience analysis sample"
        />
      </div>
    );
  }

  if (audienceData) {
    return (
      <div style={{ paddingBottom: sidebar ? 60 : 100 }}>
        <Typography
          variant="body2"
          component="p"
          style={{ width: sidebar ? '55%' : '60%' }}
        >
          Audience is currently running and will be updated shortly.
        </Typography>
      </div>
    );
  }

  if (hasAudience) {
    return (
      <>
        {sinceRefreshed >= 14 && (
          <RequestNotice
            title="Does any of the data look broken or out of date?"
            body="Click the button to run it again!"
            btnLabel="Run Analysis Again"
            handleFunction={handleRequestAudience}
            disabled={isFetching}
            isProfilePage
          />
        )}

        <div className={classes.text}>
          {sinceRefreshed >= 14 && (
            <Typography variant="body2" component="p">
              Does this analysis look broken or out of date? Click the button
              above to run it again!
            </Typography>
          )}
          <Typography variant="body2" component="p">
            Analysis performed on a sample of the audience of&nbsp;
            {profile.fullName()}
          </Typography>
          <ProfileAudienceSummary profile={profile} />
        </div>
      </>
    );
  }

  if (canRequestAudience) {
    return (
      <div style={{ paddingBottom: sidebar ? 60 : 100 }}>
        <Typography
          variant="body2"
          component="p"
          style={{ width: sidebar ? '55%' : '60%' }}
        >
          We haven’t yet analysed the audience of this profile.&nbsp; Request an
          analysis to see data such as gender split, audience location, audience
          interest and more.
        </Typography>
        <Button
          color="primary"
          disabled={isFetching}
          onClick={handleRequestAudience}
          size="small"
          style={{ marginTop: 20 }}
          variant="contained"
        >
          Request Analysis
        </Button>
        <img
          src={AudienceAnalysisImage}
          className={
            !sidebar ? classes.audienceImage : classes.audienceImageSidebar
          }
          alt=""
        />
      </div>
    );
  }

  return null;
};

AudienceStatus.propTypes = {
  profile: PropTypes.shape().isRequired,
  sidebar: PropTypes.bool,
  sinceRefreshed: PropTypes.number,
};

AudienceStatus.defaultProps = {
  sidebar: false,
  sinceRefreshed: 0,
};

const ProfileAudience = ({ profile, sidebar, sinceRefreshed }) => {
  const classes = useStyles();
  const audience = profile.audience();
  const hasAudience = Boolean(audience && audience.sample_size);

  return (
    <div className={classes.root}>
      <AudienceStatus
        profile={profile}
        sidebar={sidebar}
        sinceRefreshed={sinceRefreshed}
      />
      {hasAudience && audience.activity && audience.activity.data && (
        <ProfileAudienceActivity
          activityData={audience.activity.data}
          audience={audience}
          sidebar={sidebar}
        />
      )}
      {hasAudience && audience.location && (
        <ProfileAudienceLocation
          locationData={audience.location}
          sidebar={sidebar}
        />
      )}

      {hasAudience && (
        <>
          <Typography variant="h5" className={classes.sectionTitle}>
            Audience interests
          </Typography>
          {audience.topic && audience.topic.data && (
            <ProfileAudienceTags
              tags={audience.topic.data.map((it) => it.key)}
              title="Audience topics"
            />
          )}
          {audience.hashtag && audience.hashtag.data && (
            <ProfileAudienceTags
              tags={audience.hashtag.data.map((it) => it.key)}
              title="Audience hashtags"
            />
          )}
          {audience.mention && audience.mention.data && (
            <ProfileAudienceTags
              tags={audience.mention.data.map((it) => it.key)}
              title="Audience mentions"
            />
          )}
        </>
      )}
    </div>
  );
};

ProfileAudience.propTypes = {
  profile: PropTypes.shape().isRequired,
  sidebar: PropTypes.bool,
  sinceRefreshed: PropTypes.number,
};

ProfileAudience.defaultProps = {
  sidebar: false,
  sinceRefreshed: 0,
};

export default ProfileAudience;
