import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import AddEditAgencyMemberModal from '../settings/AddEditAgencyMemberModal';

const useStyles = makeStyles((theme) => ({
  btn: {
    color: theme.colors.danger.main,
  },
  contAppEdit: {
    color: theme.colors.secondary.main,
  },
}));

const EditMemberButton = (props) => {
  const {
    refreshData,
    buttonName,
    isMembers,
    selected,
    contentAppPage,
  } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        className={contentAppPage ? classes.contentAppEdit : classes.btn}
        startIcon={contentAppPage && <CreateIcon />}
      >
        {buttonName}
      </Button>
      {open && (
        <AddEditAgencyMemberModal
          handleClose={() => setOpen(false)}
          refreshData={refreshData}
          isMember={isMembers}
          selected={selected}
          isEdit
        />
      )}
      {/* Add new member access */}
    </>
  );
};

EditMemberButton.propTypes = {
  refreshData: PropTypes.func.isRequired,
  buttonName: PropTypes.string.isRequired,
  isMembers: PropTypes.bool,
  selected: PropTypes.shape().isRequired,
  contentAppPage: PropTypes.bool,
};

EditMemberButton.defaultProps = {
  isMembers: false,
  contentAppPage: false,
};

export default EditMemberButton;
