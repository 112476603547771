import React from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.colors.system.grey3}`,
    borderRadius: 4,
    padding: 15,
    width: '100%',
    height: '100%',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    ...theme.textStyles.bodyMedium,
    marginBottom: 15,
  },
  content: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  actions: {
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  text: {
    ...theme.textStyles.bodyExtraSmall,
    color: theme.colors.system.grey7,
  },
}));

const NewsItem = ({ item }) => {
  const classes = useStyles();

  const {
    title, body, slug, heroImage,
  } = item;

  const getTruncatedText = () => (body.length > 190 ? `${body.substring(0, 190)}...` : body);

  return (
    <a href={`https://scrunch.com/blog/${slug}`} target="_blank" rel="noopener noreferrer">
      <Card className={classes.root}>
        <div style={{ flex: 1 }}>
          <CardMedia
            component="img"
            alt={heroImage.alternativeText}
            height="110"
            title={title}
            src={heroImage.url}
          />
          <CardContent className={classes.content}>
            <Typography variant="h5" component="h5" className={classes.title}>
              {title}
            </Typography>
            <Typography variant="body2" component="p" className={classes.text}>
              {getTruncatedText()}
            </Typography>
          </CardContent>
        </div>
        <CardActions className={classes.actions}>
          <Typography variant="body2" color="primary">
            Read More &gt;
          </Typography>
        </CardActions>
      </Card>
    </a>
  );
};

NewsItem.propTypes = {
  item: PropTypes.shape.isRequired,
};

export default NewsItem;
