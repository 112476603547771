import React from 'react';
import PropTypes from 'prop-types';

import {
  View,
  Text,
  StyleSheet,
} from '@react-pdf/renderer';

import { textStyles, colors } from '../definitions';
import { PdfPage } from '../components';

/* -------------------------------------------------------------------------- */
/* ------------------------------ SINGLE BLOCK ------------------------------ */
/* -------------------------------------------------------------------------- */

const singleStyles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: colors.BG_DARK,
    padding: 50,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  main: {
    // width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  rate: {
    ...textStyles.h1,
    marginBottom: 10,
    textAlign: 'center',
    // maxWidth: '50%',
  },
  title: {
    ...textStyles.h5,
    marginBottom: 10,
    textAlign: 'center',
    // maxWidth: '50%',
  },
  desc: {
    ...textStyles.body,
    textAlign: 'center',
    // maxWidth: '50%',
  },
});

const SingleBlock = ({
  rate,
  title,
  desc,
  websiteUrl,
  handle,
  name,
}) => (
  <View style={singleStyles.root}>
    <View style={singleStyles.header}>
      <Text style={textStyles.h5}>Rate Card</Text>
      <Text style={textStyles.body}>{name}</Text>
    </View>

    <View style={singleStyles.main}>
      <Text style={singleStyles.rate}>{rate}</Text>
      <Text style={singleStyles.title}>{title}</Text>
      <Text style={singleStyles.desc}>{desc}</Text>
    </View>

    <View style={singleStyles.header}>
      <Text style={textStyles.body}>{websiteUrl}</Text>
      <Text style={textStyles.body}>{handle}</Text>
    </View>
  </View>
);

SingleBlock.propTypes = {
  rate: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  websiteUrl: PropTypes.string,
  handle: PropTypes.string,
  name: PropTypes.string,
};

SingleBlock.defaultProps = {
  rate: '',
  title: '',
  desc: '',
  websiteUrl: '',
  handle: '',
  name: '',
};

/* -------------------------------------------------------------------------- */
/* ------------------------------ DOUBLE BLOCK ------------------------------ */
/* -------------------------------------------------------------------------- */

const doubleStyles = StyleSheet.create({
  root: {
    flex: 1,
    padding: '50px 100px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  main: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  block1: {
    padding: 20,
    flex: 1,
    backgroundColor: colors.BG_DARK,
    minWidth: '50%',
    minHeight: 250,
  },
  block2: {
    padding: 20,
    flex: 1,
    minWidth: '50%',
    minHeight: 250,
  },
  rate: {
    ...textStyles.h2,
    marginBottom: 10,
    textAlign: 'center',
  },
  title: {
    ...textStyles.h5,
    marginBottom: 10,
    textAlign: 'center',
  },
  desc: {
    ...textStyles.body,
    textAlign: 'center',
  },
});

const DoubleBlock = ({
  blocks, websiteUrl, handle, name,
}) => (
  <View style={doubleStyles.root}>
    <View style={doubleStyles.header}>
      <Text style={textStyles.h5}>Rate Card</Text>
      <Text style={textStyles.body}>
        {name}
      </Text>
    </View>

    <View style={doubleStyles.main}>
      <View style={doubleStyles.wrapper}>
        {blocks.map((block, index) => (
          <View style={index === 0 || index === 3 ? doubleStyles.block1 : doubleStyles.block2} key={block.id}>
            <Text style={doubleStyles.rate}>{block.rate}</Text>
            <Text style={doubleStyles.title}>{block.title}</Text>
            <Text style={doubleStyles.desc}>{block.serviceDesc}</Text>
          </View>
        ))}
      </View>
    </View>

    <View style={doubleStyles.header}>
      <Text style={textStyles.body}>{websiteUrl}</Text>
      <Text style={textStyles.body}>{handle}</Text>
    </View>
  </View>
);

DoubleBlock.propTypes = {
  blocks: PropTypes.shape().isRequired,
  websiteUrl: PropTypes.string,
  handle: PropTypes.string,
  name: PropTypes.string,
};

DoubleBlock.defaultProps = {
  websiteUrl: '',
  handle: '',
  name: '',
};

/* -------------------------------------------------------------------------- */
/* ------------------------------ OTHER BLOCKs ------------------------------ */
/* -------------------------------------------------------------------------- */

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    width: '100%',
    height: '100%',
    padding: 50,
  },
  root: {
    padding: 20,
    width: '33%',
    minHeight: '45%',
    textAlign: 'center',
  },
  rootBg: {
    padding: 20,
    backgroundColor: colors.BG_DARK,
    width: '33%',
    minHeight: '45%',
    textAlign: 'center',
  },
  main: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '5%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rate: {
    ...textStyles.h2,
    marginBottom: 10,
    textAlign: 'center',
  },
  title: {
    ...textStyles.h5,
    marginBottom: 10,
    textAlign: 'center',
  },
  desc: {
    ...textStyles.body,
    textAlign: 'center',
  },
});

const Block = ({
  rate, title, desc, index,
}) => (
  <View style={index % 2 === 0 ? styles.rootBg : styles.root}>
    <Text style={styles.rate}>{rate}</Text>
    <Text style={styles.title}>{title}</Text>
    <Text style={styles.desc}>{desc}</Text>
  </View>
);

Block.propTypes = {
  rate: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  index: PropTypes.number.isRequired,
};

Block.defaultProps = {
  rate: '',
  title: '',
  desc: '',
};

/* -------------------------------------------------------------------------- */
/* --------------------------------- PAGE 8 --------------------------------- */
/* -------------------------------------------------------------------------- */

const Page8 = ({ formData }) => {
  /* const rateCards = [
    {
      rate: '$200-$500',
      title: 'Instagram Sponsored Posts',
      desc: '- High quality, product images for yourt brand\n- Multiple posts over a period of time'
    },
    {
      rate: '$200-$500',
      title: 'Instagram Sponsored Posts',
      desc: '- High quality, product images for yourt brand\n- Multiple posts over a period of time'
    },
    {
      rate: '$200-$500',
      title: 'Instagram Sponsored Posts',
      desc: '- High quality, product images for yourt brand\n- Multiple posts over a period of time'
    },
    {
      rate: '$200-$500',
      title: 'Instagram Sponsored Posts',
      desc: '- High quality, product images for yourt brand\n- Multiple posts over a period of time'
    },
    {
      rate: '$200-$500',
      title: 'Instagram Sponsored Posts',
      desc: '- High quality, product images for yourt brand\n- Multiple posts over a period of time'
    },
    {
      rate: '$200-$500',
      title: 'Instagram Sponsored Posts',
      desc: '- High quality, product images for yourt brand\n- Multiple posts over a period of time'
    },
  ]; */

  const rateCards = formData.services;

  if (rateCards.length === 1) {
    return (
      <PdfPage>
        <SingleBlock
          rate={rateCards[0].rate}
          title={rateCards[0].title}
          desc={rateCards[0].serviceDesc}
          name={`${formData.personalDetails.firstName} ${formData.personalDetails.lastName}`}
          websiteUrl={formData.personalDetails.websiteUrl}
          handle={formData.personalDetails.instagramHandle ? `@${formData.personalDetails.instagramHandle}` : ''}
        />
      </PdfPage>
    );
  }

  if (rateCards.length === 2 || rateCards.length === 4) {
    return (
      <PdfPage>
        <DoubleBlock
          blocks={rateCards}
          name={`${formData.personalDetails.firstName} ${formData.personalDetails.lastName}`}
          websiteUrl={formData.personalDetails.websiteUrl}
          handle={formData.personalDetails.instagramHandle ? `@${formData.personalDetails.instagramHandle}` : ''}
        />
      </PdfPage>
    );
  }

  return (
    <PdfPage>
      <View style={styles.page}>
        <View style={singleStyles.header}>
          <Text style={textStyles.h5}>Rate Card</Text>
          <Text style={textStyles.body}>
            {formData.personalDetails.firstName}
            {' '}
            {formData.personalDetails.lastName}
          </Text>
        </View>

        <View style={styles.main}>
          <View style={styles.wrapper}>
            {rateCards && rateCards.map((card, index) => (
              <Block
                rate={card.rate}
                title={card.title}
                desc={card.serviceDesc}
                index={index}
                key={card.id}
              />
            ))}
          </View>
        </View>

        <View style={singleStyles.header}>
          <Text style={textStyles.body}>{formData.personalDetails.websiteUrl}</Text>
          <Text style={textStyles.body}>
            {formData.personalDetails.instagramHandle ? `@${formData.personalDetails.instagramHandle}` : ''}
          </Text>
        </View>
      </View>
    </PdfPage>
  );
};

Page8.propTypes = {
  formData: PropTypes.shape().isRequired,
};

export default Page8;
