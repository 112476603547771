import React from 'react';

import PropTypes from 'prop-types';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import ScrunchLogoIcon from './ScrunchLogo';
import TikTokIcon from './TikTok';

const NetworkIcon = ({ network, ...props }) => {
  switch (network) {
    case 'facebook':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <FacebookIcon {...props} />;
    case 'instagram':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <InstagramIcon {...props} />;
    case 'tiktok':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <TikTokIcon {...props} />;
    case 'twitter':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <TwitterIcon {...props} />;
    case 'youtube':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <YouTubeIcon {...props} />;
    case 'email':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <MailOutlineIcon {...props} />;
    case 'merge':
    default:
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <ScrunchLogoIcon {...props} />;
  }
};

NetworkIcon.propTypes = {
  network: PropTypes.oneOf(['facebook', 'instagram', 'twitter', 'youtube', 'tiktok', 'merge']).isRequired,
};

export default NetworkIcon;
