import React from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';

import { Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'min-content',
  },
  title: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  chart: {
    height: 25,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',

    '&>:first-child': {
      borderRadius: '4px 0px 0px 4px',
    },
    '&>:last-child': {
      borderRadius: '0px 4px 4px 0px',
    },
  },
  legend: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    paddingTop: 2,
  },
  legendLabel: {
    ...theme.textStyles.bodyExtraSmall,
    fontWeight: 'bold',
    paddingLeft: 2,
  },
}));

const calculateBackgroundColor = (theme, index, max) => {
  const baseColor = theme.colors.highlight.main;

  const alpha = Math.floor(255 - (index / max) * 255);
  let alphaHex = alpha.toString(16);
  if (alphaHex.length === 1) {
    alphaHex = `0${alphaHex}`;
  }

  return `${baseColor}${alphaHex}`;
};

const HorizontalStackedChart = (props) => {
  const {
    data,
    title,
  } = props;
  const classes = useStyles();
  const theme = useTheme();

  const valueTotal = _.sum(data.map(({ value }) => value));

  return (
    <div className={classes.root}>
      {title && (
        <Typography className={classes.title} variant="body2">{title}</Typography>
      )}
      <div className={classes.chart}>
        {data.map(({ label, value }, index) => (
          <div
            key={label}
            style={{
              width: `${(value / valueTotal) * 100}%`,
              backgroundColor: calculateBackgroundColor(theme, index, data.length),
            }}
          />
        ))}
      </div>
      <div className={classes.legend}>
        {data.map(({ label, value }) => (
          <div
            key={label}
            style={{
              minWidth: `${(value / valueTotal) * 100}%`,
            }}
          >
            <Typography className={classes.legendLabel} noWrap variant="body2">{label}</Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

HorizontalStackedChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  title: PropTypes.string,
};

HorizontalStackedChart.defaultProps = {
  title: null,
};

export default HorizontalStackedChart;
