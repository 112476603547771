import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';

import { MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FilterBase from './FilterBase';
import Select from '../../common/Select';
import { formatPercentage } from '../../../utils/number';
import { decodeRange, encodeRange } from '../../../utils/string';

const ENGAGEMENT_RATE_ANY_VALUE = 'Any rate';

const ENGAGEMENT_RATE_VALUES = [
  ENGAGEMENT_RATE_ANY_VALUE,
  0.01,
  0.02,
  0.03,
  0.04,
  0.05,
  0.06,
  0.07,
  0.08,
  0.09,
  0.1,
];

const useStyles = makeStyles((theme) => ({
  inputs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  input: {
    width: 200,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  text: {
    width: 20,
    color: theme.palette.grey[600],
    textAlign: 'center',
  },
}));

const EngagementRateFilter = (props) => {
  const { className, filterData, onApply } = props;
  const [min, setMin] = useState(null);
  const [max, setMax] = useState(null);
  const [activeText, setActiveText] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    const { engagement_rate: engagementRate } = filterData;
    const [minRange, maxRange] = decodeRange(engagementRate);
    setMin(minRange);
    setMax(maxRange);

    if (minRange !== null && maxRange !== null) {
      setActiveText(
        `${formatPercentage(minRange)}-${formatPercentage(maxRange)}`,
      );
    } else if (maxRange !== null) {
      setActiveText(`up to ${formatPercentage(maxRange)}`);
    } else if (minRange !== null) {
      setActiveText(`over ${formatPercentage(minRange)}`);
    } else {
      setActiveText(null);
    }
  }, [filterData]);

  const handleOnApply = () => {
    onApply({
      ...filterData,
      engagement_rate: (min || max ? encodeRange(min, max) : null),
    });
  };

  const handleOnClear = () => {
    onApply({ ...filterData, engagement_rate: null });
  };

  return (
    <FilterBase
      className={className}
      label="Engagement rate"
      activeText={activeText}
      isActive={Boolean(activeText)}
      onApply={handleOnApply}
      onClear={handleOnClear}
    >
      <div className={classes.inputs}>
        <Select
          className={classes.input}
          label="Min engagement"
          onChange={(e) => setMin(_.isNumber(e.target.value) ? e.target.value : null)}
          value={min || ENGAGEMENT_RATE_ANY_VALUE}
        >
          {ENGAGEMENT_RATE_VALUES.map((it) => (
            <MenuItem
              disabled={_.isNumber(it) && _.isNumber(max) && it >= max}
              key={it}
              value={it}
            >
              {_.isNumber(it) ? formatPercentage(it) : it}
            </MenuItem>
          ))}
        </Select>
        <Typography className={classes.text} variant="body2">
          to
        </Typography>
        <Select
          className={classes.input}
          label="Max engagement"
          onChange={(e) => setMax(_.isNumber(e.target.value) ? e.target.value : null)}
          value={max || ENGAGEMENT_RATE_ANY_VALUE}
        >
          {ENGAGEMENT_RATE_VALUES.map((it) => (
            <MenuItem
              key={it}
              disabled={_.isNumber(it) && _.isNumber(min) && it <= min}
              value={it}
            >
              {_.isNumber(it) ? formatPercentage(it) : it}
            </MenuItem>
          ))}
        </Select>
      </div>
    </FilterBase>
  );
};

EngagementRateFilter.propTypes = {
  className: PropTypes.string,
  filterData: PropTypes.shape().isRequired,
  onApply: PropTypes.func.isRequired,
};

EngagementRateFilter.defaultProps = {
  className: '',
};

export default EngagementRateFilter;
