import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FilterBase from './FilterBase';
import { formatNumber } from '../../../utils/number';
import { decodeRange, encodeRange } from '../../../utils/string';

const useStyles = makeStyles((theme) => ({
  inputs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  input: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const SocialReachFilter = (props) => {
  const { className, filterData, onApply } = props;
  const [min, setMin] = useState(null);
  const [max, setMax] = useState(null);
  const [activeText, setActiveText] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    const { social_reach: socialReach } = filterData;
    const [minRange, maxRange] = decodeRange(socialReach);
    setMin(minRange);
    setMax(maxRange);

    if (minRange !== null && maxRange !== null) {
      setActiveText(`${formatNumber(minRange)}-${formatNumber(maxRange)}`);
    } else if (maxRange !== null) {
      setActiveText(`up to ${formatNumber(maxRange)}`);
    } else if (minRange !== null) {
      setActiveText(`${formatNumber(minRange)}+`);
    } else {
      setActiveText(null);
    }
  }, [filterData]);

  const handleOnApply = () => {
    onApply({
      ...filterData,
      social_reach: min !== null || max !== null ? encodeRange(min, max) : null,
    });
  };

  const handleOnClear = () => {
    onApply({ ...filterData, social_reach: null });
  };

  return (
    <FilterBase
      className={className}
      label="Social reach"
      activeText={activeText}
      isActive={Boolean(activeText)}
      onApply={handleOnApply}
      onClear={handleOnClear}
    >
      <div className={classes.inputs}>
        <TextField
          className={classes.input}
          type="number"
          label="Min"
          value={min !== null ? min : ''}
          onChange={(e) => setMin(e.target.value !== '' ? parseInt(e.target.value, 10) : null)}
        />
        <TextField
          className={classes.input}
          type="number"
          label="Max"
          value={max !== null ? max : ''}
          onChange={(e) => setMax(e.target.value !== '' ? parseInt(e.target.value, 10) : null)}
        />
      </div>
    </FilterBase>
  );
};

SocialReachFilter.propTypes = {
  className: PropTypes.string,
  filterData: PropTypes.shape().isRequired,
  onApply: PropTypes.func.isRequired,
};

SocialReachFilter.defaultProps = {
  className: '',
};

export default SocialReachFilter;
