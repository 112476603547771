import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import { defaultFormData } from '../data';
import API from '../../../utils/api';
import { useAuth } from '../../../hooks';

import { useStyles } from './styles';

/* ----------------------------------- RATE CARD --------------------------------*/
const ServicesForm = ({
  formData,
  setFormData,
  setHighlighted,
  finalData,
  setFinalData,
  mkId,
  // createId,
}) => {
  const classes = useStyles({ bb: true });
  const auth = useAuth();

  useEffect(() => {
    document.getElementById('formWrapper').scrollTo(0, 0);
  }, []);

  const onServiceNameChange = (e, index) => {
    const servicesTemp = [...formData.services];
    servicesTemp[index].title = e.target.value;
    const formDataTemp = { ...formData };
    formDataTemp.services = servicesTemp;

    const finalServicesTemp = [...finalData.services];
    finalServicesTemp[index].title = e.target.value;
    const finalDataTemp = { ...finalData };
    finalDataTemp.services = finalServicesTemp;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const onServiceChargeChange = (e, index) => {
    const servicesTemp = [...formData.services];
    servicesTemp[index].rate = e.target.value;
    const formDataTemp = { ...formData };
    formDataTemp.services = servicesTemp;

    const finalServicesTemp = [...finalData.services];
    finalServicesTemp[index].rate = e.target.value;
    const finalDataTemp = { ...finalData };
    finalDataTemp.services = finalServicesTemp;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const onServiceDescChange = (e, index) => {
    const servicesTemp = [...formData.services];
    servicesTemp[index].serviceDesc = e.target.value;
    const formDataTemp = { ...formData };
    formDataTemp.services = servicesTemp;

    const finalServicesTemp = [...finalData.services];
    finalServicesTemp[index].serviceDesc = e.target.value;
    const finalDataTemp = { ...finalData };
    finalDataTemp.services = finalServicesTemp;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const addService = () => {
    const servicesTemp = [
      ...formData.services,
      {
        id: formData.services.length, title: '', rate: '', serviceDesc: '',
      },
    ];
    const formDataTemp = { ...formData };
    formDataTemp.services = servicesTemp;

    const finalServicesTemp = [
      ...finalData.services,
      {
        id: finalData.services.length, title: '', rate: '', serviceDesc: '',
      },
    ];
    const finalDataTemp = { ...finalData };
    finalDataTemp.services = finalServicesTemp;

    setFormData(formDataTemp);
    setFinalData(finalDataTemp);
  };

  const deleteService = async (index) => {
    try {
      // setIsFetching(true);
      const response = await API({
        url: `/account/${auth.account.id}/media-kit/${mkId}/rate_card/${finalData.services[index].id}/`,
        method: 'delete',
      });

      if (response.status === 200) {
        const servicesTemp = formData.services.filter((_, i) => i !== index);
        Object.keys(servicesTemp).forEach((key) => {
          servicesTemp[key].id = key;
        });
        const formDataTemp = { ...formData };
        formDataTemp.services = servicesTemp;

        const finalServicesTemp = finalData.services.filter((_, i) => i !== index);
        Object.keys(finalServicesTemp).forEach((key) => {
          finalServicesTemp[key].id = key;
        });
        const finalDataTemp = { ...finalData };
        finalDataTemp.services = finalServicesTemp;

        setFormData(formDataTemp);
        setFinalData(finalDataTemp);
      }
    } catch (_) {
      // setIsFetching(false);
    }
  };

  return (
    <>
      {formData.services.map((service, index) => (
        <div
          className={classes.sectionRoot}
          style={index === formData.services.length - 1 ? { borderBottom: 'none' } : {}}
          key={service.id}
        >
          <div className={classes.sectionListTitle}>
            <Typography
              variant="body1"
              component="div"
              color="primary"
              className={classes.sectionTitle}
            >
              Service
              {' '}
              {index + 1}
            </Typography>
            {formData.services.length !== 1 && (
            <IconButton aria-label="delete" className={classes.delIconTitle} onClick={() => deleteService(index)}>
              <DeleteIcon />
            </IconButton>
            )}
          </div>

          <div className={classes.flexRow} style={{ marginBottom: 25 }}>
            <TextField
              label="Service Name"
              value={service.title}
              placeholder={defaultFormData.services[index].title}
              style={{ width: '48%', marginRight: '4%' }}
              InputProps={{ className: classes.input }}
              onChange={(e) => onServiceNameChange(e, index)}
              onFocus={() => setHighlighted(`services${index}`)}
            />
            <TextField
              label="Service Charge"
              value={service.rate}
              placeholder={defaultFormData.services[index].rate}
              style={{ width: '48%' }}
              InputProps={{ className: classes.input }}
              onChange={(e) => onServiceChargeChange(e, index)}
              onFocus={() => setHighlighted(`services${index}`)}
            />
          </div>

          <TextField
            label="Service Description"
            value={service.serviceDesc}
            placeholder={defaultFormData.services[index].serviceDesc}
            style={{ marginBottom: 0 }}
            InputProps={{ className: classes.input }}
            multiline
            rows={10}
            fullWidth
            onChange={(e) => onServiceDescChange(e, index)}
            onFocus={() => setHighlighted(`services${index}`)}
          />
        </div>
      ))}

      {formData.services.length < 6 && (
      <div className={classes.sectionRoot}>
        <Button
          color="primary"
          size="small"
          onClick={addService}
        >
          + Add Another Service
        </Button>
      </div>
      )}
    </>
  );
};

ServicesForm.propTypes = {
  formData: PropTypes.shape().isRequired,
  setFormData: PropTypes.func.isRequired,
  setHighlighted: PropTypes.func.isRequired,
  finalData: PropTypes.shape().isRequired,
  setFinalData: PropTypes.func.isRequired,
  mkId: PropTypes.number.isRequired,
};

export default ServicesForm;
