import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';

import { Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FilterBase from './FilterBase';

const useStyles = makeStyles(() => ({
  list: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
}));

const OtherFilter = (props) => {
  const {
    className,
    filterData,
    label,
    onApply,
  } = props;
  const [activeText, setActiveText] = useState(null);
  const [hasEmailChecked, setHasEmailChecked] = useState(false);
  const [isInListChecked, setIsInListChecked] = useState(false);
  const [isNotInListChecked, setIsNotInListChecked] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const {
      has_email: hasEmail,
      is_in_list: isInList,
      is_not_in_list: isNotInList,
    } = filterData;
    setHasEmailChecked(Boolean(hasEmail));
    setIsInListChecked(Boolean(isInList));
    setIsNotInListChecked(Boolean(isNotInList));

    const activeTexts = [
      hasEmail ? 'has email' : null,
      isInList ? 'is in list' : null,
      isNotInList ? 'is not in list' : null,
    ].filter((it) => it !== null);
    setActiveText(!_.isEmpty(activeTexts) ? activeTexts.join(', ') : null);
  }, [filterData]);

  const handleOnApply = () => {
    onApply({
      ...filterData,
      has_email: hasEmailChecked || null,
      is_in_list: isInListChecked || null,
      is_not_in_list: isNotInListChecked || null,
    });
  };

  const handleOnClear = () => {
    onApply({
      ...filterData,
      has_email: null,
      is_in_list: null,
      is_not_in_list: null,
    });
  };

  return (
    <FilterBase
      className={className}
      label={label}
      activeText={activeText}
      isActive={Boolean(activeText)}
      onApply={handleOnApply}
      onClear={handleOnClear}
    >
      <div className={classes.list}>
        <FormControlLabel
          control={(
            <Checkbox
              checked={hasEmailChecked}
              onChange={(e) => setHasEmailChecked(e.target.checked)}
            />
          )}
          label="Show only profiles that have an email"
        />
        <FormControlLabel
          control={(
            <Checkbox
              checked={isInListChecked}
              onChange={(e) => setIsInListChecked(e.target.checked)}
            />
          )}
          label="Show only profiles that are already in a list"
        />
        <FormControlLabel
          control={(
            <Checkbox
              checked={isNotInListChecked}
              onChange={(e) => setIsNotInListChecked(e.target.checked)}
            />
          )}
          label="Show only profiles that are NOT already in a list"
        />
      </div>
    </FilterBase>
  );
};

OtherFilter.propTypes = {
  className: PropTypes.string,
  filterData: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  onApply: PropTypes.func.isRequired,
};

OtherFilter.defaultProps = {
  className: '',
};

export default OtherFilter;
