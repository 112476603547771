export default [
  'relationships',
  'news & media',
  'sexuality',
  'tattoos',
  'celebrity gossip',
  'smoking & vaping',
  'spiritual',
  'gaming',
  'cosplay',
  'home interior',
  'garden',
  'boating',
  'family',
  'graphic novels & comics',
  'animals & pets',
  'tv & film',
  'fashion',
  'health & fitness',
  'beauty',
  'photography',
  'technology',
  'books',
  'education',
  'food & drink',
  'travel',
  'music',
  'business & finance',
  'automotive',
  'sciences',
  'adult',
  'architecture',
  'anime & manga',
  'design',
  'politics',
  'sport',
  'environment',
  'wedding',
  'self help & well being',
  'medicine',
  'not for profit',
  'the arts',
  // 'cooking',
  // 'outdoors',
].sort();
