import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Typography,
  DialogContent,
  TextField,
  DialogActions,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import ScrDialog from '../common/ScrDialog';

import { useNotification, useAuth, useContentManager } from '../../hooks';
import API from '../../utils/api';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  deleteButton: {
    color: theme.colors.danger.main,
  },
  subTitle: {
    width: '80%',
    color: theme.colors.system.grey7,
    margin: '10px auto 0',
  },
  boldText: {
    fontWeight: 600,
    fontHeight: '32px',
  },
  deleteDialogBtn: {
    backgroundColor: theme.colors.danger.main,
    color: '#FFFFFF',
    minWidth: 240,
    minHeight: 40,
    ...theme.textStyles.bodySmall,
  },
}));

const DeleteAccountButton = ({
  email,
  setIsFetching,
  refreshResults,
  deleteStyle,
  deleteColor,
  deleteVariant,
  isMember,
  isInvite,
  handleClose,
  refreshData,
  removeAccess,
  buttonName,
  client,
}) => {
  const classes = useStyles();
  const auth = useAuth();
  const content = useContentManager();
  const { createNotification } = useNotification();

  // state
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState('');
  const [isFetching, setFetching] = useState(false);

  // functions
  const closeDialog = () => {
    setIsDialogOpen(false);
    setConfirmEmail('');
  };

  const deleteApi = async () => {
    setFetching(true);
    let response;
    // delete invitations
    if (isInvite) {
      response = await API({
        method: 'delete',
        url: `account/${auth.account.id}/employee-invitations/`,
        data: {
          email,
        },
      });
      // delete Members (non owner uses of an account)
    } else if (isMember) {
      response = await API({
        method: 'delete',
        url: `/account/${auth.account.id}/users/`,
        data: {
          email,
        },
      });
      // delete account
    } else {
      response = await API({
        method: 'post',
        url: `/account/${auth.account.id}/admin/delete-account/`,
        data: {
          email,
        },
      });
    }

    if (response.status !== 200) {
      setFetching(false);
      createNotification(
        'error',
        'Could not delete account. Please try again later.',
      );
    } else {
      setFetching(false);
      createNotification('success', 'Account Deleted Successfully!');
      if (isInvite) {
        handleClose();
        // getInvites();
        refreshData();
      } else if (isMember) {
        handleClose();
        // getMembers();
        refreshData();
      }
      if (refreshResults) {
        refreshResults();
      }
    }
  };

  const deleteAccount = async () => {
    if (confirmEmail && confirmEmail === email) {
      try {
        if (setIsFetching) {
          setIsFetching(true);
        }

        deleteApi();

        if (setIsFetching) {
          setIsFetching(false);
        }
        setIsDialogOpen(false);
      } catch (_) {
        setIsFetching(false);
        setIsDialogOpen(false);
        createNotification(
          'error',
          'An unexpected error occured while deleteing account',
        );
      }
    }
  };

  const removeMemAccess = async () => {
    const {
      first_name, last_name, agency_role, brand_profiles_access,
    } = client;

    setFetching(true);

    const newArr = JSON.parse(brand_profiles_access);
    newArr.splice(newArr.indexOf(content.brand && content.brand.username), 1);

    try {
      const response = await API({
        method: 'put',
        url: `/account/${auth.account.id}/users/`,
        data: {
          email,
          first_name,
          last_name,
          agency_role,
          brand_profiles_access: JSON.stringify(newArr),
        },
      });
      if (response.status === 200) {
        setFetching(false);
        createNotification('success', 'Member Access Removed!');
        setIsDialogOpen(false);
        refreshData();
      }
    } catch (e) {
      setFetching(false);
      // console.log(e);
      createNotification('error', 'Could not Remove Access. Please try again.');
    }
  };

  return (
    <>
      <Button
        startIcon={<DeleteIcon />}
        onClick={() => setIsDialogOpen(true)}
        className={classes.deleteButton}
        variant={deleteVariant}
        color={deleteColor}
        style={deleteStyle}
      >
        {buttonName}
      </Button>

      <ScrDialog
        isOpen={isDialogOpen}
        title={buttonName}
        subTitle="This user and the lists, reports, and records will be deleted permanently.&nbsp;
              If user had created and shared a public list, it will still be accessible."
        onClose={closeDialog}
        isFetching={isFetching}
        titleStyle={{ marginBottom: '25px' }}
        minWidth={800}
      >
        <DialogContent>
          <div style={{ marginBottom: 10 }}>
            <Typography
              variant="body2"
              component="span"
              className={classes.boldText}
            >
              Confirm by typing&nbsp;
            </Typography>
            <Typography
              variant="body2"
              component="span"
              className={classes.boldText}
              color="primary"
            >
              &apos;
              {email}
              &apos;
            </Typography>
            <Typography
              variant="body2"
              component="span"
              className={classes.boldText}
            >
              &nbsp;below.
            </Typography>
          </div>

          <TextField
            value={confirmEmail}
            onChange={(e) => setConfirmEmail(e.target.value)}
            fullWidth
            required
            inputProps={{
              required: true,
            }}
          />
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => (removeAccess ? removeMemAccess() : deleteAccount())}
            disabled={!confirmEmail || (confirmEmail && confirmEmail !== email)}
            className={classes.deleteDialogBtn}
          >
            Delete Forever
          </Button>
        </DialogActions>
        {/* </Dialog> */}
      </ScrDialog>
    </>
  );
};

DeleteAccountButton.propTypes = {
  email: PropTypes.string.isRequired,
  setIsFetching: PropTypes.func,
  refreshResults: PropTypes.func,
  deleteColor: PropTypes.string,
  deleteVariant: PropTypes.string,
  deleteStyle: PropTypes.shape(),
  isInvite: PropTypes.bool,
  isMember: PropTypes.bool,
  handleClose: PropTypes.func,
  refreshData: PropTypes.func,
  removeAccess: PropTypes.bool,
  buttonName: PropTypes.string.isRequired,
  client: PropTypes.shape(),
};

DeleteAccountButton.defaultProps = {
  setIsFetching: null,
  refreshResults: null,
  deleteColor: 'primary',
  deleteVariant: 'text',
  deleteStyle: null,
  isInvite: false,
  isMember: false,
  handleClose: null,
  removeAccess: false,
  refreshData: null,
  client: null,
};

export default DeleteAccountButton;
