import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles, Typography,
} from '@material-ui/core';

import visa from '../../assets/images/icons/visa.png';
import masterCard from '../../assets/images/icons/mastercard.png';
import americanExpress from '../../assets/images/icons/american-express.png';

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.textStyles.bodyExtraLarge,
  },
  subTitle: {
    ...theme.textStyles.bodySmall,
    color: theme.colors.system.grey7,
  },
  extraSmallText: {
    ...theme.textStyles.bodyExtraSmall,
    color: theme.colors.system.grey7,
  },
  titleGroup: {
    display: 'grid',
    gridTemplateColumns: '55% 45%',
    marginBottom: '30px',
  },
  titleGroupLeft: {
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  titleGroupRight: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  paymentLogo: {
    height: '20px',
    marginLeft: '10px',
    marginTop: '10px',
  },
}));

const SafeCheckoutHeader = ({ title, subTitle }) => {
  const classes = useStyles();

  return (
    <div className={classes.titleGroup}>
      <div className={classes.titleGroupLeft}>
        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="body2" className={classes.subTitle}>
          {subTitle}
        </Typography>
      </div>
      <div className={classes.titleGroupRight}>
        <Typography variant="h5" className={classes.extraSmallText}>
          Guaranteed Safe Checkout
        </Typography>
        <div>
          <img src={visa} alt="visa logo" className={classes.paymentLogo} />
          <img src={masterCard} alt="mastercard logo" className={classes.paymentLogo} />
          <img src={americanExpress} alt="american express logo" className={classes.paymentLogo} />
        </div>
      </div>
    </div>
  );
};

SafeCheckoutHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

SafeCheckoutHeader.defaultProps = {
  title: '',
  subTitle: '',
};

export default SafeCheckoutHeader;
