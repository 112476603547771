import React from 'react';

import { SvgIcon } from '@material-ui/core';

const EditNote = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <path
      style={{
        strokeWidth: 3.42363143,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
      }}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.01921 5.57715H13.6731C14.4663 5.57715 15.1154 6.22619 15.1154 7.01946V15.6733C15.1154 16.4666 14.4663 17.1156 13.6731 17.1156H5.01921C4.22594 17.1156 3.5769 16.4666 3.5769 15.6733V7.01946C3.5769 6.22619 4.22594 5.57715 5.01921 5.57715ZM5.01919 15.6732H13.673V7.0194H5.01919V15.6732Z"
      fill="#E75041"
    />
    <path
      style={{
        strokeWidth: 3.42363143,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
      }}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8173 3.84619C15.6971 3.84619 15.5721 3.89427 15.4808 3.98561L14.6009 4.86542L16.4038 6.66831L17.2836 5.7885C17.4711 5.601 17.4711 5.29811 17.2836 5.11061L16.1586 3.98561C16.0625 3.88946 15.9423 3.84619 15.8173 3.84619ZM14.0866 6.74025L14.5289 7.18256L10.1731 11.5383H9.73084V11.096L14.0866 6.74025ZM8.76929 10.6974L14.0866 5.38006L15.8895 7.18295L10.5722 12.5003H8.76929V10.6974Z"
      fill="#E75041"
    />
  </SvgIcon>
);

export default EditNote;
