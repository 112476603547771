// Main dashboard Brand Profiles list buttons
import React from 'react';
import PropTypes from 'prop-types';

import ClickList from '../common/ClickList';
import EmptyStateList from './EmptyStateList';
import { useContentManager } from '../../hooks';

const BrandProfiles = ({ setIsDialogOpen, brands }) => {
  const content = useContentManager();

  return (
    <>
      { (brands && brands.length > 0) || content.fetching ? (
        <ClickList
          title="Brand Profiles"
          setIsOpen={setIsDialogOpen}
          addNew="Brand"
          items={brands}
        />
      ) : (
        <EmptyStateList
          title="Start by adding a Brand Profile"
          body='Brand profiles enable you to manage your client brands. To add a brand, click "Add a brand"'
          buttonLabel="+ Add a Brand"
          handleClick={setIsDialogOpen}
          video="slxPFAJN9UM"
        />
      )}
    </>
  );
};

BrandProfiles.propTypes = {
  setIsDialogOpen: PropTypes.func.isRequired,
  brands: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default BrandProfiles;
