import React from 'react';

import PropTypes from 'prop-types';

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PaletteIcon from '@material-ui/icons/Palette';
import StyleIcon from '@material-ui/icons/Style';
import PeopleIcon from '@material-ui/icons/People';
import LockIcon from '@material-ui/icons/Lock';
import MenuPageBase, { Menu, MenuItem } from '../MenuPageBase';
import AvatarIcon from '../../common/icons/Avatar';
import { useAuth, useRoute } from '../../../hooks';

const SettingsPageBase = ({
  children,
  showLoadingBar,
  title,
  alertMessage,
}) => {
  const [route, setRoute] = useRoute();
  const auth = useAuth();

  return (
    <MenuPageBase
      menu={(
        <Menu>
          <MenuItem
            Icon={AvatarIcon}
            label="Profile settings"
            onClick={() => setRoute({ pathname: '/settings/user' })}
            selected={route.pathname === '/settings/user'}
          />
          <>
            <MenuItem
              Icon={AccountBalanceIcon}
              label="Account settings"
              onClick={() => setRoute({ pathname: '/settings/account' })}
              selected={route.pathname === '/settings/account'}
            />
            <MenuItem
              Icon={StyleIcon}
              label="Plans"
              onClick={() => setRoute({ pathname: '/settings/plans' })}
              selected={route.pathname === '/settings/plans'}
            />
            {auth.hasPermission('admin.change_email') && (
              <MenuItem
                Icon={PeopleIcon}
                label="Edit/Delete Users"
                onClick={() => setRoute({ pathname: '/settings/manage-users' })}
                selected={route.pathname === '/settings/manage-users'}
              />
            )}
            {auth.account.type !== 'influencer' && (
              <MenuItem
                Icon={auth.hasPermission('account.add_employee') ? PeopleIcon : LockIcon}
                label="Members & Permissions"
                onClick={() => setRoute({ pathname: '/settings/my-team' })}
                selected={route.pathname === '/settings/my-team'}
                disabled={!auth.hasPermission('account.add_employee')}
              />
            )}
            {auth.account.type !== 'influencer' && (
            <MenuItem
              Icon={auth.hasPermission('preferences.all') ? PaletteIcon : LockIcon}
              label="Preferences"
              onClick={() => setRoute({ pathname: '/settings/preferences' })}
              selected={route.pathname === '/settings/preferences'}
              disabled={!auth.hasPermission('preferences.all')}
            />
            )}
          </>
        </Menu>
      )}
      showLoadingBar={showLoadingBar}
      title={title}
      alertMessage={alertMessage}
    >
      {children}
    </MenuPageBase>
  );
};

SettingsPageBase.propTypes = {
  children: PropTypes.node.isRequired,
  showLoadingBar: PropTypes.bool,
  title: PropTypes.string,
  alertMessage: PropTypes.node,
};

SettingsPageBase.defaultProps = {
  showLoadingBar: false,
  title: null,
  alertMessage: null,
};

export default SettingsPageBase;
