import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from '@reach/router';
import { Link } from '@material-ui/core';

const BackButton = ({ url }) => (
  <Link key="back" component={RouterLink} to={url}>
    &lt; Go Back
  </Link>
);

BackButton.propTypes = {
  url: PropTypes.string.isRequired,
};

export default BackButton;
