import React from 'react';

import { Redirect } from '@reach/router';

import { useAuth } from '../../hooks';

const DashboardPage = () => {
  const auth = useAuth();

  if (auth.account && auth.account.type === 'influencer') {
    // <Redirect noThrow to="/scrunch-university" />
    return (
      <Redirect noThrow to="/dashboard" />
    );
  }

  return (
    <>
      <Redirect noThrow to="/discover" />
    </>
  );
};

export default DashboardPage;
