import React from 'react';

import PropTypes from 'prop-types';

import { Breadcrumbs, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import PageBase from './PageBase';
import AccessLockTooltip from '../common/AccessLockTooltip';
import { useAuth } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    gridTemplateAreas: `
      "header"
      "children"
    `,
    height: '100%',
  },
  headerContainer: {
    gridArea: 'header',
    padding: '50px',
  },
  childrenContainer: {
    gridArea: 'children',
    padding: '50px',
  },
  pageTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  pageTitleLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  pageSubtitle: {
    color: theme.colors.system.grey7,
    marginTop: 10,
  },
  pageDescription: {
    marginTop: '0.75rem',
  },
  backButton: {
    marginBottom: 20,
    ...theme.textStyles.bodySmall,
  },
  titleImg: {
    borderRadius: '50%',
    height: '30px',
    marginRight: '10px',
  },
}));

const MenuPageBreadcrumbs = withStyles((theme) => ({
  root: {
    marginBottom: 15,
    ...theme.textStyles.bodySmall,
  },
}))(Breadcrumbs);

const PlainPageBase = (props) => {
  const {
    breadcrumbs,
    children,
    showLoadingBar,
    title,
    subtitle,
    lock,
    backLink,
    alertMessage,
    backButton,
    titleImg,
  } = props;
  const classes = useStyles();
  const auth = useAuth();

  return (
    <PageBase
      showLoadingBar={showLoadingBar}
      title={title}
    >
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          {backButton && (
            <MenuPageBreadcrumbs>{backButton}</MenuPageBreadcrumbs>
          )}
          {breadcrumbs && (
            <MenuPageBreadcrumbs>{breadcrumbs}</MenuPageBreadcrumbs>
          )}
          <div
            className={classes.pageTitle}
            style={
              auth.account.type === 'influencer'
              && backLink
              && backLink.link
              && backLink.label
                ? { marginTop: 10 }
                : {}
            }
          >
            <div className={classes.pageTitleLeft}>
              {titleImg
                && <img className={classes.titleImg} src={titleImg} alt="" />}
              <Typography variant="h5">{title}</Typography>
              {lock && (
                <AccessLockTooltip title={lock.title}>
                  {lock.description}
                </AccessLockTooltip>
              )}
            </div>
            {alertMessage && alertMessage}
          </div>

          {/* {description && (
            <div className={classes.pageDescription}>
              <Typography>{description}</Typography>
            </div>
           )} */}

          {subtitle && (
            <Typography variant="body2" className={classes.pageSubtitle}>
              {subtitle}
            </Typography>
          )}
        </div>
        <div className={classes.childrenContainer}>
          {children}
        </div>
      </div>
    </PageBase>
  );
};

PlainPageBase.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.node),
  backButton: PropTypes.node,
  children: PropTypes.node,
  showLoadingBar: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  lock: PropTypes.shape(),
  backLink: PropTypes.shape(),
  alertMessage: PropTypes.node,
  description: PropTypes.string,
  titleImg: PropTypes.string,
};

PlainPageBase.defaultProps = {
  title: '',
  breadcrumbs: null,
  children: null,
  showLoadingBar: false,
  lock: null,
  alertMessage: null,
  subtitle: null,
  backLink: null,
  description: null,
  backButton: null,
  titleImg: null,
};

export default PlainPageBase;
