import React from 'react';

import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Config from '../../config';

const useStyles = makeStyles((theme) => ({
  pageBase: {
    width: '100%',
    height: '100%',
  },
  children: {
    width: '100%',
    height: '100%',
  },
  backdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  loadingBar: {
    position: 'absolute',
    zIndex: 9999,
    width: '100%',
  },
}));

const LoadingBar = (props) => {
  const { show } = props;
  const classes = useStyles();

  return (
    <div className={classes.loadingBar}>
      {show && <LinearProgress variant="indeterminate" />}
    </div>
  );
};

LoadingBar.propTypes = {
  show: PropTypes.bool.isRequired,
};

const PageBase = (props) => {
  const {
    children,
    scroll,
    showLoadingBar,
    title,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.pageBase}>
      <Helmet defer={false}>
        <title>{title ? `${title} - ${Config.TITLE}` : Config.TITLE}</title>
        <link rel="icon shortcut" href={Config.FAVICON} />
        {/* hubspot code below */}
        <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/2765807.js" />

      </Helmet>
      {showLoadingBar && (
        <div className={classes.backdrop}>
          <LoadingBar show />
        </div>
      )}
      <div
        className={classes.children}
        style={{ overflowY: scroll ? 'auto' : 'inherit' }}
      >
        {children}
      </div>
    </div>
  );
};

PageBase.propTypes = {
  children: PropTypes.node.isRequired,
  scroll: PropTypes.bool,
  showLoadingBar: PropTypes.bool,
  title: PropTypes.string,
};

PageBase.defaultProps = {
  scroll: true,
  showLoadingBar: false,
  title: null,
};

export default PageBase;
