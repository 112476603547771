import React from 'react';

import PropTypes from 'prop-types';

import RouteContainer from './RouteContainer';
import Navigation from '../navigation/Navigation';

/**
 * Renders the navigation bar and menu around the the child route(s).
 */
const NavigationRouteContainer = ({ children }) => (
  <Navigation>
    <RouteContainer>
      {children}
    </RouteContainer>
  </Navigation>
);

NavigationRouteContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NavigationRouteContainer;
