import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';

import {
  Dialog,
  IconButton,
  Typography,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import UnlockEmailGif from '../../assets/images/gifs/unlock-pagination.gif';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  root: {
    width: '100%',
    padding: 40,
    backgroundColor: theme.colors.background.primary,
    minHeight: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heading: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  text: {
    ...theme.textStyles.bodyMedium,
    color: theme.colors.system.grey7,
    textAlign: 'center',
    margin: '30px auto',
    width: '50%',
    minWidth: 100,
  },
  unlockBtn: {
    ...theme.textStyles.bodySmall,
    padding: '11px 50px',
  },
  gif: {
    width: '50%',
    minWidth: 100,
    marginBottom: 30,
  },
}));

const PaginationAccessDialog = ({
  isDialogOpen,
  closeDialog,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={isDialogOpen}
      onBackdropClick={closeDialog}
      PaperProps={{ style: { padding: 0, width: '60%' } }}
    >
      <IconButton aria-label="close" className={classes.closeButton} onClick={closeDialog}>
        <CloseIcon />
      </IconButton>

      <div className={classes.root}>
        <div className={classes.heading}>
          <Typography variant="h5" component="h5">
            Want access to&nbsp;
          </Typography>
          <Typography variant="h5" component="h5" color="primary">
            more influencers?
          </Typography>
        </div>

        <Typography variant="body1" className={classes.text}>
          Upgrade now and get unlimited access to the largest influencer database!
        </Typography>

        <img
          src={UnlockEmailGif}
          className={classes.gif}
          alt="To the next level animation"
        />

        <Button
          color="primary"
          component={Link}
          to="/settings/plans"
          variant="contained"
          className={classes.unlockBtn}
        >
          Upgrade Now
        </Button>
      </div>
    </Dialog>
  );
};

PaginationAccessDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default PaginationAccessDialog;
