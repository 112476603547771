import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import BarChart from '../../chart/BarChart';

const ages = ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65+'];

const useStyles = makeStyles((theme) => ({
  chart: {
    padding: theme.spacing(2),
    borderRadius: 5,
    backgroundColor: theme.colors.system.grey1,
  },
}));

const ProfileAudienceAge = ({ ageData }) => {
  const classes = useStyles();
  const chartData = ages.map((age) => {
    const data = ageData.find(({ key }) => key === age);
    const probability = data && data.probability ? data.probability : 0;
    return {
      label: `${age}`,
      value: probability,
    };
  });
  return (
    <div className={classes.chart}>
      <BarChart
        data={chartData}
        title="Age split"
      />
    </div>
  );
};

ProfileAudienceAge.propTypes = {
  ageData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default ProfileAudienceAge;
