import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '../common/icons/Close';

import { useApi, useAuth, useNotification } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  accountSettingsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    '&>*': {
      width: '100%',
    },
    '&>:not(:last-child)': {
      marginBottom: 20,
    },
  },
  inlineEditableField: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'end',
    '&>:not(:last-child)': {
      marginRight: 10,
    },
  },
  editClose: {
    position: 'absolute',
    right: '2rem',
    top: '2rem',
  },
  title: {
    margin: '1rem',
  },
}));

const UpdateBillingEmailDialog = ({ customer, onClose, onSuccess }) => {
  const [billingEmail, setBillingEmail] = useState(customer.metadata.billing_email || customer.email);
  const [billingEmailError, setBillingEmailError] = useState('');
  const classes = useStyles();
  const auth = useAuth();
  const { createNotification } = useNotification();
  const [{ response, isFetching }, postBillingEmailRequest] = useApi();

  useEffect(() => {
    if (response) {
      if (response.status === 200) {
        createNotification('success', 'Billing email has been updated!');
        if (onSuccess) {
          onSuccess();
        }
        onClose();
      } else {
        createNotification('error', 'Billing email update failed.');
      }
    }
  }, [createNotification, onClose, onSuccess, response]);

  const handleSubmit = () => {
    setBillingEmailError(billingEmail ? '' : 'Billing email is required.');

    if (billingEmail) {
      postBillingEmailRequest({
        method: 'post',
        url: `/account/${auth.account.id}/billing/`,
        data: {
          billing_email: billingEmail,
        },
      });
    }
  };

  return (
    <Dialog open onClose={onClose}>
      <CloseIcon onClick={onClose} className={classes.editClose} />
      <DialogTitle className={classes.title}>Update your billing email</DialogTitle>
      <DialogContent>
        <div className={classes.field}>
          <TextField
            error={Boolean(billingEmailError)}
            helperText={billingEmailError}
            label="Billing email"
            onChange={(e) => setBillingEmail(e.target.value)}
            required
            fullWidth
            value={billingEmail}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={isFetching}
          onClick={handleSubmit}
          variant="contained"
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UpdateBillingEmailDialog.propTypes = {
  customer: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};

UpdateBillingEmailDialog.defaultProps = {
  onSuccess: null,
};

const UpdateBillingEmailButton = ({ customer, onSuccess }) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  return (
    <div>
      <Button
        color="primary"
        onClick={() => setDialogIsOpen(true)}
        variant="text"
        size="small"
      >
        Update billing email
      </Button>
      {dialogIsOpen && (
        <UpdateBillingEmailDialog
          customer={customer}
          onClose={() => setDialogIsOpen(false)}
          onSuccess={onSuccess}
        />
      )}
    </div>
  );
};

UpdateBillingEmailButton.propTypes = {
  customer: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func,
};

UpdateBillingEmailButton.defaultProps = {
  onSuccess: null,
};

export default UpdateBillingEmailButton;
