import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { Checkbox, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FilterBase from './FilterBase';
import NetworkIcon from '../../common/icons/NetworkIcon';

const networks = ['instagram', 'twitter', 'youtube', 'facebook', 'tiktok'];
const networkLabels = {
  instagram: 'Instagram',
  twitter: 'Twitter',
  youtube: 'YouTube',
  facebook: 'Facebook',
  tiktok: 'TikTok',
};

const useStyles = makeStyles(() => ({
  columns: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  column: {
    width: 150,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  network: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
  },
  icon: {
    paddingRight: 5,
  },
}));

const NetworkFilter = (props) => {
  const { className, filterData, onApply } = props;
  const [activeText, setActiveText] = useState(null);
  const [selectedNetworks, setSelectedNetworks] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    const { network } = filterData;
    if (network) {
      setActiveText(network.split(',').map((it) => networkLabels[it]).join(', '));
      setSelectedNetworks(network.split(','));
    } else {
      setActiveText(null);
      setSelectedNetworks([]);
    }
  }, [filterData]);

  const handleOnApply = () => {
    onApply({
      ...filterData,
      network: selectedNetworks.length ? selectedNetworks.join(',') : null,
    });
  };

  const handleOnClear = () => {
    onApply({ ...filterData, network: null });
  };

  const handleNetworCheckboxOnChange = (network, checked) => {
    if (checked && !selectedNetworks.includes(network)) {
      setSelectedNetworks(selectedNetworks.concat([network]));
    }
    if (!checked && selectedNetworks.includes(network)) {
      setSelectedNetworks(selectedNetworks.filter((it) => it !== network));
    }
  };

  return (
    <FilterBase
      className={className}
      label="Network"
      activeText={activeText}
      isActive={Boolean(activeText)}
      onApply={handleOnApply}
      onClear={handleOnClear}
    >
      <div className={classes.columns}>
        <div className={classes.column}>
          {networks.map((network) => (
            <div key={network} className={classes.network}>
              <Checkbox
                checked={selectedNetworks.includes(network)}
                onChange={(e) => handleNetworCheckboxOnChange(network, e.target.checked)}
              />
              <NetworkIcon className={classes.icon} network={network} />
              <Typography variant="body2">{networkLabels[network]}</Typography>
            </div>
          ))}
        </div>
        <div className={classes.column}>
          <div className={classes.network} />
        </div>
      </div>
    </FilterBase>
  );
};

NetworkFilter.propTypes = {
  className: PropTypes.string,
  filterData: PropTypes.shape().isRequired,
  onApply: PropTypes.func.isRequired,
};

NetworkFilter.defaultProps = {
  className: '',
};

export default NetworkFilter;
