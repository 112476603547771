import React from 'react';
import PropTypes from 'prop-types';

import {
  View,
  Text,
  Image,
  StyleSheet,
} from '@react-pdf/renderer';

import { textStyles, colors } from '../definitions';
import { PdfPage, Header } from '../components';

const networkStyles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  networkName: {
    ...textStyles.h5,
    textTransform: 'capitalize',
    marginBottom: 10,
    marginTop: 5,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  extraLarge: {
    count: {
      ...textStyles.h1,
    },
  },
  large: {
    count: {
      fontFamily: 'CooperLightBT',
      fontSize: 80,
      lineHeight: '96px',
    },
  },
  medium: {
    count: {
      fontFamily: 'CooperLightBT',
      fontSize: 60,
      lineHeight: '72px',
    },
  },
  regular: {
    count: {
      ...textStyles.h2,
    },
  },
});

const FollowersData = ({
  size,
  followers,
  network,
  handle,
  bottomMargin,
}) => {
  const marginBottom = bottomMargin ? 30 : 0;
  let networkText = '';
  if (network) {
    if (network.toLowerCase() === 'website') {
      networkText = 'Views / Month';
    } else {
      networkText = 'Followers';
    }
  }
  return (
    <View style={{ ...networkStyles.root, marginBottom }}>
      <Text style={networkStyles[size].count}>{followers}</Text>
      <Text style={networkStyles.networkName}>
        {network}
        {' '}
        {networkText}
      </Text>
      <View style={networkStyles.footer}>
        <Text style={textStyles.body}>{handle ? `2${handle}` : ''}</Text>
      </View>
    </View>
  );
};

FollowersData.propTypes = {
  size: PropTypes.string,
  followers: PropTypes.string.isRequired,
  network: PropTypes.string.isRequired,
  handle: PropTypes.string.isRequired,
  bottomMargin: PropTypes.bool,
};

FollowersData.defaultProps = {
  size: 'regular',
  bottomMargin: false,
};

const styles = StyleSheet.create({
  col1: {
    flex: 1,
    backgroundColor: colors.BG_DARK,
    padding: 50,
  },
  col2: {
    flex: 2,
    padding: 50,
    paddingLeft: 150,
  },
  main: {
    flex: 1,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    height: 440,
    width: 440,
    position: 'absolute',
    top: '15%',
    left: 0,
    backgroundColor: colors.SECONDARY,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  wrapper: {
    height: 440,
    width: '100%',
    paddingLeft: 50,
    display: 'flex',
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  justify: {
    center: {
      justifyContent: 'center',
    },
    spaceBetween: {
      justifyContent: 'space-between',
    },
    spaceAround: {
      justifyContent: 'space-around',
    },
  },
  align: {
    center: {
      alignContent: 'center',
    },
    spaceBetween: {
      alignItems: 'space-between',
    },
  },
});

const Page4 = ({ formData }) => {
  /* const networkData = [
    { followers: '275K', network: 'instagram', handle: '@daniellelewisco' },
    { followers: '275K', network: 'instagram', handle: '@daniellelewisco' },
    { followers: '275K', network: 'instagram', handle: '@daniellelewisco' },
    { followers: '275K', network: 'instagram', handle: '@daniellelewisco' },
    { followers: '275K', network: 'instagram', handle: '@daniellelewisco' },
    { followers: '275K', network: 'instagram', handle: '@daniellelewisco' },
  ]; */

  const networkData = formData.socialStatistics.socialData;

  // Determine size of the text
  let size = 'regular';
  if (networkData.length === 1) size = 'extraLarge';
  else if (networkData.length === 2) size = 'large';
  else if (networkData.length === 3 || networkData.length === 4) size = 'medium';
  else size = 'regular';

  // Determine flex properties
  let flexStyle = 'flexCol';
  if (networkData.length >= 4) flexStyle = 'flexRow';

  // Determine justify value
  let justify = 'center';
  if (networkData.length === 1) justify = 'center';
  else if (networkData.length === 2) justify = 'spaceAround';
  else if (networkData.length === 3) justify = 'spaceBetween';
  else if (networkData.length === 4) justify = 'spaceBetween';
  else if (networkData.length === 5) justify = 'spaceAround';
  else if (networkData.length === 6) justify = 'spaceBetween';

  // Determine align value
  const align = 'center';

  // determine margin bottom
  const addBottomMargin = (index) => {
    if (networkData.length === 4) {
      if (index === 0 || index === 1) return true;
    } else if (networkData.length === 5 || networkData.length === 6) {
      if (index >= 0 && index <= 3) return true;
    }
    return false;
  };

  return (
    <PdfPage>
      <View style={styles.col1}>
        <Header align="left" variant="h5">Social Statistics</Header>
        <View style={styles.main}>
          <View style={styles.container}>
            <Image src={formData.socialStatistics.socialStatsImg} style={styles.image} />
          </View>
        </View>
        <Header align="left">{formData.personalDetails.websiteUrl}</Header>
      </View>
      <View style={styles.col2}>
        <Header align="right">
          {formData.personalDetails.firstName}
          {' '}
          {formData.personalDetails.lastName}
        </Header>
        <View style={styles.main}>
          <View style={{
            ...styles.wrapper,
            ...styles[flexStyle],
            ...styles.align[align],
            ...styles.justify[justify],
          }}
          >
            {networkData && networkData.map((data, index) => (
              <FollowersData
                size={size}
                followers={data.followerCount}
                network={data.socialWebsite}
                handle={data.socialHandle}
                bottomMargin={addBottomMargin(index)}
                key={data.id}
              />
            ))}
          </View>
        </View>
        <Header align="right">
          {formData.personalDetails.instagramHandle ? `@${formData.personalDetails.instagramHandle}` : ''}
        </Header>
      </View>
    </PdfPage>
  );
};

Page4.propTypes = {
  formData: PropTypes.shape().isRequired,
};

export default Page4;
