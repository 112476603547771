import React from 'react';
import PropTypes from 'prop-types';

import {
  View,
  Text,
  Image,
  StyleSheet,
} from '@react-pdf/renderer';

import { textStyles, colors } from '../definitions';
import { PdfPage } from '../components';

const collabStyles = StyleSheet.create({
  root: {
    flex: 1,
    maxWidth: '43%',
    padding: 25,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  imgWrapper: {
    width: '100%',
    height: 275,
    backgroundColor: colors.SECONDARY,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  title: {
    ...textStyles.h4,
    marginBottom: 25,
    marginTop: 25,
  },
});

const Collaboration = ({
  imgUrl,
  title,
  desc,
}) => (
  <View style={collabStyles.root}>
    <View style={collabStyles.imgWrapper}>
      <Image src={imgUrl} style={collabStyles.image} />
    </View>
    <Text style={collabStyles.title}>{title}</Text>
    <Text style={textStyles.body}>{desc}</Text>
  </View>
);

Collaboration.propTypes = {
  imgUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
};

Collaboration.defaultProps = {
  imgUrl: '',
};

const styles = StyleSheet.create({
  col1: {
    flex: 1,
    backgroundColor: colors.BG_DARK,
  },
  col2: {
    flex: 3,
  },
  container: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    flex: 1,
    padding: 25,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  headers: {
    padding: 50,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

const Page6 = ({ formData }) => {
  /* const collabs = [
    {
        imgUrl: '',
        title: 'Scrunch',
        desc: 'Collaboration project to promote Underarmour’s new line of activewear co-designed by Maisha O’Neil'
    },
    {
        imgUrl: '',
        title: 'Scrunch',
        desc: 'Collaboration project to promote Underarmour’s new line of activewear co-designed by Maisha O’Neil'
    },
    {
        imgUrl: '',
        title: 'Scrunch',
        desc: 'Collaboration project to promote Underarmour’s new line of activewear co-designed by Maisha O’Neil'
    },
  ]; */

  const collabs = formData.instagramAnalytics.collaborations;
  return (
    <PdfPage>
      <View style={styles.col1} />
      <View style={styles.col2} />
      <View style={styles.container}>
        <View style={styles.headers}>
          <Text style={textStyles.h5}>Brand Partnerships &amp; Collaborations</Text>
          <Text style={textStyles.body}>
            {formData.personalDetails.firstName}
            {' '}
            {formData.personalDetails.lastName}
          </Text>
        </View>

        <View style={styles.wrapper}>
          {collabs && collabs.map((collab) => (
            <Collaboration
              imgUrl={collab.collabImg}
              title={collab.brandName}
              desc={collab.collabDesc}
              key={collab.id}
            />
          ))}
        </View>

        <View style={styles.headers}>
          <Text style={textStyles.body}>{formData.personalDetails.website}</Text>
          <Text style={textStyles.body}>
            {formData.personalDetails.instagramHandle ? `@${formData.personalDetails.instagramHandle}` : ''}
          </Text>
        </View>
      </View>
    </PdfPage>
  );
};

Page6.propTypes = {
  formData: PropTypes.shape().isRequired,
};

export default Page6;
