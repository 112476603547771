import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Button, Typography, Chip, Avatar,
} from '@material-ui/core';

import RefreshIcon from '@material-ui/icons/Refresh';
import { RadialChart } from 'react-vis';
import ImportIcon from '../common/icons/Import';
import ScrunchLogoFull from '../../assets/images/scrunch-logo-full.svg';
import RequestNotice from '../common/RequestNotice';
import { formatDate, formatDateWithTime } from '../../utils/datetime';
import Profile, { getGenderLabel } from '../../utils/profile';
import { useContentManager, useAuth } from '../../hooks';

import {
  checkUnique,
  typesOfPosts,
  typesOfPostsTally,
} from '../../utils/contentManager';

import campaignAnalyticsStyles from '../../styles/campaignAnalytics';

const GoogleFineprint = () => {
  const classes = campaignAnalyticsStyles();
  return (
    <Typography className={classes.fineprint}>
      Tracked From Shopify Discount Codes
    </Typography>
  );
};

const ShopifyFineprint = () => {
  const classes = campaignAnalyticsStyles();
  return (
    <Typography className={classes.fineprint}>
      Tracked From Google Analtics
    </Typography>
  );
};

const InfluencerPost = ({ post, influencer }) => {
  const classes = campaignAnalyticsStyles();

  return (
    <>
      <div className={classes.postMainCont}>
        <div className={classes.postHeader}>
          <img
            className={classes.postPic}
            src={post.img_url && post.img_url[0]}
            alt="Post"
          />
          <div>
            <div className={classes.namePic}>
              <Avatar
                className={classes.postAuthPic}
                src={influencer.profilePicture()}
                alt={influencer.fullName()}
              />
              <Typography className={classes.postInfName}>
                {' '}
                {influencer.fullName()}
              </Typography>
            </div>
            <p className={classes.postBody}>
              {' '}
              {post.text}
            </p>
            <p className={classes.postDatetime}>
              {formatDateWithTime(post.post_datetime)}
            </p>
          </div>
        </div>
        <div className={classes.postStatsCont}>
          <div className={classes.postStat}>
            <Typography>Followers</Typography>
            <Typography>{influencer.socialReach()}</Typography>
          </div>
          <div className={classes.postStat}>
            <Typography>Engagements</Typography>
            <Typography>XXXXXX</Typography>
          </div>
          <div className={classes.postStat}>
            <Typography>Campaign Engagement Rate</Typography>
            <Typography>XXXXXX</Typography>
          </div>
          <div className={classes.postStat}>
            <Typography>Influencer Engagement Rate</Typography>
            <Typography>{influencer.engagementRate()}</Typography>
          </div>
        </div>
        <div className={classes.postStatsCont}>
          <div className={classes.postStat}>
            <Typography>Users</Typography>
            <Typography>XXX</Typography>
          </div>
          <div className={classes.postStat}>
            <Typography>Sessions</Typography>
            <Typography>XXX</Typography>
          </div>
        </div>
        <div className={classes.postStatsCont}>
          <div className={classes.postStat}>
            <Typography>Orders</Typography>
            <Typography>XXX</Typography>
          </div>
          <div className={classes.postStat}>
            <Typography>Gross Sales</Typography>
            <Typography>A$XXX</Typography>
          </div>
          <div className={classes.postStat}>
            <Typography>Discounts</Typography>
            <Typography>-A$XXX</Typography>
          </div>
          <div className={classes.postStat}>
            <Typography>Net Sales</Typography>
            <Typography>A$XXX</Typography>
          </div>
        </div>
      </div>
    </>
  );
};

InfluencerPost.propTypes = {
  post: PropTypes.shape().isRequired,
  influencer: PropTypes.shape().isRequired,
};

const CampaignAnaltyics = () => {
  const classes = campaignAnalyticsStyles();
  const content = useContentManager();
  const posts = content.posts || [];
  const campaign = content.campaign || '';
  const brand = content.brand || '';
  const auth = useAuth();
  const [noData] = useState(false);
  const influencers = campaign && campaign.influencers;
  const uniqInfs = influencers && checkUnique(influencers);
  const profiledInfs = uniqInfs && uniqInfs.map((inf) => Profile(inf));
  const [initialFetch, setInitialFetch] = useState(true);
  const publishedLength = posts && posts.filter((post) => post.is_published).length;
  const unpublishedLength = posts && posts.filter((post) => !post.is_published).length;
  const postTypes = typesOfPostsTally(posts);
  const pieData = Object.entries(postTypes).map((type) => ({
    angle: type[1],
    color: `${content.contentTypes[type[0]].pieColor}`,
  }));

  const isClient = auth.user.agency_role === 'client';

  const handleRequestAnaltyics = () => {
    // console.log('nothing yet');

    // Notification example,
    // Campaign Manager Internal Username requested you to Complete Intergration for Google Analytics

    // API({
    //   method: "post",
    //   url: `/account/${auth.account.id}/content-manager/notification/${content.post.id}/`,
    //   data: {
    //     campaign_id: content.campaign.id,
    //     brand_id: content.brand.id,
    //     internal_user_id: auth.user.id,
    //     template: 4,
    //     action: 0,
    //     status: 4,
    //     other: 0,
    //   }
    // });
  };

  const handleRequestShopify = () => {
    // console.log('nothing yet');

    // Notification example,
    // Campaign Manager Internal Username requested you to Complete Intergration for Shopify

    // API({
    //   method: "post",
    //   url: `/account/${auth.account.id}/content-manager/notification/${content.post.id}/`,
    //   data: {
    //     campaign_id: content.campaign.id,
    //     brand_id: content.brand.id,
    //     internal_user_id: auth.user.id,
    //     template: 4,
    //     action: 0,
    //     status: 4,
    //     other: 0,
    //   }
    // });
  };

  useEffect(() => {
    if (campaign && brand && initialFetch) {
      setInitialFetch(false);
      content.getCampaignAnalytics(
        auth.account.id,
        content.brand.id,
        content.campaign.id,
      );
    }
  }, [auth.account.id, content, brand, campaign, initialFetch]);

  return (
    <div className={classes.mainCont}>
      {/* NO DATA */}
      {noData ? (
        <div className={classes.noData}>
          <Typography variant="h5" style={{ marginBottom: '20px' }}>
            {' '}
            We need more data to generate analytics
          </Typography>
          <Typography style={{ marginBottom: '30px', color: 'grey' }}>
            There’s not enough data for us to generate anlaytics right now.
            Start publishing posts to speed things up.
          </Typography>
          <img
            style={{ borderRadius: '4px' }}
            src="https://placekitten.com/500/300"
            alt="temporary"
          />
        </div>
      ) : (
        <div>
          {/* TITLE1 */}
          <div className={classes.title}>
            <div>
              <Typography>
                {campaign.name || ''}
                {' '}
                Campaign Analytics
              </Typography>
              <Typography className={classes.datetime}>
                {`${formatDate(campaign.startdate) || ''} - ${
                  formatDate(campaign.enddate) || ''
                }`}
              </Typography>
            </div>
            <div className={classes.titleBtns}>
              <Button
                color="primary"
                variant="outlined"
                className={classes.titleBtn}
              >
                <RefreshIcon className={classes.icon} />
                {' '}
                Refresh
              </Button>
              <Button
                color="primary"
                variant="contained"
                className={classes.titleBtn}
              >
                <ImportIcon className={classes.icon} />
                {' '}
                Download Report
              </Button>
            </div>
          </div>

          {!isClient && (
            <>
              <RequestNotice
                title={`Request ${content.brand.username} to complete Google Analytics Integration`}
                body={`Request ${content.brand.username} to connect their Google Analytics account to track the traffic contributed by influencers on their website.`}
                btnLabel="Request Now"
                handleFunction={handleRequestAnaltyics}
              />
              <RequestNotice
                title={`Request ${content.brand.username} to complete Shopify Integration`}
                body={`Request ${content.brand.username} to connect their Shopify account to track the traffic contributed by influencers on their website.`}
                btnLabel="Request Now"
                handleFunction={handleRequestShopify}
              />
            </>
          )}
          {isClient && (
            <>
              <RequestNotice
                title="Integrate with Google Analytics"
                body="Connect your Google Analytics account to track the traffic contributed by influencers on your website. "
                btnLabel="Integrate Now"
                // handleFunction=
              />
              <RequestNotice
                title="Integrate with Shopify"
                body="Connect your Shopify account to track the sales contributed by influencers on your website."
                btnLabel="Integrate Now"
                // handleFunction=
              />
            </>
          )}

          {/* TITLE2 */}
          <div className={classes.overviewTitle}>
            <Typography className={classes.title2}>
              Campaign Overview
            </Typography>
            <img
              className={classes.logo}
              src={ScrunchLogoFull}
              alt="scrunch logo"
            />
          </div>

          <div className={classes.overviewGrid}>
            {/* INFLUENCERS */}
            <div
              className={classes.overviewCont}
              style={{ gridArea: 'influencers' }}
            >
              <Typography className={classes.contTitle}>Influencers</Typography>
              <Typography>{uniqInfs.length}</Typography>
              {/* <div className={classes.avatars}> */}
              {uniqInfs
                && uniqInfs.map((inf) => (
                  <Avatar
                    className={classes.infAvatar}
                    src={inf.profile_picture[0]}
                    alt={inf.full_name[0] || 'influencer full name'}
                    key={inf.full_name}
                  />
                ))}
              {/* </div> */}
              <div />
            </div>

            {/* POSTS */}
            <div className={classes.overviewCont} style={{ gridArea: 'posts' }}>
              <Typography className={classes.contTitle}>Posts</Typography>
              <Typography>{posts.length}</Typography>
              <div
                className={classes.postBar}
                style={{
                  display: 'grid',
                  gridTemplateRows: '1fr, 1fr',
                  gridTemplateColumns: `repeat(${posts.length}, 1fr)`,
                }}
              >
                <div
                  className={classes.publishedPosts}
                  style={{
                    gridColumn: `1 / ${publishedLength + 1}`,
                    gridRow: '1',
                  }}
                />
              </div>
              <div style={{ display: 'flex' }}>
                <Typography variant="body1" style={{ fontSize: '10px' }}>
                  {publishedLength}
                  {' '}
                  Posts Published
                </Typography>

                <Typography
                  variant="body1"
                  style={{ marginLeft: '50px', fontSize: '10px' }}
                >
                  {unpublishedLength}
                  {' '}
                  Posts Pending
                </Typography>
              </div>
              <div />
            </div>

            {/* TYPES OF POSTS */}
            <div className={classes.overviewCont} style={{ gridArea: 'types' }}>
              <Typography className={classes.contTitle}>
                Types Of Posts
              </Typography>
              <Typography>{typesOfPosts(posts).length}</Typography>
              <div className={classes.pieChart}>
                <RadialChart
                  data={pieData}
                  width={300}
                  height={300}
                  colorType="literal"
                />
                <div className={classes.contentList}>
                  {Object.entries(postTypes).map((type) => (
                    <div key={type} className={classes.pieList}>
                      <div
                        className={classes.pieListPoint}
                        style={{
                          backgroundColor:
                            content.contentTypes[type[0]].pieColor,
                        }}
                      />
                      <Typography style={{ fontSize: '10px' }}>
                        {`${type[1]} ${content.contentTypes[type[0]].type} `}
                      </Typography>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* IMPRESSION */}
            <div
              className={classes.overviewCont}
              style={{ gridArea: 'impressions' }}
            >
              <Typography className={classes.contTitle}>Impressions</Typography>
              <Typography>0</Typography>
              <div />
            </div>

            {/* SOCIAL REACH */}
            <div className={classes.overviewCont} style={{ gridArea: 'reach' }}>
              <Typography className={classes.contTitle}>
                Social Reach
              </Typography>
              <Typography>0</Typography>
              <div />
            </div>

            {/* ENGAGEMENTS */}
            <div className={classes.overviewCont} style={{ gridArea: 'eng' }}>
              <Typography className={classes.contTitle}>Engagements</Typography>
              <Typography>0</Typography>
              <div />
            </div>

            {/* Google Analytics and Shopify Metrics */}
            <div className={classes.metricGrid} style={{ gridArea: 'metrics' }}>
              {/* USERS */}
              <div
                className={classes.overviewCont}
                style={{ gridArea: 'users' }}
              >
                <Typography className={classes.contTitle}>Users</Typography>
                <Typography>0</Typography>
                <div />
                <GoogleFineprint />
              </div>

              {/* SESSIONS */}
              <div
                className={classes.overviewCont}
                style={{ gridArea: 'sessions' }}
              >
                <Typography className={classes.contTitle}>Sessions</Typography>
                <Typography>0</Typography>
                <div />
                <GoogleFineprint />
              </div>

              {/* TOTAL ORDERS */}
              <div
                className={classes.overviewCont}
                style={{ gridArea: 'orders' }}
              >
                <Typography className={classes.contTitle}>
                  Total Orders
                </Typography>
                <Typography>0</Typography>
                <div />
                <ShopifyFineprint />
              </div>

              {/* GROSS SALES */}
              <div
                className={classes.overviewCont}
                style={{ gridArea: 'gross' }}
              >
                <Typography className={classes.contTitle}>
                  Gross Sales
                </Typography>
                <Typography>0</Typography>
                <div />
                <ShopifyFineprint />
              </div>

              {/* DISCOUNTS */}
              <div
                className={classes.overviewCont}
                style={{ gridArea: 'discount' }}
              >
                <Typography className={classes.contTitle}>Discounts</Typography>
                <Typography>0</Typography>
                <div />
                <ShopifyFineprint />
              </div>

              {/* NET SALES */}
              <div className={classes.overviewCont} style={{ gridArea: 'net' }}>
                <Typography className={classes.contTitle}>Net Sales</Typography>
                <Typography>0</Typography>
                <div />
                <ShopifyFineprint />
              </div>
            </div>
          </div>

          {/* INDEPTH INFLUENCER ANALYSIS */}
          <div className={classes.audienceAnalysis}>
            <Typography>Indepth Influencer Analysis</Typography>
            <img
              className={classes.logo}
              src={ScrunchLogoFull}
              alt="scrunch logo"
            />

            {profiledInfs
              && profiledInfs.map((inf) => (
                <div key={inf}>
                  <div className={classes.influencer}>
                    <Avatar
                      className={classes.infPic}
                      src={inf.profilePicture()}
                      alt={inf.fullName()}
                    />
                    <div className={classes.infInfo}>
                      <div className={classes.namePosts}>
                        <Typography>{inf.fullName()}</Typography>
                        <Typography>
                          {posts
                            && posts.filter(
                              (post) => post.influencer_id === inf.networkId(),
                            ).length}
                          {' '}
                          Posts
                        </Typography>
                      </div>
                      <div className={classes.p}>
                        {inf.gender() && (
                          <Chip
                            className={classes.infChip}
                            label={getGenderLabel(inf.gender())}
                          />
                        )}
                        {inf.locationCountry() && (
                          <Chip
                            className={classes.infChip}
                            label={inf.locationCountry()}
                          />
                        )}
                        {inf.audienceAge() && (
                          <Chip
                            className={classes.infChip}
                            label={inf.audienceAge()}
                          />
                        )}
                        {/* <Chip className={classes.infChip} label={inf.??} /> */}
                      </div>
                      <p className={classes.p}>
                        @
                        {inf.handle()}
                      </p>
                      <div className={classes.p}>
                        {inf.locationCountry() && (
                          <Chip
                            className={classes.infChip}
                            label={inf.locationCountry()}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    {/* POSTS */}
                    {posts
                      .filter((post) => post.influencer_id === inf.networkId())
                      .map((post) => (
                        <InfluencerPost post={post} influencer={inf} />
                      ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignAnaltyics;
