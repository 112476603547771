import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  main: {
    // display: "flex",
    border: `2px solid ${theme.colors.system.grey2}`,
    borderRadius: '4px',
    padding: '20px',
    height: 'fit-content',
  },
  video: {
    height: '250px',
    width: '400px',
    borderRadius: '8px',
  },
  title: {
    fontSize: '16px',
    margin: '10px 0',
  },
  body: {
    marginBottom: '10px',
    color: theme.colors.system.grey6,
  },
}));

const EmptyStateList = (props) => {
  const {
    title, body, buttonLabel, video, handleClick,
  } = props;
  const classes = useStyles();

  return (
    <>
      <div className={classes.main}>
        <iframe
          title="uniqueness"
          className={classes.video}
          src={`https://www.youtube.com/embed/${video}`}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          frameBorder="0"
        />
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.body}>{body}</Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={handleClick}
          size="small"
        >
          {buttonLabel}
        </Button>

      </div>

    </>
  );
};

EmptyStateList.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  video: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default EmptyStateList;
