import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import {
  Button,
  Link,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from '@reach/router';

import AuthPageBase from '../AuthPageBase';
import { Alert, PasswordTextField } from '../../../common';
import { useApi, useRoute } from '../../../../hooks';

const useStyles = makeStyles((theme) => ({
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  field: {
    width: '100%',
    margin: theme.spacing(1),
  },
  button: {
    width: '100%',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
  },
  footer: {
    paddingTop: theme.spacing(3),
    padding: theme.spacing(1),
    textAlign: 'center',
  },
}));

const PasswordResetConfirmPage = (props) => {
  const { token } = props;
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [, setRoute] = useRoute();
  const classes = useStyles();
  const [{ isFetching, isSuccess, isError }, setRequest] = useApi();

  useEffect(() => {
    if (isSuccess) {
      setRoute({ pathname: '/' });
    }
  }, [isSuccess, setRoute]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setPasswordError(
      password.length < 6 ? 'Password must be at least 6 characters long.' : '',
    );

    if (password && password.length >= 6) {
      setRequest({
        method: 'post',
        url: '/auth/password-reset/confirm/',
        data: {
          new_password: password,
          token,
        },
      });
    }
  };

  return (
    <AuthPageBase
      headerTitle="Confirm your new password"
      title="Confirm password"
    >
      {isError && (
        <Alert severity="error">
          <Typography variant="body2">
            Could not reset password, check the link in your email or
            {' '}
            <Link component={RouterLink} to="/auth/password-reset">
              request
            </Link>
            {' '}
            another.
          </Typography>
        </Alert>
      )}
      {!isSuccess && !isError && (
        <div>
          <form onSubmit={handleSubmit}>
            <div className={classes.row}>
              <PasswordTextField
                className={classes.field}
                label="New password"
                required
                disabled={isFetching}
                error={Boolean(passwordError)}
                helperText={passwordError}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <Button
              className={classes.button}
              type="submit"
              variant="contained"
              color="primary"
              disabled={isFetching}
              onClick={handleSubmit}
            >
              Submit new password
            </Button>
          </form>
          <div className={classes.footer}>
            <Typography variant="body2">
              Remembered your password?
              {' '}
            </Typography>
            <Link component={RouterLink} to="/auth/login">
              Log in here.
            </Link>
          </div>
        </div>
      )}
    </AuthPageBase>
  );
};

PasswordResetConfirmPage.propTypes = {
  token: PropTypes.string,
};

PasswordResetConfirmPage.defaultProps = {
  token: null,
};

export default PasswordResetConfirmPage;
