import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FilterBase from './FilterBase';
import Select from '../../common/Select';

const ages = ['18-24', '25-34', '35-44', '45-54', '55-64', '65+'];

const useStyles = makeStyles((theme) => ({
  inputs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  input: {
    width: 150,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  text: {
    width: 100,
    color: theme.palette.grey[600],
    textAlign: 'center',
  },
}));

const AgeFilter = (props) => {
  const {
    className,
    filterData,
    label,
    onApply,
  } = props;
  const [activeText, setActiveText] = useState(null);
  const [ageRange, setAgeRange] = useState('');
  const classes = useStyles();

  useEffect(() => {
    const { age } = filterData;
    if (age) {
      setActiveText(age);
      setAgeRange(age);
    } else {
      setActiveText(null);
      setAgeRange(null);
    }
  }, [filterData]);

  const handleOnApply = () => {
    onApply({ ...filterData, age: ageRange || null });
  };

  const handleOnClear = () => {
    onApply({ ...filterData, age: null });
  };

  return (
    <FilterBase
      className={className}
      label={label}
      activeText={activeText}
      isActive={Boolean(activeText)}
      onApply={handleOnApply}
      onClear={handleOnClear}
    >
      <div className={classes.inputs}>
        <Select
          className={classes.input}
          label="Age range"
          value={ageRange}
          onChange={(e) => setAgeRange(e.target.value !== '' ? e.target.value : null)}
        >
          {ages.map((it) => (
            <MenuItem key={it} value={it}>
              {it}
            </MenuItem>
          ))}
        </Select>
      </div>
    </FilterBase>
  );
};

AgeFilter.propTypes = {
  className: PropTypes.string,
  filterData: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  onApply: PropTypes.func.isRequired,
};

AgeFilter.defaultProps = {
  className: '',
};

export default AgeFilter;
