import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { ButtonBase, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import AgeFilter from './filters/AgeFilter';
import AudienceAgeFilter from './filters/AudienceAgeFilter';
import AudienceGenderFilter from './filters/AudienceGenderFilter';
import AudienceLocationFilter from './filters/AudienceLocationFilter';
import EngagementRateFilter from './filters/EngagementRateFilter';
import GenderFilter from './filters/GenderFilter';
import LocationFilter from './filters/LocationFilter';
import NetworkFilter from './filters/NetworkFilter';
import OtherFilter from './filters/OtherFilter';
import SocialReachFilter from './filters/SocialReachFilter';
import SortFilter from './filters/SortFilter';
import TopicFilter from './filters/TopicFilter';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  filter: {
    marginRight: 5,
    marginBottom: 5,
  },
  filterBtn: {
    cursor: 'pointer',
    border: `1px solid ${theme.colors.primary.main}`,
    borderRadius: 2,
    height: 'fit-content',

    '&:hover': {
      backgroundColor: theme.colors.system.grey2,
    },
  },
  filterButton: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '5px 10px 5px 10px',
  },
}));

const filters = [
  {
    filterKeys: ['network'],
    FilterComponent: NetworkFilter,
    label: 'Network',
  },
  {
    filterKeys: ['social_reach'],
    FilterComponent: SocialReachFilter,
    label: 'Social reach',
  },
  {
    filterKeys: ['engagement_rate'],
    FilterComponent: EngagementRateFilter,
    label: 'Engagement rate',
  },
  {
    filterKeys: ['location'],
    FilterComponent: LocationFilter,
    label: 'Location',
  },
  {
    filterKeys: ['audience_location'],
    FilterComponent: AudienceLocationFilter,
    label: 'Audience location',
  },
  {
    filterKeys: ['topic'],
    FilterComponent: TopicFilter,
    label: 'Topic',
  },
  {
    filterKeys: ['audience_topic'],
    FilterComponent: TopicFilter,
    label: 'Audience topic',
  },
  {
    filterKeys: ['age'],
    FilterComponent: AgeFilter,
    label: 'Age',
  },
  {
    filterKeys: ['audience_age'],
    FilterComponent: AudienceAgeFilter,
    label: 'Audience age',
  },
  {
    filterKeys: ['gender'],
    FilterComponent: GenderFilter,
    label: 'Gender',
  },
  {
    filterKeys: ['audience_gender'],
    FilterComponent: AudienceGenderFilter,
    label: 'Audience gender',
  },
  {
    filterKeys: ['has_email', 'is_in_list', 'is_not_in_list'],
    FilterComponent: OtherFilter,
    label: 'Other',
  },
  {
    filterKeys: ['sort'],
    FilterComponent: SortFilter,
    label: 'Sort by',
  },
];

const FilterBar = (props) => {
  const { className, filterData, onChange } = props;
  const classes = useStyles();

  const [showAllOptions, setShowAllOptions] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const wrapperWidth = 0.8 * window.innerWidth;
  const totalLabelWidth = filters.length * 125;

  if (wrapperWidth > totalLabelWidth) {
    if (!showAllOptions) {
      setShowAllOptions(true);
    }
  }

  const abridgedTopics = (topics) => `${topics.slice(0, 25)}...`;
  if (filterData.topic && filterData.topic.length > 25) {
    filterData.topic = abridgedTopics(filterData.topic);
  }
  if (filterData.audience_topic && filterData.audience_topic.length > 25) {
    filterData.audience_topic = abridgedTopics(filterData.audience_topic);
  }

  const filtersToShow = filters.slice(0, showAllOptions || expanded ? filters.length : 6);

  return (
    <div className={`${classes.root} ${className}`}>
      {filtersToShow.map((it) => {
        const { FilterComponent, filterKeys, label } = it;
        return (
          <FilterComponent
            className={classes.filter}
            filterData={filterData}
            filterKeys={filterKeys}
            key={filterKeys.join('|')}
            label={label}
            onApply={onChange}
          />
        );
      })}
      {!showAllOptions && (
        <div className={classes.filterBtn}>
          <ButtonBase
            className={classes.filterButton}
            onClick={() => setExpanded(!expanded)}
          >
            <Typography
              className={classes.label}
              variant="body2"
              color="primary"
            >
              {expanded ? 'Show less' : 'Show more'}
            </Typography>
          </ButtonBase>
        </div>
      )}
    </div>
  );
};

FilterBar.propTypes = {
  className: PropTypes.string,
  filterData: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func.isRequired,
};

FilterBar.defaultProps = {
  className: null,
  filterData: {},
};

export const isFilterParam = (key) => filters.some((it) => it.filterKeys.includes(key));
export default FilterBar;
