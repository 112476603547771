import React, { useEffect, useRef, useState } from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import domtoimage from 'dom-to-image';

import ScrunchLogo from '../../assets/images/scrunch-logo-full.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '40px 0 20px',
    borderBottom: `1px solid ${theme.colors.system.grey5}`,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  heading: {
    ...theme.textStyles.bodyLarge,
  },
  scrunchLogo: {
    width: 70,
  },
  bubbleChart: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px 0',
  },
}));

const CampaignOverview = ({
  campaignOverviewData: {
    influencers,
    piecesOfContent,
    engagements,
    extraMetric,
    socialReach,
  },
}) => {
  const classes = useStyles();

  const svgRef = useRef(null);
  const [imgSrc, setImgSrc] = useState('');
  const [showSvg, setShowSvg] = useState(true);

  const setImageUrl = (dataUrl) => {
    setImgSrc(dataUrl);
    setShowSvg(false);
  };

  useEffect(() => {
    if (!imgSrc) {
      const input = svgRef.current;
      domtoimage.toPng(input).then((dataUrl) => setImageUrl(dataUrl));
    }
  }, [svgRef, imgSrc]);

  const getExtraMetric = () => {
    if (extraMetric.length > 10) {
      if (extraMetric.indexOf(' ') !== -1) {
        return extraMetric.split(' ');
      }
      return [extraMetric.substring(0, 13), extraMetric.substring(13)];
    }
    return [extraMetric];
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5" className={classes.heading}>Campaign Overview</Typography>
        <img src={ScrunchLogo} alt="Scrunch" className={classes.scrunchLogo} />
      </div>

      <div className={classes.bubbleChart} id="svgRoot" ref={svgRef} style={{ display: showSvg ? 'flex' : 'none' }}>
        <svg width="460" height="324" viewBox="0 0 557 364" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="42.5523" y1="56.9033" x2="94.5523" y2="180.903" stroke="#777777" strokeWidth="0.5" strokeDasharray="2 2" />
          <line x1="42.3429" y1="56.7509" x2="266.343" y2="75.7509" stroke="#777777" strokeWidth="0.5" strokeDasharray="2 2" />
          <line x1="220.078" y1="275.944" x2="266.078" y2="75.944" stroke="#777777" strokeWidth="0.5" strokeDasharray="2 2" />
          <line x1="93.1912" y1="181.787" x2="266.191" y2="75.7868" stroke="#777777" strokeWidth="0.5" strokeDasharray="2 2" />
          <line x1="93.4674" y1="181.797" x2="220.467" y2="272.797" stroke="#777777" strokeWidth="0.5" strokeDasharray="2 2" />
          <line x1="438.353" y1="246.248" x2="220.353" y2="273.248" stroke="#777777" strokeWidth="0.5" strokeDasharray="2 2" />
          <line x1="438.143" y1="246.175" x2="269.143" y2="74.1752" stroke="#777777" strokeWidth="0.5" strokeDasharray="2 2" />
          <g>
            <circle cx="41.8218" cy="58.5" r="41.5" fill="#E75041" />
            <text x="41.8218" y="58.5" textAnchor="middle" fill="white">
              <tspan y="54">{influencers}</tspan>
              <tspan x="40" y="66" style={{ fontSize: 12 }}>{influencers === 1 ? 'Influencer' : 'Influencers'}</tspan>
            </text>
          </g>
          <g>
            <circle cx="93.8218" cy="181.5" r="60.5" fill="#E75041" />
            <text x="93.8218" y="181.5" textAnchor="middle" fill="white">
              <tspan y="170.5">
                {piecesOfContent}
                &nbsp;
                {piecesOfContent === 1 ? 'Piece' : 'Pieces'}
              </tspan>
              <tspan x="93" y="192.5">of Content</tspan>
            </text>
          </g>
          <g>
            <circle cx="268.822" cy="81.5" r="81.5" fill="#E75041" />
            <text x="268.822" y="81.5" textAnchor="middle" fill="white">
              <tspan y="71" style={{ fontSize: 18 }}>{engagements}</tspan>
              <tspan x="268.818" y="91" style={{ fontSize: 18 }}>
                {engagements === 1 ? 'Engagement' : 'Engagements'}
              </tspan>
            </text>
          </g>
          <g>
            <circle cx="219.822" cy="272.5" r="65.5" fill="#E75041" />
            {extraMetric && (
              <text x="219" y="272" textAnchor="middle" fill="white">
                {
                  getExtraMetric().map((metric, index) => (
                    <tspan x="219" y={265 + index * 15} style={{ fontSize: 14 }}>{metric}</tspan>
                  ))
                }
              </text>
            )}
            {!extraMetric && (
              <foreignObject x="180" y="235" width="80" height="120" fill="white">
                <p style={{ color: 'white', textAlign: 'center', overflowWrap: 'anywhere' }}>
                  No Extra Metric
                </p>
              </foreignObject>
            )}
          </g>
          <g>
            <circle cx="437.822" cy="245.5" r="118.5" fill="#E75041" />
            <text x="437.822" y="245.5" textAnchor="middle" fill="white">
              <tspan y="230" style={{ fontSize: 24 }}>{socialReach}</tspan>
              <tspan x="437.824" y="260" style={{ fontSize: 24 }}>Social Reach</tspan>
            </text>
          </g>
        </svg>
      </div>

      <img src={imgSrc} style={{ width: '100%' }} alt="Campaign Overview" />
    </div>
  );
};

CampaignOverview.propTypes = {
  campaignOverviewData: PropTypes.shape().isRequired,
};

export default CampaignOverview;
