import React from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 800,
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  deleteForever: {
    minWidth: 250,
    minHeight: 40,
    backgroundColor: theme.colors.danger.main,
  },
  dialogSubtitle: {
    color: theme.colors.system.grey7,
    textAlign: 'center',
    width: '80%',
    margin: '15px auto 0',
  },
}));

const DeleteListDialog = ({
  showDeleteDialog,
  setShowDeleteDialog,
  listName,
  deleteList,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={showDeleteDialog}
      onBackdropClick={() => setShowDeleteDialog(false)}
      classes={{ paper: classes.paper }}
    >
      <IconButton aria-label="close" className={classes.closeButton} onClick={() => setShowDeleteDialog(false)}>
        <CloseIcon />
      </IconButton>

      <DialogTitle style={{ marginBottom: 20 }}>
        <Typography
          variant="h5"
          component="h5"
          style={{ marginBottom: 10 }}
        >
          Delete List
        </Typography>
        <Typography variant="body2" component="p" className={classes.dialogSubtitle}>
          This list and all the data associated with the list will be deleted permanently.
          &nbsp;If this list was shared as a public list, it won’t be accessible.
        </Typography>
      </DialogTitle>

      <DialogContent>
        <TextField
          placeholder="Enter name of the list to be deleted"
          label="List Name"
          value={listName}
          fullWidth
          disabled
        />
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          onClick={() => deleteList()}
          variant="contained"
          className={classes.deleteForever}
          size="small"
        >
          Delete Forever
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteListDialog.propTypes = {
  showDeleteDialog: PropTypes.bool.isRequired,
  setShowDeleteDialog: PropTypes.func.isRequired,
  listName: PropTypes.string.isRequired,
  deleteList: PropTypes.func.isRequired,
};

export default DeleteListDialog;
