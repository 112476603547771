import React, {
  useEffect,
  useState,
} from 'react';

import PropTypes from 'prop-types';

import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import CompactProfileList from './CompactProfileList';
import ListSelectOrCreateField from '../common/ListSelectOrCreateField';
import {
  useApi,
  useAuth,
  useNotification,
  useRoute,
} from '../../hooks';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    '&>:not(last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  title: {
    marginBottom: 30,
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
}));

const AddProfilesToListDialog = ({ defaultListMode, onClose, profiles }) => {
  const [listValue, setListValue] = useState(null);
  const [listValueError, setListValueError] = useState(null);
  const [{ response: postListItemResponse, isFetching: isPostListItemFetching }, setPostListItemRequest] = useApi();
  const [{ response: postListResponse, isFetching: isPostListFetching }, setPostListRequest] = useApi();
  const { createNotification } = useNotification();
  const [, setRoute] = useRoute();
  const auth = useAuth();
  const classes = useStyles();

  useEffect(() => {
    if (postListItemResponse && postListItemResponse.status >= 200 && postListItemResponse.status < 300) {
      createNotification(
        'success',
        `${profiles.length} profiles added to list`,
      );
      onClose();
    }
  }, [createNotification, onClose, profiles, postListItemResponse, setRoute]);

  useEffect(() => {
    if (postListResponse && postListResponse.status >= 200 && postListResponse.status < 300) {
      createNotification(
        'success',
        `${profiles.length} profiles added to list`,
      );
      onClose();
    }
  }, [createNotification, onClose, profiles, postListResponse, setRoute]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (listValue && listValue.mode === 'select' && listValue.selectedList) {
      setPostListItemRequest({
        method: 'patch',
        url: `/account/${auth.account.id}/list/${listValue.selectedList.id}/`,
        data: {
          network_ids_to_add: profiles.map(
            (item) => item.data.network_id,
          ).reduce(
            (allNetworkIds, profileNetworkIds) => allNetworkIds.concat(profileNetworkIds),
            [],
          ),
        },
      });
    } else if (listValue && listValue.mode === 'create' && listValue.newListName) {
      const accountId = auth.account.id;
      setPostListRequest({
        method: 'post',
        url: `/account/${auth.account.id}/list/`,
        data: {
          account_id: accountId,
          name: listValue.newListName,
          network_ids: profiles.map(
            (item) => item.data.network_id,
          ).reduce(
            (allNetworkIds, profileNetworkIds) => allNetworkIds.concat(profileNetworkIds),
            [],
          ),
        },
      });
    } else {
      setListValueError('List must be selected or name for new list entered.');
    }
  };

  const isFetching = isPostListFetching || isPostListItemFetching;

  return (
    <Dialog
      open
      onClose={onClose}
      scroll="paper"
    >
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <DialogTitle className={classes.title}>
        {`Add ${profiles.length} profile(s) to a list`}
      </DialogTitle>
      <ListSelectOrCreateField
        defaultMode={defaultListMode}
        error={Boolean(listValueError)}
        helperText={listValueError}
        onChange={setListValue}
      />
      <DialogContent className={classes.dialogContent}>
        <CompactProfileList profiles={profiles} />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isFetching}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Add to list
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddProfilesToListDialog.propTypes = {
  defaultListMode: PropTypes.oneOf(['select', 'create']),
  onClose: PropTypes.func.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

AddProfilesToListDialog.defaultProps = {
  defaultListMode: 'select',
};

export default AddProfilesToListDialog;
