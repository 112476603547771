import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Button,
  Typography,
  TextField,
  DialogActions,
  DialogContent,
  Avatar,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
// import DateRangeIcon from '@material-ui/icons/DateRange';
import { makeStyles } from '@material-ui/core/styles';
import ScrDialog from '../common/ScrDialog';
import { useAuth, useNotification } from '../../hooks';
import API from '../../utils/api';

const useStyles = makeStyles(() => ({
  dateCont: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  btn: {
    height: '2.5rem',
    width: '13rem',
  },
  littleText: {
    marginTop: '5px',
    fontSize: '12px',
    color: 'lightgrey',
  },
  date: {
    width: '48%',
  },
  avatar: {
    height: '25px',
    width: '25px',
    marginLeft: '10px',
    fontSize: '12px',
  },
}));

const NewCampaignDialog = ({
  isOpen, onClose, brand,
}) => {
  const classes = useStyles();
  const auth = useAuth();
  const [campName, setCampName] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [startDate, setStartDate] = useState(moment(Date()));
  const [endDate, setEndDate] = useState();
  const { createNotification } = useNotification();

  const handleSubmit = async () => {
    setIsFetching(true);

    const response = await API({
      method: 'post',
      url: `/account/${auth.account.id}/content-manager/brand/${brand.id}/`,
      data: {
        name: campName,
        start_date: startDate.format(),
        end_date: endDate.format(),
        color: `#${(Math.random() * 0xfffff * 1000000)
          .toString(16)
          .slice(0, 6)}`,
      },
    });
    if (response.status === 200) {
      setIsFetching(false);
      onClose();
      createNotification('success', 'Campaign Saved');
      setCampName('');
      setStartDate(moment(Date()));
      setEndDate();
    } else {
      createNotification('error', 'Error Campaign Not Saved');
    }
  };

  const handleNameChange = (e) => {
    setCampName(e.target.value);
  };

  return (
    <ScrDialog
      isFetching={isFetching}
      isOpen={isOpen}
      onClose={onClose}
      title="New Campaign"
      subTitle="Create campaigns to bifurcate a brand's marketing initiatives"
      titleStyle={{ marginBottom: '20px' }}
    >
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DialogContent>
          <Typography
            variant="body1"
            component="span"
            style={{ marginBottom: '10px' }}
          >
            Brand Profile
          </Typography>
          <TextField
            margin="dense"
            type="name"
            // helperText={}
            // error={}
            // required
            disabled
            value={(brand && brand.username) || ''}
            fullWidth
            style={{ marginBottom: '30px' }}
            InputProps={{
              startAdornment: (
                <Avatar
                  alt={brand && brand.username}
                  className={classes.avatar}
                  src="#"
                />
              ),
            }}
          />
          <Typography
            style={{ marginBottom: '10px' }}
            variant="body1"
            component="span"
          >
            Campaign Name
          </Typography>
          <TextField
            margin="dense"
            type="text"
            value={campName || ''}
            onChange={handleNameChange}
            // helperText={}
            // error={}
            required
            fullWidth
            style={{ marginBottom: '30px' }}
            data-testid="campName"
          />
          <div className={classes.dateCont}>
            <div className={classes.date}>
              <Typography variant="body1" component="span">
                Start Date
              </Typography>
              <KeyboardDatePicker
                fullWidth
                margin="dense"
                format="DD/MM/yyyy"
                value={startDate || Date()}
                placeholder="Pick your Date"
                onChange={(date) => {
                  setStartDate(date);
                  if (!endDate) setEndDate(date);
                }}
                minDate={Date()}
                className={classes.input}
              />
              {/* <TextField
                margin="dense"
                // type="text"
                fullWidth
                // required
                // error={}
                // helperText={}
                // value={}
                // defaultValue={}
                // onChange={}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DateRangeIcon style={{ marginLeft: "7.5px" }} />
                    </InputAdornment>
                  ),
                }}
              /> */}
            </div>
            <div className={classes.date}>
              <Typography variant="body1" component="span">
                End Date
              </Typography>
              <KeyboardDatePicker
                fullWidth
                format="DD/MM/yyyy"
                margin="dense"
                value={endDate || ''}
                placeholder="Pick your Date"
                onChange={(date) => setEndDate(date)}
                minDate={startDate}
                className={classes.input}
              />
              {/* <TextField
                // required
                // value={}
                // defaultValue={}
                // error={}
                // helperText={}
                // onChange={}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DateRangeIcon style={{ marginLeft: "7.5px" }} />
                    </InputAdornment>
                  ),
                }}
              /> */}
            </div>
          </div>
          <Typography className={classes.littleText}>
            Campaign posts wihtout a date beome a part of the POST IDEAS section
          </Typography>
        </DialogContent>
        <DialogActions style={{ marginTop: '30px' }}>
          <Button
            className={classes.btn}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={campName.length < 3 || !endDate}
            data-testid="button-test"
            aria-label="create now"
          >
            Create Now
          </Button>
        </DialogActions>
      </MuiPickersUtilsProvider>
    </ScrDialog>
  );
};

NewCampaignDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  brand: PropTypes.shape(),
};

NewCampaignDialog.defaultProps = {
  brand: null,
  isOpen: false,
  onClose: null,
};

export default NewCampaignDialog;
