import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import WarningIcon from '@material-ui/icons/Warning';

import MenuPageBase, { Menu, MenuItem } from '../MenuPageBase';
import ClaimProfileContent from './ClaimProfileContent';
import { useApi, useAuth, useRoute } from '../../../hooks';
import profiles from './profiles';

const useStyles = makeStyles((theme) => ({
  successCheck: {
    color: theme.colors.success.main,
    fontSize: 15,
    marginRight: 8,
  },
  failedCheck: {
    color: theme.colors.warning.main,
    fontSize: 15,
    marginRight: 8,
  },
}));

const ClaimProfile = ({ networkName }) => {
  const classes = useStyles();
  const [route, setRoute] = useRoute();
  const auth = useAuth();

  const networkData = profiles.find((network) => network.title.toUpperCase() === networkName.toUpperCase());

  if (!networkData) {
    setRoute({
      pathname: '/404',
    });
  }

  const [{ data: verifiedProfiles }] = useApi({
    url: `/account/${auth.account.id}/profile/influencer/list/`,
    method: 'get',
  });

  const getNetworkIcon = (network) => {
    const selectedNetwork = profiles.find((n) => n.title.toUpperCase() === network.toUpperCase());
    return selectedNetwork.icon;
  };

  const getNetworkLabel = (networkId) => {
    const netId = networkId.split(':');
    return netId.length === 3 ? netId[2] : networkId;
  };

  return (
    <MenuPageBase
      title="Claim Your Profiles"
      subtitle="Claim your profiles, start editting your details and get yourself noticed by campaign managers"
      backLink={{
        link: '/',
        label: 'Go Back To Dashboard',
      }}
      menu={(
        <Menu>
          <MenuItem
            // Icon={it.icon}
            key={networkName}
            label={`Claim ${networkData.title} Profile`}
            onClick={() => setRoute({ pathname: `/claim-profile/${networkName}` })}
            selected={route.pathname === `/claim-profile/${networkName}`}
          />
          {verifiedProfiles && verifiedProfiles.reverse().map((it) => (
            <MenuItem
              Icon={getNetworkIcon(it.network_type)}
              key={it.network_id}
              label={getNetworkLabel(it.network_id)}
              onClick={() => setRoute({ pathname: `/claim-profile/${networkName}/profile/verify/${it.network_id}` })}
              selected={
                route.pathname === `/claim-profile/${networkName}/profile/claim/${it.network_id}`
                || route.pathname === `/claim-profile/${networkName}/profile/verify/${it.network_id}`
              }
              endIcon={it.is_approved !== 0 ? (
                <CheckCircleRoundedIcon className={classes.successCheck} />
              ) : (
                <WarningIcon className={classes.failedCheck} />
              )}
            />
          ))}
        </Menu>
      )}
    >
      <ClaimProfileContent networkData={networkData} verifiedProfiles={verifiedProfiles} />
    </MenuPageBase>
  );
};

ClaimProfile.propTypes = {
  networkName: PropTypes.string,
};

ClaimProfile.defaultProps = {
  networkName: 'instagram',
};

export default ClaimProfile;
