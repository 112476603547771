import React from 'react';
import * as Sentry from '@sentry/react';

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import Router from './components/router/Router';
import { theme } from './theme';

import './App.css';
import Config from './config';

if (Config.SENTRY_DSN) {
  Sentry.init({
    dsn: Config.SENTRY_DSN,
    environment: Config.SENTRY_ENVIRONMENT,
  });
}

const App = () => (
  <MuiThemeProvider theme={theme}>
    <Router />
  </MuiThemeProvider>
);

export default App;
