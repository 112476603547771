import React from 'react';

import { SvgIcon } from '@material-ui/core';

const List = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <path
      style={{ strokeWidth: 2.4000001 }}
      id="path87"
      d="M 17.4,1.2 C 13.926,1.2 12,3.660168 12,3.660168 12,3.660168 10.074,1.2 6.6,1.2 2.9544,1.2 0,4.098048 0,7.67412 0,12.583848 5.8944,17.341728 7.5372,18.844896 9.4296,20.575248 12,22.8 12,22.8 c 0,0 2.5704,-2.224752 4.4628,-3.955104 C 18.1056,17.341728 24,12.583848 24,7.67412 24,4.098048 21.0456,1.2 17.4,1.2 Z"
    />
  </SvgIcon>
);

export default List;
