import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, TextField, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

import AccessLockTooltip from '../common/AccessLockTooltip';
import { useAuth } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  pageHeading: {
    height: 50,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 30,
  },
  detailsRoot: {
    width: '100%',
    minHeight: 400,
    backgroundColor: theme.colors.system.grey1,
    borderRadius: 4,
    border: `1px solid ${theme.colors.system.grey3}`,
    padding: 20,
  },
  sectionHeading: {
    fontSize: 18,
    marginBottom: 20,
  },
  label: {
    '& span': {
      fontWeight: 600,
      lineHeight: 0,
    },
  },
  campaignLinks: {
    backgroundColor: '#FFFFFF',
    margin: '8px 0 15px',
  },
}));

const ReportDetails = ({ disabled, handleSubmit }) => {
  const classes = useStyles();

  const [urls, setUrls] = useState('');
  const [extraMetric, setExtraMetric] = useState('');

  const auth = useAuth();

  return (
    <>
      <div className={classes.pageHeading}>
        <Typography variant="h5" component="h5" style={{ marginRight: 5 }}>Campaign Reports</Typography>
        {!auth.hasPermission('report.create') && (
          <AccessLockTooltip
            title="Campaign Reports"
          >
            Upgrade now and unlock unlimited campaign reporting cabilities
          </AccessLockTooltip>
        )}
      </div>

      <div className={classes.detailsRoot}>
        <Typography variant="h6" component="h6" className={classes.sectionHeading}>Report Details</Typography>
        <div className={classes.label}>
          <Typography variant="body2" component="span">
            Enter your campaign live links to report on your Influencer Marketing success.&nbsp;
          </Typography>
          <Typography variant="body2" component="span" color="primary">(max 3 per influencer)</Typography>
        </div>
        <TextField
          disabled={disabled}
          fullWidth
          multiline
          onChange={(e) => setUrls(e.target.value)}
          placeholder={'https://www.instagram.com/p/B6mFRvYpx71/\nhttps://www.instagram.com/p/CDufi9sJUs4/\nhttps://www.instagram.com/p/B6OtpOCBVc_/'}
          required
          rows={3}
          type="textarea"
          className={classes.campaignLinks}
          value={urls}
        />

        <div className={classes.label}>
          <Typography variant="body2" component="span">
            Extra Metric
          </Typography>
        </div>
        <TextField
          disabled={disabled}
          fullWidth
          onChange={(e) => {
            if (e.target.value.length <= 20) {
              setExtraMetric(e.target.value);
            }
          }}
          placeholder="e.g. 50 impressions"
          type="input"
          className={classes.campaignLinks}
          value={extraMetric}
        />

        <Button
          fullWidth
          color="primary"
          disabled={!urls || disabled}
          onClick={() => handleSubmit({
            post_urls: urls.split(/\r?\n/),
            extra_metric: extraMetric,
          })}
          variant="contained"
          style={{ padding: '13px 0', marginTop: 8 }}
          size="small"
        >
          Generate Report
        </Button>
      </div>
    </>
  );
};

ReportDetails.propTypes = {
  disabled: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};

ReportDetails.defaultProps = {
  disabled: false,
};

export default ReportDetails;
