import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';

import {
  IconButton,
  Typography,
  Tooltip,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';

const useStyles = makeStyles((theme) => ({
  lockIcon: {
    color: theme.colors.system.grey7,
    fontSize: 14,
  },
  popoverRoot: {
    backgroundColor: 'white',
    color: theme.colors.text.main,
    padding: theme.spacing(1),
    boxShadow: theme.shadows[1],
    width: 150,
  },
  cont: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    ...theme.textStyles.bodyExtraSmall,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  desc: {
    ...theme.textStyles.bodyExtraSmall,
    textAlign: 'center',
    fontWeight: 300,
    marginBottom: theme.spacing(2),
    '& *': {
      ...theme.textStyles.bodyExtraSmall,
    },
  },
  btn: {
    ...theme.textStyles.bodyExtraSmall,
  },
}));

const LockPopover = ({ title, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.cont}>
      <Typography variant="body2" className={classes.title}>
        {title}
      </Typography>

      <Typography variant="body2" component="p" className={classes.desc}>
        {children}
      </Typography>

      <Button
        variant="contained"
        color="primary"
        size="small"
        component={Link}
        to="/settings/plans"
        className={classes.btn}
      >
        Unlock Now
      </Button>
    </div>
  );
};

LockPopover.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

LockPopover.defaultProps = {
  children: null,
};

const AccessLockTooltip = ({ title, children }) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={(
        <>
          <LockPopover
            title={title}
          >
            {children}
          </LockPopover>
        </>
      )}
      placement="right-start"
      classes={{
        tooltip: classes.popoverRoot,
      }}
      interactive
    >
      <IconButton>
        <LockIcon className={classes.lockIcon} />
      </IconButton>
    </Tooltip>
  );
};

AccessLockTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

AccessLockTooltip.defaultProps = {
  children: null,
};

export default AccessLockTooltip;
