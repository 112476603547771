import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  Button,
  Select,
  Typography,
  TextField,
  MenuItem,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { makeStyles } from '@material-ui/core/styles';

import popularSections from '../discover/popularSections';
import NoMatch from '../../router/NoMatch';
import SettingsPageBase from './SettingsPageBase';
import API from '../../../utils/api';
import { useAuth, useNotification, useApi } from '../../../hooks';

const useStyles = makeStyles((theme) => ({
  top: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '45vw',
  },
  bottom: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '45vw',
  },
  row: {
    width: '45%',
    marginRight: '1.5rem',
    marginTop: '1rem',
    position: 'relative',
  },
  buttons: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    zIndex: '-1',
  },
  title: {
    marginTop: '2rem',
  },
  prefTitleTopic: {
    marginBottom: '1rem',
  },
  prefTitleLocation: {
    marginTop: '3rem',
    marginBottom: '1rem',
  },
  searchLocation: {
    display: 'inline',
  },
  table: {
    position: 'absolute',
    zIndex: '99',
    border: `1px solid ${theme.colors.system.grey3}`,
    borderRadius: '2px',
    '& tr': {
      border: `1px solid ${theme.colors.system.grey3}`,
    },
    '& th,td': {
      padding: '17px 20px !important',
      border: 'none',
      ...theme.textStyles.bodySmall,
    },
    '& td': {
      backgroundColor: theme.colors.system.light,
    },
    '& th': {
      color: '#2b2e34 !important',
      fontWeight: 'bold',
    },
    locationSelect: {
      width: 300,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
}));

const parseLocationType = (location) => {
  switch (location.feature_class) {
    case 'L':
      return 'Global region';
    case 'A':
      if (location.feature_code === 'PCLI') {
        return 'Country';
      }
      if (location.feature_code === 'ADM1') {
        return 'State';
      }
      return 'Region';
    case 'P':
      return 'City/Town/Suburb';
    default:
      return null;
  }
};

const PrefLocationOption = (props) => {
  const { location } = props;
  const locationType = parseLocationType(location);
  return (
    <div>
      <Typography variant="body1">{location.label}</Typography>
      {locationType && (
        <Typography color="textSecondary" variant="body2">{parseLocationType(location)}</Typography>
      )}
    </div>
  );
};

PrefLocationOption.propTypes = {
  location: PropTypes.shape().isRequired,
};

// this was taken from audiencelocationselect and c be borrow modularise
const PrefLocationSelect = (props) => {
  const {
    onChange,
    value,
  } = props;
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [{ data, isFetching, status }, postSearchLocationRequest] = useApi();

  useEffect(() => {
    if (inputValue.length > 2) {
      postSearchLocationRequest({
        method: 'post',
        url: '/location/search/',
        data: {
          prefix: inputValue,
          from: 0,
          size: 10,
        },
      });
    } else {
      setOptions([]);
    }
  }, [inputValue, postSearchLocationRequest]);

  useEffect(() => {
    if (status === 200 && data) {
      setOptions(data.locations);
    }
  }, [data, status]);

  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  return (
    <div>
      <Autocomplete
        getOptionLabel={(option) => (_.isObject(option) ? option.label : option)}
        // getOptionSelected={(option, v) => option.geoname_id === v.geoname_id}
        loading={isFetching}
        noOptionsText="Enter a new location"
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={options}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            fullWidth
            placeholder="Starting typing to search for locations"
          />
        )}
        renderOption={(option) => (
          <PrefLocationOption location={option} />
        )}
        value={value}
      />
    </div>
  );
};

PrefLocationSelect.propTypes = {
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

PrefLocationSelect.defaultProps = {
  multiple: false,
  value: null,
};

const PreferencesPage = () => {
  const [isFetching, setIsFetching] = useState(false);
  const classes = useStyles();
  const auth = useAuth();
  const { createNotification } = useNotification();
  const [save, setSave] = useState(false);

  const [userLocations, setUserLocations] = useState(auth.user.preferences.locations);
  const [userTopics, setUserTopics] = useState(auth.user.preferences.topics);

  const allTopics = [];
  popularSections[0].items.forEach((item) => allTopics.push(item));

  const cityCountryString = (string) => {
    const arr = string.split(', ');
    let newString = '';
    if (arr.length > 1 && arr.length > 3) {
      newString = `${arr[1]}, ${arr[arr.length - 1]}`;
      return newString;
    } if (arr.length > 1 && arr.length < 4) {
      newString = `${arr[0]}, ${arr[arr.length - 1]}`;
      return newString;
    } if (arr.length > 2) {
      newString = `${arr[0]}, ${arr[arr.length - 1]}`;
    }
    return string;
  };

  const changeLocation = (event, index) => {
    if (event) {
      let newArray = [];

      const imageString = (string) => {
        const arr = string.split(', ');
        const newString = `${arr[0]}-${arr[arr.length - 1]}`;
        return newString.toLowerCase();
      };

      const newLocationObj = {
        name: cityCountryString(event.label),
        location: event.geoname_id,
        image: imageString(event.label),
      };

      newArray = [...userLocations];
      newArray.splice(index, 1, newLocationObj);
      setUserLocations(newArray);
      setSave(true);
    }
  };

  const handleChangeTopics = (index, e) => {
    let newArray = [];
    const { value } = e.target;
    const valueImage = value.toLowerCase();

    const newTopicObj = {
      name: value,
      topic: value,
      image: valueImage,
    };

    newArray = [...userTopics];
    newArray.splice(index, 1, newTopicObj);
    setUserTopics(newArray);
    setSave(true);
  };

  async function handleSave(e) {
    setIsFetching(true);
    e.preventDefault();

    const response = await API({
      method: 'put',
      url: `/user/${auth.user.id}/preferences/`,
      data: {
        preferences: JSON.stringify({
          topics: userTopics,
          locations: userLocations,
        }),
      },
    });

    if (response.status === 200) {
      // setIsFetching(false);
      // createNotification('success', 'Saved');
      window.location.reload();
      // setSave(false)
    } else {
      createNotification('error', 'Something went wrong');
      setIsFetching(false);
    }
  }

  if (!auth.hasPermission('preferences.all')) {
    return (
      <NoMatch default />
    );
  }

  return (
    <SettingsPageBase
      showLoadingBar={isFetching}
      title="Preferences"
    >
      <div className={classes.container}>
        <form>
          <Typography variant="h5" className={classes.prefTitleTopic}>
            Topic Preferences
          </Typography>
          <div className={classes.top}>
            {userTopics.map((it, index) => (
              <div key={it.name} className={`${classes.row}`}>
                <Typography variant="body1" component="span">
                  Topic Preference
                  {' '}
                  {index + 1}
                </Typography>
                <br />
                <Select
                  fullWidth
                  defaultValue={it.name}
                  onChange={(e) => handleChangeTopics(index, e)}
                >
                  {allTopics.map((item) => (
                    <MenuItem
                      key={item}
                      value={item}
                      disabled={userTopics.some((i) => i.name === item)}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            ))}
          </div>

          <Typography variant="h5" className={classes.prefTitleLocation}>
            Location Preferences
          </Typography>
          <div className={classes.bottom}>
            {userLocations.map((it, index) => (
              <div key={it.name} className={classes.row}>
                <Typography variant="body1" component="span">
                  Location Preference
                  {' '}
                  {index + 1}
                </Typography>
                <br />
                <PrefLocationSelect
                  className={classes.locationSelect}
                  multiple={false}
                  onChange={(e) => changeLocation(e, index)}
                  value={it.name}
                  preferencePage
                  parse={cityCountryString}
                />
              </div>
            ))}
          </div>
          <br />
          <div className={classes.buttons}>
            <Button
              variant="contained"
              size="small"
              style={{ marginRight: 10 }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="small"
              disabled={!save}
              onClick={(e) => handleSave(e)}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </SettingsPageBase>
  );
};

export default PreferencesPage;
