import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import BrandProfiles from '../../content-approval/BrandProfiles';
import TodaysPosts from '../../content-approval/TodaysPosts';
import Notifications from '../../content-approval/Notifications';
import NewBrandProfileDialog from '../../content-approval/NewBrandProfileDialog';
import PlainPageBase from '../PlainPageBase';
import DisablePage from '../../common/DisablePage';
import { useAuth, useContentManager } from '../../../hooks';

const useStyles = makeStyles(() => ({
  mainCont: {
    display: 'grid',
    gridTemplateColumns: '450px 550px 350px',
    gridGap: '2rem',
  },
}));

const ContentApprovalPage = () => {
  const classes = useStyles();
  const auth = useAuth();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [initialFetch, setInitialFetch] = useState(false);
  const content = useContentManager();
  const isClient = auth.user.agency_role === 'client';
  const profilesAccess = JSON.parse(auth.user.brand_profiles_access);

  useEffect(() => {
    if (!initialFetch) {
      setInitialFetch(true);
      if (content.isNewBrand || content.isNewAllPost) {
        content.getBrandsAndPosts(auth.account.id);
      }
    }
  }, [auth, initialFetch, content]);

  const handleClose = (bool = false) => {
    setIsDialogOpen(false);
    // setIsViewPost(false);
    if (bool) content.getBrandsAndPosts(auth.account.id);
  };

  return (
    <>
      {!auth.hasPermission('content_manager.all') && <DisablePage />}
      <PlainPageBase
        // showLoadingBar={content.fetching}
        title="Content Approval"
        subtitle="Influencer content approval made easy. Create multiple campaigns across multiple channels for your brand or agency clients."
      >
        <div className={classes.mainCont}>
          <NewBrandProfileDialog
            newBrandIsOpen={isDialogOpen}
            closeDialog={handleClose}
          />

          <BrandProfiles
            setIsDialogOpen={() => setIsDialogOpen(true)}
            brands={
              isClient
                ? content.brands.filter((brand) => profilesAccess.includes(brand.username))
                : content.brands
            }
          />

          <TodaysPosts />
          <Notifications />
        </div>
      </PlainPageBase>
    </>
  );
};

export default ContentApprovalPage;
