import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  IconButton,
  LinearProgress,
  Link,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import LaunchIcon from '@material-ui/icons/Launch';
import SlidingPane from 'react-sliding-pane';

import ReactGA from 'react-ga';
import PageBase from './PageBase';
import ProfileLayout from '../profile/ProfileLayout';
import ProfileTable from '../search/ProfileTable';
import FilterBar, { isFilterParam } from '../search/FilterBar';
import { useApi, useAuth, useRoute } from '../../hooks';
import Profile from '../../utils/profile';
import PaginationAccessDialog from '../search/PaginationAccessDialog';

const DEFAULT_FROM = 0;
const DEFAULT_SIZE = 25;

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridTemplateAreas: '"profileTable profileSideBar"',
    // overflowX: 'hidden',
  },
  profileTable: {
    gridArea: 'profileTable',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  profileSideBar: {
    margin: '0 -1.8rem',
    gridArea: 'profileSideBar',
    // width: '100%',
    '@media (min-width: 1920px)': {
      width: 750,
    },
    padding: '0px 40px',
    overflowX: 'hidden',
    overflowY: 'auto',
    borderLeft: `1px solid ${theme.colors.system.grey3}`,
    '&>*': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  filterBar: {
    padding: theme.spacing(1),
  },
  profileActions: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: theme.colors.system.light,
    borderBottom: `1px solid ${theme.colors.system.grey3}`,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 15,
    paddingBottom: 15,
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  slidingPane: {
    maxWidth: '48rem',
    height: '100%',
    right: '0',
    bottom: '0',
    zIndex: '5',
  },
}));

const isSearchParam = (paramName) => isFilterParam(paramName) || ['keywords', 'from', 'size'].includes(paramName);

const ProfileSideBar = ({ networkId, closePane }) => {
  const classes = useStyles();
  const auth = useAuth();
  const [{ data, isFetching }, getProfileRequest] = useApi();

  useEffect(() => {
    getProfileRequest({
      method: 'get',
      url: `/account/${auth.account.id}/profile/by-network-id/${networkId}/`,
    });
  }, [auth, getProfileRequest, networkId]);

  const profile = useMemo(() => (data ? Profile(data) : null), [data]);

  return (
    <div className={classes.profileSideBar}>
      {(!profile || isFetching) && (
        <LinearProgress />
      )}
      {(profile && !isFetching) && (
        <>
          <div className={classes.profileActions}>
            <IconButton
              onClick={closePane}
              style={{ fontSize: 'small' }}
            >
              <CloseIcon style={{ fontSize: 'medium' }} />
            </IconButton>
            <Link href={`/profile/${btoa(profile.networkId())}`} target="_blank">
              <Button
                color="primary"
                size="small"
                fullWidth
                startIcon={<LaunchIcon style={{ fontSize: 'medium' }} />}
                variant="contained"
              >
                Open in New Tab
              </Button>
            </Link>
          </div>
          <ProfileLayout profile={profile} sidebar />
        </>
      )}
    </div>
  );
};

ProfileSideBar.propTypes = {
  closePane: PropTypes.func.isRequired,
  networkId: PropTypes.string.isRequired,
};

const SearchPageBase = (props) => {
  const {
    actions,
    emptyContent,
    header,
    onProfilesChange,
    onSelectedProfilesChange,
    onTotalChange,
    searchUrlBase,
    showEmptyOnLoad,
    showLoadingBar,
    title,
    list,
  } = props;
  const [hasLoaded, setHasLoaded] = useState(false);
  const [profiles, setProfiles] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [total, setTotal] = useState(null);
  const [isFetchingProfiles, setIsFetchingProfiles] = useState(false);
  const [route, setRoute] = useRoute();
  const [{ response, isFetching }, setRequest] = useApi();
  const [isPageLockDialogOpen, setPageLockDialog] = useState(false);
  const auth = useAuth();
  const classes = useStyles();
  const [paneState, setPaneState] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });
  // const [sortOrder, setSortOrder] = useState('');

  const size = useMemo(() => (route.params.size ? parseInt(route.params.size, 10) : DEFAULT_SIZE), [route]);
  const from = useMemo(() => (route.params.from ? parseInt(route.params.from, 10) : DEFAULT_FROM), [route]);

  const profileSearchPerm = auth.hasPermission('influencer.all');

  useEffect(() => {
    if (auth && auth.account) {
      const nextPlan = auth.account.billing.next_plan ? auth.account.billing.next_plan.id : '';
      // sending GA custom dimensions another way
      ReactGA.ga('send', 'pageview', {
        dimension2: auth.account.billing.current_plan.id,
        dimension3: nextPlan,
        dimension4: auth.account.type,
        dimension5: "ReactGA.ga('send', 'pageview') custom dimension?",
      });
      // sending GA custom dimensions another way
      ReactGA.ga('set', 'dimension2', auth.account.billing.current_plan.id);
      ReactGA.ga('set', 'dimension3', nextPlan);
      ReactGA.ga('set', 'dimension4', auth.account.type);
      ReactGA.ga('set', 'dimension5', "ReactGA.ga('set', 'dimension2', auth...)");
      // GA4 User Properties NOT WORKING, NEED TO SET UP A GTAG ACCOUNT
      // ReactGA.ga('set', 'user_properties', {
      //   current_plan: auth.account.billing.current_plan.id,
      //   next_plan: nextPlan,
      //   account_type: auth.account.type,
      //   where_test: "ReactGA.ga('set','user_properties') user property?",
      // });
    }
  }, [auth]);

  useEffect(() => {
    const searchParams = _.pickBy(route.params, (_value, key) => isSearchParam(key));
    if (showEmptyOnLoad && _.isEmpty(searchParams)) {
      setProfiles(null);
      setHasLoaded(true);
      return;
    }
    setIsFetchingProfiles(true);
    setRequest({
      method: 'post',
      url: searchUrlBase,
      data: {
        ...searchParams,
        from,
        size,
      },
    });
  }, [from, hasLoaded, route, searchUrlBase, setRequest, showEmptyOnLoad, size]);

  useEffect(() => {
    if (response && response.status === 200) {
      setProfiles(response.data.data.profiles.map((it) => Profile(it)));
      setTotal(response.data.data.total);
    }
    setIsFetchingProfiles(isFetching);
  }, [isFetching, response]);

  useEffect(() => {
    if (onProfilesChange) {
      onProfilesChange(profiles);
    }
  }, [onProfilesChange, profiles]);
  useEffect(() => {
    if (onTotalChange) {
      onTotalChange(total);
    }
  }, [onTotalChange, total]);

  const handleFilterBarOnChange = (filters) => {
    setRoute({
      params: {
        ...route.params,
        ...filters,
      },
    });
  };

  const handleProfileClick = (event, profile) => {
    event.preventDefault();
    setSelectedProfile(profile);
    setPaneState({ isPaneOpen: true });
  };

  const handleChangePage = (event, page) => {
    if (!profileSearchPerm) {
      setPageLockDialog(true);
    } else {
      setRoute({
        params: {
          ...route.params,
          from: page * size,
        },
      });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    if (!profileSearchPerm) {
      setPageLockDialog(true);
    } else {
      setRoute({
        params: {
          ...route.params,
          from: DEFAULT_FROM,
          size: event.target.value,
        },
      });
    }
  };

  const handleClosePageAccessDialog = () => {
    setPageLockDialog(false);
  };

  const closePane = () => {
    setPaneState({ isPaneOpen: false });
  };

  const handleProfileSort = (sortColumn, order) => {
    const searchParams = _.pickBy(route.params, (_value, key) => isSearchParam(key));
    // const sortCondition = `${sortOrder ? sortOrder + ',' : ''}${sortColumn}:${order}`;
    setIsFetchingProfiles(true);
    setRequest({
      method: 'post',
      url: searchUrlBase,
      data: {
        ...searchParams,
        from,
        size,
        sort: `${sortColumn}:${order}`,
      },
    });
    // setSortOrder(sortCondition);
  };

  const ref1 = useRef();

  return (
    <PageBase
      scroll={false}
      showLoadingBar={showLoadingBar || isFetchingProfiles}
      title={title}
    >
      <div className={classes.container} ref={ref1}>
        <div className={classes.profileTable}>
          <FilterBar
            className={classes.filterBar}
            filterData={_.pickBy(route.params, (_value, key) => isFilterParam(key))}
            onChange={handleFilterBarOnChange}
          />
          {header}
          {!isFetchingProfiles && (!profiles || profiles.length === 0) && emptyContent}
          {profiles && profiles.length > 0 && (
            <ProfileTable
              contRef={ref1.current}
              profiles={profiles}
              ProfileTablePaginationProps={{
                actions,
                count: total === null ? -1 : total,
                rowsPerPage: size,
                rowsPerPageOptions: [25, 50, 100],
                page: from / size,
                onChangePage: handleChangePage,
                onChangeRowsPerPage: handleChangeRowsPerPage,
              }}
              onProfileClick={handleProfileClick}
              onSelectedProfilesChange={onSelectedProfilesChange}
              style={{ flex: '1' }}
              list={list}
              handleProfileSort={handleProfileSort}
            />
          )}
        </div>
        {selectedProfile && (
          <SlidingPane
            className={classes.slidingPane}
            isOpen={paneState.isPaneOpen}
            onRequestClose={() => {
              setPaneState({ isPaneOpen: false });
            }}
          >
            <ProfileSideBar
              networkId={selectedProfile.networkId()}
              closePane={closePane}
            />
          </SlidingPane>
        )}
      </div>

      <PaginationAccessDialog
        isDialogOpen={isPageLockDialogOpen}
        closeDialog={handleClosePageAccessDialog}
      />
    </PageBase>
  );
};

SearchPageBase.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.node),
  emptyContent: PropTypes.node,
  header: PropTypes.node,
  onProfilesChange: PropTypes.func,
  onSelectedProfilesChange: PropTypes.func,
  onTotalChange: PropTypes.func,
  showLoadingBar: PropTypes.bool,
  searchUrlBase: PropTypes.string.isRequired,
  showEmptyOnLoad: PropTypes.bool,
  title: PropTypes.string,
  list: PropTypes.node,
};

SearchPageBase.defaultProps = {
  actions: null,
  emptyContent: null,
  header: null,
  onProfilesChange: null,
  onSelectedProfilesChange: null,
  onTotalChange: null,
  showLoadingBar: false,
  showEmptyOnLoad: true,
  title: null,
  list: null,
};

export default SearchPageBase;
