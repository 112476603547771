import React from 'react';

import PropTypes from 'prop-types';

import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CloseIcon from './icons/Close';
import ErrorIcon from './icons/Error';
import InfoIcon from './icons/Info';
import SuccessIcon from './icons/Success';
import WarningIcon from './icons/Warning';

const severityToBackgroundColor = (severity, theme) => {
  switch (severity) {
    case 'error':
      return theme.palette.error.main;
    case 'warning':
      return theme.palette.warning.main;
    case 'success':
      return theme.palette.success.main;
    case 'info':
    default:
      return theme.palette.info.main;
  }
};

const severityToIcon = (severity) => {
  switch (severity) {
    case 'error':
      return ErrorIcon;
    case 'warning':
      return WarningIcon;
    case 'success':
      return SuccessIcon;
    case 'info':
    default:
      return InfoIcon;
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto auto',
    gridTemplateAreas: '"icon content actions close"',
    gridColumnGap: '5px',
    alignItems: 'center',
    padding: '10px 15px 10px 15px',
    color: theme.colors.system.light,
    borderRadius: 5,
    backgroundColor: (props) => severityToBackgroundColor(props.severity, theme),
  },
  iconArea: {
    gridArea: 'icon',
    display: 'table-cell',
    '&>*': {
      verticalAlign: 'middle',
    },
  },
  contentArea: {
    gridArea: 'content',
  },
  actionsArea: {
    gridArea: 'actions',
  },
  closeArea: {
    gridArea: 'close',
  },
  icon: {
    color: theme.colors.system.light,
  },
}));

const Notification = ({ children, onClose, severity }) => {
  const classes = useStyles({ severity });
  const Icon = severityToIcon(severity);

  return (
    <div className={classes.root}>
      <div className={classes.iconArea}>
        <Icon className={classes.icon} fontSize="small" />
      </div>
      <div className={classes.contentArea}>
        {children}
      </div>
      {onClose && (
        <IconButton
          className={classes.closeArea}
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      )}
    </div>
  );
};

Notification.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
};

Notification.defaultProps = {
  onClose: null,
  severity: 'info',
};

export default Notification;
