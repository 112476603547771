import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import {
  ButtonBase,
  FormHelperText,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ListSelect from './ListSelect';

const useStyles = makeStyles((theme) => ({
  changeModeButton: {
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontStyle: 'inherit',
    color: theme.colors.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const ListSelectOrCreateField = (props) => {
  const {
    defaultMode, error, helperText, onChange,
  } = props;
  const [selectedList, setSelectedList] = useState('');
  const [listName, setListName] = useState('');
  const [mode, setMode] = useState(defaultMode || 'select');
  const classes = useStyles();

  useEffect(() => {
    if (onChange) {
      onChange({
        mode,
        selectedList,
        newListName: listName,
      });
    }
  }, [listName, onChange, mode, selectedList]);

  const handleChangeMode = (event, newMode) => {
    event.preventDefault();
    setMode(newMode);
  };

  const handleSelectChange = (list) => {
    setSelectedList(list);
  };

  const handleTextFieldChange = (event) => {
    event.preventDefault();
    if (event.target.value.length < 64) {
      setListName(event.target.value);
    }
  };

  const changeModeToCreate = (e) => {
    handleChangeMode(e, 'create');
  };

  return (
    <div style={{ marginBottom: 30 }}>
      <InputLabel>
        {mode === 'select' && <span>Select a list</span>}
        {mode !== 'select' && (
          <span>
            Enter a new list name
            {' ('}
            <ButtonBase
              className={classes.changeModeButton}
              disableRipple
              onClick={(e) => handleChangeMode(e, 'select')}
            >
              or select an existing list
            </ButtonBase>
          </span>
        )}
      </InputLabel>
      {mode === 'select' && (
        <ListSelect
          onChange={handleSelectChange}
          onChangeMode={changeModeToCreate}
          value={selectedList}
        />
      )}
      {mode !== 'select' && (
        <TextField
          fullWidth
          value={listName}
          onChange={handleTextFieldChange}
        />
      )}
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </div>
  );
};

ListSelectOrCreateField.propTypes = {
  defaultMode: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
};

ListSelectOrCreateField.defaultProps = {
  defaultMode: 'select',
  error: false,
  helperText: null,
  onChange: null,
};

export default ListSelectOrCreateField;
