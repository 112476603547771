import React, { useState } from 'react';

import PropTypes from 'prop-types';

import {
  ButtonBase,
  Input,
  Popover,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import VirtualizedList from './VirtualizedList';

const useStyles = makeStyles((theme) => ({
  listSelectRoot: {
    cursor: 'pointer',
  },
  listSelectInput: {
    display: 'flex',
    alignItems: 'center',
  },
  popover: {
    minWidth: ({ anchorEl }) => (anchorEl ? anchorEl.clientWidth : undefined),
  },
  listItem: {
    height: 30,
    justifyContent: 'start',
    paddingLeft: 8,
    paddingRight: 8,
    backgroundColor: ({ selected }) => (selected ? theme.colors.system.grey2 : undefined),
  },
  changeModeButton: {
    borderBottom: `1px solid ${theme.colors.system.grey7}`,
    padding: `10px ${theme.spacing(1)}px`,
    width: '100%',
    justifyContent: 'flex-start',
  },
}));

const ListSelectItem = ({ data, index, style }) => {
  const { lists, onClick, selectedList } = data;
  const list = index < lists.length ? lists[index] : null;
  const classes = useStyles({ selected: list && selectedList && list.id === selectedList.id });
  return (
    <ButtonBase
      className={classes.listItem}
      disabled={!list}
      onClick={() => onClick(list)}
      style={style}
      value={list ? list.id : 0}
    >
      <Typography variant="body1">{list ? list.name : '...'}</Typography>
    </ButtonBase>
  );
};

ListSelectItem.propTypes = {
  data: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.shape().isRequired,
};

const ListSelect = (props) => {
  const {
    onChange,
    onChangeMode,
    listHeight,
    value,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles({ anchorEl });

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemSelect = (newValue) => {
    onChange(newValue);
    handleClose();
  };

  return (
    <div>
      <Input
        classes={{
          root: classes.listSelectRoot,
          input: classes.listSelectInput,
        }}
        endAdornment={<ArrowDropDownIcon />}
        fullWidth
        inputComponent={({ inputRef, ...inputProps }) => (
          <div
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...inputProps}
          >
            <Typography variant="body1">{value ? value.name : value}</Typography>
          </div>
        )}
        onClick={handleOpen}
        readOnly
        value={value ? value.id : value}
      />
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{ paper: classes.popover }}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ButtonBase
          className={classes.changeModeButton}
          onClick={onChangeMode}
        >
          <Typography variant="body1">
            + Create new list
          </Typography>
        </ButtonBase>
        <VirtualizedList
          height={listHeight}
          itemData={{
            onClick: handleItemSelect,
            selectedList: value,
          }}
          itemHeight={30}
          ListItemComponent={ListSelectItem}
        />
      </Popover>
    </div>
  );
};

ListSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  onChangeMode: PropTypes.func.isRequired,
  listHeight: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
};

ListSelect.defaultProps = {
  listHeight: 200,
};

export default ListSelect;
