import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';
import AddEditAgencyMemberModal from '../settings/AddEditAgencyMemberModal';

const AddNewMemberButton = (props) => {
  const {
    isInSettings,
    refreshInvites,
    refreshMembers,
    members,
  } = props;
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        // disabled={isFetching}
        size="small"
        variant="contained"
        color="primary"
        value="addNew"
        onClick={() => setOpen(true)}
      >
        + Add New Member
      </Button>

      {isInSettings && open && (
        <AddEditAgencyMemberModal
          handleClose={() => setOpen(false)}
          refreshData={refreshInvites}
        />
      )}

      {/* Add new member access */}
      {!isInSettings && open && (
        <AddEditAgencyMemberModal
          handleClose={() => setOpen(false)}
          refreshData={refreshInvites}
          refreshMembers={refreshMembers}
          isToBrand
          members={members}
        />
      )}
    </>
  );
};

AddNewMemberButton.propTypes = {
  isInSettings: PropTypes.bool,
  refreshInvites: PropTypes.func,
  refreshMembers: PropTypes.func,
  members: PropTypes.shape(),
};

AddNewMemberButton.defaultProps = {
  isInSettings: false,
  refreshInvites: null,
  refreshMembers: null,
  members: null,
};

export default AddNewMemberButton;
