import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ClearableTextField from '../../common/ClearableTextField';
import { formatPercentage } from '../../../utils/number';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    /* padding: theme.spacing(2), */
    borderRadius: 5,
    /* backgroundColor: theme.colors.system.grey1, */
    '&>:not(last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  title: {
    paddingBottom: 15,
    '&>p': {
      fontWeight: 'bold',
    },
  },
  gridTwoColumns: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    '&>*': {
      marginBottom: 40,
    },
  },
  locationTableRoot: {
    height: 'min-content',
    border: `1px solid ${theme.colors.system.grey1}`,
    borderRadius: 2,
    '&>*': {
      padding: theme.spacing(1),
    },
    '&>:not(last-child)': {
      borderBottom: `1px solid ${theme.colors.system.grey1}`,
    },
    '&:nth-child(odd)': {
      marginRight: 20,
    },
    '&:nth-child(even)': {
      marginLeft: 20,
    },
  },
  locationTableTitle: {
    backgroundColor: theme.colors.system.grey1,
    '&>*': {
      fontWeight: 'bold',
    },
  },
  locationTableRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 50,
    '&>*:first-child': {
      width: '80%',
    },
    '&>*:last-child': {
      width: '20%',
    },
  },
  filter: {
    paddingBottom: 40,
  },
  showMoreBn: {
    fontStyle: 'normal',
    color: theme.colors.system.grey7,
  },
}));

const LocationTable = ({
  locationFilter,
  locations,
  title,
  sidebar,
}) => {
  const [numberToShow, setNumberToShow] = useState(5);
  const classes = useStyles();

  const locationsToShow = locations.filter(
    (it) => !locationFilter || it.key.label.toLowerCase().includes(locationFilter.toLowerCase()),
  );

  return (
    <div className={classes.locationTableRoot} style={sidebar ? { marginLeft: 0, marginRight: 0 } : null}>
      <div className={`${classes.locationTableTitle} ${classes.locationTableRow}`}>
        <Typography variant="body2">{title}</Typography>
        <Typography variant="body2">Percent</Typography>
      </div>
      {locationsToShow.slice(0, numberToShow).map((location) => (
        <div key={location.key.geoname_id} className={classes.locationTableRow}>
          <Typography variant="body2">{location.key.label}</Typography>
          <Typography variant="body2">{formatPercentage(location.probability)}</Typography>
        </div>
      ))}
      {numberToShow < locationsToShow.length && (
        <div>
          <Button
            onClick={() => setNumberToShow(numberToShow + 5)}
            size="small"
            className={classes.showMoreBn}
          >
            + Show more locations
          </Button>
        </div>
      )}
    </div>
  );
};

LocationTable.propTypes = {
  locationFilter: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  title: PropTypes.string.isRequired,
  sidebar: PropTypes.bool,
};

LocationTable.defaultProps = {
  sidebar: false,
};

const ProfileAudienceLocation = (props) => {
  const { locationData, sidebar } = props;
  const [locationFilter, setLocationFilter] = useState('');
  const classes = useStyles();

  let gridTemplateColumns = '1fr 1fr';
  if (sidebar) {
    gridTemplateColumns = '1fr';
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="body1">Audience locations</Typography>
      </div>
      <div className={classes.filter}>
        <ClearableTextField
          onChange={(e) => setLocationFilter(e.target.value)}
          onClear={() => setLocationFilter('')}
          placeholder="Filter locations by name"
          value={locationFilter}
          style={{ width: '100%' }}
        />
      </div>
      <div className={classes.gridTwoColumns} style={{ gridTemplateColumns }}>
        {locationData.continent && locationData.continent.data && (
          <LocationTable
            locationFilter={locationFilter}
            locations={locationData.continent.data}
            title="Global regions"
            sidebar={sidebar}
          />
        )}
        {locationData.country && locationData.country.data && (
          <LocationTable
            locationFilter={locationFilter}
            locations={locationData.country.data}
            title="Countries"
            sidebar={sidebar}
          />
        )}
        {locationData.state && locationData.state.data && (
          <LocationTable
            locationFilter={locationFilter}
            locations={locationData.state.data}
            title="States"
            sidebar={sidebar}
          />
        )}
        {locationData.region && locationData.region.data && (
          <LocationTable
            locationFilter={locationFilter}
            locations={locationData.region.data}
            title="Regions"
            sidebar={sidebar}
          />
        )}
        {locationData.place && locationData.place.data && (
          <LocationTable
            locationFilter={locationFilter}
            locations={locationData.place.data}
            title="Cities/Towns/Suburbs"
            sidebar={sidebar}
          />
        )}
      </div>
    </div>
  );
};

ProfileAudienceLocation.propTypes = {
  locationData: PropTypes.shape().isRequired,
  sidebar: PropTypes.bool,
};

ProfileAudienceLocation.defaultProps = {
  sidebar: false,
};

export default ProfileAudienceLocation;
