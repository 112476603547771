import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import {
  Container,
  Typography,
} from '@material-ui/core';
import { Link } from '@reach/router';

import PageBase from '../PageBase';
import ProfileLayout from '../../profile/ProfileLayout';
import { useApi, useAuth } from '../../../hooks';
import Profile from '../../../utils/profile';

const ProfilePage = ({ networkId }) => {
  const [profile, setProfile] = useState(null);
  const auth = useAuth();
  const [{ response, isFetching, status }, setRequest] = useApi();

  const decodedNetworkId = useMemo(() => {
    // Decode networkId if given as base64 encoded string
    try {
      return atob(networkId);
    } catch (err) {
      return networkId;
    }
  }, [networkId]);

  useEffect(() => {
    if (decodedNetworkId) {
      setRequest({
        method: 'get',
        url: `/account/${auth.account.id}/profile/by-network-id/${decodedNetworkId}/`,
      });
    }
  }, [auth, decodedNetworkId, setRequest]);

  useEffect(() => {
    if (response && response.status === 200) {
      setProfile(Profile(response.data.data));
    }
  }, [response]);

  if (!decodedNetworkId || status === 404) {
    return (
      <div>
        <Typography variant="body1">
          Oops! We couldn&apos;t find that profile for you.
        </Typography>
        <br />
        <Typography variant="body1">
          Go to the
          {' '}
          <Link to="/discover">discover</Link>
          {' '}
          page to search for profiles.
        </Typography>
      </div>
    );
  }

  if (!profile) {
    return null;
  }

  return (
    <PageBase showLoadingBar={isFetching} title={profile.fullName()}>
      <Container maxWidth="md">
        <ProfileLayout profile={profile} />
      </Container>
    </PageBase>
  );
};

ProfilePage.propTypes = {
  networkId: PropTypes.string,
};

ProfilePage.defaultProps = {
  networkId: null,
};

export default ProfilePage;
