import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';

import PropTypes from 'prop-types';

import { TablePagination, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ProfileTable from '../../search/ProfileTable';
import PageBase from '../PageBase';
import { useApi } from '../../../hooks';
import Profile from '../../../utils/profile';

const useStyles = makeStyles(() => ({
  listHeader: {
    display: 'flex',
    gridTemplateColumns: '1fr auto',
    flexDirection: 'column',
    padding: '50px 40px 50px 40px',
  },
}));

const PublicListPage = ({ listId }) => {
  const [from, setFrom] = useState(0);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(0);
  const classes = useStyles();
  const [{ data: list, status: listStatus, isFetching: listIsFetching }] = useApi({
    method: 'get',
    url: `/public-list/${listId}/`,
  });
  const [{ data: listSearch, isFetching: searchIsFetching }, setPostListSearchRequest] = useApi();

  const profiles = useMemo(() => (listSearch ? listSearch.profiles.map((it) => Profile(it)) : null), [listSearch]);

  useEffect(() => {
    if (listSearch) {
      setTotal(listSearch.total);
    }
  }, [listSearch]);
  //
  useEffect(() => {
    if (list && list.id) {
      setPostListSearchRequest({
        method: 'post',
        url: `/public-list/${list.id}/search/`,
        data: {
          from,
          size,
        },
      });
    }
  }, [from, list, setPostListSearchRequest, size]);

  const handleChangePage = (event, page) => {
    setFrom(page * size);
  };

  const handleChangeRowsPerPage = (event) => {
    setFrom(0);
    setSize(event.target.value);
  };

  if (listStatus === 404) {
    return (
      <div>
        <Typography variant="body1">
          Oops! We couldn&apos;t find that list for you.
        </Typography>
        <br />
        <Typography variant="body1">
          The list may no longer be publicly shared.
        </Typography>
      </div>
    );
  }

  return (
    <PageBase
      scroll
      showLoadingBar={listIsFetching || searchIsFetching}
      title={list ? `Public list - ${list.name}` : 'Public list'}
    >
      {list && (
        <div className={classes.listHeader}>
          <Typography variant="h4">{list.name}</Typography>
        </div>
      )}
      <TablePagination
        component="div"
        count={total === null ? -1 : total}
        rowsPerPage={size}
        rowsPerPageOptions={[25, 50, 100]}
        page={from / size}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      {profiles && (
        <ProfileTable
          profiles={profiles}
          showActions={false}
          showCheckbox={false}
        />
      )}
    </PageBase>
  );
};

PublicListPage.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

PublicListPage.defaultProps = {
  listId: null,
};

export default PublicListPage;
