import { makeStyles } from '@material-ui/core/styles';

const campaignAnalyticsStyles = makeStyles((theme) => ({
  mainCont: {
    backgroundColor: theme.colors.system.grey1,
    width: '1050px',
    position: 'relative',
    padding: '20px',
    minHeight: '600px',
    // maxWidth: "1200px",
    // minWidth: '1000px',
  },
  noData: {
    margin: 'auto',
    width: '500px',
    position: 'absolute',
    left: '33.3%',
    top: '20%',
    textAlign: 'center',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '20px',
    borderBottom: '1px solid lightgrey',
  },
  // title2: {
  //   padding: "20px 0",
  // },
  titleBtns: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleBtn: {
    fontSize: '12px',
    height: '30px',
    marginLeft: '10px',
  },
  datetime: {
    fontSize: '10px',
    color: 'grey',
  },
  icon: {
    fontSize: '14px',
  },
  overviewTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  overviewGrid: {
    display: 'grid',
    // gridTemplateAreas: `
    // "influencers posts"
    // "types impressions"
    // "types impressions"
    // "types reach"
    // "metrics reach"
    // "metrics eng"
    // "metrics eng"
    // `,
    gridTemplateAreas: `
    "influencers posts"
    "types impressions"
    "types impressions"
    "types reach"
    "eng reach"
    "eng none"
    `,
    gridTemplateRows: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    gridTemplateColumns: '1fr 1fr',
    rowGap: '20px',
    columnGap: '30px',
  },
  metricGrid: {
    // display: "grid",
    display: 'none',

    // gridTemplateAreas: `
    //   "users sessions"
    //   "orders gross"
    //   "discount net"
    // `,
    gridTemplateRows: '1fr 1fr 1fr',
    gridTemplateColumns: '1fr 1fr',
    rowGap: '30px',
    columnGap: '20px',
  },
  overviewCont: {
    backgroundColor: 'white',
    padding: '10px',
    position: 'relative',
  },
  contTitle: {
    fontSize: '10px',
  },
  fineprint: {
    fontSize: '8px',
    color: 'lightgrey',
    position: 'absolute',
    bottom: '-20px',
    left: 0,
    // marginBottom: '-40px',
  },
  audienceAnalysis: {
    marginTop: '40px',
    borderTop: '1px solid lightgrey',
    paddingTop: '30px',
  },
  logo: {
    display: 'inline',
    height: '10px',
    float: 'right',
  },
  infPic: {
    height: '65px',
    width: '65px',
    borderRadius: '50%',
    marginRight: '15px',
  },
  influencer: {
    marginTop: '30px',
    display: 'flex',
    height: '80px',
    width: '100%',
  },
  infChip: {
    margin: '0 2px',
    fontSize: '8px',
    height: '15px',
  },
  p: {
    margin: '5px 0',
    fontSize: '10px',
  },
  namePosts: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  infInfo: {
    width: '100%',
  },
  postMainCont: {
    width: '47.5%',
    backgroundColor: 'white',
    borderRadius: '4px',
    marginTop: '30px',
  },
  postStatsCont: {
    borderTop: '1px solid lightgrey',
    margin: '0 15px',
  },
  postStat: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '15px 0',
    paddingRight: '50px',
    '&>*': {
      fontSize: '10px',
      color: 'grey',
    },
  },
  namePic: {
    display: 'flex',
    alignItems: 'center',
  },
  postAuthPic: {
    borderRadius: '50%',
    height: '30px',
    width: '30px',
    marginRight: '7.5px',
  },
  postHeader: {
    display: 'flex',
    padding: '10px',
  },
  postPic: {
    borderRadius: '4px',
    height: '100px',
    marginRight: '10px',
  },
  postBody: {
    fontSize: '10px',
  },
  postDatetime: {
    fontSize: '10px',
    color: 'grey',
  },
  postInfName: {
    fontSize: '12px',
  },
  infAvatar: {
    marginTop: '10px',
    display: 'inline-block',
    marginRight: '5px',
  },
  postBar: {
    backgroundColor: '#A9B7E5',
    borderRadius: '4px',
    height: '20px',
    marginTop: '15px',
  },
  publishedPosts: {
    backgroundColor: '#354065',
    height: '100%',
  },
  pieChart: {
    display: 'flex',
  },
  contentList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  pieListPoint: {
    height: '15px',
    width: '15px',
    borderRadius: '50%',
    marginRight: '10px',
  },
  pieList: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '20px',
    marginTop: '20px',
  },
}));

export default campaignAnalyticsStyles;
