import React from 'react';

import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Card,
  CardContent,
  CardMedia,
  Link,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FavoriteIcon from '@material-ui/icons/Favorite';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import LinkIcon from '@material-ui/icons/OpenInNew';

import { formatNumber } from '../../utils/number';
import { ellipsize } from '../../utils/string';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)',
    minHeight: 360,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  media: {
    height: 0,
    paddingTop: '100%', // 125% 16:9
  },
  cardContent: {
    padding: '10px !important',
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
  },
  topRowContainer: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  text: {
    paddingTop: 5,
    ...theme.textStyles.bodySmall,
    flex: '1',
  },
  posted: {
    color: theme.colors.system.grey7,
    textAlign: 'right',
    fontSize: 12,
  },
  statsContainer: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  statsSpan: {
    marginRight: 10,
    ...theme.textStyles.bodySmall,
    '@media (max-width: 1919px)': {
      ...theme.textStyles.bodyExtraSmall,
    },
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  statsIcon: {
    verticalAlign: 'middle',
    marginRight: 3,
    fontSize: 12,
  },
  link: {
    verticalAlign: 'middle',
    color: theme.colors.system.grey7,
    '&:hover': {
      color: theme.colors.system.dark,
    },
    fontSize: 15,
  },
}));

const ProfileContentCard = ({ content, sidebar }) => {
  const classes = useStyles();

  const displayDateTime = moment(content.created).format('Do MMM YYYY');

  return (
    <Card className={classes.root} style={sidebar ? { minHeight: 'auto' } : null}>
      <CardMedia className={classes.media} image={content.image_url} />
      <CardContent className={classes.cardContent} style={sidebar ? { display: 'none' } : null}>
        <div className={classes.topRowContainer}>
          <Typography className={classes.posted} variant="body2" component="p">{displayDateTime}</Typography>
        </div>
        <Typography className={classes.text} variant="body2">{ellipsize(content.text || '', 110)}</Typography>
        <div className={classes.statsContainer}>
          <div>
            {content.num_likes !== null && (
              <Typography variant="body2" component="span" className={classes.statsSpan}>
                <FavoriteIcon className={classes.statsIcon} />
                {formatNumber(content.num_likes)}
              </Typography>
            )}
            {content.num_views !== null && (
              <Typography variant="body2" component="span" className={classes.statsSpan}>
                <VisibilityIcon className={classes.statsIcon} />
                {formatNumber(content.num_views)}
              </Typography>
            )}
            {content.num_comments !== null && (
              <Typography variant="body2" component="span" className={classes.statsSpan}>
                <ChatBubbleIcon className={classes.statsIcon} />
                {formatNumber(content.num_comments)}
              </Typography>
            )}
          </div>
          {content.url && (
            <Link target="_blank" href={content.url}><LinkIcon className={classes.link} /></Link>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

ProfileContentCard.propTypes = {
  content: PropTypes.shape().isRequired,
  sidebar: PropTypes.bool,
};

ProfileContentCard.defaultProps = {
  sidebar: false,
};

export default ProfileContentCard;
