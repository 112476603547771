import React, { useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { PDFExport } from '@progress/kendo-react-pdf';

import InfluencerDetails from './InfluencerDetails';
import CampaignOverview from './CampaignOverview';
import '../../pdf.css';

const useStyles = makeStyles((theme) => ({
  reportRoot: {
    width: '100%',
    minHeight: 500,
    backgroundColor: theme.colors.system.grey1,
    borderRadius: 4,
    border: `1px solid ${theme.colors.system.grey3}`,
    padding: 20,
    marginTop: 50,
  },
  sectionHeading: {
    fontSize: 18,
    marginBottom: 20,
  },
  reportTypes: {
    padding: 20,
    paddingTop: 10,
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
    border: `1px solid ${theme.colors.system.grey3}`,
  },
  label: {
    '& span': {
      fontWeight: 600,
      lineHeight: 0,
    },
  },
  reportButtons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  downloadBtn: {
    padding: '11px 0',
    width: '47%',
  },
  influencerHeader: {
    padding: '25px 0px',
    borderBottom: `1px solid ${theme.colors.system.grey6}`,
  },
  influencerHeaderText: {
    ...theme.textStyles.bodyLarge,
  },
}));

const GeneratedReport = ({ data }) => {
  const classes = useStyles();

  const pdfRef = useRef(null);

  if (!data) {
    return null;
  }

  const campaignOverviewData = {
    influencers: data.num_users,
    piecesOfContent: data.num_media,
    engagements: data.total_engagement,
    extraMetric: data.extra_metric,
    socialReach: data.total_social_reach,
  };

  const downloadPdf = () => {
    pdfRef.current.save();
  };

  const downloadCsv = () => {
    if (typeof document !== 'undefined') {
      const downloadLink = document.createElement('a');
      const csv = atob(data.csv);
      const blob = new Blob(['\ufeff', csv]);
      const url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = 'Report.csv';

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <div id="reportRoot" className={classes.reportRoot}>
      <Typography variant="h6" component="h6" className={classes.sectionHeading}>Generated Campaign Report</Typography>

      {/* ---------- DOWNLOAD OPTIONS IN DIFFERENT FORMATS ---------- */}
      <div className={classes.reportTypes}>
        <div className={classes.label}>
          <Typography variant="body2" component="span">Download Report</Typography>
        </div>
        <div className={classes.reportButtons}>
          <Button
            color="primary"
            onClick={() => downloadPdf()}
            variant="outlined"
            size="small"
            className={classes.downloadBtn}
          >
            PDF Report
          </Button>

          <Button
            color="primary"
            onClick={() => downloadCsv()}
            variant="outlined"
            size="small"
            className={classes.downloadBtn}
            disabled={!data.csv}
          >
            CSV Report
          </Button>
        </div>
      </div>

      <PDFExport
        forcePageBreak=".pageBreak"
        ref={pdfRef}
        scale={1}
        margin="1cm"
        paperSize="A4"
        fileName="Report.pdf"
      >
        {/* ---------- CAMPAIGN OVERVIEW ---------- */}
        <CampaignOverview campaignOverviewData={campaignOverviewData} />

        {/* ---------- INDEPTH INFLUENCER ANALYSIS ---------- */}
        {data && data.users && (
          <>
            <div className={`${classes.influencerHeader} pageBreak`}>
              <Typography variant="h5" className={classes.influencerHeaderText}>Indepth Influencer Analysis</Typography>
            </div>

            {data.users.map((user, index) => (
              <InfluencerDetails key={user.username} influencerData={user} first={index === 0} />
            ))}
          </>
        )}
      </PDFExport>
    </div>
  );
};

GeneratedReport.propTypes = {
  data: PropTypes.shape(),
};

GeneratedReport.defaultProps = {
  data: null,
};

export default GeneratedReport;
