import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';

import {
  useApi,
  useAuth,
  useNotification,
  useRoute,
} from '../../../hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
  },
  infoWrapper: {
    height: '100%',
    border: `1px solid ${theme.colors.system.grey3}`,
    backgroundColor: theme.colors.system.grey1,
    borderRadius: 4,
    padding: 20,
  },
  instructions: {
    '& > li': {
      marginBottom: 15,
    },
    paddingInlineStart: 30,
  },
  mainContent: {
    paddingRight: 30,
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    paddingBottom: 40,
    borderBottom: `1px solid ${theme.colors.system.grey3}`,
  },
  heading: {
    ...theme.textStyles.bodyLarge,
  },
  logoWrapper: {
    border: `1px solid ${theme.colors.system.grey3}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 20,
  },
  headerSubText: {
    margin: '10px 0 20px',
  },
  cont: {
    paddingTop: 40,
    marginBottom: 20,
  },
  codeWrapper: {
    width: '100%',
    border: `2px dashed ${theme.colors.system.grey5}`,
    borderRadius: 4,
    backgroundColor: theme.colors.system.grey1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '15px 0',
    padding: '5px 12px',
  },
  subText: {
    color: theme.colors.system.grey7,
    marginBottom: 20,
  },
  link: {
    width: '100%',
    border: `2px solid ${theme.colors.system.grey3}`,
    borderRadius: 4,
    backgroundColor: theme.colors.system.grey1,
    padding: '10px 12px',
    margin: '15px 0',
    '& > a': {
      textDecoration: 'none',
    },
  },
}));

const ClaimProfileContent = ({ networkData, verifiedProfiles }) => {
  const classes = useStyles();
  const auth = useAuth();
  const [, setRoute] = useRoute();
  const { createNotification } = useNotification();

  const { title: network, logo } = networkData;
  const [profileUrl, setProfileUrl] = useState('');
  const [{ data: verificationData }, postVerificationCode] = useApi();

  const isUrl = (str) => {
    /* const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
    + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
    + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
    + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
    + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str); */

    if (str.indexOf('http://') !== -1) { return true; }
    if (str.indexOf('https://') !== -1) { return true; }
    if (str.indexOf('/') !== -1) { return true; }
    if (str.indexOf('\\') !== -1) { return true; }

    return false;
  };

  const getCode = () => {
    if (isUrl(profileUrl) || profileUrl.indexOf('@') !== -1) {
      createNotification('error', `Please enter a valid ${network} handle without the @ symbol`);
    } else {
      postVerificationCode({
        url: `/account/${auth.account.id}/profile/influencer/claim-profile/`,
        method: 'post',
        data: {
          network_handle: profileUrl.toLowerCase(),
          network_type: network.toLowerCase(),
        },
      });
    }
  };

  useEffect(() => {
    if (verificationData) {
      const verifiedNetworkIds = verifiedProfiles.map((profile) => profile.network_id.toLowerCase());
      if (verifiedNetworkIds.indexOf(verificationData.network_id.toLowerCase()) === -1) {
        setRoute({
          pathname: `/claim-profile/${verificationData.network_type.toLowerCase()}/profile/claim/${verificationData.network_id}`,
        });
      } else {
        setRoute({
          pathname: `/claim-profile/${verificationData.network_type.toLowerCase()}/profile/verify/${verificationData.network_id}`,
        });
      }
    }
    // eslint-disable exhaustive-deps
  }, [verificationData, setRoute, verifiedProfiles]);

  return (
    <div className={classes.root}>
      <div className={classes.mainContent}>
        <div className={classes.header}>
          <div className={classes.logoWrapper}>
            <img src={logo} alt={network} style={{ width: 45, height: 45 }} />
          </div>
          <div style={{ flex: 1 }}>
            <Typography variant="h5" component="h5" className={classes.heading}>
              {network}
              &nbsp;Profile
            </Typography>
            <Typography variant="body2" component="p" className={classes.headerSubText}>
              Enter your&nbsp;
              {network}
              &nbsp;profile handle to claim your profile &amp; start your verification process.
            </Typography>
            <TextField
              variant="outlined"
              placeholder={`Enter ${network} profile handle`}
              value={profileUrl}
              onChange={(e) => setProfileUrl(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" color="primary">
                    <Button
                      color="primary"
                      size="small"
                      disabled={!profileUrl}
                      onClick={getCode}
                    >
                      Get Code
                    </Button>
                  </InputAdornment>
                ),
                style: {
                  fontSize: 12,
                },
              }}
              fullWidth
              size="small"
            />
          </div>
        </div>
      </div>

      <div className={classes.infoWrapper}>
        <Typography variant="body2" component="p">
          Verification Steps
        </Typography>
        <ul className={classes.instructions}>
          <Typography variant="body2" component="li">
            Enter your&nbsp;
            {network}
            &nbsp;handle without the @ symbol.
          </Typography>
          <Typography variant="body2" component="li">
            Press “Get Code”
          </Typography>
          <Typography variant="body2" component="li">
            Highlight the code and copy it to your clipboard.
          </Typography>
          <Typography variant="body2" component="li">
            Go to the&nbsp;
            {network}
            &nbsp;link mentioned, paste the code as a comment.
          </Typography>
          <Typography variant="body2" component="li">
            After you’re done, return to this page and click Verify Connection.
          </Typography>
        </ul>
      </div>
    </div>
  );
};

ClaimProfileContent.propTypes = {
  networkData: PropTypes.shape().isRequired,
  verifiedProfiles: PropTypes.arrayOf(PropTypes.shape()),
};

ClaimProfileContent.defaultProps = {
  verifiedProfiles: [],
};

export default ClaimProfileContent;
