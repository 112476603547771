import React from 'react';
import ReactGA from 'react-ga';

import {
  Redirect,
  createHistory,
  LocationProvider,
  Router as ReachRouter,
} from '@reach/router';

import GlobalStoreRouteContainer from './GlobalStoreRouteContainer';
import NavigationRouteContainer from './NavigationRouteContainer';
import RouteContainer from './RouteContainer';
import AuthenticatedRouteContainer from './AuthenticatedRouteContainer';
import VerifiedRouteContainer from './VerifiedRouteContainer';
import NoMatch from './NoMatch';
import {
  DashboardPage,
  ProfilePage,
} from '../pages';
import {
  DiscoverPage,
  VipPage,
} from '../pages/discover';
import {
  EmailVerificationConfirmPage,
  EmailVerificationRequestPage,
  LoginPage,
  SignupPage,
  MemberSignup,
  PasswordResetConfirmPage,
  PasswordResetRequestPage,
} from '../pages/auth';
import {
  ListPage,
  ListsPage,
} from '../pages/list';
import {
  ScrunchUniversityPage,
  ScrunchUniversityModulePage,
} from '../pages/scrunch-university';
import ScrunchResourcesPage from '../pages/scrunch-resources/ScrunchResourcesPage';
import {
  ContentApprovalPage,
  BrandDashboardPage,
  CampaignDashboardPage,
} from '../pages/content-approval';
import {
  SparkPage,
  SparkModulePage,
} from '../pages/spark-ffp';
import CampaignReportPage from '../pages/campaign-report/CampaignReport';
import {
  ClaimProfilePage,
  ClaimProfileModulePage,
} from '../pages/claim-profile';
import {
  AccountSettingsPage,
  PlansSettingsPage,
  UserSettingsPage,
  MyTeamSettingsPage,
  EditDeleteUsersPage,
  PreferencesPage,
} from '../pages/settings';
import PublicListPage from '../pages/public-list/PublicListPage';
import InfluencerDashboard from '../pages/dashboard/InfluencerDashboard';
import { MediaKitPage, CreateMediaKit } from '../pages/media-kit';

const Router = () => {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  const history = createHistory(window);

  history.listen((window) => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return (
    <LocationProvider history={history}>
      <ReachRouter style={{ width: '100%', height: '100%' }}>
        <GlobalStoreRouteContainer path="/">
          {/* Main pages */}
          <VerifiedRouteContainer path="/">
            <NavigationRouteContainer path="/">
              <DashboardPage path="/" />
              <InfluencerDashboard path="/dashboard" />

              <DiscoverPage path="discover" />
              <VipPage path="discover/vip" />
              <ProfilePage path="profile/:networkId" />
              <ListsPage path="list" />
              <ListPage path="list/:listId" />
              <ListPage path="list/:listId/:slug" />

              {/* Scrunch university */}
              <ScrunchUniversityPage path="/scrunch-university" />
              <ScrunchUniversityModulePage path="/scrunch-university/module/:moduleId" />
              <ScrunchUniversityModulePage path="/scrunch-university/module/:moduleId/lesson/:lessonId" />

              {/* Scrunch resources */}
              <ScrunchResourcesPage path="/scrunch-resources" />
              {/* <ScrunchResourcesModulePage path="/scrunch-resources/:resourceId" /> */}

              {/* Content Approval */}
              <RouteContainer path="/content-approval">
                <ContentApprovalPage path="/" />
                <BrandDashboardPage path="/:brandId" />
                <CampaignDashboardPage path="/:brandId/campaign/:campaignId" />
              </RouteContainer>

              {/* Spark FFP */}
              <SparkPage path="/spark-ffp" />
              <SparkModulePage path="/spark-ffp/module/:moduleId" />
              <SparkModulePage path="/spark-ffp/module/:moduleId/lesson/:lessonId" />

              {/* Campaign Report */}
              <CampaignReportPage path="/campaign-report" />

              {/* Claim Profile */}
              <ClaimProfilePage path="/claim-profile/:networkName" />
              <ClaimProfileModulePage path="/claim-profile/:networkName/profile/:claimType/:networkId" />

              {/* Settings */}
              <RouteContainer path="settings">
                <Redirect from="/" to="user" noThrow />
                <AccountSettingsPage path="account" />
                <PlansSettingsPage path="plans" />
                <PlansSettingsPage path="plans/data-saver" />
                <UserSettingsPage path="user" />
                <MyTeamSettingsPage path="my-team" />
                <EditDeleteUsersPage path="manage-users" />
                <PreferencesPage path="preferences" />
              </RouteContainer>

              {/* Media Kit */}
              <RouteContainer path="media-kit">
                <MediaKitPage path="/" />
                <CreateMediaKit path="design/:mode/:mkId" />
              </RouteContainer>

              {/* 404 page */}
              <NoMatch default />
            </NavigationRouteContainer>
          </VerifiedRouteContainer>

          {/* Auth pages */}
          <RouteContainer path="auth">
            <Redirect from="/" to="login" />
            <LoginPage path="login" />
            <SignupPage path="signup" />
            <MemberSignup path="employee-invitation/:verificationId" />
            <PasswordResetRequestPage path="password-reset" />
            <PasswordResetConfirmPage path="password-reset/:token" />
            <AuthenticatedRouteContainer path="email-verification">
              <EmailVerificationRequestPage path="/" />
              <EmailVerificationConfirmPage path=":token" />
            </AuthenticatedRouteContainer>
          </RouteContainer>

          {/* Public lists */}
          <PublicListPage path="public-list/:listId" />
        </GlobalStoreRouteContainer>

        {/* 404 page */}
        <NoMatch default />
      </ReachRouter>
    </LocationProvider>
  );
};

export default Router;
