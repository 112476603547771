import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';

import { TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useApi } from '../../hooks';

const parseLocationType = (location) => {
  switch (location.feature_class) {
    case 'L':
      return 'Global region';
    case 'A':
      if (location.feature_code === 'PCLI') {
        return 'Country';
      }
      if (location.feature_code === 'ADM1') {
        return 'State';
      }
      return 'Region';
    case 'P':
      return 'City/Town/Suburb';
    default:
      return null;
  }
};

const LocationOption = (props) => {
  const { location } = props;
  const locationType = parseLocationType(location);
  return (
    <div>
      <Typography variant="body1">{location.label}</Typography>
      {locationType && (
        <Typography color="textSecondary" variant="body2">{parseLocationType(location)}</Typography>
      )}
    </div>
  );
};

LocationOption.propTypes = {
  location: PropTypes.shape().isRequired,
};

const AudienceLocationSelect = (props) => {
  const {
    className,
    multiple,
    onChange,
    value,
  } = props;
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [{ data: defaultLocations }] = useApi({
    method: 'post',
    url: '/location/search/',
    data: {
      feature_code: ['CONT'],
      from: 0,
      size: 10,
    },
  });
  const [{ data, isFetching, status }, postSearchLocationRequest] = useApi();

  const defaultOptions = useMemo(() => (defaultLocations ? defaultLocations.locations : []), [defaultLocations]);

  useEffect(() => {
    if (inputValue) {
      postSearchLocationRequest({
        method: 'post',
        url: '/location/search/',
        data: {
          prefix: inputValue,
          from: 0,
          size: 10,
        },
      });
    } else {
      setOptions(defaultOptions || []);
    }
  }, [defaultOptions, inputValue, postSearchLocationRequest]);

  useEffect(() => {
    if (status === 200 && data) {
      setOptions(data.locations);
    }
  }, [data, status]);

  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  return (
    <div className={className}>
      <Autocomplete
        getOptionLabel={(option) => (_.isObject(option) ? option.label : option)}
        getOptionSelected={(option, v) => option.geoname_id === v.geoname_id}
        loading={isFetching}
        multiple={multiple}
        noOptionsText="No matching locations"
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={options}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            fullWidth
            label="Search for locations"
            placeholder="Starting typing to search for locations"
          />
        )}
        renderOption={(option) => (
          <LocationOption location={option} />
        )}
        value={value}
      />
    </div>
  );
};

AudienceLocationSelect.propTypes = {
  className: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.shape()),
  ]),
};

AudienceLocationSelect.defaultProps = {
  className: '',
  multiple: false,
  value: null,
};

export default AudienceLocationSelect;
