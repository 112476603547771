import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Link,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from '@reach/router';

import ReactGA from 'react-ga';
import AuthPageBase from './AuthPageBase';
import { PasswordTextField } from '../../common';
import { useApi, useRoute, useNotification } from '../../../hooks';
import { isValidEmail } from '../../../utils/string';

const useStyles = makeStyles((theme) => ({
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  field: {
    width: '100%',
    margin: theme.spacing(1),
  },
  button: {
    width: '100%',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
  },
  footer: {
    paddingTop: theme.spacing(3),
    padding: theme.spacing(1),
    textAlign: 'center',
  },
}));

const SignUp = ({ setIsInfluencer, setNotInfluencer }) => {
  const [route, setRoute] = useRoute();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState();
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [accountType, setAccountType] = useState(route.params.type || '');
  const [accountTypeError, setAccountTypeError] = useState('');
  const { createNotification } = useNotification();

  const classes = useStyles();
  const [{ response, isFetching }, setRequest] = useApi();

  useEffect(() => {
    if (response && response.status === 200) {
      setRoute({ pathname: route.params.next || '/auth/email-verification/' });
    }
    if (response && response.status === 400) {
      createNotification('error', response.data.message);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, route, setRoute]);

  async function handleSubmit(e) {
    e.preventDefault();

    setEmailError(!email ? 'Email is required.' : '');
    if (email) setEmailError(!isValidEmail(email) ? 'Not a valid email.' : '');
    setFirstNameError(!firstName ? 'First name is required.' : '');
    setLastNameError(!lastName ? 'Last name is required.' : '');
    setPasswordError(
      password.length < 6 ? 'Password must be at least 6 characters long.' : '',
    );
    setAccountTypeError(!accountType ? 'An account type must be selected.' : '');

    if (
      email
      && isValidEmail(email)
      && password.length >= 6
      && firstName
      && lastName
      && accountType
    ) {
      setRequest({
        method: 'post',
        url: '/auth/signup/',
        data: {
          email,
          password,
          first_name: firstName,
          last_name: lastName,
          account_type: accountType,
        },
      });
      ReactGA.event({
        category: 'Account Created',
        action: 'Submit clicked after details filled in',
      });
    }
  }

  useEffect(() => {
    if (accountType === 'influencer') {
      setIsInfluencer();
    } else {
      setNotInfluencer();
    }
  }, [accountType, setIsInfluencer, setNotInfluencer]);

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.row}>
        <TextField
          className={classes.field}
          type="email"
          label="Email"
          required
          error={Boolean(emailError)}
          helperText={emailError}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className={classes.row}>
        <TextField
          className={classes.field}
          type="text"
          label="First name"
          required
          error={Boolean(firstNameError)}
          helperText={firstNameError}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <div className={classes.row}>
        <TextField
          className={classes.field}
          type="text"
          label="Last name"
          required
          error={Boolean(lastNameError)}
          helperText={lastNameError}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
      <div className={classes.row}>
        <PasswordTextField
          className={classes.field}
          label="Password"
          required
          error={Boolean(passwordError)}
          helperText={
              passwordError || 'Must be at least 6 characters long.'
            }
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className={classes.row}>
        <FormControl className={classes.field} component="fieldset">
          <FormLabel component="legend" error={Boolean(accountTypeError)} required>Account type</FormLabel>
          <RadioGroup
            aria-label="account type"
            name="account-type"
            value={accountType}
            onChange={(e) => setAccountType(e.target.value)}
          >
            <FormControlLabel value="brand" control={<Radio />} label="I am a brand." />
            <FormControlLabel value="agency" control={<Radio />} label="I am an agency." />
            <FormControlLabel value="influencer" control={<Radio />} label="I am an influencer." />
          </RadioGroup>
          {accountTypeError && (
          <FormHelperText error>{accountTypeError}</FormHelperText>
          )}
        </FormControl>
      </div>
      <Button
        className={classes.button}
        disabled={isFetching}
        type="submit"
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        Sign Up
      </Button>
    </form>
  );
};

SignUp.propTypes = {
  setIsInfluencer: PropTypes.func.isRequired,
  setNotInfluencer: PropTypes.func.isRequired,
};

const SignupPage = () => {
  const classes = useStyles();
  const [{ isFetching }] = useApi();
  const [isInfluencer, setIsInfluencer] = useState(false);

  return (
    <AuthPageBase
      headerTitle={
        isInfluencer
          ? 'EPIC tools to help grow your business of Influence.'
          : "World's #1 Influencer Marketing Platform"
      }
      headerSubtitle={
        isInfluencer
          ? 'GET STARTED FOR FREE'
          : '7-day free trial, no credit card required'
      }
      headerSubtitleTwo={isInfluencer && 'No credit card required'}
      showLoadingBar={isFetching}
      title="Sign up"
    >
      <SignUp
        setIsInfluencer={() => setIsInfluencer(true)}
        setNotInfluencer={() => setIsInfluencer(false)}
      />

      <div className={classes.footer}>
        <Typography variant="body2">Already have an account? </Typography>
        <Link component={RouterLink} to="/auth/login">
          Log in here.
        </Link>
      </div>
    </AuthPageBase>
  );
};

export default SignupPage;
