import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { Chip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import FilterBase from './FilterBase';
import topics from './topics';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 320,
  },
  autoComplete: {
    width: '100%',
  },
  textField: {
    margin: 0,
  },
  tag: {
    display: 'none',
  },
  option: {
    color: theme.colors.primary.main,
    display: 'flex',
    alignItems: 'flex-end',
  },
  checkIcon: {
    height: 13,
    marginBottom: '4px',
  },
  chip: {
    padding: 5,
    height: 'auto',
    maxWidth: '100%',
    '&:not(:last-child)': {
      marginRight: 10,
    },
    marginTop: 10,
  },
}));

const TopicFilter = (props) => {
  const {
    className,
    filterData,
    filterKeys,
    label,
    onApply,
  } = props;
  const [activeText, setActiveText] = useState(null);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    const { [filterKeys[0]]: topic } = filterData;
    if (topic) {
      setActiveText(topic.split(',').join(', '));
      setSelectedTopics(topic.split(','));
    } else {
      setActiveText(null);
      setSelectedTopics([]);
    }
  }, [filterData, filterKeys]);

  const handleOnApply = () => {
    onApply({
      ...filterData,
      [filterKeys[0]]: selectedTopics.join.length ? selectedTopics.join(',') : null,
    });
  };

  const handleOnClear = () => {
    onApply({ ...filterData, [filterKeys[0]]: null });
  };

  const handleOnChange = (event, selected) => {
    setSelectedTopics(selected);
  };

  const handleOnDelete = (topic) => {
    setSelectedTopics(selectedTopics.filter((item) => item !== topic));
  };

  return (
    <FilterBase
      className={className}
      label={label}
      activeText={activeText}
      isActive={Boolean(activeText)}
      onApply={handleOnApply}
      onClear={handleOnClear}
    >
      <div className={classes.container}>
        <Autocomplete
          value={selectedTopics}
          className={classes.autoComplete}
          multiple
          onChange={handleOnChange}
          defaultValue={selectedTopics}
          options={topics}
          getOptionLabel={(option) => option}
          disableClearable
          renderTags={(value, getTagProps) => value.map((option, index) => (
            <Chip
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...getTagProps({ index })}
              className={classes.tag}
            />
          ))}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              placeholder="Make a selection..."
              label="Multiple topics can be selected"
              fullWidth
              margin="normal"
              className={classes.textField}
            />
          )}
          renderOption={(option, state) => (
            <Typography className={state.selected ? classes.option : ''}>
              {state.selected && <CheckIcon className={classes.checkIcon} />}
              {option}
            </Typography>
          )}
        />
        {selectedTopics && (
          <div>
            {selectedTopics.map((topic) => (
              <Chip
                key={topic}
                label={topic}
                onDelete={() => { handleOnDelete(topic); }}
                color="primary"
                deleteIcon={<ClearIcon style={{ height: 15 }} />}
                className={classes.chip}
              />
            ))}
          </div>
        )}
      </div>
    </FilterBase>
  );
};

TopicFilter.propTypes = {
  className: PropTypes.string,
  filterData: PropTypes.shape().isRequired,
  filterKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  onApply: PropTypes.func.isRequired,
};

TopicFilter.defaultProps = {
  className: null,
};

export default TopicFilter;
