import React from 'react';

import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 'max-content',
    padding: theme.spacing(1),
    borderRadius: 2,
    backgroundColor: theme.colors.warning.light,
    marginBottom: '1.5rem',
    position: 'relative',
  },
  warning: {
    color: theme.colors.warning.main,
    position: 'absolute',
    left: '0.5rem',
    top: '0.3rem',
  },
  text: {
    color: theme.colors.warning.main,
    paddingLeft: '2rem',
  },
}));

const DowngradeStatus = (props) => {
  const {
    customer,
    nextPlan,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="body2" className={classes.text}>
        <WarningIcon className={classes.warning} />
        {`Your plan is scheduled to be downgraded to the ${nextPlan.product.name} plan on ${moment.unix(customer.subscriptions.data[0].current_period_end).format('Do MMMM, YYYY')}. ${nextPlan.product.name !== 'Starter' ? '' : "If you don't select a paid plan before this time all of your influencer data and lists will be deleted"}`}
      </Typography>
    </div>
  );
};

DowngradeStatus.propTypes = {
  customer: PropTypes.shape().isRequired,
  nextPlan: PropTypes.shape().isRequired,
};

export default DowngradeStatus;
