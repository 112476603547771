import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import Header from './Header';
import Sidebar from './Sidebar';

const COMPACT_SIDEBAR_WIDTH = 50;
const EXPANDED_SIDEBAR_WIDTH = 240;
const HEADER_HEIGHT = 60;

const useStyles = makeStyles(() => ({
  container: {
    width: '100vw',
    height: '100vh',
    display: 'grid',
    gridTemplateColumns: (props) => (props.expandSidebar ? `${EXPANDED_SIDEBAR_WIDTH}px 1fr` : `${COMPACT_SIDEBAR_WIDTH}px 1fr`),
    gridTemplateRows: `${HEADER_HEIGHT}px 1fr`,
    gridTemplateAreas: `
      "sidebar header"
      "sidebar content"
    `,
  },
  sidebar: {
    gridArea: 'sidebar',
  },
  header: {
    gridArea: 'header',
    overflow: 'hidden',
  },
  children: {
    gridArea: 'content',
    height: '100%',
    overflow: 'hidden',
  },
}));

const Navigation = ({ children }) => {
  const [expandSidebar, setExpandSidebar] = useState(true);
  const classes = useStyles({ expandSidebar });

  const handleSidebarButtonClick = (event) => {
    event.preventDefault();
    setExpandSidebar(!expandSidebar);
  };

  return (
    <div className={classes.container}>
      <Sidebar
        barHeight={HEADER_HEIGHT}
        className={classes.sidebar}
        compact={!expandSidebar}
        onSidebarButtonClick={handleSidebarButtonClick}
        width={expandSidebar ? EXPANDED_SIDEBAR_WIDTH : COMPACT_SIDEBAR_WIDTH}
      />
      <Header
        className={classes.header}
        height={HEADER_HEIGHT}
      />
      <div className={classes.children}>
        {children}
      </div>
    </div>
  );
};

Navigation.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Navigation;
