// Campaign page posts list
import React, { useState } from 'react';

import {
  Typography,
  Button,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HighlightIcon from '@material-ui/icons/Highlight';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Calendar from './Calendar';
import PostItem from './PostItem';
import Search from '../common/icons/Search';
import AddNewPostPane from './AddNewPostPane';
import ViewPost from './ViewPost';
import { useContentManager, useAuth } from '../../hooks';

// import { useApi, useAuth, useRoute } from "../../../hooks";

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: '1550px',
    backgroundColor: theme.colors.system.grey1,
    borderRadius: '4px',
    display: 'flex',
    padding: '25px 20px',
    border: `2px solid ${theme.colors.system.grey2}`,
  },
  leftCont: {
    width: '500px',
    marginRight: '15px',
  },
  calendar: {
    width: '900px',
    zIndex: '0',
  },
  filters: {},
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  headerButtons: {
    display: 'flex',
  },
  headerButton: {
    fontSize: '10px',
    display: 'inline',
    marginLeft: '10px',
  },
  search: {
    backgroundColor: 'white',
  },
  tabs: {
    width: '100%',
    borderBottom: `2px solid ${theme.colors.system.grey2}`,
    margin: '20px 0',
    display: 'flex',
  },
  tabTitle: {
    textAlign: 'center',
    padding: '15px 20px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  activeTab: {
    border: `2px solid ${theme.colors.system.grey2}`,
    borderRadius: '4px',
    padding: '15px 20px',
    borderBottom: `5px solid ${theme.colors.system.grey1}`,
    cursor: 'pointer',
    marginBottom: '-4px',
    color: theme.colors.primary.main,
    display: 'flex',
    alignItems: 'center',
  },
  posts: {},
  postButton: {
    width: '100%',
    '&>*': {
      width: '100%',
    },
  },
  calendarItem: {
    '& > .fc-daygrid-event-dot': {
      borderRadius: '50px',
      border: '6px solid',
    },
  },
}));

const CalendarPosts = () => {
  const classes = useStyles();
  const content = useContentManager();
  const auth = useAuth();
  const [plannedTab, setPlannedTab] = useState(true);
  const [isViewPost, setIsViewPost] = useState(false);

  const isClient = auth.user.agency_role === 'client';

  const calendarEvents = content.posts && content.posts.filter((post) => post.post_datetime && post.text)
    .map((post) => ({
      title: `${post.text.slice(0, 9)}...`,
      start: post.post_datetime,
      end: post.post_datetime,
      borderColor: content.campaign && content.campaign.color,
      className: classes.calendarItem,
    }));

  const handleClose = (callback) => {
    setIsViewPost(false);
    if (callback) {
      callback();
    }
    content.getCampaignAndPosts(auth.account.id, content.brand.id, content.campaign.id);
  };

  const handleClickOnView = (post) => {
    content.setPost(auth.account.id, post.id);
    setIsViewPost(true);
  };

  return (
    <>
      <ViewPost isOpen={isViewPost} closeDialog={handleClose} />
      {/* <div className={classes.filters}>
        <Typography>Filters</Typography>
      </div> */}
      <div className={classes.mainCont}>
        <div className={classes.leftCont}>
          <div className={classes.header}>
            <Typography style={{ fontSize: '20px' }}>
              Posts (
              {content.posts.length}
              )
            </Typography>
            <div className={classes.headerButtons}>
              <Button
                className={classes.headerButton}
                color="primary"
                variant="outlined"
              >
                *icon* Download All Assets
              </Button>
              {!isClient && content.brand && content.campaign && (
                <div className={classes.headerButton}>
                  <AddNewPostPane />
                </div>
              )}
            </div>
          </div>
          <TextField
            className={classes.search}
            fullWidth
            placeholder="Search Posts"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search style={{ fontSize: '15px', marginLeft: '10px' }} />
                </InputAdornment>
              ),
            }}
          />
          <div className={classes.tabs}>
            <Typography
              className={plannedTab ? classes.activeTab : classes.tabTitle}
              onClick={() => setPlannedTab(true)}
            >
              <PlaylistAddIcon
                style={{ fontSize: '20px', marginRight: '10px' }}
              />
              Planned Posts
            </Typography>
            <Typography
              className={!plannedTab ? classes.activeTab : classes.tabTitle}
              onClick={() => setPlannedTab(false)}
            >
              <HighlightIcon
                style={{ fontSize: '20px', marginRight: '10px' }}
              />
              Post Ideas
            </Typography>
          </div>
          <div className={classes.posts}>
            {content.fetching ? (
              <Typography>
                Loading...
              </Typography>
            ) : (
              <>
                {plannedTab
                  && content.posts
                  && content.posts
                    .filter((i) => i.post_datetime)
                    .map((post) => (
                      <PostItem
                        img={post.img_url && post.img_url[0]}
                        contentType={post.content_type}
                        brandImg={content.brand && content.brand.username}
                        brandName={content.brand && content.brand.username}
                        postStatus={post.status}
                        text={post.text}
                        date={post.post_datetime}
                        key={post.text}
                        handleClick={() => handleClickOnView(post)}
                      />
                    ))}
                {!plannedTab
                  && content.posts
                  && content.posts
                    .filter((i) => !i.post_datetime)
                    .map((post) => (
                      <PostItem
                        img={post.img_url && post.img_url[0]}
                        contentType={post.content_type}
                        brandImg={content.brand && content.brand.username}
                        brandName={content.brand && content.brand.username}
                        postStatus={post.status}
                        text={post.text}
                        date={post.post_datetime}
                        key={post.post_text}
                        handleClick={() => handleClickOnView(post)}
                      />
                    ))}
              </>
            )}
          </div>
        </div>
        <div className={classes.calendar}>
          <Calendar events={calendarEvents} />
        </div>
      </div>
    </>
  );
};

export default CalendarPosts;
