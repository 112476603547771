import moment from 'moment';

const formatDate = (datetime) => {
  if (!datetime) {
    return '-';
  }
  const d = moment(datetime);
  const now = moment();

  if (d.isSame(now, 'day')) {
    // Is today
    return `${d.format('Do MMM YYYY')} (Today)`;
  }

  if (d.isSame(now.subtract(1, 'day'), 'day')) {
    // Is yesterday
    return `${d.format('Do MMM YYYY')} (Yesterday)`;
  }

  return d.format('Do MMM YYYY');
};

const joinDateToTime = (date, time) => {
  const t = moment(time);
  const d = moment(date);

  return `${d.format().split('T')[0]}T${t.format().split('T')[1]}`;
};

const formatDateWithTime = (datetime) => {
  if (!datetime) {
    return '-';
  }
  const d = moment(datetime);

  return `${formatDate(datetime)} - ${d.format('HH:mm')}`;
};

export { formatDate, joinDateToTime, formatDateWithTime };
