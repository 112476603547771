import React, { useEffect, useState } from 'react';

import { Link } from '@reach/router';
import ReactGA from 'react-ga';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import { useAuth, useApi, useRoute } from '../../../hooks';
import InstagramLogo from '../../../assets/images/network-logo/instagram.svg';
import NewsItem from '../../dashboard/NewsItem';
import TipOfTheWeek from '../../dashboard/TipOfTheWeek';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '50px 40px',
    height: '100vh',
    overflowY: 'auto',
  },
  dashboardCont: {
    width: '100%',
    display: 'grid',
    // gridTemplateColumns: '2.4fr 1fr',
    gridGap: 20,
    margin: '35px 0',
  },
  dashboardLeft: {
    display: 'flex',
    flexDirection: 'column',
  },
  claimCont: {
    backgroundColor: theme.colors.system.grey1,
    border: `1px solid ${theme.colors.system.grey3}`,
    borderRadius: 4,
    marginBottom: 25,
    padding: '25px 20px',
    '& a, a:hover, a:visited': {
      textDecoration: 'none !important',
      color: '#000',
    },
  },
  title: {
    ...theme.textStyles.bodyLarge,
  },
  profilesCont: {
    display: 'flex',
    marginTop: 25,
    '& > div:not(:last-child)': {
      marginRight: 10,
    },
  },
  profileBox: {
    backgroundColor: '#FFFFFF',
    border: `1px solid ${theme.colors.system.grey3}`,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 150,
    width: 150,
    '& img': {
      width: 35,
      height: 35,
      marginBottom: 10,
    },
  },
  boxNetwork: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  verified: {
    backgroundColor: theme.colors.success.light,
    borderRadius: 2,
    display: 'flex',
    width: '80%',
    justifyContent: 'center',
    marginBottom: 12,
    padding: 7,
  },
  checkIcon: {
    color: theme.colors.success.main,
    fontSize: 15,
    marginRight: 8,
  },
  claimText: {
    color: theme.colors.success.main,
  },
  newsCont: {
    border: `1px solid ${theme.colors.system.grey3}`,
    borderRadius: 4,
    padding: '25px 20px',
    '& a, a:hover, a:visited': {
      textDecoration: 'none !important',
      color: '#000',
    },
  },
  newsItemsWrapper: {
    padding: '25px 0',
    display: 'flex',
  },
  newsItem: {
    width: '32%',
    '&:not(:nth-child(3n))': {
      marginRight: '2%',
    },
  },
}));

const BlogsContainer = () => {
  const classes = useStyles();

  const [blogData, setBlogData] = useState(null);

  useEffect(() => {
    const request = new XMLHttpRequest();
    request.open('GET', 'https://scrunch-web-strapi.herokuapp.com/blogs?category=For_Influencers', true);
    request.onload = () => {
      if (request.status >= 200 && request.status < 300) {
        setBlogData(JSON.parse(request.responseText));
      }
    };
    request.send();
  }, []);

  if (!blogData) {
    return null;
  }

  return (
    <div className={classes.newsCont}>
      <Typography variant="body1" className={classes.title}>
        Latest News
      </Typography>
      <div className={classes.newsItemsWrapper}>
        {blogData && blogData.slice(0, 3).map((blog) => (
          <div className={classes.newsItem} key={blog.title}>
            <NewsItem item={blog} />
          </div>
        ))}
      </div>
    </div>
  );
};

const InfluencerDashboard = () => {
  const classes = useStyles();
  const auth = useAuth();
  const [, setRoute] = useRoute();

  const [{ data: verifiedProfiles }] = useApi({
    url: `/account/${auth.account.id}/profile/influencer/list/`,
    method: 'get',
  });

  if (auth.account.type !== 'influencer') {
    setRoute({ pathname: '/discover' });
  }

  useEffect(() => {
    if (auth && auth.account) {
      const nextPlan = auth.account.billing.next_plan ? auth.account.billing.next_plan.id : '';
      // sending GA custom dimensions another way
      ReactGA.ga('send', 'pageview', {
        dimension2: auth.account.billing.current_plan.id,
        dimension3: nextPlan,
        dimension4: auth.account.type,
        dimension5: "ReactGA.ga('send', 'pageview') custom dimension?",
      });
      // sending GA custom dimensions another way
      ReactGA.ga('set', 'dimension2', auth.account.billing.current_plan.id);
      ReactGA.ga('set', 'dimension3', nextPlan);
      ReactGA.ga('set', 'dimension4', auth.account.type);
      ReactGA.ga('set', 'dimension5', "ReactGA.ga('set', 'dimension2', auth...)");
      // GA4 User Properties NOT WORKING, NEED TO SET UP A GTAG ACCOUNT
      // ReactGA.ga('set', 'user_properties', {
      //   current_plan: auth.account.billing.current_plan.id,
      //   next_plan: nextPlan,
      //   account_type: auth.account.type,
      //   where_test: "ReactGA.ga('set','user_properties') user property?",
      // });
    }
  }, [auth]);

  return (
    <div className={classes.root}>
      <div>
        <Typography variant="h5" component="h5" style={{ marginBottom: 5 }}>
          Welcome back,&nbsp;
          {auth.user.first_name}
          ! 👋
        </Typography>
      </div>

      <div className={classes.dashboardCont}>
        <div className={classes.dashboardLeft}>
          <div className={classes.claimCont}>
            <Typography variant="body1" className={classes.title}>
              Claim Your Profiles
            </Typography>
            <Typography
              variant="body2"
              component="p"
              style={{ marginButton: 25 }}
            >
              Claim your profiles to start leveraging your Scrunch Influencer
              tools.
            </Typography>
            <Link to="/claim-profile/instagram">
              <div className={classes.profilesCont}>
                <div className={classes.profileBox}>
                  <div className={classes.boxNetwork}>
                    <img src={InstagramLogo} alt="Instagram" />
                    <Typography variant="body2" component="span">
                      Instagram
                    </Typography>
                  </div>
                  {verifiedProfiles
                    && verifiedProfiles.length > 0
                    && verifiedProfiles.filter((p) => p.is_approved !== 0).length
                      > 0 && (
                      <div className={classes.verified}>
                        <CheckCircleRoundedIcon className={classes.checkIcon} />
                        <Typography
                          variant="body2"
                          component="span"
                          className={classes.claimText}
                        >
                          {
                            verifiedProfiles.filter((p) => p.is_approved !== 0)
                              .length
                          }
                          &nbsp;Claimed
                        </Typography>
                      </div>
                  )}
                </div>
              </div>
            </Link>
          </div>

          <BlogsContainer />
        </div>

        {/* <Notifications /> */}
      </div>

      <TipOfTheWeek />
    </div>
  );
};

export default InfluencerDashboard;
