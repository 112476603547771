import React from 'react';
import PropTypes from 'prop-types';

import {
  View,
  Text,
  Image,
  StyleSheet,
} from '@react-pdf/renderer';

import { textStyles } from '../definitions';
import { PdfPage, Header, InfoCard } from '../components';

const styles = StyleSheet.create({
  col: {
    flex: 1,
    maxWidth: '50%',
  },
  main: {
    flex: 1,
    position: 'relative',
  },
  imgWrapper: {
    width: '100%',
    height: '100%',
    padding: 0,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
});

const Page2 = ({ formData }) => {
  /* const paragraphs = [
    `I\'m the co-founder of Global Influencer Marketing platform Scrunch, and I\'ve dedicated the past 15+ years of
    my life to sales success. I fucking love it. And yes, I get that most people hate sales... and hopefully
    that\'s why you are here today.`,
    `*insert some vomit-worthy stats for you here* Yep, I\'ve brokered mutli-million dollar deals. Yep I\'ve sold
    hundreds ofmillions in total sales revenue for both previous employers and my current business.`,
    `But it wasn\'t until I stopped giving a fuck about the numbers, and started giving a fuck about the humans,
    that the game really changed.`,
    `I\'ve taken all the dry, boring sales courses.. delivered by old white men. And after being asked continuously
    by other sales people and founders \'what the best sales tactics\' were... far too many times. I said \'fuck it\',
    poured a glass of bubbles and decided to distill complex sales strategies into simple, easy to understand
    concepts and communicate them in a way to help people actually give AF. My goal is simple. I want you to let go
    of mastering a 50 year old sales process, and just sell more shit so you achieve your goals in life and business.`,
  ]; */

  const paragraphs = formData.personalDetails.about.split(/\r?\n/);

  return (
    <PdfPage>
      <View style={styles.col}>
        <View style={styles.imgWrapper}>
          <Image src={formData.personalDetails.aboutImg} style={styles.image} />
        </View>
      </View>
      <View style={{ ...styles.col, padding: 50 }}>
        <Header align="right">
          {formData.personalDetails.firstName}
          {' '}
          {formData.personalDetails.lastName}
        </Header>
        <View style={styles.main}>
          <InfoCard style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Text style={textStyles.h2}>
              {formData.personalDetails.firstName}
              {' '}
              {formData.personalDetails.lastName}
            </Text>
            {paragraphs && paragraphs.map((para) => (
              <View style={{ marginTop: 20 }} key={para.substring(0, 10)}>
                <Text style={textStyles.body}>{para}</Text>
              </View>
            ))}
          </InfoCard>
        </View>
        <Header align="right">
          {formData.personalDetails.instagramHandle ? `@${formData.personalDetails.instagramHandle}` : ''}
        </Header>
      </View>
    </PdfPage>
  );
};

Page2.propTypes = {
  formData: PropTypes.shape().isRequired,
};

export default Page2;
