import React from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatPercentage } from '../../utils/number';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'min-content',
  },
  title: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  chart: {
    minHeight: 150,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dataContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
  },
  dataValueContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    '&:hover': {
      '& .tooltip': {
        visibility: 'visible',
      },
    },
  },
  dataValue: {
    width: '100%',
    backgroundColor: theme.colors.highlight.main,
    borderRadius: '4px 4px 0px 0px',
  },
  dataLegend: {
    height: 40,
    paddingTop: 2,
    textAlign: 'center',
  },
  legendLabel: {
    ...theme.textStyles.bodyExtraSmall,
    fontWeight: 'bold',
  },
  tooltip: {
    visibility: 'hidden',
  },
}));

const BarChart = (props) => {
  const {
    data,
    title,
    labelClassName,
  } = props;

  const classes = useStyles();

  const valueMax = _.max(data.map(({ value }) => value));
  const totalValue = _.sum(data.map(({ value }) => value));

  return (
    <div className={classes.root}>
      {title && (
        <Typography className={classes.title} variant="body2">{title}</Typography>
      )}
      <div className={classes.chart}>
        {data.map(({ label, value }) => (
          <div
            key={label}
            className={classes.dataContainer}
            style={{
              width: `${60 / data.length}%`,
            }}
          >
            <div className={classes.dataValueContainer}>
              <Typography
                variant="body2"
                className={`tooltip ${classes.tooltip}`}
              >
                {formatPercentage(value / totalValue)}
              </Typography>
              <div
                className={classes.dataValue}
                style={{
                  height: `${(value / valueMax) * 100}%`,
                }}
              >
                &nbsp;
              </div>
            </div>
            <div
              className={classes.dataLegend}
            >
              <Typography className={`${classes.legendLabel} ${labelClassName}`} variant="body2">{label}</Typography>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

BarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  title: PropTypes.string,
  labelClassName: PropTypes.string,
};

BarChart.defaultProps = {
  title: null,
  labelClassName: '',
};

export default BarChart;
