import { createMuiTheme } from '@material-ui/core/styles';

import BeatriceRegularWoff2 from '../assets/fonts/beatrice/Beatrice-Regular.woff2';
import BeatriceThinWoff2 from '../assets/fonts/beatrice/Beatrice-Thin.woff2';
import BeatriceMediumWoff2 from '../assets/fonts/beatrice/Beatrice-Medium.woff2';
import CooperLtBtTtf from '../assets/fonts/cooperLtBt/CooperLightBT.ttf';

const colors = {
  primary: {
    main: '#FF503A',
    light: '#FF8466',
    dark: '#C,40A0F',
  },
  secondary: {
    main: '#354065',
    light: '#35406510',
  },
  button: {
    primary: {
      main: '#E75041',
      hover: '#FF7B6D',
    },
    secondary: {
      main: '#354065',
      hover: '#35406510',
      green: '#219653',
    },
  },
  text: {
    main: '#262628',
    light: '#909090',
    extraLight: '#D5D5D5',
  },
  system: {
    light: '#FFFFFF',
    grey1: '#FAFAFA',
    grey2: '#F5F4F4',
    grey3: '#EFEEEE',
    grey4: '#E2E2E2',
    grey5: '#D4D4D4',
    grey6: '#BCB8B8',
    grey7: '#9C9C9C',
    grey8: '#5E5E5E',
    dark: '#1E1E22',
    darkTrans: '#1E1E2230',
  },

  success: {
    main: '#219653',
    light: '#27AE6010',
    dark: '#013730',
  },
  warning: {
    main: '#F2994A',
    mid: '#FFEEE7',
    light: '#F2C94C10',
    dark: '#F2994A',
  },
  error: {
    main: '#B00020',
    light: '#FFEEF1',
    dark: '#691524',
  },
  info: {
    main: '#4299E1',
    light: '#E4F3FF',
    dark: '#065495',
  },
  danger: {
    main: '#CB1A08',
  },
  highlight: {
    main: '#354065',
    light: '#A9B7E5',
  },

  background: {
    primary: '#F5F0EA',
  },
};

const textStyles = {
  h1: {
    fontSize: '48px',
    letterSpacing: '-0.01em',
    lineHeight: '100%',
  },
  h2: {
    fontSize: '44px',
    letterSpacing: '-0.01em',
    lineHeight: '100%',
  },
  h3: {
    fontSize: '38px',
    letterSpacing: '-0.01em',
    lineHeight: '100%',
  },
  h4: {
    fontSize: '30px',
    letterSpacing: '-0.01em',
    lineHeight: '100%',
  },
  h5: {
    fontSize: '23px',
    letterSpacing: '-0.01em',
    lineHeight: '100%',
  },
  h6: {
    fontSize: '14px',
    letterSpacing: '-0.01em',
    lineHeight: '100%',
  },
  buttonRegular: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px',
  },
  buttonSmall: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px',
  },
  bodyExtraSmall: {
    fontSize: '10px',
    lineHeight: '15px',
  },
  bodySmall: {
    fontSize: '12px',
    lineHeight: '18px',
  },
  body: {
    fontSize: '14px',
    lineHeight: '21px',
  },
  bodyMedium: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  bodyLarge: {
    fontSize: '18px',
    lineHeight: '27px',
  },
  bodyExtraLarge: {
    fontSize: '20px',
    lineHeight: '30px',
  },
};

const beatriceRegular = {
  fontFamily: 'Beatrice-Regular',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${BeatriceRegularWoff2} format('woff2'))`,
};

const beatriceMedium = {
  fontFamily: 'Beatrice-Medium',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `url(${BeatriceMediumWoff2} format('woff2'))`,
};

const beatriceThin = {
  fontFamily: 'Beatrice-Thin',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${BeatriceThinWoff2} format('woff2'))`,
};

const cooperLtBtRegular = {
  fontFamily: 'Cooper Lt BT',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${CooperLtBtTtf} format('truetype'))`,
};

const fonts = {
  primary: {
    fontFamily: 'Beatrice-Regular',
  },
  secondary: {
    fontFamily: 'Beatrice-Thin',
  },
  tertiary: {
    fontFamily: 'Cooper Lt BT',
  },
  medium: {
    fontFamily: 'Beatrice-Medium',
  },
};

const theme = createMuiTheme({
  palette: {
    primary: colors.primary,
    secondary: colors.secondary,
    text: {
      primary: colors.text.main,
      secondary: colors.text.light,
    },
  },
  typography: {
    fontFamily: 'Beatrice-Regular',
  },
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        paddingLeft: 8,
      },
    },
    MuiButton: {
      root: {
        textTransform: 'inherit',
        borderRadius: 2,
        ...textStyles.buttonRegular,
        alignItems: 'center',
        justifyContent: 'center',
      },
      contained: {
        padding: '7px 10px',
        boxShadow: 'none',
        borderRadius: 4,
        '&:hover': {
          boxShadow: 'none',
        },
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      },
      containedSizeSmall: {
        padding: '7px 10px',
      },
      outlinedPrimary: {
        borderColor: colors.button.primary.main,
        color: colors.button.primary.main,
        '&:hover': {
          borderColor: colors.button.primary.main,
          color: colors.button.primary.hover,
        },
      },
      outlinedSecondary: {
        borderColor: colors.button.secondary.main,
        color: colors.button.secondary.main,
        '&:hover': {
          borderColor: colors.button.secondary.main,
          color: colors.button.secondary.hover,
        },
      },
      containedPrimary: {
        color: colors.system.light,
        backgroundColor: colors.button.primary.main,
        '&:hover': {
          backgroundColor: colors.button.primary.hover,
        },
      },
      containedSecondary: {
        color: colors.system.light,
        backgroundColor: colors.button.secondary.main,
        '&:hover': {
          backgroundColor: colors.button.secondary.hover,
        },
      },
      textSizeSmall: {
        fontWeight: 500,
        ...fonts.medium,
      },
      sizeSmall: {
        ...textStyles.buttonSmall,
      },
      label: {
        width: 'fit-content',
      },
    },
    MuiButtonBase: {
      root: {
        ...textStyles.bodySmall,
        fontFamily: 'Beatrice-Regular',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          beatriceRegular,
          beatriceThin,
          beatriceMedium,
          cooperLtBtRegular,
        ],
      },
    },
    MuiCheckbox: {
      root: {
        color: colors.system.grey5,
        '&$checked': {
          color: colors.text.main,
        },
      },
      colorSecondary: {
        '&$checked': {
          color: colors.primary.main,
        },
      },
    },
    MuiChip: {
      root: {
        backgroundColor: colors.system.grey3,
        borderRadius: '2px',
        cursor: 'inherit',
        height: 24,
      },
      sizeSmall: {
        ...textStyles.bodyExtraSmall,
      },
      label: {
        fontWeight: 'normal',
      },
      colorSecondary: {
        backgroundColor: colors.secondary.light,
        color: colors.secondary.main,
      },
    },
    MuiDialog: {
      paper: {
        padding: '40px',
      },
      paperWidthSm: {
        maxWidth: 850,
      },
    },
    MuiDialogTitle: {
      root: {
        ...textStyles.h4,
        textAlign: 'center',
        marginBottom: 60,
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 60,
      },
    },
    MuiFormControlLabel: {
      label: {
        ...textStyles.bodySmall,
      },
    },
    MuiFormLabel: {
      root: {
        ...textStyles.bodySmall,
        color: colors.system.dark,
        fontWeight: 'bold',
      },
    },
    MuiInput: {
      root: {
        border: `1px solid ${colors.system.grey4}`,
        borderRadius: 4,
      },
      input: {
        ...textStyles.bodySmall,
        padding: '8px 10px 8px 10px',
        '&:focused': {
          outline: 'none',
        },
        minHeight: 27,
      },
      formControl: {
        'label + &': {
          marginTop: 22,
        },
      },
    },
    MuiInputLabel: {
      root: {
        ...textStyles.bodyMedium,
        fontWeight: 'bold',
        color: colors.text.main,
      },
      shrink: {
        paddingLeft: 4,
      },
    },
    MuiTableBody: {
      root: {
        '& tr:nth-child(odd)': {
          backgroundColor: colors.system.grey1,
        },
        '& tr': {
          '&:hover': {
            backgroundColor: colors.system.grey2,
          },
        },
        '& td': {
          borderRight: '1px solid #EFEEEE',
        },
      },
    },
    MuiTableHead: {
      root: {
        '& th': {
          color: colors.system.grey7,
          ...textStyles.bodySmall,
          borderRight: '1px solid #EFEEEE',
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: '16px 8px 16px 8px',
      },
      head: {
        padding: '0 20px',
      },
      body: {
        padding: '28px 20px',
      },
    },
    MuiTypography: {
      h1: textStyles.h1,
      h2: textStyles.h2,
      h3: textStyles.h3,
      h4: textStyles.h4,
      h5: textStyles.h5,
      h6: textStyles.h6,
      button: textStyles.buttonRegular,
      body1: textStyles.body,
      body2: textStyles.bodySmall,
    },
    MuiContainer: {
      maxWidthMd: {
        '@media (min-width: 1920px)': {
          maxWidth: 1000,
        },
      },
    },
  },
  props: {
    MuiDialogTitle: {
      disableTypography: true,
    },
    MuiInput: {
      disableUnderline: true,
    },
    MuiInputLabel: {
      shrink: true,
    },
  },
  colors,
  textStyles,
  fonts,
});

export { theme, colors, fonts };
