/* eslint-disable no-use-before-define */
import React, { createContext, useState } from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';

import { Snackbar } from '@material-ui/core';

import Notification from '../components/common/Notification';

let nextNotificationId = 0;

const NotificationContext = createContext({
  createNotification: () => {},
  closeNotification: () => {},
});

const NotificationStore = ({ children }) => {
  const createNotification = (severity, content, duration = 6000) => {
    nextNotificationId += 1;
    setState({
      ...state,
      notifications: {
        ...state.notifications,
        [nextNotificationId]: {
          id: nextNotificationId,
          severity,
          content,
          duration,
          createdAt: Date.now(),
        },
      },
    });
    return nextNotificationId;
  };

  const closeNotification = (notificationId, reason) => {
    if (reason === 'clickaway') {
      // Ignore clickaway to close
      return;
    }
    setState({
      ...state,
      notifications: _.pick(state.notifications, (value, key) => key !== notificationId),
    });
  };

  const [state, setState] = useState({
    notifications: {},
    contextValue: {
      createNotification,
      closeNotification,
    },
  });

  return (
    <NotificationContext.Provider value={state.contextValue}>
      {children}
      <div>
        {_.values(state.notifications).sort((a, b) => a.createdAt - b.createdAt).map((it) => (
          <Snackbar
            key={it.id}
            autoHideDuration={it.duration}
            onClose={(e, reason) => closeNotification(it.id, reason)}
            open
          >
            <Notification
              onClose={() => closeNotification(it.id)}
              severity={it.severity}
            >
              {it.content}
            </Notification>
          </Snackbar>
        ))}
      </div>
    </NotificationContext.Provider>
  );
};

NotificationStore.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationContext;

export {
  NotificationStore,
};
