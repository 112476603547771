import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import FilterBase from './FilterBase';
import LocationSelect from '../../common/LocationSelect';
import API from '../../../utils/api';

const useStyles = makeStyles(() => ({
  locationSelect: {
    width: 400,
  },
  region: {
    display: 'flex',
    flexDirection: 'column',
  },
  regionCheckboxes: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
}));

const LocationFilter = (props) => {
  const {
    className,
    filterData,
    filterKeys,
    label,
    onApply,
  } = props;

  const [value, setValue] = useState([]);
  const classes = useStyles();

  const [activeText, setActiveText] = useState('');
  const [, setLocData] = useState([]);

  const getLocationData = async (locationId) => {
    const response = await API({
      method: 'get',
      url: `/location/by-id/${locationId}/`,
    });
    if (response.status !== 200) {
      window.location.reload();
    }
    const { data: { data } } = response;
    return data;
  };

  useEffect(() => {
    const { [filterKeys[0]]: locationIds } = filterData;
    if (locationIds) {
      const promiseArray = [];
      locationIds.split(',').forEach((locId) => {
        promiseArray.push(getLocationData(locId));
      });
      Promise.all(promiseArray).then((values) => {
        const locations = [];
        const locationLabels = [];
        values.forEach((val) => {
          if (locationLabels.indexOf(val.label) === -1) {
            locations.push(val);
            locationLabels.push(val.label);
          }
        });
        setLocData(locations);
        setActiveText(locations ? locations.map((loc) => loc.label).join(',') : '');
      });
    } else {
      setLocData([]);
      setActiveText('');
    }
  }, [filterData, filterKeys]);

  const handleOnApply = () => {
    const locationIds = value ? value.map((loc) => loc.geoname_id) : null;
    onApply({
      ...filterData,
      [filterKeys[0]]: locationIds ? locationIds.join(',') : null,
    });
  };

  const handleOnClear = () => {
    setValue([]);
    setActiveText('');
    setLocData([]);
    onApply({ ...filterData, [filterKeys[0]]: [] });
  };

  return (
    <FilterBase
      className={className}
      label={label}
      activeText={activeText}
      isActive={Boolean(activeText)}
      onApply={handleOnApply}
      onClear={handleOnClear}
    >
      <LocationSelect
        className={classes.locationSelect}
        multiple={false}
        onChange={(val) => {
          setValue(val);
        }}
        value={value}
        activeLocations={value}
      />
    </FilterBase>
  );
};

LocationFilter.propTypes = {
  className: PropTypes.string,
  filterData: PropTypes.shape().isRequired,
  filterKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  onApply: PropTypes.func.isRequired,
};

LocationFilter.defaultProps = {
  className: '',
};

export default LocationFilter;
