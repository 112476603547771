import React from 'react';
import PropTypes from 'prop-types';

import {
  View,
  Text,
  Image,
  StyleSheet,
} from '@react-pdf/renderer';

import { textStyles, colors } from '../definitions';
import { PdfPage, Header } from '../components';

const metricStyles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 160,
  },
  title: {
    ...textStyles.h3,
    marginBottom: 10,
  },
});

const Metric = ({ value, label }) => (
  <View style={metricStyles.root}>
    <Text style={metricStyles.title}>{value}</Text>
    <Text style={textStyles.h5}>{label}</Text>
  </View>
);

Metric.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
};

Metric.defaultProps = {
  value: '',
  label: '',
};

const blockStyles = StyleSheet.create({
  root: {
    padding: '25px 0',
  },
  heading: {
    ...textStyles.h5,
    textDecoration: 'underline',
    marginBottom: 25,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});

const Block = ({ blockTitle, children }) => (
  <View style={blockStyles.root}>
    <Text style={blockStyles.heading}>{blockTitle}</Text>
    <View style={blockStyles.container}>{children}</View>
  </View>
);

Block.propTypes = {
  blockTitle: PropTypes.string,
  children: PropTypes.node,
};

Block.defaultProps = {
  blockTitle: '',
  children: null,
};

const styles = StyleSheet.create({
  col1: {
    flex: 1,
    backgroundColor: colors.BG_DARK,
    padding: 50,
  },
  col2: {
    flex: 1.5,
    padding: 50,
  },
  main: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  imgWrapper: {
    width: '100%',
    height: 310,
    marginBottom: 25,
    backgroundColor: colors.SECONDARY,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  heading: {
    ...textStyles.h4,
    textAlign: 'center',
  },
});

const Page5 = ({ formData }) => {
  const analyticsData = formData.instagramAnalytics;

  return (
    <PdfPage>
      <View style={styles.col1}>
        <Header align="left" variant="h5">Instagram Analytics</Header>
        <View style={styles.main}>
          <View style={styles.imgWrapper}>
            <Image src={analyticsData.analyticsImg} style={styles.image} />
          </View>
          <Text style={styles.heading}>
            {analyticsData.handle ? `@${analyticsData.handle}` : ''}
          </Text>
        </View>
        <Header align="left">{formData.personalDetails.websiteUrl}</Header>
      </View>

      <View style={styles.col2}>
        <Header align="right">
          {formData.personalDetails.firstName}
          {' '}
          {formData.personalDetails.lastName}
        </Header>
        <View style={styles.main}>
          <View>
            {(analyticsData.followerCount || analyticsData.avgPostEngagement) && (
              <Block blockTitle="Instagram Overview">
                {analyticsData.followerCount && (
                  <Metric value={analyticsData.followerCount} label="Followers" />
                )}
                {analyticsData.avgPostEngagement && (
                  <Metric value={`${analyticsData.avgPostEngagement}%`} label="Average post engagement rate" />
                )}
              </Block>
            )}
          </View>
          <View>
            {(analyticsData.audienceGender.audienceGenderPercentage || analyticsData.audienceAge) && (
              <Block blockTitle="Audience Gender &mp; Age">
                {analyticsData.audienceGender.audienceGenderPercentage && (
                  <Metric
                    value={`${analyticsData.audienceGender.audienceGenderPercentage}`}
                    label={analyticsData.audienceGender.gender}
                  />
                )}
                {analyticsData.audienceAge && (
                  <Metric value={analyticsData.audienceAge} label="years old" />
                )}
              </Block>
            )}
          </View>
          <View>
            {analyticsData.audienceLocations.filter((item) => item.locationPercentage).length > 0 && (
              <Block blockTitle="Audience Locations">
                {analyticsData.audienceLocations.map((loc) => (loc.locationPercentage ? (
                  <Metric value={`${loc.locationPercentage}%`} label={loc.location} key={loc.id} />
                ) : <View key={loc.id} />))}
              </Block>
            )}
          </View>
        </View>
        <Header align="right">
          {formData.personalDetails.instagramHandle ? `@${formData.personalDetails.instagramHandle}` : ''}
        </Header>
      </View>
    </PdfPage>
  );
};

Page5.propTypes = {
  formData: PropTypes.shape().isRequired,
};

export default Page5;
