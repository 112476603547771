import React from 'react';

import PropTypes from 'prop-types';

import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import NetworkIcon from './icons/NetworkIcon';

const networkStyles = (theme) => ({
  merge: {
    root: {
      color: theme.palette.text.primary,
      background: theme.colors.system.grey2,
      fontWeight: 'bold',
      border: `1px solid ${theme.colors.primary.main}`,
    },
    icon: {
      color: theme.colors.system.dark,
      width: 15,
      height: 15,
    },
  },
  instagram: {
    root: {
      color: theme.colors.system.light,
      background: 'linear-gradient(90deg, #8A3AB9 0%, #BC2A8D 52.08%, #FBAD50 100%)',
      fontWeight: 'bold',
    },
    icon: {
      color: theme.colors.system.light,
      width: 15,
      height: 15,
    },
  },
  twitter: {
    root: {
      color: theme.colors.system.light,
      background: '#1DA1F2',
      fontWeight: 'bold',
    },
    icon: {
      color: theme.colors.system.light,
      width: 15,
      height: 15,
    },
  },
  facebook: {
    root: {
      color: theme.colors.system.light,
      background: '#4267B2',
      fontWeight: 'bold',
    },
    icon: {
      color: theme.colors.system.light,
      width: 15,
      height: 15,
    },
  },
  youtube: {
    root: {
      color: theme.colors.system.light,
      background: '#C4302B',
      fontWeight: 'bold',
    },
    icon: {
      color: theme.colors.system.light,
      width: 15,
      height: 15,
    },
  },
  tiktok: {
    root: {
      color: theme.colors.system.light,
      background: '#010101',
      fontWeight: 'bold',
      border: '1px solid',
      borderImageSource: 'linear-gradient(45deg, #69C9D0, #EE1D52)',
      borderImageSlice: 1,
    },
    icon: {
      color: theme.colors.system.light,
      width: 15,
      height: 15,
    },
  },
});

const NetworkChip = ({ network, label }) => {
  const classes = makeStyles((theme) => networkStyles(theme)[network])();

  return (
    <Chip
      classes={classes}
      icon={<NetworkIcon network={network} />}
      label={label}
    />
  );
};

NetworkChip.propTypes = {
  network: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default NetworkChip;
