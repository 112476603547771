// Main Dashboard Notifications list
import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import { useApi, useAuth, useRoute } from "../../../hooks";

const useStyles = makeStyles((theme) => ({
  mainCont: {
    border: `1.5px solid ${theme.colors.system.grey2}`,
    borderRadius: '4px',
    // height: 'fit-content',
  },
  mainHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
  },
  todayPast: {
    padding: '1rem',
  },
  notifItem: {
    height: '100px',
    backgroundColor: theme.colors.system.grey1,
    borderTop: `1px solid ${theme.colors.system.grey2}`,
    borderBottom: `1px solid ${theme.colors.system.grey2}`,
    display: 'flex',

  },
  viewMoreBtn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: '50px',
  },
  notifImg: {
    margin: '1.25rem 0.5rem 0 1.25rem',
    height: '25px',
    borderRadius: '50%',
  },
  activity: {
    fontSize: '12px',
    padding: '0.5rem 4rem 0.5rem 0',
  },
  noNotifs: {
    color: theme.colors.system.grey6,
    margin: '20px 20px',
  },
}));

// const TEMP_NOTIF = {
//   user: 'Cynthia Lopesz',
//   activity: 'marked your post as',
//   keyword: 'Reword',
//   brand: 'AmazonAu/March2021',
//   date: '21 March 2021 - 22:50',
// };

// const TODAY_NOTIFS = [TEMP_NOTIF, TEMP_NOTIF, TEMP_NOTIF];
// const PAST_NOTIFS = [TEMP_NOTIF, TEMP_NOTIF, TEMP_NOTIF];
const TODAY_NOTIFS = [];
const PAST_NOTIFS = [];

const NotificationItem = ({ notif, img }) => {
  const {
    user, activity, keyword, brand, date,
  } = notif;
  const classes = useStyles();

  return (
    <div className={classes.notifItem}>
      <img className={classes.notifImg} src={img} alt="notif" />
      <div className={classes.activity}>
        <p>{`${user} ${activity} ${keyword} within ${brand}`}</p>
        <p>
          {date}
        </p>
      </div>
    </div>
  );
};

NotificationItem.propTypes = {
  notif: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
};

const Notifications = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.mainCont}>
        <div className={classes.mainHeader}>
          <Typography variant="h6">
            Notifications
          </Typography>
        </div>
        <div className={classes.notifsCont}>
          {TODAY_NOTIFS.length < 1 && PAST_NOTIFS < 1
            && <Typography className={classes.noNotifs}>There are no new notifictions to show.</Typography>}
          {(TODAY_NOTIFS > 0) && (
            <>
              <div className={classes.todayPast}>
                <Typography style={{ fontSize: '10px' }}>
                  Today
                </Typography>
              </div>
              {TODAY_NOTIFS.map((notif) => (
                <NotificationItem
                  img="https://placekitten.com/50/50"
                  notif={notif}
                />
              ))}
            </>
          )}
          {(PAST_NOTIFS > 0) && (
            <>
              <div className={classes.todayPast}>
                <Typography style={{ fontSize: '10px' }}>
                  Past Notifications
                </Typography>
              </div>
              {PAST_NOTIFS.map((notif) => (
                <NotificationItem
                  img="https://placekitten.com/50/50"
                  notif={notif}
                />
              ))}
            </>
          )}
        </div>
        {false && (
          <Button
            color="primary"
                  // onClick={() => setDialogIsOpen(true)}
            size="small"
            variant="text"
            className={classes.viewMoreBtn}
          >
            View More
          </Button>
        )}
      </div>
    </>
  );
};

export default Notifications;
