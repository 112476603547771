import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FilterBase from './FilterBase';
import Select from '../../common/Select';

const sortOptions = [
  {
    key: 'social_reach',
    label: 'Social reach',
  },
];

const useStyles = makeStyles((theme) => ({
  inputs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  input: {
    minWidth: 100,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const SortFilter = (props) => {
  const { className, filterData, onApply } = props;
  const [sortKey, setSortKey] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [activeText, setActiveText] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    const { sort } = filterData;
    if (!sort) {
      setActiveText(null);
      return;
    }
    const [key, direction] = sort.split(':', 2);
    setSortKey(key);
    setSortDirection(direction);

    const sortOption = sortOptions.find((it) => it.key === key);
    if (sortOption) {
      setActiveText(`${sortOption.label} (${direction})`);
    } else {
      setActiveText(null);
    }
  }, [filterData]);

  const handleOnApply = () => {
    onApply({
      ...filterData,
      sort: sortKey !== null && sortDirection !== null ? `${sortKey}:${sortDirection}` : null,
    });
  };

  const handleOnClear = () => {
    onApply({ ...filterData, sort: null });
  };

  return (
    <FilterBase
      className={className}
      label="Sort by"
      activeText={activeText}
      isActive={Boolean(activeText)}
      onApply={handleOnApply}
      onClear={handleOnClear}
    >
      <div className={classes.inputs}>
        <Select
          className={classes.input}
          label="Sort by"
          onChange={(event) => setSortKey(event.target.value)}
          value={sortKey}
        >
          {sortOptions.map((it) => (
            <MenuItem key={it.key} value={it.key}>{it.label}</MenuItem>
          ))}
        </Select>
        <Select
          className={classes.input}
          label="Direction"
          onChange={(event) => setSortDirection(event.target.value)}
          value={sortDirection}
        >
          <MenuItem value="desc">Desc</MenuItem>
          <MenuItem value="asc">Asc</MenuItem>
        </Select>
      </div>
    </FilterBase>
  );
};

SortFilter.propTypes = {
  className: PropTypes.string,
  filterData: PropTypes.shape().isRequired,
  onApply: PropTypes.func.isRequired,
};

SortFilter.defaultProps = {
  className: '',
};

export default SortFilter;
