import React from 'react';

import PropTypes from 'prop-types';

import { Avatar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import NetworkIcon from '../common/icons/NetworkIcon';
import { formatNumber } from '../../utils/number';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
  },
  container: {
    display: 'grid',
    gridTemplateRows: '50% 50%',
    gridTemplateColumns: 'auto 1fr',
    gridTemplateAreas: `
      "avatar name"
      "avatar social"
    `,
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  avatarContainer: {
    gridArea: 'avatar',
  },
  avatar: {
    width: 50,
    height: 50,
  },
  nameContainer: {
    gridArea: 'name',
    paddingLeft: '5px',
    overflow: 'hidden',
  },
  socialContainer: {
    gridArea: 'social',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    paddingLeft: '5px',
  },
  networkIcon: {
    width: 14,
    height: 14,
  },
}));

const CompactProfileList = ({ profiles }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {profiles.map((profile) => (
        <div className={classes.container} key={profile.id()}>
          <div className={classes.avatarContainer}>
            <Avatar
              className={classes.avatar}
              alt={profile.fullName()}
              src={profile.profilePicture()}
            />
          </div>
          <div className={classes.nameContainer}>
            <Typography
              noWrap
              variant="body1"
            >
              {profile.fullName() || profile.handle()}
            </Typography>
          </div>
          <div className={classes.socialContainer}>
            {profile.data.network_profiles.map((networkProfile) => (
              <NetworkIcon
                className={classes.networkIcon}
                key={networkProfile.network}
                network={networkProfile.network}
              />
            ))}
            <Typography
              variant="body2"
              noWrap
              style={{ margin: 5 }}
            >
              {formatNumber(profile.socialReach())}
            </Typography>
          </div>
        </div>
      ))}
    </div>
  );
};

CompactProfileList.propTypes = {
  profiles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default CompactProfileList;
