import React, { useEffect, useState } from 'react';
import { Link } from '@reach/router';

import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Typography,
  ButtonBase,
  Menu,
  MenuItem,
  Dialog,
  IconButton,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import {
  useApi, useAuth, useRoute, useNotification,
} from '../../../hooks';
import API from '../../../utils/api';

import MediaKitImage from '../../../assets/images/media-kit/media-kit.png';
import Page1Preview from '../../media-kit/previews/Page1Preview';
import PageBase from '../PageBase';
import DisablePage from '../../common/DisablePage';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    padding: '50px 70px',
  },
  container: {
    height: '100%',
    backgroundColor: theme.colors.system.grey1,
    border: `1px solid ${theme.colors.system.grey3}`,
    borderRadius: 4,
    padding: 40,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '&:first-child': {
      borderRight: `1px solid ${theme.colors.system.grey3}`,
    },
    '& *': {
      textAlign: 'center',
    },
  },
  text: {
    marginTop: 20,
    marginBottom: 20,
    width: 330,
    color: theme.colors.system.grey7,
  },
  btn: {
    minWidth: 250,
    minHeight: 40,
  },
  mediaKitWrapper: {
    width: '32%',
    '@media (max-width: 1280px)': {
      width: '42%',
    },
    marginBottom: 20,
    marginRight: 10,
  },
  mediaKitCont: {
    width: '100%',
    padding: 20,
    backgroundColor: theme.colors.system.grey1,
    border: `1px solid ${theme.colors.system.grey3}`,
  },
  cardTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  dotsIcon: {
    color: theme.colors.system.grey7,
  },
  subHeading: {
    color: theme.colors.system.grey7,
    paddingTop: 10,
    paddingBottom: 20,
  },
  cont: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '70vh',
    overflowY: 'auto',
  },
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
}));

const MediaKitActions = ({
  mkId,
  handleDeleteMediaKit,
  setIsFetching,
}) => {
  const classes = useStyles();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const auth = useAuth();
  const { createNotification } = useNotification();
  const [, setRoute] = useRoute();

  const handleMenuClose = (event) => {
    event.preventDefault();
    setAnchorEl(null);
  };

  /* const handleMenuItemClick = (event, handler) => {
    handleMenuClose(event);
    handler();
  }; */

  const deleteMediaKit = async () => {
    setShowDeleteDialog(false);
    setIsFetching(true);

    try {
      const response = await API({
        method: 'delete',
        url: `/account/${auth.account.id}/media-kit/${mkId}/`,
      });

      if (response && response.status >= 200 && response.status < 300) {
        handleDeleteMediaKit();
        createNotification(
          'success',
          'Media Kit Deleted Successfully',
        );
      }
    } catch (err) {
      createNotification(
        'error',
        'An unexpected error occured. Could not delete media kit',
      );
    }

    setIsFetching(false);
  };

  return (
    <>
      <ButtonBase
        onClick={(e) => setAnchorEl(e.target)}
      >
        <MoreVertIcon style={{ color: '#2B2E34' }} />
      </ButtonBase>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          className={`${classes.menuItem} ${classes.deleteItem}`}
          onClick={() => setRoute({ pathname: `/media-kit/design/edit/${mkId}` })}
        >
          View Media Kit
        </MenuItem>
        <MenuItem
          className={`${classes.menuItem} ${classes.deleteItem}`}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setShowDeleteDialog(true);
          }}
        >
          Delete Media Kit
        </MenuItem>
      </Menu>

      <Dialog
        open={showDeleteDialog}
        onBackdropClick={() => setShowDeleteDialog(false)}
      >
        <IconButton aria-label="close" className={classes.closeButton} onClick={() => setShowDeleteDialog(false)}>
          <CloseIcon />
        </IconButton>

        <DialogTitle style={{ marginBottom: 0 }}>
          <Typography
            variant="h5"
            component="h5"
            style={{ marginBottom: 10 }}
          >
            Delete Media Kit
          </Typography>
          <Typography
            variant="body1"
            component="p"
            className={classes.text}
          >
            Are you sure you want to delete this media kit?
          </Typography>
        </DialogTitle>

        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setAnchorEl(null);
              deleteMediaKit();
            }}
            variant="contained"
            style={{ minWidth: 250, minHeight: 40 }}
            size="small"
          >
            Delete Media Kit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

MediaKitActions.propTypes = {
  mkId: PropTypes.string.isRequired,
  handleDeleteMediaKit: PropTypes.func.isRequired,
  setIsFetching: PropTypes.func.isRequired,
};

const MediaKit = (props) => {
  const { mediaKit, handleDeleteMediaKit, setIsFetching } = props;
  const classes = useStyles();
  const [, setRoute] = useRoute();

  const getFormattedDate = () => {
    const dateTemp = new Date(mediaKit.modified).toLocaleString();
    return dateTemp.replace(',', ' -');
  };

  const getPreviewData = () => {
    const dataTemp = { ...mediaKit.personal_details };
    dataTemp.mainImg = dataTemp.main_page_image;
    dataTemp.firstName = dataTemp.first_name;
    dataTemp.lastName = dataTemp.last_name;
    dataTemp.professionalTitle = dataTemp.professional_title;
    dataTemp.aboutImg = dataTemp.about_page_image;
    dataTemp.about = dataTemp.about_me;
    dataTemp.contactImg = dataTemp.contact_page_image;
    dataTemp.instagramHandle = dataTemp.instagram_handle;
    dataTemp.websiteUrl = dataTemp.website_url;
    // dataTemp.email = dataTemp.email;
    dataTemp.mobile = dataTemp.mobile_number;
    dataTemp.shippingAddress = dataTemp.shipping_address;
    dataTemp.availableFor = dataTemp.available_for;

    mediaKit.personalDetails = dataTemp;

    return mediaKit;
  };

  return (
    <>
      <div className={classes.mediaKitCont}>
        <div className={classes.cardTitle}>
          <Typography
            color="primary"
            variant="body1"
            component="span"
            onClick={() => setRoute({ pathname: `/media-kit/design/edit/${mediaKit.id}` })}
            style={{ cursor: 'pointer' }}
          >
            Media Kit -
            {getFormattedDate()}
          </Typography>
          <MediaKitActions
            mkId={mediaKit.id}
            handleDeleteMediaKit={handleDeleteMediaKit}
            setIsFetching={setIsFetching}
          />
        </div>
        <div className={classes.preview}>
          <Page1Preview finalData={getPreviewData()} />
        </div>
      </div>
    </>
  );
};

MediaKit.propTypes = {
  mediaKit: PropTypes.shape().isRequired,
  handleDeleteMediaKit: PropTypes.func.isRequired,
  setIsFetching: PropTypes.func.isRequired,
};

const StartCreatingNow = () => {
  const classes = useStyles();
  const auth = useAuth();
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.col}>
          <Typography variant="h5" component="h5">
            Media Kit Creator
          </Typography>
          <Typography variant="body1" component="p" className={classes.text}>
            A media kit is an essential tool to getting more paid gigs as an
            Influencer. The Scrunch Media Kit Generator makes it quick and easy
            to look like a pro!
          </Typography>
          {auth.hasPermission('media_kit.create') ? (
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.btn}
              component={Link}
              to="/media-kit/design/create/new"
            >
              Start Creating Now
            </Button>
          ) : (
            <Button
              color="primary"
              component={Link}
              startIcon={<LockOpenIcon />}
              to="/settings/plans"
              variant="contained"
              className={classes.btn}
            >
              Upgrade Plan
            </Button>
          )}
        </div>
        <div className={classes.col}>
          <img
            src={MediaKitImage}
            alt="Start Creating Media Kit"
            height="400"
          />
        </div>
      </div>
    </div>
  );
};

const MediaKitPage = () => {
  const classes = useStyles();

  const auth = useAuth();
  const [{ response }] = useApi({
    url: `/account/${auth.account.id}/media-kit/`,
    method: 'get',
  });
  const [mediaKits, setMediaKits] = useState([]);
  const [showPage, setShowPage] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (response && response.status === 200) {
      setMediaKits(response.data.data.media_kits);
      setShowPage(true);
    }
  }, [response]);

  if (!showPage) {
    return null;
  }

  const handleDeleteMediaKit = async () => {
    const res = await API({
      method: 'get',
      url: `/account/${auth.account.id}/media-kit/`,
    });

    if (res && res.status >= 200 && res.status < 300) {
      setMediaKits(res.data.data.media_kits);
    }
  };

  return (
    <>
      {!auth.hasPermission('media_kit.create') && <DisablePage />}
      <PageBase showLoadingBar={isFetching}>
        <div style={{ width: '100%', padding: 40 }}>
          {mediaKits && mediaKits.length >= 1 ? (
            <>
              <div style={{ width: '100%' }}>
                <Typography variant="h5" component="h5">
                  Media Kit Creator
                </Typography>
                <div className={classes.headerRow}>
                  <Typography
                    variant="body1"
                    component="p"
                    className={classes.subHeading}
                  >
                    A media kit is an essential tool to getting more paid gigs
                    as an Influencer. The Scrunch Media Kit Generator makes it
                    quick and easy to look like a pro!
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.btn}
                    component={Link}
                    to="/media-kit/design/create/new"
                    style={{ marginBottom: 20 }}
                    startIcon={<AddIcon />}
                  >
                    Create New Media Kit
                  </Button>
                </div>
              </div>
              <div className={classes.cont}>
                {mediaKits.map((mk, index) => (
                  <div className={classes.mediaKitWrapper} key={mk.id}>
                    <MediaKit
                      mediaKit={mk}
                      index={index}
                      handleDeleteMediaKit={handleDeleteMediaKit}
                      setIsFetching={setIsFetching}
                    />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <StartCreatingNow />
          )}
        </div>
      </PageBase>
    </>
  );
};

export default MediaKitPage;
