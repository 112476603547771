import React, { useState } from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';
import { TwitterTweetEmbed } from 'react-twitter-embed';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import PageBase from '../PageBase';
import ScrunchLogoFull from '../../../assets/images/scrunch-logo-full.svg';
import Image1 from '../../../assets/images/login/1.jpg';
import Image2 from '../../../assets/images/login/2.jpg';
import Image3 from '../../../assets/images/login/3.jpg';
import Image4 from '../../../assets/images/login/4.jpg';
import Image5 from '../../../assets/images/login/5.jpg';

const CONTENT = [
  {
    tweetId: '1109169188604649474',
    image: Image1,
  },
  {
    tweetId: '1216618589551091712',
    image: Image2,
  },
  {
    tweetId: '884272663195131904',
    image: Image3,
  },
  {
    tweetId: '1119047403913351168',
    image: Image4,
  },
  {
    tweetId: '1118243734716657667',
    image: Image5,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'flex-end',
    backgroundColor: theme.colors.background.primary,
  },
  leftPanel: {
    width: '100%',
    maxWidth: 400,
    maxHeight: '100%',
    padding: 50,
    overflowY: 'auto',
  },
  rightPanel: {
    height: '100%',
    flex: 1,
    padding: 50,
    '@media only screen and (max-width: 600px)': {
      display: 'none',
    },
  },
  rightPanelImage: {
    height: '100%',
    backgroundImage: (props) => `url('${props.image}')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: 10,
  },
  headerLogo: {
    height: 20,
    marginBottom: 20,
  },
  headerTitle: {
    ...theme.fonts.tertiary,
    fontSize: 30,
    marginBottom: 10,
  },
  tweet: {
    position: 'absolute',
    top: 90,
    left: 350,
    width: 400,
    '@media only screen and (max-width: 600px)': {
      display: 'none',
    },
  },
  children: {
    marginTop: 50,
  },
}));

const AuthPageBase = (props) => {
  const {
    children,
    headerTitle,
    headerSubtitle,
    headerSubtitleTwo,
    ...pageProps
  } = props;
  const [content] = useState(_.sample(CONTENT));
  const classes = useStyles({ ...content });

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <PageBase {...pageProps}>
      <div className={classes.root}>
        <div className={classes.leftPanel}>
          <img className={classes.headerLogo} alt="Scrunch" src={ScrunchLogoFull} />
          <Typography className={classes.headerTitle} variant="h4">{headerTitle}</Typography>
          {headerSubtitle && (
            <Typography className={classes.headerSubtitle} variant="body2">{headerSubtitle}</Typography>
          )}
          {headerSubtitleTwo && (
            <>
              <Typography className={classes.headerSubtitle} variant="body2">{headerSubtitleTwo}</Typography>
            </>
          )}
          <div className={classes.children}>
            {children}
          </div>
        </div>
        <div className={classes.rightPanel}>
          <div className={classes.rightPanelImage} />
        </div>
        {content.tweetId && (
          <div className={classes.tweet}>
            <TwitterTweetEmbed tweetId={content.tweetId} />
          </div>
        )}
      </div>
    </PageBase>
  );
};

AuthPageBase.propTypes = {
  children: PropTypes.node.isRequired,
  headerTitle: PropTypes.string.isRequired,
  headerSubtitle: PropTypes.string,
  headerSubtitleTwo: PropTypes.string,
};

AuthPageBase.defaultProps = {
  headerSubtitle: null,
  headerSubtitleTwo: null,
};

export default AuthPageBase;
