import React from 'react';
import PropTypes from 'prop-types';

import SparkAndSuModules from '../../scrunch-university/SparkAndSuModules';

const SparkModulePage = ({ moduleId, lessonId }) => (
  <SparkAndSuModules
    path="spark-ffp"
    title="Spark Female Founders Program"
    moduleId={moduleId}
    lessonId={lessonId}
    isSpark
  />

);

SparkModulePage.propTypes = {
  moduleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lessonId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SparkModulePage.defaultProps = {
  moduleId: null,
  lessonId: null,
};

export default SparkModulePage;
