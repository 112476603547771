import React, { useEffect, useState } from 'react';

import _ from 'lodash';

import PropTypes from 'prop-types';

import { MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FilterBase from './FilterBase';
import Select from '../../common/Select';
import { formatPercentage } from '../../../utils/number';
import { decodeRange, encodeRange } from '../../../utils/string';

const ages = ['18-24', '25-34', '35-44', '45-54', '55-64', '65+'];

const probabilities = [0, 0.1, 0.25, 0.5, 0.75];

const useStyles = makeStyles((theme) => ({
  inputs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  input: {
    width: 150,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  text: {
    width: 100,
    color: theme.palette.grey[600],
    textAlign: 'center',
  },
}));

const AudienceAgeFilter = (props) => {
  const {
    className,
    filterData,
    label,
    onApply,
  } = props;
  const [activeText, setActiveText] = useState(null);
  const [ageRange, setAgeRange] = useState('');
  const [probability, setProbability] = useState('');
  const classes = useStyles();

  useEffect(() => {
    const { audience_age: audienceAge } = filterData;
    if (!_.isEmpty(audienceAge)) {
      setActiveText(
        Object.entries(audienceAge)
          .map((it) => `${it[0]} >= ${formatPercentage(it[1])}`)
          .join(', '),
      );
      setAgeRange(_.keys(audienceAge)[0]);
      const [min] = decodeRange(audienceAge[_.keys(audienceAge)[0]]);
      setProbability(min);
    } else {
      setActiveText(null);
      setAgeRange(null);
      setProbability(null);
    }
  }, [filterData]);

  const handleOnApply = () => {
    if (ageRange) {
      onApply({
        ...filterData,
        audience_age: {
          [ageRange]: encodeRange(probability, null),
        },
      });
    } else {
      onApply({ ...filterData, audience_age: null });
    }
  };

  const handleOnClear = () => {
    onApply({ ...filterData, audience_age: null });
  };

  return (
    <FilterBase
      className={className}
      label={label}
      activeText={activeText}
      isActive={!_.isEmpty(activeText)}
      onApply={handleOnApply}
      onClear={handleOnClear}
    >
      <div className={classes.inputs}>
        <Select
          className={classes.input}
          label="Age range"
          value={ageRange}
          onChange={(e) => setAgeRange(e.target.value !== '' ? e.target.value : null)}
        >
          {ages.map((it) => (
            <MenuItem key={it} value={it}>
              {it}
            </MenuItem>
          ))}
        </Select>
        <Typography className={classes.text} variant="body2">
          greater than or equal to
        </Typography>
        <Select
          className={classes.input}
          type="number"
          label="Percentage"
          value={probability}
          onChange={(e) => setProbability(e.target.value !== '' ? e.target.value : null)}
        >
          {probabilities.map((it) => (
            <MenuItem key={it} value={it}>
              {formatPercentage(it)}
            </MenuItem>
          ))}
        </Select>
      </div>
    </FilterBase>
  );
};

AudienceAgeFilter.propTypes = {
  className: PropTypes.string,
  filterData: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  onApply: PropTypes.func.isRequired,
};

AudienceAgeFilter.defaultProps = {
  className: '',
};

export default AudienceAgeFilter;
