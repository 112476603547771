import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Link } from '@reach/router';

import MenuPageBase, { Menu, MenuItem } from '../MenuPageBase';
import SparkAndSuContent from '../../scrunch-university/SparkAndSuContent';
import { useApi, useAuth, useRoute } from '../../../hooks';
import UnlockScrunchUniversityDialog from '../../scrunch-university/UnlockScrunchUniversityDialog';

const useStyles = makeStyles((theme) => ({
  ctaRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
  },
  ctaText: {
    maxWidth: 300,
    padding: theme.spacing(4),
    '&>*': {
      margin: theme.spacing(1),
    },
  },
  ctaVideo: {
    maxWidth: 600,
    width: '100%',
    '&>iframe': {
      width: '100%',
      height: 420,
    },
  },
}));

const ScrunchUniversityUnlockCTA = (props) => {
  const { title, youtubeVideoId } = props;
  const classes = useStyles();
  return (
    <div className={classes.ctaRoot}>
      <div className={classes.ctaText}>
        <Typography variant="h5">Welcome!</Typography>
        <Typography color="textSecondary" variant="body2">
          Get access to powerful audience insights, hours of educational content, templates,
          {' '}
          cheatsheets and a library of Lightroom presets!
        </Typography>
        <Button
          color="primary"
          component={Link}
          startIcon={<LockOpenIcon />}
          to="/settings/plans"
          variant="contained"
        >
          Upgrade Plan
        </Button>
      </div>
      <div className={classes.ctaVideo}>
        <iframe
          title={title}
          src={`https://www.youtube.com/embed/${youtubeVideoId}`}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          frameBorder="0"
        />
      </div>
    </div>
  );
};

ScrunchUniversityUnlockCTA.propTypes = {
  title: PropTypes.string.isRequired,
  youtubeVideoId: PropTypes.string.isRequired,
};

const ScrunchUniversityPage = () => {
  const auth = useAuth();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [route, setRoute] = useRoute();
  const [{ data, isFetching }] = useApi({
    method: 'get',
    url: '/scrunch-university/',
  });

  const handleLockedMenuItemClick = () => {
    setIsDialogOpen(true);
  };

  if (!auth.hasPermission('scrunch_university.view')) {
    // Show the plan upgrade CTA
    return (
      <MenuPageBase
        menu={(
          <Menu>
            {data && data.children.map((it) => (
              <MenuItem
                Icon={LockIcon}
                key={it.title}
                label={it.title}
                onClick={handleLockedMenuItemClick}
              />
            ))}
          </Menu>
        )}
        showLoadingBar={isFetching}
        title={data ? data.title : ''}
        lock={{
          title: 'Scrunch University',
          description: 'Upgrade now and get access to hours of educational content',
        }}
      >
        {data && (
          <ScrunchUniversityUnlockCTA title="Welcome!" youtubeVideoId={data.youtube_video_id} />
        )}
        <UnlockScrunchUniversityDialog
          isDialogOpen={isDialogOpen}
          closeDialog={() => setIsDialogOpen(false)}
          content={data}
        />
      </MenuPageBase>
    );
  }

  return (
    <MenuPageBase
      menu={(
        <Menu>
          {data && data.children.map((it, index) => (
            <MenuItem
              // Icon={UniversityIcon}
              Numbered={index + 1}
              key={it.title}
              label={it.title}
              onClick={() => setRoute({ pathname: `/scrunch-university/module/${index + 1}` })}
              selected={route.pathname === `/scrunch-university/module/${index + 1}`}
            />
          ))}
        </Menu>
      )}
      showLoadingBar={isFetching}
      title={data ? data.title : ''}
    >
      {data && (
        <SparkAndSuContent content={data} />
      )}
    </MenuPageBase>
  );
};

export default ScrunchUniversityPage;
export { useStyles, ScrunchUniversityUnlockCTA };
