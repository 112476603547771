import React from 'react';
import PropTypes from 'prop-types';

import {
  Page,
  View,
  Text,
  StyleSheet,
} from '@react-pdf/renderer';

import { textStyles, colors } from './definitions';

/* -------------------- GENERAL PAGE -------------------- */
const pageStyles = StyleSheet.create({
  root: {
    backgroundColor: colors.BG_LIGHT,
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
  },
});

const PdfPage = ({ children, style }) => (
  <Page size={[768, 1024]} orientation="landscape" style={{ ...pageStyles.root, ...style }}>
    {children}
  </Page>
);

PdfPage.propTypes = {
  style: PropTypes.shape(),
  children: PropTypes.node,
};

PdfPage.defaultProps = {
  style: {},
  children: null,
};

/* -------------------- HEADER/FOOTER -------------------- */
const headerStyles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  right: {
    justifyContent: 'flex-end',
  },
  left: {
    justifyContent: 'flex-start',
  },
});

const Header = ({ children, align, variant }) => {
  let alignStyle = headerStyles.right;

  if (align && headerStyles[align]) {
    alignStyle = headerStyles[align];
  }

  return (
    <View style={{ ...headerStyles.root, ...alignStyle }}>
      <Text style={textStyles[variant]}>{children}</Text>
    </View>
  );
};

Header.propTypes = {
  align: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node,
};

Header.defaultProps = {
  align: 'right',
  variant: 'body',
  children: null,
};

/* -------------------- ABSOLUTE INFO CARD -------------------- */
const infoCardStyles = StyleSheet.create({
  root: {
    backgroundColor: colors.SECONDARY,
    color: colors.BG_LIGHT,
    height: '90%',
    width: '130%',
    position: 'absolute',
    top: '5%',
    right: 0,
    padding: 50,
  },
});

const InfoCard = ({ children, style }) => (
  <View style={{ ...infoCardStyles.root, ...style }}>
    {children}
  </View>
);

InfoCard.propTypes = {
  style: PropTypes.shape(),
  children: PropTypes.node,
};

InfoCard.defaultProps = {
  style: {},
  children: null,
};

export {
  PdfPage,
  Header,
  InfoCard,
};
