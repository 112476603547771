import React, { useState } from 'react';

import PropTypes from 'prop-types';

import {
  Button,
  ButtonBase,
  IconButton,
  Popover,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CaretDownIcon from '../../common/icons/CaretDown';
import CloseIcon from '../../common/icons/Close';

const useStyles = makeStyles((theme) => ({
  filter: {
    cursor: 'pointer',
    border: (props) => (props.isActive ? `1px solid ${theme.colors.primary.main}` : `1px solid ${theme.colors.system.grey2}`),
    borderRadius: 2,

    '&:hover': {
      backgroundColor: theme.colors.system.grey2,
    },
  },
  filterButton: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '5px 10px 5px 10px',
  },
  label: {
    whiteSpace: 'nowrap',
  },
  caretDownIcon: {
    width: 10,
    height: 10,
    marginLeft: 10,
  },
  content: {
    padding: theme.spacing(2),
  },
  header: {
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  spacer: {
    flex: 1,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
  },
  applyButton: {
    marginLeft: theme.spacing(2),
    fontWeight: 'bold',
  },
}));

const FilterBase = (props) => {
  const {
    activeText,
    children,
    className,
    isActive,
    label,
    onApply,
    onClear,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles(props);

  const handleFilterButtonClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleFilterButtonKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleFilterButtonClick(event);
    }
  };

  const handlePopoverClose = (event) => {
    event.preventDefault();
    setAnchorEl(null);
  };

  const handleApplyButtonClick = (event) => {
    event.preventDefault();
    onApply();
    handlePopoverClose(event);
  };

  const handleClearButtonClick = (event) => {
    event.preventDefault();
    onClear();
    handlePopoverClose(event);
  };

  return (
    <div className={`${classes.filter} ${className}`}>
      <ButtonBase
        className={classes.filterButton}
        onClick={handleFilterButtonClick}
        onKeyDown={handleFilterButtonKeyDown}
      >
        <Typography
          className={classes.label}
          variant="body2"
        >
          {label}
          {activeText && `: ${activeText}`}
        </Typography>
        <CaretDownIcon className={classes.caretDownIcon} />
      </ButtonBase>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={classes.content}>
          <div className={classes.header}>
            <Typography variant="body1" gutterBottom>
              {label}
            </Typography>
            <div className={classes.spacer} />
            <Tooltip title="Close">
              <IconButton size="small" onClick={handlePopoverClose}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div>{children}</div>
          <div className={classes.footer}>
            {isActive && (
              <Button
                color="secondary"
                onClick={handleClearButtonClick}
                variant="outlined"
              >
                Clear
              </Button>
            )}
            <Button
              className={classes.applyButton}
              color="primary"
              onClick={handleApplyButtonClick}
              variant="contained"
            >
              Apply
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
};

FilterBase.propTypes = {
  activeText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  isActive: PropTypes.bool,
  label: PropTypes.string,
  onApply: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

FilterBase.defaultProps = {
  activeText: null,
  className: '',
  isActive: false,
  label: null,
};

export default FilterBase;
