import React from 'react';
import PropTypes from 'prop-types';

import {
  View,
  Text,
  Image,
  StyleSheet,
} from '@react-pdf/renderer';

import { textStyles, colors } from '../definitions';
import { PdfPage, Header } from '../components';

import ScrunchStamp from '../../../assets/images/media-kit/scrunch-stamp.png';

const styles = StyleSheet.create({
  root: {
    backgroundColor: colors.PRIMARY,
    color: colors.BG_LIGHT,
    padding: 50,
    flexDirection: 'column',
    position: 'relative',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  main: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    ...textStyles.h1,
    fontSize: 72,
    marginBottom: 20,
    textAlign: 'center',
  },
  text: {
    ...textStyles.h5,
    textAlign: 'center',
  },
  stamp: {
    position: 'absolute',
    top: 10,
    left: 10,
    width: 220,
  },
});

const Page9 = ({ formData }) => (
  <PdfPage style={styles.root}>
    <Image style={styles.stamp} src={ScrunchStamp} />
    <Header align="right">
      {formData.personalDetails.firstName}
      {' '}
      {formData.personalDetails.lastName}
    </Header>
    <View style={styles.main}>
      <Text style={styles.title}>Let’s Work Together</Text>
      <Text style={styles.text}>
        {formData.personalDetails.email}
      </Text>
    </View>
    <View style={styles.header}>
      <Text style={textStyles.body}>{formData.personalDetails.websiteUrl}</Text>
      <Text style={textStyles.body}>
        {formData.personalDetails.instagramHandle ? `@${formData.personalDetails.instagramHandle}` : ''}
      </Text>
    </View>
  </PdfPage>
);

Page9.propTypes = {
  formData: PropTypes.shape().isRequired,
};

export default Page9;
