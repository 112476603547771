import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  makeStyles,
  Typography,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  Paper,
  Avatar,
  InputAdornment,
  FormHelperText,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import MUIRichTextEditor from 'mui-rte';
import { convertToRaw } from 'draft-js';
import { DropzoneArea } from 'material-ui-dropzone';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import SlidingPaneBase from '../account/SlidingPaneBase';
import AccessInfoTooltip from '../common/AccessInfoTooltip';
import { useContentManager, useAuth, useNotification } from '../../hooks';
import { joinDateToTime } from '../../utils/datetime';
import API from '../../utils/api';
import topics from '../search/filters/topics';

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.textStyles.h5,
    marginBottom: theme.spacing(2),
  },
  form: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.spacing(3),
    paddingBottom: theme.spacing(6),
  },
  input: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  select: {
    height: '58px',
    marginTop: '22px',
    '&>*': {
      display: 'flex',
    },
  },
  campaign: {
    height: '43px',
  },
  influencer: {
    gridArea: '2/1/3/3',
  },
  postText: {
    gridArea: '3/1/4/3',
  },
  contentType: {
    gridArea: '4/1/5/3',
    '& h6': {
      ...theme.textStyles.h6,
      fontWeight: 'bold',
      fontSize: '12px',
      paddingLeft: '4px',
    },
    '& label': {
      marginTop: '4px',
      marginRight: '4px',
      marginLeft: 0,
      padding: '3px',
    },
  },
  uploadImage: {
    gridArea: '5/1/6/3',
    minHeight: '150px',
    marginBottom: theme.spacing(3),
    '& p': {
      color: theme.colors.primary.main,
      fontSize: '12px',
    },
    '& p::after': {
      content: '"or drag and drop"',
      color: 'initial',
    },
  },
  trackingURL: {
    gridArea: '6/1/7/3',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    '& h6': {
      ...theme.textStyles.h6,
      fontWeight: 'bold',
      fontSize: '12px',
      '& span': {
        position: 'absolute',
        right: '0',
        top: '-3px',
      },
    },
    '& p': {
      ...theme.textStyles.bodyExtraSmall,
      color: theme.colors.text.light,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      '& a': {
        textDecoration: 'none',
        color: theme.colors.primary.main,
      },
      '& a:hover': {
        cursor: 'pointer',
      },
    },
    '& button': {
      width: 'min-content',
      whiteSpace: 'nowrap',
      marginBottom: theme.spacing(1),
    },
  },
  labels: {
    gridArea: '7/1/8/3',
    '& h6': {
      ...theme.textStyles.h6,
      fontWeight: 'bold',
      fontSize: '12px',
      paddingLeft: '4px',
      marginBottom: theme.spacing(1),
    },
  },
  notes: {
    gridArea: '9/1/10/3',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    '& h6': {
      ...theme.textStyles.h6,
      fontWeight: 'bold',
      fontSize: '12px',
      marginBottom: '6px',
    },
  },
  notesInput: {
    minHeight: '150px',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& button:first-of-type': {
      paddingLeft: '0',
    },
  },
  buttons: {
    display: 'flex',
    columnGap: theme.spacing(1),
  },
  contentChips: {
    height: '30px',
    paddingLeft: '2px',
    fontSize: '12px',
    '&>svg': {
      height: '18px',
    },
  },
}));

const ContentOption = ({
  value, icon, type, setType,
}) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      value={value}
      control={<Radio style={{ display: 'none' }} />}
      label={(
        <Chip
          icon={icon}
          label={value}
          color={type === value ? 'primary' : 'default'}
          className={classes.contentChips}
        />
      )}
      onChange={() => setType(value)}
    />
  );
};

ContentOption.propTypes = {
  value: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  type: PropTypes.string.isRequired,
  setType: PropTypes.func.isRequired,
};

const AddNewPostPane = () => {
  const classes = useStyles();
  const auth = useAuth();
  const { createNotification } = useNotification();
  const [paneIsOpen, setPaneIsOpen] = useState(false);
  const content = useContentManager();
  const brand = content.brand || '';
  const [campaign, setCampaign] = useState(content.campaign || '');
  const influencers = campaign && campaign.influencers;
  const [influencer, setInfluencer] = useState();
  const [, setIsFetching] = useState(false);
  const [text, setText] = useState('');
  const [type, setType] = useState('Feed Post');
  const [postImages, setPostImages] = useState([]);
  const [analyticsUrl, setAnalyticsUrl] = useState('');
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [postDate, setPostDate] = useState();
  const [postTime, setPostTime] = useState();
  const [notes, setNotes] = useState();
  const [influencerError, setInfluencerError] = useState('');
  const [imagesError, setImagesError] = useState('');

  const checkDateTime = () => {
    if (!postDate && !postTime) {
      return null;
    }
    if (postDate && postTime) {
      return joinDateToTime(postDate, postTime);
    }
    if (postDate && !postTime) {
      return postDate.format();
    }
    return null;
  };

  const resetInputs = () => {
    setIsFetching(false);
    setSelectedLabels([]);
    setText('');
    setType('Feed Post');
    setPaneIsOpen(false);
    setPostDate(null);
    setPostTime(null);
    setNotes(null);
    setPostImages([]);
    setInfluencer(null);
    setAnalyticsUrl(null);
    setInfluencerError('');
  };

  const handleNoteChange = (event) => {
    setNotes(convertToRaw(event.getCurrentContent()));
  };

  const handleImages = () => {
    const newObj = {};
    let ind = 0;
    postImages.forEach((img) => {
      newObj[ind] = img.name;
      ind += 1;
    });
    return newObj;
  };

  const handleSave = async () => {
    setInfluencerError(influencer ? '' : 'Influencer is Required');
    setImagesError(postImages.length > 0 ? '' : 'Image is Required');

    if (influencer && postImages.length > 0) {
      setIsFetching(true);

      const images = handleImages();

      const response = await API({
        method: 'post',
        url: `/account/${auth.account.id}/content-manager/brand/${brand.id}/campaign/${campaign.id}/`,
        data: {
          post_text: text,
          content_type: content.contentTypes.indexOf(
            content.contentTypes.find((i) => i.type === type),
          ),
          labels: JSON.stringify(selectedLabels),
          notes: JSON.stringify(notes),
          post_datetime: checkDateTime(),
          influencer_id: (influencer && influencer.network_id[0]) || null,
          google_tracking_url: analyticsUrl,
          images: JSON.stringify(images),
        },
      });

      if (response.status === 200) {
        resetInputs();
        createNotification('success', 'Post Saved - Uploading Image');

        try {
          const imgResponse = await API({
            method: 'post',
            url: `/account/${auth.account.id}/content-manager/brand/${brand.id}/campaign/${campaign.id}/post/${response.data.data.data.post_id}/get_signed_url/`,
            data: {
              request_type: 'UPLOAD',
              images: JSON.stringify(images),
              ContentType: 'application/x-www-form-urlencoded',
            },
          });

          if (imgResponse.status === 200) {
            Object.entries(imgResponse.data.data.data).forEach(async (img) => {
              const put_response = await API({
                method: 'put',
                url: img[1],
                data: postImages.find((file) => file.path === img[0]),
              });
              if (put_response.status === 200) {
                createNotification('success', 'Image Uploaded');
              }
            });
          } else {
            createNotification('error', 'Image Failed to Upload');
          }
          content.getCampaignAndPosts(
            auth.account.id,
            content.brand.id,
            content.campaign.id,
          );
        } catch (e) {
          createNotification('error', 'Error Post Image Didnt Save');
          content.getCampaignAndPosts(
            auth.account.id,
            content.brand.id,
            content.campaign.id,
          );
        }
      }
    }
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Button
        onClick={() => setPaneIsOpen(true)}
        color="primary"
        size="small"
        variant="contained"
      >
        + Add New Post
      </Button>
      <SlidingPaneBase
        style={{ zIndex: '999' }}
        paneIsOpen={paneIsOpen}
        onRequestClose={setPaneIsOpen}
      >
        <Typography variant="h5" className={classes.title}>
          Add New Post
        </Typography>
        <form className={classes.form}>
          <FormControl fullWidth className={classes.input}>
            <InputLabel>Brand Profile</InputLabel>
            <TextField
              // defaultValue={brand.username}
              // onChange={(e) => setBrand(
              //   brands.find((brand) => brand.brand === e.target.value),
              // )}
              disabled
              value={brand.username}
              className={classes.select}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Avatar
                      style={{
                        fontSize: '12px',
                        height: '20px',
                        width: '20px',
                        marginLeft: '10px',
                        marginRight: '-10px',
                      }}
                      src="#"
                      alt={brand.username}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <FormControl fullWidth className={classes.input}>
            <InputLabel>Campaign</InputLabel>
            <TextField
              value={campaign.name}
              onChange={(e) => setCampaign(e.target.value)}
              // className={classes.campaign}
              className={classes.select}
              disabled
            />
            {/* {brand.campaigns.map((camp) => (
                <MenuItem key={camp.name} value={camp.name}>
                  {camp.name}
                </MenuItem>
              ))} */}
            {/* </Select> */}
          </FormControl>
          <FormControl
            fullWidth
            className={`${classes.input} ${classes.influencer}`}
          >
            <InputLabel>Select An Influencer Profile</InputLabel>
            <Select
              defaultValue="Select"
              onChange={(e) => setInfluencer(e.target.value)}
              className={classes.select}
            >
              <MenuItem value="Select" disabled>
                Select an influencer profile
              </MenuItem>
              {influencers
                && influencers.map((inf) => (
                  <MenuItem key={inf.full_name} value={inf}>
                    <Avatar
                      style={{
                        fontSize: '12px',
                        height: '20px',
                        width: '20px',
                        marginRight: '10px',
                      }}
                      src={inf.profile_picture}
                    />
                    <Typography style={{ display: 'inline' }}>
                      {inf.full_name}
                      {' '}
                      (@
                      {inf.handle}
                      )
                    </Typography>
                  </MenuItem>
                ))}
            </Select>
            {influencerError && (
              <FormHelperText style={{ color: 'red' }}>
                {influencerError}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            fullWidth
            className={`${classes.input} ${classes.postText}`}
          >
            <TextField
              label="Post Text"
              rows={4}
              placeholder="Write caption, #hashtags, @mentions or add emojis..."
              helperText={`${2200 - text.length} characters left`}
              onChange={(e) => setText(e.target.value)}
              multiline
            />
          </FormControl>
          <FormControl
            component="fieldset"
            className={`${classes.contentType} ${classes.input}`}
          >
            <Typography variant="h6">Post Content Type</Typography>
            <RadioGroup
              name="contentType"
              value={type}
              onChange={(e) => setType(e.target.value)}
              row
            >
              {content.contentTypes.map((option) => (
                <ContentOption
                  value={option.type}
                  icon={option.icon}
                  type={type}
                  setType={setType}
                  key={option.type}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <DropzoneArea
            dropzoneClass={classes.uploadImage}
            onChange={(images) => setPostImages(images)}
            dropzoneText="Upload images "
            maxFileSize={1000000}
            filesLimit={1}
          />
          <div className={`${classes.input} ${classes.trackingURL}`}>
            <Typography variant="h6">
              Google Analytics tracking URL
              <span>
                <AccessInfoTooltip title="Tracking URL?">
                  Info about Google Analytics
                </AccessInfoTooltip>
              </span>
            </Typography>
            <p>
              Remember to share this link with your influencer to track the
              traffic contributed by them on your website. Requires
              {' '}
              <a
                href="https://analytics.google.com/analytics/web/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Analytics
              </a>
              {' '}
              enabled on your website.
            </p>
            <a
              href="https://ga-dev-tools.web.app/ga4/campaign-url-builder/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <Button color="primary" variant="contained">
                Create Custom URL
              </Button>
            </a>
            <FormControl fullWidth>
              <TextField
                placeholder="Copy and paste the URL here"
                onChange={(e) => setAnalyticsUrl(e.target.value)}
              />
            </FormControl>
          </div>
          <FormControl className={`${classes.input} ${classes.labels}`}>
            <Typography variant="h6">Add Labels</Typography>
            <Autocomplete
              value={selectedLabels}
              multiple
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              freeSolo
              options={topics}
              renderOption={(option) => (option.title ? option.title : option)}
              ChipProps={{
                color: 'secondary',
                style: { backgroundColor: '#354065', color: 'white' },
              }}
              onChange={(_, newValue) => {
                const selected = newValue.map((option) => (option.inputValue ? option.inputValue : option));
                setSelectedLabels(selected);
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    title: `Create "${params.inputValue}" label`,
                  });
                }
                return filtered;
              }}
              getOptionLabel={(option) => {
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option;
              }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  placeholder="Select or create a new label..."
                />
              )}
            />
          </FormControl>
          <KeyboardDatePicker
            clearable
            value={postDate || content.campaign.startdate}
            placeholder="Pick your Date"
            onChange={(date) => setPostDate(date)}
            minDate={content.campaign.startdate}
            label="Post Date"
            className={classes.input}
          />
          <KeyboardTimePicker
            label="Post Time"
            placeholder="Pick your time"
            mask="__:__ _M"
            value={postTime}
            onChange={(date) => setPostTime(date)}
            className={classes.input}
            clearable
          />
          <FormControl className={classes.notes}>
            <Typography variant="h6">Notes</Typography>
            <Paper variant="outlined" className={classes.notesInput}>
              <MUIRichTextEditor
                label="Add private notes to this post..."
                onChange={handleNoteChange}
                controls={[
                  'title',
                  'bold',
                  'italic',
                  'underline',
                  'bulletList',
                  'link',
                ]}
                toolbar
              />
            </Paper>
            {influencerError && (
              <FormHelperText style={{ color: 'red' }}>
                {influencerError}
              </FormHelperText>
            )}
            {imagesError && (
              <FormHelperText style={{ color: 'red' }}>
                {imagesError}
              </FormHelperText>
            )}
          </FormControl>
          <div className={classes.buttons}>
            <Button onClick={() => setPaneIsOpen(false)} variant="contained">
              Cancel
            </Button>
            <Button color="primary" onClick={handleSave} variant="contained">
              Save Now
            </Button>
          </div>
        </form>
      </SlidingPaneBase>
    </MuiPickersUtilsProvider>
  );
};

export default AddNewPostPane;
