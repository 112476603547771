import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  MenuItem,
  Avatar,
  Menu,
  ButtonBase,
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Link } from '@reach/router';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth, useContentManager, useNotification } from '../../hooks';
import API from '../../utils/api';

const useStyles = makeStyles((theme) => ({
  mainCont: {
    border: `2px solid ${theme.colors.system.grey2}`,
    borderRadius: '4px',
    padding: '1rem',
  },
  mainHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemNamesCont: {
    margin: '1rem 0 1rem 0',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: '4px',
    padding: '0.5rem',
  },
  itemPic: {
    borderRadius: 50,
    height: '25px',
    width: '25px',
  },
  itemName: {
    marginLeft: '1rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  dotPoint: {
    height: '10px',
    width: '10px',
    borderRadius: '50%',
  },
  avatar: {
    width: 25,
    height: 25,
    fontSize: '12px',
  },
}));

const ClickList = (props) => {
  const {
    title, setIsOpen, addNew, items, dotPoint, brand,
  } = props;
  const auth = useAuth();
  const content = useContentManager();
  const classes = useStyles();
  const isClient = auth.user.agency_role === 'client';
  const [anchorEl, setAnchorEl] = useState(null);
  const { createNotification } = useNotification();

  const handleOptions = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const deleteListItem = async (id) => {
    try {
      const response = await API({
        method: 'delete',
        url: dotPoint
          ? `/account/${auth.account.id}/content-manager/brand/${content.brand.id}/campaign/${id}/`
          : `/account/${auth.account.id}/content-manager/brand/${id}/`,
      });
      if (response.status === 200) {
        // console.log(response);
        createNotification('success', 'Campaign Deleted');
        content.getBrandCampaignsPosts(auth.account.id, content.brand.id);
        setAnchorEl(null);
      }
    } catch (e) {
      createNotification('error', `Couldn't Delete: ${e}`);
    }
  };

  return (
    <div className={classes.mainCont}>
      <div className={classes.mainHeader}>
        <Typography variant="h6">{title}</Typography>
        {!isClient && (
          <Button
            color="primary"
            onClick={setIsOpen}
            size="small"
            variant="text"
          >
            {`+ Add A New ${addNew}`}
          </Button>
        )}
      </div>
      {content.fetching ? (
        <Typography>Loading...</Typography>
      ) : (
        <>
          {items.length > 0
            && items.map((item, ind) => (
              <>
                <MenuItem
                  component={Link}
                  to={
                    !brand
                      ? `/content-approval/${item.id}/`
                      : `/content-approval/${brand.id}/campaign/${item.id}/`
                  }
                  className={classes.itemNamesCont}
                  key={`${item}${String(ind)}`}
                >
                  {dotPoint ? (
                    // if dotPoint == true then this is on campaignDashboard
                    <>
                      <div
                        style={{
                          backgroundColor: item.color,
                        }}
                        className={classes.dotPoint}
                      />
                    </>
                  ) : (
                    <Avatar
                      alt={item.username}
                      className={classes.avatar}
                      src="#"
                    />
                  )}
                  <div className={classes.itemName}>
                    <div style={{ fontSize: 'small' }}>
                      {item.username || item.name}
                    </div>
                  </div>
                  <ButtonBase onClick={(e) => handleOptions(e)}>
                    <MoreHorizIcon style={{ fontSize: 'medium' }} />
                  </ButtonBase>
                </MenuItem>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                  elevation={0}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <MenuItem onClick={() => deleteListItem(item.id)}>Delete</MenuItem>
                </Menu>
              </>
            ))}
        </>
      )}
    </div>
  );
};

ClickList.propTypes = {
  title: PropTypes.string.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  addNew: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  dotPoint: PropTypes.bool,
  brand: PropTypes.shape(),
};

ClickList.defaultProps = {
  dotPoint: false,
  brand: null,
};

export default ClickList;
