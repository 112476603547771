import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Popover,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';

import VirtualizedList from '../common/VirtualizedList';
import AddProfilesToListDialog from '../list/AddProfilesToListDialog';
import { useApi, useAuth, useNotification } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 180,
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    padding: theme.spacing(1),
  },
  heading: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'centre',
    paddingBottom: 8,
    '&>p': {
      fontWeight: 'bold',
    },
  },
  label: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  button: {
    marginTop: theme.spacing(1),
  },
}));

const ListCheckboxItem = ({ data, index, style }) => {
  const {
    isFetching,
    lists,
    networkIds,
    onChange,
    updatedLists,
  } = data;
  const list = index < lists.length ? lists[index] : null;
  const updatedList = list && list.id in updatedLists ? updatedLists[list.id] : list;
  const classes = useStyles();
  return (
    <div style={style}>
      <FormControlLabel
        className={classes.label}
        control={(
          <Checkbox
            checked={
              Boolean(updatedList)
              && networkIds.some((it) => updatedList.items.map((item) => item.network_id).includes(it))
            }
            disabled={isFetching || !updatedList}
            onChange={(e) => onChange(e, updatedList)}
            size="small"
          />
        )}
        key={`list_${index}`}
        label={updatedList ? updatedList.name : '...'}
      />
    </div>
  );
};

ListCheckboxItem.propTypes = {
  data: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.shape().isRequired,
};

const AddToListContent = ({ onClose, profile }) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [updatedLists, setUpdatedLists] = useState([]);
  const [listAddedOrRemoved, setAddedOrRemoved] = useState('');
  const classes = useStyles();
  const auth = useAuth();
  const { createNotification } = useNotification();
  const [{ data: updatedList, isFetching }, setPostListRequest] = useApi();
  const networkIds = profile.data.network_id;

  useEffect(() => {
    if (updatedList) {
      setUpdatedLists((lists) => ({ ...lists, [updatedList.id]: updatedList }));
      createNotification('success', listAddedOrRemoved);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedList]);

  const handleCheckedChange = (event, list) => {
    if (event.target.checked) {
      setPostListRequest({
        method: 'patch',
        url: `/account/${auth.account.id}/list/${list.id}/`,
        data: {
          network_ids_to_add: networkIds,
        },
      });
      setAddedOrRemoved('Added');
    } else {
      setPostListRequest({
        method: 'patch',
        url: `/account/${auth.account.id}/list/${list.id}/`,
        data: {
          network_ids_to_remove: networkIds,
        },
      });
      setAddedOrRemoved('Removed');
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <Typography variant="body1">Add to list</Typography>
        <PlaylistAddIcon size="small" />
      </div>
      <VirtualizedList
        height={210}
        itemData={{
          isFetching,
          networkIds,
          onChange: handleCheckedChange,
          updatedLists,
        }}
        itemHeight={30}
        ListItemComponent={ListCheckboxItem}
      />
      <Button
        className={classes.button}
        color="primary"
        fullWidth
        onClick={() => setDialogIsOpen(true)}
        size="small"
        variant="contained"
      >
        Create new list
      </Button>
      {dialogIsOpen && (
        <AddProfilesToListDialog
          defaultListMode="create"
          onClose={onClose}
          profiles={[profile]}
        />
      )}
    </div>
  );
};

AddToListContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  profile: PropTypes.shape().isRequired,
};

const AddToListButton = ({ profile }) => {
  const [anchorEl, setAnchorEl] = useState();
  const auth = useAuth();
  const listPermission = auth.hasPermission('list.create');

  return (
    <div>
      <Tooltip title="Add profile to lists">
        <span>
          <IconButton
            color={profile.data.is_in_list ? 'primary' : 'default'}
            onClick={(e) => setAnchorEl(e.currentTarget)}
            size="small"
            disabled={!listPermission}
          >
            <PlaylistAddIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {Boolean(anchorEl) && (
          <AddToListContent
            onClose={() => setAnchorEl(null)}
            profile={profile}
          />
        )}
      </Popover>
    </div>
  );
};

AddToListButton.propTypes = {
  profile: PropTypes.shape().isRequired,
};

export default AddToListButton;
