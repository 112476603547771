// 404 Page
import React from 'react';

import { Link } from '@reach/router';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ScrunchLogoFull from '../../assets/images/scrunch-logo-full.svg';
import SearchingGif from '../../assets/images/gifs/searching.gif';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.colors.background.primary,
  },
  root: {
    width: '80%',
    '@media (min-width:1920px)': {
      width: '50%',
    },
    height: '100%',
    margin: 'auto',
    '@media (max-width: 1919px)': {
      padding: '40px 0',
    },
    overflow: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  headerLogo: {
    height: 30,
    '@media (min-width:2560px)': {
      height: 40,
    },
  },
  text: {
    ...theme.fonts.tertiary,
    margin: '20px 0',
    '@media (max-width: 767px)': {
      fontSize: '24px',
    },
    '@media (min-width: 1920px)': {
      margin: '35px 0',
    },
    '@media (min-width: 2560px)': {
      fontSize: '48px',
      width: 820,
      margin: '40px 0',
    },
  },
  link: {
    color: theme.colors.primary.main,
    textDecoration: 'none',
  },
  bottomText: {
    color: theme.colors.system.grey7,
    margin: '20px 0',
    lineHeight: '24px',
    '@media (min-width: 768px)': {
      width: '70%',
    },
    '@media (min-width: 1920px)': {
      margin: '35px 0',
    },
    '@media (min-width: 2560px)': {
      fontSize: 20,
      width: 740,
      lineHeight: '28px',
      margin: '40px 0',
    },
  },
  buttonLink: {
    textDecoration: 'none',
  },
  button: {
    padding: '7px 10px',
    borderRadius: 4,
    boxShadow: 'none',
    '@media (min-width: 2560px)': {
      ...theme.textStyles.bodyLarge,
      padding: '10px 15px',
    },
  },
  gif: {
    height: 400,
    '@media (max-width: 1919px)': {
      height: 250,
    },
    '@media (min-width: 2560px)': {
      width: 760,
      height: 'auto',
    },
  },
}));

const NoMatch = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <img className={classes.headerLogo} alt="Scrunch" src={ScrunchLogoFull} />
        <Typography className={classes.text} variant="h4" component="p">
          Whoops! We can&apos;t find the page you&apos;re looking for!
        </Typography>

        <img className={classes.gif} alt="Man searching for broken link" src={SearchingGif} />

        <Typography className={classes.bottomText} variant="body1" component="p">
          {/* We know there’s nothing influential about a 404 page. Instead, head over to our&nbsp;
          <Link className={classes.link} to="/">Home Page</Link>
          &nbsp;or to the&nbsp;
          <Link className={classes.link} to="/">Scrunch Blog</Link>
          &nbsp;- “Matters of Influence”. */}
          How about we take you back to discovering influencers instead?
        </Typography>

        <Link className={classes.buttonLink} to="/">
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            size="small"
          >
            <Typography variant="body1">Save Me!</Typography>
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default NoMatch;
