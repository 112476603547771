import React, { useEffect, useState, useCallback } from 'react';

import PropTypes from 'prop-types';

import {
  Typography,
  Chip,
  CircularProgress,
  Link,
  Avatar,
  IconButton,
  ButtonBase,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from '@material-ui/core';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from '@reach/router';

import PageBase from '../PageBase';
import {
  useApi,
  useAuth,
  useRoute,
  useNotification,
  useLists,
} from '../../../hooks';
import API from '../../../utils/api';
import EmptyList from '../../list/EmptyList';
import DeleteListDialog from '../../list/DeleteListDialog';
import DisablePage from '../../common/DisablePage';
import NoMatch from '../../router/NoMatch';
import ListSettingsButton from '../../list/ListSettingsButton';
import { formatNumber } from '../../../utils/number';
import { formatDate } from '../../../utils/datetime';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '100%',
    padding: '20px 40px',
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    padding: '40px 0',
    display: 'flex',
    flexDirection: 'column',
  },
  subHeading: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  paginationArrow: {
    fontSize: 'small',
    color: theme.colors.system.grey6,
  },
  pageTitle: {
    '@media(max-width: 1919px)': {
      ...theme.textStyles.bodyLarge,
    },
  },
  pageSubtitle: {
    color: theme.colors.system.grey6,
  },
  listContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  cardRoot: {
    width: '32%',
    '@media (max-width: 1280px)': {
      width: '48%',
    },
    '@media (max-width: 767px)': {
      width: '100%',
    },
    border: `1px solid ${theme.colors.system.grey3}`,
    backgroundColor: theme.colors.system.grey1,
    borderRadius: 4,
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: 20,
    '@media (min-width: 768px)': {
      '&:not(:nth-child(3n))': {
        marginRight: '2%',
      },
    },
  },
  cardHeader: {
    padding: '0 0 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  influencerImages: {
    display: 'flex',
  },
  profileAvatar: {
    width: 45,
    height: 45,
    border: '1px solid #FFFFFF',
    '&:not(:first-child)': {
      marginLeft: '-10px',
    },
  },
  countAvatar: {
    backgroundColor: '#354065',
  },
  listNameCont: {
    width: '100%',
    flex: 1,
    marginBottom: 20,
  },
  listName: {
    ...theme.textStyles.bodyLarge,
  },
  listDetailsCont: {
    backgroundColor: '#FFFFFF',
    border: `1px solid ${theme.colors.system.grey3}`,
    borderRadius: 4,
    width: '100%',
    padding: '0 15px 20px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  detailTitle: {
    color: theme.colors.system.grey7,
    padding: '20px 0 10px',
  },
  socialReachChip: {
    backgroundColor: '#354065',
    color: theme.colors.system.light,
    width: 'fit-content',
  },
  engagementChip: {
    backgroundColor: theme.colors.secondary.main,
    color: theme.colors.system.light,
    width: 'fit-content',
  },
  menuItem: {
    ...theme.textStyles.bodySmall,
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  paper: {
    minWidth: 800,
  },
  deleteItem: {
    color: theme.colors.danger.main,
  },
}));
//

const ListActionsButton = ({
  list,
  listId,
  listName,
  handleDeleteList,
  setIsFetching,
}) => {
  const classes = useStyles();

  const [showCopyDialog, setShowCopyDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [clientEmail, setClientEmail] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [, setRoute] = useRoute();
  const auth = useAuth();
  const { createNotification } = useNotification();
  const lists = useLists();

  const handleMenuClose = (event) => {
    event.preventDefault();
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event, handler) => {
    handleMenuClose(event);
    handler();
  };

  const deleteList = async () => {
    setShowDeleteDialog(false);
    setIsFetching(true);

    try {
      const response = await API({
        method: 'delete',
        url: `/account/${auth.account.id}/list/${listId}/`,
      });

      if (response && response.status >= 200 && response.status < 300) {
        handleDeleteList();
        createNotification('success', 'List Deleted Successfully');
      }
    } catch (err) {
      createNotification(
        'error',
        'An unexpected error occured. Could not delete list',
      );
    }

    setIsFetching(false);
  };

  const handleCopyList = async () => {
    setShowCopyDialog(false);

    try {
      const response = await API({
        method: 'post',
        url: `/account/${auth.account.id}/list/${listId}/copy/`,
        data: {
          user_email: clientEmail,
        },
      });

      if (response && response.status >= 200 && response.status < 300) {
        setClientEmail('');
        createNotification(
          'success',
          `List successfully copied to ${clientEmail}`,
        );
      }
    } catch (err) {
      createNotification(
        'error',
        'An unexpected error occured. Could not copy list',
      );
    }
  };

  return (
    <>
      <ButtonBase onClick={(e) => setAnchorEl(e.target)}>
        <MoreVertIcon style={{ color: '#2B2E34' }} />
      </ButtonBase>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={(event) => handleMenuItemClick(event, () => setRoute({ pathname: `/list/${listId}` }))}
        >
          View List
        </MenuItem>
        {auth.hasPermission('list.copy') && (
          <MenuItem
            className={classes.menuItem}
            onClick={(event) => handleMenuItemClick(event, () => setShowCopyDialog(true))}
          >
            Copy List
          </MenuItem>
        )}
        {auth.hasPermission('list.delete') && (
          <>
            <MenuItem
              className={`${classes.menuItem} ${classes.deleteItem}`}
              // onClick={(event) => handleMenuItemClick(event, deleteList)}
              onClick={() => setShowDeleteDialog(true)}
            >
              Delete List
            </MenuItem>
            <ListSettingsButton
              key="settings"
              list={list}
              listsPage
              onSuccess={() => lists.refreshLists(auth.account.id)}
              // setIsListFetching={isListFetching}
            />
          </>
        )}
      </Menu>

      <Dialog
        open={showCopyDialog}
        onBackdropClick={() => setShowCopyDialog(false)}
        // classes={{ paper: classes.paper }}
      >
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => setShowCopyDialog(false)}
        >
          <CloseIcon />
        </IconButton>

        <DialogTitle>
          <Typography variant="h5" component="h5" style={{ marginBottom: 10 }}>
            Copy List To Client Account
          </Typography>
          <Typography variant="body1" color="primary">
            List Name:&nbsp;
            {listName}
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Typography variant="body1">
            Enter Client’s Email Address (Linked with Scrunch)
          </Typography>
          <TextField
            placeholder="Enter Email Address"
            onChange={(e) => setClientEmail(e.target.value)}
            value={clientEmail}
            fullWidth
          />
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            disabled={!clientEmail}
            onClick={handleCopyList}
            variant="contained"
            style={{ minWidth: 250, minHeight: 40 }}
            size="small"
          >
            Copy List Now
          </Button>
        </DialogActions>
      </Dialog>

      <DeleteListDialog
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        listName={listName}
        deleteList={() => {
          setAnchorEl(null);
          deleteList();
        }}
      />
    </>
  );
};

ListActionsButton.propTypes = {
  listId: PropTypes.number.isRequired,
  listName: PropTypes.string.isRequired,
  handleDeleteList: PropTypes.func.isRequired,
  setIsFetching: PropTypes.func.isRequired,
  list: PropTypes.shape().isRequired,
};

const ListItemCard = ({ list, handleDeleteList, setIsFetching }) => {
  const classes = useStyles();
  const auth = useAuth();
  const [{ response, isFetching }] = useApi({
    method: 'post',
    url: `/account/${auth.account.id}/list/${list.id}/search/`,
    data: {
      from: 0,
      size: 3,
      include_stats: true,
    },
  });

  const profiles = response && response.data && response.data.data
    ? response.data.data.profiles
    : [];
  const total = response && response.data && response.data.data
    ? response.data.data.total
    : 0;
  const listStats = response && response.data && response.data.data
    ? response.data.data.stats
    : null;

  return (
    <div className={classes.cardRoot} key={list.id}>
      <div className={classes.cardHeader}>
        <div className={classes.influencerImages}>
          {profiles
            && profiles.map((profile, index) => (
              <Avatar
                key={profile.id}
                alt={profile.full_name[0]}
                className={classes.profileAvatar}
                src={profile.profile_picture[0]}
                style={{ zIndex: 4 - index }}
              />
            ))}
          {total > 3 && (
            <Avatar
              className={`${classes.profileAvatar} ${classes.countAvatar}`}
              style={{ zIndex: 1 }}
            >
              <Typography variant="body1">
                +
                {total - 3}
              </Typography>
            </Avatar>
          )}
        </div>
        <ListActionsButton
          list={list}
          listId={list.id}
          listName={list.name}
          handleDeleteList={handleDeleteList}
          setIsFetching={setIsFetching}
        />
        {/* <Link component={RouterLink} to={`/list/${list.id}`}>
          <MoreVertIcon style={{ color: '#2B2E34' }} />
        </Link> */}
      </div>
      <div className={classes.listNameCont}>
        <Link component={RouterLink} to={`/list/${list.id}`}>
          <Typography
            variant="body1"
            color="primary"
            className={classes.listName}
          >
            {list.name}
          </Typography>
        </Link>
      </div>
      <div className={classes.listDetailsCont}>
        <Typography variant="body2" className={classes.detailTitle}>
          Created by
        </Typography>
        <Typography variant="body2" className={classes.detailTitle}>
          Created at
        </Typography>
        <Typography variant="body2">{list.created_by.first_name}</Typography>
        <Typography variant="body2">{formatDate(list.created_at)}</Typography>
        <Typography variant="body2" className={classes.detailTitle}>
          Last modified at
        </Typography>
        <Typography variant="body2" className={classes.detailTitle}>
          No. of Influencers
        </Typography>
        <Typography variant="body2">
          {formatDate(list.last_modified_at)}
        </Typography>
        <Typography variant="body2">{list.items.length}</Typography>
        <Typography variant="body2" className={classes.detailTitle}>
          Social Reach
        </Typography>
        <Typography variant="body2" className={classes.detailTitle}>
          Engagement
        </Typography>
        {isFetching && <CircularProgress size="1rem" />}
        {!isFetching && listStats && listStats.social_reach && (
          <Chip
            className={classes.socialReachChip}
            icon={<TrendingUpIcon style={{ color: 'white' }} />}
            label={formatNumber(listStats.social_reach.sum)}
            size="small"
          />
        )}
        {isFetching && <CircularProgress size="1rem" />}
        {!isFetching && listStats && listStats.engagement && (
          <Chip
            className={classes.engagementChip}
            icon={<EqualizerIcon style={{ color: 'white' }} />}
            label={formatNumber(listStats.engagement.sum)}
            size="small"
          />
        )}
      </div>
    </div>
  );
};

ListItemCard.propTypes = {
  list: PropTypes.shape().isRequired,
  handleDeleteList: PropTypes.func.isRequired,
  setIsFetching: PropTypes.func.isRequired,
};

const ListsPage = () => {
  const classes = useStyles();
  const [from, setFrom] = useState(0);
  const size = 12;
  const auth = useAuth();
  const [{ data, isFetching }] = useApi({
    method: 'get',
    url: `/account/${auth.account.id}/list/`,
    params: {
      from,
      size,
    },
  });
  const [{ data: listsData, isFetchingLists }, setGetListsRequest] = useApi();
  const [isFetchingResponse, setIsFetchingResponse] = useState(false);
  const listsHook = useLists();

  const [lists, setLists] = useState(null);
  const [total, setTotal] = useState(0);

  const getLists = useCallback(() => {
    setGetListsRequest({
      method: 'get',
      url: `/account/${auth.account.id}/list/`,
      params: {
        from,
        size,
      },
    });
  }, [auth, from, size, setGetListsRequest]);

  useEffect(() => {
    getLists();
  }, [getLists, listsHook]);

  useEffect(() => {
    if (!isFetching && data) {
      setLists(data.lists);
    }
  }, [data, isFetching]);

  useEffect(() => {
    if (!isFetchingLists && listsData) {
      setLists(listsData.lists);
      setTotal(listsData.total);
    }
  }, [listsData, isFetchingLists]);

  const nextPage = () => {
    setFrom(from + size);
  };

  const previousPage = () => {
    setFrom(from - size);
  };

  const handleDeleteList = () => {
    if (lists && lists.length - 1 <= 0 && from >= size) {
      previousPage();
    } else {
      setGetListsRequest({
        method: 'get',
        url: `/account/${auth.account.id}/list/`,
        params: {
          from,
          size,
        },
      });
    }
  };

  if (auth.account.type === 'influencer') {
    return <NoMatch />;
  }

  return (
    <>
      <PageBase
        showLoadingBar={isFetching || isFetchingResponse || isFetchingLists}
        title="Lists"
      >
        {!auth.hasPermission('list.create') && <DisablePage />}
        {isFetching || isFetchingResponse || isFetchingLists ? null : (
          <div>
            {(lists === null || (lists && lists.length === 0)) && <EmptyList />}
            {lists && lists.length > 0 && (
              <>
                <div className={classes.root}>
                  <div className={classes.heading}>
                    <Typography
                      className={classes.pageTitle}
                      component="span"
                      variant="h5"
                    >
                      Lists
                    </Typography>
                    <div className={classes.subHeading}>
                      <Typography
                        className={classes.pageSubtitle}
                        component="span"
                        variant="body2"
                      >
                        A place for all your influencer campaign lists
                      </Typography>

                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {from + 1}
                        &nbsp;-&nbsp;
                        {from + size > total ? total : from + size}
                        &nbsp;of&nbsp;
                        {total}
                        {total > size && (
                          <IconButton
                            aria-label="Previous"
                            onClick={previousPage}
                            style={{ marginLeft: 10 }}
                            disabled={from === 0}
                          >
                            <ChevronLeftIcon
                              className={classes.paginationArrow}
                            />
                          </IconButton>
                        )}
                        {total > size && (
                          <IconButton
                            aria-label="Next"
                            onClick={nextPage}
                            style={{ marginLeft: 10 }}
                            disabled={from + size >= total}
                          >
                            <ChevronRightIcon
                              className={classes.paginationArrow}
                            />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={classes.listContainer}>
                    {lists.map((list) => (list.is_private
                      && auth.hasPermission('manager.restricted')
                      && list.created_by.id !== auth.user.id ? null : (
                        <ListItemCard
                          list={list}
                          key={list.id}
                          handleDeleteList={handleDeleteList}
                          setIsFetching={setIsFetchingResponse}
                        />
                      )))}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </PageBase>
    </>
  );
};

export default ListsPage;
