import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import DeleteAccountButton from '../account/DeleteAccountButton';
import EditMemberButton from './EditMemberButton';

import { capitalizeName, capitalize } from '../../utils/string';
import { useAuth } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    maxWidth: '1200px',
    border: `solid 2px ${theme.colors.system.grey1}`,
    borderCollapse: 'collapse',
    marginBottom: '3rem',
  },
  tableRow: {
    backgroundColor: theme.colors.system.grey1,
    height: '4rem',
  },
  tableRow2: {
    height: '4rem',
    borderBottom: `solid 2px ${theme.colors.system.grey1}`,
  },
  tableD: {
    paddingLeft: '2rem',
  },
  tableDName: {
    paddingLeft: '2rem',
    width: '350px',
    // maxWidth: '350px'
  },
  tableDButton: {
    float: 'right',
    margin: '15px 20px',
  },
  removeAccBtn: {
    color: theme.colors.secondary.main,
  },
}));

const MemberTable = (props) => {
  const {
    items,
    memberNameCol,
    roleCol,
    inviteNameCol,
    inviteStatusCol,
    brandProfileCol,
    buttonName,
    removeMemberAccess,
    refreshData,
    isMembers,
  } = props;
  const classes = useStyles();
  const auth = useAuth();

  return (
    <>
      <table className={classes.table}>
        <thead>
          <tr className={classes.tableRow}>
            {/* Member Name */}
            {memberNameCol && (
              <th align="left" className={classes.tableDName}>
                <Typography variant="body1">Member Name</Typography>
              </th>
            )}
            {/* Invite Name */}
            {inviteNameCol && (
              <th align="left" className={classes.tableDName}>
                <Typography variant="body1">Invite Name</Typography>
              </th>
            )}

            {/* Role */}
            {roleCol && (
              <th align="left" className={classes.tableDRole}>
                <Typography variant="body1">Role</Typography>
              </th>
            )}

            {/* Brand Profile Access */}
            {brandProfileCol && (
              <th align="left" className={classes.tableD}>
                <Typography variant="body1">Brand Profiles Access</Typography>
              </th>
            )}

            {/*  Invite Status */}
            {inviteStatusCol && (
              <th align="left" className={classes.tableD}>
                <Typography variant="body1">Invitation Status</Typography>
              </th>
            )}
            {!inviteStatusCol && (
              <th align="left" className={classes.tableD}>
                &nbsp;
              </th>
            )}
          </tr>
        </thead>

        <tbody>
          {memberNameCol && (
            <tr className={classes.tableRow2}>
              <td className={classes.tableDName}>
                {auth.account.owner}
                {' '}
                (Owner)
              </td>
              <td className={classes.tableDRole}>Administrator & Owner</td>
            </tr>
          )}
          {items
            .sort((a, b) => {
              if (a.agency_role > b.agency_role) {
                return 1;
              } if (b.agency_role > a.agency_role) {
                return -1;
              }
              return 0;
            })
            .map((item) => (
              <tr
                className={classes.tableRow2}
                key={item.first_name + item.last_name}
              >
                {(memberNameCol || inviteNameCol) && (
                  <td className={classes.tableDName}>
                    {capitalizeName(item.first_name, item.last_name)}
                  </td>
                )}
                {/* ROLE */}
                {roleCol && (
                  <td className={classes.tableDRole}>
                    {item.agency_role && capitalize(item.agency_role)}
                  </td>
                )}
                {/* BRAND PROFILE ACCESS */}
                {brandProfileCol && (
                  <td className={classes.tableD}>
                    {item.brand_profile_access && item.brand_profiles_access}
                  </td>
                )}
                {/*  INVITE STATUS */}
                {inviteStatusCol && (
                  <td className={classes.tableD}>Not Accepted</td>
                )}

                {/* EDIT/REMOVE BUTTON */}
                {!auth.hasPermission('manager.restricted') && (
                  <td className={classes.tableDButton}>
                    {/* CONTENT APPROVAL, MEMBER TABLE */}
                    {item.agency_role === 'client'
                      && removeMemberAccess
                      && !inviteNameCol && (
                        <DeleteAccountButton
                          buttonName={buttonName}
                          removeAccess
                          email={item.email}
                          client={item}
                          refreshData={refreshData}
                        />
                    )}
                    {/* CONTENT APPROVAL, INVITE TABLE */}
                    {item.agency_role === 'client'
                      && removeMemberAccess
                      && inviteNameCol && (
                        <EditMemberButton
                          refreshData={refreshData}
                          buttonName={buttonName}
                          isMembers={isMembers}
                          selected={item}
                          contentAppPage
                        />
                    )}
                    {/* SETTINGS PAGE TABLE BUTTON */}
                    {!removeMemberAccess && (
                      <EditMemberButton
                        refreshData={refreshData}
                        buttonName={buttonName}
                        isMembers={isMembers}
                        selected={item}
                      />
                    )}
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

MemberTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  memberNameCol: PropTypes.bool,
  roleCol: PropTypes.bool,
  inviteNameCol: PropTypes.bool,
  inviteStatusCol: PropTypes.bool,
  brandProfileCol: PropTypes.bool,
  buttonName: PropTypes.string,
  removeMemberAccess: PropTypes.bool,
  isMembers: PropTypes.bool,
  refreshData: PropTypes.func.isRequired,
};

MemberTable.defaultProps = {
  memberNameCol: false,
  roleCol: false,
  inviteNameCol: false,
  inviteStatusCol: false,
  brandProfileCol: false,
  buttonName: '',
  removeMemberAccess: false,
  isMembers: false,
};

export default MemberTable;
