import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.colors.system.darkTrans,
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: '99',
  },
}));

const DisablePage = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper} />
  );
};

export default DisablePage;
