/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  TablePagination as MuiTablePagination,
  IconButton,
  ButtonBase,
} from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paginationRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actions: {
    padding: theme.spacing(1),
  },
  tablePagination: {
    border: 'none',
  },
  paginationBtnRoot: {
    display: 'flex',
    // justifyContent: 'space-between',
  },
  pageBtn: {
    width: 40,
    height: 40,
    margin: '0 5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.colors.system.grey3}`,
    borderRadius: 1,
  },
  selectedPage: {
    border: `1px solid ${theme.colors.primary.main}`,
    color: theme.colors.primary.main,
  },
}));

const TablePaginationActions = ({
  onChangePage, count, rowsPerPage, page,
}) => {
  const classes = useStyles();

  const handlePageButtonClick = (event, pageNo) => {
    onChangePage(event, pageNo);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  let pageNos = [];
  const lastPage = Math.max(0, Math.ceil(count / rowsPerPage) - 1);
  const displayNos = 5;

  if (lastPage > displayNos - 1) {
    if (page < displayNos - 1) {
      pageNos = [1, 2, 3, 4, 5];
    } else if (page > lastPage - displayNos) {
      for (let i = lastPage - 5 + 1; i <= lastPage; i += 1) {
        pageNos.push(i);
      }
    } else {
      for (let i = page - 2; i <= page + 2; i += 1) {
        pageNos.push(i + 1);
      }
    }
  } else {
    for (let i = 0; i <= lastPage; i += 1) {
      pageNos.push(i + 1);
    }
  }

  return (
    <div className={classes.paginationBtnRoot}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page"
      >
        <KeyboardArrowLeft />
      </IconButton>

      {pageNos.map((pageNo) => (
        <ButtonBase
          onClick={(e) => handlePageButtonClick(e, pageNo - 1)}
          size="small"
          className={`${classes.pageBtn} ${page === pageNo - 1 ? classes.selectedPage : ''}`}
        >
          {pageNo}
        </ButtonBase>
      ))}

      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        <KeyboardArrowRight />
      </IconButton>
    </div>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const labelDisplayedResults = ({ count }) => {
  const countDisplay = count >= 10000 ? '10k+' : count;

  if (count === -1) {
    return 'No Results';
  }

  return `About ${countDisplay} Results`;
};

const ProfileTablePagination = (props) => {
  const { actions, ...paginationProps } = props;
  const classes = useStyles();

  return (
    <div className={classes.paginationRoot}>
      {actions && (
        <div className={classes.actions}>
          {actions}
        </div>
      )}
      <MuiTablePagination
        className={classes.tablePagination}
        component="div"
        labelDisplayedRows={labelDisplayedResults}
        {...paginationProps}
        ActionsComponent={TablePaginationActions}
      />
    </div>
  );
};

ProfileTablePagination.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.node),
};

ProfileTablePagination.defaultProps = {
  actions: null,
};

export default ProfileTablePagination;
