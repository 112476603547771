import React from 'react';

import PropTypes from 'prop-types';

import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const ClearableTextField = (props) => {
  const { onClear, ...textFieldProps } = props;

  return (
    <TextField
      style={{ height: 40 }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear text field"
              disabled={!textFieldProps.value}
              onClick={onClear}
              onMouseDown={(e) => e.preventDefault()}
            >
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...textFieldProps}
    />
  );
};

ClearableTextField.propTypes = {
  onClear: PropTypes.func.isRequired,
};

export default ClearableTextField;
