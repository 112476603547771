import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Select,
  TextField,
  Typography,
  DialogContent,
  DialogActions,
  MenuItem,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import ScrDialog from '../common/ScrDialog';
import { useAuth, useNotification, useContentManager } from '../../hooks';
import API from '../../utils/api';

const useStyles = makeStyles(() => ({
  field: {
    marginBottom: '20px',
    marginTop: '10px',
  },
  multiline: {
    // "&>*": {
    //   height: '150px',
    // },
  },
  btn: {
    height: '50px',
    width: '275px',
  },
}));

const SendForApprovalDialog = (props) => {
  const classes = useStyles();
  const auth = useAuth();
  const content = useContentManager();
  const { toClient, onClose } = props;
  // const content = useContentManager();
  const [members, setMembers] = useState([]);
  const [recievers, setRecievers] = useState([]);
  const clientArr = (recievers.length > 0
      && recievers.map((c) => `${c.first_name} ${c.last_name}`))
    || [];
  const [selectedClient, setSelectedClient] = useState('');
  const [reciever, setReciever] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const { createNotification } = useNotification();

  const handleSendNow = async () => {
    createNotification('success', 'Sending Email');
    setIsFetching(true);

    // Notification example,
    // Internal Username marked a post as Awaiting Approval with BrandOne / CampaignOne

    // API({
    //   method: "post",
    //   url: `/account/${auth.account.id}/content-manager/notification/${content.post.id}/`,
    //   data: {
    //     campaign_id: content.campaign.id,
    //     brand_id: content.brand.id,
    //     internal_user_id: auth.user.id,
    //     template: 1,
    //     status: 4,
    //   }
    // });

    try {
      const responseEmail = await API({
        method: 'post',
        url: `/account/${auth.account.id}/content-manager/email_users/`,
        data: {
          users: [reciever.email],
          subject,
          body,
          by_id: false,
        },
      });

      if (responseEmail.status === 200) {
        createNotification('success', 'Email Sent');

        try {
          const response = await API({
            method: 'patch',
            url: `/account/${auth.account.id}/content-manager/post/${content.post.id}/`,
            data: {
              status: toClient ? 3 : 6,
            },
          });

          if (response.status === 200) {
            createNotification('success', 'Status Updated');
            content.setPost(auth.account.id, content.post.id);
          }
        } catch (e) {
          createNotification('error', `Status not changed: ${e}`);
          setIsFetching(false);
        }
      }
    } catch (e) {
      createNotification('error', `Email couldn't send: ${e}`);
      setIsFetching(false);
    }

    onClose();
    setIsFetching(false);
  };

  const handleSelectChange = (value) => {
    const none = ['none', 'loading', ''];
    setSelectedClient(value);

    if (!none.includes(value)) {
      const names = value.split(' ');
      setReciever(
        recievers.find(
          (cli) => cli.first_name === names[0] && cli.last_name === names[1],
        ),
      );
    }
  };

  const getMembers = useCallback(async () => {
    setIsFetching(true);
    const response = await API({
      method: 'get',
      url: `/account/${auth.account.id}/users/`,
    });
    if (response.status === 200) {
      setMembers(response.data.data.user);
    }
    setIsFetching(false);
  }, [auth.account.id]);

  useEffect(() => {
    getMembers();
  }, [getMembers]);

  useEffect(() => {
    if (members && members.length > 0) {
      setRecievers(
        members.filter((mem) => (toClient ? mem.agency_role === 'client' : mem.agency_role !== 'client')),
      );
    }
  }, [members, toClient]);

  useEffect(() => {
    // console.log(clientArr);
  }, [clientArr]);

  return (
    <ScrDialog
      title={`Send For ${toClient ? 'Client' : 'Internal'} Approval`}
      isOpen
      onClose={onClose}
      titleStyle={{ marginBottom: 0 }}
      // children,
      // isFetching,
      isFetching={isFetching}
      minWidth={700}
      maxWidth={700}
    >
      <DialogContent style={{ padding: '0 50px' }}>
        <Typography variant="body1" component="span">
          {toClient ? `${'Brand'} Clients` : 'Recepients'}
        </Typography>
        <Select
          fullWidth
          margin="dense"
          className={classes.field}
          value={selectedClient}
          displayEmpty
          onChange={(e) => handleSelectChange(e.target.value)}
        >
          <MenuItem disabled value="">
            {toClient ? 'Select A Client' : 'Select a Member'}
          </MenuItem>

          {isFetching && clientArr && (
            <MenuItem disabled value="loading">
              Loading...
            </MenuItem>
          )}
          {!isFetching && clientArr.length < 1 && (
            <MenuItem disabled value="none">
              No Clients Available
            </MenuItem>
          )}
          {!isFetching
            && clientArr.length > 0
            && clientArr.map((c) => (
              <MenuItem key={c} value={c}>
                {c}
              </MenuItem>
            ))}
        </Select>
        <Typography variant="body1" component="span">
          Subject
        </Typography>
        <TextField
          margin="dense"
          fullWidth
          className={classes.field}
          onChange={(e) => setSubject(e.target.value)}
        />
        <Typography variant="body1" component="span">
          Body
        </Typography>
        <TextField
          margin="dense"
          multiline
          rows={5}
          fullWidth
          className={classes.field}
          onChange={(e) => setBody(e.target.value)}
        />
      </DialogContent>
      <DialogActions style={{ marginTop: '10px' }}>
        <Button
          onClick={handleSendNow}
          disabled={!reciever}
          className={classes.btn}
          color="primary"
          variant="contained"
        >
          Send Now
        </Button>
      </DialogActions>
    </ScrDialog>
  );
};

SendForApprovalDialog.propTypes = {
  toClient: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

SendForApprovalDialog.defaultProps = {
  toClient: false,
};

export default SendForApprovalDialog;
