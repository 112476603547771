import React from 'react';

import MenuPageBase, { Menu, MenuItem } from '../MenuPageBase';
import NoMatch from '../../router/NoMatch';
import SparkAndSuContent from '../../scrunch-university/SparkAndSuContent';
import { useApi, useRoute, useAuth } from '../../../hooks';

const SparkPage = () => {
  const [route, setRoute] = useRoute();
  const auth = useAuth();
  // const [activeIndex, setActiveIndex] = useState(0);
  const [{ data, isFetching }] = useApi({
    method: 'get',
    url: '/spark-ffp/',
  });
  //
  if (!auth.hasPermission('spark_ffp.view')) {
    return (
      <NoMatch default />
    );
  }
  //
  const moduleRoute = (index) => {
    //
    if ([0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(index)) {
      setRoute({ pathname: `/spark-ffp/module/${index}/lesson/1` });
    } else {
      setRoute({ pathname: `/spark-ffp/module/${index}` });
    }
  };

  return (
    <MenuPageBase
      menu={(
        <Menu>
          {data
            && data.children.map((it, index) => (
              <MenuItem
                Numbered={index === 0 ? '-' : index}
                key={`${it.title}${String(index)}`}
                label={it.title}
                onClick={() => moduleRoute(index)}
                selected={route.pathname === `/spark-ffp/module/${index + 1}`}
              />
            ))}
        </Menu>
      )}
      showLoadingBar={isFetching}
      title={data ? data.title : ''}
    >
      {data && <SparkAndSuContent content={data} />}
    </MenuPageBase>
  );
};

export default SparkPage;
