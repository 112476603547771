import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { colors } from '../definitions';
import { previewStyles } from '../form/styles';

const useStyles = makeStyles(() => ({
  highlighted: {
    border: `2px solid ${colors.PRIMARY}`,
  },
  rootBg: {
    backgroundColor: colors.BG_LIGHT,
    display: 'flex',
    height: 250,
    position: 'relative',
    '@media (max-width: 1280px)': {
      height: 220,
    },
    // marginTop: 20,
  },
  colBg: {
    width: 100,
    height: '100%',
    backgroundColor: colors.BG_DARK,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  root: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    // marginTop: 20,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 15,
  },
  main: {
    flex: 1,
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    padding: 5,
  },
  blockRoot: {
    flex: '1 1',
    maxWidth: '43%',
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  imgWrapper: {
    width: '100%',
    height: 80,
    marginBottom: 5,
    '@media (max-width: 1280px)': {
      height: 60,
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  padding: {
    padding: 5,
    '@media (max-width: 1280px)': {
      padding: 2,
    },
  },
}));

const Collaboration = ({
  collabImg,
  brandName,
  collabDesc,
  highlight,
}) => {
  const classes = useStyles();
  const textStyles = previewStyles();

  return (
    <div className={`${classes.blockRoot} ${highlight ? classes.highlighted : ''}`}>
      <div className={classes.imgWrapper}>
        <img src={collabImg} className={classes.image} alt="Background for Collaboration" />
      </div>
      <div className={textStyles.h4} style={{ marginBottom: 5 }}>
        {brandName}
      </div>
      <div className={textStyles.body} style={{ whiteSpace: 'pre-wrap' }}>
        {collabDesc}
      </div>
    </div>
  );
};

Collaboration.propTypes = {
  collabImg: PropTypes.string,
  brandName: PropTypes.string,
  collabDesc: PropTypes.string,
  highlight: PropTypes.bool,
};

Collaboration.defaultProps = {
  collabImg: '',
  brandName: '',
  collabDesc: '',
  highlight: false,
};

const Page6Preview = ({ finalData, highlighted }) => {
  const classes = useStyles();
  const textStyles = previewStyles();

  return (
    <div className={classes.rootBg} id="page-6-preview">
      <div className={classes.colBg} />
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={textStyles.h5}>Brand Partnerships &amp; Collaborations</div>
          <div className={textStyles.body}>
            {finalData.personalDetails.firstName}
            &nbsp;
            {finalData.personalDetails.lastName}
          </div>
        </div>
        <div className={classes.main}>
          {finalData.instagramAnalytics.collaborations.map((collab, index) => (
            <Collaboration
              collabImg={collab.collabImg}
              brandName={collab.brandName}
              collabDesc={collab.collabDesc}
              highlight={highlighted === `instagramAnalytics.collaboration${index}`}
              key={collab.id}
            />
          ))}
        </div>
        <div className={classes.header}>
          <div className={textStyles.body}>{finalData.personalDetails.websiteUrl}</div>
          <div className={textStyles.body}>{finalData.personalDetails.instagramHandle}</div>
        </div>
      </div>
    </div>
  );
};

Page6Preview.propTypes = {
  finalData: PropTypes.shape().isRequired,
  highlighted: PropTypes.string,
};

Page6Preview.defaultProps = {
  highlighted: '',
};

export default Page6Preview;
