import _ from 'lodash';

// Cost per post, broken down by network. The key in each network is the
// minimum number of followers needed for the cost value
const costPerPostMatrix = {
  facebook: [
    { minFollowers: 0, cost: '$250' },
    { minFollowers: 10000, cost: '$500' },
    { minFollowers: 50000, cost: '$1,350' },
    { minFollowers: 100000, cost: '$2,000' },
    { minFollowers: 300000, cost: '$3,000' },
    { minFollowers: 500000, cost: '$4,000' },
    { minFollowers: 1000000, cost: '$5,000+' },
  ],
  instagram: [
    { minFollowers: 0, cost: '$50-100' },
    { minFollowers: 10000, cost: '$100-500' },
    { minFollowers: 50000, cost: '$500-1000' },
    { minFollowers: 100000, cost: '$1,500+' },
    { minFollowers: 300000, cost: '$2,500+' },
    { minFollowers: 500000, cost: '$5,000+' },
    { minFollowers: 1000000, cost: '$10,000+' },
  ],
  youtube: [
    { minFollowers: 0, cost: '$300' },
    { minFollowers: 10000, cost: '$2,000+' },
    { minFollowers: 50000, cost: '$3,000+' },
    { minFollowers: 100000, cost: '$4,000+' },
    { minFollowers: 300000, cost: '$5,000+' },
    { minFollowers: 500000, cost: '$10,000+' },
    { minFollowers: 1000000, cost: '$20,000+' },
  ],
};

const getAvatarAlt = (name) => {
  if (!name) {
    return null;
  }
  return name
    .split(' ')
    .map((it) => (it ? it[0] : it))
    .filter((it) => Boolean(it))
    .join('')
    .toUpperCase()
    .substring(0, 3);
};

const getGenderLabel = (gender) => {
  switch (gender) {
    case 'm':
      return 'Male';
    case 'f':
      return 'Female';
    default:
      return null;
  }
};

const getCostPerPost = (network, numFollowers) => {
  if (costPerPostMatrix[network]) {
    const filteredCostsPerPost = costPerPostMatrix[network].filter((it) => it.minFollowers <= numFollowers);
    const costPerPost = _.maxBy(filteredCostsPerPost, (it) => it.minFollowers);
    return costPerPost.cost;
  }
  return null;
};

const getValue = (data, network, key) => {
  if (network === 'merge') {
    return _.get(data, key);
  }
  const match = data.network_profiles.find(
    (it) => (!network || network === it.network) && _.get(it, key),
  );
  return match ? _.get(match, key) : null;
};

const Profile = (data) => {
  const networkProfile = (network) => data.network_profiles.find((it) => (!network || it.network === network));
  const networkId = (network) => data.network_id.find((it) => (!network || it.startsWith(network)));
  const networkHandle = (network) => data.network_handle.find((it) => (!network || it.startsWith(network)));

  const id = (network) => getValue(data, network, 'id');
  const handle = (network) => getValue(data, network, 'handle');
  const fullName = (network) => getValue(data, network, 'full_name');
  const biography = (network) => getValue(data, network, 'biography');
  const profilePicture = (network) => getValue(data, network, 'profile_picture');
  const emails = (network) => getValue(data, network, 'emails');
  const socialReach = (network) => getValue(data, network, 'stats.social_reach');
  const engagement = (network) => getValue(data, network, 'stats.engagement');
  const engagementRate = (network) => getValue(data, network, 'stats.engagement_rate');

  const age = () => getValue(data, 'merge', 'inferred.age.majority[0]');
  const gender = () => getValue(data, 'merge', 'inferred.gender.majority[0]');
  const ethnicity = () => getValue(data, 'merge', 'inferred.ethnicity.majority[0]');
  const locationCountry = () => getValue(
    data,
    'merge',
    'inferred.location.country.majority[0].label',
  );
  const topics = () => getValue(data, 'merge', 'inferred.topic.majority');

  const content = () => getValue(data, 'merge', 'content');

  const audience = () => getValue(data, 'merge', 'audience');
  const audienceRequest = () => getValue(data, 'merge', 'audience.request');

  const audienceAge = () => getValue(data, 'merge', 'audience.age.majority[0]');
  const audienceGender = () => getValue(data, 'merge', 'audience.gender.majority[0]');
  const audienceEthnicity = () => getValue(data, 'merge', 'audience.ethnicity.majority[0]');
  const audienceHashtags = () => getValue(data, 'merge', 'audience.hashtag.majority');
  const audienceLocationCountry = () => getValue(
    data,
    'merge',
    'audience.location.country.majority[0].label',
  );
  const audienceMentions = () => getValue(data, 'merge', 'audience.mention.majority');
  const audienceTopics = () => getValue(data, 'merge', 'audience.topic.majority');

  return {
    data,
    networkProfile,
    networkId,
    networkHandle,
    id,
    handle,
    fullName,
    biography,
    profilePicture,
    emails,
    socialReach,
    engagement,
    engagementRate,
    content,

    age,
    gender,
    ethnicity,
    locationCountry,
    topics,

    audience,
    audienceRequest,
    audienceAge,
    audienceGender,
    audienceEthnicity,
    audienceHashtags,
    audienceLocationCountry,
    audienceMentions,
    audienceTopics,
  };
};

export default Profile;

export {
  getAvatarAlt,
  getCostPerPost,
  getGenderLabel,
};
