import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import AuthPageBase from './AuthPageBase';
import { PasswordTextField } from '../../common';
import { useApi, useRoute } from '../../../hooks';
import API from '../../../utils/api';

const useStyles = makeStyles((theme) => ({
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  field: {
    width: '100%',
    margin: theme.spacing(1),
  },
  button: {
    width: '100%',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
  },
  footer: {
    paddingTop: theme.spacing(3),
    padding: theme.spacing(1),
    textAlign: 'center',
  },
}));

const SignUp = ({ verificationId }) => {
  const [route, setRoute] = useRoute();
  const [{ response: data, isFetching: dataFetching }, setRequest] = useApi();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [agencyRole, setAgencyRole] = useState('');
  const [brandProfileAccess, setBrandProfileAccess] = useState('');
  const [accountId, setAccountId] = useState('');
  const classes = useStyles();

  const getMemberInfo = useCallback(() => {
    setRequest({
      method: 'post',
      url: '/auth/employee-invitation/',
      data: {
        invitation_key: verificationId,
      },
    });
  }, [setRequest, verificationId]);

  useEffect(() => {
    getMemberInfo();
  }, [verificationId, getMemberInfo]);

  useEffect(() => {
    if (data && data.status === 200) {
      setEmail(data.data.data.email);
      setFirstName(data.data.data.first_name);
      setLastName(data.data.data.last_name);
      setAgencyRole(data.data.data.agency_role);
      setBrandProfileAccess(data.data.data.brand_profiles_access);
      setAccountId(data.data.data.account_id);
      // setRoute({ pathname: route.params.next || '/' });
    }
  }, [data, route, setRoute]);

  async function handleSubmit(e) {
    e.preventDefault();

    setPasswordError(
      password.length < 6 ? 'Password must be at least 6 characters long.' : '',
    );

    if (password.length >= 6) {
      const response = await API({
        method: 'post',
        url: '/auth/employee-invitation/signup/',
        data: {
          email,
          password,
          first_name: firstName,
          last_name: lastName,
          agency_role: agencyRole,
          brand_profiles_access: brandProfileAccess,
          account_id: accountId,
        },
      });

      if (response && response.status === 200) {
        setRoute({ pathname: route.params.next || '/' });
      }
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.row}>
        <TextField
          className={classes.field}
          type="email"
          label="Email"
          value={email}
          InputProps={{
            readOnly: true,
          }}
        />
      </div>
      <div className={classes.row}>
        <TextField
          className={classes.field}
          type="text"
          label="First name"
          value={firstName}
          InputProps={{
            readOnly: true,
          }}
        />
      </div>
      <div className={classes.row}>
        <TextField
          className={classes.field}
          type="text"
          label="Last name"
          value={lastName}
          InputProps={{
            readOnly: true,
          }}
        />
      </div>
      <div className={classes.row}>
        <PasswordTextField
          className={classes.field}
          label="Password"
          required
          error={Boolean(passwordError)}
          helperText={
              passwordError || 'Must be at least 6 characters long.'
            }
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <Button
        className={classes.button}
        disabled={dataFetching}
        type="submit"
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        Sign Up
      </Button>
    </form>
  );
};

SignUp.propTypes = {
  verificationId: PropTypes.string,
};

SignUp.defaultProps = {
  verificationId: null,
};

const MemberSignup = ({ verificationId }) => {
  const [{ isFetching }] = useApi();

  return (
    <AuthPageBase
      headerTitle="World's #1 Influencer Marketing Platform"
      showLoadingBar={isFetching}
      title="Sign in"
    >
      <SignUp verificationId={verificationId} />

    </AuthPageBase>
  );
};

MemberSignup.propTypes = {
  verificationId: PropTypes.string,
};

MemberSignup.defaultProps = {
  verificationId: null,
};

export default MemberSignup;
