import React, { useState } from 'react';

import { Dialog } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

import PageBase from '../PageBase';
import ReportDetails from '../../campaign-report/ReportDetails';
import GeneratedReport from '../../campaign-report/GeneratedReport';
import { useApi, useAuth } from '../../../hooks';
import NoReportAccess from '../../campaign-report/NoReportAccess';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '42.5% 55%',
    gridColumnGap: 25,
    overflowX: 'hidden',
    padding: '40px 0 0 20px',
    '&>div': {
      height: '100%',
    },
  },
  noReportAccess: {
    marginTop: 50,
    marginBottom: 50,
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
}));

const CampaignReport = () => {
  const classes = useStyles();

  const auth = useAuth();
  const reportPerm = auth.hasPermission('report.create');

  const [{ response, isFetching }, postCampaignReportRequest] = useApi();
  const [reportPermBeforeSubmission, setReportPermBeforeSubmission] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(!reportPerm);

  const handleSubmit = (postData) => {
    if (reportPerm) {
      postCampaignReportRequest({
        method: 'post',
        url: `/account/${auth.account.id}/report/`,
        data: postData,
        timeout: 5 * 60 * 1000,
      });
    } else {
      setReportPermBeforeSubmission(false);
    }
  };

  return (
    <PageBase isFetching={isFetching} showLoadingBar={isFetching} title="Campaign Reports">
      <div className={classes.root}>
        <div>
          <ReportDetails disabled={isFetching} handleSubmit={handleSubmit} />
        </div>
        <div style={{ overflowY: 'auto', paddingRight: 20 }}>
          {reportPermBeforeSubmission && (
            <GeneratedReport data={response && response.data ? response.data.data : null} />
          )}
          {!reportPermBeforeSubmission && (
            <div className={classes.noReportAccess}>
              <NoReportAccess />
            </div>
          )}
        </div>
      </div>

      {!reportPerm && (
        <Dialog
          open={isDialogOpen}
          onBackdropClick={() => setIsDialogOpen(false)}
          PaperProps={{ style: { padding: 0, width: '45%' } }}
        >
          <IconButton aria-label="close" className={classes.closeButton} onClick={() => setIsDialogOpen(false)}>
            <CloseIcon />
          </IconButton>
          <NoReportAccess />
        </Dialog>
      )}
    </PageBase>
  );
};

export default CampaignReport;
