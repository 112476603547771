import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import WarningIcon from '@material-ui/icons/Warning';
import RefreshIcon from '@material-ui/icons/Refresh';
import CloseIcon from '@material-ui/icons/Close';

import profiles from './profiles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
  },
  infoWrapper: {
    height: '100%',
    border: `1px solid ${theme.colors.system.grey3}`,
    backgroundColor: theme.colors.system.grey1,
    borderRadius: 4,
    padding: 20,
  },
  instructions: {
    '& > li': {
      marginBottom: 15,
    },
    paddingInlineStart: 30,
  },
  mainContent: {
    paddingRight: 30,
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    paddingBottom: 40,
    borderBottom: `1px solid ${theme.colors.system.grey3}`,
    marginTop: 20,
  },
  heading: {
    ...theme.textStyles.bodyLarge,
  },
  logoWrapper: {
    border: `1px solid ${theme.colors.system.grey3}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 20,
  },
  headerSubText: {
    margin: '10px 0 20px',
  },
  disconnectBtn: {
    textDecoration: 'underline',
  },
  cont: {
    paddingTop: 40,
    marginBottom: 20,
  },
  codeWrapper: {
    width: '100%',
    border: `2px dashed ${theme.colors.system.grey5}`,
    borderRadius: 4,
    backgroundColor: theme.colors.system.grey1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '15px 0',
    padding: '5px 12px',
  },
  subText: {
    color: theme.colors.system.grey7,
    marginBottom: 20,
  },
  link: {
    width: '100%',
    border: `2px solid ${theme.colors.system.grey3}`,
    borderRadius: 4,
    backgroundColor: theme.colors.system.grey1,
    padding: '10px 12px',
    margin: '15px 0',
    '& > a': {
      textDecoration: 'none',
    },
  },
  verifiedSuccess: {
    padding: 7,
    backgroundColor: theme.colors.success.light,
    borderRadius: 4,
    color: theme.colors.success.main,
    display: 'flex',
    alignItems: 'center',
  },
  successCheck: {
    color: theme.colors.success.main,
    fontSize: 14,
    marginRight: 8,
  },
  verifiedFailed: {
    padding: 7,
    backgroundColor: theme.colors.warning.light,
    borderRadius: 4,
    color: theme.colors.warning.main,
    display: 'flex',
    alignItems: 'center',
  },
  failedCheck: {
    color: theme.colors.warning.main,
    fontSize: 14,
    marginRight: 8,
  },
  paper: {
    minWidth: 800,
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  deleteForever: {
    minWidth: 250,
    minHeight: 40,
    backgroundColor: theme.colors.danger.main,
  },
  dialogSubtitle: {
    color: theme.colors.system.grey7,
    textAlign: 'center',
    width: '80%',
    margin: '15px auto 0',
  },
}));

const VerifiedSuccess = ({ network }) => {
  const classes = useStyles();

  return (
    <div className={classes.verifiedSuccess}>
      <CheckCircleRoundedIcon className={classes.successCheck} />
      <Typography variant="body2" component="span">
        Your&nbsp;
        {network}
        &nbsp;profile was successfully verified.
      </Typography>
    </div>
  );
};

VerifiedSuccess.propTypes = {
  network: PropTypes.string.isRequired,
};

const VerificationFailure = ({ network }) => {
  const classes = useStyles();

  return (
    <div className={classes.verifiedFailed}>
      <WarningIcon className={classes.failedCheck} />
      <Typography variant="body2" component="span">
        We were unable to verify your&nbsp;
        {network}
        &nbsp;profile. Please try again.
      </Typography>
    </div>
  );
};

VerificationFailure.propTypes = {
  network: PropTypes.string.isRequired,
};

const VerifiedProfileContent = ({
  profileData,
  verifyProfile,
  disconnectAccount,
  restartSetup,
  // editProfile,
  isFetching,
  claimType,
}) => {
  const classes = useStyles();

  // state variables
  const [codeCopied, setCodeCopied] = useState(false);
  const [showDisconnectDialog, setShowDisconnectDialog] = useState(false);

  // other variables
  const networkTypeData = profiles.find((n) => n.title.toUpperCase() === profileData.network_type.toUpperCase());

  // functions
  const getNetworkLabel = () => {
    const netId = profileData.network_id.split(':');
    return netId.length === 3 ? netId[2] : profileData.network_id;
  };

  return (
    <div className={classes.root}>
      <div className={classes.mainContent}>
        {claimType === 'verify' && profileData.is_approved !== 0 && !isFetching && (
          <VerifiedSuccess network={profileData.network_type} />
        )}

        {claimType === 'verify' && profileData.is_approved === 0 && !isFetching && (
          <VerificationFailure network={profileData.network_type} />
        )}

        <div className={classes.header}>
          <div className={classes.logoWrapper}>
            <img src={networkTypeData.logo} alt={networkTypeData.title} style={{ width: 45, height: 45 }} />
          </div>

          <div style={{ flex: 1 }}>
            <Typography variant="h5" component="h5" className={classes.heading}>
              {networkTypeData.title}
              &nbsp;Profile
            </Typography>
            {claimType === 'verify' && profileData.is_approved !== 0 && (
              <Typography variant="body2" component="p" className={classes.headerSubText}>
                You have successfully verified your&nbsp;
                {networkTypeData.title}
                &nbsp;account. You can now make changes to your&nbsp;
                {networkTypeData.title}
                &nbsp;social profile
              </Typography>
            )}

            {claimType === 'verify' && profileData.is_approved === 0 && (
              <Typography variant="body2" component="p" className={classes.headerSubText}>
                We were unable to verify your&nbsp;
                {networkTypeData.title}
                &nbsp;account. To claim your profile try the verification process again.
              </Typography>
            )}

            {claimType === 'claim' && (
              <Typography variant="body2" component="p" className={classes.headerSubText}>
                Enter your&nbsp;
                {networkTypeData.title}
                &nbsp;profile handle to claim your profile &amp; start your verification process.
              </Typography>
            )}

            <TextField
              variant="outlined"
              value={getNetworkLabel()}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography variant="body2" component="span"><b>handle:</b></Typography>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {claimType === 'verify' && profileData.is_approved !== 0 && (
                      <CheckCircleRoundedIcon className={classes.successCheck} />
                    )}
                  </InputAdornment>
                ),
                style: {
                  fontSize: 12,
                },
              }}
              fullWidth
              size="small"
              disabled
            />
          </div>

          {/* {claimType === 'verify' && profileData.is_approved !== 0 && (
            <Button
              variant="contained"
              onClick={() => editProfile()}
              color="primary"
              style={{ marginTop: 20, marginRight: 20 }}
              size="small"
            >
              Edit your profile
            </Button>
          )} */}

          {claimType === 'verify' && profileData.is_approved !== 0 && (
            <div>
              <Button
                onClick={() => setShowDisconnectDialog(true)}
                style={{ marginTop: 20, marginRight: 20, padding: '7px 10px' }}
                size="small"
                className={classes.disconnectBtn}
              >
                Disconnect Account
              </Button>
            </div>
          )}
        </div>

        {profileData.is_approved === 0 && (
          <div className={classes.cont}>
            <Typography
              variant="h5"
              component="h5"
              className={classes.heading}
            >
              Your Custom Verification Code
            </Typography>

            <div className={classes.codeWrapper}>
              <Typography variant="body2">{profileData.authorization_code}</Typography>
              {codeCopied ? (
                <Button
                  color="primary"
                  size="small"
                  onClick={() => null}
                >
                  Code Copied!
                </Button>
              ) : (
                <Button
                  color="primary"
                  size="small"
                  startIcon={(
                    <FileCopyIcon fontSize="small" color="primary" />
                  )}
                  onClick={() => {
                    navigator.clipboard.writeText(profileData.authorization_code);
                    setCodeCopied(true);
                  }}
                >
                  Copy Code
                </Button>
              )}
            </div>

            <Typography variant="body2" className={classes.subText}>
              Comment the above code as a comment in the&nbsp;
              {networkTypeData.title}
              &nbsp;link given bellow.
            </Typography>

            <Typography
              variant="h5"
              component="h5"
              className={classes.heading}
            >
              Link To Comment Code
            </Typography>

            <div className={classes.link}>
              <a href="https://www.instagram.com/p/CJzbLX4A_Cm/" target="_blank" rel="noopener noreferrer">
                <Typography variant="body2" color="primary">
                  https://www.instagram.com/p/CJzbLX4A_Cm/
                </Typography>
              </a>
            </div>

            <div style={{ display: 'flex' }}>
              <Button
                variant="contained"
                onClick={() => verifyProfile()}
                color="primary"
                size="small"
                style={{ marginRight: 20 }}
              >
                {claimType === 'claim' ? 'Verify Now' : 'Verify Again'}
              </Button>

              <Button
                size="small"
                startIcon={(
                  <RefreshIcon fontSize="small" />
                )}
                onClick={() => restartSetup(getNetworkLabel(), profileData.network_type)}
              >
                Restart Setup
              </Button>
            </div>
          </div>
        )}
      </div>

      <div className={classes.infoWrapper}>
        <Typography variant="body2" component="p">
          Verification Steps
        </Typography>
        <ul className={classes.instructions}>
          <Typography variant="body2" component="li">
            Enter your&nbsp;
            {networkTypeData.title}
            &nbsp;handle without the @ symbol.
          </Typography>
          <Typography variant="body2" component="li">
            Press “Get Code”
          </Typography>
          <Typography variant="body2" component="li">
            Highlight the code and copy it to your clipboard.
          </Typography>
          <Typography variant="body2" component="li">
            Go to the&nbsp;
            {networkTypeData.title}
            &nbsp;link mentioned, paste the code as a comment.
          </Typography>
          <Typography variant="body2" component="li">
            After you’re done, return to this page and click Verify Connection.
          </Typography>
        </ul>
      </div>

      <Dialog
        open={showDisconnectDialog}
        onBackdropClick={() => setShowDisconnectDialog(false)}
        classes={{ paper: classes.paper }}
      >
        <IconButton aria-label="close" className={classes.closeButton} onClick={() => setShowDisconnectDialog(false)}>
          <CloseIcon />
        </IconButton>

        <DialogTitle>
          <Typography
            variant="h5"
            component="h5"
            style={{ marginBottom: 10 }}
          >
            Disconnect&nbsp;
            {networkTypeData.title}
            &nbsp;Account
          </Typography>
        </DialogTitle>

        <DialogContent>
          <TextField
            label={`${networkTypeData.title} handle:`}
            value={getNetworkLabel()}
            fullWidth
            disabled
          />
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setShowDisconnectDialog(false);
              disconnectAccount(getNetworkLabel(), profileData.network_type);
            }}
            variant="contained"
            style={{ minWidth: 250, minHeight: 40 }}
            size="small"
            className={classes.deleteForever}
          >
            Disconnect Account Now
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

VerifiedProfileContent.propTypes = {
  profileData: PropTypes.shape().isRequired,
  verifyProfile: PropTypes.func.isRequired,
  disconnectAccount: PropTypes.func.isRequired,
  restartSetup: PropTypes.func.isRequired,
  // editProfile: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  claimType: PropTypes.string.isRequired,
};

export default VerifiedProfileContent;
