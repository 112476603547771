import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import { useApi, useAuth, useNotification } from '../../hooks';

const useStyles = makeStyles(() => ({
  dialogContent: {
    maxWidth: 400,
  },
}));

const ListExportDialog = ({ list, onClose }) => {
  const [{ status, isFetching }, getListExportEmailRequest] = useApi();
  const auth = useAuth();
  const { createNotification } = useNotification();
  const classes = useStyles();

  useEffect(() => {
    if (status) {
      if (status === 202) {
        createNotification('success', 'List export requested!');
      }
      onClose();
    }
  }, [createNotification, onClose, status]);

  const handleClick = (event) => {
    event.preventDefault();
    getListExportEmailRequest({
      method: 'get',
      url: `/account/${auth.account.id}/list/${list.id}/export/email/`,
    });
  };

  return (
    <Dialog
      onClose={onClose}
      open
    >
      <DialogTitle>Export list</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body1">
          Get your list as a spreadsheet! Hit the button below and we&apos;ll email it to
          {' '}
          <strong>{auth.user.email}</strong>
          {' '}
          in a few minutes.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={isFetching}
          onClick={handleClick}
          variant="contained"
        >
          Email me
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ListExportDialog.propTypes = {
  list: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
};

const ListExportButton = ({ list, profiles }) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const auth = useAuth();
  const [{ response }, getListExport] = useApi();

  const handleExportClick = () => {
    if (list.items.length < 100) {
      getListExport({
        method: 'get',
        url: `/account/${auth.account.id}/list/${list.id}/export/download/`,
      });
    } else {
      setDialogIsOpen(true);
    }
  };

  useEffect(() => {
    if (response && response.status === 200) {
      if (typeof document !== 'undefined') {
        const downloadLink = document.createElement('a');
        const csv = response.data;
        const blob = new Blob(['\ufeff', csv]);
        const url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = `${list.name}.csv`;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    }
  }, [response, list]);

  return (
    <span>
      <Tooltip title="Export List as CSV">
        <span>
          <IconButton
            disabled={!profiles || profiles.length === 0}
            onClick={handleExportClick}
          >
            <SaveAltIcon />
          </IconButton>
        </span>
      </Tooltip>
      {dialogIsOpen && (
        <ListExportDialog
          list={list}
          onClose={() => setDialogIsOpen(false)}
        />
      )}
    </span>
  );
};

ListExportButton.propTypes = {
  list: PropTypes.shape().isRequired,
  profiles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ListExportButton;
