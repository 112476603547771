import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  ButtonBase,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useRoute, useAuth } from '../../../hooks';
import popularSections, { locationImages } from './popularSections';

const useStyles = makeStyles((theme) => ({
  popularItems: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: '2',
    '@media (min-width:1920px)': {
      flex: '2.5',
    },
  },
  emptyContainer: {
    width: '100%',
    alignItems: 'center',
    '@media(min-width: 1920px)': {
      padding: '12px 0 0',
    },
    '@media(max-width: 1919px)': {
      paddingBottom: 40,
    },
    '&:last-child': {
      paddingBottom: '0 !important',
    },
  },
  searchOptionTitleContainer: {
    marginBottom: 30,
    '@media(max-width: 1919px)': {
      marginBottom: 10,
    },
  },
  searchOptionTitle: {
    marginRight: 8,
    '@media(max-width: 1919px)': {
      ...theme.textStyles.bodyLarge,
    },
  },
  searchOptionSubtitle: {
    color: theme.colors.system.grey6,
  },
  searchOptions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  searchOptionButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    '&>img': {
      width: '11vw',
      height: '11vw',
      '@media (min-width: 1920px)': {
        width: '12.8vw',
        height: '12.8vw',
      },
      '@media (min-width: 2560px)': {
        width: '13.8vw',
        height: '13.8vw',
      },
      border: '2px solid #00000000',
      borderRadius: 8,
      marginBottom: 8,
    },
    '&>img:hover': {
      border: `2px solid ${theme.colors.primary.main}`,
    },
    '&>p': {
      fontWeight: 'bold',
    },
    '&:last-child': {
      display: 'flex',
      marginRight: 0,
      borderRight: 0,
    },
  },
  discoverImages: {
    '&>img': {
      width: '12vw',
      height: '12vw',
      '@media (min-width: 1920px)': {
        width: '14vw',
        height: '14vw',
      },
      '@media (min-width: 2560px)': {
        width: '15vw',
        height: '15vw',
      },
    },
  },
  emptyImage: {
    objectFit: 'cover',
  },
  button: {
    float: 'right',
  },
}));

const Title = (props) => {
  const classes = useStyles();

  const { showTitle, title } = props;

  if (showTitle) {
    return (
      <div className={classes.searchOptionTitleContainer}>
        <Typography
          className={classes.searchOptionTitle}
          component="span"
          variant="h5"
        >
          { title }
        </Typography>
        <Typography
          className={classes.searchOptionSubtitle}
          component="span"
          variant="body2"
        >
          Based on current Scrunch users
        </Typography>
      </div>
    );
  }
  return false;
};

Title.propTypes = {
  showTitle: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

Title.defaultProps = {
  title: '',
};

const PopularItems = (props) => {
  const [, setRoute] = useRoute();
  const auth = useAuth();
  const classes = useStyles();
  const { showTitle } = props;
  const [userTopics] = useState(auth.user.preferences.topics);
  const [userLocations] = useState(auth.user.preferences.locations);
  const noSearchKeywords = {
    locations: [
      {
        name: 'Australia',
        location: 2077456,
        image: 'australia-australia',
      },
      {
        name: 'Brisbane',
        location: 2174003,
        image: 'brisbane-australia',
      },
      {
        name: 'Sydney',
        location: 2147714,
        image: 'sydney-australia',
      },
      {
        name: 'Japan',
        location: 1861060,
        image: 'japan-japan',
      },
    ],
    topics: [
      {
        name: 'Home Interior',
        topic: 'Home Interior',
        image: 'home interior',
      },
      {
        name: 'Food & Drink',
        topic: 'Food & Drink',
        image: 'food & drink',
      },
      {
        name: 'Fashion',
        topic: 'Fashion',
        image: 'fashion',
      },
      {
        name: 'Beauty',
        topic: 'Beauty',
        image: 'beauty',
      },
    ],
  };
  const [topics] = useState(showTitle ? userTopics : noSearchKeywords.topics);
  const [locations] = useState(showTitle ? userLocations : noSearchKeywords.locations);

  const showLocationImage = (imageString) => {
    if (locationImages.includes(`${imageString}.jpg`)) {
      // eslint-disable-next-line
      return require(`../../../assets/images/locations/${imageString}.jpg`);
    }
    // eslint-disable-next-line
    return require(`../../../assets/images/locations/default-${Math.floor(Math.random() * (9 - 1) + 1)}.jpg`);
  };

  return (
    <div className={classes.popularItems}>
      {/* Topics */}
      <div className={classes.emptyContainer} style={{ paddingBottom: showTitle ? 20 : 0 }}>
        <div className={classes.popularTopics}>
          <Title showTitle={showTitle} title={popularSections[0].title} />
          <div className={classes.searchOptions}>
            {topics.map((it) => (
              <ButtonBase
                key={`button_${it.name}`}
                className={`${classes.searchOptionButton} ${showTitle ? classes.discoverImages : ''}`}
                onClick={() => setRoute({ pathname: '/discover', params: { topic: it.topic.toLowerCase() } })}
              >
                <img
                  alt={it.name && it.name.toLowerCase()}
                  // eslint-disable-next-line
                  src={require(`../../../assets/images/topics/${it.image}.jpg`)}
                  className={classes.emptyImage}
                />
                <Typography variant="body2">{it.name}</Typography>
              </ButtonBase>
            ))}
          </div>
        </div>
      </div>

      {/* Locations */}
      <div className={classes.emptyContainer} style={{ paddingBottom: showTitle ? 20 : 0 }}>
        <div className={classes.popularTopics}>
          <Title showTitle={showTitle} title={popularSections[1].title} />
          <div className={classes.searchOptions}>
            {locations.map((it) => (
              <ButtonBase
                key={`button_${it.name}`}
                className={`${classes.searchOptionButton} ${showTitle ? classes.discoverImages : ''}`}
                onClick={() => setRoute({ pathname: '/discover', params: { location: it.location } })}
              >
                <img
                  alt={it.name && it.name.toLowerCase()}
                  src={showLocationImage(it.image)}
                  className={classes.emptyImage}
                />
                <Typography variant="body2">{it.name}</Typography>
              </ButtonBase>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

PopularItems.propTypes = {
  showTitle: PropTypes.bool.isRequired,
};

export default PopularItems;
