import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FilterBase from './FilterBase';
import Select from '../../common/Select';

const genders = [
  {
    name: 'Male',
    value: 'm',
  },
  {
    name: 'Female',
    value: 'f',
  },
];

const useStyles = makeStyles((theme) => ({
  inputs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  input: {
    width: 150,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  text: {
    width: 100,
    color: theme.palette.grey[600],
    textAlign: 'center',
  },
}));

const GenderFilter = (props) => {
  const { className, filterData, onApply } = props;
  const [activeText, setActiveText] = useState(null);
  const [gender, setGender] = useState('');
  const classes = useStyles();

  useEffect(() => {
    if (filterData.gender) {
      setActiveText(genders.find((it) => it.value === filterData.gender).name);
      setGender(filterData.gender);
    } else {
      setActiveText(null);
      setGender('');
    }
  }, [filterData]);

  const handleOnApply = () => {
    onApply({
      ...filterData,
      gender: gender || null,
    });
  };

  const handleOnClear = () => {
    onApply({ ...filterData, gender: null });
  };

  return (
    <FilterBase
      className={className}
      label="Gender"
      activeText={activeText}
      isActive={Boolean(activeText)}
      onApply={handleOnApply}
      onClear={handleOnClear}
    >
      <div className={classes.inputs}>
        <Select
          className={classes.input}
          label="Gender"
          value={gender}
          onChange={(e) => setGender(e.target.value !== '' ? e.target.value : null)}
        >
          {genders.map((it) => (
            <MenuItem key={it.value} value={it.value}>
              {it.name}
            </MenuItem>
          ))}
        </Select>
      </div>
    </FilterBase>
  );
};

GenderFilter.propTypes = {
  className: PropTypes.string,
  filterData: PropTypes.shape().isRequired,
  onApply: PropTypes.func.isRequired,
};

GenderFilter.defaultProps = {
  className: '',
};

export default GenderFilter;
