import React, {
  useEffect,
  useState,
  useRef,
} from 'react';

import PropTypes from 'prop-types';

import {
  Box,
  Button,
  IconButton,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import AccessInfoTooltip from '../common/AccessInfoTooltip';

import { useApi, useAuth } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    '&>:not(last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  switchRoot: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridTemplateRows: '1fr',
    gridTemplateAreas: `
      "info info"
    `,
    alignItems: 'center',
  },
  switchInput: {
    gridArea: 'input',
    paddingLeft: 0,
  },
  switchLabel: {
    gridArea: 'label',
  },
  switchInfo: {
    gridArea: 'info',
  },
  copyButton: {
    paddingRight: 14,
  },
  box: {
    backgroundColor: theme.colors.system.grey1,
  },
  paper: {
    minWidth: 700,
  },
  descText: {
    color: theme.colors.system.grey7,
    marginTop: 5,
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  button: {
    minWidth: 150,
    minHeight: 40,
    ...theme.textStyles.bodySmall,
  },
  delButton: {
    color: theme.colors.danger.main,
    marginLeft: '20px !important',
  },
  infoIcon: {
    float: 'right',
    paddingTop: '0.5rem',
    color: theme.colors.system.grey5,
  },
  privateCheck: {
    display: 'inline',
    padding: 'auto 0',
  },
  makePrivate: {
    paddingLeft: 0,
  },
}));

const IsPublicSwitch = ({ list, isPublic, setIsPublic }) => {
  const listPath = useRef(null);
  const copyToClipboard = () => {
    listPath.current.firstChild.select();
    document.execCommand('copy');
  };
  const classes = useStyles();

  return (
    <>
      <div className={classes.switchRoot}>
        <div className={classes.switchInfo}>
          <Box className={classes.box}>
            <Input
              ref={listPath}
              className={classes.inputField}
              label="Public List Link"
              fullWidth
              readOnly
              value={`https://www.scrunch.com/public/list/${list.id}`}
              endAdornment={(
                <InputAdornment position="end" color="primary">
                  <Button
                    className={classes.copyButton}
                    color="primary"
                    onClick={isPublic ? copyToClipboard : () => setIsPublic(!isPublic)}
                    variant="text"
                    size="small"
                  >
                    { isPublic ? 'Copy Link' : 'Make Link Public'}
                  </Button>
                </InputAdornment>
              )}
            />
          </Box>
          <Typography disabled variant="body2" className={classes.descText}>
            Public lists are accessible to anyone with the public list link
          </Typography>
        </div>
      </div>
    </>
  );
};

IsPublicSwitch.propTypes = {
  list: PropTypes.shape().isRequired,
  isPublic: PropTypes.bool.isRequired,
  setIsPublic: PropTypes.func.isRequired,
};

const ListSettingsDialog = ({
  list,
  onClose,
  onSuccess,
  onDelete,
}) => {
  const [name, setName] = useState(list.name || '');
  const [nameError, setNameError] = useState('');
  const [isPublic, setIsPublic] = useState(list.is_public || false);
  const [isPrivate, setIsPrivate] = useState(list.is_private || false);
  const classes = useStyles();
  const auth = useAuth();
  const [{ data, isFetching, status }, patchListRequest] = useApi();

  useEffect(() => {
    if (status === 200) {
      if (onSuccess) {
        onSuccess(data);
      }
      onClose();
    }
  }, [data, onClose, onSuccess, status]);

  const handleSubmit = () => {
    if (name.length < 1) {
      setNameError('Name cannot be empty');
      return;
    }
    if (name.length > 32) {
      setNameError('Name cannot be over 32 characters.');
      return;
    }
    patchListRequest({
      method: 'patch',
      url: `/account/${auth.account.id}/list/${list.id}/`,
      data: {
        name,
        is_public: isPublic,
        is_private: isPrivate,
      },
    });
  };

  const handleNameChange = (event) => {
    event.preventDefault();
    if (event.target.value.length < 64) {
      setName(event.target.value);
    }
  };

  return (
    <Dialog
      open
      onClose={onClose}
      scroll="paper"
      classes={{
        paper: classes.paper,
      }}
    >
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <DialogTitle>List settings</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography style={{ marginBottom: '8px' }}>List Name</Typography>
        <TextField
          error={Boolean(nameError)}
          helperText={nameError}
          onChange={handleNameChange}
          value={name}
          fullWidth
        />
        <div />
        <Typography style={{ marginBottom: '8px' }}>Public Link List</Typography>
        <IsPublicSwitch
          list={list}
          isPublic={isPublic}
          setIsPublic={setIsPublic}
        />

        <div>
          <Checkbox
            className={classes.makePrivate}
            onChange={(e) => setIsPublic(e.target.checked)}
            checked={isPublic}
          />
          <Typography className={classes.privateCheck} variant="body1">
            Anyone with the public list link can view the list
          </Typography>
        </div>

        {auth.account && (auth.hasPermission('account.add_employee'))
        && (
          <div>
            <Checkbox
              className={classes.makePrivate}
              onChange={(e) => setIsPrivate(e.target.checked)}
              checked={isPrivate}
            />
            <Typography className={classes.privateCheck} variant="body1">
              Hide this list from other agency managers
              <span className={classes.infoIcon}>
                <AccessInfoTooltip
                  title="What it means?"
                >
                  This option hides the list from other agency managers.
                  Administrators can still see the list.
                </AccessInfoTooltip>
              </span>
            </Typography>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={isFetching}
          onClick={handleSubmit}
          variant="contained"
          className={classes.button}
        >
          Save Settings
        </Button>
        {auth.hasPermission('list.delete') && (
          <Button
            color="primary"
            onClick={() => onDelete()}
            variant="outlined"
            startIcon={<DeleteIcon />}
            className={`${classes.button} ${classes.delButton}`}
          >
            Delete List
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

ListSettingsDialog.propTypes = {
  list: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
};

ListSettingsDialog.defaultProps = {
  onSuccess: null,
};

export default ListSettingsDialog;
