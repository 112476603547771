import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import HorizontalStackedChart from '../../chart/HorizontalStackedChart';
import { getGenderLabel } from '../../../utils/profile';
import { formatPercentage } from '../../../utils/number';

const useStyles = makeStyles((theme) => ({
  chart: {
    height: 'min-content',
    padding: theme.spacing(2),
    borderRadius: 5,
    backgroundColor: theme.colors.system.grey1,
  },
}));

const ProfileAudienceGender = ({ genderData }) => {
  const classes = useStyles();
  const chartData = genderData.map(({ key, probability }) => ({
    label: `${getGenderLabel(key)} (${formatPercentage(probability)})`,
    value: probability,
  }));
  return (
    <div className={classes.chart}>
      <HorizontalStackedChart
        data={chartData}
        title="Gender split"
      />
    </div>
  );
};

ProfileAudienceGender.propTypes = {
  genderData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default ProfileAudienceGender;
