import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { colors } from '../definitions';
import { previewStyles } from '../form/styles';

const useStyles = makeStyles((theme) => ({
  highlighted: {
    border: `2px solid ${theme.colors.primary.main}`,
  },
  root: {
    backgroundColor: colors.BG_LIGHT,
    display: 'flex',
    height: 260,
    marginTop: 20,
    '@media (max-width: 1280px)': {
      height: 230,
    },
  },
  col: {
    flex: 1,
    maxWidth: '50%',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  col2: {
    display: 'flex',
    flexDirection: 'column',
    padding: 15,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
  },
  container: {
    width: '125%',
    height: '96%',
    position: 'absolute',
    top: '2%',
    right: 0,
    backgroundColor: colors.SECONDARY,
    color: colors.BG_LIGHT,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  padding: {
    padding: 5,
    '@media (max-width: 1280px)': {
      padding: 2,
    },
  },
}));

const Page2Preview = ({
  highlighted,
  finalData,
}) => {
  const classes = useStyles();
  const textStyles = previewStyles();

  const data = finalData.personalDetails;
  const instaHandle = data.instagramHandle;
  const paragraphs = data.about.split(/\r?\n/);

  return (
    <div className={classes.root} id="page-2-preview">
      <div className={`${classes.col} ${highlighted === 'personalDetails.aboutImg' ? classes.highlighted : ''}`}>
        <img
          src={data.aboutImg}
          className={classes.image}
          alt="ackground for Page 2"
        />
      </div>
      <div className={`${classes.col} ${classes.col2}`}>
        <div className={classes.header}>
          <div className={`${textStyles.body} ${classes.padding}`}>
            {data.firstName}
            &nbsp;
            {data.lastName}
          </div>
        </div>
        <div className={classes.main}>
          <div className={classes.container}>
            <div className={`${textStyles.h2} ${classes.padding}`}>
              About&nbsp;
              {data.firstName}
            </div>
            <div
              className={`${highlighted === 'personalDetails.about' ? classes.highlighted : ''} ${classes.padding}`}
            >
              {paragraphs.map((para, index) => (
                <div
                  className={textStyles.body}
                  style={{ marginBottom: index !== paragraphs.length - 1 ? 5 : 0 }}
                  key={para.substring(0, 25)}
                >
                  {para}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={`${classes.header} ${classes.padding}`}>
          <div className={textStyles.body}>
            {instaHandle ? `@${instaHandle}` : ''}
          </div>
        </div>
      </div>
    </div>
  );
};

Page2Preview.propTypes = {
  highlighted: PropTypes.string,
  finalData: PropTypes.shape().isRequired,
};

Page2Preview.defaultProps = {
  highlighted: '',
};

export default Page2Preview;
