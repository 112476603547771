import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  IconButton,
  Link,
  Popover,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import { useAuth } from '../../hooks';
import EmailAccessDialog from './EmailAccessDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    padding: theme.spacing(1),
  },
}));

const MailToButton = ({ profile }) => {
  const [anchorEl, setAnchorEl] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const classes = useStyles();
  const emails = profile.emails('merge');

  const auth = useAuth();

  const handleClick = (e) => {
    if (auth.hasPermission('profile.view_contact')) {
      setAnchorEl(e.currentTarget);
    } else {
      setIsDialogOpen(true);
    }
  };

  return (
    <div>
      <IconButton
        onClick={handleClick}
        size="small"
      >
        <MailOutlineIcon fontSize="small" />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.root}>
          {!_.isEmpty(emails) && emails.map((email) => (
            <Link key={email} href={`mailto:${email}`}>{email}</Link>
          ))}
          {_.isEmpty(emails) && (
            <Typography color="textSecondary" variant="body1">
              No emails found for this user, try contacting them on their socials!
            </Typography>
          )}
        </div>
      </Popover>

      <EmailAccessDialog
        influencerName={profile ? profile.fullName() : ''}
        isDialogOpen={isDialogOpen}
        closeDialog={() => setIsDialogOpen(false)}
      />
    </div>
  );
};

MailToButton.propTypes = {
  profile: PropTypes.shape().isRequired,
};

export default MailToButton;
