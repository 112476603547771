import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import PopularItems from './PopularItems';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '50px 20px',
  },
  heading: {
    lineHeight: '100%',
    marginBottom: '5px',
  },
  container: {
    width: '100%',
    minHeight: '100%',
    padding: '50px 0',
    display: 'flex',
  },
  discoverCont: {
    flex: '2',
    '@media (min-width:1920px)': {
      flex: '2.5',
    },
    marginRight: 16,
    padding: '25px 10px',
    '@media (min-width: 1920px)': {
      padding: '25px 40px',
    },
    '& > p': {
      ...theme.textStyles.bodyLarge,
    },
    border: `1px solid ${theme.colors.system.grey3}`,
    borderRadius: '4px',
  },
  tipsCont: {
    flex: '1',
    marginLeft: 16,
    backgroundColor: theme.colors.system.grey1,
    padding: '25px 20px',
    '@media (min-width: 1920px)': {
      padding: '25px 30px',
    },
    '& > p': {
      marginBottom: '30px',
      ...theme.textStyles.bodyLarge,
    },
    border: `1px solid ${theme.colors.system.grey3}`,
  },
  searchTipsList: {
    '& > li': {
      '@media (max-width:1919px)': {
        ...theme.textStyles.bodySmall,
      },
      '&:not(:last-child)': {
        marginBottom: 20,
      },
    },
  },
  email: {
    color: theme.colors.primary.main,
  },
}));

const Discover = () => {
  const classes = useStyles();

  return (
    <div className={classes.discoverCont}>
      <Typography variant="body1" component="p" style={{ marginBottom: 20 }}>
        Don&apos;t feel like typing? Just choose from one of our most searched keywords&nbsp;
        <span role="img" aria-label="wink">😉</span>
      </Typography>

      <PopularItems showTitle={false} />
    </div>
  );
};

const SearchTips = () => {
  const classes = useStyles();

  return (
    <div className={classes.tipsCont}>
      <Typography variant="body1" component="p">
        Search Tips
      </Typography>

      <ul className={classes.searchTipsList}>
        <Typography variant="body2" component="li">Double check your spelling for typos.</Typography>
        <Typography variant="body2" component="li">User general term(s) or fewer keywords.</Typography>
        <Typography variant="body2" component="li">Try searching for an item that is less specific and refine results.</Typography>
        <Typography variant="body2" component="li">If you are looking for a specific influencer, try entering the username as on the social channel.</Typography>
      </ul>

      <Typography variant="body1" component="p" style={{ marginTop: '30px' }}>
        Incase, you need additional assistance we are here to help!
      </Typography>

      <ul className={classes.searchTipsList}>
        <Typography variant="body2" component="li">
          Drop us a mail at&nbsp;
          <span className={classes.email}>
            support@scrunch.com
          </span>
        </Typography>
        <Typography variant="body2" component="li">Chat with our online customer representative</Typography>
      </ul>
    </div>
  );
};

const NoSearchResults = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h5" component="h5" className={classes.heading}>
        Whoops! We couldn&apos;t find any influencers!
      </Typography>

      <Typography variant="body2" component="p">
        We recommend trying another search phrase.
      </Typography>

      <div className={classes.container}>
        <Discover />
        <SearchTips />
      </div>
    </div>
  );
};

export default NoSearchResults;
