import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import CompactProfileList from './CompactProfileList';
import { useApi, useAuth, useNotification } from '../../hooks';

const RemoveFromListDialog = (props) => {
  const {
    list,
    onClose,
    onSuccess,
    profiles,
  } = props;
  const auth = useAuth();
  const [{ isSuccess }, setRequest] = useApi();
  const { createNotification } = useNotification();

  useEffect(() => {
    if (isSuccess && onSuccess) {
      createNotification(
        'success',
        `${profiles.length} profiles removed from list.`,
      );
      onSuccess(profiles);
    }
  }, [createNotification, isSuccess, onSuccess, profiles]);

  const handleRemoveButtonClick = (event) => {
    event.preventDefault();
    if (profiles) {
      setRequest({
        method: 'patch',
        url: `/account/${auth.account.id}/list/${list.id}/`,
        data: {
          network_ids_to_remove: profiles.map(
            (profile) => profile.data.network_id,
          ).reduce(
            (networkIds, profileNetworkIds) => networkIds.concat(profileNetworkIds),
            [],
          ),
        },
      });
    }
  };

  return (
    <Dialog
      onClose={onClose}
      open
      scroll="paper"
    >
      <DialogTitle>
        {`Remove ${profiles.length} profiles from list '${list.name}'`}
      </DialogTitle>
      <DialogContent>
        <CompactProfileList profiles={profiles} />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={handleRemoveButtonClick}
          variant="contained"
        >
          Remove profiles
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RemoveFromListDialog.propTypes = {
  list: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const RemoveFromListButton = ({ list, onSuccess, profiles }) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  return (
    <>
      <Tooltip
        title={
          profiles.length
            ? `Remove ${profiles.length} profile(s) from the list`
            : 'Select profiles to remove from the list'
        }
      >
        <span>
          <IconButton
            disabled={profiles.length === 0}
            onClick={() => setDialogIsOpen(!dialogIsOpen)}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </span>
      </Tooltip>
      {dialogIsOpen && (
        <RemoveFromListDialog
          list={list}
          onClose={() => setDialogIsOpen(false)}
          onSuccess={onSuccess}
          profiles={profiles}
        />
      )}
    </>
  );
};

RemoveFromListButton.propTypes = {
  list: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default RemoveFromListButton;
