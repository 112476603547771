import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { colors } from '../definitions';
import { previewStyles } from '../form/styles';

const useStyles = makeStyles(() => ({
  highlighted: {
    border: `2px solid ${colors.PRIMARY}`,
  },
  root: {
    backgroundColor: colors.BG_LIGHT,
    display: 'flex',
    height: 250,
    marginTop: 20,
    marginBottom: 20,
    '@media (max-width: 1280px)': {
      height: 220,
    },
  },
  col1: {
    flex: 1,
    backgroundColor: colors.BG_DARK,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  main1: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imgWrapper: {
    width: 100,
    height: 100,
    marginBottom: 5,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  col2: {
    flex: 1.6,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  main2: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  heading: {
    textDecoration: 'underline',
    marginBottom: 5,
  },
  flexRow: {
    display: 'flex',
  },
  blockRoot: {
    minWidth: '33%',
    display: 'flex',
    flexDirection: 'column',
  },
  padding: {
    padding: 5,
    '@media (max-width: 1280px)': {
      padding: 2,
    },
  },
}));

const Block = ({ value, label, highlight }) => {
  const classes = useStyles();
  const textStyles = previewStyles();

  return (
    <div
      className={`${classes.blockRoot} ${highlight ? classes.highlighted : ''} ${classes.padding}`}
    >
      <div className={textStyles.h3}>{value}</div>
      <div className={textStyles.h5}>{value ? label : ''}</div>
    </div>
  );
};

Block.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  highlight: PropTypes.bool,
};

Block.defaultProps = {
  value: '',
  label: '',
  highlight: false,
};

const Page5Preview = ({ finalData, highlighted }) => {
  const classes = useStyles();
  const textStyles = previewStyles();

  const data = finalData.instagramAnalytics;
  const instaHandle = data.handle;

  const pData = finalData.personalDetails;

  const { followerCount } = data;
  const { avgPostEngagement } = data;
  const { audienceGenderPercentage } = data.audienceGender;
  const { audienceAge } = data;

  return (
    <div className={classes.root} id="page-5-preview">
      <div className={classes.col1}>
        <div className={`${textStyles.h5} ${classes.padding}`}>Instagram Analytics</div>
        <div className={classes.main1}>
          <div className={`${classes.imgWrapper} ${highlighted === 'instagramAnalytics.analyticsImg' ? classes.highlighted : ''}`}>
            <img
              src={
                data.analyticsImg
              }
              className={classes.image}
              alt="Background for Page 6"
            />
          </div>
          <div
            className={`${textStyles.h4} ${highlighted === 'instagramAnalytics.handle' ? classes.highlighted : ''} ${classes.padding}`}
          >
            {instaHandle ? `@${instaHandle}` : ''}
          </div>
        </div>
        <div className={`${textStyles.body} ${classes.padding}`}>
          {pData.websiteUrl}
        </div>
      </div>
      <div className={classes.col2}>
        <div className={`${textStyles.body} ${classes.padding}`} style={{ textAlign: 'right' }}>
          {pData.firstName}
          &nbsp;
          {pData.lastName}
        </div>
        <div className={classes.main2}>
          {(followerCount || avgPostEngagement) && (
            <>
              <div className={`${textStyles.h5} ${classes.heading}`}>Instagram Overview</div>
              <div className={classes.flexRow} style={{ marginBottom: 10 }}>
                {followerCount && (
                  <Block
                    value={followerCount}
                    label="Followers"
                    highlight={highlighted === 'instagramAnalytics.followerCount'}
                  />
                )}
                {avgPostEngagement && (
                  <Block
                    value={`${avgPostEngagement}%`}
                    label="Average post engagement rate"
                    highlight={highlighted === 'instagramAnalytics.avgPostEngagement'}
                  />
                )}
              </div>
            </>
          )}

          {(audienceGenderPercentage || audienceAge) && (
            <>
              <div className={`${textStyles.h5} ${classes.heading}`}>Audience Gender &amp; Age</div>
              <div className={classes.flexRow} style={{ marginBottom: 10 }}>
                {audienceGenderPercentage && (
                  <Block
                    value={`${audienceGenderPercentage}%`}
                    label={data.audienceGender.gender}
                    highlight={highlighted === 'instagramAnalytics.audienceGender'}
                  />
                )}
                {audienceAge && (
                  <Block
                    value={audienceAge}
                    label="years old"
                    highlight={highlighted === 'instagramAnalytics.audienceAge'}
                  />
                )}
              </div>
            </>
          )}

          {data.audienceLocations.filter((item) => item.locationPercentage).length > 0 && (
            <>
              <div className={`${textStyles.h5} ${classes.heading}`}>Audience Locations</div>
              <div className={classes.flexRow}>
                {data.audienceLocations.map((loc, index) => (loc.locationPercentage ? (
                  <Block
                    value={`${loc.locationPercentage}${loc.locationPercentage ? '%' : ''}`}
                    label={loc.location}
                    highlight={highlighted === `instagramAnalytics.audienceLocation${index}`}
                    key={loc.id}
                  />
                ) : null))}
              </div>
            </>
          )}

        </div>
        <div className={`${textStyles.body} ${classes.padding}`} style={{ textAlign: 'right' }}>
          {pData.instagramHandle ? `@${pData.instagramHandle}` : ''}
        </div>
      </div>
    </div>
  );
};

Page5Preview.propTypes = {
  finalData: PropTypes.shape().isRequired,
  highlighted: PropTypes.string,
};

Page5Preview.defaultProps = {
  highlighted: '',
};

export default Page5Preview;
