import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  IconButton,
  Dialog,
  Menu,
  MenuItem,
  Button,
  TextField,
  Chip,
  Checkbox,
  Avatar,
  Paper,
  InputBase,
  ButtonBase,
  LinearProgress,
} from '@material-ui/core';
import MUIRichTextEditor from 'mui-rte';
import { convertToRaw } from 'draft-js';

import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import AnnouncementOutlinedIcon from '@material-ui/icons/AnnouncementOutlined';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import TelegramIcon from '@material-ui/icons/Telegram';
import SettingsIcon from '@material-ui/icons/Settings';
import CreateIcon from '@material-ui/icons/Create';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Profile from '../../utils/profile';

import API from '../../utils/api';
// import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import ImportIcon from '../common/icons/Import';
import Iphone from '../../assets/images/iphoneInstagram.svg';

import SendForApprovalDialog from './SendForApprovalDialog';
import AnalyticsTrackingDialog from './AnalyticsTrackingDialog';
import StatusBox from './StatusBox';
import { LeftMessage, RightMessage } from './PostMessage';
import viewPostStyles from '../../styles/viewPost';
import Tabs from '../common/Tabs';
import { joinDateToTime } from '../../utils/datetime';
import { useContentManager, useAuth, useNotification } from '../../hooks';

const Checklist = (props) => {
  const {
    checklist,
    handleCheckChange,
    checklistKeyName,
    updateChecklist,
    deleteChecklistItem,
  } = props;
  const classes = viewPostStyles();

  return (
    <div className={classes.checklist}>
      <Typography variant="h6" style={{ marginBottom: '10px' }}>
        Post Checklist
      </Typography>

      {Object.keys(checklist).length > 0
        && Object.keys(checklist).map((item) => (
          <div key={item} className={classes.checklistItem}>
            <Checkbox
              defaultChecked={checklist[item]}
              onClick={() => handleCheckChange(item)}
            />
            {item === ' ' ? (
              <TextField
                style={{ width: '90%' }}
                onBlur={(e) => checklistKeyName(item, e.target.value)}
              />
            ) : (
              <>
                <span style={{ fontSize: '14px' }}>{item}</span>
                <ButtonBase
                  style={{ float: 'right' }}
                  onClick={() => deleteChecklistItem(item)}
                >
                  <DeleteIcon color="primary" style={{ fontSize: '18px' }} />
                </ButtonBase>
              </>
            )}
          </div>
        ))}

      <Button
        color="primary"
        className={classes.checklistBtn}
        onClick={updateChecklist}
      >
        + Add another checklist item
      </Button>
    </div>
  );
};

Checklist.propTypes = {
  checklist: PropTypes.shape().isRequired,
  handleCheckChange: PropTypes.func.isRequired,
  checklistKeyName: PropTypes.func.isRequired,
  updateChecklist: PropTypes.func.isRequired,
  deleteChecklistItem: PropTypes.func.isRequired,
};

const MarkAsPublishedButton = () => {
  const auth = useAuth();
  const content = useContentManager();
  const classes = viewPostStyles();
  const { createNotification } = useNotification();
  const [isOpen, setIsOpen] = useState(false);

  // Notification example,
  // Internal Username marked a post as Published within BrandOne / CampaignOne

  // API({
  //   method: "post",
  //   url: `/account/${auth.account.id}/content-manager/notification/${content.post.id}/`,
  //   data: {
  //     campaign_id: content.campaign.id,
  //     brand_id: content.brand.id,
  //     internal_user_id: auth.user.id,
  //     template: 1,
  //     status_index: 2,
  //   }
  // });

  const patchPost = async () => {
    try {
      const response = await API({
        method: 'patch',
        url: `/account/${auth.account.id}/content-manager/post/${content.post.id}`,
        data: {
          status: 5,
        },
      });
      if (response.status === 200) {
        createNotification('success', 'Status Updated');
        content.setPost(auth.account.id, content.post.id);
      } else {
        createNotification('error', 'Status Not Saved');
      }
    } catch (e) {
      createNotification('error', `Status not Updated: ${e}`);
    }
  };

  return (
    <>
      {isOpen && (
        <AnalyticsTrackingDialog
          handleSendNow={patchPost}
          onClose={() => setIsOpen(false)}
        />
      )}
      <Button
        color="primary"
        variant="contained"
        className={classes.sendForBtn}
        onClick={() => setIsOpen(true)}
      >
        Mark As Published
      </Button>
    </>
  );
};

const SendForApprovalButton = () => {
  const classes = viewPostStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [intApproval, setIntApproval] = useState(false);
  const [clientApproval, setClientApproval] = useState(false);

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        className={classes.sendForBtn}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        Send For Approval
        {anchorEl ? (
          <ExpandLessIcon style={{ fontSize: '14px' }} />
        ) : (
          <ExpandMoreIcon style={{ fontSize: '14px' }} />
        )}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={() => setClientApproval(true)}
        >
          Send For Client Approval
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => setIntApproval(true)}
        >
          Send For Internal Approval
        </MenuItem>
      </Menu>

      {clientApproval && (
        <SendForApprovalDialog
          toClient
          onClose={() => setClientApproval(false)}
        />
      )}

      {intApproval && (
        <SendForApprovalDialog onClose={() => setIntApproval(false)} />
      )}
    </>
  );
};

const ApprovePostButton = () => {
  const auth = useAuth();
  const content = useContentManager();
  const classes = viewPostStyles();
  const { createNotification } = useNotification();

  const handlePatchStatus = async () => {
    // Notification example,
    // Client Username Approved a post within BrandOne / CampaignOne

    // API({
    //   method: "post",
    //   url: `/account/${auth.account.id}/content-manager/notification/${content.post.id}/`,
    //   data: {
    //     campaign_id: content.campaign.id,
    //     brand_id: content.brand.id,
    //     internal_user_id: auth.user.id,
    //     template: 2,
    //     status_index: 0,
    //   }
    // });

    try {
      const response = await API({
        method: 'patch',
        url: `/account/${auth.account.id}/content-manager/post/${content.post.id}`,
        data: {
          status: 4,
        },
      });
      if (response.status === 200) {
        createNotification('success', 'Status Updated');
        content.setPost(auth.account.id, content.post.id);
      } else {
        createNotification('error', 'Status Not Saved');
      }
    } catch (e) {
      createNotification('error', `Status not Updated: ${e}`);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        style={{ backgroundColor: '#219653', color: 'white' }}
        className={classes.sendForBtn}
        onClick={handlePatchStatus}
      >
        Approve Post
      </Button>
    </>
  );
};

const RequestReworkButton = () => {
  const auth = useAuth();
  const content = useContentManager();
  const classes = viewPostStyles();
  const { createNotification } = useNotification();

  // Notification example,
  // Client or Internal User marked a post as Internal Rework with BrandOne / CampaignOne

  // API({
  //   method: "post",
  //   url: `/account/${auth.account.id}/content-manager/notification/${content.post.id}/`,
  //   data: {
  //     campaign_id: content.campaign.id,
  //     brand_id: content.brand.id,
  //     internal_user_id: auth.user.id,
  //     client_id: auth.user.id,
  //     template: 1,
  //     status: 4,
  //   }
  // });

  const handlePatchStatus = async () => {
    try {
      const response = await API({
        method: 'patch',
        url: `/account/${auth.account.id}/content-manager/post/${content.post.id}`,
        data: {
          status: 1,
        },
      });
      if (response.status === 200) {
        createNotification('success', 'Status Updated');
        content.setPost(auth.account.id, content.post.id);
      } else {
        createNotification('error', 'Status Not Saved');
      }
    } catch (e) {
      createNotification('error', `Status not Updated: ${e}`);
    }
  };

  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        className={classes.sendForBtn}
        onClick={handlePatchStatus}
      >
        Request Rework
      </Button>
    </>
  );
};

const MoreSettingsButton = () => {
  const classes = viewPostStyles();
  const content = useContentManager();
  const auth = useAuth();
  const { createNotification } = useNotification();
  const [anchorEl, setAnchorEl] = useState(null);

  const patchStatus = async (newStatus) => {
    try {
      const response = await API({
        method: 'patch',
        url: `/account/${auth.account.id}/content-manager/post/${content.post.id}/`,
        data: {
          status: newStatus,
        },
      });
      // console.log(response);
      if (response.status === 200) {
        createNotification('success', 'Status Updated');
        content.setPost(auth.account.id, content.post.id);
      } else {
        createNotification('error', 'Status Not Saved');
      }
    } catch (e) {
      // console.log(`MoreSettingsButton ${e}`);
      createNotification('error', `Status not Updated: ${e}`);
    }
  };

  return (
    <>
      <ButtonBase onClick={(e) => setAnchorEl(e.currentTarget)}>
        <MoreVertIcon />
      </ButtonBase>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem className={classes.menuItem} onClick={() => patchStatus(4)}>
          Approve Post
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={() => patchStatus(1)}>
          Mark as Internal Rework
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={() => patchStatus(3)}>
          Patch Back
        </MenuItem>
      </Menu>
    </>
  );
};

const ViewPost = ({ isOpen, closeDialog }) => {
  const classes = viewPostStyles();
  const content = useContentManager();
  const auth = useAuth();
  const { createNotification } = useNotification();
  const [note, setNote] = useState();
  const [whichTab, setWhichTab] = useState('Messages');
  const [newNote, setNewNote] = useState();
  const [postDate, setPostDate] = useState();
  const [postTime, setPostTime] = useState();
  const [checklist, setChecklist] = useState({});
  const [isFetching, setIsFetching] = useState(true);
  const [influencer, setInfluencer] = useState();
  const [postEdited, setPostEdited] = useState(false);
  const [postMessage, setPostMessage] = useState('');
  const [internalMsgs, setInternalMsgs] = useState(false);
  // const [publishedChecklist, setPubChecklist] = useState({});
  const [trackingUrl] = useState(
    (content.post && content.post.google_tracking_url) || '',
  );
  const status = content.post && {
    label: content.status[content.post.status].label,
    bgColor: content.status[content.post.status].bgColor,
    fontColor: content.status[content.post.status].fontColor,
  };

  const profiledInf = content.post && Profile(content.post.influencer);
  const isClient = auth.user.agency_role === 'client';

  const resetInputs = () => {
    setPostEdited(false);
    setChecklist({});
    setInternalMsgs(true);
    setWhichTab('Messages');
  };

  useEffect(() => {
    // Can dry this up a fair bit
    if (content.post) {
      setIsFetching(false);
    }
    if (content.post && content.post.post_datetime) {
      setPostDate(new Date(content.post.post_datetime.split(' ').join('T')));
      setPostTime(new Date(content.post.post_datetime.split(' ').join('T')));
    }
    if (content.post && content.post.notes) {
      setNote(content.post.notes);
    }
    if (content.post && content.post.checklist) {
      setChecklist(content.post.checklist);
    }
    if (content.post && content.post.influencer) {
      setInfluencer(content.post.influencer);
    }
  }, [content.post, content.campaign]);

  const checkDateTime = () => {
    if (!postDate && !postTime) {
      return null;
    }
    if (postDate && postTime) {
      return joinDateToTime(postDate, postTime);
    }
    if (postDate && !postTime) {
      return postDate.format();
    }
    return null;
  };

  const handleSave = async () => {
    if (postEdited) {
      setIsFetching(true);
      // console.log(checklist);

      const response = await API({
        method: 'patch',
        url: `/account/${auth.account.id}/content-manager/post/${content.post.id}/`,
        data: {
          notes: newNote || content.post.notes,
          post_datetime: checkDateTime(),
          checklist: JSON.stringify(checklist),
          post_text: content.post.text,
          content_type: content.post.content_type,
          labels: JSON.stringify(content.post.labels),
          status: content.post.status,
          version: content.post.version,
        },
      });
      if (response.status === 200) {
        resetInputs();
        createNotification('success', 'Post Updated');
        if (content.brand) {
          content.getCampaignAndPosts(
            auth.account.id,
            content.brand.id,
            content.campaign.id,
          );
        } else {
          content.getBrandsAndPosts(auth.account.id);
        }
      } else {
        createNotification('error', 'Error Post Not Saved');
      }
    } else {
      resetInputs();
    }
  };

  const handleCheckChange = (item) => {
    const newObj = checklist;
    newObj[item] = !newObj[item];
    setChecklist(newObj);
    setPostEdited(true);
  };

  const updateChecklist = () => {
    setPostEdited(true);
    setChecklist({ ...checklist, ' ': false });
  };

  const deleteChecklistItem = (item) => {
    setPostEdited(true);
    const newChecklist = checklist;
    delete newChecklist[item];
    setChecklist(newChecklist);
  };

  const handleValueChange = (event) => {
    setPostEdited(true);
    const text = JSON.stringify(convertToRaw(event.getCurrentContent()));
    setNewNote(text);
  };

  const checklistKeyName = (item, keyName) => {
    const newObject = {};
    delete Object.assign(newObject, checklist, { [keyName]: checklist[item] })[
      item
    ];
    setChecklist(newObject);
  };

  const sendMessage = async () => {
    const response = await API({
      method: 'post',
      url: `/account/${auth.account.id}/content-manager/postmessage/${content.post.id}/`,
      data: {
        owner: `${auth.user.first_name} ${auth.user.last_name}`,
        // if user is a client is_to_client will always be true
        is_to_client: isClient || !internalMsgs,
        body: postMessage,
      },
    });
    if (response.status === 200) {
      setPostMessage('');
      createNotification('success', 'Post Sent');
      content.setPost(auth.account.id, content.post.id);
    }
  };

  const deletePost = async () => {
    try {
      const response = await API({
        method: 'delete',
        url: `/account/${auth.account.id}/content-manager/post/${content.post.id}/`,
      });
      if (response.status === 200) {
        createNotification('success', 'Post Deleted ');
        content.getCampaignAndPosts(
          auth.account.id,
          content.brand.id,
          content.campaign.id,
        );
        closeDialog();
      }
    } catch (e) {
      createNotification('error', `Couldn't Delete: ${e}`);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onBackdropClick={() => closeDialog()}
      className={classes.main}
      PaperProps={{
        style: {
          minWidth: '1200px',
          display: 'flex',
          padding: '0',
          flexDirection: 'row',
          height: '800px',
        },
      }}
    >
      {!content.post ? (
        <Typography>Loading...</Typography>
      ) : (
        <>
          <div className={classes.leftSide}>
            <div className={classes.leftCont}>
              {/* Old version warning */}
              <img className={classes.iphone} src={Iphone} alt="iphone" />
              <div className={classes.phoneContent}>
                <div className={classes.leftAvatarUsername}>
                  <Avatar
                    src=""
                    alt="instagram avatar"
                    className={classes.iphoneAvatar}
                  />
                  <div className={classes.handleLocation}>
                    <Typography className={classes.handle}>
                      {profiledInf && profiledInf.handle()}
                    </Typography>
                    <Typography className={classes.location}>
                      {profiledInf && profiledInf.locationCountry()}
                    </Typography>
                  </div>
                </div>
                <img
                  className={classes.postImage}
                  src={content.post.img_url[0]}
                  alt="Post"
                />
                <div className={classes.postText}>
                  <Typography
                    style={{
                      fontSize: '8px',
                      display: 'inline',
                      fontWeight: 'bold',
                      lineHeight: '1',
                    }}
                  >
                    {profiledInf && profiledInf.handle()}
                    {' '}
&nbsp;
                  </Typography>
                  <Typography
                    style={{
                      fontSize: '8px',
                      display: 'inline',
                      lineHeight: '1',
                    }}
                  >
                    {content.post.text}
                  </Typography>
                </div>
              </div>
              <Button
                color="primary"
                variant="contained"
                className={classes.downContBtn}
              >
                <ImportIcon style={{ fontSize: '12px', marginRight: '10px' }} />
                {' '}
                Download Content
              </Button>
            </div>
          </div>
          <div className={classes.rightSide}>
            {isFetching ? (
              <LinearProgress
                style={{
                  width: '100%',
                }}
              />
            ) : (
              <>
                {/* HEADER */}
                <div className={classes.header}>
                  <div className={classes.titlePicStatus}>
                    <Avatar
                      src={content.post.brand}
                      alt={content.post.brand}
                      className={classes.brandPic}
                    />
                    <div>
                      <Typography
                        style={{ fontSize: '18px', fontWeight: '200' }}
                      >
                        {content.post.brand}
                      </Typography>
                      <StatusBox
                        status={status && status.label}
                        bgColor={status && status.bgColor}
                        textColor={status && status.fontColor}
                      />
                    </div>
                  </div>
                  <div>
                    <IconButton
                      aria-label="close"
                      className={classes.closeButton}
                      onClick={() => (postEdited ? closeDialog(handleSave) : closeDialog())}
                    >
                      <CloseIcon />
                    </IconButton>
                    <div className={classes.headIcons}>
                      {!isClient && (
                        <>
                          <ButtonBase>
                            <SettingsIcon />
                          </ButtonBase>
                          <ButtonBase onClick={() => deletePost()}>
                            <DeleteIcon />
                          </ButtonBase>
                          <ButtonBase>
                            <CreateIcon />
                          </ButtonBase>
                          {content.status[content.post.status].label
                            === 'Draft Post' && <SendForApprovalButton />}
                          {content.status[content.post.status].label
                            === 'Pending Client Approval' && <MoreSettingsButton />}
                          {content.status[content.post.status].label
                            === 'Pending Internal Approval' && (
                            <MoreSettingsButton />
                          )}
                          {content.status[content.post.status].label
                            === 'Internal Rework' && <MoreSettingsButton />}
                          {content.status[content.post.status].label
                            === 'Approved By Client' && <MarkAsPublishedButton />}
                        </>
                      )}
                      {content.status[content.post.status].label
                        === 'Pending Client Approval'
                        && isClient && (
                          <>
                            <RequestReworkButton />
                            <ApprovePostButton />
                          </>
                      )}
                      {content.status[content.post.status].label
                        === 'Approved By Client'
                        && isClient && <RequestReworkButton />}
                    </div>
                  </div>
                </div>

                {/* TABS */}
                <div className={classes.tabs}>
                  <Tabs
                    tabOneActive={whichTab === 'Messages'}
                    tabTwoActive={whichTab === 'General Information'}
                    tabOneTitle={`Messages (${
                      isClient
                        ? content.post.messages.filter((p) => p.is_to_client)
                          .length
                        : content.post.messages.length
                    })`}
                    tabTwoTitle="General Information"
                    tabThreeTitle={
                      content.post.published_post_url ? 'Post Analytics' : null
                    }
                    changeToTabOne={() => setWhichTab('Messages')}
                    changeToTabTwo={() => setWhichTab('General Information')}
                    changeToTabThree={() => setWhichTab('Post Analytics')}
                    noDataNeeded
                    fontSize="12px"
                  />
                </div>

                {whichTab === 'General Information' && (
                  <>
                    {/* CHECKLIST */}
                    <Checklist
                      checklist={checklist}
                      handleCheckChange={handleCheckChange}
                      updateChecklist={updateChecklist}
                      checklistKeyName={checklistKeyName}
                      deleteChecklistItem={deleteChecklistItem}
                    />

                    {/* INLFLUENCER */}
                    <div className={classes.influencer}>
                      <Typography variant="h6">
                        Influencer Instagram Profile Username
                      </Typography>
                      <Paper variant="outlined" className={classes.infField}>
                        <Avatar
                          className={classes.infAvatar}
                          src={influencer.profile_picture[0]}
                          alt="influencer avatar"
                        />
                        {influencer.full_name}
                      </Paper>
                    </div>

                    {/* ANALYTICS */}
                    <div className={classes.analytics}>
                      <Typography variant="h6">
                        Google Analytics tracking URL
                      </Typography>
                      <TextField
                        className={classes.analyticsField}
                        fullWidth
                        value={trackingUrl}
                        disabled
                      />
                    </div>

                    {/* LABELS */}
                    <div className={classes.labels}>
                      <Typography variant="h6">Post Labels</Typography>
                      <Paper variant="outlined" className={classes.labelField}>
                        {content.post.labels.map((label) => (
                          <Chip
                            key={label}
                            label={label}
                            style={{
                              backgroundColor: '#354065',
                              color: 'white',
                              margin: '5px',
                            }}
                          />
                        ))}
                      </Paper>
                    </div>

                    {/* DATES */}
                    <div className={classes.dates}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <div>
                          <Typography variant="h6">Post Date</Typography>
                          <KeyboardDatePicker
                            clearable
                            value={postDate || content.campaign.startdate}
                            onChange={(date) => {
                              setPostDate(date);
                              setPostEdited(true);
                            }}
                            minDate={content.campaign.startdate}
                            className={classes.dateField}
                          />
                        </div>
                        <div>
                          <Typography variant="h6">Post Time</Typography>
                          <KeyboardTimePicker
                            placeholder="Pick your time"
                            mask="__:__ _M"
                            value={postTime}
                            onChange={(time) => {
                              setPostTime(time);
                              setPostEdited(true);
                            }}
                            clearable
                            className={classes.dateField}
                          />
                        </div>
                      </MuiPickersUtilsProvider>
                    </div>

                    {/* NOTES */}
                    <div className={classes.notes}>
                      <Typography variant="h6">Notes</Typography>
                      {note && (
                        <MUIRichTextEditor
                          defaultValue={note}
                          className={classes.noteField}
                          label="Add private notes to this post..."
                          onChange={() => handleValueChange}
                          controls={[
                            'title',
                            'bold',
                            'italic',
                            'underline',
                            'bulletList',
                            'link',
                          ]}
                          toolbar
                        />
                      )}
                    </div>
                  </>
                )}

                {whichTab === 'Messages' && (
                  <>
                    <div className={classes.msgCont}>
                      <div className={classes.msgHeader}>
                        {!isClient ? (
                          <>
                            <button
                              type="button"
                              className={
                                internalMsgs
                                  ? classes.activeTab
                                  : classes.msgTab
                              }
                              onClick={() => setInternalMsgs(true)}
                            >
                              {' '}
                              <AnnouncementOutlinedIcon
                                style={{
                                  marginRight: '10px',
                                  fontSize: '14px',
                                }}
                              />
                              {' '}
                              <Typography
                                style={{
                                  marginBottom: 0,
                                  fontWeight: 'normal',
                                }}
                              >
                                Internal Messages (
                                {
                                  content.post.messages.filter(
                                    (msg) => msg.is_to_client === false,
                                  ).length
                                }
                                )
                              </Typography>
                            </button>
                            <button
                              type="button"
                              className={
                                !internalMsgs
                                  ? classes.activeTab
                                  : classes.msgTab
                              }
                              onClick={() => setInternalMsgs(false)}
                            >
                              {' '}
                              <ChatBubbleOutlineIcon
                                style={{
                                  marginRight: '10px',
                                  fontSize: '14px',
                                }}
                              />
                              <Typography
                                style={{
                                  marginBottom: 0,
                                  fontWeight: 'normal',
                                }}
                              >
                                Client Messages (
                                {
                                  content.post.messages.filter(
                                    (msg) => msg.is_to_client,
                                  ).length
                                }
                                )
                              </Typography>
                            </button>
                          </>
                        ) : (
                          <button
                            type="button"
                            className={
                              internalMsgs ? classes.activeTab : classes.msgTab
                            }
                            style={{ cursor: 'auto' }}
                          >
                            {' '}
                            <AnnouncementOutlinedIcon
                              style={{ marginRight: '10px', fontSize: '14px' }}
                            />
                            {' '}
                            <Typography
                              style={{ marginBottom: 0, fontWeight: 'normal' }}
                            >
                              Messages (
                              {
                                content.post.messages.filter(
                                  (msg) => msg.is_to_client,
                                ).length
                              }
                              )
                            </Typography>
                          </button>
                        )}
                      </div>

                      <div className={classes.msgMsgs}>
                        {content.post.messages
                          .filter((msg) => msg.is_to_client === !internalMsgs)
                          .map((msg) => {
                            if (
                              msg.owner.split(' ')[0] === auth.user.first_name
                            ) {
                              return (
                                <RightMessage
                                  author={msg.owner}
                                  text={msg.body}
                                  date={msg.datetime}
                                  key={msg.body}
                                />
                              );
                            }
                            return (
                              <LeftMessage
                                author={msg.owner}
                                text={msg.body}
                                date={msg.datetime}
                                key={msg.body}
                              />
                            );
                          })}
                      </div>

                      <div className={classes.msgActions}>
                        <InputBase
                          className={classes.msgInput}
                          placeholder="Write a message..."
                          inputProps={{ 'aria-label': 'naked' }}
                          onChange={(e) => setPostMessage(e.target.value)}
                          value={postMessage}
                        />
                        {' '}
                        <ButtonBase onClick={sendMessage}>
                          <Avatar className={classes.msgSend}>
                            <TelegramIcon />
                          </Avatar>
                        </ButtonBase>
                      </div>
                    </div>
                  </>
                )}

                {whichTab === 'Post Analytics' && (
                  <>
                    <Checklist
                      checklist={checklist}
                      handleCheckChange={handleCheckChange}
                      updateChecklist={updateChecklist}
                      checklistKeyName={checklistKeyName}
                    />
                    {/* INFLUENCER */}
                    <div className={classes.influencer}>
                      <Typography variant="h6">
                        Influencer Instagram Profile Username
                      </Typography>
                      <Paper variant="outlined" className={classes.infField}>
                        <Avatar
                          className={classes.infAvatar}
                          src="#"
                          alt="influencer avatar"
                        />
                        {influencer && influencer.full_name}
                      </Paper>
                    </div>

                    {/*  */}
                    <div className={classes.analytics}>
                      <Typography variant="h6">Published Post URL</Typography>
                      <TextField
                        className={classes.analyticsField}
                        fullWidth
                        value="published post url"
                        disabled
                      />
                    </div>

                    <div className={classes.analytics}>
                      <Typography variant="h6">
                        Google Analytics tracking URL
                      </Typography>
                      <TextField
                        className={classes.analyticsField}
                        fullWidth
                        value="https://www.chnagethistoaurl.com"
                        disabled
                      />
                    </div>

                    <div className={classes.analytics}>
                      <Typography variant="h6">Shopify Coupon Code</Typography>
                      <TextField
                        className={classes.analyticsField}
                        fullWidth
                        value="SHOPIFYCODE"
                        disabled
                      />
                    </div>

                    {/* LABELS */}
                    <div className={classes.labels}>
                      <Typography variant="h6">Post Labels</Typography>
                      <Paper variant="outlined" className={classes.labelField}>
                        {content.post.labels.map((label) => (
                          <Chip
                            key={label}
                            label={label}
                            style={{
                              backgroundColor: '#354065',
                              color: 'white',
                              margin: '5px',
                            }}
                          />
                        ))}
                      </Paper>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}
    </Dialog>
  );
};

ViewPost.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default ViewPost;
