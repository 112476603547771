import React, { useEffect, useState } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  Button,
  ButtonBase,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SuccessIcon from '../common/icons/Success';
import ErrorIcon from '../common/icons/Error';
import { useApi, useAuth/* , useInterval */ } from '../../hooks';

import ExcitedGif from '../../assets/images/gifs/excited.gif';

const IMPORT_TIMEOUT = 10 * 60; // 10 minutes

const isTimedOut = (profileImport) => (
  profileImport.status === 'PENDING'
  && moment.utc(profileImport.created_at).add(IMPORT_TIMEOUT, 'seconds') < moment.utc()
);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  circularProgress: {
    marginRight: 5,
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  listItemStatus: {
    width: 12,
    height: 12,
    marginRight: 5,
  },
  container: {
    width: '100%',
    minHeight: 500,
    marginTop: 30,
    padding: '60px 0',
    '@media(min-width:1920px)': {
      minHeight: '70vh',
    },
    '@media(min-width:2560px)': {
      minHeight: '75vh',
      marginTop: 60,
      marginBottom: 100,
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.system.grey1,
    border: `1px solid ${theme.colors.system.grey3}`,
    borderRadius: 4,
  },
  gif: {
    margin: '40px 0',
    '@media(min-width:1920px)': {
      height: 300,
    },
    '@media(min-width:2560px)': {
      height: 550,
      margin: '70px 0',
    },
  },
  bottomText: {
    ...theme.textStyles.bodyMedium,
    lineHeight: '24px',
  },
  retryBtn: {
    marginTop: 40,
    '@media(min-width:2560px)': {
      marginTop: 60,
    },
  },
  highlightedText: {
    color: theme.colors.primary.main,
  },
}));

const ImportingProfiles = ({
  complete,
  total,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h4" component="h4" style={{ display: 'flex' }}>
        Importing Profiles...&nbsp;
        <span className={classes.highlightedText}>
          {complete}
          &nbsp;of&nbsp;
          {total}
        </span>
      </Typography>

      <img alt="Excited Man" src={ExcitedGif} className={classes.gif} />

      <Typography variant="body1" component="p" className={classes.bottomText}>
        Get excited! It won&apos;t be long now!
        {' '}
        <span role="img" aria-label="man-dancing">&#128378;</span>
      </Typography>
    </div>
  );
};

ImportingProfiles.propTypes = {
  complete: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

const ImportListStatusDialog = (props) => {
  const { list, onClose, profileImports } = props;
  const classes = useStyles();
  const auth = useAuth();
  const [{ isFetching }, setPostProfileImportsRequest] = useApi();

  const handleRetryButtonClick = (event) => {
    // Retry all imports that are not currently pending or complete
    event.preventDefault();
    setPostProfileImportsRequest({
      method: 'post',
      url: `/account/${auth.account.id}/list/${list.id}/import/`,
      data: {
        urls: profileImports.filter((it) => (
          !['PENDING', 'COMPLETE'].includes(it.status)
        )).map((it) => (
          it.original_url
        )),
      },
    });
  };

  return (
    <Dialog
      onClose={onClose}
      open
    >
      <DialogTitle>
        {`Profiles imported into list '${list.name}'`}
      </DialogTitle>
      <DialogContent>
        {profileImports.map((profileImport) => {
          const getStatusIcon = (status) => {
            switch (status) {
              case 'PENDING':
                return <CircularProgress className={classes.listItemStatus} size={10} />;
              case 'COMPLETE':
                return <SuccessIcon className={classes.listItemStatus} style={{ color: 'green' }} />;
              default:
                return <ErrorIcon className={classes.listItemStatus} style={{ color: 'red' }} />;
            }
          };

          return (
            <div
              className={classes.listItem}
              key={profileImport.original_url}
            >
              {getStatusIcon(isTimedOut(profileImport) ? 'TIMEDOUT' : profileImport.status)}
              <Typography variant="body2">{profileImport.original_url}</Typography>
            </div>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={isFetching || profileImports.every((it) => !isTimedOut(it) && ['PENDING', 'COMPLETE'].includes(it.status))}
          onClick={handleRetryButtonClick}
          variant="contained"
        >
          Retry Imports
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ImportListStatusDialog.propTypes = {
  list: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  profileImports: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const ImportProfilesStatusWidget = (props) => {
  const { list, profiles } = props;
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [profileImports, setProfileImports] = useState([]);
  // const [timer, setTimer] = useState(10000);
  // const [timerCount, setTimerCount] = useState(0);
  const auth = useAuth();
  const [{ data: listImportData }/* , setGetListImportData */] = useApi({
    method: 'get',
    url: `/account/${auth.account.id}/list/${list.id}/import/`,
  });
  const classes = useStyles();

  // useEffect(() => {
  //   if (timerCount > 60) {
  //     setTimer(300000);
  //   }
  // }, [timerCount]);

  // useInterval(() => {
  //   setGetListImportData({
  //     method: 'get',
  //     url: `/account/${auth.account.id}/list/${list.id}/import/`,
  //   });
  //   setTimerCount(timerCount + 1);
  // }, timer);

  useEffect(() => {
    if (Array.isArray(listImportData)) {
      setProfileImports(listImportData.map((it) => ({
        ...it,
        status: isTimedOut(it) ? 'TIMEDOUT' : it.status,
      })));
    }
  }, [listImportData]);

  if (profileImports.length === 0) {
    return null;
  }

  const pending = profileImports.filter((it) => it.status === 'PENDING');
  const complete = profileImports.filter((it) => it.status === 'COMPLETE');

  return (
    <div>
      <ButtonBase
        className={classes.root}
        onClick={() => setDialogIsOpen(true)}
      >
        {pending.length > 0 && (
          <CircularProgress className={classes.circularProgress} size={10} />
        )}
        <Typography variant="body2" style={{ display: 'flex' }}>
          Imported&nbsp;
          <span className={classes.highlightedText}>{`${complete.length} of ${profileImports.length}`}</span>
          &nbsp;profiles
        </Typography>
      </ButtonBase>
      {pending.length >= 0 && _.isEmpty(profiles) && (
        <ImportingProfiles
          complete={complete.length}
          total={profileImports.length}
        />
      )}
      {dialogIsOpen && (
        <ImportListStatusDialog
          list={list}
          onClose={() => setDialogIsOpen(false)}
          profileImports={profileImports}
        />
      )}
    </div>
  );
};

ImportProfilesStatusWidget.propTypes = {
  list: PropTypes.shape().isRequired,
  profiles: PropTypes.arrayOf(PropTypes.shape()),
};

ImportProfilesStatusWidget.defaultProps = {
  profiles: null,
};

export default ImportProfilesStatusWidget;
