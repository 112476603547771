import React, { useState } from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';

import { MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import BarChart from '../../chart/BarChart';
import Select from '../../common/Select';

import ProfileAudienceAge from './ProfileAudienceAge';
import ProfileAudienceGender from './ProfileAudienceGender';

const weekdayLabels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const timezones = [
  {
    id: 0, text: '(GMT -12) Eniwetok, Kwajalein', offset: -12, code: '',
  },
  {
    id: 1, text: '(GMT -11) Midway Island, Samoa', offset: -11, code: '',
  },
  {
    id: 2, text: '(GMT -10) Hawaii', offset: -10, code: '',
  },
  {
    id: 3, text: '(GMT -9) Alaska', offset: -9, code: '',
  },
  {
    id: 4, text: '(GMT -8) Pacific Time (US & Canada)', offset: -8, code: '',
  },
  {
    id: 5, text: '(GMT -7) Mountain Time (US & Canada)', offset: -7, code: '',
  },
  {
    id: 6, text: '(GMT -6) Central Time (US & Canada), Mexico City', offset: -6, code: '',
  },
  {
    id: 7, text: '(GMT -5) Eastern Time (US & Canada), Bogota, Lima', offset: -5, code: '',
  },
  {
    id: 8, text: '(GMT -4) Atlantic Time (Canada), Caracas, La Paz', offset: -4, code: '',
  },
  {
    id: 9, text: '(GMT -3) Brazil, Buenos Aires, Georgetown', offset: -3, code: '',
  },
  {
    id: 10, text: '(GMT -2) Mid-Atlantic', offset: -2, code: '',
  },
  {
    id: 11, text: '(GMT -1) Azores, Cape Verde Islands', offset: -1, code: '',
  },
  {
    id: 12, text: '(GMT) Western Europe Time, London, Lisbon, Casablanca', offset: 0, code: '',
  },
  {
    id: 13, text: '(GMT +1) Brussels, Copenhagen, Madrid, Paris', offset: 1, code: '',
  },
  {
    id: 14, text: '(GMT +2) Kaliningrad, South Africa', offset: 2, code: '',
  },
  {
    id: 15, text: '(GMT +3) Baghdad, Riyadh, Moscow, St. Petersburg', offset: 3, code: '',
  },
  {
    id: 16, text: '(GMT +4) Abu Dhabi, Muscat, Baku, Tbilisi', offset: 4, code: '',
  },
  {
    id: 17, text: '(GMT +5) Ekaterinburg, Islamabad, Karachi, Tashkent', offset: 5, code: '',
  },
  {
    id: 18, text: '(GMT +6) Almaty, Dhaka, Colombo', offset: 6, code: '',
  },
  {
    id: 19, text: '(GMT +7) Bangkok, Hanoi, Jakarta', offset: 7, code: '',
  },
  {
    id: 20, text: '(GMT +8) Beijing, Perth, Singapore, Hong Kong', offset: 8, code: '',
  },
  {
    id: 21, text: '(GMT +9) Tokyo, Seoul, Osaka, Sapporo, Yakutsk', offset: 9, code: '',
  },
  {
    id: 22, text: '(GMT +10) Eastern Australia, Guam, Vladivostok', offset: 10, code: '',
  },
  {
    id: 23, text: '(GMT +11) Magadan, Solomon Islands, New Caledonia', offset: 11, code: '',
  },
  {
    id: 24, text: '(GMT +12) Auckland, Wellington, Fiji, Kamchatka', offset: 12, code: '',
  },
];

/**
 * Get the default timezone detected in the browser, if possible.
 */
const browserTimezoneIndex = () => {
  const browserTimezoneOffset = Math.round((new Date()).getTimezoneOffset() / 60);
  const timezoneIndex = timezones.findIndex((it) => it.offset === -browserTimezoneOffset);
  return _.isInteger(timezoneIndex) ? timezoneIndex : 12; // UTC
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: `${theme.spacing(2)}px 0px`,
    borderRadius: 5,
    /* backgroundColor: theme.colors.system.grey1, */
    '&>:not(last-child)': {
      marginBottom: 38,
    },
  },
  title: {
    '&>p': {
      fontWeight: 'bold',
    },
  },
  gridTwoColumns: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '50% 50%',
  },
  timezoneSelect: {
    width: '100%',
  },
  chart: {
    backgroundColor: theme.colors.system.grey1,
    borderRadius: 5,
    padding: 16,
    marginTop: 15,
    '&:first-child': {
      marginRight: 28,
    },
  },
  activity: {
    width: '100%',
    backgroundColor: theme.colors.system.grey1,
    borderRadius: 5,
    padding: 16,
    marginTop: 40,
    display: 'flex',
    alignItems: 'center',
    flex: '1',
  },
  activitySidebar: {
    width: '100%',
    padding: '16px 0',
    marginTop: 40,
    display: 'flex',
    alignItems: 'center',
    flex: '1',
  },
  activityLabel: {
    transform: 'rotate(30deg)',
    width: 'max-content',
    margin: '9px 0',
  },
}));

const generateHourlyLabel = (interval, index) => {
  const label = index * interval;
  const start = label % 12 !== 0 ? label % 12 : 12;
  const end = (label + interval) % 12 !== 0 ? (label + interval) % 12 : 12;
  const period = (label + interval) % 24 < 12 ? 'am' : 'pm';
  if (end !== start) {
    return `${start}-${end} ${period}`;
  }
  return `${start} ${period}`;
};

const rotateArray = (arr, n) => {
  const arrLength = arr.length;
  const nTemp = n > 0 ? n % arrLength : arrLength - (Math.abs(n) % arrLength);
  return arr.slice(arrLength - nTemp).concat(arr.slice(0, arrLength - nTemp));
};

const ProfileAudienceActivity = (props) => {
  const { activityData, audience, sidebar } = props;
  const [timezoneIndex, setTimezoneIndex] = useState(browserTimezoneIndex());
  const hourlyInterval = 2;
  const classes = useStyles();

  // Fill out missing probabilities in the activity data
  const weekhourProbabilities = Array(24 * 7).fill(0).map((it, idx) => {
    const data = activityData.find((it2) => parseInt(it2.key, 10) === idx);
    return data ? data.probability : 0;
  });

  // Rotate the weekhour probability array by the selected timezone offset
  const { offset } = timezones[timezoneIndex];
  const offsetWeekhourProbabilities = rotateArray(weekhourProbabilities, offset);

  // Group probabilties into hour of the day
  const hourOfDayProbabilities = offsetWeekhourProbabilities.reduce(
    (accumulator, currentValue, idx) => {
      accumulator[Math.floor((idx % 24) / hourlyInterval)] += currentValue / 7;
      return accumulator;
    },
    Array(24 / hourlyInterval).fill(0),
  );

  const weekdayProbabilities = offsetWeekhourProbabilities.reduce(
    (accumulator, currentValue, idx) => {
      const accumulatorIndex = Math.floor(idx / 24);
      accumulator[accumulatorIndex] += currentValue / 24;
      return accumulator;
    },
    Array(7).fill(0),
  );

  const weekdayData = weekdayProbabilities.map((probability, idx) => ({
    label: weekdayLabels[idx],
    value: probability,
  }));

  const hourlyData = hourOfDayProbabilities.map((probability, idx) => ({
    label: generateHourlyLabel(hourlyInterval, idx),
    value: probability,
  }));

  const largestWeekday = _.maxBy(weekdayData, (it) => it.value);
  const largestHourly = _.maxBy(hourlyData, (it) => it.value);

  const offsetStatus = offset ? ` ${offset > 0 ? '+' : ''}${offset}` : '';
  const activityStatus = `Audience is most active around \
    ${largestHourly.label} (GMT${offsetStatus}) on ${largestWeekday.label}.`;

  const emptyLabel = '';

  let gridTemplateColumns = '1fr 1fr';
  if (sidebar) {
    gridTemplateColumns = '1fr';
  }

  return (
    <div className={classes.root}>
      <div className={classes.gridTwoColumns} style={{ gridTemplateColumns }}>
        <div style={{ paddingRight: sidebar ? 0 : 20, display: 'flex', flexDirection: 'column' }}>
          {audience.gender && audience.gender.data && audience.gender.data.length > 0 && (
            <ProfileAudienceGender genderData={audience.gender.data} />
          )}

          {sidebar && (
            <div style={{ marginTop: 40 }}>
              {audience.age && audience.age.data && audience.age.data.length > 0 && (
                <ProfileAudienceAge ageData={audience.age.data} />
              )}
            </div>
          )}

          <div className={sidebar ? classes.activitySidebar : classes.activity}>
            <div style={{ width: '70%', paddingRight: 30 }}>
              <Typography variant="body2">{activityStatus}</Typography>
            </div>
            <div style={{ width: '30%' }}>
              <Select
                className={classes.timezoneSelect}
                value={timezoneIndex}
                onChange={(e) => setTimezoneIndex(e.target.value)}
                label={emptyLabel}
              >
                {timezones.map((it, idx) => (
                  <MenuItem key={it.id} value={idx}>
                    {it.text}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </div>
        {!sidebar && (
          <div style={{ paddingLeft: 20 }}>
            {audience.age && audience.age.data && audience.age.data.length > 0 && (
              <ProfileAudienceAge ageData={audience.age.data} />
            )}
          </div>
        )}
      </div>

      <div className={classes.gridTwoColumns} style={{ gridTemplateColumns }}>
        <div className={classes.chart} style={sidebar ? { margin: 0 } : { marginRight: 20 }}>
          <BarChart
            data={weekdayData}
            title="Audience activity by day"
          />
        </div>
        <div className={classes.chart} style={sidebar ? { marginTop: 40 } : { marginLeft: 20 }}>
          <BarChart
            data={hourlyData}
            title="Audience activity by hour"
            labelClassName={classes.activityLabel}
          />
        </div>
      </div>
    </div>
  );
};

ProfileAudienceActivity.propTypes = {
  activityData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  audience: PropTypes.shape().isRequired,
  sidebar: PropTypes.bool,
};

ProfileAudienceActivity.defaultProps = {
  sidebar: false,
};

export default ProfileAudienceActivity;
