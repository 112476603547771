import React, { useEffect, useState, useRef } from 'react';

import PropTypes from 'prop-types';

import {
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
  // Avatar,
  Input,
  InputAdornment,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// import NoMatch from '../../router/NoMatch';
import SettingsPageBase from './SettingsPageBase';
import PasswordTextField from '../../common/PasswordTextField';
import ScrDialog from '../../common/ScrDialog';
import { useApi, useAuth, useNotification } from '../../../hooks';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
  },
  passwordField: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    '&>:not(:last-child)': {
      marginRight: 10,
    },
    alignItems: 'end',
  },
  actions: {
    paddingTop: theme.spacing(2),
    display: 'flex',
  },
  row: {
    width: '100%',
    marginBottom: 30,
    flexWrap: 'wrap',
  },
  label: {
    ...theme.fonts.medium,
    paddingBottom: 15,
    fontWeight: 500,
    transition: 'color 0.2s ease',
  },
  buttonAvatar: {
    width: 80,
    height: 80,
    marginRight: 20,
  },
  uploadBtn: {
    backgroundColor: theme.colors.system.grey3,
  },
  inputField: {
    width: '45%',
    '@media(max-width: 767px)': {
      width: '100%',
    },
    '@media(min-width: 1920px)': {
      width: '35%',
    },
    minWidth: 280,
    marginBottom: 10,
  },
  agencyInputField: {
    width: '90%',
    '@media(max-width: 767px)': {
      width: '100%',
    },
    '@media(min-width: 1920px)': {
      width: '35%',
    },
    minWidth: 280,
    marginBottom: 10,
  },
  selectedLabel: {
    color: theme.colors.primary.main,
  },
  changeContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
    padding: '1rem 7rem',
  },
  changeButton: {
    width: '18rem',
    height: '3rem',
    margin: '0 auto',
  },
  changeSupport: {
    border: `1px solid ${theme.colors.system.grey3}`,
    margin: '3rem 0rem',
    padding: '1rem',
    fontSize: '1rem',
    fontWeight: '500',
    '&:link': {
      textDecoration: 'none',
    },
  },
}));

const UpdatePasswordDialog = ({ onClose, isOpen }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const classes = useStyles();
  const auth = useAuth();
  const { createNotification } = useNotification();
  const [{ response, isFetching }, setPasswordResetRequest] = useApi();

  useEffect(() => {
    if (response) {
      if (response.status === 200) {
        createNotification('success', 'Password updated successfully!');
        onClose();
      } else {
        createNotification('error', 'Password update failed.');
      }
    }
  });

  const handleSubmit = () => {
    setCurrentPasswordError(currentPassword ? '' : 'Current password is required.');
    setNewPasswordError(newPassword && newPassword.length >= 6 ? '' : 'New password must be at least 6 characters.');

    if (currentPassword && newPassword && newPassword.length >= 6) {
      setPasswordResetRequest({
        method: 'post',
        url: `/user/${auth.user.id}/password-reset/`,
        data: {
          current_password: currentPassword,
          new_password: newPassword,
        },
      });
    }
  };

  // if (auth.hasPermission('administrator.restricted')) {
  //   return (
  //     <NoMatch default />
  //   );
  // }

  return (
    <ScrDialog
      isOpen={isOpen}
      onClose={onClose}
      title="Update Password"
      minWidth={800}
    >
      <DialogContent style={{ display: 'flex' }}>
        <div style={{ marginRight: 40, width: '50%' }}>
          <PasswordTextField
            error={Boolean(currentPasswordError)}
            helperText={currentPasswordError}
            label="Current password"
            InputLabelProps={{
              required: false,
              classes: {
                root: classes.label,
              },
            }}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
            value={currentPassword}
            style={{ width: '100%' }}
          />
        </div>
        <div style={{ width: '50%' }}>
          <PasswordTextField
            error={Boolean(newPasswordError)}
            helperText={newPasswordError}
            label="New password"
            InputLabelProps={{
              required: false,
              classes: {
                root: classes.label,
              },
            }}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            value={newPassword}
            style={{ width: '100%' }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={isFetching}
          onClick={handleSubmit}
          variant="contained"
          size="small"
          style={{ padding: '11px 70px' }}
        >
          Update password
        </Button>
      </DialogActions>
    </ScrDialog>
  );
};

UpdatePasswordDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const UpdatePasswordButton = () => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  return (
    <div>
      <Button
        color="primary"
        onClick={() => setDialogIsOpen(true)}
        size="small"
        variant="text"
        style={{ marginRight: 10 }}
      >
        Update Password
      </Button>
      <UpdatePasswordDialog
        onClose={() => setDialogIsOpen(false)}
        isOpen={dialogIsOpen}
      />
    </div>
  );
};

const ChangeOwnerModal = ({ onClose, isOpen }) => {
  const classes = useStyles();
  return (
    <ScrDialog
      isOpen={isOpen}
      onClose={onClose}
      title="Change Owner"
      titleStyle={{ marginBottom: 0 }}
      minWidth={800}
    >
      <DialogContent className={classes.changeContent}>
        <Typography component="p">
          If giving the other person admin permissions isn&apos;t enough, you
          need to
          {' '}
          <strong style={{ color: '#E75041', fontWeight: '600' }}>
            contact support
          </strong>
          {' '}
          and request for the ownership of the agency to be transferred.
        </Typography>
        <div className={classes.contactUsDiv}>
          <Typography variant="body2" className={classes.changeSupport}>
            Contact us at
            {' '}
            <a
              href="mailto:support@scrunch.com"
              style={{ textDecoration: 'none', color: '#E75041' }}
            >
              support@scrunch.com
            </a>
          </Typography>
        </div>
        <div>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={classes.changeButton}
          >
            Okay
          </Button>
        </div>
      </DialogContent>
    </ScrDialog>
  );
};

ChangeOwnerModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const AgencyProfile = ({ name, userType }) => {
  const classes = useStyles();
  const [changeOwner, setChangeOwner] = useState(false);

  return (
    <>
      <Typography variant="body1" style={{ marginBottom: '1rem' }}>Agency Profile</Typography>
      <div>
        <Typography
          variant="body2"
          component="p"
          className={classes.label}
          style={{ paddingBottom: 4 }}
        >
          Owner Details
        </Typography>
        <Input
          className={classes.inputField}
          readOnly
          value={name}
          fullWidth
          endAdornment={
            userType === 'owner' && (
            <InputAdornment position="end" color="primary">
              <Button
                color="primary"
                onClick={() => setChangeOwner(true)}
                size="small"
                variant="text"
                style={{ marginRight: 10 }}
              >
                Change Owner
              </Button>
              <ChangeOwnerModal
                isOpen={changeOwner}
                onClose={() => setChangeOwner(false)}
              />

            </InputAdornment>
            )
}
        />
      </div>
    </>
  );
};

AgencyProfile.propTypes = {
  name: PropTypes.string,
  userType: PropTypes.string.isRequired,
};

AgencyProfile.defaultProps = {
  name: '',
};

const UserSettingsPage = () => {
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [email, setEmail] = useState('');
  const classes = useStyles();
  const auth = useAuth();
  const [{ response: getUserResponse, isFetching: isGetUserFetching }, setGetUserRequest] = useApi();
  const [{ response: postUserResponse, isFetching: isPostUserFetching }, setPostUserRequest] = useApi();

  const passwordLabel = useRef();

  useEffect(() => {
    setGetUserRequest({
      method: 'get',
      url: `/user/${auth.user.id}/`,
    });
  }, [auth, setGetUserRequest]);

  useEffect(() => {
    if (getUserResponse && getUserResponse.status === 200) {
      setFirstName(getUserResponse.data.data.first_name);
      setLastName(getUserResponse.data.data.last_name);
      setEmail(getUserResponse.data.data.email);
    }
  }, [getUserResponse]);

  useEffect(() => {
    if (postUserResponse && postUserResponse.status === 200) {
      setFirstName(postUserResponse.data.data.first_name);
      setLastName(postUserResponse.data.data.last_name);
    }
  }, [postUserResponse]);

  async function handleOnFormSubmit(event) {
    event.preventDefault();

    setFirstNameError(!firstName ? 'First name is required.' : '');
    setLastNameError(!lastName ? 'Last name is required.' : '');

    if (firstName && lastName) {
      setPostUserRequest({
        method: 'put',
        url: `/user/${auth.user.id}/`,
        data: {
          first_name: firstName,
          last_name: lastName,
        },
      });
    }
  }

  const isLoading = isGetUserFetching || isPostUserFetching;

  return (
    <SettingsPageBase
      showLoadingbBar={isLoading}
      title="Profile settings"
    >
      <div className={classes.container}>
        <form className={classes.form} onSubmit={handleOnFormSubmit}>
          {/* <div className={classes.row}>
            <Typography variant="body2" component="p" className={classes.label}>Photo</Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                alt={auth.user ? auth.user.first_name : null}
                className={classes.buttonAvatar}
                src="#"
              />
              <Button size="small" variant="contained" className={`${classes.button} ${classes.uploadBtn}`}>
                Upload image
              </Button>
            </div>
          </div> */}
          <Typography variant="body1" style={{ marginBottom: '1rem' }}>Personal Profile</Typography>
          <div className={classes.row} style={{ display: 'flex' }}>
            <TextField
              error={Boolean(firstNameError)}
              helperText={firstNameError}
              label="First name"
              InputLabelProps={{
                required: false,
                classes: {
                  root: classes.label,
                },
              }}
              onChange={(e) => setFirstName(e.target.value)}
              required
              value={firstName}
              className={classes.inputField}
              style={{ marginRight: 20 }}
            />
            <TextField
              error={Boolean(lastNameError)}
              helperText={lastNameError}
              label="Last name"
              InputLabelProps={{
                required: false,
                classes: {
                  root: classes.label,
                },
              }}
              onChange={(e) => setLastName(e.target.value)}
              required
              value={lastName}
              className={classes.inputField}
            />
          </div>

          <div className={classes.row} style={{ display: 'flex' }}>
            <TextField
              label="Email"
              disabled
              InputLabelProps={{
                classes: {
                  root: classes.label,
                },
              }}
              readOnly
              value={email}
              className={classes.inputField}
              style={{ marginRight: 20 }}
            />
            <div className={classes.inputField}>
              <Typography
                variant="body2"
                component="p"
                className={classes.label}
                style={{ paddingBottom: 4 }}
                ref={passwordLabel}
              >
                Current Password
              </Typography>
              <Input
                className={classes.inputField}
                readOnly
                value="********"
                endAdornment={(
                  <InputAdornment position="end" color="primary">
                    <UpdatePasswordButton />
                  </InputAdornment>
                )}
                style={{ width: '100%' }}
                onFocus={() => {
                  passwordLabel.current.classList.add(classes.selectedLabel);
                }}
                onBlur={() => {
                  passwordLabel.current.classList.remove(classes.selectedLabel);
                }}
              />
            </div>
          </div>
          {auth.hasPermission('account.add_employee') && (
            <div style={{ width: '100%' }}>
              <AgencyProfile
                name={auth.account.owner}
                userType={auth.user.roles[0].name}
              />
            </div>
          )}
          <div className={classes.actions}>
            <Button
              className={`${classes.button} ${classes.uploadBtn}`}
              variant="contained"
              size="small"
              style={{ marginRight: 10 }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="small"
              disabled={isLoading}
              onClick={handleOnFormSubmit}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </SettingsPageBase>
  );
};

export default UserSettingsPage;
