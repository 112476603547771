import React from 'react';

import { SvgIcon } from '@material-ui/core';

const ScrunchLogo = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <g
      transform="matrix(1.3333333,0,0,-1.3333333,-161.04446,147.61513)"
      id="g4580"
    >
      <g
        transform="matrix(0.01155778,0,0,0.01155778,114.78327,90.331288)"
        id="g4582"
      >
        <path
          id="path4584"
          d="M 1075.47,487.16 C 1012.6209,484.0735 977.02878,539.32856 952.01953,588.30995 807.72536,838.22997 663.43118,1088.15 519.137,1338.07 c 65.039,37.5667 130.078,75.1333 195.117,112.7 120.40533,-208.547 240.81067,-417.094 361.216,-625.641 118.76,204.3929 235.2989,410.1947 355.4555,613.7018 47.4953,67.7084 160.6676,52.9542 192.3897,-22.0056 C 1774.3868,1155.1568 1925.4584,893.4884 2076.53,631.82 2011.48,594.26267 1946.43,556.70533 1881.38,519.148 1760.9867,727.69533 1640.5933,936.24267 1520.2,1144.79 1401.4389,940.39748 1284.9033,734.59315 1164.7428,531.08831 1143.8388,503.70363 1109.9087,486.99704 1075.47,487.16 Z"
          style={{
            fill: '#ef3731',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
        />
      </g>
    </g>
  </SvgIcon>
);

export default ScrunchLogo;
