import React, { useEffect, useState } from 'react';

import _ from 'lodash';

import PropTypes from 'prop-types';

import { MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FilterBase from './FilterBase';
import Select from '../../common/Select';
import { formatPercentage } from '../../../utils/number';
import { decodeRange, encodeRange } from '../../../utils/string';

const genders = ['m', 'f'];
const genderLabels = { m: 'Male', f: 'Female' };

const probabilities = [0, 0.1, 0.25, 0.5, 0.75];

const useStyles = makeStyles((theme) => ({
  inputs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  input: {
    width: 150,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  text: {
    width: 100,
    color: theme.palette.grey[600],
    textAlign: 'center',
  },
}));

const AudienceGenderFilter = (props) => {
  const { className, filterData, onApply } = props;
  const [activeText, setActiveText] = useState(null);
  const [gender, setGender] = useState('');
  const [probability, setProbability] = useState('');
  const classes = useStyles();

  useEffect(() => {
    const { audience_gender: audienceGender } = filterData;
    if (!_.isEmpty(audienceGender)) {
      setActiveText(
        Object.entries(audienceGender)
          .map((it) => `${genderLabels[it[0]]} >= ${formatPercentage(it[1])}`)
          .join(', '),
      );
      setGender(_.keys(audienceGender)[0]);
      const [min] = decodeRange(audienceGender[_.keys(audienceGender)[0]]);
      setProbability(min);
    } else {
      setActiveText(null);
      setGender(null);
      setProbability(null);
    }
  }, [filterData]);

  const handleOnApply = () => {
    if (gender) {
      onApply({
        ...filterData,
        audience_gender: {
          [gender]: encodeRange(probability, null),
        },
      });
    } else {
      onApply({ ...filterData, audience_gender: null });
    }
  };

  const handleOnClear = () => {
    onApply({ ...filterData, audience_gender: null });
  };

  return (
    <FilterBase
      className={className}
      label="Audience gender"
      activeText={activeText}
      isActive={!_.isEmpty(activeText)}
      onApply={handleOnApply}
      onClear={handleOnClear}
    >
      <div className={classes.inputs}>
        <Select
          className={classes.input}
          label="Gender"
          value={gender}
          onChange={(e) => setGender(e.target.value !== '' ? e.target.value : null)}
        >
          {genders.map((it) => (
            <MenuItem key={it} value={it}>
              {genderLabels[it]}
            </MenuItem>
          ))}
        </Select>
        <Typography className={classes.text} variant="body2">
          greater than or equal to
        </Typography>
        <Select
          className={classes.input}
          type="number"
          label="Percentage"
          value={probability}
          onChange={(e) => setProbability(e.target.value !== '' ? e.target.value : null)}
        >
          {probabilities.map((it) => (
            <MenuItem key={it} value={it}>
              {formatPercentage(it)}
            </MenuItem>
          ))}
        </Select>
      </div>
    </FilterBase>
  );
};

AudienceGenderFilter.propTypes = {
  className: PropTypes.string,
  filterData: PropTypes.shape().isRequired,
  onApply: PropTypes.func.isRequired,
};

AudienceGenderFilter.defaultProps = {
  className: '',
};

export default AudienceGenderFilter;
