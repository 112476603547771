import React from 'react';
import PropTypes from 'prop-types';

import {
  Document,
} from '@react-pdf/renderer';

import Page1 from './pages/Page1';
import Page2 from './pages/Page2';
import Page3 from './pages/Page3';
import Page4 from './pages/Page4';
import Page5 from './pages/Page5';
import Page6 from './pages/Page6';
import Page7 from './pages/Page7';
import Page8 from './pages/Page8';
import Page9 from './pages/Page9';

const MediaKitPdf = ({ formData }) => (
  <Document>
    <Page1 formData={formData} />
    <Page2 formData={formData} />
    <Page3 formData={formData} />
    <Page4 formData={formData} />
    <Page5 formData={formData} />
    <Page6 formData={formData} />
    <Page7 formData={formData} />
    <Page8 formData={formData} />
    <Page9 formData={formData} />
  </Document>
);

MediaKitPdf.propTypes = {
  formData: PropTypes.shape().isRequired,
};

export default MediaKitPdf;
