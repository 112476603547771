//
const locationImages = [
  'amstedam-netherlands.jpg',
  'mexico-mexico.jpg',
  'argentina-argentina.jpg',
  'miami-united-states.jpg',
  'ashburn-united states.jpg',
  'milan-italy.jpg',
  'athens-greece.jpg',
  'morocco-morocco.jpg',
  'auckland-new zealand.jpg',
  'australia-australia.jpg',
  'austria-austria.jpg',
  'bangkok-thailand.jpg',
  'barcelona-spain.jpg',
  'beijing-china.jpg',
  'belgium-belgium.jpg',
  'berlin-germany.jpg',
  'brazil-brazil.jpg',
  'brisbane-australia.jpg',
  'brussels-belgium.jpg',
  'california-united states.jpg',
  'canada-canada.jpg',
  'cape town-south africa.jpg',
  'china-china.jpg',
  'colombia-colombia.jpg',
  'copenhagen-denmark.jpg',
  'croatia-croatia.jpg',
  'czechia-czechia.jpg',
  'delhi-india.jpg',
  'denmark-denmark.jpg',
  'dubai-united arab emirates.jpg',
  'egypt-egypt.jpg',
  'finland-finland.jpg',
  'florida-united states.jpg',
  'france-france.jpg',
  'germany-germany.jpg',
  'greece-greece.jpg',
  'hong kong-hong kong.jpg',
  'houston-united states.jpg',
  'hungary-hungary.jpg',
  'india-india.jpg',
  'indonesia-indonesia.jpg',
  'ireland-ireland.jpg',
  'irvine-united states.jpg',
  'israel-israel.jpg',
  'italy-italy.jpg',
  'jakarta-indonesia.jpg',
  'japan-japan.jpg',
  'karachi-pakistan.jpg',
  'kuala lumpur-malaysia.jpg',
  'lahore-pakistan.jpg',
  'lebanon-lebanon.jpg',
  'lisbon-portugal.jpg',
  'london-united kingdom.jpg',
  'los angeles-united states.jpg',
  'madrid-spain.jpg',
  'malaysia-malaysia.jpg',
  'mexico city-mexico.jpg',
  'moscow-russia.jpg',
  'mumbai-india.jpg',
  'netherlands-netherlands.jpg',
  'new york-united states.jpg',
  'new zealand-new zealand.jpg',
  'nigeria-nigeria.jpg',
  'ontario-canada.jpg',
  'pakistan-pakistan.jpg',
  'paris-france.jpg',
  'peru-peru.jpg',
  'phillipines-phillipines.jpg',
  'poland-poland.jpg',
  'portugal-portugal.jpg',
  'prague-czechia.jpg',
  'rio de janeiro-brazil.jpg',
  'rome-italy.jpg',
  'russia-russia.jpg',
  'san diego-united states.jpg',
  'san francisco-united states.jpg',
  'san jose-united states.jpg',
  'sao paulo-brazil.jpg',
  'saudi arabia-saudi arabia.jpg',
  'seoul-south korea.jpg',
  'shanghai-china.jpg',
  'shenzhen-china.jpg',
  'singapore-singapore.jpg',
  'slovakia-slovakia.jpg',
  'slovenia-slovenia.jpg',
  'south africa-south africa.jpg',
  'south korea-south korea.jpg',
  'spain-spain.jpg',
  'stockholm-sweden.jpg',
  'sweden-sweden.jpg',
  'switzerland-switzerland.jpg',
  'sydney-australia.jpg',
  'taipei-taiwan.jpg',
  'taiwan-taiwan.jpg',
  'texas-united states.jpg',
  'thailand-thailand.jpg',
  'tokyo-japan.jpg',
  'tunisia-tunisia.jpg',
  'turkey-turkey.jpg',
  'ukraine-ukraine.jpg',
  'united arab emirates-united arab emirates.jpg',
  'united kingdom-united kingdom.jpg',
  'united states-united states.jpg',
  'venezuela-venezuela.jpg',
  'virginia-united states.jpg',
  'wellington-new zealand.jpg',
];

const popularSections = [
  {
    title: 'Popular Topics',
    items: [
      'Adult',
      'Animals & Pets',
      'Anime & Manga',
      'Architecture',
      'Automotive',
      'Beauty',
      'Boating',
      'Books',
      'Business & Finance',
      'Celebrity & Gossip',
      // 'Cooking',
      'Cosplay',
      'Design',
      'Education',
      'Environment',
      'Family',
      'Fashion',
      'Food & Drink',
      'Gaming',
      'Garden',
      'Graphic Novels & Comics',
      'Health & Fitness',
      'Home Interior',
      'Medicine',
      'Music',
      'News & Media',
      'Not for Profit',
      // 'Outdoors',
      'Photography',
      'Politics',
      'Relationships',
      'Sexuality',
      'Sciences',
      'Smoking & Vaping',
      'Spiritual',
      'Sport',
      'Tattoos',
      'Technology',
      'Tv & Film',
      'The Arts',
      'Wedding',
      'Self Help & Well Being',
    ],
  },
  {
    title: 'Popular Locations',
    items: [
      {
        label: 'Australia',
        routeParams: { location: 2077456 },
        imageSrc: 'australia-australia',
      },
      {
        label: 'Brisbane',
        routeParams: { location: 2174003 },
        imageSrc: 'brisbane-australia',
      },
      {
        label: 'Sydney',
        routeParams: { location: 2147714 },
        imageSrc: 'sydney-australia',
      },
      {
        label: 'Japan',
        routeParams: { location: 1861060 },
        imageSrc: 'japan-japan',
      },
    ],
  },
];

export default popularSections;
export { locationImages };
